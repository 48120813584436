import React, { useEffect, useState } from 'react';
import { IOverflowSetItemProps, OverflowSet, Checkbox, ContextualMenuItemType, IIconProps } from '@fluentui/react';
import { CommandBarButton, CommandButton } from '@fluentui/react/lib/Button';
import { IMultiSelectDropDownProps } from './IMultiSelectDropDownProps';
import { IKendoGridColumnProps } from '../grid/IKendoGridColumnProps';
import { SessionStorageConsts } from '../../../models/SessionStorageConsts';
import { UIControlsText } from '../../../content/UIControlsText';
import { getUserRole, LoadDefaultGridData, readGridConfig } from '../common/util';
import { buttonStyles, chooseColumnStyles } from "../../../content/styles/Page.styles";
import { CssClassConstants } from '../../../content/CssClassConstants';
import { IMultiSelectDropDownSessionProps } from '../props/IMultiSelectSessionProps';
import { SpecialPricing } from '../../../pages/dashboard/special-pricing-view';
import { MessageTypeText } from '../../../models/MessageTypeText';
import { getRuleValidationMessage, getEntityDataFromSession } from "../../../components/shared/common/util";

const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings)

const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
    if (item.onRender) {
        return item.onRender(item);
    }
    return (
        <CommandBarButton
            iconProps={{ iconName: item.icon }}
            menuProps={item.subMenuProps}
            text={item.name}
        />
    );
};

const resetIcon: IIconProps = { iconName: CssClassConstants.ResetIconName };

const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
    return (
        <CommandBarButton
            styles={buttonStyles}
            menuProps={{ items: overflowItems! }}
        />
    );
};

export const MultiSelectDropDown: React.FunctionComponent<IMultiSelectDropDownProps> = (props: IMultiSelectDropDownProps) => {
    const [menuItems, setMenuItems] = useState<IOverflowSetItemProps[]>([])
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    var columns: IKendoGridColumnProps[] = []

    let selectedColumns = sessionStorage.getItem(SessionStorageConsts.selectedColumns);

    useEffect(() => {
        if (selectedColumns) {
            let pricingGridColumns = JSON.parse(selectedColumns).filter((col: IMultiSelectDropDownSessionProps) => col.gridName === props.gridName)
            if (pricingGridColumns.length !== 0) {
                setSelectedKeys(pricingGridColumns[0].selectedColumns.filter((col: IKendoGridColumnProps) => col.isDefaultSelected === true).map((c: { key: any; }) => c.key))
            } else {
                if (props.dashBoardData) {
                    setSelectedKeys(props.dashBoardData.filter((col: IKendoGridColumnProps) => col.isDefaultSelected === true).map((c) => c.key))
                }
            }
        } else {
            if (props.dashBoardData) {
                setSelectedKeys(props.dashBoardData.filter((col: IKendoGridColumnProps) => col.isDefaultSelected === true).map((c) => c.key))
            }
        }
        bindGridColumns();

    }, []);


    useEffect(() => {
        bindGridColumns();
    }, [props]);

    // Method to add grid columns and default selected and disabled checkboxes
    const bindGridColumns = () => {
        var userRole = getUserRole();
        var userSubRole = userRole.split(",");
        let tempMenuItems: any = []
        props.dashBoardData?.map((col: IKendoGridColumnProps) => {
            if(col.allowColumnView.some(item => userSubRole.includes(item))){
                tempMenuItems.push({
                    key: col.key,
                    onRender: () => {
                        return <Checkbox name={col.key} inputProps={{role :"menuitemcheckbox"}} styles={chooseColumnStyles.checkboxStyles} label={col.columnTitle} checked={col.isDefaultSelected} disabled={col.isDisabled} onChange={e => onCheckBoxChange(e)} />
                    }
                })
            }
        })
        setMenuItems(tempMenuItems)
    }

    // Method to handle the the onChange event of checkbox
    const onCheckBoxChange = (e: any) => {
        var selecteditems: string[] = [];
        selecteditems = e.target.checked ? [...selectedKeys, e.target.name as string] : selectedKeys.filter(key => key !== e.target.name)
        setSelectedKeys(selecteditems)
        populateColumnsOnGrid(selecteditems)
    }

    // Method to add selectedColumns data to session.
    const populateColumnsOnGrid = (selectedKeys: string[]) => {
        let updatedGridColumns: IKendoGridColumnProps[] = []
        if (props.dashBoardData) {
            updatedGridColumns = updateDashboardGridConfig(selectedKeys, props.dashBoardData)
        }
        updateSessionDataConfig(updatedGridColumns)

    }

    const updateSessionDataConfig = (gridColumns: IKendoGridColumnProps[]) => {
        if (selectedColumns) {
            let sessionGridConfigData = JSON.parse(selectedColumns)
            let pricingGridColumns = sessionGridConfigData.filter((col: IMultiSelectDropDownSessionProps) => col.gridName === props.gridName)
            if (pricingGridColumns.length !== 0) {
                pricingGridColumns[0].selectedColumns = gridColumns
                sessionStorage.setItem(SessionStorageConsts.selectedColumns, JSON.stringify(sessionGridConfigData))
            }
        }
    }

    const updateDashboardGridConfig = (selectedKeys: string[], gridData: IKendoGridColumnProps[]): IKendoGridColumnProps[] => {
        gridData.forEach((element) => {
            if (selectedKeys.includes(element.key)) {
                element.isDefaultSelected = true
                element.isVisible = true
            } else {
                element.isDefaultSelected = false
                element.isVisible = false
            }
            columns.push(element)
        })
        props.updateDashboardGridColumnsData?.(columns)
        return columns;
    }

    // Method to remove selectedColumns data from session storage 
    const onReset = () => {
        let defaultGridState = readGridConfig(props.gridName)
        let defaultSelectedColumns = LoadDefaultGridData(defaultGridState.kendoGridProps)
        updateSessionDataConfig(defaultSelectedColumns.columns)
        props.updateDashboardGridColumnsData?.(defaultSelectedColumns.columns)
        setSelectedKeys(defaultSelectedColumns.columns.filter((col) => col.isDefaultSelected === true).map((col) => col.key))
    }

    return (
        <OverflowSet
            className='choosecolumns'
            aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChooseColumnsLabel", UIControlsText.ChooseColumnsLabel)}
            items={[
                {
                    key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChooseColumnsLabel", UIControlsText.ChooseColumnsLabel),
                    icon: CssClassConstants.ChooseColumnIconName,
                    name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChooseColumnsLabel", UIControlsText.ChooseColumnsLabel),
                    subMenuProps: {
                        items: [
                            {
                                key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Resetbtn", UIControlsText.Resetbtn),
                                onRender: () => {
                                    return (
                                        <CommandButton iconProps={resetIcon} id="resetBtn" text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Resetbtn", UIControlsText.Resetbtn)} onClick={onReset} />

                                    )
                                }
                            },
                            {
                                key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ColumnsHeader", UIControlsText.ColumnsHeader),
                                text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ColumnsHeader", UIControlsText.ColumnsHeader),
                                itemType: ContextualMenuItemType.Header,

                            },
                            ...menuItems,

                        ],
                        calloutProps: {
                            calloutMaxHeight: 400,
                        },
                    },
                },
            ]}
            onRenderOverflowButton={onRenderOverflowButton}
            onRenderItem={onRenderItem}
        />
    )
};
