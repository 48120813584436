import * as React from "react";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import fetchJsonp from "fetch-jsonp";
import { _Styles } from '../../content/styles/Page.styles';
import { Footer } from "./footer";
import { nextGenService } from "../../services/NextGenService";
import { IUserDetail } from "../../models/IUserDetail";
import { IUserRoleDetail } from "../../models/IUserRoleDetail";
import { encryptString, isNullOrEmpty, refreshSessionStorageKey, getEntityDataFromSession, getRuleValidationMessage } from "../../components/shared/common/util";
import { SessionStorageConsts } from "../../models/SessionStorageConsts";
import { UIControlsText } from "../../content/UIControlsText";
import { Guid } from "../../infrastructure/Guid";
import { ApplicationConstants } from "../../models/ApplicationConstants";
import { MessageTypeText } from '../../models/MessageTypeText';

const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

export const AAdAuth: React.FunctionComponent = (props) => {
    let history = useHistory();
    let loggedInUser: IUserDetail;
    let RSLR_UserToken_URI: string = process.env.REACT_APP_RSLR_ACCESS_TOKEN || "";

    useEffect(() => {
        FetchUserDetails();
    });

    // Fetching the user token via PC API
    function Fetch_UserToken() {
        return new Promise((resolve, reject) => {
            // Fetching the user token via PC API
            fetchJsonp(RSLR_UserToken_URI, { timeout: Number(process.env.REACT_APP_PCAPI_TIMEOUT_MS) })
                .then(function (response) {
                    return response.json();
                })
                .then(function (token) {
                    if (!isNullOrEmpty(token)) {
                        var parsedToken = JSON.parse(token)
                        sessionStorage.setItem("userToken", parsedToken); //SessionStorageConsts.nextGenAPIPCUserToken
                        resolve(parsedToken);
                    } else {
                        history.push({
                            pathname: '/errorPage',
                            state: { message: getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "NotFoundApiError", UIControlsText.NotFoundApiError) }
                        });

                        reject(new Error('Error fetching API Auth token.'));
                    }
                })
                .catch(function (ex) {
                    console.log('parsing failed', ex);
                    history.push({
                        pathname: '/errorPage',
                        state: { message: ex.toString() }
                    });

                    reject(new Error('Error fetching API Auth token.'));
                });
        });
    }

    function FetchUserDetails() {
        var UserDetailsAPI = process.env.REACT_APP_RSLR_USERDETAILS_API_URI || "";
        var enableAuth = process.env.REACT_APP_AUTHORIZATION_ENABLED === "true" || false;
        fetchJsonp(UserDetailsAPI, { timeout: Number(process.env.REACT_APP_PCAPI_TIMEOUT_MS) })
            .then(function (response) {
                return response.json()
            }).then(async function (json) {
                loggedInUser =
                {
                    email: JSON.parse(json)["Email"],
                    firstName: JSON.parse(json)["FirstName"],
                    lastName: JSON.parse(json)["LastName"],
                    name: JSON.parse(json)["Name"],
                    puid: JSON.parse(json)["PUID"],
                    userName: JSON.parse(json)["UserName"],
                    userRoleDetails: []
                };
                if (enableAuth) {
                    await Fetch_UserToken();
                }
            })
            .then(function () {
                if (loggedInUser.email.toLowerCase().endsWith(ApplicationConstants.microsoftEmail)) {
                    FetchUserRoles();
                }
                else {
                    if (enableAuth) {
                        FetchAccountsDetails();
                    }
                    else {
                        FetchAccountsDetailsFromRslr();
                    }
                }

            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
                history.push({
                    pathname: '/errorPage',
                    state: { message: ex.toString() }
                })
            })
    }

    // Gets the Org Account Details via NextGen API for External Accounts.
    function FetchAccountsDetails() {
        nextGenService.getUserAccountDetails(loggedInUser.email).then(async (response) => {
            if (!isNullOrEmpty(response)) {
                // Get the Org and Loc Details from the response.
                if (response.data != null && response.data.length > 0) {
                    var locationList = response.data;
                    loggedInUser.organizationId = locationList[0]?.organizationId;
                    loggedInUser.organizationName = locationList[0]?.organizationName;
                    locationList.forEach((locationObj: IUserRoleDetail) => {
                        if (!isNullOrEmpty(locationObj)) {
                            loggedInUser.userRoleDetails?.push(locationObj);
                        }
                    });
                    FetchUserRoles();
                }
                else {
                    history.push({
                        pathname: '/errorPage',
                        state: { message: getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "OrganizationInfoMissing", UIControlsText.OrganizationInfoMissing) }
                    })
                }
            }
            else {
                history.push({
                    pathname: '/errorPage',
                    state: { message: getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "OrganizationInfoMissing", UIControlsText.OrganizationInfoMissing) }
                })
            }
        }).catch(function (ex: { toString: () => any; }) {
            console.log('parsing failed', ex);
            history.push({
                pathname: '/errorPage',
                state: { message: ex.toString() }
            })
        });
    }

    function FetchAccountsDetailsFromRslr() {
        var AccountAPI = process.env.REACT_APP_RSLR_ACCOUNT_API_URI || ""
        fetchJsonp(AccountAPI, { timeout: Number(process.env.REACT_APP_PCAPI_TIMEOUT_MS) })
            .then(function (response) {
                return response.json()
            }).then(function (json) {
                let parsedJson = JSON.parse(json);
                let Organization = Object.keys(parsedJson);
                loggedInUser.organizationId = Organization != null && Organization.length ? Organization[0].split("-")[0].trim() : "";
                if (!isNullOrEmpty(loggedInUser.organizationId)) {
                    Object.keys(parsedJson[Organization[0]]).forEach(function (key) {
                        if (!isNullOrEmpty(key)) {
                            let eachOrg = {
                                locationId: key.split("-")[0].trim()
                            } as IUserRoleDetail
                            loggedInUser.userRoleDetails?.push(eachOrg);
                        }
                    });
                    FetchUserRoles();
                }
                else {
                    history.push({
                        pathname: '/errorPage',
                        state: { message: getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "OrganizationInfoMissing", UIControlsText.OrganizationInfoMissing) }
                    })
                }
            }).catch(function (ex) {
                console.log('parsing failed', ex);
                history.push({
                    pathname: '/errorPage',
                    state: { message: ex.toString() }
                })
            })
    }

    function FetchUserRoles() {
        sessionStorage.setItem(SessionStorageConsts.userEmail, loggedInUser.email);
        refreshSessionStorageKey(SessionStorageConsts.correlationId, Guid.newGuid());

        // Next Gen API Call to get the user roles
        nextGenService.getUserDetails(loggedInUser).then(async (response) => {
            if (!isNullOrEmpty(response.data.userRoleDetails) && !isNullOrEmpty(loggedInUser.userRoleDetails)) {
                loggedInUser.userRoleDetails = response.data.userRoleDetails;
                sessionStorage.setItem(SessionStorageConsts.loggedInUserInfo, encryptString(JSON.stringify(loggedInUser)));
                let userRequestedUrL = sessionStorage.getItem(SessionStorageConsts.userRequestedUrL);
                if (!isNullOrEmpty(userRequestedUrL)) {
                    sessionStorage.removeItem(SessionStorageConsts.userRequestedUrL);
                    history.push(userRequestedUrL || "/homePage");
                    window.location.reload();
                }
                else {
                    history.push("/homePage");
                    window.location.reload();
                }
            }
            else {
                history.push("/homePage");
                window.location.reload();
            };
        }).catch((error) => {
            console.log('parsing failed');
            history.push({
                pathname: '/errorPage',
                state: { message: getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "NotFoundApiError", UIControlsText.NotFoundApiError) }
            })
        });
    }

    return (
        <div>
            <div className={_Styles.contentStyle}>
                <h3>Fetching account/user details is in-progress</h3>
            </div>
        </div>
    )
}