import React, { useEffect } from 'react'
import { Prompt } from 'react-router-dom'
import { IRouteLeavingGuardProps } from '../props/IRouteLeavingGuardProps'
import { PageMode } from '../../../../models/PageMode'

const RouteLeavingGuard: React.FunctionComponent<IRouteLeavingGuardProps> = (props: IRouteLeavingGuardProps) => {
  useEffect(() => {
    if (!props.when || props.pageMode?.toLocaleLowerCase() !== PageMode.Create.toLocaleLowerCase()) return () => {}

    const beforeUnloadCallback = (event: any) => {
      event.preventDefault()
      event.returnValue = ""
      return ""
    }

    window.addEventListener('beforeunload', beforeUnloadCallback)
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback)
    }
  }, [props.when])

  return <Prompt when={props.when} 
    message={() => {
      if (props.when) {
          return true
      } 
      return ""
  }} />
}

export default RouteLeavingGuard