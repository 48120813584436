import { AxiosWrapperBase } from "../infrastructure/axios-wrapper/AxiosWrapperBase";

/**
 * BaseService.
 * @class
 */
export class BaseService {
    /**
     * Base Url of API.
     * @type {string | undefined}
    */
    public static apiBaseUrl: string | undefined = process.env.REACT_APP_API_URI;

    /**
     * Axios Wrapper to make API call.
     * @type {AxiosWrapperBase}
    */
    public static axiosWrapper: AxiosWrapperBase = new AxiosWrapperBase();
}