import { IButtonProps, Pivot, PivotItem } from '@fluentui/react';
import { FormRenderingService, IComponent } from '../Components/DynamicFormRendering';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { IPivotTab } from '../../../models/IPivotTab';
import { PageStyles } from '../../pricingprogram/common/content/Page.Styles';

const PivotContainer = (props: any) => {
    const { register } = useForm({});
    const [selectedTabName, setSelectedTabName] = useState<string>(props?.selectedTabName || "");
    
    const onTabChange = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
        setSelectedTabName(item?.props?.headerText || "");
        props.handleTabSelectionChanges && props.handleTabSelectionChanges(item?.props?.headerText);
    }

    const getTabTextColor = (pivotTab: IPivotTab) => {
       if (pivotTab.isValid === false) {
            return PageStyles.pivotTabError;
        }
        else if (pivotTab.isDisabled === true) {
            return PageStyles.pivotTabDisabled;
        }
        else {
            return PageStyles.pivotTabNormal;
        }
    }

    useEffect(() => {
        setSelectedTabName(props?.selectedTabName || "");
    }, [props?.selectedTabName])

    return (
        <Pivot key='PivotRoot' id='pContainer' selectedKey={selectedTabName} onLinkClick={onTabChange} data-nextgen-parent-group= "NextGen Tab Container"
        data-nextgen-parentid= "NextGen Tab Container">
            {props?.tabNames?.map((item: IPivotTab, index: number) => {
                return (
                    <PivotItem headerText={`${item.tabName}`} itemKey={item.tabName} headerButtonProps = {{disabled: item.isDisabled, style: getTabTextColor(item)}} >
                        {props.items[index] instanceof Array && props.items[index].map((eachItem: IComponent, key: number) => {
                            return (
                                <>
                                    {FormRenderingService(eachItem, register, props.baseState)}
                                </>
                            );
                        })}
                    </PivotItem>
                );
            })}
        </Pivot >
    );
}

export default PivotContainer;
