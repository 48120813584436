/**
 * Enum for Partner Type.
 * @readonly
 * @enum {Enum}
 */
export enum PartnerType {
    /**
    * ADD value.
    */
    ADD = 1,
    /**
     * ADR value.
     */
    ADR = 2,
    /**
     * DMP value.
     */
    DMP = 3,
    /**
     * MsStore value.
     */
    MsStore = 4,
    /**
     * MsStore value.
     */
    Reseller = 5,
    /**
     * Additional Tier Reseller value.
     */
    AdditionalTierReseller = 6,
    /**
     * Final Tier Reseller value.
     */
    FinalTierReseller = 7,
}