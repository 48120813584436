import { MessageBarType, Panel, PrimaryButton, Spinner, Stack } from "@fluentui/react";
import { Checkbox, DefaultButton, MessageBar, StackItem } from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { UIControlsText } from "../../../../content/UIControlsText";
import { JavaScriptExtensions } from "../../../../infrastructure/JavaScriptExtensions";
import { IDealType } from "../../../../models/IDealType";
import { IPricingProgramDuplicateDeal } from "../../../../models/IPricingProgramDuplicateDeal";
import { PartnerType } from "../../../../models/PartnerType";
import { SessionStorageConsts } from "../../../../models/SessionStorageConsts";
import { nextGenService } from "../../../../services/NextGenService";
import { PageStyles } from "../content/Page.Styles";
import { IPricingProgramSelectorProps } from "../props/IPricingProgramSelectorProps";
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { getRuleValidationMessage, getEntityDataFromSession } from "../../../../components/shared/common/util";

/**
 * Select Pricing Program Component.
 * @function component
 */
const PricingProgramSelector: React.FunctionComponent<IPricingProgramSelectorProps> = (props: IPricingProgramSelectorProps) => {
    const [pricingPrograms, setPricingPrograms] = useState<IDealType[]>(props.selectedDealTypes);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [isPanelOpen, setIsPanelOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isDuplicateCheckPerformed, setIsDuplicateCheckPerformed] = useState<boolean>(false);
    const [isPricingProgramFormLoading, setIsPricingProgramFormLoading] = useState<boolean>(false);
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const onDismiss = React.useCallback((ev?: React.SyntheticEvent | KeyboardEvent) => {
        if (ev) {
            ev.preventDefault();
            dismissPanel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.isOpenPanel) {
            setIsPanelOpen(true);
        }
        else {
            setIsPanelOpen(false);
        }
    }, [props?.isOpenPanel])
    /**
    * cancel button click.
    * @method
    */
    const dismissPanel = () => {

        props?.handlePricingProgramSelectionCancel && props?.handlePricingProgramSelectionCancel();
    }
    const onContinueDismissPanel = () => {
        setPricingPrograms([]);
        resetPanelValues();
        setIsDuplicateCheckPerformed(false);
    }
    const resetPanelValues = () => {
        setShowWarning(false);
        setErrorMessage('');
        setIsDuplicateCheckPerformed(false);
    }

    /**
    * continue button click.
    * @method
    */
    const onContinue = async () => {
        try {
            if (!isDuplicateCheckPerformed && pricingPrograms.findIndex(t => t.name === props?.dealTypeName) >= 0) {
                setIsPricingProgramFormLoading(true);
                type PartnerDataType = keyof typeof PartnerType;
                const pricingProgramDuplicateDeal: IPricingProgramDuplicateDeal = {
                    dealTypeName: props?.dealTypeName,
                    isManaged: props?.isManaged,
                    organizationName: props?.organizationName,
                    productTypeName: props?.partnerDetails?.productGroupName || "",
                    mpnLocationId: props?.partnerDetails?.mpnLocationId || "",
                    mpnOrganizationId: props?.partnerDetails?.organizationId || "",
                    userEmail: sessionStorage.getItem(SessionStorageConsts.userEmail) || "",
                    partnerType: JavaScriptExtensions.isEmptyOrNullOrUndefined(props?.partnerDetails?.userRole) ? PartnerType.MsStore : PartnerType[props?.partnerDetails?.userRole as PartnerDataType]
                }
                var result = await Promise.resolve(nextGenService.searchDuplicateDeal(pricingProgramDuplicateDeal));
                setIsDuplicateCheckPerformed(true);
                if (result.status === 200 && result.data.isDuplicate) {
                    setErrorMessage(result.data.errorMessage);
                    setShowWarning(true);
                }
                else {
                    props?.handlePricingProgramSelection && props?.handlePricingProgramSelection(pricingPrograms);
                    onContinueDismissPanel();
                }
            }
            else {
                props?.handlePricingProgramSelection && props?.handlePricingProgramSelection(pricingPrograms);
                onContinueDismissPanel();
            }
        }
        catch (error: any) {
            props?.handlePricingProgramSelection && props?.handlePricingProgramSelection(pricingPrograms);
            onContinueDismissPanel();
        }
        finally {
            setIsPricingProgramFormLoading(false);
        }
    }

    /**
    * pricing Program Checked Change Callback function.
    * @method
    * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
    * @param {checked} boolean.
    * @param {string} dealType property
    */
    const pricingProgramChecked = (dealType: IDealType) => (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        resetPanelValues();
        if (checked && !(pricingPrograms.findIndex(t => t.id === dealType.id) !== -1)) {
            setPricingPrograms([...pricingPrograms, dealType]);
        }
        else if (!checked) {
            setPricingPrograms(pricingPrograms.filter(x => x.id !== dealType.id));
        }
    }

    // display the buttons in panel footer.
    const onRenderFooterContent = React.useCallback(
        () => (
            <>
                <PrimaryButton
                    text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContinueText", UIControlsText.ContinueText)}
                    styles={PageStyles.commandButtonPadding}
                    aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContinueText", UIControlsText.ContinueText)}
                    title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContinueText", UIControlsText.ContinueText)}
                    allowDisabledFocus={false}
                    onClick={onContinue}
                    disabled={pricingPrograms?.length === 0} />
                <DefaultButton
                    text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)}
                    styles={PageStyles.commandButtonPadding}
                    aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)}
                    title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)}
                    onClick={dismissPanel}
                    allowDisabledFocus={false} />
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dismissPanel],
    );

    return (
        <div>
            <Panel
                headerText={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SelectPricingProgramText", UIControlsText.SelectPricingProgramText)}
                isOpen={isPanelOpen}
                onDismiss={onDismiss}
                closeButtonAriaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}
                isFooterAtBottom={true}
                onRenderFooterContent={onRenderFooterContent}>
                <Stack tokens={{ childrenGap: 10 }} style={PageStyles.paddingTop10px}>
                    {
                        props?.dealTypes.map((item: any) => {
                            return <Checkbox key={item?.id} ariaLabel={item?.name} label={item?.name} onChange={pricingProgramChecked(item)}
                                checked={pricingPrograms.filter(x => x.id == item?.id).length > 0 ? true : false} />
                        })
                    }
                </Stack>
                {isPricingProgramFormLoading &&
                        <Stack>
                          <div>
                            <Spinner label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SearchDuplicateDeals", UIControlsText.SearchDuplicateDeals)} />
                          </div>
                        </Stack>
                      }
                <Stack style={PageStyles.paddingTop10px} >
                    <StackItem style={showWarning ? { visibility: "visible" } : { visibility: "hidden" }}>
                        <MessageBar styles={PageStyles.warningBackGroundColor}
                            id="WarningDuplicate"
                            messageBarType={MessageBarType.severeWarning}>
                            {errorMessage}
                        </MessageBar>
                    </StackItem>
                </Stack>
            </Panel>
        </div>
    );
}

export default PricingProgramSelector;