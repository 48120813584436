import { FC, Fragment, useEffect } from "react";
import { GridCellProps, GRID_COL_INDEX_ATTRIBUTE, } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { ApplicationConstants } from "../../models/ApplicationConstants";
import { SessionStorageConsts } from "../../models/SessionStorageConsts";
import { PageMode } from "../../models/PageMode";


const LinkCustomKendoGridCell: FC<GridCellProps> = (props: GridCellProps) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    const navigatetoViewforDRSP = (opportunityName?: string, opportunityDealName?: string) => {
        sessionStorage.setItem(SessionStorageConsts.pageMode, PageMode.View);
        window.location.href = "/managecase/pricingprogram?oppNumber=" + opportunityName + "&oppDealNumber=" + opportunityDealName
    }
    const navigatetoViewforCPS = (opportunityName?: string) => {
        sessionStorage.setItem(SessionStorageConsts.pageMode, PageMode.View);
        window.location.href = "/managecase/cpstrategic?oppNumber=" + opportunityName
    }
    
    const navigatetoViewforOTLE = (opportunityName?: string) => {
        sessionStorage.setItem(SessionStorageConsts.pageMode, PageMode.View);
        window.location.href = "/managecase/otle?oppNumber=" + opportunityName
    }

    return (
        <Fragment>
            <td style={props.style} // this applies styles that lock the column at a specific position
                className={props.className} // this adds classes needed for locked columns
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
                {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
                {...navigationAttributes}
            >
                {(props.dataItem.dealType.name === ApplicationConstants.OTLE) &&
                    (<a className="custom-kendo-grid-link" onClick={() => navigatetoViewforOTLE(props.dataItem.opportunityName)} >
                        {props.dataItem.opportunityName}
                    </a>)
                }
                {(props.dataItem.dealType.name === ApplicationConstants.CPStrategic) &&
                    (<a className="custom-kendo-grid-link" onClick={() => navigatetoViewforCPS(props.dataItem.opportunityName)} >
                        {props.dataItem.opportunityName}
                    </a>)
                }
                {((props.dataItem.dealType.name === ApplicationConstants.DealRegistration) || (props.dataItem.dealType.name === ApplicationConstants.SpecialPricing)) &&
                    (<a className="custom-kendo-grid-link" onClick={() => navigatetoViewforDRSP(props.dataItem.opportunityName, props.dataItem.name)} >
                        {props.dataItem.opportunityName}
                    </a>)
                }
            </td>
        </Fragment>
    )
}

export default LinkCustomKendoGridCell