import { FontIcon, HoverCard, HoverCardType, ITextProps, MessageBar, Panel, Text } from '@fluentui/react';
import React, { useState } from 'react';
import { UIControlType } from '../../../models/UIControlType';
import { DefaultButton, DialogType, PrimaryButton, Label, Stack, StackItem } from "office-ui-fabric-react";
import { DefaultPageStyles, expireDialog, stackButtonStyles, stackStyles, _Styles } from '../../../content/styles/Page.styles';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import NextGenKendoGrid from '../../../components/shared/grid/nextgenkendogrid';
import { isNullOrEmpty } from '../../../components/shared/common/util';
import { JavaScriptExtensions } from '../../../infrastructure/JavaScriptExtensions';
import { ActionMenu } from './Components';
import { UIControlsText } from '../../../content/UIControlsText';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { PageStyles } from '../../pricingprogram/common/content/Page.Styles';
import { CssClassConstants } from '../../../content/CssClassConstants';
import { SessionStorageConsts } from '../../../models/SessionStorageConsts';
import { getRuleValidationMessage, getEntityDataFromSession } from "../../../components/shared/common/util";
import { MessageTypeText } from '../../../models/MessageTypeText';


function InnerHTMLContainer(data: any): JSX.Element {

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(data?.hidden);
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

    switch (data.contolType) {
        case UIControlType.MessageBar:
            return (
                <MessageBar aria-label={data?.message} styles={data?.styles} messageBarType={data?.messageBarType}
                    onDismiss={data.handleDismiss && data?.handleDismiss} dismissButtonAriaLabel="Close"
                    isMultiline={true}>
                    {data?.message}
                </MessageBar>
            );
        case UIControlType.Label:
            return (
                <Label styles={JavaScriptExtensions.isEmptyOrNullOrUndefined(data?.styles) ? DefaultPageStyles.customWidthStyles : data?.styles} id={data?.id} className={data?.className ? data?.className : _Styles.customLabel} >
                    {data?.value}
                </Label>
            );
            case UIControlType.SubHeading:
                return (
                    <Stack role="heading" aria-level={2} wrap styles={JavaScriptExtensions.isEmptyOrNullOrUndefined(data?.styles) ? DefaultPageStyles.customWidthStyles : data?.styles} id={data?.id} className={_Styles.customLabelHeading} >
                        {data?.value}
                    </Stack>
                );
        case UIControlType.LabelValueView:
            return (
                <>
                    <Label styles={JavaScriptExtensions.isEmptyOrNullOrUndefined(data?.styles) ? DefaultPageStyles.customWidthStyles : data?.styles} id={data?.id} className={_Styles.customLabelHeading} >
                        {data?.label}: &nbsp; &nbsp;
                        <Label className={'ms-LabelValue'}>{data?.value}</Label>
                    </Label>
                </>
            );
        case UIControlType.PanelBarItemWithGrid:
            return (
                <PanelBar expandMode={data?.expandMode} className={CssClassConstants.PanelBarMaxWidth}>
                    {
                        data?.items?.map((item: any) => {
                            return (
                                item.visible && item.gridcongfig ? (
                                    <PanelBarItem title={item.title} expanded={item.expanded}>
                                        <NextGenKendoGrid
                                            {...{
                                                kendoGridProps: { ...item.gridcongfig.kendoGridProps }
                                            }}
                                        />
                                    </PanelBarItem>) : ""
                            )
                        })
                    }
                </PanelBar>
            )
        case UIControlType.Text:
            //for size pease refer to https://developer.microsoft.com/en-us/fluentui#/controls/web/text
            return (
                <Text variant={data.textSize as ITextProps['variant']} nowrap block>
                    {data.text}
                </Text>
            );
        case UIControlType.HoverCardPlain:
            return (
                <HoverCard
                    cardDismissDelay={data.cardDismissDelay}
                    type={HoverCardType.plain}
                    plainCardProps={data.plainCardProps}
                    componentRef={data.hoverCard}
                    onCardHide={data.onCardHide}
                >
                    {
                        data.iconName ? <FontIcon aria-label={data.text} iconName={data.iconName} className={data.iconClassName} /> : ""
                    }
                    <span className={data.hoverTextStyles}>{data.text}</span>
                </HoverCard>
            );
        case UIControlType.Panel:
            return (
                <Panel headerText={data.headerText}
                    isOpen={data.isOpen}
                    onDismiss={data.dismissPanel}
                    type={data.type}
                    customWidth={data.customWidth}
                    closeButtonAriaLabel="Close">
                    {data.content}
                </Panel>
            );
        case UIControlType.CustomerTable:
            return (
                <table className="table table-sm table-fixed table-borderless p-1 tbl-info w-50">
                    <tbody>
                        <tr>
                            <td className="p-1 fw-bold " width="20%"> Customer Name: </td>
                            <td className="p-1">{data.selectedCustomer?.legalEntityName}</td>
                            <td className="p-1" hidden={data.showEdit} onClick={data.editEvent}><a href="#">Edit</a></td>
                        </tr>
                        {!isNullOrEmpty(data.selectedCustomer?.dunsNumber) && <tr>
                            <td className="p-1 fw-bold " width="20%">Duns ID: </td>
                            <td className="p-1">{data.selectedCustomer?.dunsNumber}</td>
                        </tr>
                        }
                        {!isNullOrEmpty(data.selectedCustomer?.organizationNameInLocalLanguage) && <tr>
                            <td className="p-1 fw-bold " width="20%">Customer Name - Local Language: </td>
                            <td className="p-1">{data.selectedCustomer?.organizationNameInLocalLanguage}</td>
                        </tr>
                        }
                        {!isNullOrEmpty(data.selectedCustomer?.industry) && <tr>
                            <td className="p-1 fw-bold " width="20%">Industry: </td>
                            <td className="p-1">{data.selectedCustomer?.industry}</td>
                        </tr>
                        }
                        {!isNullOrEmpty(data.selectedCustomer?.vertical) && <tr>
                            <td className="p-1 fw-bold " width="20%">Vertical: </td>
                            <td className="p-1">{data.selectedCustomer?.vertical}</td>
                        </tr>
                        }
                        {!isNullOrEmpty(data.selectedCustomer?.verticalCategory) && <tr>
                            <td className="p-1 fw-bold " width="20%">Vertical Category: </td>
                            <td className="p-1">{data.selectedCustomer?.verticalCategory}</td>
                        </tr>
                        }
                        {!isNullOrEmpty(data.selectedCustomer?.webPageURL) && <tr>
                            <td className="p-1 fw-bold " width="20%">Webpage URL: </td>
                            <td className="p-1">{data.selectedCustomer?.webPageURL}</td>
                        </tr>
                        }
                        {!isNullOrEmpty(data.selectedCustomer?.dealCustomerDomain) && <tr>
                            <td className="p-1 fw-bold " width="20%">Email Domain: </td>
                            <td className="p-1">{data.selectedCustomer?.dealCustomerDomain}</td>
                        </tr>
                        }
                    </tbody>
                </table>
            );
        case UIControlType.ActionMenu:
            return (
                <ActionMenu {...data.actionMenuConfig} />
            )
        case UIControlType.MessageWithDialog:
            return (
                <div>
                    {isDialogOpen && (
                        <Dialog style={_Styles}>
                            <Stack horizontal={false} styles={stackStyles}>
                                <Stack>
                                    <h4> {data.title}</h4>
                                </Stack>

                                <Stack>
                                    <Label style={expireDialog} className={_Styles.DialogueCheckBox}>
                                        {data.message}
                                        {data.listdata.map((item: any) => {
                                            return (<ul><li key={item.id}>{item.name}</li></ul>)
                                        })}
                                    </Label>
                                </Stack>
                            </Stack>
                            <DialogActionsBar>
                                <Stack horizontal horizontalAlign="end" styles={stackButtonStyles}>
                                    <PrimaryButton
                                        title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "OkText", UIControlsText.OkText)}
                                        aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "OkText", UIControlsText.OkText)}
                                        text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "OkText", UIControlsText.OkText)}
                                        onClick={() => {
                                            setIsDialogOpen(false);
                                        }}
                                    />
                                </Stack>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>
            );

            default:
                return (
                    <></>
            )
    }
}

export default InnerHTMLContainer;