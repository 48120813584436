/**
 * Enum for Guidance Text.
 * @readonly
 * @enum {string}
 */
 export enum GuidanceText {
  DealInfoCustomerDomain = "Enter “@company.com” domain of the end customer. Please do not input contact emails.",
  DealInfoNewCustomerWarning = "The opportunity will be reviewed by Microsoft Business Desks only after the tentatively submitted customer review has been completed. Microsoft reserves the right to deny the opportunity submission if the submitted customer record is deemed not acceptable.",
  DealInfoDealOverview = "Enter deal details such as the general nature of the deal, the end customer’s planned use for the devices, and/or any other relevant details that may assist in the review of this opportunity.",
  DealInfoDealOverviewForMSStore = "Enter deal details such as the general nature of the deal, the end customer’s plans for the devices, pricing offered by MS Stores to the customer, value added services being offered by the MS Store to the customer (if applicable), or any other details that may assist in the review of this opportunity.",
  DealInfoPublicTenderJustification = "If this deal is part of a RFP/RFQ/Public Tender, you must provide details on the transaction by attaching proof in the ‘attachments section’ or provide justification in the field below.",
  DealInfoPublicTenderAttachments = "If this deal is part of a RFP/RFQ/Public Tender, you must provide details on the transaction by attaching proof in the ‘attachments section’ or attach any additional documents needed for this deal.",
  DuplicateGridCustomerSelectErrorMessage ="Please select a customer",
  PartnerSearchCaseSubmissionText="Please select the MPN Partner Organization & associated country, for which you would like to submit this case. The organization you choose, will be the name displayed to all viewers of the case.",
  PartnerSearchCaseSubmissionNote="Note: If the 'MPN Partner Organization / Country' Field is not editable, this means that your partner organization only has one MPN Organization & Location ID. You may only use this location, to submit a case.",
  CreateUnmanagedCustomerGuidanceText="Please Note: The Customer Submittal will result in a longer case review period, as the new customer will be reviewed before the case undergoes standard review for Deal Registration and/or Special Pricing. If the customer submittal is approved, the customer will be attached to the case submission and will proceed to standard review for DR/SP.",
  CreateUnmanagedCustomerGuidanceTextOTLE="Please Note: \"Submitted Customers will be checked against existing records for duplicates. If duplicates are found, you will have the option to select the existing Customer details or continue with creating the new Customer record.\”",
  CreateUnmanagedDuplicateCustomerGuidanceText="The records below are potential duplicates of your customer submittal - please review the records below and select the record which matches your submittal. If no records match, you may still submit the new customer, for review. PLEASE NOTE - Selecting a pre-existing customer will prevent an extended case review period.  Your case submission will proceed directly to pricing & discount review.",
  CreateUnmanagedCustomerSavingText=" Submitted customer record is undergoing automated review, Please wait...",
  CreateUnmanagedCustomerSoftDuplicateText="Similar Customer details are available in the system, would you like to select the require customer from search result(s)?",
  CreateUnmanagedCustomerHardDuplicateText="Customer record already exists (Name, Address, City, State/Province, ZIP / Postal Code). Do you want to continue with new case submission with existing customer record?",
  CreateUnmanagedCustomerSuccessText="You may proceed to deal submission. However, the opportunity will be reviewed by Microsoft Business Desks only after the newly submitted customer review has been completed. Microsoft reserves the right to deny the opportunity submission if the submitted customer record is deemed not acceptable.",
  InternalUserSearchCaseSubmissionText = "For this case submission, please confirm the Product Group for which this case will be submitted.",
  NoDealVTeamMessage = "No Deal V-Team Members were added to show",
}