import { OpportunityDealStatusCodes } from "./OpportunityDealStatusCodes";
import { OpportunityDealStatusNames } from "./OpportunityDealStatusNames";

/**
 * Label for Deal Status.
 * @readonly
 * @type {Map<number, string>}
 */
 export const DealStatusLabel: Map<number, string> = new Map<number, string>([
    [OpportunityDealStatusCodes.Draft, OpportunityDealStatusNames.Draft],
    [OpportunityDealStatusCodes.Approved, OpportunityDealStatusNames.Approved],
    [OpportunityDealStatusCodes.Denied, OpportunityDealStatusNames.Denied],
    [OpportunityDealStatusCodes.Unassigned, OpportunityDealStatusNames.DecisionPending],
    [OpportunityDealStatusCodes.Expired, OpportunityDealStatusNames.Expired],
    [OpportunityDealStatusCodes.DealPricingApprovedPendingChannelStatus, OpportunityDealStatusNames.DealPricingApprovedPendingChannel],
    [OpportunityDealStatusCodes.ForcedExpire, OpportunityDealStatusNames.ForcedExpire],
    [OpportunityDealStatusCodes.InProgress, OpportunityDealStatusNames.DecisionPending],
    [OpportunityDealStatusCodes.NeedMoreInfo, OpportunityDealStatusNames.NeedMoreInfo],
    [OpportunityDealStatusCodes.Incomplete, OpportunityDealStatusNames.Incomplete],
    [OpportunityDealStatusCodes.DraftRenew, OpportunityDealStatusNames.DraftRenew],
    [OpportunityDealStatusCodes.PendingSecondaryReview, OpportunityDealStatusNames.PendingSecondaryReview]
  ]);