/* tslint:disable:no-bitwise */

/**
 * Contains methods to help work with GUIDs.
 * @class
 */
export class Guid {

    /**
     * Generates new Guid.
     * @method
     * @return {string} A Guid.
     */
    public static newGuid(): string {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
            let r = Math.random() * 16 | 0;
            let v = c === "x" ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    /**
     * Generates empty Guid.
     * @method
     * @return {string} A default guid.
     */
     public static emptyGuid(): string {
        return "00000000-0000-0000-0000-000000000000"
        }
}