import React, { useEffect, useState } from 'react';
import _ from "lodash";
import { PartnerType } from '../../../../models/PartnerType';
import { IAccount } from '../../../../models/IAccount';
import { ArrayExtensions } from '../../../../infrastructure/ArrayExtensions';
import { Label, Stack, TextField } from 'office-ui-fabric-react';
import { UIControlsText } from '../../../../content/UIControlsText';
import { ChannelStructure } from '../../../../models/ChannelStructure';
import { defaultColumnProps, DefaultPageStyles } from '../../../../content/styles/Page.styles';
import { PageStyles } from '../content/Page.Styles';
import { AccountAllType } from '../../../../models/AccountAllType';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { PartnerTypeLabel } from '../../../../models/PartnerTypeLabel';
import { ErrorMessages } from '../../../../content/ErrorMessages';
import { getChannelContactAction, isFieldDisabled, isValidEmail, getRuleValidationMessage, getEntityDataFromSession, getChannelContactEmailFromSavedContext, getChannelContactIsComplete } from '../../../../components/shared/common/util';
import { IBaseProps } from '../props/IBaseProps';
import { IOpportunityChannelContact } from '../../../../models/IOpportunityChannelContact';
import { Guid } from '../../../../infrastructure/Guid';
import { JavaScriptExtensions } from '../../../../infrastructure/JavaScriptExtensions';
import { CaseActionTypes } from '../reducer/CaseActionTypes';
import { PageMode } from '../../../../models/PageMode';
import { ActionType } from '../../../../models/ActionType';
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { PageConstants } from '../../../pageconstants/Constants';

/**
 * Channel Contacts Component.
 * @function component
 */
const ChannelContacts: React.FunctionComponent<IBaseProps> = (props: IBaseProps) => {
    const { pageContext, pageDispatch } = props;
    const [channelContacts, setChannelContacts] = useState<IOpportunityChannelContact[]>(pageContext?.opportunityChannelContacts || []);
    const [distributors] = useState<IAccount[]>(JSON.parse(sessionStorage.getItem(SessionStorageConsts.distributors) || '{}'));
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    //this method is to hide required field error message on view mode
    const isRequired = (): boolean => {
        return pageContext?.pageMode?.toLowerCase() !== PageMode.View.toLowerCase()
    }

    //Common method for sorting the Channel Contacts
    const sortContacts = (updatedContacts: IOpportunityChannelContact[]): IOpportunityChannelContact[] => {
        return _.sortBy(updatedContacts, x => [x.action, x.partnerType, x.countryName, x.companyName?.toLowerCase()]);
    }

    useEffect(() => {
        let oppAccounts: IAccount[] = [];
        if (pageContext?.opportunityDeals[0].channelStructure === ChannelStructure.Customer) {
            if (pageContext?.opportunityAccounts && pageContext?.opportunityAccounts.length > 0) {
                pageContext.opportunityAccounts
                    .forEach(x => {
                        let countryname = props.countries.find(c => c.countryId === x.countryId)?.countryName;
                        let accounts = x.accounts && x.accounts.length > 0 && x.accounts.filter((y: any) => y.partnerType === PartnerType.ADR).map((a: any) => { return { ...a, countryName: countryname } });
                        if (accounts && accounts.length) {
                            oppAccounts = ArrayExtensions.mergeArrays(oppAccounts, accounts);
                        }
                    });

                pageContext.opportunityAccounts.filter(x => x.partnerType === PartnerType.DMP)
                    .forEach(x => {
                        if (x.partnerAccounts && !_.isEmpty(x.partnerAccounts)) {
                            for (let key in x.partnerAccounts) {
                                const accounts = x.partnerAccounts[key];
                                const addAccounts = distributors.filter(dis => accounts.map((a: any) => a.accountId).includes(dis.accountId));
                                if (accounts && accounts.length) {
                                    oppAccounts = ArrayExtensions.mergeArrays(oppAccounts, addAccounts);
                                }
                                else if (x.accounts) {
                                    let dmpAccounts = x.accounts.filter(x => x.accountId === key);
                                    if (dmpAccounts && dmpAccounts.length) {
                                        oppAccounts = ArrayExtensions.mergeArrays(oppAccounts, dmpAccounts);
                                    }
                                }
                            }
                        }
                        else {
                            if (x.accounts && x.accounts.length) {
                                oppAccounts = ArrayExtensions.mergeArrays(oppAccounts, x.accounts);
                            }
                        }
                    });
            }
        }

        if (pageContext?.opportunityDeals[0].channelStructure === ChannelStructure.Distributor) {
            if (pageContext?.opportunityAccounts && pageContext?.opportunityAccounts.length > 0) {
                let addAccounts = pageContext.opportunityAccounts.filter(x => x.partnerType === PartnerType.ADD);

                let selectedAdds = addAccounts.filter(x => x.isAllSelected === AccountAllType.None);
                selectedAdds.length && selectedAdds.forEach(x => {
                    let accounts = x.accounts && x.accounts.length > 0 && x.accounts.filter((y: any) => y.partnerType === PartnerType.ADD);
                    if (accounts && accounts.length) {
                        oppAccounts = ArrayExtensions.mergeArrays(oppAccounts, accounts);
                    }
                });

                let allAdds = addAccounts.filter(x => x.isAllSelected === AccountAllType.All);
                allAdds.length > 0 && allAdds.forEach(x => {
                    let accounts = distributors.filter(y => y.applicablePriceListGeo === x.applicablePriceListGeo);
                    if (accounts && accounts.length) {
                        oppAccounts = ArrayExtensions.mergeArrays(oppAccounts, accounts);
                    }
                });

                let allEueftaAdds = addAccounts.filter(x => x.isAllSelected === AccountAllType.AllEuefta);
                allEueftaAdds.length > 0 && allEueftaAdds.forEach(x => {
                    let accounts = distributors.filter(y => y.isEueftaAccount);
                    if (accounts && accounts.length) {
                        oppAccounts = ArrayExtensions.mergeArrays(oppAccounts, accounts);
                    }
                });
            }
        }

        //Remove duplicates
        oppAccounts = _.uniqBy(oppAccounts, x => x.accountId);

        let updatedContacts = [...channelContacts];
        if (pageContext?.opportunityChannelContacts && pageContext.opportunityChannelContacts.length && pageContext.opportunityChannelContacts.some(c => JavaScriptExtensions.isEmptyOrNullOrUndefined(c.companyName))) {
            updatedContacts = pageContext.opportunityChannelContacts.map(c => {
                let account = oppAccounts.find(a => a.accountId === c.accountId);
                return { ...c, companyName: account?.companyName, countryName: account?.countryName, partnerType: account?.partnerType, action: getChannelContactAction(account?.accountId, pageContext?.opportunityDeals[0].statusCode), isComplete: JavaScriptExtensions.isEmptyOrNullOrUndefined(getEmailFieldErrorMessage(c.channelContactEmails || '')) }
            })
        }

        //Delete removed accounts
        updatedContacts = updatedContacts.filter(c => oppAccounts.map(o => o.accountId).includes(c.accountId || ""));
        //getChannelContactIsComplete is added for 9862010-Customer Channel Tab Validation Bug
        let newContacts = oppAccounts.filter(o => !updatedContacts.map(c => c.accountId).includes(o.accountId)).map(cc => { return { id: Guid.newGuid(), companyName: cc.companyName, accountId: cc.accountId, partnerType: cc.partnerType, countryName: cc.countryName, action: getChannelContactAction(cc.accountId, pageContext?.opportunityDeals[0].statusCode), isComplete: getChannelContactIsComplete(cc.accountId, pageContext?.opportunityDeals[0].statusCode) } as IOpportunityChannelContact });
        updatedContacts = ArrayExtensions.mergeArrays(updatedContacts, newContacts);
        updatedContacts = sortContacts(updatedContacts);

        const savedContext = pageContext?.opportunityChannelContacts;
        const accountIds = updatedContacts.map((item: any) => item.accountId);
        const filteredChannelContacts = savedContext?.filter((item: any) => accountIds.includes(item.accountId));
        //getChannelContactEmailFromSavedContext is added for 9862010-Customer Channel Tab Validation Bug
        const updatedChannelContacts = updatedContacts.map(item => {
            const currentAccounts: any = filteredChannelContacts?.filter((key: any) => key.accountId === item.accountId);
            const val = currentAccounts && currentAccounts.length > 0 ? currentAccounts[0].channelContactEmails : getChannelContactEmailFromSavedContext(item.accountId, pageContext?.opportunityDeals[0].statusCode);
            return {
                ...item,
                channelContactEmails: val,
            }
        });
        setChannelContacts(updatedChannelContacts);
        pageDispatch && pageDispatch({ type: CaseActionTypes.channelContactChanged, channelContacts: updatedChannelContacts });
    }, [pageContext?.opportunityAccounts]);

    /**
     * Get TextField ErrorMessage.
     * @method
     * @param {string} value Text Box value.
     * @@returns {string} Error Message.
     */
    const getEmailFieldErrorMessage = (value: string): string => {
        if (!value) {
            return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredFieldError", ErrorMessages.RequiredFieldError);
        }
        else {
            let isInvalid = value.split(';').some((email) => !isValidEmail(email));
            if (isInvalid) {
                return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidEmailError", ErrorMessages.ValidEmailError);
            }
        }
        return '';
    };

    /**
     * ChannelContact blur callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {accountId} accountId Account Id.
     */
    const onChannelContactBlur = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, accountId?: string): void => {
        let value = event.currentTarget.value.trim();

        //Remove trailing semicolon
        if (value.endsWith(';')) {
            value = value.substring(0, value.length - 1);
            updateChannelContact(accountId, value);
        }
    }

    /**
     * ChannelContact change callback function.
     * @method
     * @param {accountId} accountId Account Id.
     * @param {string} newValue updated value from textField.
     */
    const onChannelContactChange = (accountId?: string, newValue?: string): void => {
        updateChannelContact(accountId, newValue);
    }

    /**
     * Update ChannelContact state with updated channel contact
     * @param {accountId} accountId Account Id.
     * @param {string} newValue updated value.
     */
    function updateChannelContact(accountId?: string, newValue?: string) {
        let modifiedContact = channelContacts.find(c => c.accountId === accountId);
        if (modifiedContact) {
            modifiedContact!.channelContactEmails = newValue;
            modifiedContact.isComplete = JavaScriptExtensions.isEmptyOrNullOrUndefined(getEmailFieldErrorMessage(newValue || ''));

            let updatedContacts = [...channelContacts].filter(c => c.accountId !== accountId);
            updatedContacts.push(modifiedContact);
            updatedContacts = sortContacts(updatedContacts);
            setChannelContacts(updatedContacts);
            pageDispatch && pageDispatch({ type: CaseActionTypes.channelContactChanged, channelContacts: updatedContacts });
        }
    }

    return (
        <div data-nextgen-parent-group={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelContacts", UIControlsText.ChannelContacts)} data-nextgen-parentid={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelContacts", UIControlsText.ChannelContacts)}>
            <Stack>
                <Stack horizontal {...defaultColumnProps}>
                    <Label styles={DefaultPageStyles.customWidthStyles} >
                        {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerNameText", UIControlsText.PartnerNameText)}
                    </Label>
                    <Label styles={DefaultPageStyles.customWidthStyles}>
                        {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Country", UIControlsText.Country)}
                    </Label>
                    <Label styles={DefaultPageStyles.customWidth175Styles}>
                        {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerTypeText", UIControlsText.PartnerTypeText)}
                    </Label>
                    <Label>
                        {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerEmailContactsText", UIControlsText.PartnerEmailContactsText)}
                    </Label>
                </Stack>
                <Stack className='channelcontacts' {...defaultColumnProps}>
                    {channelContacts.map((k, i) => (
                        <Stack horizontal key={k.accountId} style={{ margin: 5 }}>
                            <Label styles={DefaultPageStyles.customWidthForStackLabels}>{k.companyName}</Label>
                            <Label styles={DefaultPageStyles.customWidthForStackLabels}>{k.countryName}</Label>
                            {k.partnerType && <Label styles={DefaultPageStyles.customWidthStyles}>{PartnerTypeLabel.get(k.partnerType)}</Label>}
                            <TextField
                                id={k.accountId}
                                ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelContacts", UIControlsText.ChannelContacts)}
                                placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelContactsPlaceHolderText", UIControlsText.ChannelContactsPlaceHolderText)}
                                styles={DefaultPageStyles.oneColumnWidthStyles}
                                style={pageContext?.errors?.includes(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredChannelContactError", ErrorMessages.RequiredChannelContactError)) && !JavaScriptExtensions.isEmptyOrNullOrUndefined(getEmailFieldErrorMessage(k.channelContactEmails || '')) ? PageStyles.missingFieldHighlight : {}}
                                validateOnFocusOut
                                multiline
                                validateOnLoad={false}
                                onGetErrorMessage={getEmailFieldErrorMessage}
                                onChange={(event, value) => {
                                    onChannelContactChange(k.accountId, value);
                                    return;
                                }}
                                onBlur={(event) => {
                                    onChannelContactBlur(event, k.accountId);
                                    return;
                                }}
                                value={k.channelContactEmails}
                                required={isRequired()}
                                disabled={isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelContacts", UIControlsText.ChannelContacts), pageContext?.pageMode, pageContext?.opportunityDeals[0]?.statusCode, undefined, undefined,  PageConstants.PropCPStrategictype) || (pageContext?.pageMode !== PageMode.View && k.action != ActionType.Add)}
                            />
                        </Stack>
                    ))}
                </Stack>
            </Stack>
        </div>
    )
}

export default ChannelContacts;