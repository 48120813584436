/**
 * Enum for OpportunityDealPortalEditStatusNames.
 * @readonly
 * @enum
 */
export enum OpportunityDealPortalEditStatusNames {
    /**
     * Review Complete Name.
     */
    ReviewComplete = "Review Complete",

    /**
     * Edit Under Review Name.
     */
    EditUnderReview = "Edit Under Review",

    /**
     * Approved Name.
     */
    Approved = "Approved",

    /**
     * Edit Pending Review Name.
     */
    EditPendingReview = "Edit Pending Review",
}
