/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { UIControlsText } from "../../../content/UIControlsText";
import { IDealState } from "../common/state/IDealState";
import { DealDetailsStyles, PageStyles } from "../common/content/Page.Styles";
import { Label, Stack, StackItem, TextField } from "@fluentui/react";
import { JavaScriptExtensions } from "../../../infrastructure/JavaScriptExtensions";
import { StatePropertiesText } from "../common/state/StatePropertiesText";
import { ErrorMessages } from "../../../content/ErrorMessages";
import { PageMode } from "../../../models/PageMode";
import { OpportunityDealStatusCodes } from "../../../models/OpportunityDealStatusCodes";
import { DealType } from "../../../models/DealType";
import { getDealQuantityThreshold } from "./PricingProgramFormUtils";
import { IDealType } from "../../../models/IDealType";
import { CssClassConstants } from "../../../content/CssClassConstants";
import { getDealDurationConfig, getEntityDataFromSession, getRuleValidationMessage, isNullOrEmpty } from '../../../components/shared/common/util';
import { MessageTypeText } from '../../../models/MessageTypeText';
import { SessionStorageConsts } from "../../../models/SessionStorageConsts";

export const CommittedDealVolume: React.FunctionComponent<any> = (props: any) => {
    const optyDeal = props?.opportunityDeals.find((t: IDealState) => t.name === props?.selectedOptyDealCaseNumber);
    const [dealTypeNames, setdealTypeNames] = React.useState<string>("");
    const [dealDurationInMonths, setDealDurationInMonths] = React.useState<number>(0);
    const [dealQuantityThreshold, setDealQuantityThreshold] = React.useState<number>(0);
    const [isFieldDisabled, setIsFieldDisabled] = React.useState<boolean>(false);
    const [selectedOptyDeal, setSelectedOptyDeal] = React.useState<IDealState>();
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    
    useEffect(() => {
        const dealData = props?.opportunityDeals.find((t: IDealState) => t.statusCode !== OpportunityDealStatusCodes.Draft);
        if(!JavaScriptExtensions.isNullOrUndfinedOrEmpty(dealData)) { 
            setSelectedOptyDeal(optyDeal);
        }
    }, []);

    useEffect(() => {
        setdealTypeNames(getDealTypeNames());
        setDealDurationInMonths(getDealDurationInMonths());
        setDealQuantityThreshold(getDealQuantityThreshold(props?.opportunityDeals, props?.partnerDetails?.productGroupName, props?.selectedOptyDealCaseNumber, props?.pageMode));
        setIsFieldDisabled(isDealVolumeDisabled());
    }, [selectedOptyDeal]);
    
    /**
    * Get deal type names by dealTypeId.
    * @method
    * @@returns {string} deal type names.
    */
    const getDealTypeNames = (): string => {
        let dealTypeNames : string = '';
        if(!JavaScriptExtensions.isNullOrUndfinedOrEmpty(selectedOptyDeal)) {
            dealTypeNames = selectedOptyDeal?.dealType?.name || "";
        }
        else {
            props?.opportunityDeals?.forEach((optyDeal : IDealState) => {
                if(dealTypeNames === '') {
                    dealTypeNames = optyDeal?.dealType?.name || "";
                }
                else {
                    dealTypeNames = dealTypeNames + ' and ' + optyDeal?.dealType?.name || "";
                }
            });
        }

        return dealTypeNames;
    }

    const getDealDurationInMonths = () : number => {
        let dealDuration : number = -1;
        
        if(!JavaScriptExtensions.isNullOrUndfinedOrEmpty(selectedOptyDeal)) {
            let dealDurationConfig = getDealDurationConfig(selectedOptyDeal?.dealType?.name || "", selectedOptyDeal?.customerCountry)
            if (!isNullOrEmpty(dealDurationConfig)) {
                dealDuration = dealDurationConfig?.defaultDurationInMonths || 0;
            }
        }
        else {
            props?.opportunityDeals?.forEach((optyDeal : IDealState) => {
                let dealDurationConfig = getDealDurationConfig(optyDeal?.dealType?.name || "", optyDeal?.customerCountry)
                if((dealDuration > (dealDurationConfig?.defaultDurationInMonths || 0)) || dealDuration === -1) {
                    dealDuration = dealDurationConfig?.defaultDurationInMonths || 0;
                }
            });
        }

        return dealDuration;
    }

    const isDealVolumeDisabled = () : boolean => {
        switch (props?.pageMode) {
            case PageMode.Create: {
                return false;
            }
            case PageMode.Edit: {
                if (selectedOptyDeal && ((selectedOptyDeal.statusCode === OpportunityDealStatusCodes.Approved &&
                    selectedOptyDeal?.dealType?.name === DealType.DealRegistration) || selectedOptyDeal.statusCode === OpportunityDealStatusCodes.Unassigned || selectedOptyDeal.statusCode === OpportunityDealStatusCodes.PendingSecondaryReview)) {
                    return true;
                }

                return false;
            }
            case PageMode.View: {
                return true;
            }
            default: {
                return false;
            }
        }
    }

    /**
     * Get TextField ErrorMessage.
     * @method
     * @param {string>} value Text Box value.
     * @@returns {string} Error Message.
     */
    const getTextFieldErrorMessageForQuantity = (value: string): string => {
        let message = ''
        if (!value) {
            message = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoCommittedBlankDealVolume", ErrorMessages.DealInfoCommittedBlankDealVolume);
        }
        else if (parseInt(value) === 0) {
            message = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoCommittedZeroDealVolume", ErrorMessages.DealInfoCommittedZeroDealVolume);
        }
        else if (parseInt(value) < dealQuantityThreshold && dealQuantityThreshold !== -1) {
            message = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoCommittedMinimumThreshold", ErrorMessages.DealInfoCommittedMinimumThreshold);
        }

        props.setErrorsDataIntoContext && props.setErrorsDataIntoContext(message, StatePropertiesText.DealVolumeText);
        return message;
    };

    return (
        <>
            <Stack horizontal>
                <StackItem style={DealDetailsStyles.dealVolumeWidth}>
                    <Label>
                        {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CommittedDealVolumeText", UIControlsText.CommittedDealVolumeText)}
                    </Label>
                </StackItem>
                <StackItem>
                    <Label>
                        {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "QuantityText", UIControlsText.QuantityText)}
                    </Label>
                </StackItem>
            </Stack>
            <Stack horizontal>
                <StackItem style={DealDetailsStyles.dealVolumeWidthPaddingTop}>
                    <Label style={DealDetailsStyles.labelFontStyle} >
                        {JavaScriptExtensions.stringFormat(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVolumeBasedOnDealTypes", UIControlsText.DealVolumeBasedOnDealTypes), dealTypeNames, dealDurationInMonths.toString())}
                    </Label>
                </StackItem>
                <StackItem style={DealDetailsStyles.dealVolumeWidth} id="StackItemDealVolume">
                    {!isFieldDisabled &&
                        <TextField
                            ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVolume", UIControlsText.DealVolume)}
                            styles={PageStyles.textFieldStylesWidth92}
                            validateOnFocusOut
                            validateOnLoad={false}
                            //type="number"
                            min={0}
                            maxLength={10}
                            onGetErrorMessage={getTextFieldErrorMessageForQuantity}
                            onChange={props?.handleDealQuantityChanges && props?.handleDealQuantityChanges(StatePropertiesText.DealVolumeText, JavaScriptExtensions.isNullOrUndfinedOrEmpty(selectedOptyDeal) ? undefined : selectedOptyDeal?.id)}
                            value= {JavaScriptExtensions.isNullOrUndfinedOrEmpty(selectedOptyDeal) ? props?.opportunityDeals[0]?.dealVolume?.toString() : selectedOptyDeal?.dealVolume?.toString()}
                            readOnly={isFieldDisabled}
                            className={isFieldDisabled ? CssClassConstants.backgroundColorWhite: ''}
                            required={ props?.pageMode === PageMode.View ? false : true}
                            errorMessage = {props?.pivotTabContext?.errors?.get(StatePropertiesText.DealVolumeText)}
                        />
                    }
                    {isFieldDisabled &&
                        <Label style={DealDetailsStyles.labelFontStyle}>
                             {JavaScriptExtensions.isNullOrUndfinedOrEmpty(selectedOptyDeal) ? props?.opportunityDeals[0]?.dealVolume?.toString() : selectedOptyDeal?.dealVolume?.toString()}
                        </Label>
                    }

                </StackItem>
            </Stack>
            {(props?.pageMode !== PageMode.Create && !JavaScriptExtensions.isNullOrUndfinedOrEmpty(selectedOptyDeal)) &&
                <Stack horizontal>
                    <StackItem style={DealDetailsStyles.dealVolumeWidthPaddingTop}>
                        <Label style={DealDetailsStyles.labelFontStyle} >
                            {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealInfoUnitsTransactedText", UIControlsText.DealInfoUnitsTransactedText)}
                        </Label>
                    </StackItem>
                    <StackItem style={DealDetailsStyles.dealVolumeWidth} >
                        <Label style={DealDetailsStyles.labelFontStyle}>
                            {selectedOptyDeal?.unitsTransacted?.toString()}
                        </Label>
                    </StackItem>
                </Stack>
            }
            <Stack>
                <StackItem style={DealDetailsStyles.dealVolumeWidth} >
                        <Label></Label>
                        <Label></Label>
                </StackItem>
            </Stack>
        </>
    );
};