/**
 * Enum for Contact Roles.
 * @readonly
 * @enum {Enum}
 */

 export enum ContactRoles {
    /**
    * Account Executive.
    */
    AccountExecutive = 1,
    /**
    * Account Technology Strategist.
    */
    AccountTechnologyStrategist = 2,
    /**
    * M365 Devices GBB.
    */
    M365DevicesGBB = 3,
    /**
    * Surface BG Lead.
    */
    SurfaceBGLead = 4,
    /**
    * Surface CE.
    */
    SurfaceCE = 5,
    /**
    * Surface CSM.
    */
    SurfaceCSM= 6,
    /**
    * Surface Specialist.
    */
    SurfaceSpecialist = 7,
    /**
    * Surface PCMM.
    */
    SurfacePCMM = 8,
    /**
    * Surface PDM .
    */
    SurfacePDM = 9,
    /**
    * Surface PDM Lead.
    */
    SurfacePDMLead = 10,
    /**
    * Surface PMM.
    */
    SurfacePMM = 11,
    /**
    * Others.
    */
    Others = 12,
};