import { Label, MessageBar, Stack } from "@fluentui/react";
import React from "react";
import { IComponentDataItem } from "../Components/DynamicFormRendering";

export function GuidanceTextInformation(props: IComponentDataItem) {
    const [showMessageBar, setShowMessageBar] = React.useState(true);
    const toggleMessageBar = (): (() => void) => (): void => setShowMessageBar(!showMessageBar);

    return (
        <Stack>
            {showMessageBar &&
                <MessageBar role="none" onDismiss={toggleMessageBar()} messageBarIconProps={{ iconName: 'info' }} dismissButtonAriaLabel="Close"
                    overflowButtonAriaLabel="See more" className="mb-1 mt-1">
                    {props?.messageHeader &&
                        <h6> {props?.messageHeader} </h6>
                    }
                    <span key={props.id}> {props?.label} </span>
                </MessageBar>
            }
        </Stack>
    );
}