import { Checkbox, ChoiceGroup, Dropdown, IChoiceGroupOption, IDropdownOption, Link, mergeStyles, PanelType, Stack } from "office-ui-fabric-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { CssClassConstants } from "../../../../content/CssClassConstants";
import { CommonStyles, defaultColumnProps, DefaultPageStyles } from "../../../../content/styles/Page.styles";
import { UIControlsText } from "../../../../content/UIControlsText";
import { JavaScriptExtensions } from "../../../../infrastructure/JavaScriptExtensions";
import { AccountAllType } from "../../../../models/AccountAllType";
import { ActionType } from "../../../../models/ActionType";
import { IAccount } from "../../../../models/IAccount";
import { ICountry } from "../../../../models/ICountry";
import { IOpportunityAccount } from "../../../../models/IOpportunityAccount";
import { PartnerType } from "../../../../models/PartnerType";
import { IAccountBaseProps } from "../../../../components/shared/props/IAccountBaseProps";
import { EntityLookup } from "../../common/components/EntityLookup";
import { IProductType } from "../../../../models/IProductType";
import { getAction, getParentAccountAction, hideResellerSectionsInViewModeForReseller, isEditableAccount, isFieldVisible, getRuleValidationMessage, getEntityDataFromSession } from "../../../../components/shared/common/util";
import { getURLParamValue, isMSStoreDisable } from "../../../../components/shared/common/util";
import { isRoleBasedFilterRequired } from "../../../../components/shared/common/util";
import { useInternalPricingContext } from '../context/internalpricing-context';
import { PageMode } from "../../../../models/PageMode";
import { useHistory } from "react-router";
import { ApplicationConstants } from "../../../../models/ApplicationConstants";
import { Guid } from "../../../../infrastructure/Guid";
import _ from "lodash";
import { SessionStorageConsts } from "../../../../models/SessionStorageConsts";
import { UserSubRole } from "../../../../models/UserSubRole";
import { partnerTypeValues } from "../../../../services/StaticData";
import { IEntityLookupColumnConfig } from "../../../../models/IEntityLookupColumnConfig";
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { PageConstants } from "../../../pageconstants/Constants";
import { OpportunityDealStatusCodes } from "../../../../models/OpportunityDealStatusCodes";
/**
 * Channel Accounts component.
 * @function component
 */
const ChannelAccounts: React.FunctionComponent<IAccountBaseProps> = (props: IAccountBaseProps) => {
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
  let history = useHistory();
  const { internalPricingContextState } = useInternalPricingContext();
  const [allResellerSelected, setallResellerSelected] = useState<boolean>(false)
  const [allResellerEUSelected, setallResellerEUSelected] = useState<boolean>(false)
  const [customerCountry] = useState<ICountry | undefined>(props?.customerCountry);
  const [selectedResellers, setSelectedResellers] = useState<Array<IAccount>>(
    props?.opportunityAccounts?.find(opp => (opp.partnerType === PartnerType.Reseller || opp.partnerType === PartnerType.ADR || opp.partnerType === PartnerType.DMP) && opp.isAllSelected == AccountAllType.None)?.accounts
    || []);
  const [selectedDistributors, setSelectedDistributors] = useState<Array<IAccount>>(
    props?.opportunityAccounts?.find(opp => opp.partnerType === PartnerType.ADD && opp.isAllSelected == AccountAllType.None)?.accounts
    || []);
  const [distributorOption, setDistributorOption] = useState<string>(
    (props?.opportunityAccounts?.find(opp => opp.partnerType === PartnerType.ADD) && props?.opportunityAccounts?.find(opp => opp.partnerType === PartnerType.ADD)?.isAllSelected?.toString())
    || (props?.customerCountry && props?.customerCountry?.isEUEFTA ? '2' : '1')
    || '1');
  const [resellerOption, setResellerOption] = useState<string>(
    (props?.opportunityAccounts?.find(opp => opp.partnerType === PartnerType.Reseller || opp.partnerType === PartnerType.ADR || opp.partnerType === PartnerType.DMP) && props?.opportunityAccounts?.find(opp => opp.partnerType === PartnerType.Reseller || opp.partnerType === PartnerType.ADR || opp.partnerType === PartnerType.DMP)?.isAllSelected?.toString())
    || (props?.customerCountry && props?.customerCountry?.isEUEFTA ? '2' : '1')
    || '1');
  const [isMsStoreChecked, setIsMsStoreChecked] = useState(
    !JavaScriptExtensions.isNullOrUndfinedOrEmpty(props?.opportunityAccounts?.find(opp => opp.partnerType === PartnerType.MsStore))
    || false);
  const onMsStoreCheckBoxChange = useCallback(
    (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
      setIsMsStoreChecked(!!checked);
    },
    [],
  );
  const [productType, setProductType] = useState<IProductType | undefined>(props.productType?.find(x => x.productId === internalPricingContextState.productGroup));
  const userRole = sessionStorage.getItem(SessionStorageConsts.userRole);
  const resellers = useRef<IAccount[]>([]);
  if (props?.resellerAccounts && props?.resellerAccounts.length > 0) {
    if (internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase()) {
      resellers.current = props?.resellerAccounts.filter(resellerAccount => selectedResellers.find(({ accountId }) => resellerAccount.accountId === accountId));
    }
    else {
      resellers.current = props?.resellerAccounts;
    }
  }
  if (resellers.current && resellers.current.length > 0) {
    if (customerCountry) {
      if (customerCountry.isEUEFTA) {
        resellers.current = resellers.current.filter((x: IAccount) => x.countryId === customerCountry.countryId
          || (x.isEueftaAccount));
      }
      else {
        resellers.current = resellers.current.filter((x: IAccount) => x.applicablePriceListGeo === customerCountry.applicablePriceListGeo);
      }
    }

  }
  var distributors: IAccount[] = [];
  if (props?.distiAccounts && props?.distiAccounts.length > 0) {
    if (internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase()) {
      distributors = props?.distiAccounts.filter(distiAccount => selectedDistributors.find(({ accountId }) => distiAccount.accountId === accountId));
    }
    else {
      distributors = props?.distiAccounts;
    }
  }
  //var distributors = props?.distiAccounts;
  if (distributors && distributors.length > 0) {
    if (customerCountry) {
      if (customerCountry.isEUEFTA) {
        distributors = distributors.filter((x: IAccount) => x.countryId === customerCountry.countryId
          || (x.isEueftaAccount));
      }
      else {
        distributors = distributors.filter((x: IAccount) => x.applicablePriceListGeo === customerCountry.applicablePriceListGeo);
      }
    }

    if (productType) {
      distributors = distributors.filter((x: IAccount) => x.productTypeId === productType.productId);
    }
  }

  useEffect(() => {
    setProductType(props.productType?.find(x => x.productId === internalPricingContextState.productGroup));
  }, [props.productType, internalPricingContextState.productGroup]);

  useEffect(() => {
    let resellerSelectionComplete: boolean = true;
    let distributorSelectionComplete: boolean = true;
    if (!customerCountry || (distributorOption === AccountAllType.None.toString() && selectedDistributors.length === 0)) {
      distributorSelectionComplete = false;
    }

    if (!customerCountry || (resellerOption === AccountAllType.None.toString() && selectedResellers.length === 0)) {
      resellerSelectionComplete = false;
    }

    let distiSelection = props && props.opportunityAccounts?.find(x => x.partnerType === PartnerType.ADD) as IOpportunityAccount;

    if (distiSelection) {
      distiSelection.accounts = selectedDistributors;
      distiSelection.isAllSelected = parseInt(distributorOption) as AccountAllType;
      distiSelection.isComplete = distributorSelectionComplete;
    }

    let resellerSelection = props && props.opportunityAccounts?.find(x => x.partnerType === PartnerType.Reseller) as IOpportunityAccount;

    if (resellerSelection) {
      resellerSelection.accounts = selectedResellers;
      resellerSelection.isAllSelected = parseInt(resellerOption) as AccountAllType;
      resellerSelection.isComplete = resellerSelectionComplete;

      if (resellerSelection.isAllSelected === 1
        && internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase() && userRole === UserSubRole.ADD) {
        setallResellerSelected(true)
      }
      if (resellerSelection.isAllSelected === 2
        && internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase() && userRole === UserSubRole.ADD) {
        setallResellerEUSelected(true)
      }
    }
    props && props.updateAccounts && props.updateAccounts([distiSelection, resellerSelection], ActionType.Update);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResellers, selectedDistributors, distributorOption, resellerOption]);

  useEffect(() => {
    let msStoreAccount = props && props.opportunityAccounts?.find(x => x.partnerType === PartnerType.MsStore) as IOpportunityAccount;

    if (!msStoreAccount) {
      msStoreAccount = { id: Guid.newGuid(), countryId: customerCountry?.countryId, partnerType: PartnerType.MsStore, };
    }

    msStoreAccount = {
      ...msStoreAccount,
      isAllSelected: AccountAllType.None,
      isComplete: isMsStoreChecked,
      action: getParentAccountAction(customerCountry?.countryId, PartnerType.MsStore)
    };


    props && props.updateAccounts && props.updateAccounts([msStoreAccount], ActionType.Update);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMsStoreChecked]);

  /**
   * A callback for receiving a notification when the distributor choice has been changed.
   */
  const onDistributorChoiceGroupChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
    if (option && option.key) {
      setDistributorOption(option.key);
      if (option.key !== '3') {
        setSelectedDistributors([]);
      }
    }
  }

  /**
   * A callback for receiving a notification when the Reseller choice has been changed.
   */
  const onResellerChoiceGroupChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
    if (option && option.key) {
      setResellerOption(option.key);
      if (option.key !== '3') {
        setSelectedResellers([]);
      }
    }
  }

  const updateLookupList = (itemsSelected: any[], itemsList: IAccount[]) => {
    const updatedItemsList = itemsList && itemsList.map(item => {
      return {
        ...item,
        key: `${ item.accountId }-${ item.partnerType }`,
      };
    });
    let selectedItems: IAccount[] = [];
    itemsSelected.forEach(x => {
      let list: any;
      list = updatedItemsList?.find(y => y.key === x.key);
      if (list) {
        list = _.omit(list, 'key');
        selectedItems.push(list);
      }
    });
    return selectedItems;
  };

  //callback method to add new ADDs from entity lookup
  const onAddSelectionUpdate = (selectedItems: any[]): void => {
    if (selectedItems) {
      let selectedAdds = distributors?.length ? updateLookupList(selectedItems, distributors) : [];
      selectedAdds = selectedAdds.map(x => { return { ...x, action: getAction(x, customerCountry?.countryId, PartnerType.ADD) } });
      setSelectedDistributors(selectedAdds);
    }
  }

  const onResellerSelectionUpdate = (selectedItems: any[]): void => {
    if (selectedItems) {
      let updatedResellers = resellers.current?.length ? updateLookupList(selectedItems, resellers.current) : [];
      updatedResellers = updatedResellers.map(rs => { return { ...rs, action: getAction(rs, customerCountry?.countryId, PartnerType.Reseller) } })
      setSelectedResellers(updatedResellers);
    }
  }

  const optionRootClass = mergeStyles({ display: 'flex', alignItems: 'baseline' });
  const distiOptions: IChoiceGroupOption[] = [
      { key: '1', text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AllAddsText", UIControlsText.AllAddsText), },
      { key: '2', text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AllAddsEuText", UIControlsText.AllAddsEuText) },
    {
      key: '3',
      text: '',
      onRenderField: (props, render) => {
        const columnDefinitions: IEntityLookupColumnConfig[] = [
          {
            apiField: 'name',
            key: 'name',
                columnTitle: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddNameText", UIControlsText.AddNameText),
            displayOrder: 2

          }, {
            apiField: 'country',
            key: 'country',
                columnTitle: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CountryFacetText", UIControlsText.CountryFacetText),
            displayOrder: 3
          }, {
            apiField: 'data',
            key: 'data',
                columnTitle: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerTypeText", UIControlsText.PartnerTypeText),
            displayOrder: 4,
            isVisible: true
          }]
        return (
          <div className={optionRootClass}>
            {render!(props)}
            <label>
              <EntityLookup
                        headerText={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddLookupText", UIControlsText.AddLookupText)}
                subHeaderText={`${ productType && productType.productName } - ${ customerCountry?.countryName }`}
                items={(distributors && distributors.map((pt: IAccount) => ({
                  key: `${ pt.accountId }-${ pt.partnerType }`, accountId: pt.accountId, name: pt.companyName, data: partnerTypeValues[`${ pt.partnerType }`], partnerType: pt.partnerType, country: pt.countryName,
                  editMode: internalPricingContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.PendingSecondaryReview || internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase()
                }))) || []}
                lookUpTextFieldProps={{
                    placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "AddNamePlaceHolderText", UIControlsText.AddNamePlaceHolderText),
                    title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "AddNamePlaceHolderText", UIControlsText.AddNamePlaceHolderText),
                  disabled: props ? !props.checked : false,
                  required: props ? props.checked : false,
                  style: CommonStyles.entityLookupWidth,
                  value: (selectedDistributors && selectedDistributors.length) ?
                                                      selectedDistributors.length > 1 ? selectedDistributors.length + ' ADDs Selected'
                                                                                      : selectedDistributors.length + ' ADD Selected'
                                                                                : ''
                }}
                isCountrySelected={true}
                columnProps={columnDefinitions}
                itemSelectionUpdate={onAddSelectionUpdate}
                selectedItems={selectedDistributors && selectedDistributors.map(x => ({
                  key: `${ x.accountId }-${ x.partnerType }`, accountId: x.accountId, name: x.companyName, data: partnerTypeValues[`${ x.partnerType }`], partnerType: x.partnerType, country: x.countryName,
                  editMode: isEditableAccount(customerCountry?.countryId, internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, x, PartnerType.ADD)
                } as any))}
                disableSelectAllSelection={internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase()}
                countryId={customerCountry?.countryId}
                PageMode={internalPricingContextState.pageMode}
                partnerType={PartnerType.ADD}
                statusCode={internalPricingContextState.opportunityDeals[0]?.statusCode}
              />
            </label>
          </div>
        );
      },
    },
  ];
  const viewResellers = () => {
    var isEUEFTA: any;
    var country: any;
    if (allResellerEUSelected === true) {
      isEUEFTA = true;

    } else {
      country = customerCountry?.countryName
    }
    history.push({
      pathname: '/viewresellers',
      search: '?' + ApplicationConstants.OpportunityNumberParamName + "=" + getURLParamValue(ApplicationConstants.OpportunityNumberParamName),
      state: { countryName: { country }, isEUEFTA: { isEUEFTA } }
    });
  }
  const resellerOptions: IChoiceGroupOption[] = [
    {
          key: '1', text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AllResellersText", UIControlsText.AllResellersText),
      onRenderLabel: () => {
        return (
          <div>
                <label style={{ marginLeft: "28px" }}>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AllResellersText", UIControlsText.AllResellersText)}</label>
            {allResellerSelected === true ? (
                    <Link underline className="ps-2" onClick={viewResellers}><i>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ViewReseller", UIControlsText.ViewReseller)}</i></Link>
            ) : ""}

          </div>
        );
      },
    },
    {
        key: '2', text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AllResellersEuText", UIControlsText.AllResellersEuText),
      onRenderLabel: () => {
        return (
          <div>
                <label style={{ marginLeft: "28px" }}>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AllResellersEuText", UIControlsText.AllResellersEuText)}</label>
            {allResellerEUSelected === true ? (
                    <Link underline className="ps-2" onClick={viewResellers}><i>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ViewReseller", UIControlsText.ViewReseller)}</i></Link>
            ) : ""}

          </div>
        );
      },
    },
    {
      key: '3',
      text: '',
      onRenderField: (props, render) => {
        const columnDefinitions: IEntityLookupColumnConfig[] = [{
          apiField: 'name',
          key: 'name',
            columnTitle: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameText", UIControlsText.ResellerNameText),
          displayOrder: 2
        }, {
          apiField: 'country',
          key: 'country',
            columnTitle: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CountryFacetText", UIControlsText.CountryFacetText),
          displayOrder: 3
        }, {
          apiField: 'organizationId',
          key: 'organizationId',
            columnTitle: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MpnIdText", UIControlsText.MpnIdText),
          displayOrder: 4
        }, {
          apiField: 'data',
          key: 'data',
            columnTitle: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerTypeText", UIControlsText.PartnerTypeText),
          displayOrder: 5,
          isVisible: true
        }]
        return (
          <div className={optionRootClass}>
            {render!(props)}
            <label>
              <EntityLookup
                        headerText={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerLookupText", UIControlsText.ResellerLookupText)}
                subHeaderText={`${ productType && productType.productName } - ${ customerCountry?.countryName }`}
                items={(resellers && resellers.current.map((pt: IAccount) => ({
                  key: `${ pt.accountId }-${ pt.partnerType }`, accountId: pt.accountId, name: pt.companyName, data: partnerTypeValues[`${ pt.partnerType }`], partnerType: pt.partnerType, country: pt.countryName, organizationId: pt.organizationId,
                  editMode: internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase()
                }))) || []}
                lookUpTextFieldProps={{
                    placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNamePlaceholderText", UIControlsText.ResellerNamePlaceholderText),
                    title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNamePlaceholderText", UIControlsText.ResellerNamePlaceholderText),
                  disabled: props ? !props.checked : false,
                  required: props ? props.checked : false,
                  style: CommonStyles.entityLookupWidth,
                  value: (selectedResellers && selectedResellers.length) ?
                                                    selectedResellers.length > 1 ? selectedResellers.length + ' Resellers Selected'
                                                                                  : selectedResellers.length + ' Reseller Selected'
                                                                          : ''
                }}
                isCountrySelected={true}
                columnProps={columnDefinitions}
                itemSelectionUpdate={onResellerSelectionUpdate}
                selectedItems={selectedResellers && selectedResellers.map(x => ({
                  key: `${ x.accountId }-${ x.partnerType }`, accountId: x.accountId, name: x.companyName, data: partnerTypeValues[`${ x.partnerType }`], partnerType: x.partnerType, country: x.countryName,
                  editMode: isEditableAccount(customerCountry?.countryId, internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, x, PartnerType.Reseller)
                } as any))}
                disableSelectAllSelection={internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase()}
                countryId={customerCountry?.countryId}
                PageMode={internalPricingContextState.pageMode}
                partnerType={PartnerType.Reseller}
                statusCode={internalPricingContextState.opportunityDeals[0]?.statusCode}
              />
            </label>
          </div>
        );
      },
    },
  ];
  return (
    <div className={CssClassConstants.ChannelAccounts}>
      <Stack horizontal {...defaultColumnProps} id={props?.id} style={{ marginTop: "15px" }}>
        <Dropdown
                  aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LegalEntityCountry", UIControlsText.LegalEntityCountry)}
          options={(props?.countries.map((pt: ICountry) => ({ key: pt.countryId, text: pt.countryName } as IDropdownOption))) || []}
          defaultSelectedKey={customerCountry?.countryId}
          disabled={JavaScriptExtensions.isDefined(props?.customerCountry)}
          styles={DefaultPageStyles.customWidthStyles}
          style={JavaScriptExtensions.isDefined(props?.customerCountry) ? CommonStyles.blackBorder : {}}
          required />
              {isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ADDRegistration", UIControlsText.ADDRegistration), internalPricingContextState.pageMode, PageConstants.CPStrategicDealtype) &&
          <ChoiceGroup
            defaultSelectedKey={distributorOption}
            options={customerCountry && !customerCountry.isEUEFTA ? distiOptions.filter(x => x.key !== "2") : distiOptions}
            style={{ marginLeft: "24px" }}
            onChange={onDistributorChoiceGroupChange}
            disabled={internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase() || internalPricingContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.PendingSecondaryReview} />
        }
        {hideResellerSectionsInViewModeForReseller(userRole || "", internalPricingContextState.pageMode) &&
          <ChoiceGroup
            defaultSelectedKey={resellerOption}
            options={customerCountry && !customerCountry.isEUEFTA ? resellerOptions.filter(x => x.key !== "2") : resellerOptions}
            style={{ marginLeft: "24px", width: '300px' }}
            onChange={onResellerChoiceGroupChange}
            disabled={internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase() || internalPricingContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.PendingSecondaryReview} />
        }
        <Stack style={{ marginLeft: "48px" }} >
          {hideResellerSectionsInViewModeForReseller(userRole || "", internalPricingContextState.pageMode) &&
            <Checkbox
              checked={isMsStoreChecked}
              onChange={onMsStoreCheckBoxChange}
              disabled={isMSStoreDisable(customerCountry, PartnerType.MsStore, internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0].statusCode)} />
          }
        </Stack>

      </Stack>
    </div>
  );
}

export default ChannelAccounts;