import React from 'react';
import { useForm } from 'react-hook-form';
import { FormRenderingService, IComponent } from './DynamicFormRendering';

/**
 * GenericFormComponent.
 * @function component
 */
const GenericForm: React.FunctionComponent<FormProps> = ({ onSubmit, validate, errors, formJsonTemplate, baseState }: FormProps) => {
    // Read the formState before render to subscribe the form state through the Proxy            
    const { register, handleSubmit } = useForm({});
    /**
     * Method to handle submit
     * @method
     */
    const onSubmitForm = (formData: any) => {
        console.log(JSON.stringify(formData));
    };

    /**
     * Method to handle rendering of content for tabs
     * @method
     */
    const renderTabs = () => {
        return (
            <div key='pivot'>
                {renderFieldsInTabs(formJsonTemplate)}
            </div>
        );
    }

    /**
     * Method to handle rendering of all field level components inside the tab
     * @method
     * @param {IComponent} data template for the form 
     */
    const renderFieldsInTabs = (data: IComponent) => {
        const container = data?.data as any;
        return container.components.map((eachItem: IComponent) => {
            return (
                <div key={eachItem.data.id} className={eachItem.className}>
                    {FormRenderingService(eachItem, register, baseState)}
                </div>
            );
        });
    }

    /**
     * go to dashboard on click of back event handler.
     * @method
     */
    const BacktoDashboard = (): void => {
        window.location.href ='/dashboard'
    }

    return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
            <div>{renderTabs()}</div>
        </form>
    );
}
export interface FormProps {
    onSubmit?: any,
    errors: any,
    validate?: any,
    formJsonTemplate: IComponent,
    baseState: any
}

export default GenericForm;