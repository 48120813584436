/**
 * Enum for ActionType.
 * @readonly
 * @enum 
 */
export enum ActionType {

    /**
     * Add Action.
     */
    Add = "Add",

    /**
     * Update Action.
     */
    Update = "Update",

    /**
     * Delete Action.
     */
    Delete = "Delete",

    /**
    * Created Action.
    */
    Created = "Created",

    /**
    * PanelAdd Action.
    */
     PanelAdd = "PanelAdd",    
}