import React from 'react';
import { JavaScriptExtensions } from '../../../infrastructure/JavaScriptExtensions'; 
import { DisplayMode } from '../../../models/DisplayMode';
import { getDealClosureDecisionNameByCode, getFieldConfig, getStatusNameByCode } from '../../../components/shared/common/util';
import { IBasePageState } from '../../pricingprogram/common/state/IBasePageState';
import { FieldMapMode } from '../../../models/FieldMapMode';
import { PageMode } from '../../../models/PageMode';
import * as Components from '../ComponentTypes/Components';

/**
 * Provides functionality related to rendering form related components
 * @class
 */
export function FormRenderingService(formJsonTemplate: IComponent | undefined, register: any, baseState?: IBasePageState): React.ReactNode {
    // Don't render if template is undefined
    if (!formJsonTemplate || formJsonTemplate === undefined) return null;

    /**
     * Creates the Component based on the pageMode type
     * @function 
     */
    function createComponent(item: IComponent, parentDisableFlag?: boolean): React.ReactNode {
        const selectedDeal = baseState?.pageMode === PageMode.Create ? baseState?.opportunityDeals[0] :
            baseState?.opportunityDeals?.find(t => t.name === baseState?.selectedOptyDealCaseNumber);
        const { data, type } = item;
        const { components, id, ...properties } = data;
        type ObjectKey = keyof typeof Components;
        let createType = type as ObjectKey;
        let localDisabledFlag: boolean | undefined = data['disabled'] as boolean;
        let isEditDisabled: boolean | undefined;
        let isHidden: boolean | undefined;
        let closureDecision: string | undefined;
        if (selectedDeal?.dealType && selectedDeal?.statusCode) {
            closureDecision = getDealClosureDecisionNameByCode(selectedDeal?.closureDecision);
            isEditDisabled = getFieldConfig("commonPP", baseState?.pageMode || "", selectedDeal?.statusCode, selectedDeal?.dealType, id, FieldMapMode.Disable, closureDecision || "");
            isHidden = getFieldConfig("commonPP", baseState?.pageMode || "", selectedDeal?.statusCode, selectedDeal?.dealType, id, FieldMapMode.Hidden, closureDecision || "");
        }

        return !isHidden && (!JavaScriptExtensions.isDefined(item.visible) || item.visible === true) ? React.createElement(
            Components[createType] as any,
            {
                ...data,
                key: id,
                disabled: (JavaScriptExtensions.isDefined(localDisabledFlag) && localDisabledFlag) || (JavaScriptExtensions.isDefined(isEditDisabled) && isEditDisabled) || (JavaScriptExtensions.isDefined(parentDisableFlag) && parentDisableFlag),
                register: { register },
                'aria-label': properties?.label
            } as any,
            // Map if there are items, if not try to render the embedded view as children
            Array?.isArray(components)
                ? components?.map(c => renderer(c, JavaScriptExtensions.isDefined(isEditDisabled) ? isEditDisabled : parentDisableFlag)) : renderer(null)
        ) : "";
    }

    /**
     * Render function that get the configuration properties and creates the specified component
     * @function 
     */
    function renderer(config: IComponent | null, disableFlag?: boolean): React.ReactNode {
        if (!config || !config.data || config?.data?.visible === false) return null;

        return createComponent(config, disableFlag);
    }

    return (
        renderer(formJsonTemplate)
    );
}

export interface IComponent {
    type: string;
    data: IComponentDataItem;
    visible?: boolean;
    roles?: any[];
    displayMode?: DisplayMode;
    className?: string;
}
export interface IComponentDataItem {
    id: string;
    visible?: boolean;
    tabNames?: any[];
    components?: Array<IComponent>;
    [key: string]: unknown;
}