import React, { useEffect, useReducer, useState } from 'react';
import { useContext } from 'react';
import { UIControlsText } from '../../../../content/UIControlsText';
import { Guid } from '../../../../infrastructure/Guid';
import { ErrorCode } from '../../../../models/ErrorCode';
import { ApplicationConstants } from '../../../../models/ApplicationConstants';
import { OpportunityDealStatusCodes } from '../../../../models/OpportunityDealStatusCodes';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { nextGenService } from '../../../../services/NextGenService';
import { PageConstants } from '../../../pageconstants/Constants';
import { CaseActionTypes } from '../../common/reducer/CaseActionTypes';
import { IPageState } from '../../common/state/IPageState';
import InternalPricingProgramReducer from "../reducer/InternalPricingReducer";
import { IInternalPricingContext } from './IInternalPricingContext';
import { PageMode } from '../../../../models/PageMode';
import { getURLParamValue, isNullOrEmpty, getLoggedinUserRoleDetails, getUserSession, getOwnerInfo, intializeSessionConstants, getDealType, getProductType, getRuleValidationMessage, getEntityDataFromSession, getPropsDeaType } from '../../../../components/shared/common/util';
import { IUserRoleDetail } from '../../../../models/IUserRoleDetail';
import { LocalStorageConsts } from '../../../../models/LocalStorageConsts';
import { IAttachment } from '../../../../models/IAttachment';
import { IOpportunityChannelContact } from '../../../../models/IOpportunityChannelContact';
import { IOpportunityAccount } from '../../../../models/IOpportunityAccount';
import { IAccount } from '../../../../models/IAccount';
import { CustomerType } from '../../../../models/CustomerType';
import { Spinner, Stack } from '@fluentui/react';
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { IProductFamily } from '../../../../models/IProductFamily';
import { getDealQuantityThresholdValue } from '../../PricingProgramForm/PricingProgramFormUtils';
import { IProductType } from '../../../../models/IProductType';
import { JavaScriptExtensions } from '../../../../infrastructure/JavaScriptExtensions';


const InternalPricingContext = React.createContext<IInternalPricingContext>({} as IInternalPricingContext);
const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

let internalPricingProgramDealId = Guid.newGuid();
const caseInitialState: IPageState = {
    id: Guid.newGuid(),
    dataLoading: true,
    selectedPivot: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText),
    submitterDetails: JSON.parse(getUserSession() || "{}"),
    pageMode: PageMode.Create,
    opportunityDeals: [{ id: internalPricingProgramDealId, statusCode: OpportunityDealStatusCodes.Draft, submitterDetails: JSON.parse(getUserSession() || "{}") }]
};

/**
 * InternalPricingProvider - CP and OTLE programs provider.
 * @function
 */
export function InternalPricingProvider(props: any) {
    const [internalPricingContextState, internalPricingContextDispatch] = useReducer(InternalPricingProgramReducer, caseInitialState);
    const contextValue = { internalPricingContextState, internalPricingContextDispatch };
    const [isSessionDataLoadComplete, setIsSessionDataLoadComplete] = useState<boolean>(true);
    const propDealType = getPropsDeaType(props.dealtype) || '';
    
    useEffect(() => {
        const userRoleDetails: IUserRoleDetail[] = getLoggedinUserRoleDetails();
        async function getOpportunity() {
            if (oppNumber && oppNumber.length > 0) {
                if (!isNullOrEmpty(userRoleDetails)) {
                    var opportunity = await Promise.resolve(nextGenService.getOpportunity(oppNumber, userRoleDetails, pageMode || ""));
                    if (opportunity.status === 200) {
                        setLocalStoreOpportunity(opportunity.data);
                        sessionStorage.setItem(SessionStorageConsts.opportunityProductType, JSON.stringify(opportunity.data.productGroup));
                        const dealQuantityThresholdForDealType : number = getDealQuantityThresholdValue(internalPricingContextState.opportunityDeals, opportunity.data.productGroup|| "", opportunity.data.opportunityDeals[0].dealType?.name || "");
                        sessionStorage.setItem(SessionStorageConsts.dealQuantityThresholdConfigs, dealQuantityThresholdForDealType.toString());
                        internalPricingProgramDealId = (opportunity.data && opportunity.data.opportunityDeals[0].id) || Guid.newGuid();
                        //add submitter details at opp deal from logged in user
                        opportunity.data.opportunityDeals[0].submitterDetails = JSON.parse(getUserSession() || "{}");
                        //on draft oppurtunity, savedraft flag returns as true, because of this on edit mode request re-submit automatically. so reseting flag to false
                        if (opportunity.data.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.Draft) {
                            opportunity.data.saveDraft = false;
                        }
                        opportunity.data.pageMode = pageMode?.toString();

                        var loggeinUser = getOwnerInfo();
                        if (loggeinUser !== getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Owner", UIControlsText.Owner) && pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()) {
                            internalPricingContextDispatch({ type: CaseActionTypes.ownerInfoChanged, isNotCaseOwner: true });
                        } else {
                            internalPricingContextDispatch({ type: CaseActionTypes.draftCaseLoaded, case: { ...opportunity?.data, selectedPivot: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText) } });
                        }
                        internalPricingContextDispatch({ type: CaseActionTypes.dataLoadingEnd });
                    }
                }
            }
        }
        const oppNumber = getURLParamValue(ApplicationConstants.OpportunityNumberParamName);
        const debugMode = getURLParamValue(ApplicationConstants.DebugParam);
        const pageMode = oppNumber && oppNumber.length > 0 ?
              isNullOrEmpty(sessionStorage.getItem(SessionStorageConsts.pageMode)) ? PageMode.View : sessionStorage.getItem(SessionStorageConsts.pageMode) : PageMode.Create;

        internalPricingContextDispatch({ type: CaseActionTypes.pageModeChanged, pageMode: pageMode || "" });
        if (!isNullOrEmpty(userRoleDetails)) {
            try {
                intializeSessionConstants().then(res1 => {
                    setIsSessionDataLoadComplete(true);
                    let internalPricingDealType = getDealType(propDealType);
                    internalPricingContextDispatch({ type: CaseActionTypes.dealTypeUpdated, dealTypeValue: { key: internalPricingProgramDealId, value: internalPricingDealType } });
                    let cpStrategicProductType = getProductType(PageConstants.DefaultProductType);
                    if (cpStrategicProductType) {
                        internalPricingContextDispatch({ type: CaseActionTypes.productGroupChanged, productGroupValue: cpStrategicProductType.productId });
                    }

                    if (oppNumber && oppNumber.length > 0) {
                        getOpportunity();
                    }
                    else {
                        localStorage.removeItem(LocalStorageConsts.savedOpportunity);
                        sessionStorage.removeItem(SessionStorageConsts.opportunityProductType);
                    }
                });
            }
            catch (error: any) {
                if (error && error.response && error.response.status === 404) {
                    internalPricingContextDispatch({ type: CaseActionTypes.errorOccured, inputNumber: ErrorCode.InvalidCase });
                }
            }
            finally {
                if (pageMode === PageMode.Create) {
                    internalPricingContextDispatch({ type: CaseActionTypes.dataLoadingEnd });
                }
                internalPricingContextDispatch({ type: CaseActionTypes.pageModeChanged, pageMode: pageMode || "" });
                if (debugMode && debugMode.toLocaleLowerCase() === "true") {
                    internalPricingContextDispatch({ type: CaseActionTypes.debugModeOn });
                }
            }
        }
        else {
            setIsSessionDataLoadComplete(true);
        }
    }, []);

    // Avoid storing dealcustomers/products/attachments/contacts  in local storage, Maintain only in Context Object.
    // Apply deep copy so that main Opportunity object is not changed...
    const setLocalStoreOpportunity = (Opportunity: IPageState) => {
        let caseOpportunity = JSON.parse(JSON.stringify(Opportunity));
        //keep only attachmentid to compare on delete action
        var attachments = Opportunity.attachments?.map((attachment: any) => {
            return {
                id: attachment.id,
            } as IAttachment
        });
        //keeping only id fields to compare and add action on draft
        var channelContacts = Opportunity.opportunityChannelContacts?.map((contact: any) => {
            return {
                accountId: contact.accountId,
                channelContactEmails: contact.channelContactEmails
            } as IOpportunityChannelContact
        });
        //to keep only required opp Account related ids
        var oppAccount = Opportunity.opportunityAccounts?.map((account: any) => {
            return {
                id: account.id,
                countryId: account.countryId,
                partnerType: account.partnerType,
                accounts: account.accounts?.map((acc: any) => {
                    return {
                        accountId: acc.accountId,
                        action:acc.action
                    } as IAccount
                }),
                partnerAccounts: account.partnerAccounts
            } as IOpportunityAccount
        });

        var oppProducts = Opportunity.opportunityDeals[0].products?.map((item: any) => {
            return {
                id: item.id,
                countryId: item.countryId,
                productFamilyId: item.productFamilyId,
                productFamilyCode: item.productFamilyCode,
                name: item.name,
                businessUnitId: item.businessUnitId,
                quantity: item.quantity,
            } as IProductFamily
        });
        //primary customer is used in export excel so keeping only primary row here
        var primaryCustomer = caseOpportunity.opportunityDeals[0].dealCustomers?.filter((primary: any) => primary.customerType === CustomerType.Primary);
        caseOpportunity.opportunityAccounts = oppAccount;
        caseOpportunity.attachments = attachments;
        caseOpportunity.opportunityChannelContacts = channelContacts;
        caseOpportunity.opportunityDeals[0].dealCustomers = primaryCustomer;
        caseOpportunity.opportunityDeals[0].products = oppProducts;
        localStorage.setItem(LocalStorageConsts.savedOpportunity, JSON.stringify(caseOpportunity))
    }
    return (
        <>
            {!isSessionDataLoadComplete &&
                <Stack>
                    <Spinner role="alert" aria-live="assertive" aria-busy="true" label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LoadingMCL", UIControlsText.LoadingMCL)} 
                        //styles={PageStyles.spinnerLoadingIcon}
                        />
                </Stack>
            }
            <InternalPricingContext.Provider value={contextValue}>
                {isSessionDataLoadComplete && props.children}
            </InternalPricingContext.Provider>
        </>
       
    );
}

/**
 * CP Strategic and OTLE Context custom hook.
 * @function
 * @returns {IInternalPricingContext}
 */
export function useInternalPricingContext(): IInternalPricingContext {
    return useContext(InternalPricingContext)
}