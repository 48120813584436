/**
 * Enum for CaseDecisionLetterType.
 * @readonly
 * @enum {number}
 */
export enum CaseDecisionLetterType {

    /**
     * SingleCountryDeal value.
     */
    WithSKU = 0,

    /**
     * MultiNationalDeal value.
     */
    WithoutSKU = 1,
}