/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { IStackTokens, Panel, PanelType, Stack, ActionButton, Dialog } from "office-ui-fabric-react";
import { IPanelFieldProps } from "../props/PanelFieldProps";
import { useBoolean } from '@fluentui/react-hooks';
import { UIControlsText } from "../../../content/UIControlsText";
import { PageMode } from "../../../models/PageMode";
import { DefaultButton, PrimaryButton, MessageBar, DialogType, DialogFooter } from "@fluentui/react";
import { CssClassConstants } from "../../../content/CssClassConstants";
import FinalTierFields from "./FinalTierFields";
import { PageStyles } from "../../pricingprogram/common/content/Page.Styles";
import { JavaScriptExtensions } from "../../../infrastructure/JavaScriptExtensions";
import { MessageTypeText } from '../../../models/MessageTypeText';
import { SessionStorageConsts } from '../../../models/SessionStorageConsts';
import { getRuleValidationMessage, getEntityDataFromSession } from "../../../components/shared/common/util";

const stackTokens: Partial<IStackTokens> = { childrenGap: 20 };
const modalPropsStyles = { main: { maxWidth: 450 } };
const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
const dialogContentProps = {
    type: DialogType.normal,
    title: '',
    subText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelDialogText", UIControlsText.CancelDialogText),
};

const PanelFieldContainer: React.FunctionComponent<IPanelFieldProps> = (props: IPanelFieldProps) => {
    const { onClick } = props.secondaryButtonFields;
    const { messageBar, showErrorMessage } = props;
    const [showPanel, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const resetChoice = React.useCallback(() => setChoice(undefined), []);
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
        }),
        [],
    );

    useEffect(() => {
        if (JavaScriptExtensions.isDefined(props.openPanel) && props.openPanel) {
            openPanel();
        }
        else {
            dismissPanel();
        }

    }, [props.openPanel]);

    //To enable interaction with the popup - Click on close button will dismiss the panel
    const onDismiss = React.useCallback(
        (ev?: React.SyntheticEvent<HTMLElement, Event>) => {
            if (!ev) return;
            // eslint-disable-next-line
            const srcElement = ev.nativeEvent.srcElement as Element | null;
            if ((srcElement && srcElement.className.indexOf('ms-Button-flexContainer') !== -1)
                || (srcElement && srcElement.className.indexOf('ms-Button-icon') !== -1)) {
                dismissPanel();
            } else return;
        },
        [dismissPanel],
    );

    const onSubmitClick = (e: any) => {
        props?.primaryButtonFields?.onClick && props?.primaryButtonFields?.onClick(e);
        if (props.isError) {
            return;
        }
        return dismissPanel();
    }

    const onSecondaryButtonClick = (ev: any) => {
        onClick && onClick(ev);
    }
    //Panel Cancel Button
    const onCancel = () => {
        toggleHideDialog();
    };

    const OnCancelDialog = () => {
        toggleHideDialog();
        props.onCancel();
        return dismissPanel();
    }

    const OnSubmitDialog = (e: any) => {
        props?.primaryButtonFields?.onClick && props?.primaryButtonFields?.onClick(e);
        toggleHideDialog();
        if (props.isError) {
            return;
        }
        return dismissPanel();
    }
    const onRenderFooterContent = React.useCallback(
        () => (
            <>
                <div className="panelNavigationContainer">
                    <ActionButton
                        aria-label="previous form"
                        text="Back" label="Back"
                        iconProps={{
                            iconName: CssClassConstants.BackButtonClass,
                            ariaLabel: CssClassConstants.BackButtonClass
                        }}
                        onClick={props.onBackButtonClick}
                        disabled={props.isBackButtonDisabled}
                    >
                        Back
                    </ActionButton>
                    <ActionButton
                        aria-label=" next form"
                        text="Next" label="Next"
                        iconProps={{
                            iconName: CssClassConstants.NextButtonClass,
                            ariaLabel: CssClassConstants.NextButtonClass
                        }}
                        className='NextButtonWithIcon'
                        onClick={props.onNextButtonClick}
                        disabled={props.isNextButtonDisabled}
                    >
                        Next
                    </ActionButton>
                </div>
                {messageBar && showErrorMessage &&
                    < div >
                        <MessageBar
                            messageBarType={messageBar.messageBarType}
                            isMultiline={messageBar.isMultiline}
                            onDismiss={resetChoice}
                            dismissButtonAriaLabel="Close"
                        >
                            {props?.errorMessage}
                        </MessageBar>
                    </div>}
                <div>
                    <PrimaryButton
                        aria-label={props?.primaryButtonFields?.ariaLabel}
                        style={props?.primaryButtonFields?.style}
                        text={props?.primaryButtonFields?.text}
                        onClick={onSubmitClick}
                        disabled={props?.primaryButtonFields?.disabled}
                    />
                    {props?.secondaryButtonFields && <PrimaryButton
                        aria-label={props?.secondaryButtonFields?.ariaLabel}
                        style={props?.secondaryButtonFields?.style}
                        text={props?.secondaryButtonFields?.text}
                        onClick={onSecondaryButtonClick}
                        disabled={props?.secondaryButtonFields?.disabled}
                    />}
                    <DefaultButton
                        aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)}
                        text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)}
                        onClick={onCancel}
                        style={PageStyles.panelButtonStyle}
                    />
                </div>
            </>

        ),
        [dismissPanel, props.isBackButtonDisabled, props.isNextButtonDisabled, props.primaryButtonFields?.disabled, props.secondaryButtonFields?.disabled, messageBar, props.errorFields],
    );
    const onPanelClick = () => {
        props.invokePanelProps.onButtonClick();
        return openPanel();
    }
    return (
        <Stack tokens={stackTokens}>
            <PrimaryButton
                iconProps={props.invokePanelProps.iconProps}
                disabled={props.PageMode?.toLowerCase() === PageMode.View.toLowerCase() || props.invokePanelProps.disabled}
                style={props.invokePanelProps?.buttonStyles}
                onClick={onPanelClick}>
                {props.invokePanelProps.buttonText}
            </PrimaryButton>
            <Panel
                id="PanelFieldContainer"
                isOpen={showPanel}
                type={PanelType.custom}
                customWidth='600px'
                onDismiss={onDismiss}
                onDismissed={props.onDismissed}
                closeButtonAriaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}
                isFooterAtBottom={true}
                onRenderFooterContent={onRenderFooterContent}
                onOpen={() => {

                }}
                headerText={props.headerText}
            >
                {props.text ? props.text : ''}
                {props.panelContent}
                <Dialog
                    hidden={hideDialog}
                    onDismiss={toggleHideDialog}
                    dialogContentProps={dialogContentProps}
                    modalProps={modalProps}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={OnSubmitDialog} text={props.text ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddAdditionResellersText", UIControlsText.AddAdditionResellersText) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddFinalTierResellers", UIControlsText.AddFinalTierResellers)} />
                        <DefaultButton onClick={OnCancelDialog} text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)} />
                    </DialogFooter>
                </Dialog>
            </Panel>
        </Stack >
    );
};

export default PanelFieldContainer;