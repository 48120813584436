/**
 * Enum for GridSelectionType.
 * @readonly
 * @enum {number}
 */
export enum GridSelectionType {

    /**
     * single value.
     */
    Single = 1,

    /**
     * multiple value.
     */
    Mulitple = 2,
}