import { ActionButton, Checkbox, Dropdown, IDropdownOption, Label, Stack, TextField } from "@fluentui/react";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { CssClassConstants } from "../../content/CssClassConstants";
import { ErrorMessages } from "../../content/ErrorMessages";
import { CommonStyles, DealContactStyles, defaultColumnProps, DefaultPageStyles } from "../../content/styles/Page.styles";
import { UIControlsText } from "../../content/UIControlsText";
import { JavaScriptExtensions } from "../../infrastructure/JavaScriptExtensions";
import { ApplicationConstants } from "../../models/ApplicationConstants";
import { ContactRoles } from "../../models/ContactRoles";
import { IDealContact } from "../../models/IDealContact";
import { IKeyValue } from "../../models/IKeyValue";
import { PageMode } from "../../models/PageMode";
import { nextGenService } from "../../services/NextGenService";
import { IDealContactProps } from "./props/IDealContactProps";
import { getRuleValidationMessage, getEntityDataFromSession } from "./common/util";
import { SessionStorageConsts } from "../../models/SessionStorageConsts";
import { MessageTypeText } from "../../models/MessageTypeText";
import { OpportunityDealStatusCodes } from "../../models/OpportunityDealStatusCodes";

const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

/**
 * Deal Contact component.
 * @function component
 */
const DealContact: React.FunctionComponent<IDealContactProps> = (props?: IDealContactProps) => {

    const [contactEmail, setContactEmail] = useState<string>(props?.dealContact?.email as string);
    const [contactName, setContactName] = useState<string>(props?.dealContact?.firstName ? props?.dealContact?.firstName + ' ' + props?.dealContact?.lastName : "");
    const [contactFirstName, setContactFirstName] = useState<string>(props?.dealContact?.firstName as string);
    const [contactLastName, setContactLastName] = useState<string>(props?.dealContact?.lastName as string);
    const [contactRole, setContactRole] = useState<string | number>(props?.dealContact?.contactType as number);
    const [otherRole, setOtherRole] = useState<string>(props?.dealContact?.otherRole as string);
    const [isCoOwnerChecked, setIsCoOwnerChecked] = useState(props?.dealContact?.isCoOwner);
    const [dealNameErrorMessage, setDealNameErrorMessage] = useState<string>("");

    useEffect(() => {
        let contactComplete: boolean = false;
        //If required field are set for deal contact, update the parent component using callback
        if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(contactEmail)
            && !JavaScriptExtensions.isNullOrUndfinedOrEmpty(contactRole)
            && !JavaScriptExtensions.isEmptyOrNullOrUndefined(contactName)) {
            contactComplete = true;
        }

        if (props?.updateDealContact) {
            var contact: IDealContact = {
                id: props.id,
                email: contactEmail,
                contactType: contactRole,
                otherRole: otherRole,
                firstName: contactFirstName,
                lastName: contactLastName,
                isCoOwner: isCoOwnerChecked,
                isComplete: contactComplete
            };
            props?.updateDealContact(contact);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactEmail, contactName, contactRole, isCoOwnerChecked, otherRole]);

    const onCoOwnerChange = useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setIsCoOwnerChecked(!!checked);
        },
        [],
    );

    /**
     * Get TextField ErrorMessage.
     * @method
     * @param {string>} value Text Box value.
     * @@returns {string} Error Message.
     */
    const getTextFieldErrorMessage = (value: string): string => {
        if (!value) {
            return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredFieldError", ErrorMessages.RequiredFieldError);
        }
        else if (value.indexOf("@") !== -1 && value.indexOf(ApplicationConstants.microsoftEmail) === -1) {
            return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealContactInputError", ErrorMessages.DealContactInputError);
        }

        return '';
    };

    /**
     * Role change callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element change Event.
     * @param {IDropdownOption} item Clicked Option from Dropdown.
     */
    const onRoleChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            setContactRole(item.key);
        }
        if (item && item.key !== ContactRoles.Others) {
            setOtherRole('');
        }
    }

    /**
     * Other Role change callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element change Event.
     * @param {IDropdownOption} item Clicked Option from Dropdown.
     */
    const onOtherRoleChange = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, item?: string): void => {
        if (item) {
            setOtherRole(item);
        }
    }

    /**
     * Deal Contact Alias change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {string} newValue updated value from textField.
     */
    const onDealContactAliasTextChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        setContactEmail(newValue as string);
    }

    /**
     * Deal Contact Alias blur Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {string} newValue updated value from textField.
     */
    const onDealContactAliasTextBlur = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        let input = event.currentTarget.value.toLocaleLowerCase().trim();
        let email = input;
        if (input.indexOf("@") !== -1 && input.indexOf(ApplicationConstants.microsoftEmail) === -1) {
            setContactName("");
            setDealNameErrorMessage("");
            return;
        }

        if (input.indexOf(ApplicationConstants.microsoftEmail) === -1) {
            email = JavaScriptExtensions.stringFormat("{0}{1}", input, ApplicationConstants.microsoftEmail);
        }

        nextGenService.getMicrosoftContactByAlias(email)
            .then((response) => {
                setContactEmail(response.data.email);// Update contactEmail to alias name instead of firstname.lastname@microsoft.com.
                setDealNameErrorMessage("");
                setContactFirstName(response.data.firstName);
                setContactLastName(response.data.lastName);
                setContactName(JavaScriptExtensions.stringFormat("{0} {1}", response.data.firstName, response.data.lastName));
            }).catch((error) => {
                setContactName("");
                setDealNameErrorMessage(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealContactNameInputError", ErrorMessages.DealContactNameInputError));
            });
    }
    return (
        <Stack horizontal {...defaultColumnProps} id={props?.id}>
            {props?.pageMode?.toLowerCase() !== PageMode.View.toLowerCase() && props?.opportunityDealStatusCode !== OpportunityDealStatusCodes.PendingSecondaryReview ? (
                <>
                <Label>
                <TextField
                    name={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealContactAliasText", UIControlsText.DealContactAliasText)}
                    ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealContactAliasText", UIControlsText.DealContactAliasText)}
                    placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealContactAliasPlaceholderText", UIControlsText.DealContactAliasPlaceholderText)}
                    validateOnFocusOut
                    validateOnLoad={false}
                    onGetErrorMessage={getTextFieldErrorMessage}
                    onBlur={onDealContactAliasTextBlur}
                    onChange={onDealContactAliasTextChange}
                    styles={DefaultPageStyles.customWidth190Styles}
                    value={contactEmail}
                    disabled={props?.pageMode?.toLowerCase() === PageMode.View.toLowerCase()}
                    required />
                </Label>
                <Label>
                    <TextField
                        ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealContactName", UIControlsText.DealContactName)}
                        styles={DefaultPageStyles.customWidthForDealTeamLabels}
                        style={CommonStyles.blackBorder}
                        value={contactName}
                        validateOnLoad={false}
                        errorMessage={dealNameErrorMessage}
                        disabled={true}
                        required />
                </Label>
                <Label>
                    <Dropdown
                        ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealContactRoleText", UIControlsText.DealContactRoleText)}
                        options={(props?.contactRoles && props.contactRoles.map((pt: IKeyValue) => ({ key: pt.key, text: pt.value } as IDropdownOption))) || []}
                        placeholder={UIControlsText.DealContactRolePlaceholderText}
                        styles={{ dropdown: { width: 200 } }}
                        onChange={onRoleChange}
                        selectedKey={contactRole}
                        disabled={props?.pageMode?.toLowerCase() === PageMode.View.toLowerCase()}
                        required />
                </Label>
                <Label styles={props?.otherRoleStyle}>
                    {contactRole === ContactRoles.Others &&
                        <TextField
                            ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealContactName", UIControlsText.DealContactName)}
                            styles={DefaultPageStyles.customWidthForDealTeamLabels}
                            style={CommonStyles.blackBorder}
                            value={otherRole}
                            onChange={onOtherRoleChange}
                            disabled={props?.pageMode?.toLowerCase() === PageMode.View.toLowerCase()}
                            validateOnLoad={false}
                            errorMessage={dealNameErrorMessage}
                            required />}
                </Label>
                <Label>
                    <Checkbox
                        name={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealContactCoOwner", UIControlsText.DealContactCoOwner)}
                        ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealContactCoOwner", UIControlsText.DealContactCoOwner)}
                        styles={{ root: DealContactStyles.CoOwnerCheckBox }}
                        checked={isCoOwnerChecked}
                        disabled={props?.pageMode?.toLowerCase() === PageMode.View.toLowerCase()}
                        onChange={onCoOwnerChange} />
                </Label>
                <Label>
                    <ActionButton
                        iconProps={{ iconName: CssClassConstants.DeleteIconName, ariaLabel: CssClassConstants.DeleteIconName, styles: DefaultPageStyles.redColor }}
                        disabled={props?.pageMode?.toLowerCase() === PageMode.View.toLowerCase()}
                        style={DealContactStyles.DeleteButton}
                        id={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeleteVTeamText", UIControlsText.DeleteVTeamText)}
                        onClick={(event) => {
                            event.preventDefault();
                            props?.removeDealContact && props?.removeDealContact(props?.id);
                            return;
                        }}>
                    </ActionButton>
                </Label>
                </>
            ) : 
            <> 
                <Label styles={DefaultPageStyles.customWidth190Styles} className={'ms-LabelValue'}>{contactEmail}</Label>
                
                <Label styles={DefaultPageStyles.customWidthForDealTeamLabels} className={'ms-LabelValue'}>{contactName}</Label>
                
                {
                    !props?.isMicrosoftContact && 
                    <>
                        <Label styles={DefaultPageStyles.customWidth190Styles} className={'ms-LabelValue'}>{props?.contactRoles?.filter((pt: IKeyValue) => (pt.key === contactRole))[0]?.value}</Label>
                        
                        {contactRole === ContactRoles.Others &&
                            <Label styles={DefaultPageStyles.customWidthStyles} className={'ms-LabelValue'}>{otherRole}</Label>
                        }
                        <Label styles={DefaultPageStyles.customWidth40Styles} className={'ms-LabelValue'}>{isCoOwnerChecked ? "Yes" : "No"}</Label>
                    </>
                }
            </>
            }
        </Stack>
    );

}

export default DealContact;