/**
 * Enum for UserSubRole.
 * @readonly
 * @enum {UserSubRole}
 */
export enum UserSubRole {
    /// <summary>
    /// ADD
    /// </summary>
    ADD = "ADD",

    /// <summary>
    /// Surface Specialist
    /// </summary>
    SurfaceSpecialist = "SSP",

    /// <summary>
    /// ADR
    /// </summary>
    ADR = "ADR",

    /// <summary>
    /// MSStoreUser
    /// </summary>
    MSStoreUser = "MSS",

    /// <summary>
    /// DMP
    /// </summary>
    DMP = "DMP",

    /// <summary>
    /// SurfacePDM
    /// </summary>
    SurfacePDM = "PDM",

    /// <summary>
    /// SurfacePDMLead
    /// </summary>
    SurfacePDMLead = "PML",

    /// <summary>
    /// SurfaePMM
    /// </summary>
    SurfacePMM = "PMM",

    /// <summary>
    /// FinanceBusinessDesk.
    /// </summary>
    FinanceBusinessDesk = "FBD",

    /// <summary>
    /// SurfaceCE
    /// </summary>
    SurfaceCE = "SCE",

    /// <summary>
    /// CorpGBB
    /// </summary>
    CorpGBB = "COU",

    /// <summary>
    /// CorpAdmin
    /// </summary>
    CoprAdmin = "COA",

    /// <summary>
    /// CorpFinanceAdmin
    /// </summary>
    CorpFinanceAdmin = "CFA",

    /// <summary>
    /// OperationDeskVendor
    /// </summary>
    OperationDeskVendor = "POD",

    /// <summary>
    /// ClaimVendors
    /// </summary>
    ClaimVendors = "CMT",

    /// <summary>
    /// Reseller
    /// </summary>
    Reseller = "Reseller",

    /// <summary>
    /// ADDReseller
    /// </summary>
    ADDReseller = "ADDReseller",

    /// <summary>
    /// MSSeller
    /// </summary>
    MSSeller = "MSSeller",

    /// <summary>
    /// ClaimOnlyADD
    /// </summary>
    ClaimOnlyADD = "COD",
}