/**
 * Class containing shortcuts and methods that should be in javascript but isn't.
 * @class
 */
export class JavaScriptExtensions {

    /**
     * Tests whether an object is defined or not.
     * @method
     * @param object {any} The object to check.
     * @returns {boolean} true if the object is defined, false otherwise.
     */
    public static isDefined(object: any): boolean {
        return typeof object !== "undefined";
    }

    /**
     * Tests whether an object is undefined or null.
     * @method
     * @param object {any} The object to check.
     * @returns {boolean} true if the object is undefined, false otherwise.
     */
    public static isNullOrUndfinedOrEmpty<T>(object: T | null | undefined): boolean {
        return typeof object === "undefined" || object === null;
    }

    /**
     * Tests whether an string is defined or null or empty.
     * @method
     * @param str {any} The tring to check.
     * @returns {boolean} true if the string is undefined, null or Empty, false otherwise.
     */
    public static isEmptyOrNullOrUndefined(str: string | undefined): boolean {
        return str === null || str === undefined || str === "";
    }

    /**
     * Tests whether an string is empty guid
     * @method
     * @param str {any} String to check.
     * @returns {boolean} true if the string is empty guid, null or Empty, false otherwise.
     */
    public static isEmptyGuid(str: string | undefined): boolean {
        return str === null || str === undefined || str === "" || str === "00000000-0000-0000-0000-000000000000";
    }

    /**
     * Replaces the placeholders in the format string with the given arguments.
     * @method
     * @param format {string} The format string with the placeholders to replace.
     * @param args {string[]} The arguments to replace the placeholders with.
     * @returns {string} The format string with the placeholders replaced.
     */
    public static stringFormat(format: string, ...args: string[]): string {
        for (var ndx = 0; ndx < args.length; ++ndx) {
            format = format.replace(new RegExp("\\{" + ndx + "\\}", "g"), args[ndx]);
        }

        return format;
    };

    /**
     * Compare two strings are equal or not.
     * @method
     * @param oldStr {string} The old string to check.
     * @param newStr {string} The new string to check.
     * @returns {boolean} true if the both strings are equal, false otherwise.
     */
     public static isEqual(oldStr: string | undefined, newStr: string | undefined): boolean {
        if (!this.isEmptyOrNullOrUndefined(oldStr) && !this.isEmptyOrNullOrUndefined(newStr) && (oldStr === newStr)) {
          return true;
        } else {
          return false;
        }
    }
}