/**
 * Enum for CustomerType.
 * @readonly
 * @enum {number}
 */
export enum CustomerType {

    /**
     * Primary value.
     */
    Primary = 1,

    /**
     * Affiliates value.
     */
    Affiliates = 2,
}