import { CoherenceDialog, CoherenceDialogFooter } from "@cseo/controls";
import { DialogType, Stack } from "@fluentui/react";
import React from "react";
import { IComponentDataItem } from "../Components/DynamicFormRendering";

export function DialogComponent(props: IComponentDataItem) {
    const [showMessageBar, setShowMessageBar] = React.useState(true);
    const toggleMessageBar = (): (() => void) => (): void => setShowMessageBar(!showMessageBar);

    return (
        <Stack>
            <CoherenceDialog
                hidden={props?.hidden as boolean}
                onDismiss={props?.onDismiss as ((ev?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => any)}
                isBlocking={props?.isBlocking as boolean}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: props?.title as string,
                    subText: props?.subText as string
                }}
            >
                {props?.DialogContent}
                <CoherenceDialogFooter>
                    props?.FooterContent
                </CoherenceDialogFooter>
            </CoherenceDialog>
        </Stack>
    );
}