/**
 * Enum for PageMode.
 * @readonly
 * @enum {number}
 */
 export enum PageMode {

    /**
     * Create value.
     */
    Create = "create",

    /**
     * Edit value.
     */
    Edit = "edit",

    /**
     * View value.
     */
    View = "view",
}