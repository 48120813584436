/**
 * Enum for Deal Type.
 * @readonly
 * @enum {DealType}
 */
export enum DealType
{
    /**
     * CP Strategic.
     */
    CPStrategic = "CP Strategic",

    /**
     * Deal Registration.
     */
    DealRegistration = "Deal Registration",

   /**
   * Special Pricing.
   */
   SpecialPricing = "Special Pricing",

   /**
   * BidGrid Pricing.
   */
   BidGrid = "Bid Grid",

   /**
   * OTLE.
   */
   OTLE = "OTLE",
}