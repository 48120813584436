import React, { useEffect, useState } from "react";
import { _Styles } from '../../content/styles/Page.styles';
import { IKendoGridProps } from '../../components/shared/grid/IKendoGridProps';
import { readGridConfig } from "../../components/shared/common/util";
import { GridType } from "../../models/GridType";
import "../../content/styles/AddCaseGrid.css";
import { IOpportunityDeal } from "../../models/IOpportunityDeal";
import DashboardGrid from "../../components/shared/grid/dashboardgrid";
import { JavaScriptExtensions } from "../../infrastructure/JavaScriptExtensions";
import { IDashboardGridProps } from "./props/IDashboardGridProps";

export const CPStrategicDealReportGrid: React.FunctionComponent<IDashboardGridProps> = (props: IDashboardGridProps) => {
    var KendoGridProps: IKendoGridProps[] = [];
    KendoGridProps.push(readGridConfig(GridType.CPStrategicDealReport).kendoGridProps)
    var activeDashboardConfig = KendoGridProps.filter(x => x.gridName === GridType.CPStrategicDealReport)
    const [cpStrategicDealReportGridData, setCPStrategicDealReportGridData] = useState<Array<IOpportunityDeal> | undefined>(props.opportunitDeals);
    const [activeTabConfig, setactiveTabConfig] = useState(activeDashboardConfig);

    useEffect(() => {
        const config = activeDashboardConfig;
        config[0].data = props.opportunitDeals; 
        config[0].totalRecords = props.opportunitDeals?.length !== undefined ? props.opportunitDeals?.length : 0;
        setactiveTabConfig(config);
        setCPStrategicDealReportGridData(props.opportunitDeals);
    }, [props])

    return (
        <React.Fragment>
          <DashboardGrid {...{ kendoGridProps: { ...activeTabConfig[0] } }} />
        </React.Fragment>
    );
};
