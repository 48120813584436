import { Reducer } from "react";
import { IPageState } from "../../common/state/IPageState";
import { CaseActionTypes } from '../../common/reducer/CaseActionTypes';
import { IInternalPricingProgramActions } from "./IInternalPricingProgramActions";
import { isNullOrEmpty } from "../../../../components/shared/common/util";
import { IDealState } from "../../common/state/IDealState";

/**
 * InternalPricingReducer -  CP Strategic and OTLE Reducer.
 * @function
 */
const InternalPricingReducer: Reducer<IPageState, IInternalPricingProgramActions> =
    (state: IPageState, action: IInternalPricingProgramActions) => {
        switch (action.type) {
            case CaseActionTypes.dataLoadingStart:
                return { ...state, dataLoading: true };

            case CaseActionTypes.dataLoadingEnd:
                return { ...state, dataLoading: false };

            case CaseActionTypes.debugModeOn:
                return { ...state, debugMode: true };

            case CaseActionTypes.casePivotChanged:
                return { ...state, selectedPivot: action.selectedPivot };

            case CaseActionTypes.dealGeoCoverageChanged:
                return { ...state, isMultiNational: action.boolValue };

            case CaseActionTypes.productGroupChanged:
                return { ...state, productGroup: action.productGroupValue };

            case CaseActionTypes.dealCountryChanged:
                {
                    let newState = { ...state };
                    let index = newState.opportunityDeals.findIndex(x => x.id === action.countries?.key);
                    newState.opportunityDeals[index].geoCountries = action.countries?.value;
                    return newState;
                }

            case CaseActionTypes.dealAffiliationTypeChanged:
                return updateDealDropDownFieldState(state, action, "affiliationType");

            case CaseActionTypes.dealAffiliationTypeOtherChanged:
                return updateDealTextFieldState(state, action, "affiliationTypeOthers");

            case CaseActionTypes.channelOverviewInputChanged:
                return updateDealTextFieldState(state, action, "channelOverview");

            case CaseActionTypes.channelJustificationRequiredChanged:
                {
                    let newState = { ...state };
                    let index = newState.opportunityDeals.findIndex(x => x.id === action.dealBoolValue?.key);
                    newState.opportunityDeals[index].isChannelJustificationRequired = action.dealBoolValue?.value;
                    return newState;
                }

            case CaseActionTypes.channelJustificationInputChanged:
                return updateDealTextFieldState(state, action, "channelJustification");

            case CaseActionTypes.channelStructureChanged:
                return updateDealDropDownFieldState(state, action, "channelStructure");

            case CaseActionTypes.dealNameInputChanged:
                return updateDealTextFieldState(state, action, "dealName");

            case CaseActionTypes.dealOverviewInputChanged:
                return updateDealTextFieldState(state, action, "dealOverview");

            case CaseActionTypes.dealAdditionalInfoInputChanged:
                return updateDealTextFieldState(state, action, "additionalComments");

            case CaseActionTypes.dealVerticalChanged:
                return updateDealDropDownFieldState(state, action, "dealVertical");

            case CaseActionTypes.dealTypeUpdated:
                {
                    let newState = { ...state };
                    let index = newState.opportunityDeals.findIndex(x => x.id === action.dealTypeValue?.key);
                    if (newState?.opportunityDeals[index]) {
                        newState.opportunityDeals[index].dealType = action.dealTypeValue?.value;
                    }
                    return newState;
                }

            case CaseActionTypes.dealDetailsRequestedExpirationDateChanged:
                {
                    let newState = { ...state };
                    let index = newState.opportunityDeals.findIndex(x => x.id === action.inputDate?.key);
                    newState.opportunityDeals[index].expirationDate = action.inputDate?.value;
                    return newState;
                }

            case CaseActionTypes.dealDetailsExpirationDateJustificationChanged:
                return updateDealTextFieldState(state, action, "expirationDateJustification");

            case CaseActionTypes.dealDetailsExceptionRequestChanged:
                return updateDealTextFieldState(state, action, "exceptionRequest");

            case CaseActionTypes.attachmentChanged:
                return { ...state, attachments: action.attachments };

            case CaseActionTypes.dealDetailsContactChanged:
                {
                    let newState = { ...state };
                    let index = newState.opportunityDeals.findIndex(x => x.id === action.dealContacts?.key);
                    newState.opportunityDeals[index].dealContacts = action.dealContacts?.value;
                    return newState;
                }

            case CaseActionTypes.channelAccountChanged:
                return { ...state, opportunityAccounts: action.opportunityAccounts };

            case CaseActionTypes.selectedLegalEntityChanged:
                {
                    let newState = { ...state };
                    let index = newState.opportunityDeals.findIndex(x => x.id === action.dealCustomers?.key);
                    if (index >= 0)
                        newState.opportunityDeals[index].dealCustomers = action.dealCustomers?.value;
                    return newState
                }

            case CaseActionTypes.selectedProductFamilyChanged: {
                {
                    let newState = { ...state };
                    let index = newState.opportunityDeals.findIndex(x => x.id === action.products?.key);
                    if (index >= 0)
                        newState.opportunityDeals[index].products = action.products?.value;
                    return newState;
                }
            }
            case CaseActionTypes.pageModeChanged:
                return { ...state, pageMode: action.pageMode };

            case CaseActionTypes.draftCaseLoaded:
                {
                    let newState = { ...state };
                    newState = Object.assign(newState, action.case as IPageState);;
                    return newState;
                }

            case CaseActionTypes.skipChannelReasonChanged:
                return updateDealTextFieldState(state, action, "skipChannelReason");

            case CaseActionTypes.saveDraft:
                return { ...state, saveDraft: action.boolValue };

            case CaseActionTypes.submitCase:
                return { ...state, submitCase: action.boolValue };

            case CaseActionTypes.submitCaseAsync:
                return { ...state, submitCaseAsync: action.boolValue };

            case CaseActionTypes.totalunitsTransactedChanged:
                {
                    let newState = { ...state };
                    let index = newState.opportunityDeals.findIndex(x => x.id === action.dealVolume?.key);
                    if (index >= 0)
                        newState.opportunityDeals[index].dealVolume = action.dealVolume?.value;
                    return newState;
                }
            case CaseActionTypes.missingFieldTab: {
                return { ...state, missingTabs: action.inputArray }
            }
            case CaseActionTypes.validationErrors: {
                return { ...state, errors: action.inputArray }
            }
            case CaseActionTypes.errorOccured: {
                return { ...state, errorCode: action.inputNumber }
            }
            case CaseActionTypes.ownerInfoChanged:
                return { ...state, isNotCaseOwner: action.isNotCaseOwner };

            case CaseActionTypes.channelContactChanged:
                return { ...state, opportunityChannelContacts: action.channelContacts };

            case CaseActionTypes.dealCustomerValueChanged:
                return updateDealCustomerState(state, action);
    
            case CaseActionTypes.channelAccountContactChanged:
                return {
                    ...state,
                    opportunityChannelContacts: action.opportunityChannel?.channelContacts,
                    opportunityAccounts: action.opportunityChannel?.opportunityAccounts
                };

            case CaseActionTypes.closureDecisionChanged:
                {
                    let newState = { ...state };
                    let index = newState.opportunityDeals.findIndex(x => x.id === action.closureDecision?.key);
                    if (index >= 0) {
                        newState.opportunityDeals[index].closureDecision = action.closureDecision?.value;
                    }
                    return newState
                }
        }

        return state;
    }

/**
 * update Deal Text Field State.
 * @param {IPageState} state state object
 * @param {IInternalPricingProgramActions} action action object
 * @param {string} fieldName field to be changed
 */
const updateDealTextFieldState = (state: IPageState, action: IInternalPricingProgramActions, fieldName: string): IPageState => {
    let newState = { ...state };
    let index = newState.opportunityDeals.findIndex(x => x.id === action.dealInputValue?.key);
    (newState.opportunityDeals[index] as any)[fieldName] = action.dealInputValue?.value
    return newState;
}

/**
 * update Deal Customer State.
 * @param {IPageState} state state object
 * @param {IInternalPricingProgramActions} action action object
 */
const updateDealCustomerState = (state: IPageState, action: IInternalPricingProgramActions): IPageState => {
    let newState = { ...state };
    if (action && !isNullOrEmpty(action.dealInputControlValue)) {
        let dealCustomer: any = action.dealInputControlValue;
        var opportunityDeals = newState.opportunityDeals.filter(t => isNullOrEmpty(action?.dealInputControlValue?.id) || t.id === action?.dealInputControlValue?.id);
        if (opportunityDeals) {
            opportunityDeals.forEach((deal?: IDealState) => {
                deal?.dealCustomers?.forEach(x => (x as any)[dealCustomer.key] = dealCustomer.value)
            });
        }
    }

    return newState;
}

/**
 * update Deal Dropdown Field State.
 * @param {IPageState} state state object
 * @param {IInternalPricingProgramActions} action action object
 * @param {string} fieldName field to be changed
 */
const updateDealDropDownFieldState = (state: IPageState, action: IInternalPricingProgramActions, fieldName: string): IPageState => {
    let newState = { ...state };
    let index = newState.opportunityDeals.findIndex(x => x.id === action.dealDropDownValue?.key);
    (newState.opportunityDeals[index] as any)[fieldName] = action.dealDropDownValue?.value;
    return newState;
}

export default InternalPricingReducer;