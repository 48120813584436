/**
 * Css Class Constants.
 * @constant 
 */
export const CssClassConstants = {
    AddItemButtonClass: "add-item",
    AddItemIconName: "AddTo",
    AddIconName:"Add",
    DeleteIconName: "Delete",
    SearchIconName: "Search",
    ChannelAccounts: "channel-accounts",
    ViewIconName: "View",
    CPStrategicLetters: "Attach",
    EditIconName: "Edit",
    UndoIconName: "Undo",
    ChooseColumnIconName: "ColumnOptions",
    IncidentTriangleIconName: "IncidentTriangle",
    MoreVerticalIconName: "MoreVertical",
    CirclePlusIconName: "CirclePlus",
    DownloadIconName: "Download",
    FilterIconName: "Filter",
    AddCaseOverlayStylesClearButton: "AddCaseOverlayStylesClearButton",
    AddCaseOverlayStylesSearchButton: "AddCaseOverlayStylesSearchButton",
    AddCaseOverlaySingleSearchButton: "AddCaseOverlaySingleSearchButton",
    DealCountryDropdownId: "dealcountrydropdown",
    NotAvailableTextMargin: "notAvailableTextMargin",
    ResellerDistributorULClass: "names-list",
    ResellerDistributorListClass: "names-li-item",
    ChannelGridCellContentAlign: "channel-grid-cell-content-align",
    ActiveClass: "active",
    ResetIconName: "Undo",
    ExtendIconName: "Clock",
    ExpireIconName: "IncidentTriangle",
    RenewIconName: "RepeatAll",
    CopyIconName: "Copy",
    CustomerIcon: "AccountManagementIcon",
    BackButtonClass: "Back",
    NextButtonClass: "Forward",
    CustomerSearchBoxContainer: "customerSearchBoxContainer",
    AddToCasePanelClass: "add-to-case-panel",
    KendoRightToLeftClass: "k-rtl",
    DirectionRightToLeftClass: "rtl",
    KendoButtonLinkClass: "k-button-link",
    AddToCaseResellerGridClass: "gridStyle",
    AddToCaseResellerRadioClass: "reseller-radio",
    customerFilter: "customer-filter",
    partnerDetailCase: "partner-case-detail",
    AddToCaseButtonClass: "add-to-case-button",
    backgroundColorWhite: "backgroundColorWhite",
    PanelBarMaxWidth: "panelbar-wrapper",
    RefreshIconName: "Refresh"
}