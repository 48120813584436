import { ICellRenderProps } from "./ICellRenderProps"
import { IRowRenderProps } from "./IRowRenderProps"
import * as React from 'react';
import { isNullOrEmpty } from "../common/util";
//used to render kendo grid cells as editable cell
export const CellRender = (props: ICellRenderProps) => {
    const dataItem = props.originalProps.dataItem;
    const cellField = props.originalProps.field || "";
    const inEditField = dataItem[props.editField || ''];
    const additionalProps = cellField && cellField === inEditField ?
        {
            ref: (td: any) => {
                const input = td && td.querySelector('input');
                const activeElement = document.activeElement;

                if (!input ||
                    !activeElement ||
                    input === activeElement ||
                    !activeElement.contains(input)) {
                    return;
                }
                console.log(input);
                if (input.type === 'checkbox') {
                    input.focus();
                    input.title = "chktitle";
                } else {
                    input.select();
                }
            }
        } : {
            onClick: () => { props.enterEdit(dataItem, cellField); }
        };

    const clonedProps: any = { ...props.td.props, ...additionalProps };
    return React.cloneElement(props.td, clonedProps, props.td.props.children);
}
//used to render kendo grid row as editable 
export const RowRender = (props: IRowRenderProps) => {
    let hasInEdit = props.originalProps?.dataItem?.inEdit;
        const trProps = {
            ...props.tr.props,
            onBlur: () => {
                if (!isNullOrEmpty(hasInEdit)) { props.exitEdit(); }
            }
        };
        return React.cloneElement(props.tr, { ...trProps }, props.tr.props.children);
}