import { UIControlsText } from "../../../content/UIControlsText";
import { ILegalEntity } from "../../../models/ILegalEntity";
import { UIControlType } from "../../../models/UIControlType";
import { stackItemCustomerStyles } from "./PricingProgramFormUtils";
import { PageStyles } from "../common/content/Page.Styles";
import { useCommonPPContext } from "../common/context/common-pp-context";
import { smallAlignmentTokens } from "./PricingProgramFormUtils";
import { getEntityDataFromSession, getRuleValidationMessage } from '../../../components/shared/common/util';
import { MessageTypeText } from '../../../models/MessageTypeText';
import { SessionStorageConsts } from "../../../models/SessionStorageConsts";

const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

export function GetCustomerViewJson(customerDetail?: ILegalEntity): any {
    const { commonPPContextState, commonPPContextDispatch } = useCommonPPContext();
    
    //validate customer type and visible fields accordingly
    const isManagedCustomer = (isManagedCustomerField: boolean): boolean => {
        var isManaged = customerDetail?.isManaged;
        return isManagedCustomerField === isManaged
    }
    //validate customer type and visible fields accordingly
    const isCustomerExists = (): boolean => {
        var isExists = true 
        if(!customerDetail?.country)
        {
            isExists = false;
        }
        return isExists;
    }
    
    const CustomerDetailsJson = [
        {
            type: "Stack",
            data: {
                id: "StackForCustDetails",
                'data-nextgen-parent-group': 'View - Customer Details',
                'data-nextgen-parentid': 'View - Customer Details',
                wrap: true,
                horizontal: true,
                verticalAlign:"start",
                visible: isCustomerExists(),
                disableShrink:true,
                tokens: smallAlignmentTokens,
                components: [
                    {
                        type: 'StackItem',
                        data: {
                            id: 'EndCustomercountry',
                            visible: isCustomerExists(),
                            horizontal: "true",
                            disableshrink: 'true',
                            components: [
                                {
                                    type: "CustomTileCardComponent",
                                    data: {
                                        id: "ViewCardCustomer",                                     
                                        baseState: commonPPContextState,
                                        name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDetails", UIControlsText.CustomerDetails),
                                        iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDetailsIcon", UIControlsText.CustomerDetailsIcon),
                                        components: [
                                            {
                                                type: "Stack",
                                                data: {
                                                    id: "StackForAllCustomerStackItems",
                                                    horizontal: true,
                                                    components: [
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                styles: stackItemCustomerStyles,
                                                                align: "start",
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerCountryTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerCountry", UIControlsText.CustomerCountry),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerCountry", UIControlsText.CustomerCountry),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.country,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerName',
                                                                visible: isManagedCustomer(true),
                                                                horizontal: "true",
                                                                disableshrink: 'true',
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerNameValueField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerNameText", UIControlsText.CustomerNameText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerNameText", UIControlsText.CustomerNameText),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.legalEntityName,
                                                                            styles: PageStyles.textFieldStylesOneColumn
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerDomain',
                                                                visible: isManagedCustomer(true),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerDomainTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDomainText", UIControlsText.CustomerDomainText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDomainText", UIControlsText.CustomerDomainText),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            styles: PageStyles.textFieldStylesOneColumn,
                                                                            value: customerDetail?.dealCustomerDomain,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerIndustry',
                                                                visible: isManagedCustomer(false),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerIndustryTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Industry", UIControlsText.Industry),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Industry", UIControlsText.Industry),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.industry,
                                                                            styles: PageStyles.textFieldStylesOneColumn
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerName',
                                                                visible: isManagedCustomer(false),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerIndustryTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerNameEnglish", UIControlsText.CustomerNameEnglish),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerNameEnglish", UIControlsText.CustomerNameEnglish),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.legalEntityName,
                                                                            styles: PageStyles.textFieldStylesOneColumn
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerName',
                                                                visible: isManagedCustomer(false),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerIndustryTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerNameLocalLanguage", UIControlsText.CustomerNameLocalLanguage),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerNameLocalLanguage", UIControlsText.CustomerNameLocalLanguage),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.organizationNameInLocalLanguage,
                                                                            styles: PageStyles.textFieldStylesOneColumn,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerAddress',
                                                                visible: isManagedCustomer(false),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerIndustryTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddressFacetText", UIControlsText.AddressFacetText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddressFacetText", UIControlsText.AddressFacetText),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.address,
                                                                            styles: PageStyles.textFieldStylesOneColumn,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerVertical',
                                                                visible: isManagedCustomer(false),
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerVerticalTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Vertical", UIControlsText.Vertical),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Vertical", UIControlsText.Vertical),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.vertical,
                                                                            styles: PageStyles.textFieldStylesOneColumn,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerVertical',
                                                                visible: isManagedCustomer(false),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerVerticalCategoryTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "VerticalCategory", UIControlsText.VerticalCategory),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "VerticalCategory", UIControlsText.VerticalCategory),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.verticalCategory,
                                                                            styles: PageStyles.textFieldStylesOneColumn
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerWebPageURL',
                                                                visible: isManagedCustomer(false),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerWebpageTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "WebPageUrl", UIControlsText.WebPageUrl),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "WebPageUrl", UIControlsText.WebPageUrl),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.webPageURL,
                                                                            styles: PageStyles.textFieldStylesOneColumn,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerEmailDomain',
                                                                visible: isManagedCustomer(false),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerEmailDomainTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailDomain", UIControlsText.EmailDomain),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailDomain", UIControlsText.EmailDomain),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.emailDomain,
                                                                            styles: PageStyles.textFieldStylesOneColumn,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                ]
            }
        },
    ]

    
    return CustomerDetailsJson;
}
