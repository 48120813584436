import { Store } from "azsearchstore";
import { DefaultButton, PrimaryButton, Stack, StackItem } from "office-ui-fabric-react";
import React from "react";
import { useState } from "react";
import AutoSuggest from "react-autosuggest";
import { _Styles } from "../../../content/styles/Page.styles";
import { ISearchProps } from "./ISearchProps";
import { UIControlsText } from '../../../../src/content/UIControlsText';
import { isFieldDisabled, isNullOrEmpty } from "../common/util";
import { PageStyles } from "../../../pages/pricingprogram/common/content/Page.Styles";
import { MessageTypeText } from '../../../models/MessageTypeText';
import { getRuleValidationMessage, getEntityDataFromSession } from "../../../components/shared/common/util";
import { SessionStorageConsts } from '../../../models/SessionStorageConsts';

const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings)
/**
 * SearchBar Component.
 * @function component
 */
export const SearchBar: React.FunctionComponent<ISearchProps> = (props: ISearchProps) => {

    let state = props.store.getState();
    const [value, setValue] = useState("");
    const [suggestions, setSuggestions] = useState<string[]>([]);
    //function to get suggestions based on user type
    function getSuggestions(): string[] {
        let suggestionResults = state.suggestions.suggestions.map((suggestion: any) => {
            return suggestion[state.parameters.suggestionsParameters.select];
        });
        if (props.showDistinctSuggestions != undefined && props.showDistinctSuggestions) {
            return [...new Map(suggestionResults.map((item: any) => [item, item])).values()];
        }
        else {
            return suggestionResults;
        }
    }
    function getSuggestionValue(suggestion: any) {
        return suggestion;
    }
    //search data on click of search
    function onSubmit() {
        let formatValue = value.split(",");
        if ((props.hideSlashFormatter == undefined || !props.hideSlashFormatter ) && !isNullOrEmpty(value) && formatValue.length > 0) {
            formatValue = formatValue.map((eachSearchValue) => {
                return '"' + eachSearchValue + '"'
            });
        }
        props.store.setInput(formatValue.join());
        props.onSubmit(formatValue.join());
    }

    function onSearchKeyPress(event: any): void {
        if (event.keyCode === 13) {
            setTimeout(() => { onSubmit(); }, 200)
        }
    }

    function onClear() {
        setValue("");
        props.onClear();
    }
    function assignSearchValue(searchValue: string): void {
        setValue(searchValue);
        props.store.setInput(searchValue);
        if (props.onSuggest != undefined) {
            let filterCondition = props.onSuggest(searchValue);
            props.store.updateSuggestionsParameters({
                filter: filterCondition
            });
        }
        props.store.suggest();
    }
    function onShowFacets() {
        props.onShowFacets?.();
    }
    const showSearchButton = () => {
        if (!isNullOrEmpty(props.hideSearchBtn)) {
            return props.hideSearchBtn;
        }
        else {
            return !isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SearchButton", UIControlsText.SearchButton), props?.pageMode, props?.opportunityDealStatusCode)
        }
    }

    
    return (
        <div className={[_Styles.SearchBarContainer, props.overrideCss].join(" ")}>
            <AutoSuggest
                suggestions={suggestions}
                onSuggestionsClearRequested={() => setSuggestions([])}
                onSuggestionsFetchRequested={({ value }) => {
                    setSuggestions(getSuggestions());
                    //onHandleChange(value);
                }}
                onSuggestionSelected={(_, { suggestionValue }) =>
                    console.log(suggestionValue)
                }
                getSuggestionValue={getSuggestionValue.bind(this)}
                renderSuggestion={(suggestion) => <span>{suggestion}</span>}
                inputProps={{
                    placeholder: props.placeHolderText,
                    value: value,
                    minLength: 3,
                    onChange: (_, { newValue, method }) => {
                        assignSearchValue(newValue);
                    },
                    onKeyUp: (e) => {
                        onSearchKeyPress(e);
                    },
                }}
                highlightFirstSuggestion={true}
                id={props.searchID}
      

            />
            <Stack horizontal>
                <StackItem>
                    <PrimaryButton text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SearchButton", UIControlsText.SearchButton)} styles={PageStyles.commandButtonPadding} onClick={onSubmit}
                        className="mt-0 me-1" disabled={showSearchButton()}
                    />
                </StackItem>
                <StackItem>
                    <DefaultButton
                        text={props.showFacetsText} styles={PageStyles.commandButtonPadding} aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ShowSearchFilter", UIControlsText.ShowSearchFilter)}
                        className="mt-0  me-1" onClick={onShowFacets} hidden={props.hideFacetsButton}
                    />
                </StackItem>
                <StackItem>
                    <DefaultButton text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Clear", UIControlsText.Clear)} styles={PageStyles.commandButtonPadding} onClick={onClear} className="mt-0  me-1 p-1" />
                </StackItem>
            </Stack>
        </div>
    );
};
