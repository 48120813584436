import { IRenderFunction, ITextFieldProps, Stack, StackItem } from "@fluentui/react";
import Tooltip from "../../../components/shared/tooltip";
import { DefaultPageStyles } from "../../../content/styles/Page.styles";

/**
 * For textbox rendering the lable with tooltip.
 * @guidanceText {string}
 */
const DefaultLabelRendererText = (guidanceText?: string) => (
    props: ITextFieldProps,
    defaultRender: IRenderFunction<ITextFieldProps>): JSX.Element => {

    return (
        <>
            <Stack horizontal verticalAlign="center" >
                <StackItem style={props?.required === true ? {} : DefaultPageStyles.paddingRight10}>
                    <span>{defaultRender(props)}</span>
                </StackItem>
                <StackItem>
                    <Tooltip {...{ guidanceText: guidanceText, id: props.id }}></Tooltip>
                </StackItem>

            </Stack>
        </>
    );
};

export default DefaultLabelRendererText;