import React from 'react'
import { IStyle, mergeStyleSets, ActionButton, IIconProps, FontWeights } from 'office-ui-fabric-react';
import { NotificationListItem, ISearchResult, ISearchSuggestion, CoherenceHeader, CoherencePanelSize } from '@cseo/controls';
import { FontSizes } from '@cseo/styles';
import { getUserSession } from './common/util';
import { SessionStorageConsts } from '../../models/SessionStorageConsts';
import { UIControlsText } from '../../content/UIControlsText';
import { getRuleValidationMessage, getEntityDataFromSession } from "./common/util";
import { MessageTypeText } from "../../models/MessageTypeText";

const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

interface HeaderProps {
    onShowMoreResultsCallback?: (searchtext: string, searchResults: ISearchResult[]) => void;
}
type PivotOption = 'general' | 'releaseNote' | 'criticalNotification';

interface HeaderState {
    newNotifications: number;
    notificationItems: NotificationListItem[];
    showDismissAllBool: boolean;
    ocvButtonIsFocused: boolean;
    dismissOpenedPanel?: boolean;
    searchResults: ISearchResult[];
    recentSearchTexts: ISearchSuggestion[];
    selectedPivot: PivotOption;
    userName: string;
    emailAddress: string;
}

// #region Notification Panel
const initialNotifications: NotificationListItem[] = [];

const openItem = (itemKey: string) => {
    const items = initialNotifications.filter(notificationItem => notificationItem.itemKey === itemKey);
    alert('Open notification: ' + items[0].subjectHeader + (items[0].senderName ? ' from ' + items[0].senderName : ''));
}
// #endregion

export class Header extends React.Component<HeaderProps, HeaderState> {
    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            newNotifications: 0,
            notificationItems: initialNotifications,
            showDismissAllBool: false,
            ocvButtonIsFocused: false,
            dismissOpenedPanel: undefined,
            searchResults: [],
            recentSearchTexts: [],
            selectedPivot: 'general',
            userName: '',
            emailAddress: ''
        };
    }
    componentDidMount() {
        var userDetails = getUserSession()
        let fullName = ""
        let email = ""
        if (userDetails != null) {
            let loggedInUserInfo = JSON.parse(userDetails);
            fullName = loggedInUserInfo.firstName + ' ' + loggedInUserInfo.lastName
            email = loggedInUserInfo.email
            this.setState({ userName: fullName });
            this.setState({ emailAddress: email });
        }
        //Accessibility Fix to Add Heading Level
        var pageHeading = document.querySelectorAll(`a[aria-label= '${ getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PageHeaderLabel", UIControlsText.PageHeaderLabel) }']`);
        pageHeading && pageHeading.forEach((element) => {
            element.setAttribute("role", "heading");
            element.setAttribute("aria-level", "1");
        });
    }

    // #region   Notification Panel States Private Functions
    private _onDismiss = (id: string[]): void => {
        this.setState({ showDismissAllBool: true });
    };

    private _updateItem = (
        itemKey: string,
        dStatus: NotificationListItem['displayStatus'],
        rStatus?: NotificationListItem['status']
    ) => {
        const list = [...this.state.notificationItems];
        list.map((item) => {
            if (item.itemKey === itemKey) {
                item.displayStatus = dStatus;
                if (rStatus) {
                    item.status = rStatus;
                }
            }
        });
        this.setState({ notificationItems: list });
    };

    private _updateItemDisplayStatus = (): void => {
        this.setState({ newNotifications: -1 });
    };

    private _onUndoDismissAll = () =>
        this.setState({ showDismissAllBool: false });
    // #endregion

    private _getHeaderMessage = () : JSX.Element | null => {
        var message = null
        if(process.env.REACT_APP_ENVTYPE !== undefined && process.env.REACT_APP_ENVTYPE === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "TestEnvType", UIControlsText.TestEnvType)) {
            message = <span className={this.headerStyles.root}>{ " - " + getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PageHeaderLabelTestEnv", UIControlsText.PageHeaderLabelTestEnv) }</span>;
        }
        return message;
    }

    public render(): JSX.Element {
        return (
            <CoherenceHeader
                headerLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PageHeaderLabel", UIControlsText.PageHeaderLabel)}
                appNameSettings={{
                    // @ts-ignore
                    label: <div>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PageHeaderLabel", UIControlsText.PageHeaderLabel)}{this._getHeaderMessage()}</div>,
                    linkUrl: process.env.REACT_APP_HOME_PAGE_URI || ""
                }}
                farRightSettings={{
                    profileSettings: {
                        fullName: this.state.userName,
                        emailAddress: this.state.emailAddress,
                        imageUrl: '',
                        logOutLink: process.env.REACT_APP_RSLR_LOGOUT_API_URI || "",
                        body: <div />,
                        onLogOut: (() => this._onLogout())
                    }
                }}
                dismissOpenedPanel={this.state.dismissOpenedPanel}
                onDismissOpenedPanel={() => {
                    this.setState({ dismissOpenedPanel: undefined });
                }}
                styles={{
                    dividerStyle: {
                        height: '0px',
                        marginBottom: '0px',
                        marginTop: '0px',
                    }
                }}
            />
        );
    }

    private settingsStyles = mergeStyleSets({
        root: {
            marginLeft: '-10px',
            marginRight: '-10px'
        } as IStyle
    });

    private headerStyles = mergeStyleSets({
        root: {
            color: '#FF7F50',
        } as IStyle
    });

    // #region   Help Panel 
    private helpLinkStyles = mergeStyleSets({
        helpLink: {
            margin: '8px 0',
            display: 'block',
            fontSize: FontSizes.size14,
            fontWeight: FontWeights.semibold
        } as IStyle,
        helpList: {
            listStyle: 'none',
            paddingLeft: '0'
        } as IStyle
    });
    // #endregion

    // #region   Settings Panel 
    private settingsIcon: IIconProps = { iconName: 'Settings' };

    private ringerIcon: IIconProps = { iconName: 'Ringer' };

    private favoriteStarIcon: IIconProps = { iconName: 'FavoriteStar' };

    private touchIcon: IIconProps = { iconName: 'Touch' };

    private settings: JSX.Element = (
        <div className={this.settingsStyles.root}>
            <ul className={this.helpLinkStyles.helpList}>
                <li>
                    <ActionButton iconProps={this.settingsIcon}>
                        General
                    </ActionButton>
                </li>
                <li>
                    <ActionButton iconProps={this.ringerIcon}>
                        Notifications
                    </ActionButton>
                </li>
                <li>
                    <ActionButton iconProps={this.favoriteStarIcon}>
                        Favorites
                    </ActionButton>
                </li>
                <li>
                    <ActionButton iconProps={this.touchIcon}>
                        Accessibility
                    </ActionButton>
                </li>
            </ul>
        </div>
    );

    // #endregion

    private _onLogout() {
        // Remove user session saved data from sessionStorage
        sessionStorage.removeItem(SessionStorageConsts.loggedInUserInfo);
        // Remove all saved data from sessionStorage
        sessionStorage.clear();
    }
    // #endregion
}