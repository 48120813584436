/**
 * Enum for OpportunityDealClosureDecisionType.
 * @readonly
 * @enum {number}
 */
export enum OpportunityDealClosureDecisionType {
    /**
    * Approved value.
    */
    Approved = 441820000,

    /**
    * ApprovedPendingChannel value.
    */
    ApprovedPendingChannel = 441820002,

    /**
    * EditPendingReview value.
    */
    EditPendingReview = 441820004,

    /**
    * Denied value.
    */
    Denied = 441820001,

    /**
    * InsufficientInformation value.
    */
    InsufficientInformation = 441820003,

    /**
     * RequireAdditionalInformation value.
     */
    RequireAdditionalInformation = 441820005,
}