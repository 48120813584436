/**
 * Enum for ChannelStructure.
 * @readonly
 * @enum {Enum}
 */
export enum ChannelStructure {
    /**
     * Cascade CAS-ID to Customer value.
     */
    Customer = 1,

    /**
     * Cascade CAS-ID only to ADD value.
     */
    Distributor = 2,

    /**
     * Skip Channel value.
     */
    Skip = 3
}