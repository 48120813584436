import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { populateGridColumns, getUserRole, getUserSession, readGridConfig, getPageNumberFromSession, getRuleValidationMessage, getEntityDataFromSession } from "../../components/shared/common/util";
import { GridType } from "../../models/GridType";
import "../../content/styles/AddCaseGrid.css";
import DashboardGrid from "../../components/shared/grid/dashboardgrid";
import { IGridProps } from "../../components/shared/grid/IGridProps";
import { DealType } from "../../models/DealType";
import { IDashboardDataRequest } from "../../models/IDashboardDataRequest";
import { nextGenService } from "../../services/NextGenService";
import { Stack, Spinner, MessageBar, CommandBarButton, FontIcon, MessageBarType } from "office-ui-fabric-react";
import { UIControlsText } from "../../content/UIControlsText";
import { JavaScriptExtensions } from "../../infrastructure/JavaScriptExtensions";
import { IOpportunityDealProductSku } from "../../models/IOpportunityDealProductSku";
import { CssClassConstants } from "../../content/CssClassConstants";
import { AddToCase } from "./add-to-case";
import Row from "react-bootstrap/esm/Row";
import { UserSubRole } from "../../models/UserSubRole";
import { RefreshButtonStyles, _Styles } from "../../content/styles/Page.styles";
import { PageConstants } from "../pageconstants/Constants";
import { IconButton } from "@fluentui/react";
import { Guid } from "../../infrastructure/Guid";
import { MessageTypeText } from '../../models/MessageTypeText';
import { SessionStorageConsts } from "../../models/SessionStorageConsts";


export const SpecialPricingProductInformation: FC = () => {
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const [productInformation, setproductInformation] = useState<IGridProps>(readGridConfig(GridType.ProductInformationView));
    const [isCaseLoading, setIsCaseLoading] = useState<boolean>(false);
    const [pageNumber, setpageNumber] = useState<number>(1)
    const [userRoles] = useState<string>(getUserRole());
    const [productInformationData, setproductInformationData] = useState<IOpportunityDealProductSku[]>([]);
    const [msgBarText, setMsgBarText] = useState<string>();
    const [hideDialog, setHideDialog] = useState<boolean>(true);
    const [errorMessageType, setErrorMessageType] = useState(MessageBarType.success);
    const [showMessageBarOnDashboard, setshowMessageBarOnDashboard] = useState<boolean>(false);
    const resetDashboardMessageBarChoice = useCallback(() => setshowMessageBarOnDashboard(false), []);

    useEffect(() => {
        productInformation.kendoGridProps = populateGridColumns(productInformation.kendoGridProps)
        productInformation.kendoGridProps.noOfRecords = getPageNumberFromSession(productInformation.kendoGridProps)
        setproductInformation(productInformation)
        getProductInformationData(pageNumber)
    }, []);

    const getProductInformationData = async (pageNo?: number, dataState?: any) => {
        try {
            const userSession = getUserSession();
            setIsCaseLoading(true);
            let loggedInUserInfo = JSON.parse(userSession);
            let roles = userRoles.split(",");
            let sort: any
            let filter: any
            if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(dataState)) {
                if (dataState.sort[0] && !JavaScriptExtensions.isEmptyOrNullOrUndefined(dataState.sort[0].field)) {
                    sort = dataState.sort[0].field + " " + dataState.sort[0].dir
                }
                filter = JSON.stringify(dataState.filter)
            }
            const dashboardRequest: IDashboardDataRequest = {
                userRoleDetails: loggedInUserInfo.userRoleDetails,
                dealType: DealType.SpecialPricing,
                email: loggedInUserInfo.email,
                userRoles: roles.join(","),
                filter: filter,
                pageNumber: pageNo,
                recordsToFetch: productInformation.kendoGridProps.recordsToFetch,
                sort: sort,
                isAllCasesDashboardRequest: false,
            };
            let apiReturnedData = await Promise.resolve(nextGenService.getProductinformationDashboardData(dashboardRequest));
            if (apiReturnedData.status === 200) {
                apiReturnedData.data = apiReturnedData.data.map((item: IOpportunityDealProductSku) => ({
                    ...item,
                    dealPeriodStartDate: (JavaScriptExtensions.isNullOrUndfinedOrEmpty(item.dealPeriodStartDate) ? item.dealPeriodStartDate : new Date(item.dealPeriodStartDate as Date)),
                    dealPeriodEndDate: (JavaScriptExtensions.isNullOrUndfinedOrEmpty(item.dealPeriodEndDate) ? item.dealPeriodEndDate : new Date(item.dealPeriodEndDate as Date)),
                }));
                let spConfig = { ...productInformation };
                var finalData: IOpportunityDealProductSku[] = [...apiReturnedData.data, ...productInformationData];
                spConfig.kendoGridProps.data = finalData;
                finalData.forEach((element) => {
                    element.id = Guid.newGuid()
                })
                setproductInformationData(finalData);
                spConfig.kendoGridProps.totalRecords = finalData.length;
                setproductInformation(spConfig);
            }
            productInformation.kendoGridProps.customGridMessage = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "NoRecordsFound", UIControlsText.NoRecordsFound)
            setIsCaseLoading(false);
        } catch (error) {
            productInformation.kendoGridProps.customGridMessage = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "NoRecordsFound", UIControlsText.NoRecordsFound)
            setIsCaseLoading(false);
        }

    }

    const onGetDataRequest = (dataState: any, isSortingOrFilterAppliedOrRemoved?: boolean) => {
        if ((((productInformation.kendoGridProps.data.length - dataState.skip) <= dataState.take) || isSortingOrFilterAppliedOrRemoved === true)) {
            var pageNo = (!isSortingOrFilterAppliedOrRemoved && isSortingOrFilterAppliedOrRemoved !== undefined && isSortingOrFilterAppliedOrRemoved !== null) ?  Math.round(((productInformation.kendoGridProps.data.length / productInformation.kendoGridProps.recordsToFetch) + 1)) : 1;
            setpageNumber(pageNo);
            getProductInformationData(pageNo, dataState)
        }
    }

    function addCase() {
        return (!userRoles.includes(UserSubRole.ADD)) ? "" : (<CommandBarButton onClick={() => { setHideDialog(false); }}>
            <div className={_Styles.CommandBarButton}>
                <FontIcon className={_Styles.DashboardIcons} aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddCaseAriaLabel", UIControlsText.AddCaseAriaLabel)} iconName={CssClassConstants.CirclePlusIconName}></FontIcon>
                <span className={_Styles.DashboardBtnText}>{PageConstants.AddCasToDashboard}</span>
            </div>
        </CommandBarButton>)
    }

    const onRefresh = () => {
        let productConfig = { ...productInformation };
        productConfig.kendoGridProps.data = []
        productConfig.kendoGridProps.isRefresh = true
        productConfig.kendoGridProps = populateGridColumns(productConfig.kendoGridProps)
        productConfig.kendoGridProps.noOfRecords = getPageNumberFromSession(productConfig.kendoGridProps)
        setproductInformation(productConfig)
        productConfig.kendoGridProps.customGridMessage = ""
        setpageNumber(1)
        setproductInformationData([])
        getProductInformationData(1)
    }

    const onSuccess = (showMessageBar: boolean, messageType: any, messageText: string, isReloadData: boolean, resetHideDialog: boolean): void => {

        if (resetHideDialog) {
            setHideDialog(resetHideDialog);
        }

        if (showMessageBar) {
            setErrorMessageType(messageType);
            setMsgBarText(messageText);
            setshowMessageBarOnDashboard(true);
        }
        if (isReloadData) {
            onRefresh()
        }
    };

    function addRefreshIcon() {
        return (
            <CommandBarButton onClick={onRefresh} iconProps={{ iconName: CssClassConstants.RefreshIconName }} ariaLabel={CssClassConstants.RefreshIconName} title="Refresh" styles={RefreshButtonStyles}>
                <span className={_Styles.DashboardBtnText}>{PageConstants.Refresh}</span>
            </CommandBarButton>
        )

    }

    return (
        <Fragment>
            {(!userRoles.includes(UserSubRole.ADD)) ? (
                <div id="RefreshIconButton" className={_Styles.CommandBarButton}>
                    {addRefreshIcon()}
                </div>
            ) : <div id="RefreshButton" className={_Styles.CommandBarButton}>
                {addRefreshIcon()}
            </div>}
            <div className={CssClassConstants.AddToCaseButtonClass}>
                {addCase()}
            </div>
            <div data-nextgen-parent-group="Dashboard - Product Information" data-nextgen-parentid="Dashboard - Product Information">
            <Stack>
                <Row>
                    {showMessageBarOnDashboard && (
                        <Stack>
                            <div>
                                <MessageBar messageBarType={errorMessageType} onDismiss={resetDashboardMessageBarChoice} dismissButtonAriaLabel="Close" isMultiline={true}>
                                    {msgBarText}
                                </MessageBar>
                            </div>
                        </Stack>
                    )}
                </Row>
                {isCaseLoading &&
                    <Stack>
                        <div>
                            <Spinner label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LoadingCases", UIControlsText.LoadingCases)} />
                        </div>
                    </Stack>
                }
                <DashboardGrid
                    {...{

                        kendoGridProps: { ...productInformation.kendoGridProps }, onGetDataRequest: onGetDataRequest

                    }}
                />
            </Stack>
            </div>            
            <Stack horizontalAlign="end">
                {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductinformationFooterText", UIControlsText.ProductinformationFooterText)}
            </Stack>
            <div id="footerPadding"></div>
            {/* Add Case Model */}
            <AddToCase {...{ hideDialog: hideDialog, onSuccess }} />
        </Fragment>
    );
};