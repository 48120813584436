/**
 * Enum for StateCode.
 * @readonly
 * @enum {number}
 */
export enum StateCode {

    /**
     * Active value.
     */
    Active = 0,

    /**
     * InActive value.
     */
    InActive = 1

}