import { itemAlignmentsStackTokens, stackItemCustomerStyles, stackItemStyles } from '../../PricingProgramForm/PricingProgramFormUtils';
import { defaultColumnProps, _Styles } from '../../../../content/styles/Page.styles';
import { useInternalPricingContext } from '../../CPStrategic/context/internalpricing-context';
import { useEffect, useState } from 'react';
import { IGridProps } from "../../../../components/shared/grid/IGridProps";
import { getEntityDataFromSession, getURLParamValue, readGridConfig, getRuleValidationMessage } from '../../../../components/shared/common/util';
import { GridType } from '../../../../models/GridType';
import { UIControlType } from '../../../../models/UIControlType';
import { UIControlsText } from '../../../../content/UIControlsText';
import { IProductType } from '../../../../models/IProductType';
import { IProductFamily } from '../../../../models/IProductFamily';
import { IDealType } from '../../../../models/IDealType';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { ProductStructure } from '../../../../models/ProductStructure';
import { OpportunityDealStatusCodes } from '../../../../models/OpportunityDealStatusCodes';
import { MessageTypeText } from '../../../../models/MessageTypeText';
import DocumentCardContainerComponent from '../../../shared/ComponentTypes/CustomTileCardContainer';
import { Stack } from 'office-ui-fabric-react/lib/components/Stack';
import { PageStyles } from '../../common/content/Page.Styles';
import { ApplicationConstants } from '../../../../models/ApplicationConstants';
import { DealType } from '../../../../models/DealType';

export function InternalPricingProductDetails() {
    const { internalPricingContextState } = useInternalPricingContext();
    const [internalPricingProductGridConfig, setinternalPricingProductGridConfig] = useState<IGridProps>(readGridConfig(GridType.InternalPricingProducts));
    const [productGroup, setProductGroup] = useState<string>("");
    const [dealType, setDealType] = useState<string>("");
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

    useEffect(() => {
        // to read product information from context
        if (internalPricingContextState?.opportunityDeals[0]?.id) {
            var oppDeal = internalPricingContextState.opportunityDeals[0];            
            const dealTypes = getEntityDataFromSession<IDealType>(SessionStorageConsts.dealTypes);
            const dealTypeName = dealTypes.find(t => t.id === oppDeal.dealType?.id)?.name;
            var products = oppDeal.products;
            setDealType(dealTypeName || "");  
            let groupedbyPFAM = [
                ...new Map(
                    products?.map((item: IProductFamily) => [item.productFamilyCode, item])
                ).values(),
            ];
            let Config = { ...internalPricingProductGridConfig };
            var data = groupedbyPFAM.filter(d => d.productStructure === ProductStructure.ProductFamily);
            if (oppDeal.statusCode === OpportunityDealStatusCodes.Draft) {
                data = groupedbyPFAM;
            }
            Config.kendoGridProps.data = data;
            setinternalPricingProductGridConfig({ ...Config });  
            
            // If OTLE deal type then show quantity column
            if(dealTypeName === DealType.OTLE){
                Config.kendoGridProps.columns.filter(x => x.key == ApplicationConstants.Quantity.toLowerCase()).forEach((item) => {
                    item["isVisible"] = true;
                });
            }    

            let productTypes: IProductType[] = [];
            let productTypesInSession = sessionStorage.getItem(SessionStorageConsts.productTypes);
            if (productTypesInSession !== null && internalPricingContextState.productGroup) {
                productTypes = JSON.parse(productTypesInSession);
                let productTypeSelected : IProductType[] = productTypes.filter(product => product.productId == internalPricingContextState.productGroup);
                if (productTypeSelected) {
                    setProductGroup(productTypeSelected[0].productName)
                }
            }            
        }
    }, [internalPricingContextState.opportunityDeals[0].products])

    const ProductDetailsJson = {
        components:        
        [
        {
            type: "CustomTileCardComponent",
            data: {
                id: "ViewCardForProductDetails",
                baseState: internalPricingContextState,
                name: 'Product Details',
                iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductVariantIConName", UIControlsText.ProductVariantIConName),
                components: [
                    {
                        type: "Stack",
                        data: {
                            id: "StackForProductsTable",
                            wrap: true,
                            vertical: true,
                            verticalAlign: "start",                               
                            tokens: itemAlignmentsStackTokens,
                            components: [
                                {
                                    type: 'StackItem',
                                    data: {
                                        id: 'productgroupdetails',
                                        horizontal: "true",
                                        disableshrink: 'true',
                                        components: [
                                            {
                                                type: 'Col',
                                                visible: true,
                                                data: {
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                contolType: UIControlType.Label,
                                                                id: "CustomerCountryTextField",
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductGroup", UIControlsText.ProductGroup),
                                                                value: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductGroup", UIControlsText.ProductGroup).concat(" ").concat(productGroup),
                                                                className: 'productgroupStyle'
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    type: 'StackItem',
                                    data: {
                                        id: 'ProductDetails',
                                        horizontal: "true",
                                        disableshrink: 'true',
                                        components: [                                            
                                            {
                                                type: 'Col',
                                                visible: true,
                                                data: {
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "NextGenKendoGrid",
                                                            data: {
                                                                ...{
                                                                    kendoGridProps: { ...internalPricingProductGridConfig.kendoGridProps }
                                                                }
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
    ]};
    return (
        <div>
            <Stack horizontal {...defaultColumnProps} styles={PageStyles.labelStyles}>
                <DocumentCardContainerComponent {...ProductDetailsJson} />
            </Stack>
        </div>
    );    
}