/**
 * Enum for UI ControlType.
 * @readonly
 * @enum {UIControlType}
 */
export enum UIControlType {
    /// <summary>
    /// MessageBar
    /// </summary>
    MessageBar = "MessageBar",

    /// <summary>
    /// Label
    /// </summary>
    Label = "Label",

    /// <summary>
    /// SubHeading
    /// </summary>
    SubHeading = "SubHeading",

    /// <summary>
    /// LabelValueView
    /// </summary>
    LabelValueView = "LabelValueView",
    
    /// <summary>
    /// PanelBarItemWithGrid
    /// </summary>
    PanelBarItemWithGrid = "PanelBarItemWithGrid",

    /// <summary>
    /// Text
    /// </summary>
    Text = "Text",

    /// <summary>
    /// HoverCard
    /// </summary>
    HoverCardPlain = "HoverCardPlain",

    /// <summary>
    /// Panel
    /// </summary>
    Panel = "Panel",

    /// <summary>
    /// customer Table
    /// </summary>
    CustomerTable = "CustomerTable",

    /// <summary>
    /// customer Table
    /// </summary>
    CustomerDetails = "CustomerDetails",

    /// <summary>
    /// ActionMenu
    /// </summary>
    ActionMenu = "ActionMenu",

    /// <summary>
    /// MessageWithDialog
    /// </summary>
    MessageWithDialog = "MessageWithDialog",
}