export const PageConstants = {
	AddCountry: "ADD Country",
	AddName: "ADD Name",
	DeleteColumn: "Delete",
	AddDistributor: "Add Distributor",
	AddReseller: "Add Reseller",
	ResellerType: "Reseller Type",
	ResellerCountry: "Reseller Country",
	ResellerName: "Reseller Name",
	SaveAsDraft: "Save as Draft",
	Cancel: "Cancel",
	Label_Overview: "Overview",
	Label_ChannelStructure: "Channel Structure",
	Label_MsStoreRequired: "Ms Store Required",
	ListOfDistributors: "List of Distributors",
	ListOfReseller: "List of Reseller",
	Attachments: "Attachments",
	DealName: "Deal Name",
	DealOverview: "Deal Overview",
	ProductGroup: "Product Group",
	DealGeoCoverage: "Deal Geo Coverage",
	DealCountry: "Deal Country(s)",
	AffiliationType: "Affiliation Type",
	AffiliationTypeOthers: "Affiliation Type Others",
	Continue: "Continue",
	Country: "Country",
	SearchFilter: "Search Filter",
	AddSelectedLegalEntites: "Add Selected Legal Entites",
	LegalEntityName: "Legal Entity Name",
	TPID: "TPID",
	Address: "Address",
	City: "City",
	State: "State",
	WebUrl: "Web Url",
	IsPrimary: "Is Primary",
	DealVolume: "Deal Volume",
	BusinessUnit: "Business Unit",
	PFAMCode: "PFAMCode",
	PFAMName: "PFAMName",
	Segment: "Segment",
	DealVertical: "Deal Vertical",
	RequestedExpirationDate: "Requested Expiration Date",
	RequestedExpirationDateJustification: "Requested Expiration Date Justification",
	ExceptionRequest: "Exception Request",
	ProductSearch: "Product Search",
	Search: "Search",
	AddSelectedProducts: "Add Selected Products",
	AddVTeamMember: "Add V-Team Member",
	Name: "Name",
	Alias: "Alias",
	Role: "Role",
	Submit: "Submit",
	PFAM: "PFAM",
	SelectedLegalEntities: "Selected Legal Entities",
	NoLegalEntitySelected: "Please select atleast one legal entity to add",
	NoPrimaryEntitySelected: "Atleast one primary legal entity to be selected",
	CustomerSegmentContainsSMB: "smb",
	CPStrategicDealtype: "CP Strategic",
	OTLEDealtype: "otle",
	CaseId: "Case Id",
	Status: "Status",
	PricingProgram: "Pricing Program",
	CustomerCountry: "Customer Country",
	EndCustomerName: "EndCustomer Name",
	DefaultProductType: "Surface",
	ResellerNameInputPlaceholderText: "e.g., XYZ Ltd.",
    CaseIdInputPlaceholderText: "e.g., CAS-XX-XXXX",
	AddCasToDashboard: "Add Case to Dashboard",
	Expanded: "expanded",
	Refresh: "Refresh",
	PropCPStrategictype: "cpstrategic"
}



