/**
 * Enum for CustomerStatusCodes.
 */

export enum CustomerStatusCodes {

    /**
     * Draft value.
     */
    Draft = 441820000,

  
    /**
    * Approved value.
    */
    Approved = 100000011

   
}