import { FC, FormEvent, Fragment, useEffect, useState } from "react";
import { getUserRole, getUserSession, readGridConfig, getLoggedinUserRoleDetails, getRuleValidationMessage, getEntityDataFromSession } from "../../components/shared/common/util";
import { GridType } from "../../models/GridType";
import "../../content/styles/AddCaseGrid.css";
import DashboardGrid from "../../components/shared/grid/dashboardgrid";
import { IGridProps } from "../../components/shared/grid/IGridProps";
import { DealType } from "../../models/DealType";
import { nextGenService } from "../../services/NextGenService";
import { Stack, Spinner, IBreadcrumbItem, Dropdown, DatePicker, PrimaryButton, Separator, IDropdownOption, SearchBox } from "office-ui-fabric-react";
import { UIControlsText } from "../../content/UIControlsText";
import { JavaScriptExtensions } from "../../infrastructure/JavaScriptExtensions";
import { Breadcrumbs } from "../../components/shared/breadcrumb/breadcrumb";
import Row from "react-bootstrap/esm/Row";
import { PageStyles } from "../pricingprogram/common/content/Page.Styles";
import { BGDatePickerStyles, DashboardHeaderStyle, pageTitleStyles, separatorStyles, showDiscountButtonStyles, _Styles } from "../../content/styles/Page.styles";
import { ComboBox, defaultDatePickerStrings, IComboBox, IComboBoxOption } from "@fluentui/react";
import { CaseDropdown } from "../../components/shared/searchablecombobox/casedropdowncontrol";
import { DiscountViewTypesValues } from "../../services/StaticData";
import { ICountry } from "../../models/ICountry";
import { IProductType } from "../../models/IProductType";
import { IBidGridDeal, Item } from "../../models/IBidGridDeal";
import { IKendoGridColumnProps } from "../../components/shared/grid/IKendoGridColumnProps";
import { IUserRoleDetail } from "../../models/IUserRoleDetail";
import { ApplicationConstants } from "../../models/ApplicationConstants";
import { PageConstants } from "../pageconstants/Constants";
import { MessageTypeText } from '../../models/MessageTypeText';
import { SessionStorageConsts } from '../../models/SessionStorageConsts';
import { useToSetPageTitle as mdppAccessibilitySetPageTitle } from "../../hooks/accessiblity-hooks";
import { PageNames } from "../../hooks/accessiblity-hooks";

const items: IBreadcrumbItem[] = [
    {
        text: 'Discount', key: 'discount'
    },
    {
        text: 'Bid Grid & Non-Profit/EDU Discounts', key: ''
    },
]

export const BidGridCase: FC = () => {
    const [dealBidGrid, setDealBidGrid] = useState<IGridProps>(readGridConfig(GridType.BidGrid));
    const [isCaseLoading, setIsCaseLoading] = useState<boolean>(false);
    const [pageNumber, setpageNumber] = useState<number>(1)
    const [userRoles] = useState<string>(getUserRole());
    const [bgData, setBGData] = useState<IBidGridDeal[]>([]);
    const [allCountries, setAllCountries] = useState<ICountry[]>([]);
    const [productType, setProductType] = useState<IProductType[]>([]);
    const [product, setProduct] = useState<any>('');
    const [country, setCountry] = useState<any>('');
    const [date, setDate] = useState<any>(new Date());
    const [initialData, setInitailData] = useState<IBidGridDeal[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    
    mdppAccessibilitySetPageTitle(PageNames.Discount);
    
    useEffect(() => {
        getAllCountries()
        getProductTypes()
    }, []);

    async function getAllCountries() {
        const { data } = await nextGenService.getAllCountries()
        setAllCountries(data);
    }

    async function getProductTypes() {
        const { data } = await nextGenService.getProductTypes()
        setProductType(data);
    }

    const excelExport = (finalData: IBidGridDeal[], gridColumns: string[]) => {
        let excelData: any = []
        finalData.map((element) => {
            element.productSku.map((ele) => {
                var excelRow: IBidGridDeal = {} as IBidGridDeal
                excelRow.businessUnitName = element.businessUnit?.name
                excelRow.productFamilyName = element.productFamily?.name
                excelRow.SkuName = ele?.name
                excelRow.SkuNumber=ele?.partNumber
                gridColumns.map((colName: string) => {
                    const customDiscount = element.customDiscounts.find((discount) => discount.discountTier.name === colName);
                    if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(customDiscount)) {
                        excelRow[colName] = customDiscount?.discount + " %"
                    } else {
                        excelRow[colName] = ""
                    }
                });
                excelData.push(excelRow)
            });
        });
        return excelData;
    }

    const getBidGrid = async (pageNo?: number, dataState?: any) => {
        if (product === "" || country === "") {
            return
        }
        try {
            const applicablePriceListGeo = allCountries.find((c: ICountry) => c.countryId === country)?.applicablePriceListGeo;
            const userSession = getUserSession();
            setIsCaseLoading(true);
            let loggedInUserInfo = JSON.parse(userSession);
            let roles = userRoles.split(",");
            let sort: any
            let filter: any
            if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(dataState)) {
                sort = dataState.sort[0].field + " " + dataState.sort[0].dir
                filter = JSON.stringify(dataState.filter)
            }
            let userRoleDetails = getLoggedinUserRoleDetails()
            const dashboardRequest = {
                accountsIds: userRoleDetails.map(function (userRoleDetail: IUserRoleDetail) { return userRoleDetail.accountId }).join(','),
                userRoles: roles.join(","),
                email: loggedInUserInfo.email,
                dealType: DealType.BidGrid,
                tpiDs: [],
                userRoleDetails: loggedInUserInfo.userRoleDetails,
                filter: filter,
                pageNumber: pageNo,
                recordsToFetch: dealBidGrid.kendoGridProps.recordsToFetch,
                sort: "",
                isAllCasesDashboardRequest: false,
                productType: product,
                country: applicablePriceListGeo,
                date: date,
                organizationId: loggedInUserInfo.organizationId
            };
            roles = dealBidGrid.kendoGridProps.columns.filter(col => col.columnTitle === 'Business Unit')[0].allowColumnView;
            let DRConfig = { ...dealBidGrid };
            let i = 0;
            let apiData = await Promise.resolve(nextGenService.getBidGridDashboardData(dashboardRequest));
            if (apiData.status === 200) {
                dealBidGrid.kendoGridProps.columns= dealBidGrid.kendoGridProps.columns.filter(col=>col.isDynamic!==true)
                let selectedData: IBidGridDeal[] = []
                apiData.data.map((element: IBidGridDeal) => {
                    element.customDiscounts.map((ele: any) => {
                        let discount = `discountApiField${i}`
                        var column = {} as IKendoGridColumnProps;
                        if (DRConfig.kendoGridProps.columns.find((c) => c.columnTitle === ele.discountTier.name)) {
                            discount = DRConfig.kendoGridProps.columns.filter(col => col.columnTitle === ele.discountTier.name)[0].apiField;
                            let idx = selectedData.findIndex(item => item.productFamily.name === element.productFamily.name);
                            let newArray = {} as IBidGridDeal;
                            let data = selectedData
                            [idx];
                            newArray = {
                                ...data, [discount]:
                                    ele.discount + " %"
                            }
                            selectedData[idx] = newArray;
                            if (idx === -1) {
                                selectedData.push({
                                    ...element, [discount]: ele.discount + " %"
                                })
                            }
                            
                        } else {
                            column.apiField = discount;
                            column.columnTitle = ele.discountTier.name;
                            column.isVisible = true;
                            column.allowColumnView = roles;
                            column.isDynamic = true;
                            column.width = "200";
                            column.headerClassName="breakWord20";
                            DRConfig.kendoGridProps.columns.push(column);
                            let idx = selectedData.findIndex(item => item.productFamily.name === element.productFamily.name);
                            let newArray = {} as IBidGridDeal;
                            let data = selectedData[idx];
                            newArray = {
                                ...data, [discount]:
                                    ele.discount + " %"
                            }
                            selectedData[idx] = newArray;
                            if (idx === -1) {
                                selectedData.push({
                                    ...element, [discount]: ele.discount + " %"
                                })
                            }
                        }

                        i++
                    })
                })
                var finalData: IBidGridDeal[] = [...selectedData];
                let gridColumns = DRConfig.kendoGridProps.columns.filter((col) => col.isDynamic === true).map((c) => c.columnTitle)
                DRConfig.kendoGridProps.data = finalData;
                const excelData = excelExport(finalData, gridColumns)
                setBGData(finalData);
                DRConfig.kendoGridProps.totalRecords = finalData.length;
                setDealBidGrid(DRConfig);
                DRConfig.kendoGridProps.excelData = excelData;
                DRConfig.kendoGridProps.excelColumns = getExcelColumns(gridColumns);
                setInitailData([...selectedData]);
                DRConfig.kendoGridProps.isFilterAppliedOrRemoved=false
            }
            setIsCaseLoading(false);
        } catch (error) {
            setIsCaseLoading(false);
        }
    }

    const onGetDataRequest = (dataState: any, isFilterAppliedOrRemoved?: boolean) => {
        if ((dealBidGrid.kendoGridProps.data.length >= (dealBidGrid.kendoGridProps.recordsToFetch * pageNumber)) && (dealBidGrid.kendoGridProps.recordsToFetch * pageNumber) <= (dataState.skip + dataState.take)) {
            var pageNo = (bgData.length / dealBidGrid.kendoGridProps.recordsToFetch) + 1
            setpageNumber(pageNo);
            getBidGrid(pageNo, dataState)
        }
        if(isFilterAppliedOrRemoved===true){
            dealBidGrid.kendoGridProps.isFilterAppliedOrRemoved=true
        }
    }

    const onProductChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        setProduct(item?.key)
    };

    const onCountryChange = (event: FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        setCountry(option?.key)
    };

    const onDateChange = (date?: Date | null | undefined) => {
        setDate(date)
    }

    const boundDynamicColumns = (DRConfig: IGridProps, items: IBidGridDeal[]) => {
        let selectedData: IBidGridDeal[] = []
        let roles = dealBidGrid.kendoGridProps.columns.filter(col => col.columnTitle === 'Business Unit')[0].allowColumnView;
        items.map((element: IBidGridDeal) => {
            element.customDiscounts.map((ele: any, index: number) => {
                let discount = `discountApiField${index}`
                var column = {} as IKendoGridColumnProps;
                if (DRConfig.kendoGridProps.columns.find((c) => c.columnTitle === ele.discountTier.name)) {
                    discount = DRConfig.kendoGridProps.columns.filter(col => col.columnTitle === ele.discountTier.name)[0].apiField;
                    var idx = selectedData.findIndex(item => item.productFamily.name === element.productFamily.name);
                    var newArray = {} as IBidGridDeal;
                    var data = selectedData[idx];
                    newArray = {
                        ...data, [discount]:
                            ele.discount + " %"
                    }
                    selectedData[idx] = newArray;
                    if (idx === -1) {
                        selectedData.push({
                            ...element, [discount]: ele.discount + " %", expanded: true
                        })
                    }
                    
                } else {
                    column.apiField = discount;
                    column.columnTitle = ele.discountTier.name;
                    column.isVisible = true;
                    column.allowColumnView = roles;
                    column.width = "200";
                    column.headerClassName="breakWord20";
                    DRConfig.kendoGridProps.columns.push(column);
                    let idx = selectedData.findIndex(item => item.productFamily.name === element.productFamily.name);
                    let newArray = {} as IBidGridDeal;
                    let data = selectedData[idx];
                    newArray = {
                        ...data, [discount]:
                            ele.discount + " %"
                    }
                    selectedData[idx] = newArray;
                    if (idx === -1) {
                        selectedData.push({
                            ...element, [discount]: ele.discount + " %"
                        })
                    }
                }
            })
        })
        return selectedData
    }

    const onChangeHandler = (value: string) => {
            let DRConfig = { ...dealBidGrid };
            var filteredList: IBidGridDeal[] = [];
            setSearchValue(value);
            dealBidGrid.kendoGridProps.filterValue = value;
            const originalData = [...initialData];
            if (value !== "") {
                originalData.map((element) => {
                    if (element.productSku?.filter((ele: Item) => ele.partNumber?.toLowerCase().includes(value.toLowerCase())).length > 0) {
                        var bidgridSKUdeal: IBidGridDeal = { ...element };
                        var filteredSKUList: Item[] = element.productSku?.filter((sku: Item) => sku.partNumber?.toLowerCase().includes(value.toLowerCase()));
                        bidgridSKUdeal.productSku = [...filteredSKUList]
                        filteredList.push(bidgridSKUdeal);
                    }
                })
                const selectedData = boundDynamicColumns(DRConfig, filteredList)
                var finalData: IBidGridDeal[] = [...selectedData];
                DRConfig.kendoGridProps.expandField = PageConstants.Expanded;
                DRConfig.kendoGridProps.data = finalData;
                setBGData(finalData);
                DRConfig.kendoGridProps.totalRecords = finalData.length;
                setDealBidGrid(DRConfig);
            }
            else if (value === "") {
                DRConfig.kendoGridProps.data = [...initialData];
                DRConfig.kendoGridProps.totalRecords = [...initialData].length;
                DRConfig.kendoGridProps.expandField = ""
                setBGData([...initialData]);
                setDealBidGrid(DRConfig);
            }
    }

    const getExcelColumns = (dynamicGridColumns: string[]): IKendoGridColumnProps[]  => {
        let columns: IKendoGridColumnProps[] = [];
        const businessUnitName = Object.assign({} as IKendoGridColumnProps, { apiField: ApplicationConstants.BusinessUnitName, columnTitle: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BusinessUnitExcelColumnTitle", UIControlsText.BusinessUnitExcelColumnTitle)} as IKendoGridColumnProps);
        const productFamilyName = Object.assign({} as IKendoGridColumnProps, { apiField: ApplicationConstants.ProductFamilyName, columnTitle: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductFamilyNameExcelColumnTitle", UIControlsText.ProductFamilyNameExcelColumnTitle)} as IKendoGridColumnProps);
        const skuName = Object.assign({} as IKendoGridColumnProps, { apiField: ApplicationConstants.skuName, columnTitle: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SkuNameExcelColumnTitle", UIControlsText.SkuNameExcelColumnTitle)} as IKendoGridColumnProps);
        const skuNumber = Object.assign({} as IKendoGridColumnProps, { apiField: ApplicationConstants.skuNumber, columnTitle: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "skuNumberExcelColumnTitle", UIControlsText.skuNumberExcelColumnTitle)} as IKendoGridColumnProps);
        columns = [businessUnitName, productFamilyName, skuName,skuNumber];

        dynamicGridColumns.forEach((colName) => {
            const dynamicCol = Object.assign({} as IKendoGridColumnProps, { apiField: colName, columnTitle: colName} as IKendoGridColumnProps);
            columns.push(dynamicCol);
        });

        return columns;
    }
   
    return (
        <Fragment>
            <Row>
                <Breadcrumbs items={items} />
                <Separator styles={separatorStyles} />
            </Row>
            <Row>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                        <h1 className="header" style={DashboardHeaderStyle}>Discount</h1> <span style={pageTitleStyles}> | </span> <CaseDropdown options={DiscountViewTypesValues} onChange={() => { }} disabled={false} defaultSelectedKey={1} />
                    </div>
                </div>
            </Row>
            <Row style={pageTitleStyles.dashboardTitle}>
                <Stack horizontal horizontalAlign="space-between">
                    <Stack horizontal horizontalAlign="start" gap="16">
                        <Dropdown
                            aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BGProductGroup", UIControlsText.BGProductGroup)}
                            placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "BGProductGroupPlaceholder", UIControlsText.BGProductGroupPlaceholder)}
                            label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BGProductGroup", UIControlsText.BGProductGroup)}
                            styles={PageStyles.bgdropdownStyles}
                            required
                            options={productType.map((product: IProductType) => ({ key: product.productId, text: product.productName }))}
                            onChange={onProductChange}
                            selectedKey={product}
                        />
                        <ComboBox
                            id={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BGComboBoxID", UIControlsText.BGComboBoxID)}
                            label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BGCountryLabel", UIControlsText.BGCountryLabel)}
                            required
                            styles={PageStyles.bgdropdownStyles}
                            className={_Styles.dropdown}
                            placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "BGCountryLabelPlaceholder", UIControlsText.BGCountryLabelPlaceholder)}
                            options={allCountries.map((country: any) => ({ key: country.countryId, text: country.countryName }))}
                            onChange={onCountryChange}
                            selectedKey={country}
                        />
                        <DatePicker
                            styles={BGDatePickerStyles}
                            placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DatePlaceHolder", UIControlsText.DatePlaceHolder)}
                            strings={defaultDatePickerStrings}
                            highlightSelectedMonth={true}
                            // value={maxExpirationDate}
                            onSelectDate={onDateChange}
                            textField={{
                                validateOnLoad: false, validateOnFocusOut: true,
                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BGDatePickerLabel", UIControlsText.BGDatePickerLabel),
                                placeholder: Date.now().toString(),
                                required: true,
                                //  onGetErrorMessage: () => { return ErrorMessages.RequiredFieldError},                                  
                            }}
                            value={date}
                        />
                        <PrimaryButton
                            text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BGShowDiscountsText", UIControlsText.BGShowDiscountsText)}
                            ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BGShowDiscountsText", UIControlsText.BGShowDiscountsText)}
                            styles={showDiscountButtonStyles}
                            disabled={!(product !== '' && country !== '')}
                            onClick={() => { setSearchValue(""); getBidGrid(1);}}

                        />
                    </Stack>
                    <Stack horizontal horizontalAlign="end" gap='16'>
                        <SearchBox
                            styles={showDiscountButtonStyles}
                            placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "BGSearchPlaceholder", UIControlsText.BGSearchPlaceholder)}
                            value={searchValue}
                            onChange={(_, newValue: any) => onChangeHandler(newValue)}
                            disabled={initialData.length <= 0}
                        />
                    </Stack>
                </Stack>
            </Row>
            {isCaseLoading &&
                <Stack>
                    <div>
                        <Spinner label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LoadingCases", UIControlsText.LoadingCases)} />
                    </div>
                </Stack>
            }
            <Stack id="BidgridDashboard">
                <DashboardGrid
                    {...{

                        kendoGridProps: { ...dealBidGrid.kendoGridProps }, onGetDataRequest: onGetDataRequest, excelExport: excelExport

                    }}
                />
                <div id="footerPadding"></div>
            </Stack>
        </Fragment>
    );
};
