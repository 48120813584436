import Col from "react-bootstrap/Col";
import React, { useCallback, useEffect, useState } from 'react';
import { DefaultButton, IDropdownOption, Label, MessageBar, MessageBarType, Spinner } from '@fluentui/react';
import { IStackTokens, Stack, StackItem } from '@fluentui/react/lib/Stack';
import { useInternalPricingContext } from '../context/internalpricing-context';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { CaseActionTypes } from '../../common/reducer/CaseActionTypes';
import { UIControlsText } from '../../../../content/UIControlsText';
import { nextGenService } from '../../../../services/NextGenService';
import { IInternalPricingDealDetailsProps } from '../props/IInternalPricingDealDetailsProps';
import { IKeyValue } from '../../../../models/IKeyValue';
import { IDealContact } from "../../../../models/IDealContact";
import Row from "react-bootstrap/Row";
import { Splitter, SplitterOnChangeEvent } from "@progress/kendo-react-layout";
import { SearchBar } from "../../../../components/shared/search/searchbar";
import { AzSearchStore } from "azsearchstore";
import { isEditmode, readGridConfig, getsavedContext, isFieldVisible, getSelectedCountry, getURLParamValue, isNullOrEmpty, getLoggedinUserRoleDetails, isAnyEUCountrySelected, getUserRole, getRuleValidationMessage, getEntityDataFromSession } from "../../../../components/shared/common/util";
import { IGridProps } from "../../../../components/shared/grid/IGridProps";
import { ApplicationConstants } from "../../../../models/ApplicationConstants";
import _ from "lodash";
import { ICountry } from "../../../../models/ICountry";
import { _Styles } from "../../../../content/styles/Page.styles";
import NextGenKendoGrid from "../../../../components/shared/grid/nextgenkendogrid";
import { getter } from "@progress/kendo-react-common";
import { IProductFamily } from "../../../../models/IProductFamily";
import { GridType } from "../../../../models/GridType";
import { IFacetData } from "../../../../models/IFacetData";
import { SearchableComboBoxComponent } from "../../../../components/shared/searchablecombobox/searchablecombobox";
import { PageMode } from "../../../../models/PageMode";
import { ActionType } from "../../../../models/ActionType";
import { IKendoGridColumnProps } from "../../../../components/shared/grid/IKendoGridColumnProps";
import { IOpportunityDealProductSku } from "../../../../models/IOpportunityDealProductSku";
import { PanelBar, PanelBarExpandMode, PanelBarItem } from "@progress/kendo-react-layout";
import { IUserRoleDetail } from "../../../../models/IUserRoleDetail";
import { Guid } from "../../../../infrastructure/Guid";
import { OpportunityDealStatusCodes } from "../../../../models/OpportunityDealStatusCodes";
import { UserSubRole } from "../../../../models/UserSubRole";
import { formatProductSearchAPIData, GetCompactProductData, GetExpandedProductData } from "../../../../components/shared/productDatabyProductview";
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { PageConstants } from "../../../pageconstants/Constants";
import { IProductType } from "../../../../models/IProductType";
import { DealType } from "../../../../models/DealType";
import { getDealQuantityThresholdValue } from "../../PricingProgramForm/PricingProgramFormUtils";
import InnerHTMLContainer from "../../../shared/ComponentTypes/InnerHTMLContainer";
import { UIControlType } from "../../../../models/UIControlType";
import { ErrorMessages } from "../../../../content/ErrorMessages";
import { useToOverridePanelBarTabIndex} from "../../../../hooks/accessiblity-hooks";

const stackTokens: IStackTokens = { childrenGap: 20 };
const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
/**
 * CP Product Search Overview Component.
 * @function component
 */
const InternalPricingProductSearch: React.FunctionComponent<IInternalPricingDealDetailsProps> = (props?: IInternalPricingDealDetailsProps) => {
    //divide page into two section using kendo spliter
    //spliter related: helps to divide page in two sections and define content % here.
    const [panes, setPanes] = useState<Array<any>>();
    React.useEffect(() => {
        setPanes(props?.facetProps?.splitterConfig);
    }, []);
    const onSplitterChange = (event: SplitterOnChangeEvent) => {
        setPanes(event.newState);
        var isCollapsed = event.newState[0].collapsed || false;
        setSearchFilterButtonText(isCollapsed ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ShowSearchFilter", UIControlsText.ShowSearchFilter) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "HideSearchFilter", UIControlsText.HideSearchFilter))
    };
    const { internalPricingContextState, internalPricingContextDispatch } = useInternalPricingContext();
    const [dealContact, setDealContact] = useState<IDealContact[]>([]);
    const [contactRoles, setContactRoles] = useState<IKeyValue[]>([]);
    const [oppdealId, setOppdealId] = useState<string>((internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].id) || "");
    const defaultProductSearchStore = new AzSearchStore();
    const productSearchStore = new AzSearchStore();
    const [productFamilyGridData, setProductFamilyGridData] = useState<IProductFamily[]>([]);
    const [selectedSearchValue, setSelectedSearchValue] = useState<string>("");
    const [selectedCountries, setSelectedCountries] = useState<ICountry[]>([]);
    const [globalSearchFilterExpression, setGlobalSearchFilterExpression] = useState<string>("");
    const [productSearchGridConfig, setProductSearchGridConfig] = useState<IGridProps>(readGridConfig(GridType.ProductSearch));
    const [productFamilyGridConfig, setProductFamilyGridConfig] = useState<IGridProps>(readGridConfig(GridType.PFAMSelection));
    const idGetter = getter(productSearchGridConfig.kendoGridProps.dataItemKey);
    const [productTypeId, setProductTypeId] = useState<string>("");
    const [expandMode, setExpandMode] = useState<PanelBarExpandMode>("single");
    const [selectionState, setSelectionState] = useState<any>({});
    const [searchFilterButtonText, setSearchFilterButtonText] = useState<string>(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ShowSearchFilter", UIControlsText.ShowSearchFilter));
    const [hideSearchFilterButton, setHideSearchFilterButton] = useState<boolean>(true);
    const [isCPStrategicDeal, setIsCPStrategicDeal] = useState<boolean>(false);
    /**
     * Facet Constants
     */
    const [productFamilyCode, setProductFamilyCode] = useState<IFacetData[]>([]);
    const [selectedProductFamilyCodeValues, setSelectedProductFamilyCodeValues] = useState<IFacetData[]>([]);
    const [pfamName, setPfamName] = useState<IFacetData[]>([]);
    const [selectedPfamNameValues, setSelectedPfamNameValues] = useState<IFacetData[]>([]);
    const [businessUnit, setBusinessUnit] = useState<IFacetData[]>([]);
    const [selectedBusinessUnitValues, setSelectedBusinessUnitValues] = useState<IFacetData[]>([]);
    const [skuName, setSkuName] = useState<IFacetData[]>([]);
    const [selectedSkuNameValues, setSelectedSkuNameValues] = useState<IFacetData[]>([]);
    const [skuPartNumber, setSkuPartNumber] = useState<IFacetData[]>([]);
    const [selectedSkuPartNumberValues, setSelectedSkuPartNumberValues] = useState<IFacetData[]>([]);
    const [country, setCountry] = useState<IFacetData[]>([]);
    const [selectedCountryValues, setSelectedCountryValues] = useState<IFacetData[]>([]);
    const [isDefaultSearch, setDefaultSearch] = useState<boolean>(true);
    const [isSearchCleared, setSearchCleared] = useState<boolean>();
    var allFacetNames = ApplicationConstants.InternalPricingProgramDistinctProductSearchFacetNames;
    var allFacets = [selectedProductFamilyCodeValues, selectedPfamNameValues, selectedBusinessUnitValues, selectedCountryValues];
    const [pfamExpandConfig, setPfamExpandConfig] = useState<IGridProps>(readGridConfig(GridType.PFAMExpandView));
    const [pfamCompactConfig, setPfamCompactConfig] = useState<IGridProps>(readGridConfig(GridType.PFAMCompactView));
    const userRoleDetails: IUserRoleDetail[] = getLoggedinUserRoleDetails();
    const oppNumber = getURLParamValue(ApplicationConstants.OpportunityNumberParamName);
    const pageMode = (oppNumber && oppNumber.length > 0 ? isNullOrEmpty(sessionStorage.getItem(SessionStorageConsts.pageMode)) ? PageMode.View : sessionStorage.getItem(SessionStorageConsts.pageMode) : PageMode.Create) || PageMode.Create;
    const [isProductLoading, setIsProductLoading] = useState<boolean>(false);
    const [expandGridVisible, setExpandGridVisible] = useState<boolean>(false);
    const [showProductData, setShowProductData] = useState<boolean>(false);
    const [showWarningMessage, setShowWarningMessage] = useState<boolean>(true);
    const resetWarningMessage = useCallback(() => setShowWarningMessage(false), []);
    const [savedProductFamilyGridData, setSavedProductFamilyGridData] = useState<IProductFamily[]>([]);
    const [showProductDialog, setShowProductDialog] = useState(false);
    const [inactivePFAMs, setInactivePFAMs] = useState<IOpportunityDealProductSku[]>([]);
    const [issearchBarVisible, setIsSearchBarVisible] = useState<boolean>(true);
    const dealType = internalPricingContextState && internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals.length && internalPricingContextState.opportunityDeals[0].dealType?.name?.toLowerCase() || "";

    // Function For Loading data in Expanded View
    const expandedGridData = (productData: IOpportunityDealProductSku[]) => {
        var selectedData: IOpportunityDealProductSku[] = [];
        if (internalPricingContextState?.opportunityDeals[0]?.id) {
            if (productData.length !== 0) {
                selectedData = GetExpandedProductData(productData, pfamExpandConfig);

                let expandGridConfig = { ...pfamExpandConfig };
                expandGridConfig.kendoGridProps.data = selectedData;
                expandGridConfig.kendoGridProps.resizable = true;
                expandGridConfig.kendoGridProps.totalRecords = selectedData.length;

                 // If OTLE deal type hide country column as single country and SKU name column
                if(dealType !== undefined && dealType == PageConstants.OTLEDealtype){
                    expandGridConfig.kendoGridProps.columns.filter(x => x.key == ApplicationConstants.skuName.toLowerCase()).forEach((item) => {
                        item["isVisible"] = false;
                    });
                    expandGridConfig.kendoGridProps.columns.filter(x => x.key == ApplicationConstants.Country.toLowerCase()).forEach((item) => {
                        item["isVisible"] = false;
                    });
                }      
                setPfamExpandConfig({ ...expandGridConfig });
            }
        }
    }

    // Function For Loading data in Expanded View
    const compactGridData = (productData: IOpportunityDealProductSku[]) => {
        var userRole = getUserRole();
        var userSubRole = userRole.split(",");
        var selectedData: IOpportunityDealProductSku[] = [];
        var selectedDataRole: IOpportunityDealProductSku[] = [];

        if (internalPricingContextState?.opportunityDeals[0]?.id) {
            if (productData.length !== 0) {
                selectedData = GetCompactProductData(productData, pfamCompactConfig, isCPStrategicDeal ? DealType.CPStrategic : DealType.OTLE);
                // Add quantity column value from context object for OTLE
                if (!isCPStrategicDeal)
                {
                        internalPricingContextState?.opportunityDeals[0]?.products?.forEach((element:IProductFamily) => {
                        var idx = selectedData.findIndex(item => item.productFamilyId === element.productFamilyId && item.country === element.country)
                        var newArray: IOpportunityDealProductSku;
                        var data = selectedData[idx];                    
                        newArray = {
                            ...data,
                            quantity: element.quantity ? element.quantity : 0,
                        };                   
                        selectedData[idx] = newArray;                                                         
                    });
                }
            }
            else if (internalPricingContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.Unassigned ||
                internalPricingContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.InProgress) {
                if (internalPricingContextState.opportunityDeals[0].products) {
                    selectedData = internalPricingContextState.opportunityDeals[0].products?.map((item: any) => {
                        return {
                            decisionType: item.decisionTypeName,
                            country: item.country,
                            productFamily: item.productFamilyName,
                            businessUnitName: item.businessUnitName,
                            quantity: item.quantity,
                        } as IOpportunityDealProductSku
                    });
                }
            }

            let compactGridConfig = { ...pfamCompactConfig };
            compactGridConfig.kendoGridProps.resizable = true;
            if (userSubRole[0] === UserSubRole.MSStoreUser || userSubRole[0] === UserSubRole.ADD
                || userSubRole[0] === UserSubRole.ADR || userSubRole[0] === UserSubRole.DMP) {
                selectedDataRole = selectedData.filter(item => item.decisionType === ApplicationConstants.ApprovedStatus)
                compactGridConfig.kendoGridProps.data = selectedDataRole;
                compactGridConfig.kendoGridProps.totalRecords = selectedDataRole.length;
            } else {
                compactGridConfig.kendoGridProps.data = selectedData;
                compactGridConfig.kendoGridProps.totalRecords = selectedData.length;
            }
            // If OTLE deal type hide country column as single country and show quantity column
            if(dealType !== undefined && dealType == PageConstants.OTLEDealtype){
                compactGridConfig.kendoGridProps.columns.filter(x => x.key == ApplicationConstants.Country.toLowerCase()).forEach((item) => {
                    item["isVisible"] = false;
                });
                compactGridConfig.kendoGridProps.columns.filter(x => x.key == ApplicationConstants.Quantity).forEach((item) => {
                    item["isVisible"] = true;
                });
            }            
            setPfamCompactConfig({ ...compactGridConfig });            
        }
    }
    useEffect(() => {
        if (internalPricingContextState?.opportunityDeals[0]?.id && internalPricingContextState.pageMode === PageMode.View) {
            var productData: Array<IOpportunityDealProductSku> = []
            const fetchProductsData = async () => {
                setIsProductLoading(true);
                //This method is used to fetch the data from getOpportunityDealDiscountPeriodandSkuMsrp
                if (internalPricingContextState?.opportunityDeals[0]?.id) {
                    let response = await nextGenService.getOpportunityDealDiscountPeriodAndSkuMSRP(internalPricingContextState?.opportunityDeals[0]?.id, userRoleDetails, pageMode, internalPricingContextState?.opportunityDeals[0].dealType?.name || "")           
                    productData = response.data;
                    expandedGridData(productData);
                    compactGridData(productData);
                    setIsProductLoading(false);
                    var caseStatus = (internalPricingContextState.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.Approved ||
                        internalPricingContextState.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.DealPricingApprovedPendingChannelStatus);
                    setExpandGridVisible(caseStatus);
                    setShowProductData(true);
                }
            }
            try {
                fetchProductsData()
            } catch {
                setIsProductLoading(false)
            }
        }
        else if (internalPricingContextState.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.PendingSecondaryReview && internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()) {
            setIsSearchBarVisible(false);
        }
    }, [internalPricingContextState?.opportunityDeals[0]?.id]);

    const allCountries: ICountry[] = JSON.parse(sessionStorage.getItem(SessionStorageConsts.countries) || '{}');
    var selectedGeoCountries: ICountry[] = internalPricingContextState.opportunityDeals[0].geoCountries || [];
    useEffect(() => {
        if (internalPricingContextState?.opportunityDeals[0]?.id) {
            setOppdealId(internalPricingContextState?.opportunityDeals[0]?.id)
        }
        if (internalPricingContextState?.opportunityDeals[0].dealContacts) {
            setDealContact(internalPricingContextState?.opportunityDeals[0].dealContacts.filter((item) => item.action !== ActionType.Delete));
        }

        var contactRoles = sessionStorage.getItem(SessionStorageConsts.contactRoles);
        if (!contactRoles) {
            nextGenService.getContactRoleOptions()
                .then((response) => {
                    sessionStorage.setItem(SessionStorageConsts.contactRoles, JSON.stringify(response.data));
                    setContactRoles(response.data);
                });
        }
        else {
            setContactRoles(JSON.parse(contactRoles));
        }
        if (internalPricingContextState.selectedPivot === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductSelection", UIControlsText.ProductSelection) && (
            internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase() || internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase())) {
            if (internalPricingContextState?.opportunityDeals[0]?.products) {
                let productFamily: IProductFamily[] = internalPricingContextState?.opportunityDeals[0]?.products as IProductFamily[];
                let inActiveproductFamily: IOpportunityDealProductSku[] = internalPricingContextState?.opportunityDeals[0]?.inActiveProducts as IOpportunityDealProductSku[];
                let updatedproductFamily = productFamily.map((item: IProductFamily) => ({ ...item, editMode: isSavedProduct(item)}));

                if(inActiveproductFamily && inActiveproductFamily.length > 0 && internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase() && internalPricingContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.Draft && internalPricingContextState.opportunityDeals[0].dealType?.name?.toLowerCase() === PageConstants.OTLEDealtype){
                    updatedproductFamily = updatedproductFamily.filter(x => inActiveproductFamily.some(y => y.productFamilyId !== x.productFamilyId));
                    setInactivePFAMs(inActiveproductFamily);
                    setShowProductDialog(true);
                }

                setSavedProductFamilyGridData(updatedproductFamily);
                updateProductFamilyAndDispatch(updatedproductFamily);
            }
        }
    }, [sessionStorage.getItem(SessionStorageConsts.contactRoles), internalPricingContextState?.opportunityDeals[0]?.id, internalPricingContextState?.opportunityDeals[0].dealContacts, internalPricingContextState.attachments, internalPricingContextState.selectedPivot]);
    //to identify legal entity is already saved and show delete button accordingly
    const isSavedProduct = (item: IProductFamily): boolean => {
        var isSaved = false;
        var savedOpportunityData = getsavedContext().opportunityDeals[0];
        if (savedOpportunityData && savedOpportunityData.products?.findIndex(c => c.id == item.id) !== -1) {
            isSaved = true;
        }
        return isEditmode(internalPricingContextState.opportunityDeals[0]?.statusCode, isSaved)
    }
    //oppdealId is not holding the id that is already saved in editmode, so comparing and assigning value accordingtly
    const getOppdealId = (): string => {
        var dealId = oppdealId;
        if (internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()) {
            var savedId = getsavedContext().opportunityDeals[0].id;
            dealId = (savedId !== oppdealId && savedId) ? savedId : oppdealId;
        }
        return dealId;
    }
    //dispatch products
    const updateProductFamilyAndDispatch = (productFamily: IProductFamily[]): void => {
        let selectedProductFamilyConfig = { ...productFamilyGridConfig };
        let totalProductQuantity = 0;
        selectedProductFamilyConfig.kendoGridProps.data = productFamily.filter(item => item.action !== ActionType.Delete);
        selectedProductFamilyConfig.kendoGridProps.editMode = isEditmode(internalPricingContextState.opportunityDeals[0]?.statusCode);
        selectedProductFamilyConfig.kendoGridProps.totalRecords = productFamily.filter(item => item.action !== ActionType.Delete).length;
        //to hide clear all button in view mode
        if (selectedProductFamilyConfig.kendoGridProps.actionTypes?.showClearAllGridAction) {
            selectedProductFamilyConfig.kendoGridProps.actionTypes.showClearAllGridAction = (internalPricingContextState.pageMode === PageMode.View ||
                (internalPricingContextState.pageMode === PageMode.Edit && internalPricingContextState.opportunityDeals[0]?.statusCode !== OpportunityDealStatusCodes.Draft)) ? false : true
        }
        setProductFamilyGridConfig({ ...selectedProductFamilyConfig });
        setProductFamilyGridData(productFamily.filter(item => item.action !== ActionType.Delete));
        internalPricingContextDispatch({ type: CaseActionTypes.selectedProductFamilyChanged, products: { key: getOppdealId(), value: productFamily } });

        productFamily.forEach(item => {
            if(item.quantity){
                totalProductQuantity = totalProductQuantity + item.quantity;
            }
        });

        let dealQuantityThresholdForDealType = Number(sessionStorage.getItem(SessionStorageConsts.dealQuantityThresholdConfigs));
        totalProductQuantity >= dealQuantityThresholdForDealType || productFamily.length == 0 ? setShowWarningMessage(false) : setShowWarningMessage(true);
    }
    //this function used to remove products specific to the country that got removed in deal overview
    const resetSelectedData = (dealCountries: string[]): void => {
        if (dealCountries) {
            var countriesDropdownValues: IDropdownOption[] = getSelectedCountry(dealCountries);
            var newSelection: Array<IProductFamily> = [];
            countriesDropdownValues.forEach((countryItem: IDropdownOption) => {
                internalPricingContextState.opportunityDeals[0].products?.filter(
                    (selectedItem: IProductFamily) => selectedItem.country === countryItem.text)
                    .map((filteredItem: IProductFamily) => {
                        newSelection.push(filteredItem);
                    });
            });
            updateProductFamilyAndDispatch(newSelection);
        }
    };
    setSearchStore();
    useEffect(() => {
        if (isDefaultSearch) {
            setDefaultSearchStore();
            if(isCPStrategicDeal){
                allFacetNames = ApplicationConstants.InternalPricingProgramDistinctProductSearchFacetNames;
                allFacets = [selectedProductFamilyCodeValues, selectedPfamNameValues, selectedBusinessUnitValues, selectedCountryValues];
            }
            else{
                allFacetNames = ApplicationConstants.DistinctProductSearchFacetNames;
                allFacets = [selectedPfamNameValues, selectedBusinessUnitValues];
            }
        }
        else {
            setSearchStore();
            if(isCPStrategicDeal){
                allFacetNames = ApplicationConstants.InternalPricingProgramProductSearchFacetNames;
                allFacets = [selectedProductFamilyCodeValues, selectedPfamNameValues, selectedBusinessUnitValues, selectedSkuNameValues, selectedSkuPartNumberValues, selectedCountryValues];
            }
            else{
                allFacetNames = ApplicationConstants.ProductSearchFacetNames;
                allFacets = [selectedPfamNameValues, selectedBusinessUnitValues, selectedSkuNameValues, selectedSkuPartNumberValues];
            }
        }
    }, [isDefaultSearch]);

    useEffect(() => {
        if(internalPricingContextState.opportunityDeals[0].dealType?.name?.toLowerCase() === PageConstants.OTLEDealtype && internalPricingContextState.productGroup && internalPricingContextState.opportunityDeals[0].geoCountries)
        {
            const dealQuantityThresholdForDealType : number = getDealQuantityThresholdValue(internalPricingContextState.opportunityDeals, internalPricingContextState.productGroup, internalPricingContextState.opportunityDeals[0].dealType?.name.toLowerCase() || "");
            sessionStorage.setItem(SessionStorageConsts.dealQuantityThresholdConfigs, dealQuantityThresholdForDealType.toString());
        }
        setDefaultSearch(true);
        setDefaultSearchStore();
        setProductTypeId(internalPricingContextState.productGroup || "");
        if(internalPricingContextState.opportunityDeals[0].dealType?.name === PageConstants.CPStrategicDealtype){
            setIsCPStrategicDeal(true);
        }
        else{
            setIsCPStrategicDeal(false);
        }
        setCountriesForSearch(selectedGeoCountries?.filter(c => c.action !== ActionType.Delete) || [], true, internalPricingContextState.productGroup || "");
        if (selectedGeoCountries.length > 0) {
            resetSelectedData(selectedGeoCountries?.filter(c => c.action !== ActionType.Delete).map(x => x.countryId) || []);
        }
    }, [internalPricingContextState.opportunityDeals[0].geoCountries, internalPricingContextState.productGroup]);

    useEffect(() => {
        if (selectedSearchValue != "" || isSearchCleared) {
            if (isSearchCleared) {
                setDefaultSearchStore();
                setSearchCleared(false);
            }
            search(selectedCountries, productTypeId, isDefaultSearch);
        }
    }, [selectedSearchValue, isSearchCleared]);

    // Accessibility - Ensures correct tab index is set for the panel bar list items
    useToOverridePanelBarTabIndex();

    function setDefaultSearchStore() {
        /** * AZ Search Configuration */
        // define search configuration
        defaultProductSearchStore.setConfig({
            index: ApplicationConstants.DistinctProductSearchIndex,
            queryKey: process.env.REACT_APP_SEARCH_API_KEY || "",
            service: process.env.REACT_APP_SEARCH_API_ENV || "",
        });

        // set suggester, project some additional fields into the returned suggestions
        defaultProductSearchStore.updateSuggestionsParameters({
            suggesterName: ApplicationConstants.DistinctProductSearchSuggesterName,
            select: ApplicationConstants.ProductSearchSelectColumn,
            top: ApplicationConstants.TopSearchSuggesstions,
            fuzzy: ApplicationConstants.IsFuzzy,
            filter: globalSearchFilterExpression,
        });
        const defaultProductSuggestionsProcessor = (results: any): any => {
            return results.map(function (result: any) {
                result.searchText = result["@search.text"];
                return result;
            });
        };
        defaultProductSearchStore.setSuggestionsProcessor(defaultProductSuggestionsProcessor);
    }

    function setSearchStore() {
        /** * AZ Search Configuration */
        // define search configuration
        productSearchStore.setConfig({
            index: ApplicationConstants.ProductSearchIndex,
            queryKey: process.env.REACT_APP_SEARCH_API_KEY || "",
            service: process.env.REACT_APP_SEARCH_API_ENV || "",
        });

        // set suggester, project some additional fields into the returned suggestions
        productSearchStore.updateSuggestionsParameters({
            suggesterName: ApplicationConstants.ProductSearchSuggesterName,
            select: ApplicationConstants.ProductSearchSelectColumn,
            top: ApplicationConstants.TopSearchSuggesstions,
            fuzzy: ApplicationConstants.IsFuzzy,
            filter: globalSearchFilterExpression,
        });
        const productSuggestionsProcessor = (results: any): any => {
            return results.map(function (result: any) {
                result.searchText = result["@search.text"];
                return result;
            });
        };
        productSearchStore.setSuggestionsProcessor(productSuggestionsProcessor);
    }

    if (internalPricingContextState.dataLoading || isProductLoading) {
        return (
            <Stack>
                <div>
                    <Spinner label="Getting Data..." />
                </div>
            </Stack>
        );
    }

    //invoke search on click of search button
    function onSubmit(selectedValue: string) {
        setDefaultSearch(false);
        setSelectedSearchValue(selectedValue);
        setSearchFilterButtonText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ShowSearchFilter", UIControlsText.ShowSearchFilter))
        setPanes(props?.facetProps?.splitterConfig);
        setHideSearchFilterButton(false);
    };

    //invoke clear on click of clear button
    function onClear() {
        setDefaultSearch(true);
        setSelectedSearchValue("");
        setSearchCleared(true);
        for (let i = 0; i < allFacetNames.length; i++) {
            setComboBoxDatasource([], allFacetNames[i]);
            setSelectedValuesDatasource([], allFacetNames[i]);
        }
        setPanes(props?.facetProps?.splitterConfig);
        setSearchFilterButtonText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ShowSearchFilter", UIControlsText.ShowSearchFilter))
        setHideSearchFilterButton(true);
    };

    //update country dropdown based on geo country selection in deal overview tab
    function setCountriesForSearch(dealCountries: ICountry[], isDefaultSearch: boolean, productTypeId: string) {
        setSelectedCountries(dealCountries);
        search(dealCountries, productTypeId, isDefaultSearch);
    };

    //Search for Products 
    function search(selectedCountries: ICountry[], productTypeId: string, isDefaultSearch: boolean) {
        if (internalPricingContextState.pageMode?.toLowerCase() !== PageMode.View.toLowerCase()) {
            let nonEUCounties: ICountry[] = [];
            selectedCountries.forEach(function (country) {
                let selectedGeoCountry = allCountries.find(allCountry => allCountry.countryName === country.applicablePriceListGeo);
                if (selectedGeoCountry != null) {
                    nonEUCounties.push(selectedGeoCountry);
                }
            });
            nonEUCounties = _.uniqBy(nonEUCounties, "countryId");
            let filterStringExpression = "";

            if (isDefaultSearch) {
                if(isCPStrategicDeal){
                    allFacetNames = ApplicationConstants.InternalPricingProgramDistinctProductSearchFacetNames;
                    allFacets = [selectedProductFamilyCodeValues, selectedPfamNameValues, selectedBusinessUnitValues, selectedCountryValues];
                }
                else{
                    allFacetNames = ApplicationConstants.DistinctProductSearchFacetNames;
                    allFacets = [selectedPfamNameValues, selectedBusinessUnitValues];
                }
            }
            else {
                if(isCPStrategicDeal){
                    allFacetNames = ApplicationConstants.InternalPricingProgramProductSearchFacetNames;
                    allFacets = [selectedProductFamilyCodeValues, selectedPfamNameValues, selectedBusinessUnitValues, selectedSkuNameValues, selectedSkuPartNumberValues, selectedCountryValues];
                }
                else{
                    allFacetNames = ApplicationConstants.ProductSearchFacetNames;
                    allFacets = [selectedPfamNameValues, selectedBusinessUnitValues, selectedSkuNameValues, selectedSkuPartNumberValues];
                }
            }

            if (selectedCountries.length > 0) {
                filterStringExpression = props?.facetProps?.createGlobalFilterExpression([ApplicationConstants.CustomerSearchCountryGlobalIndex, ApplicationConstants.ProductSearchProductTypeId], [nonEUCounties.map(item => item.countryName), [productTypeId]], isAnyEUCountrySelected(internalPricingContextState.opportunityDeals[0].geoCountries || [])) || "";
                if(isCPStrategicDeal){
                    filterStringExpression += ("(") + ApplicationConstants.ProductSearchApplicableDeals + "/any(d: d eq " + ApplicationConstants.CPStrategicApplicableDeal + ")) " + ApplicationConstants.AndOperator;
                }
                else{
                    filterStringExpression += ("(") + ApplicationConstants.ProductSearchApplicableDeals + "/any(d: d eq " + ApplicationConstants.OTLEApplicableDeal + ")) " + ApplicationConstants.AndOperator;
                }
                filterStringExpression += (" (") + ApplicationConstants.ProductSearchChannelType + " " + ApplicationConstants.Eq + " " + ApplicationConstants.IndirectChannelType + ")" + " " + ApplicationConstants.AndOperator;
                filterStringExpression += (" (") + ApplicationConstants.InternalDealVisibility + " " + ApplicationConstants.Ne + " " + false + " )";

                if (filterStringExpression.substring(filterStringExpression.length - 4) == 'and ') {
                    filterStringExpression = filterStringExpression.slice(0, -4);
                }

                //Set the global search filter expression
                setGlobalSearchFilterExpression(filterStringExpression);
                getProductSearchData(filterStringExpression, 0, "", [], selectedCountries, isDefaultSearch);
            }
        }
    }

    //this function used to initiate product search api call and returns data according to the user selected filter
    async function getProductSearchData(filterStringExpression: string, skip: number, facetName: string, selectedValue: any[], dealCountries: ICountry[], isDefaultSearch: boolean) {
        try {
            let indexURL: string = "";
            let searchStore = new AzSearchStore();
            if (isDefaultSearch) {
                searchStore = defaultProductSearchStore;
                indexURL = process.env.REACT_APP_SEARCH_API_URL_ENV + ApplicationConstants.DistinctProductSearchIndexApiUrl || "";
            }
            else {
                searchStore = productSearchStore;
                indexURL = process.env.REACT_APP_SEARCH_API_URL_ENV + ApplicationConstants.ProductSearchIndexApi_Url || "";   
            }
            let facetString = props?.facetProps?.facetList(allFacetNames);
            if (dealCountries.length > 0) {
                searchStore.setGlobalFilter(
                    ApplicationConstants.ProductSearchGlobalFilter,
                    filterStringExpression
                );
            }
            searchStore.updateSearchParameters({ count: true, skip: skip, searchMode: "all", });
            searchStore.setInput(selectedSearchValue);
            let apiReturnedData = await Promise.resolve(nextGenService.getProductSearchResults(indexURL, filterStringExpression, selectedSearchValue, skip, facetString || [], ApplicationConstants.CustomerSearchTopThreshold));

            if (apiReturnedData.status === 200) {
                let productsCount = (apiReturnedData.data as { [key: string]: any })["@odata.count"] as number;
                let productsSearchResultsData = [...(apiReturnedData.data as { [key: string]: any })["value"] as string];
                if (productsCount > productsSearchResultsData.length) {
                    for (
                        let i = 1;
                        i < Math.ceil(productsCount / ApplicationConstants.CustomerSearchTopThreshold);
                        i++
                    ) {
                        apiReturnedData = await Promise.resolve(nextGenService.getProductSearchResults(indexURL, filterStringExpression, selectedSearchValue, i * ApplicationConstants.CustomerSearchTopThreshold, facetString || [], ApplicationConstants.CustomerSearchTopThreshold));
                        productsSearchResultsData.push(...(apiReturnedData.data as { [key: string]: any })["value"] as string);
                    }
                }
                let array: any[] = [];
                array = formatProductSearchAPIData(productsSearchResultsData, selectedGeoCountries, true);
                let productSearchConfig = { ...productSearchGridConfig };
                let searchData = _.uniqBy(array, (item) =>
                    [item?.CountryId, item?.PfamId].join()
                );
                let groupedData = [
                    ...new Map(
                        searchData?.map((item: any) => [item.BusinessUnit, item])
                    ).values(),
                ];
                productSearchConfig.kendoGridProps.totalRecords = groupedData?.length;
                productSearchConfig.kendoGridProps.data = groupedData;
                productSearchConfig.kendoGridProps.masterData = searchData;
                if(!isCPStrategicDeal){
                    productSearchConfig.kendoGridProps.detailGrid.columns.filter(x => x.key == ApplicationConstants.ProductFamilyCodeFacetName || x.key == ApplicationConstants.CountryFacetName).forEach((item) => {
                        item["isVisible"] = false;
                    });

                    let selectedProductsGridConfig = { ...productFamilyGridConfig };
                    selectedProductsGridConfig.kendoGridProps.gridDisplayName = ApplicationConstants.OTLESelectedProductsGridName;
                    selectedProductsGridConfig.kendoGridProps.columns.filter(x => x.key == ApplicationConstants.ProductFamilyCodeFacetName || x.key == ApplicationConstants.CountryFacetName).forEach((item) => {
                        item["isVisible"] = false;
                    });
                    selectedProductsGridConfig.kendoGridProps.columns.filter(x => x.key == ApplicationConstants.Quantity)[0]["isVisible"] = true;
                    setProductFamilyGridConfig(selectedProductsGridConfig);
                }
                
                if (internalPricingContextState.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.PendingSecondaryReview && internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()){
                    productSearchConfig.kendoGridProps.className = "ms-Panel-hideSelectAllCheckBox ms-Panel-hideAllCheckBox";
                }
                setProductSearchGridConfig({ ...productSearchConfig });

                //Check if onSubmit triggered the event or facet selection and set data source accordingly
                if (facetName.length == 0 && selectedValue.length == 0) {
                    for (var i = 0; i < allFacetNames.length; i++) {
                        setComboBoxDatasource((apiReturnedData.data as { [key: string]: any })["@search.facets"][allFacetNames[i]] as IFacetData[], allFacetNames[i]);
                    }
                } else {
                    for (var i = 0; i < allFacetNames.length; i++) {
                        if (allFacetNames[i] != facetName) {
                            setComboBoxDatasource((apiReturnedData.data as { [key: string]: any })["@search.facets"][allFacetNames[i]] as IFacetData[], allFacetNames[i]);
                            setSelectedValuesDatasource(allFacets[i], allFacetNames[i]);
                        } else {
                            setSelectedValuesDatasource(selectedValue, facetName);
                        }
                    }
                }
                setHideSearchFilterButton(false);
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    //callback function on checkbox selection of header and/or row(s)
    const onDetailRowSelectionCallBack = (selectedState: any, gridData: any) => {
        const selectedProducts: IProductFamily[] = [];
        const searchGridDatawithSelection = productSearchGridConfig.kendoGridProps.masterData.map((item: any) => (
            {
                ...item, ['selected']: selectedState[idGetter(item)]
            }));
        searchGridDatawithSelection.map((item: any) => {
            if (item.selected === true) {
                selectedProducts.push(Object.assign({ inEdit: undefined }, item));
            }
        });
        addSelectedProductFamily(selectedProducts);
        setSelectionState(selectedState);
    }
    //function to remove selected row from grid and update state
    const onDeleteCallBack = (deletedRowData: any, refreshedGridData: any) => {
        let deletedlegalConfig = { ...productFamilyGridConfig };
        var DeletedDataFromDeleteAll: IProductFamily[] = [];
        let totalProductQuantity = 0;
        if (refreshedGridData.length === 0) {
            DeletedDataFromDeleteAll = deletedlegalConfig.kendoGridProps.data
        }
        deletedlegalConfig.kendoGridProps.data = refreshedGridData;
        deletedlegalConfig.kendoGridProps.totalRecords = refreshedGridData.length;
        setProductFamilyGridConfig(deletedlegalConfig);
        setProductFamilyGridData(refreshedGridData);
        let selectedProductFamily = getProductFamilyModel(refreshedGridData, false);
        internalPricingContextDispatch({ type: CaseActionTypes.selectedProductFamilyChanged, products: { key: getOppdealId(), value: selectedProductFamily } });
        resetSearchgrid(deletedRowData, refreshedGridData, DeletedDataFromDeleteAll);
        selectedProductFamily.forEach(item => {
            if(item.quantity){
                totalProductQuantity = totalProductQuantity + item.quantity;
            }
        });
        let dealQuantityThresholdForDealType = Number(sessionStorage.getItem(SessionStorageConsts.dealQuantityThresholdConfigs));
        totalProductQuantity >= dealQuantityThresholdForDealType || selectedProductFamily.length == 0 ? setShowWarningMessage(false) : setShowWarningMessage(true);
    }

    //callback function on editing selection grid row(s)
    const onEditCallBack = (data: any) => {
        if (!isNullOrEmpty(data) && !isCPStrategicDeal && internalPricingContextState.opportunityDeals[0].products) {
            let tempData = internalPricingContextState.opportunityDeals[0].products || [];;
            let editedRowIndex = tempData.findIndex((obj => obj.id == data.id));
            if (editedRowIndex != -1 && (internalPricingContextState.pageMode?.toLowerCase() === PageMode.Create.toLowerCase() || internalPricingContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.Draft)){
                tempData[editedRowIndex] = data;
            }
            else if (editedRowIndex != -1 && internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()) {
                let savedProducts = getsavedContext().opportunityDeals[0].products || [];
                let savedProductIndex = savedProducts.findIndex((obj => obj.id == data.id));
                if(savedProductIndex >= 0){
                    if(data.quantity > (savedProducts[savedProductIndex].quantity || 0)){
                        tempData[editedRowIndex].action = ActionType.Update;
                        tempData[editedRowIndex].quantity = data.quantity;
                    }
                    else{
                        tempData[editedRowIndex].action = "";
                        tempData[editedRowIndex].quantity = savedProducts[savedProductIndex].quantity;
                    }
                }
                else{
                    tempData[editedRowIndex].quantity = data.quantity;
                }
            }

            updateProductFamilyAndDispatch(tempData);
        }
    }

    //on click checkbox add checked rows to selection grid at right
    const addSelectedProductFamily = (selectedData: IProductFamily[]): void => {
        var selectedproductFamily: IProductFamily[] = getProductFamilyModel(selectedData, true).map((item: IProductFamily) => ({ ...item, action: ActionType.Add }));
        var finalSelection: IProductFamily[] = productFamilyGridConfig.kendoGridProps.data;
        selectedproductFamily.map(item => {
            var findItem = finalSelection.find(x => x.productFamilyCode === item.productFamilyCode && x.country === item.country);
            if (findItem === undefined) {
                finalSelection.push(item);
            }
            else {
                if (internalPricingContextState.pageMode === PageMode.Edit && internalPricingContextState.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.Draft) {
                    var index = finalSelection.findIndex(item => item.productFamilyCode === findItem?.productFamilyCode && item.country === findItem?.country);
                    finalSelection[index].searchId = item.searchId;
                }
            }
        })
        updateProductFamilyAndDispatch(finalSelection);
    }
    //reset product search grid selection after adding
    const resetSearchgrid = (deletedItem: IProductFamily, gridData: IProductFamily[], DeletedDataFromDeleteAll: IProductFamily[]): void => {
        let productSearchConfig = { ...productSearchGridConfig };
        if (gridData.length > 0 && deletedItem.searchId) {
            selectionState[deletedItem.searchId] = false;
            productSearchConfig.kendoGridProps.defaultDetailGridSelectionstate = selectionState;
        }
        else {
            DeletedDataFromDeleteAll.map((item: IProductFamily) => {
                if (item.searchId) {
                    selectionState[item.searchId] = false;
                }
            });
            productSearchConfig.kendoGridProps.defaultDetailGridSelectionstate = selectionState;
        }
        setProductSearchGridConfig({ ...productSearchConfig });
    }
    //construct pfam model from selection grid
    const getProductFamilyModel = (productFamily: IProductFamily[], isAddition: boolean): IProductFamily[] => {
        let selectedProductFamily: IProductFamily[] = [];
        selectedProductFamily = productFamily.map((item: any) => {
            return {
                id: isAddition ? Guid.newGuid() : item.id,
                countryId: isAddition ? item.CountryId : item.countryId,
                productFamilyId: isAddition ? item.PfamId : item.productFamilyId,
                productFamilyCode: isAddition ? item.ProductFamilyCode : item.productFamilyCode,
                country: isAddition ? item.Country : item.country,
                name: isAddition ? item.PfamName : item.name,
                skuId: isAddition ? item.SKUId : item.skuId,
                businessUnitId: isAddition ? item.BusinessUnitId : item.businessUnitId,
                action: item.action,
                searchId: isAddition ? item.id : item.searchId,
                quantity: isAddition ? 0 : item.quantity,
            } as IProductFamily
        });
        return selectedProductFamily;
    }

    // Method called on onChange event of any child combobox (facet)
    const selectedValues = (selectedValue: any[], facetName: string): void => {
        setDefaultSearch(false);
        // create filter expression based on selected facet data
        let filterStringExpression = "";

        if (isDefaultSearch) {
            if(isCPStrategicDeal){
                allFacetNames = ApplicationConstants.InternalPricingProgramDistinctProductSearchFacetNames;
                allFacets = [selectedProductFamilyCodeValues, selectedPfamNameValues, selectedBusinessUnitValues, selectedCountryValues];
            }
            else{
                allFacetNames = ApplicationConstants.DistinctProductSearchFacetNames;
                allFacets = [selectedPfamNameValues, selectedBusinessUnitValues];
            }
        }
        else {
            if(isCPStrategicDeal){
                allFacetNames = ApplicationConstants.InternalPricingProgramProductSearchFacetNames;
                allFacets = [selectedProductFamilyCodeValues, selectedPfamNameValues, selectedBusinessUnitValues, selectedSkuNameValues, selectedSkuPartNumberValues, selectedCountryValues];
            }
            else{
                allFacetNames = ApplicationConstants.ProductSearchFacetNames;
                allFacets = [selectedPfamNameValues, selectedBusinessUnitValues, selectedSkuNameValues, selectedSkuPartNumberValues];
            }
        }
        
        filterStringExpression = props?.facetProps?.createGlobalFilterExpression([ApplicationConstants.CustomerSearchCountryGlobalIndex], [selectedCountries.map(item => item.countryName)], isAnyEUCountrySelected(internalPricingContextState.opportunityDeals[0].geoCountries || [])) || "";

        filterStringExpression = filterStringExpression + props?.facetProps?.createFilterExpression(allFacetNames, facetName, selectedValue, allFacets);
        if(isCPStrategicDeal){
            filterStringExpression += ("(") + ApplicationConstants.ProductSearchApplicableDeals + "/any(d: d eq " + ApplicationConstants.CPStrategicApplicableDeal + ")) " + ApplicationConstants.AndOperator;
        }
        else{
            filterStringExpression += ("(") + ApplicationConstants.ProductSearchApplicableDeals + "/any(d: d eq " + ApplicationConstants.OTLEApplicableDeal + ")) " + ApplicationConstants.AndOperator;
        }
        filterStringExpression += (" (") + ApplicationConstants.ProductSearchChannelType + " " + ApplicationConstants.Eq + " " + ApplicationConstants.IndirectChannelType + ")" + " " + ApplicationConstants.AndOperator;
        filterStringExpression += (" (") + ApplicationConstants.InternalDealVisibility + " " + ApplicationConstants.Ne + " " + false + " )";

        if (filterStringExpression.substring(filterStringExpression.length - 4) == 'and ') {
            filterStringExpression = filterStringExpression.slice(0, -4);
        }
        getProductSearchData(filterStringExpression, 0, facetName, selectedValue, selectedCountries, false);
    }

    //Set selected values of facets
    const setSelectedValuesDatasource = (selectedValue: any[], facetType: string): void => {
        switch (facetType) {
            case ApplicationConstants.ProductFamilyCodeFacetName:
                setSelectedProductFamilyCodeValues(selectedValue);
                break;

            case ApplicationConstants.ProductFamilyNameFacetName:
                setSelectedPfamNameValues(selectedValue);
                break;

            case ApplicationConstants.BusinessUnitFacetName:
                setSelectedBusinessUnitValues(selectedValue);
                break;

            case ApplicationConstants.SkuNameFacetName:
                setSelectedSkuNameValues(selectedValue);
                break;

            case ApplicationConstants.SkuPartNumberFacetName:
                setSelectedSkuPartNumberValues(selectedValue);
                break;

            case ApplicationConstants.CountryFacetName:
                setSelectedCountryValues(selectedValue);
                break;


            default:
                console.log("Default setSelectedValuesDatasource");
        }
    }

    //Set data source of facets
    const setComboBoxDatasource = (values: IFacetData[], facetType: string): void => {
        switch (facetType) {
            case ApplicationConstants.ProductFamilyCodeFacetName:
                setProductFamilyCode(values);
                break;

            case ApplicationConstants.ProductFamilyNameFacetName:
                setPfamName(values);
                break;

            case ApplicationConstants.BusinessUnitFacetName:
                setBusinessUnit(values);
                break;

            case ApplicationConstants.SkuNameFacetName:
                setSkuName(values);
                break;

            case ApplicationConstants.SkuPartNumberFacetName:
                setSkuPartNumber(values);
                break;

            case ApplicationConstants.CountryFacetName:
                setCountry(values.filter(country => selectedGeoCountries.filter(geoCountry => geoCountry.countryName === Object.values(country)[1]).length > 0));
                //setCountry(values);
                break;

            default:
                console.log("Default setSelectedValuesDatasource");
        }
    }

    //hide or show facets on click of button
    const onShowFacets = () => {
        var isCollapsed = false;
        if (panes) {
            isCollapsed = panes[0]?.collapsed;
        }
        setPanes([
            { size: "15%", collapsible: true, resizable: true, collapsed: !isCollapsed },
            {}
        ]);
        setSearchFilterButtonText(isCollapsed ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "HideSearchFilter", UIControlsText.HideSearchFilter) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ShowSearchFilter", UIControlsText.ShowSearchFilter))
    }

    return (
        <div>
            <Row>
                <Col>
                    {isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductSearchElements", UIControlsText.ProductSearchElements), internalPricingContextState.pageMode, dealType) &&
                        <Stack id="productSelectionSearchBar">
                            {issearchBarVisible && 
                            <Col>
                                <SearchBar {...{
                                    store: productSearchStore, onSubmit, placeHolderText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Search", UIControlsText.Search), onClear, searchID: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductSearchID", UIControlsText.ProductSearchID),
                                    showFacetsText: searchFilterButtonText, onShowFacets,
                                    hideSearchBtn: false,
                                    hideFacetsButton: hideSearchFilterButton,
                                    pageMode: internalPricingContextState.pageMode,
                                    opportunityDealStatusCode: internalPricingContextState.opportunityDeals[0].statusCode
                                }} />
                                <Label>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "ProductSearchPlaceHolderText", UIControlsText.ProductSearchPlaceHolderText)}</Label>
                            </Col>}
                            <div>
                                {showProductDialog && 
                                <InnerHTMLContainer {...{ 
                                    id:"InactiveProductMessage" ,
                                    contolType: UIControlType.MessageWithDialog,
                                    listdata: inactivePFAMs, 
                                    message: getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "InActiveProductErrorMessage", ErrorMessages.InActiveProductErrorMessage),
                                    hidden: true, 
                                    isBlocking: true,
                                    title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "InactiveProducts", UIControlsText.InactiveProducts),
                                    subText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "InactiveProductsSubTitle", UIControlsText.InactiveProductsSubTitle),
                                    productType: "Product Family",
                                }}>
                                </InnerHTMLContainer>
                                }
                            </div>
                        </Stack>
                    }
                    <Stack horizontal tokens={stackTokens}>
                        <Splitter
                            className={_Styles.SplitterStyle}
                            panes={internalPricingContextState.pageMode !== PageMode.View ? panes : []}
                            onChange={onSplitterChange}
                        >
                            {isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductSearchElements", UIControlsText.ProductSearchElements), internalPricingContextState.pageMode, dealType) &&
                                <div>
                                    <Label id="gridTitle" className={_Styles.LegalEntityLabel}>
                                        {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SearchFilter", UIControlsText.SearchFilter)}
                                    </Label>
                                    {isCPStrategicDeal &&
                                        <SearchableComboBoxComponent
                                        {...{
                                            facetProps: {
                                                facetName: ApplicationConstants.CountryFacetName,
                                                facetLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CountryFacetText", UIControlsText.CountryFacetText),
                                                facetData: country,
                                                selectedData: selectedCountryValues,
                                                showFacetCount: false
                                            },
                                            selectedValues,
                                        }}
                                        ></SearchableComboBoxComponent>
                                    }
                                    <SearchableComboBoxComponent
                                        {...{
                                            facetProps: {
                                                facetName: ApplicationConstants.BusinessUnitFacetName,
                                                facetLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BusinessUnitFacetText", UIControlsText.BusinessUnitFacetText),
                                                facetData: businessUnit,
                                                selectedData: selectedBusinessUnitValues,
                                                showFacetCount: false
                                            },
                                            selectedValues,
                                        }}
                                    ></SearchableComboBoxComponent>
                                    <SearchableComboBoxComponent
                                        {...{
                                            facetProps: {
                                                facetName: ApplicationConstants.ProductFamilyNameFacetName,
                                                facetLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductFamilyNameFacetText", UIControlsText.ProductFamilyNameFacetText),
                                                facetData: pfamName,
                                                selectedData: selectedPfamNameValues,
                                                showFacetCount: false
                                            },
                                            selectedValues,
                                        }}
                                    ></SearchableComboBoxComponent>
                                    {isCPStrategicDeal &&
                                        <SearchableComboBoxComponent
                                        {...{
                                            facetProps: {
                                                facetName: ApplicationConstants.ProductFamilyCodeFacetName,
                                                facetLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductFamilyCodeFacetText", UIControlsText.ProductFamilyCodeFacetText),
                                                facetData: productFamilyCode,
                                                selectedData: selectedProductFamilyCodeValues,
                                                showFacetCount: false
                                            },
                                            selectedValues,
                                        }}
                                        ></SearchableComboBoxComponent>
                                    }
                                    {!isDefaultSearch &&
                                        <><SearchableComboBoxComponent
                                            {...{
                                                facetProps: {
                                                    facetName: ApplicationConstants.SkuNameFacetName,
                                                    facetLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SkuNameFacetText", UIControlsText.SkuNameFacetText),
                                                    facetData: skuName,
                                                    selectedData: selectedSkuNameValues,
                                                    showFacetCount: false
                                                },
                                                selectedValues,
                                            }}
                                        ></SearchableComboBoxComponent><SearchableComboBoxComponent
                                            {...{
                                                facetProps: {
                                                    facetName: ApplicationConstants.SkuPartNumberFacetName,
                                                    facetLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SkuPartNumberFacetText", UIControlsText.SkuPartNumberFacetText),
                                                    facetData: skuPartNumber,
                                                    selectedData: selectedSkuPartNumberValues,
                                                    showFacetCount: false
                                                },
                                                selectedValues,
                                            }}
                                        ></SearchableComboBoxComponent></>
                                    }
                                </div>
                            }
                            {isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductSearchElements", UIControlsText.ProductSearchElements), internalPricingContextState.pageMode, dealType) &&
                                <div>
                                    <div className="DealDetailsSearchGrid">
                                        <NextGenKendoGrid
                                            {...{
                                                kendoGridProps: { ...productSearchGridConfig.kendoGridProps }, onDetailRowSelectionCallBack
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            {internalPricingContextState.pageMode === PageMode.View && showProductData ? (
                                <div id="ProductSelectionViewGrids" >
                                    {expandGridVisible ? (
                                        <PanelBar expandMode={expandMode}>
                                            <PanelBarItem title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CompactView", UIControlsText.CompactView)} id={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CompactView", UIControlsText.CompactView)}>
                                                <NextGenKendoGrid
                                                    {...{
                                                        kendoGridProps: { ...pfamCompactConfig.kendoGridProps }
                                                    }}
                                                />
                                            </PanelBarItem>
                                            <PanelBarItem title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpandedView", UIControlsText.ExpandedView)} expanded={true} id={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpandedView", UIControlsText.ExpandedView)}>
                                                <NextGenKendoGrid
                                                    {...{
                                                        kendoGridProps: { ...pfamExpandConfig.kendoGridProps }
                                                    }}
                                                />
                                            </PanelBarItem>
                                        </PanelBar>
                                    ) : (
                                        <PanelBar expandMode={expandMode}>
                                                <PanelBarItem title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CompactView", UIControlsText.CompactView)} expanded={true} id={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CompactView", UIControlsText.CompactView)}>
                                                <NextGenKendoGrid
                                                    {...{
                                                        kendoGridProps: { ...pfamCompactConfig.kendoGridProps }
                                                    }}
                                                />
                                            </PanelBarItem>
                                        </PanelBar>
                                    )}
                                </div>
                            ) : <div>
                                {showWarningMessage && !isCPStrategicDeal && (
                                    <Stack>
                                    <div>
                                        <MessageBar messageBarType={MessageBarType.warning} onDismiss={resetWarningMessage} dismissButtonAriaLabel="Close" isMultiline={true}>
                                            {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductQuantityWarningText", UIControlsText.ProductQuantityWarningText)}
                                        </MessageBar>
                                    </div>
                                </Stack>
                                )}
                                <NextGenKendoGrid
                                    {...{
                                        kendoGridProps: { ...productFamilyGridConfig.kendoGridProps }, onDeleteCallBack, onEditCallBack
                                    }}
                                />
                            </div>
                            }
                        </Splitter>
                    </Stack>
                </Col>
            </Row>
        </div>
    )
}

export default InternalPricingProductSearch;