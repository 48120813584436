/**
 * Enum for Product Structure.
 * @readonly
 * @enum {Enum}
 */
export enum ProductStructure {

    /**
     * ProductFamily value.
     */
    ProductFamily = 1,

    /**
     * ProductSKU value.
     */
    ProductSKU = 2,

    /**
    * BusinessUnit value.
    */
    BusinessUnit = 3,

}