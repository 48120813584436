/**
 * Enum for UserRole.
 * @readonly
 * @enum {UserRole}
 */
export enum UserRole {

    /// <summary>
    /// channel
    /// </summary>
    Channel = "CHL",

    /// <summary>
    /// MSStore
    /// </summary>
    MSStore = "MST",

    /// <summary>
    /// MSSeller
    /// </summary>
    MSSeller = "MSS",

    /// <summary>
    /// BusinessDesk
    /// </summary>
    BusinessDesk = "BD"
}