/**
 * Enum for DashboardViewTypes.
 * @readonly
 * @enum 
 */

export const DashboardViewTypes = {
    /**
     * All Cases.
     */
    AllCases: 1,

    /**
     *  Special Pricing Cases.C
     */
    SpecialPricingCases: 2,

    /**
     * Deal Registration Cases.
     */
    DealRegistrationCases: 3,

    /**
     * Custom Pricing Strategic Cases.
     */
    CustomPricingStrategicCases: 4,

    /**
     * Custom Pricing Strategic Cases.
     */
    BidGridAndNonProfitEDUDiscounts: 1,

     /**
     *  Special Pricing Local Empowerment
     */
    SpecialPricingLocalEmpowerment:5,

    /**
     *  One Time Local Empowerment
     */
    OneTimeLocalImpowerement:6
}