import { RequestorsRoleType } from "./RequestorRoleType"
import { SpecialLocationtype } from "./SpecialLocationtype"

export enum ChannelDisplayFunctionalities {
    //Bitwise AND operations
    PrimaryResellersOnly = RequestorsRoleType.ADD & SpecialLocationtype.WorldWide,
    PrimaryAndFinalTierResellers = RequestorsRoleType.ADD & SpecialLocationtype.China,
    PrimaryandAdditionalResellers = RequestorsRoleType.ADD & SpecialLocationtype.Japan,
    AdditionalAndDistributors = ((RequestorsRoleType.ADR & SpecialLocationtype.Japan) || (RequestorsRoleType.DMP & SpecialLocationtype.Japan)),
    FinalTierAndDistributors = (RequestorsRoleType.ADR & SpecialLocationtype.China) || (RequestorsRoleType.DMP & SpecialLocationtype.China),
    MultipleDistributorsOnly = (RequestorsRoleType.ADR & SpecialLocationtype.WorldWide) || (RequestorsRoleType.DMP & SpecialLocationtype.WorldWide),
    MSStore = RequestorsRoleType.MSStore & SpecialLocationtype.WorldWide,
}
//  8    9   10   11   12   13   14   15
// 1000 1001 1010 1011 1100 1101 1110 1111

// ADD 0111 - Japan - 0110  = 6 //Add_PrimaryandAdditionalResellers
// ADD 0111 - China - 0111  =  7 //Add_PrimaryAndFinalTierResellers
// ADD 0111 - None - 1101 =  5 //Add_PrimaryResellersOnly

// ADR DMP 0011 - China - 0111  =  3 //Add_FinalTierAndDistributors

// ADR DMP 0011 - Japan - 0110  =  2 //Add_AdditionalAndDistributors

// MSS 1101 - None -  1101 =  13  //Add_MSStore

// ADR DMP - 0011 - None - 1101 = 1 //Add_MultipleDistributorsOnly