/**
 * Enum for Grid Types which are implemented gridjson config.
 * @readonly
 * @enum {number}
 */
export enum GridType {
    LegalEntitySearchOTLE = "LegalEntitySearchOTLE",
    LegalEntitySearch = "LegalEntitySearch",
    LegalEntitySelection = "LegalEntitySelection",
    LegalEntityUpload = "LegalEntityUpload",
    ProductSearch = "ProductSearch",
    PFAMSelection = "PFAMSelection",
    CaseView = "Case View",
    CPStrategicCaseView = "CP Strategic Case View",
    ProductInformationView = "Product Information View",
    AccountsList = 'AccountsList',
    CPStrategicLetters = "CPStrategicLetters",
    ViewResellers = "View Resellers",
    PFAMExpandView = "PFAMExpandedView",
    PFAMCompactView = "PFAMCompactView",
    PFAMDynamicSelection = "PFAMDynamicSelection",
    CPStrategicDealReport = "CP Strategic Deal Report",
    ResellersList = "Add Reseller",
    DistributorList = "Add Distributors",
    DuplicateCustomerResults = "DuplicateCustomerResults",
    DRProducts = "DRProducts",
    SPProducts = "SPProducts",
    InternalPricingProducts = "InternalPricingProducts",
    BUView = "BUView",
    DRSPExpandView = "DRSPExpandView",
    DRSPCompactView = "DRSPCompactView",
    CustomerSearch = "CustomerSearch",
    BusinessUnitProductSearch = "BusinessUnitProductSearch",
    SelectedBusinessUnit = "SelectedBusinessUnit",
    PFAMProductSearch = "PFAMProductSearch",
    SelectedPFAM = "SelectedPFAM",
    DealRegistration = "Deal Registration",
    AllCaseView = "AllCases",
    SpecialPricing="Special Pricing",
    BidGrid="Bid Grid",
    SPLocalEmpowerment= "Special Pricing(Local Empowerment)",
    OneTimeLocalEmpowerment = "One Time Local Empowerment"
}
