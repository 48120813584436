import { itemAlignmentsStackTokens, stackItemCustomerStyles, stackItemStyles } from '../PricingProgramFormUtils';
import { _Styles } from '../../../../content/styles/Page.styles';
import { useCommonPPContext } from '../../common/context/common-pp-context';
import { useEffect, useState } from 'react';
import { IGridProps } from "../../../../components/shared/grid/IGridProps";
import { getEntityDataFromSession, getURLParamValue, readGridConfig, getRuleValidationMessage } from '../../../../components/shared/common/util';
import { GridType } from '../../../../models/GridType';
import { DealType } from '../../../../models/DealType';
import { UIControlType } from '../../../../models/UIControlType';
import { UIControlsText } from '../../../../content/UIControlsText';
import { IProductType } from '../../../../models/IProductType';
import { IProductFamily } from '../../../../models/IProductFamily';
import { ApplicationConstants } from '../../../../models/ApplicationConstants';
import { IDealType } from '../../../../models/IDealType';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { ProductStructure } from '../../../../models/ProductStructure';
import { OpportunityDealStatusCodes } from '../../../../models/OpportunityDealStatusCodes';
import { MessageTypeText } from '../../../../models/MessageTypeText';

export function ProductDetails(productTypes: IProductType[]) {
    const { commonPPContextState } = useCommonPPContext();
    const [DRproductGridConfig, setDRproductGridConfig] = useState<IGridProps>(readGridConfig(GridType.DRProducts));
    const [SPproductGridConfig, setSPproductGridConfig] = useState<IGridProps>(readGridConfig(GridType.SPProducts));
    const [productGroup, setProductGroup] = useState<string>("");
    const [dealType, setDealType] = useState<string>("");
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

    useEffect(() => {
        // to read product information from context
        const oppDealNumber = commonPPContextState.selectedOptyDealCaseNumber;
        if (oppDealNumber && oppDealNumber.length > 0) {
            var oppDeal = commonPPContextState.opportunityDeals.filter(d => d.name === oppDealNumber)[0];
            if (oppDeal) {
                const dealTypes = getEntityDataFromSession<IDealType>(SessionStorageConsts.dealTypes);
                const dealTypeName = dealTypes.find(t => t.id === oppDeal.dealType?.id)?.name;
                var products = oppDeal.products;
                setDealType(dealTypeName || "");
                
                if (dealTypeName?.toLowerCase() === DealType.DealRegistration.toLowerCase() && products) {
                    var buProducts = products.filter(p => p.productStructure !== ProductStructure.BusinessUnit);
                    let groupedbyBU = [
                        ...new Map(
                            buProducts?.map((item: IProductFamily) => [item.businessUnitName, item])
                        ).values(),
                    ];
                    let Config = { ...DRproductGridConfig };
                    Config.kendoGridProps.data = groupedbyBU
                    setDRproductGridConfig({ ...Config });
                }
                else if (dealTypeName?.toLowerCase() === DealType.SpecialPricing.toLowerCase()) {
                    let groupedbyPFAM = [
                        ...new Map(
                            products?.map((item: IProductFamily) => [item.productFamilyCode, item])
                        ).values(),
                    ];
                    let Config = { ...SPproductGridConfig };
                    var data = dealTypeName === DealType.SpecialPricing ? groupedbyPFAM.filter(d => d.productStructure === ProductStructure.ProductFamily) : groupedbyPFAM.filter(d => d.productStructure !== ProductStructure.BusinessUnit);
                    if (oppDeal.statusCode === OpportunityDealStatusCodes.Draft) {
                        data = groupedbyPFAM;
                    }
                    Config.kendoGridProps.data = data;
                    setSPproductGridConfig({ ...Config });
                }

                if (commonPPContextState.productGroup) {
                    var productType = productTypes.filter(p => p.productId === commonPPContextState.productGroup)[0].productName
                    if (productType) {
                        setProductGroup(productType)
                    }
                }
            }
        }
    }, [commonPPContextState.opportunityDeals[0].products, commonPPContextState.selectedOptyDealCaseNumber])

    const ProductDetailsJson = [
        {
            type: "CustomTileCardComponent",
            data: {
                id: "ViewCardForProductDetails",                
                baseState: commonPPContextState,
                name: 'Product Details',
                iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductVariantIConName", UIControlsText.ProductVariantIConName),
                components: [
                    {
                        type: "Stack",
                        data: {
                            id: "StackForProductsTable",
                            'data-nextgen-parent-group': "View - Product Details",
                            'data-nextgen-parentid': "View - Product Details",
                            wrap: true,
                            vertical: true,
                            verticalAlign: "start",                               
                            tokens: itemAlignmentsStackTokens,
                            components: [
                                {
                                    type: 'StackItem',
                                    data: {
                                        id: 'productgroupdetails',
                                        horizontal: "true",
                                        disableshrink: 'true',
                                        components: [
                                            {
                                                type: 'Col',
                                                visible: true,
                                                data: {
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                contolType: UIControlType.Label,
                                                                id: "CustomerCountryTextField",
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductGroup", UIControlsText.ProductGroup),
                                                                value: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductGroup", UIControlsText.ProductGroup).concat(" ").concat(productGroup),
                                                                className: 'productgroupStyle'
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    type: 'StackItem',
                                    data: {
                                        id: 'ProductDetails',
                                        horizontal: "true",
                                        disableshrink: 'true',
                                        components: [
                                            {
                                                type: 'Col',
                                                visible: dealType.toLowerCase() === DealType.DealRegistration.toLowerCase(),
                                                data: {
                                                    styles: stackItemStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "NextGenKendoGrid",
                                                            data: {
                                                                ...{
                                                                    kendoGridProps: { ...DRproductGridConfig.kendoGridProps }
                                                                }
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                type: 'Col',
                                                visible: dealType.toLowerCase() === DealType.SpecialPricing.toLowerCase(),
                                                data: {
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "NextGenKendoGrid",
                                                            data: {
                                                                ...{
                                                                    kendoGridProps: { ...SPproductGridConfig.kendoGridProps }
                                                                }
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
    ]

    return ProductDetailsJson;
}