import { FC } from 'react';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { dropdownStyles } from '../../../content/styles/Page.styles';
import { ICaseDropdownControlProps } from '../props/ICaseDropdownControlProps';
import { UIControlsText } from '../../../content/UIControlsText';
import { MessageTypeText } from '../../../models/MessageTypeText';
import { getRuleValidationMessage, getEntityDataFromSession } from "../../../components/shared/common/util";
import { SessionStorageConsts } from '../../../models/SessionStorageConsts';

const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings)

const stackTokens: IStackTokens = { childrenGap: 5 };

export const CaseDropdown: FC<ICaseDropdownControlProps> = (props: ICaseDropdownControlProps) => {
  return (
    <Stack tokens={stackTokens}>
      <Dropdown
        placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "CasesDropdownPlaceHolderText", UIControlsText.CasesDropdownPlaceHolderText)}
        ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DashboardView", UIControlsText.DashboardView)}
        options={props.options}
        styles={dropdownStyles}
        onChange={props.onChange}
        disabled={props.disabled}
        defaultSelectedKey={props.defaultSelectedKey}
      />
    </Stack>
  );
};