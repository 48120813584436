import { PartnerType } from "./PartnerType";

/**
 * Label for Partner Type.
 * @readonly
 * @type {Map<number, string>}
 */
 export const PartnerTypeLabel: Map<number, string> = new Map<number, string>([
    [PartnerType.ADD, 'ADD'],
    [PartnerType.ADR, 'ADR'],
    [PartnerType.DMP, 'DMP'],
  ]);

  /**
 * Label for Partner Type Text.
 * @readonly
 * @type {Map<number, string>}
 */
 export const PartnerTypeName: Map<number, string> = new Map<number, string>([
  [PartnerType.ADD, 'Authorized Device Distributor'],
  [PartnerType.ADR, 'Authorized Device Reseller'],
  [PartnerType.DMP, 'Distributor Managed Partner'],
]);