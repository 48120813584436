/**
 * Enum for RfpType.
 * @readonly
 * @enum {number}
 */
export enum RfpType {
   /**
   * Yes.
   */
   Yes = 1,

   /**
   * No.
   */
   No = 0
}