import { Label } from "office-ui-fabric-react";
import React from "react";
import { default as ReactSelect } from "react-select";
import { IFacetData } from "../../../models/IFacetData";
import { IComboboxData } from "./IComboboxData";
import { IComboboxProps } from "./IComboboxProps";

export const SearchableComboBoxComponent: React.FunctionComponent<IComboboxProps> = (
    props: IComboboxProps
) => {
    const [dataSource,setdataSource] = React.useState<IComboboxData[]>([]);
    const [selectedDataSource,setSelectedDataSource] = React.useState<IComboboxData[]>([]);
    React.useEffect(() => {
        var data: IComboboxData[] = [];
        var selectedData: IComboboxData[] = [];

        if(props.facetProps.facetData != null){

            if(props.facetProps.showFacetCount){
                data =
                props.facetProps.facetData.map((pt:IFacetData) => {
                    return { value: pt.value, label: pt.value + ' ('+pt.count+')'}
                }); 
                
                props.facetProps.facetData.map((pt:IFacetData) => {
                    if(props.facetProps.selectedData?.some((x: { value: string; }) => x.value === pt.value)){
                        selectedData.push({ value: pt.value, label: pt.value + ' ('+pt.count+')' });
                    }
                });
            }
            else{
                data =
                props.facetProps.facetData.map((pt:IFacetData) => {
                    return { value: pt.value, label: pt.value }
                }); 
                
                props.facetProps.facetData.map((pt:IFacetData) => {
                    if(props.facetProps.selectedData?.some((x: { value: string; }) => x.value === pt.value)){
                        selectedData.push({ value: pt.value, label: pt.value });
                    }
                });
            }
            
            setdataSource(data);
            setSelectedDataSource(selectedData);
        }
    }, [props.facetProps.facetData,props.facetProps.selectedData]);
    
    const handleChange = (selectedOptions:any) => {
        props.selectedValues(selectedOptions,props.facetProps.facetName);
    };
    const styles = {
        control: (base: any) => ({
          ...base,
          fontFamily: "Segoe UI",
          fontSize: "12px"
        }),
        menu: (base: any) => ({
          ...base,
          fontFamily: "Segoe UI",
          fontSize: "12px"
        })
    };
    return (
        <div>
            <Label>{props.facetProps.facetLabel}</Label>  
            <ReactSelect
                aria-label={props.facetProps.facetLabel}
                options={dataSource}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                maxMenuHeight={250}
                onChange={handleChange}
                value = {selectedDataSource}
                styles={styles}
            />
        </div>
    );
};