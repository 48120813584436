import { _Styles } from '../../../../content/styles/Page.styles';
import { useCommonPPContext } from '../../common/context/common-pp-context';
import { useEffect, useState } from 'react';
import { ILegalEntity } from '../../../../models/ILegalEntity';
import { GetCustomerViewJson } from '../customerDetail';

export function CustomerDetails() {
    const { commonPPContextState } = useCommonPPContext();
    const [customerDetail, SetCustomerDetail] = useState<ILegalEntity>();
    let dealCustomer: ILegalEntity = {} as ILegalEntity;

    useEffect(() => {
        // to read customer information from context
        if (commonPPContextState.opportunityDeals[0].dealCustomers && commonPPContextState.opportunityDeals[0].dealCustomers.length > 0) {
            dealCustomer = commonPPContextState.opportunityDeals[0].dealCustomers[0];
        }

        if (dealCustomer) {
            SetCustomerDetail(dealCustomer);
        }
    }, [commonPPContextState.opportunityDeals[0].dealCustomers])

    const CustomerDetailsJson = GetCustomerViewJson(customerDetail);

    return CustomerDetailsJson;
}