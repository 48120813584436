import { useCallback, useEffect, useState } from "react";
import { GridColumn, GridColumnMenuProps } from "@progress/kendo-react-grid";
import { useLocation } from "react-router";

import { FontIcon, Link } from "@fluentui/react";
import { filter, forEach } from "lodash";
import NextGenKendoGrid from "../components/shared/grid/nextgenkendogrid";
import { FilterMenu } from "../components/shared/grid/gridfilter";
import { GridColumnMenuFilterBaseProps } from "@progress/kendo-react-grid/dist/npm/interfaces/GridColumnMenuFilterBaseProps";
import { Grid } from "@fluentui/react-northstar";

//constants used in accessibility fixes
export const PageNames = {
  Dashboard: "Dashboard",
  NewCaseSubmission: "New Case Submission",
  Discount: "Discount",
};

// Custom Hook Intended to Facilitate Column Filter Menu Close and Focus Handling
export function useFilterMenuEscCloseAndFocus(props?: GridColumnMenuProps) {
  let filterMenuContainerSelector = ".k-grid-columnmenu-popup";

  const escapeFilterMenuHandler = useCallback(
    (event) => {
      if (event.key === "Escape") {
        let isFocusOnfilterMenuDropdown = document.querySelector(':focus')?.classList.contains('k-dropdown-wrap');
        if (!isFocusOnfilterMenuDropdown && props?.onCloseMenu) {
          props.onCloseMenu();
        }
      } else if (event.key === "Tab") {
        let filterMenuContainer = document.querySelector(
          filterMenuContainerSelector
        );
        let primaryFilterButtonDisabledArray = Array.from(
          document.querySelectorAll("button.k-button[disabled]")
        );
        let primaryFilterButtonDisabled = primaryFilterButtonDisabledArray
          ? (primaryFilterButtonDisabledArray[0] as HTMLButtonElement)
          : undefined;
        let filterMenuButtons = Array.from(
          document.querySelectorAll("button.k-button")
        );
        let hasFilterMenuButtons =
          filterMenuButtons?.length === 2 &&
          filterMenuButtons[0] &&
          filterMenuButtons[1];
        if (
          hasFilterMenuButtons &&
          ((primaryFilterButtonDisabled &&
            event.target === filterMenuButtons[0]) ||
            (!primaryFilterButtonDisabled &&
              event.target === filterMenuButtons[1]))
        ) {
          (filterMenuContainer as HTMLDivElement).focus();
        }
      }
    },
    [props]
  );

  useEffect(() => {
    if (props) {
      document
        .querySelector(filterMenuContainerSelector)
        ?.addEventListener("keydown", escapeFilterMenuHandler);

      return () => {
        document
          .querySelector(filterMenuContainerSelector)
          ?.removeEventListener("keydown", escapeFilterMenuHandler);
      };
    }
  }, [props, escapeFilterMenuHandler]);
}

// useToHandleStateAndSetFocusBetweenChannelGridLinksAndViewReseller
export function useToHandleGlassFravcr() {
  let location = useLocation<any>();
  let whereTo = location?.state?.mdppAccessibilityNavigation;

  let pivot = undefined;
  if (
    whereTo === "back to channel tab" &&
    location.pathname.includes("/managecase/cpstrategic")
  ) {
    pivot = "Channel";
  }

  return pivot;
}

export function useToHandleSettingViewResellerNavState(): string | undefined {
  let location = useLocation<any>();
  let currentPath = location.pathname;

  if (currentPath.includes("/managecase/cpstrategic")) {
    return "from channel tab grid link";
  }

  return undefined;
}

export function useToSetFocusOnResellerNameLinkFocus() {
  let currentLocation = useLocation<any>();
  let currentLocationStateLinkId =
    "#" + currentLocation.state?.mdppAccessibilityLinkId;

  useEffect(() => {
    if (currentLocationStateLinkId) {
      (
        document.querySelector(currentLocationStateLinkId) as HTMLElement
      )?.focus();

      let locationState = currentLocation.state;
      let locationNav = locationState?.mdppAccessibilityNavigation;

      if (locationNav && locationNav === "back to channel tab") {
        currentLocation.state.mdppAccessibilityNavigation = "on channel tab";
      }
    }
  }, []);
}

export function MdppAccessibilityLink({
  ...rest
}: {} & React.ComponentPropsWithoutRef<typeof Link>) {
  useToSetFocusOnResellerNameLinkFocus();
  return <Link {...rest}></Link>;
}

//Accessibility - set Focus to Header in View Resellers component
export function useToSetFocusToHeader() {
  useEffect(() => {
    var heading = document.querySelector("h1");
    heading?.focus();
  });
}

//Accessibility - enable validation message to be announced by screen readers
export function useToAddRoleAlert() {
  useEffect(() => {
    var messageBox = document.querySelector(".ms-MessageBar-text");
    messageBox?.setAttribute("role", "alert");
  });
}

//Accessibility - enable Loading message to be announced by screen readers
export function useToSetRoleToLoadingMessage() {
  useEffect(() => {
    var loadingMessageSpinner = document.querySelector(".ms-Spinner-label");
    loadingMessageSpinner?.setAttribute("role", "alert");
  });
}

//Function to modify page title based on the active Page
function setTitle(currentTitle: string) {
  var setDocumentTitle = "NextGen Pricing Portal " + currentTitle;
  var currentDocumentTitle = document.querySelector("head title");
  if (currentDocumentTitle != null) {
    currentDocumentTitle.innerHTML = setDocumentTitle;
  }
}

//Accessibility Fix for screen readers to announce dashboard and new case submissionpage title correctly
export function useToSetPageTitle(page: string) {
  useEffect(() => {
    var currentPage = document.querySelectorAll(
      ".ms-Link.ms-Breadcrumb-itemLink div div"
    );
    if (page == "Discount") {
      var currentPageTitle = currentPage[0]?.innerHTML;
    } else {
      var currentPageTitle = currentPage[1]?.innerHTML;
    }
    setTitle(currentPageTitle);
  });
}

//Accessibility Fix for screen readers to announce Health and Support Page title correctly
export function useToSetSupportPageTitle() {
  useEffect(() => {
    var currentPage = document.querySelectorAll(".DRStyleExpand-197 div div");
    var currentPageTitle = currentPage[0]?.innerHTML;
    setTitle(currentPageTitle);
  });
}

/**
 * Accessibility fix adding role to grid column filter button
 * @function useToRemoveInvaidChildRole
 * @description This function removes the invalid role attribute
 * (Element has children which are not allowed: [role=navigation], button[aria-disabled], button[tabindex], [role=group]) from the child element of the grid
 * valid child elements can have the following aria attributes:
 * ("role=row","role=rowgroup","role=gridcell","role=columnheader","role=rowheader","aria-colindex","aria-rowindex","aria-selected","aria-level","aria-expanded")
 * and role[listbox] (Element has children which are not allowed: span[aria-hidden])
 * This fixes an accessibility issue reported by Accessibility Insights for Web 2.40.0 (axe-core 4.7.2).
 * @see WCAG 1.3.1: Ensures elements with an ARIA role that require child roles contain them (.k-grid)
 * @see https://accessibilityinsights.io/info-examples/web/aria-required-children)
 */
export function useToRemoveInvalidChildRole() {
  useEffect(() => {
    var grid = document.querySelector(".k-grid");

    var listboxes = document.querySelectorAll("[role=listbox]");
    let observers: MutationObserver[] = [];
    listboxes?.forEach((listbox) => {
      listbox.setAttribute("role", "combobox");
      listbox.querySelectorAll("span.k-input[role=option]").forEach((span) => {
        span.setAttribute("aria-label", "option");

        const callback = function (mutationsList: any[], observer: any) {
          for (const mutation of mutationsList) {
            if (
              mutation.type === "attributes" &&
              span.getAttribute("role") === "option"
            ) {
              span.setAttribute("role", "textbox");
            }
          }
        };

        // observer will be used to observe the span element for any changes in the role attribute
        // if the role attribute is changed to option, it will be changed back to textbox
        // this is to fix the accessibility issue reported by Accessibility Insights for Web 2.40.0 (axe-core 4.7.2)
        // the disconnect is not being used on the observer because there's one or more scripts that change the span role back to option
        // the observer should be garbage collected
        const observer = new MutationObserver(callback);
        observer.observe(span, { attributes: true });
        observers.push(observer);
      });
    });

    return () => {  
      forEach(observers, (observer) => {
        observer.disconnect();
      });
    }
  }, []);
}

/**
 * Accessibility fix adding role to grid column filter button
 * @function useToAddRoleToGridColumnFilterButton
 * @description This function adds a role attribute of 'button' to the parent element of each grid column filter icon,
 * to ensure that ARIA attributes are allowed for an element's role.
 * This fixes an accessibility issue reported by Accessibility Insights for Web 2.40.0 (axe-core 4.7.2).
 * @see WCAG 4.1.2: Ensures ARIA attributes are allowed for an element's role (div[aria-label="null filter"])
 * @see https://accessibilityinsights.io/info-examples/web/aria-allowed-attr
 */
export function useToAddRoleToGridColumnFilterButton(){
    useEffect(() => {
        
        var gridColumnFilterButton = document.querySelectorAll('.k-icon.k-i-more-vertical');
        gridColumnFilterButton.forEach((gridColumnFilterButton) => {
            gridColumnFilterButton.parentElement?.setAttribute('role','button');
        });
    }, []);
} 

/*
* Accessibility fix to remove invalid role attributes from the child elements of the grid
* @function useToFixGridAriaRequiredChildren
* @description This function removes the invalid role attribute
* (Element has children which are not allowed: [role=navigation], button[aria-disabled], button[tabindex], [role=group]) from the child element of the grid
* valid child elements can have the following aria attributes:
* ("role=row","role=rowgroup","role=gridcell","role=columnheader","role=rowheader","aria-colindex","aria-rowindex","aria-selected","aria-level","aria-expanded")
* and role[listbox] (Element has children which are not allowed: span[aria-hidden])
* This fixes an accessibility issue reported by Accessibility Insights for Web 2.40.0 (axe-core 4.7.2).
* @see WCAG 1.3.1: Ensures elements with an ARIA role that require child roles contain them (.k-grid)
*/
export function useToFixGridAriaRequiredChildren(gridContainerId: string){
  useEffect(() => {
    let gridContainer = document.querySelector(gridContainerId);
    var grid = document.querySelector('.k-grid');
    var gridPager = grid?.querySelectorAll(".k-grid .k-grid-pager");
    gridPager?.forEach((pager) => {
      let removedPager = grid?.removeChild(pager);
      gridContainer?.appendChild(removedPager as Node);
    });

  }, []);
}

/**
 * Ensures all ARIA attributes have valid values (tr[aria-rowindex="NaN"])
 * @function useToFixAriaAttributes
 * @description This function fixes an accessibility issue where the ARIA attribute value is invalid (aria-rowindex="NaN") for the specified element path (tr[aria-rowindex="NaN"]). The function sets the ARIA attribute value to a valid value.
 * @see WCAG 4.1.2: Ensures all ARIA attributes have valid values (https://www.w3.org/WAI/WCAG21/Understanding/aria-valid-attr-value.html)
 * @see https://accessibilityinsights.io/info-examples/web/aria-valid-attr-value
 */
export function useToFixAriaRowIndexNan() {

  function removeAriaRowIndex(element: Element) {
    if (element) {
      element.removeAttribute("aria-rowindex");
    }
  }
  
  useEffect(() => {
    let nanRowIndexes = document.querySelectorAll("tr[aria-rowindex]");
    let observers: MutationObserver[] = [];
    forEach(nanRowIndexes, (element, i) => {
      // once for each element
      removeAriaRowIndex(element);
      const mutationHandler = () => {
        // again in case another script readds the attribute
        removeAriaRowIndex(element);
      }

      const observer = new MutationObserver(mutationHandler);
      observer.observe(element, { attributes: true });
      observers.push(observer);
    });
    
    return () => {  
      forEach(observers, (observer) => {
        observer.disconnect();
      });
    }
  });
}

/*
* Accessibility fix for case action buttons at reflow resolution
* @function useToShowActionButtonTextOnHoverOrFocusInReflow
* @description This function hides the action button icons and shows the action button text on hover or focus in reflow resolution
* This fixes an accessibility issue reported by manual testing.
*/
export function useToShowActionButtonTextOnHoverOrFocusInReflow() {
  useEffect(() => {
    let actionButtons = document.querySelectorAll(".ms-Stack button.ms-Button--commandBar");
    let actionButtonText = document.querySelectorAll(".ms-Stack button.ms-Button--commandBar span span");
    let actionButtonIcons = document.querySelectorAll(".ms-Stack button.ms-Button--commandBar span i");

    let hideHandler = (event: Event) => {
      if(window.matchMedia("(width < 1281px)").matches){
        let hideAll = (ele: Element) => {
          let htmlEle = ele as HTMLElement;
          htmlEle.style.display = 'none';
        }
        
        // hide each of the button icons
        actionButtonIcons.forEach(hideAll);
        
        // show the current button's text
        let currentButton = event.currentTarget as HTMLElement;
        let currentButtonText = currentButton?.querySelector("span span") as HTMLElement;
        currentButtonText.style.display = 'block';
      }
    }

    let removeDisplayProperty = (element: Element) => {
      let htmlElement = element as HTMLElement;
      htmlElement.style.removeProperty("display");
    }

    let focusSlashMouseOutHandler = () => {
      actionButtonText.forEach(removeDisplayProperty);
      actionButtonIcons.forEach(removeDisplayProperty);
    }

    let focusSlashMouseInHandler = (element: any) => {
      hideHandler(element);
    }
    
    actionButtons.forEach((actionButton) => {
      actionButton.addEventListener("mouseover", focusSlashMouseInHandler);
      actionButton.addEventListener("focusin", focusSlashMouseInHandler);
      actionButton.addEventListener("mouseout", focusSlashMouseOutHandler);
     actionButton.addEventListener("focusout", focusSlashMouseOutHandler);
    });
  }, []);
}

/*
* @function MdppAccessibilityPanelGrid
* @description This function returns a NextGenKendoGrid component with the accessibility fixes applied
*/
export function MdppAccessibilityPanelGrid({...rest}: {} & React.ComponentPropsWithoutRef<typeof NextGenKendoGrid>) {
  useToFixAriaRowIndexNan();
  useToEnsureTabIndexSetForGridFilterButton();
  return <NextGenKendoGrid {...rest}></NextGenKendoGrid>;
}

/*
* Accessibility - replacing the action button on the view and edit case pages.
* @function cardHeaderIcon
* @description This function returns a div with the specified icon name
*/
export const cardHeaderIcon = (iconName: string, ariaLabel: string, id: string = iconName) : JSX.Element => {
  return <div style={{display:'inline-block', borderRadius:'27px', height:'27px', backgroundColor:'black', width:'27px', color: 'white', textAlign: 'center', verticalAlign:'center'}}>
      <FontIcon id={id} iconName={iconName} aria-label={ariaLabel} />
  </div>
}

/*
* Accessibility fix to ensure tabindex is set for grid column filter button
* @function useToEnsureTabIndexSetForGridFilterButton
* @description This function ensures tabindex is set for grid column filter button
*/
export function useToEnsureTabIndexSetForGridFilterButton() {
  useEffect(() => {
    let gridColumnFilters = document.querySelectorAll(
      ".k-icon.k-i-more-vertical"
    );
    
    const observer = new MutationObserver((mutationsList, observer) => {
      [...gridColumnFilters].filter((filter) => 
        !(filter.parentNode as Element).hasAttribute("tabindex")
      ).forEach((gridFilterButton) => {
        gridFilterButton?.parentElement?.setAttribute("tabindex", "0");
      });
    });

    observer.observe(document.body, { attributes: true });
    
    return () => {
      observer.disconnect();
    }
  }, []);
}

/*
* Accessibility fix to ensure tabindex is set for the panel bar li elements
* @function useToOverridePanelBarTabIndex
* @description This function ensures proper tab navigation for the panel bar li elements
*/
export function useToOverridePanelBarTabIndex(){
  useEffect(() => {

    const observer = new MutationObserver((mutationsList, observer) => {
      let panelBars = document.querySelectorAll(".k-panelbar");
  
      if(panelBars.length === 1){
        let panelBarElements = panelBars[0].querySelectorAll(".k-panelbar .k-panelbar-header");
        (panelBars[0] as HTMLElement).setAttribute("tabindex", "-1");
        panelBarElements.forEach( (liEle) => {
          let li = liEle as HTMLElement;
          li.setAttribute("tabindex", "0");
        });
      }
    });

    observer.observe(document.body, { attributes: true });

    return () => {
      observer.disconnect();
    }
  }, []);
}

export function useToHandleOpenColumnMenuOnKeyboardEnter() {
  const filterButtonEnterHandler = useCallback((event: Event) => {
    if((event as KeyboardEvent).key === "Enter"){
      event.preventDefault();
      event.target && (event.target as HTMLElement).click();
    }
  }, []);

  useEffect(() => {
    let filterButtons = document.querySelectorAll('[role="columnheader"] [role="button"][aria-label*="filter"]');
    filterButtons.forEach((filterButton) => {
      filterButton.addEventListener("keydown", filterButtonEnterHandler);
    });
  }, []);

  return () => {
    let filterButtons = document.querySelectorAll('[role="columnheader"] [role="button"][aria-label*="filter"]');
    filterButtons.forEach((filterButton) => {
      filterButton.removeEventListener("keydown", filterButtonEnterHandler);
    });
  }
}