
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { useState } from "react";
import { IDropDownCellProps } from "./IDropDownCellProps";
import { UIControlsText } from "../../../content/UIControlsText";
import React from "react";
import { PageStyles } from "../../../pages/pricingprogram/CPStrategic/content/Page.InternalPricingProgram.Styles";
import { SessionStorageConsts } from "../../../models/SessionStorageConsts";
import { getEntityDataFromSession, getRuleValidationMessage } from "../common/util";
import { MessageTypeText } from "../../../models/MessageTypeText";

/**
 * DropDownGridCell component.
 * @function component
 */
export const DropDownGridCell = (props: IDropDownCellProps) => {

    const [values, setValues] = useState(props.selectedValues || []); 
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    
    /**
     * Event to handle dropdown updates
     * @method
     * @param {IDropdownOption} item Updated dropdown selection 
     */
    const dropdownChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            if (props.isMultiSelect) {
                setValues((prevState: IDropdownOption[]) => {
                    let newState = item.selected ? [...prevState, item] : prevState.filter(x => x.key !== item.key);
                    props.itemSelectionUpdate && props.itemSelectionUpdate(props.cellId, newState);
                    return newState;
                });
            }
            else {
                if(props.isUploadedRecord != false){
                    setValues([item.key]);
                    props.itemSelectionUpdate && props.itemSelectionUpdate(props.cellId, [item.key]);
                }
            }
           
        }
    }

    return (
        <React.Fragment>
            {props.isMultiSelect &&
                <td>
                    <Dropdown
                        aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DropdownText", UIControlsText.DropdownText)}
                        multiSelect
                        onChange={dropdownChange}
                        options={props.data}
                        selectedKeys={values.map(i => i.key)}
                    />
                </td>
            }
            {!props.isMultiSelect &&
                <td>
                    <Dropdown
                    aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DropdownText", UIControlsText.DropdownText)}
                    onChange={dropdownChange}
                    options={props.data}
                    selectedKey={values[0]}
                    styles={PageStyles.gridCellDropDownStyles}
                    />
                </td>
            }
        </React.Fragment>
    )
}
export default DropDownGridCell;