import { useState, useCallback, useEffect } from "react";
import {Grid, GridColumn,  GridSelectionChangeEvent, GridHeaderSelectionChangeEvent,
    getSelectedState, GridPageChangeEvent,  GridSortChangeEvent
    
} from "@progress/kendo-react-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { getter } from "@progress/kendo-react-common";
import * as React from "react";
import { IPageState } from "./IPageState"
import { GridSelectionType } from "./SelectionType";
import { _Styles } from "../../../content/styles/Page.styles";
import { IGridChildProps } from "./IGridChildProps";

/**
 * NextGenKendoGrid Component.
 * @function component
 */
const NextGenKendoChildGrid: React.FunctionComponent<IGridChildProps> = (props: IGridChildProps) => {
    const detailIdGetter = getter(props.parent.kendoGridProps.dataItemKey === "undefined" ? "id" : props.parent.kendoGridProps.dataItemKey);
    const [detailSelectedCheckState, setDetailSelectedCheckState] = useState<{ [id: string]: boolean | number[]; }>({});
    const detailGridInitialSort: Array<SortDescriptor> = [{ field: (props.parent.kendoGridProps.detailGrid?.initialSortField || "id"), dir: "asc" }];
    const [detailsort, setDetailSort] = useState(detailGridInitialSort);
    const initialDataStateChildGrid: IPageState = { skip: 0, take: props.parent.kendoGridProps.detailGrid?.noOfRecords };
    const [childGridPage, setChildGridPage] = useState<IPageState>(initialDataStateChildGrid);
    const [childGriddata, setChildGridData] = useState<any[]>([]);
    useEffect(() => {
        if (props.childGridData) {
            setChildGridData(props.childGridData);
        }
    }, [props.childGridData])

    useEffect(() => {
        if (props.defaultChildGridSelectionstate) {
            setDetailSelectedCheckState(props.defaultChildGridSelectionstate);
        }
    }, [props.defaultChildGridSelectionstate])
    //--event for checkbox header selection in the product search child grid: when header checked all the child checkbox will be checked
    const onDetailGridHeaderSelectionChange = useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            const checkboxElement: any = event.syntheticEvent.target
            const checked = checkboxElement.checked;
            let newSelectedState: any = {} = detailSelectedCheckState;
            var data = props.parent.kendoGridProps.selectAllRows ? childGriddata : event.dataItems;
            data.forEach(item => {
                newSelectedState[detailIdGetter(item)] = checked;
            });
            setDetailSelectedCheckState(newSelectedState);
            props.parent.onDetailRowSelectionCallBack?.(newSelectedState, event.dataItems);
        }, [detailSelectedCheckState]);
    //event to trigger on change of checkbox in the grid
    const onDetailGridSelectionChange = useCallback(
        (event: GridSelectionChangeEvent) => {
            const checkboxElement: any = event.syntheticEvent.target
            const checked = checkboxElement.checked;
            let newSelectedState: any = {} = detailSelectedCheckState;
            newSelectedState[detailIdGetter(event.dataItem)] = checked;
            setDetailSelectedCheckState(newSelectedState);
            props.parent.onDetailRowSelectionCallBack?.(newSelectedState, event.dataItems);
        },
        [detailSelectedCheckState]
    );
    const onPageChangeChildGrid = (event: GridPageChangeEvent) => {
        setChildGridPage(event.page);
    };
    return (
        <React.Fragment>
            <Grid data={orderBy(childGriddata, detailsort)?.map((item: any) =>
                ({ ...item, [props.parent.kendoGridProps.detailGrid.selectedField]: detailSelectedCheckState[detailIdGetter(item)] })).slice
                (childGridPage.skip, childGridPage.take + childGridPage.skip)}
                skip={childGridPage.skip}
                take={childGridPage.take}
                selectedField={props.parent.kendoGridProps.detailGrid.selectedField}
                dataItemKey={props.parent.kendoGridProps.detailGrid.dataItemKey}
                selectable={{
                    enabled: true, drag: false, cell: false,
                    mode: props.parent.kendoGridProps.detailGrid.selectionType === GridSelectionType.Mulitple ? 'multiple' : 'single',
                }}
                resizable={props.parent.kendoGridProps.detailGrid.resizable}
                sortable={props.parent.kendoGridProps.detailGrid.sortable}
                total={childGriddata?.length}
                pageable={props.parent.kendoGridProps.detailGrid.pageable}
                filterable={props.parent.kendoGridProps.detailGrid.filterable}
                onSelectionChange={onDetailGridSelectionChange}
                onHeaderSelectionChange={onDetailGridHeaderSelectionChange}
                onPageChange={onPageChangeChildGrid}
                onSortChange={(e: GridSortChangeEvent) => { setDetailSort(e.sort); }}
                >
                {props.parent.kendoGridProps.detailGrid.actionTypes?.showSelectAllCheckboxGridAction &&
                    <GridColumn field={props.parent.kendoGridProps.detailGrid.selectedField}
                        headerSelectionValue={
                            props.childGridData.findIndex((item: any) => !detailSelectedCheckState[detailIdGetter(item)]) === -1
                        }
                        filterable={false} />
                }
                {props.parent.kendoGridProps.detailGrid.columns.map((col) => {
                    if (col.isVisible)
                        return <GridColumn
                            key={col.key} field={col.apiField} title={col.columnTitle} width={col.width}
                            editable={col.isEditable} sortable={col.allowSorting} filterable={col.allowFilter}
                            editor={col.editorType} className={col.cssClass}
                        />
                })}
            </Grid>
        </React.Fragment>
    )
}
export default NextGenKendoChildGrid;
