import * as React from "react";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { useLocation } from "react-router-dom";
import { UIControlsText } from "../../content/UIControlsText";
import { isNullOrEmpty } from "./common/util";
import { IError } from "../../models/IError";
import { getRuleValidationMessage, getEntityDataFromSession } from "./common/util";
import { MessageTypeText } from "../../models/MessageTypeText";
import { SessionStorageConsts } from "../../models/SessionStorageConsts";

const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

export const ErrorPage: React.FunctionComponent<IError> = (props: IError) => {
    const location = useLocation();
    const historyProps: any = location.state;
    const backToLogin = (): void => {
        // Remove all saved data from sessionStorage
        sessionStorage.clear();
        window.open(process.env.REACT_APP_RSLR_LOGOUT_API_URI, "_self");
    }
    const showHideBackToLogin = () => {
        if (isNullOrEmpty(props.showBackToLogin)) {
            return true
        }
        else {
            return props.showBackToLogin
        }
    }
    const showSomethingWrongMessage = () => {
        if (isNullOrEmpty(props.showSomethingWrongMessage)) {
            return true
        }
        else {
            return props.showSomethingWrongMessage
        }
    }
    return (
        <div className="text-center mt-3">
            {showSomethingWrongMessage() &&
                <h2>{getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "SomeThingWrong", UIControlsText.SomeThingWrong)}</h2>
            }
            <br />
            <h4>{historyProps?.message}</h4>
            <h4>{props?.customMessage}</h4>
            <br />
            {showHideBackToLogin() &&
                <PrimaryButton text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BackToLogin", UIControlsText.BackToLogin)}
                    onClick={backToLogin} title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BackToLogin", UIControlsText.BackToLogin)} />
            }
        </div>)
}