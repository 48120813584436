import { TextField, Stack, IStackTokens } from '@fluentui/react';
import Attachment from '../../../components/shared/attachment';
import { PageStyles } from '../../pricingprogram/common/content/Page.Styles';
import { IFinalTierFields } from '../props/IFInalTierFields';

const horizontalGapStackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
};

const FinalTierFields = (props: IFinalTierFields) => {
    const {
        ResellerNameTextField,
        ContactName,
        ContactEmail,
        Address,
        City,
        StateOrProvince,
        PostalCode,
        attachment,
        showAttachment
    } = props;
    return (
        <>
            <Stack horizontal tokens={horizontalGapStackTokens}>
                <TextField
                    id={ResellerNameTextField.id}
                    name={ResellerNameTextField.name}
                    label={ResellerNameTextField.label}
                    styles={ResellerNameTextField.styles}
                    placeholder={ResellerNameTextField.placeholder}
                    required={ResellerNameTextField.required}
                    validateOnLoad={ResellerNameTextField.validateOnLoad}
                    validateOnFocusOut={true}
                    maxLength={ResellerNameTextField.maxLength}
                    value={ResellerNameTextField.value}
                    onChange={ResellerNameTextField.onChange}
                    onGetErrorMessage={ResellerNameTextField.onGetErrorMessage}
                    disabled={ResellerNameTextField.disabled}
                />
            </Stack>
            <Stack horizontal tokens={horizontalGapStackTokens}>
                <TextField
                    id={ContactName.id}
                    name={ContactName.name}
                    label={ContactName.label}
                    styles={ContactName.styles}
                    placeholder={ContactName.placeholder}
                    required={ContactName.required}
                    validateOnLoad={false}
                    validateOnFocusOut={true}
                    maxLength={ContactName.maxLength}
                    value={ContactName.value}
                    onChange={ContactName.onChange}
                    onGetErrorMessage={ResellerNameTextField.onGetErrorMessage}
                    disabled={ContactName.disabled}
                />
                <TextField
                    id={ContactEmail.id}
                    name={ContactEmail.name}
                    label={ContactEmail.label}
                    styles={ContactEmail.styles}
                    placeholder={ContactEmail.placeholder}
                    required={ContactEmail.required}
                    validateOnLoad={false}
                    validateOnFocusOut={true}
                    maxLength={ContactEmail.maxLength}
                    value={ContactEmail.value}
                    onChange={ContactEmail.onChange}
                    onGetErrorMessage={ResellerNameTextField.onGetErrorMessage}
                    disabled={ContactEmail.disabled}
                />
            </Stack>
            <Stack horizontal tokens={horizontalGapStackTokens}>
                <TextField
                    id={Address.id}
                    name={Address.name}
                    label={Address.label}
                    styles={Address.styles}
                    placeholder={Address.placeholder}
                    required={Address.required}
                    validateOnLoad={false}
                    validateOnFocusOut={true}
                    maxLength={Address.maxLength}
                    value={Address.value}
                    onChange={Address.onChange}
                    onGetErrorMessage={ResellerNameTextField.onGetErrorMessage}
                    disabled={Address.disabled}
                />
                <TextField
                    id={City.id}
                    name={City.name}
                    label={City.label}
                    styles={City.styles}
                    placeholder={City.placeholder}
                    required={City.required}
                    validateOnLoad={false}
                    validateOnFocusOut={true}
                    maxLength={City.maxLength}
                    value={City.value}
                    onChange={City.onChange}
                    onGetErrorMessage={ResellerNameTextField.onGetErrorMessage}
                    disabled={City.disabled}
                />
            </Stack>
            <Stack horizontal tokens={horizontalGapStackTokens}>
                <TextField
                    id={StateOrProvince.id}
                    name={StateOrProvince.name}
                    label={StateOrProvince.label}
                    styles={StateOrProvince.styles}
                    placeholder={StateOrProvince.placeholder}
                    required={StateOrProvince.required}
                    validateOnLoad={false}
                    validateOnFocusOut={true}
                    maxLength={StateOrProvince.maxLength}
                    value={StateOrProvince.value}
                    onChange={StateOrProvince.onChange}
                    onGetErrorMessage={ResellerNameTextField.onGetErrorMessage}
                    disabled={StateOrProvince.disabled}
                />
                <TextField
                    id={PostalCode.id}
                    name={PostalCode.name}
                    label={PostalCode.label}
                    styles={PostalCode.styles}
                    placeholder={PostalCode.placeholder}
                    required={PostalCode.required}
                    validateOnLoad={false}
                    validateOnFocusOut={true}
                    maxLength={PostalCode.maxLength}
                    value={PostalCode.value}
                    onChange={PostalCode.onChange}
                    onGetErrorMessage={ResellerNameTextField.onGetErrorMessage}
                    disabled={PostalCode.disabled}
                />
            </Stack>
            {attachment && showAttachment &&
                <Stack horizontal tokens={horizontalGapStackTokens}>
                    <Attachment
                        {...{
                            ...attachment
                        }}
                    />
                </Stack>}
        </>
    );
}

export default FinalTierFields;
