import { getTheme, IDocumentCardPreviewProps, mergeStyleSets } from "@fluentui/react";
import { FontWeights } from "office-ui-fabric-react";

/**
 * Page Style Channel contacts Submission.
 * @constant
 */
export const PageStyles = {
  disabledTabs: {
    opacity: 0.5,
    cursor: "default",
  },
  missingFieldTabs: {
    borderBottom: "solid 3px red",
  },
  commandButtonStyles: {
    root: { marginTop: 20 },
  },
  commandButtonPadding: {
    root: { marginRight: 8, marginTop: 10 },
  },
  labelStyles: {
    root: { marginTop: 10 },
  },
  dropdownStyles: {
    dropdown: { width: 300 },
    title: {
      color: '#171717',
      fontSize: '11px',
      height: '32px',
      padding: '0px 28px 28px 8px',
    },
    errorMessage: {
      width: 295
    }
  },
  bgdropdownStyles: {
    dropdown: { width: 230 },
    title: {
      color: '#171717',
      fontSize: '11px',
      height: '32px',
      padding: '0px 28px 28px 8px',
    },
    errorMessage: {
      width: 295
    }
  },
  bgSKUStyles: {
    header1: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "20px",
      border: "none",
      width: "100px"
    },
    paddingLeft250: {
      paddingLeft: "250px"
    },
    header2: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "20px",
      border: "none",
    },
    productNames: {
      fontSize: "12px",
      lineHeight: "20px",
    },
  },
  dropdownStylesTwoColumn: {
    dropdown: { width: 400 },
  },
  PaneldropdownStyles: {
    dropdown: { width: 165 },
  },
  addItemButtonStyles: {
    height: "25px",
    width: "150px",
    padding: 0,
  },
  addResellerAlignement: {
    height: "25px",
    width: "150px",
    padding: 0,
    marginTop: 0,
    marginBottom: 15,
  },
  innerHtmlMinWidth: {
    root: { minWidth: 250, maxWidth: 350 },
  },
  textFieldStylesWidth550: {
    root: { width: 550 },
  },
  textFieldStylesOneColumn: {
    root: { width: 300 },
  },
  textFieldStylesTwoColumn: {
    root: { width: 250 },
  },
  textFieldStylesFourColumn: {
    root: { width: 140 },
  },
  textFieldStylesWidth146: {
    root: { width: 146 },
  },
  textFieldStylesReadOnlyWidth146: {
    root: { width: 146 },
    fieldGroup: { backgroundColor: "#f3f2f1" }
  },
  textFieldStylesReadOnlyWidth250: {
    root: { width: 250 }
  },
  textFieldStylesWidth92: {
    root: { width: 92 },
    errorMessage: { width: 300 },
  },
  textFieldPanelWidth: {
    root: { width: 514 },
  },
  multiLineTextWidth: {
    root: { width: 350 },
  },

  dealContactAddButton: {
    height: "25px",
    width: "150px",
    padding: 0,
  },
  ErrorMessageStyles: {
    paddingBottom: "30px",
  },
  missingFieldHighlight: {
    border: "solid 1px red",
  },
  sectionHeading: {
    fontSize: "15px",
    borderBottom: "2px solid #0063B1",
  },
  sectionDivider: {
    margin: "10px 30px 10px 0px",
    borderTop: "1px solid #d3d3d3",
    width: "100%",
  },
  epcLabelWidth: {
    width: "26px",
  },
  warningMessageWidth: {
    width: "100px",
  },
  openPanelButtonStyles: {
    width: "180px",
    border: "1px solid #000",
    maxHeight: "25px",
  },
  distributorDropdownStyles: {
    dropdown: { width: 185 },
  },
  panelButton: {
    margin: '20px 0 0 10px',
    cursor: 'pointer'
  },
  panelButtonNoCursor: {
    margin: '20px 0 0 10px',
  },
  panelButtonStyle: {
    margin: '0 0 0 10px',
    cursor: 'pointer'
  },
  buttonStyles: {
    borderRadius: '3px',
    margin: '5px 8px 0px 8px',
    padding: '3px',
    width: '150px',
    fontSize: '.10em'
  },
  buttonStylesLong: {
    borderRadius: '3px',
    margin: '5px 8px 0px 8px',
    padding: '3px',
    width: '180px',
    fontSize: '.10em'
  },
  paddingTop10px: {
    paddingTop: "10px"
  },
  paddingTop8px: {
    paddingTop: "8px"
  },
  paddingTop20px: {
    paddingTop: "20px"
  },
  completedSolidIconStyle: {
    fontSize: 70,
    height: 70,
    width: 70
  },
  greenColor: {
    color: 'green'
  },
  successCaseColor: {
    color: "#0070c0",
    fontWeight: "bold",
    fontSize: 15
  },
  successBlackColor: {
    color: "black",
    fontWeight: "bold",
    fontSize: 15
  },
  ThankyouFontSize: {
    fontSize: 15
  },
  warningBackGroundColor: {
    content: { backgroundColor: "#fff4ce" }
  },
  searchStyles: {
    dropdown: { width: 140 },
  },
  successDraftPadding: {
    root: { padding: "0px 100px 0 100px" }
  },
  spinnerLoadingIcon: {
    root: {
      position: "fixed" as "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: "rgba(0, 0, 0, 0.1)",
      zIndex: 99999999,
    }
  },
  pivotTabError: {
    color: "red",
    fontWeight: "bold"
  },
  pivotTabNormal: {
    color: "black",
    fontWeight: "bold"
  },
  pivotTabDisabled: {
    opacity: 0.5,
    cursor: "default",
    fontWeight: "bold"
  },
  paddingBottom15: {
    paddingBottom: '15px'
  }
};

export const OpportunityDetailsStyles = {
  DeleteButton: {
    height: "27px",
    paddingTop: "30px",
  },
  HorizontalChoiceGroup: {
    flexContainer: { display: "flex", justifyContent: "space-between" },
  },
};

export const DealDetailsStyles = {
  dealRegistrationColWidth: {
    width: "300px",
  },
  dealRegistrationVolumeWidth: {
    width: "179px",
    marginTop: "4px",
    marginLeft: "5px",
  },
  dealRegistratioLabelWidth: {
    width: "36px",
  },
  quantityTextBoxWidth: {
    width: "35px",
    height: "20px",
  },
  monthsTextWidth: {
    width: "60px",
    paddingTop: "10px",
    paddingLeft: "5px",
  },
  radioButtonStyle: {
    padding: "4px 10px 0px 5px",
  },
  stackStylesWidth: {
    width: "auto",
    paddingTop: "7px",
  },
  textboxLabelPadding: {
    paddingTop: "28px",
  },
  marginTopStyle: {
    marginTop: "10px",
  },
  marginLeftStyle: {
    marginLeft: "18px",
  },
  marginLeft10: {
    marginLeft: "10px",
  },
  marginLeftFontSize: {
    marginLeft: "10px",
    fontSize: "12px"
  },
  fontSize12: {
    fontSize: "12px"
  },
  checkBoxLabelFontStyle: {
    fontSize: "12px",
  },
  sectionDivider: {
    margin: "15px 30px 10px 0px",
    borderTop: "1px solid #d3d3d3",
  },
  backGroundColor: {
    backgroundColor: "#EDEBE9",
    width: "40%",
    paddingBottom: "7px",
  },
  backgroundColorWhite: {
    backgroundColor: "white",
  },
  horizontalChoiceGroupSingleColumn: {
    flexContainer: { display: "flex" },
    root: { width: 300 },
  },
  horizontalChoiceGroupSmallColumn: {
    flexContainer: { display: "flex" },
    root: { width: 200 },
  },
  horizontalChoiceGroup: {
    flexContainer: { display: "flex" },
  },
  backGroundColorGreyWithPadding: {
    root: {
      backgroundColor: "#f3f2f1",
      margin: "0px 40px 0px 7px",
      padding: "5px"
    },
  },
  backGroundColorGrey: {
    backgroundColor: "#f3f2f1",
  },
  borderWidth: {
    borderWidth: 0
  },
  labelFontStyle: {
    fontWeight: 400
  },
  dealVolumeWidth: {
    width: "300px",
    paddingTop: "5px"
  },
  dealVolumeWidthPaddingTop: {
    width: "300px",
    paddingTop: "5px"
  },
  textBoxWidth: {
    width: "60px"
  },
  customerWarningMessageWidth: {
    text: { width: "600px" }
  }
};

export const hovercardClassNames = mergeStyleSets({

  expandedCard: {
    padding: '16px 24px',
  },
});