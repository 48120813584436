import { smallAlignmentTokens, stackItemCustomerStyles } from '../PricingProgramFormUtils';
import { UIControlsText, UIConfigIdentifierConstants } from '../../../../content/UIControlsText';
import { PageStyles } from '../../common/content/Page.Styles';
import { getsavedContext, getEntityDataFromSession, getRuleValidationMessage, isNullOrEmpty, getUserDetails, isRequestorViewing } from '../../../../components/shared/common/util';
import { useCommonPPContext } from '../../common/context/common-pp-context';
import { AttachmentDisplayMode } from '../../../../models/AttachmentDisplayMode';
import Moment from 'moment';
import { IDealInfoProps } from '../props/IDealInfoProps';
import { JavaScriptExtensions } from '../../../../infrastructure/JavaScriptExtensions';
import { UIControlType } from '../../../../models/UIControlType';
import { RfpType } from '../../../../models/RfpType';
import { textFieldStylesTwoColumn } from '../../../../content/styles/Page.styles';
import { IVertical } from '../../../../models/IVertical';
import { DealType } from '../../../../models/DealType';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { MessageTypeText } from '../../../../models/MessageTypeText';

export function DealDetails(props: IDealInfoProps) {
    const { commonPPContextState } = useCommonPPContext();
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const isRfpRfqPublicTender: boolean = commonPPContextState?.opportunityRfp === RfpType.Yes;
    const selectedOptyDeal = commonPPContextState.opportunityDeals && commonPPContextState.opportunityDeals.find(t => t.name === commonPPContextState?.selectedOptyDealCaseNumber);
    const customerData = selectedOptyDeal && selectedOptyDeal?.dealCustomers && selectedOptyDeal?.dealCustomers[0];
    let loggedInuser = getUserDetails();
    const getCountryName = (): string | undefined => {
        const country = selectedOptyDeal && selectedOptyDeal?.dealAddress && selectedOptyDeal?.dealAddress?.country;
        if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(country)) {
            return props?.countries?.find(t => t.countryId === country)?.countryName;
        }

        return '';
    }
    const checkRequestorViewing = () => {
        return isRequestorViewing(commonPPContextState.submitterDetails?.email);
    }
    const DealDetailsJSON = [
        {
            type: "Stack",
            data: {
                id: "StackTileForDeal",
                'data-nextgen-parent-group': "View - Deal Details",
                'data-nextgen-parentid': "View - Deal Details",
                horizontal: true,
                verticalAlign: "start",
                components: [
                    {
                        type: "CustomTileCardComponent",
                        data: {
                            id: "ViewCardForDeal",
                            baseState: commonPPContextState,
                            name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText),
                            iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText),
                            components: [
                                {
                                    type: "Stack",
                                    data: {
                                        id: "StackTileItemForDeal",
                                        horizontal: true,
                                        components: [
                                            {
                                                type: 'StackItem',
                                                data: {
                                                    id: UIConfigIdentifierConstants.StackDealsNameRowId,
                                                    styles: stackItemCustomerStyles,
                                                    align: "start",
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                id: "DealNameTextField",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealNameText", UIControlsText.DealNameText),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealNameText", UIControlsText.DealDetailsText),
                                                                contolType: UIControlType.LabelValueView,
                                                                value: selectedOptyDeal && selectedOptyDeal?.dealName,
                                                            }
                                                        },
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                id: "dealVerticalDropdown",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVerticalText", UIControlsText.DealVerticalText),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVerticalText", UIControlsText.DealVerticalText),
                                                                contolType: UIControlType.LabelValueView,
                                                                value: (props.verticals && props.verticals?.map((item: IVertical) => ((item?.id === (commonPPContextState?.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.dealVertical)) ? item.name : '') as string)),
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                type: "Stack",
                                                data: {
                                                    id: "StackContainerForDealOverview",
                                                    vertical: true,
                                                    components: [
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: UIConfigIdentifierConstants.StackItemDealOverviewId,
                                                                styles: stackItemCustomerStyles,
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: UIConfigIdentifierConstants.DealOverviewTextFieldId,
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText),
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: selectedOptyDeal && selectedOptyDeal?.dealOverview,
                                                                            styles: PageStyles.multiLineTextWidth,
                                                                        }
                                                                    },
                                                                ]
                                                            }
                                                        },
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    type: 'Stack',
                                    data: {
                                        id: 'StackForCaseDecision',
                                        horizontal: "true",
                                        disableshrink: 'true',
                                        components: [
                                            {
                                                type: 'StackItem',
                                                data: {
                                                    id: 'StackItemForStartDate',
                                                    styles: stackItemCustomerStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                id: "CaseStartDateTextField",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealInfoCaseStartDateText", UIControlsText.DealInfoCaseStartDateText),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealInfoCaseStartDateText", UIControlsText.DealInfoCaseStartDateText),
                                                                contolType: UIControlType.LabelValueView,
                                                                value: selectedOptyDeal && selectedOptyDeal?.caseStartDate && Moment(selectedOptyDeal?.caseStartDate).format('MM/DD/YYYY'),
                                                            }
                                                        },
                                                    ]
                                                }
                                            },
                                            {
                                                type: 'StackItem',
                                                data: {
                                                    id: 'StackItemForCaseDealEndDate',
                                                    styles: stackItemCustomerStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                id: "CaseEndDateTextField",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealInfoEndDateText", UIControlsText.DealInfoEndDateText),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealInfoEndDateText", UIControlsText.DealInfoEndDateText),
                                                                contolType: UIControlType.LabelValueView,
                                                                value: selectedOptyDeal && selectedOptyDeal?.expirationDate && Moment(selectedOptyDeal?.expirationDate).format('MM/DD/YYYY'),
                                                            }
                                                        },
                                                    ]
                                                }
                                            },
                                            {
                                                type: "StackItem",
                                                data: {
                                                    id: "StackItemForDomain",
                                                    styles: stackItemCustomerStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                id: "CustomerDomainTextField",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDomainText", UIControlsText.CustomerDomainText),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDomainText", UIControlsText.CustomerDomainText),
                                                                contolType: UIControlType.LabelValueView,
                                                                value: selectedOptyDeal && selectedOptyDeal?.dealCustomers && selectedOptyDeal?.dealCustomers[0]?.dealCustomerDomain,
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                },
                            ]
                        }
                    },
                    {
                        //Device Ship to Address Stack Tile
                        type: "Stack",
                        data: {
                            id: "StackTileForAddress",
                            horizontal: true,
                            verticalAlign: "start",
                            components: [
                                {
                                    type: "CustomTileCardComponent",
                                    data: {
                                        id: "ViewCardForAddress",
                                        baseState: commonPPContextState,
                                        name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeviceShipToAddressCardText", UIControlsText.DeviceShipToAddressCardText),
                                        iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddressIcon", UIControlsText.AddressIcon),
                                        components: [
                                            {
                                                type: 'Stack',
                                                data: {
                                                    id: UIConfigIdentifierConstants.StackDeviceShipToAddressRowId,
                                                    horizontal: true,
                                                    components: [
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'StackItemForShipAddress',
                                                                styles: stackItemCustomerStyles,
                                                                align: "start",
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "DeviceShipToAddressTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeviceShipToAddressText", UIControlsText.DeviceShipToAddressText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeviceShipToAddressText", UIControlsText.DeviceShipToAddressText),
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: selectedOptyDeal && selectedOptyDeal?.dealAddress && selectedOptyDeal?.dealAddress?.address1,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'StackItemForCountry',
                                                                styles: stackItemCustomerStyles,
                                                                align: "end",
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CountryTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Country", UIControlsText.Country),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Country", UIControlsText.Country),
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: getCountryName(),
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                    ]
                                                }
                                            },
                                            {
                                                type: 'Stack',
                                                data: {
                                                    id: 'StackForFirstRowAddress',
                                                    horizontal: true,
                                                    //disableshrink: 'true',
                                                    components: [
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'StackItemForCity',
                                                                styles: stackItemCustomerStyles,
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CityTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CityText", UIControlsText.CityText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CityText", UIControlsText.CityText),
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: selectedOptyDeal && selectedOptyDeal?.dealAddress && selectedOptyDeal?.dealAddress?.city,
                                                                        }
                                                                    },
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'StackItemForStateProvince',
                                                                styles: stackItemCustomerStyles,
                                                                align: "end",
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "StateProvinceTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeviceShipToStateProvinceText", UIControlsText.DeviceShipToStateProvinceText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeviceShipToStateProvinceText", UIControlsText.DeviceShipToStateProvinceText),
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: selectedOptyDeal && selectedOptyDeal?.dealAddress && selectedOptyDeal?.dealAddress?.stateOrProvince,
                                                                        }
                                                                    },
                                                                ]
                                                            }
                                                        },
                                                    ]
                                                },
                                            },
                                            {
                                                type: "Stack",
                                                data: {
                                                    id: "StackForSecondRowAddress",
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                id: "PostalCodeTextField",

                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeviceShipToPostalCodeText", UIControlsText.DeviceShipToPostalCodeText),
                                                                    ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeviceShipToPostalCodeText", UIControlsText.DeviceShipToPostalCodeText),

                                                                contolType: UIControlType.LabelValueView,
                                                                value: selectedOptyDeal && selectedOptyDeal?.dealAddress && selectedOptyDeal?.dealAddress?.postalCode,
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                        ]
                                    }
                                },
                            ]
                        }
                    },
                ]
            }
        },
        {
            type: "Stack",
            data: {
                id: "StackItemForCommittedDealVolTile",
                horizontal: true,
                verticalAlign: "start",
                components: [
                    {
                        type: "CustomTileCardComponent",
                        data: {
                            id: "ViewCardForCommittedDealVolume",
                            baseState: commonPPContextState,
                            name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CommittedDealVolumeText", UIControlsText.CommittedDealVolumeText),
                            iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CommittedDealVolumeIcon", UIControlsText.CommittedDealVolumeIcon),
                            components: [
                                {
                                    type: 'StackItem',
                                    data: {
                                        id: 'StackItemCommittedDealVolume',
                                        styles: stackItemCustomerStyles,
                                        align: "auto",
                                        components: [
                                            {
                                                type: "CommittedDealVolume",
                                                data: {
                                                    opportunityDeals: commonPPContextState?.opportunityDeals,
                                                    pageMode: commonPPContextState?.pageMode,
                                                    dealTypes: props.dealTypes,
                                                    selectedOptyDealCaseNumber: commonPPContextState?.selectedOptyDealCaseNumber,
                                                    partnerDetails: commonPPContextState?.partnerDetails,
                                                }
                                            },
                                        ]
                                    }
                                },
                            ]
                        }
                    },
                    {
                        type: "StackItem",
                        data: {
                            id: "StackTileItemForCase",
                            horizontal: true,
                            verticalAlign: "start",
                            components: [
                                {
                                    type: "CustomTileCardComponent",
                                    data: {
                                        id: "ViewCardForCase",
                                        baseState: commonPPContextState,
                                        name: "Case Details",
                                        iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseDetailsIcon", UIControlsText.CaseDetailsIcon),
                                        components: [
                                            {
                                                type: 'StackItem',
                                                data: {
                                                    id: UIConfigIdentifierConstants.StackItemEmailCCNotificationRecipientsId,
                                                    styles: stackItemCustomerStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            visible: checkRequestorViewing(),
                                                            data: {
                                                                id: "EmailCCNotificationRecipient",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailCCNotificationRecipients", UIControlsText.EmailCCNotificationRecipients),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailCCNotificationRecipients", UIControlsText.EmailCCNotificationRecipients),
                                                                styles: PageStyles.textFieldStylesOneColumn,
                                                                contolType: UIControlType.LabelValueView,
                                                                value: commonPPContextState?.opportunityChannelContacts && commonPPContextState?.opportunityChannelContacts.find(t => t.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText))?.channelContactEmails,
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                type: 'StackItem',
                                                data: {
                                                    id: UIConfigIdentifierConstants.StackForCaseDecisionRowId,
                                                    styles: stackItemCustomerStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                id: "CaseDecisionTextField",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseDecisionJustification", UIControlsText.CaseDecisionJustification),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseDecisionJustification", UIControlsText.CaseDecisionJustification),
                                                                contolType: UIControlType.LabelValueView,
                                                                styles: textFieldStylesTwoColumn,
                                                                value: selectedOptyDeal && selectedOptyDeal?.closureDecisionComments
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                        ]
                                    },
                                }
                            ]
                        }
                    }
                ]
            }
        },
        {
            type: "Stack",
            data: {
                id: "StackItemTileForRFPRFQTender",
                horizontal: true,
                verticalAlign: "start",
                components: [
                    {
                        type: "StackItem",
                        visible: selectedOptyDeal?.dealType?.name === DealType.DealRegistration,
                        data: {
                            id: "StackTileForPreSales",
                            horizontal: true,
                            verticalAlign: "start",
                            components: [
                                {
                                    type: "CustomTileCardComponent",
                                    data: {
                                        id: "CustomTileCardComponentForPreSales",
                                        baseState: commonPPContextState,
                                        name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PreSalesActivitiesText", UIControlsText.PreSalesActivitiesText),
                                        iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PreSalesIcon", UIControlsText.PreSalesIcon),
                                        components: [
                                            {
                                                type: "Stack",
                                                data: {
                                                    id: "ContainerForPreSales",
                                                    vertical: true,
                                                    tokens: smallAlignmentTokens,
                                                    verticalAlign: "start",
                                                    components: [
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: UIConfigIdentifierConstants.StackItemPreSalesActivitiesId,
                                                                styles: stackItemCustomerStyles,
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "dealPreSalesActivities",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PreSalesActivitiesText", UIControlsText.PreSalesActivitiesText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PreSalesActivitiesText", UIControlsText.PreSalesActivitiesText),
                                                                            styles: PageStyles.textFieldPanelWidth,
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: selectedOptyDeal && selectedOptyDeal?.preSalesActivity,
                                                                        }
                                                                    },
                                                                ]
                                                            }
                                                        },
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        type: "StackItem",
                        data: {
                            id: "StackTileForRfQ",
                            horizontal: true,
                            verticalAlign: "start",
                            components: [
                                {
                                    type: "CustomTileCardComponent",
                                    data: {
                                        id: "ViewCardForRFP",
                                        baseState: commonPPContextState,
                                        name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RfpRfqPublicTenderText", UIControlsText.RfpRfqPublicTenderText),
                                        iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PublicTenderPolicyIcon", UIControlsText.PublicTenderPolicyIcon),
                                        components: [
                                            {
                                                type: 'Stack',
                                                data: {
                                                    id: 'DealsRFPRFQTab',
                                                    wrap: true,
                                                    horizontal: true,
                                                    components: [
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                styles: stackItemCustomerStyles,
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: UIConfigIdentifierConstants.RfpRfqPublicTenderId,
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RfpRfqPublicTenderText", UIControlsText.RfpRfqPublicTenderText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RfpRfqPublicTenderText", UIControlsText.RfpRfqPublicTenderText),
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: commonPPContextState.opportunityRfp === RfpType.Yes ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText)
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: UIConfigIdentifierConstants.StackItemPublicTenderJustificationId,
                                                                styles: stackItemCustomerStyles,
                                                                visible: isRfpRfqPublicTender,
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "RfpRfqPublicTenderJustificationText",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RfpRfqPublicTenderJustificationText", UIControlsText.RfpRfqPublicTenderJustificationText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RfpRfqPublicTenderJustificationText", UIControlsText.RfpRfqPublicTenderJustificationText),
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: commonPPContextState?.rfpJustification,
                                                                            styles: PageStyles.textFieldPanelWidth,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            visible: (commonPPContextState?.attachments?.filter(x => x?.source === UIControlsText.DealDetailsText) || [])?.length > 0,
                                                            data: {
                                                                id: 'StackItemPublicTenderAttachements',
                                                                styles: stackItemCustomerStyles,
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "Attachment",
                                                                        data: {
                                                                            id: "RfpRfqPublicTenderAttachement",
                                                                            attachmentList: commonPPContextState?.attachments?.filter(x => x?.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText)) || [],
                                                                            labelText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachments", UIControlsText.Attachments),
                                                                            source: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText),
                                                                            //guidanceText: GuidanceText.DealInfoPublicTenderAttachments,
                                                                            opportunityDealStatusCode: commonPPContextState.opportunityDeals[0]?.statusCode,
                                                                            pageMode: commonPPContextState.pageMode,
                                                                            attachmentDisplayMode: AttachmentDisplayMode.Grid,
                                                                            allowedFileExtensions: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AllowedFileExtensionsForDRAndSP", UIControlsText.AllowedFileExtensionsForDRAndSP),
                                                                            savedAttachments: getsavedContext()?.attachments
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },

                                                    ]
                                                },
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        {
            type: "Stack",
            data: {
                id: "StackItemTileForAdditionalInformation",
                horizontal: true,
                verticalAlign: "start",
                components: [
                    {
                        type: "StackItem",
                        visible: true,
                        data: {
                            id: "StackTileForAdditionalInformation",
                            horizontal: true,
                            verticalAlign: "start",
                            components: [
                                {
                                    type: "CustomTileCardComponent",
                                    data: {
                                        id: "ViewCardForAdditionalInformation",
                                        baseState: commonPPContextState,
                                        name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalInformation", UIControlsText.AdditionalInformation),
                                        iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsIcon", UIControlsText.DealDetailsIcon),
                                        components: [
                                            {
                                                type: 'Stack',
                                                data: {
                                                    id: 'AdditionalInformationTab',
                                                    wrap: true,
                                                    horizontal: true,
                                                    components: [
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                styles: stackItemCustomerStyles,
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: UIConfigIdentifierConstants.AdditionalInformation,
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalInformation", UIControlsText.AdditionalInformation),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalInformation", UIControlsText.AdditionalInformation),
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: selectedOptyDeal && selectedOptyDeal.additionalComments,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                },
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ];

    return DealDetailsJSON;
}