import React, { useState,useEffect, useRef } from "react";
import { Stack, IStackTokens, PrimaryButton, Spinner } from "@fluentui/react";
import {  defaultColumnProps, stackGridStyles, _Styles } from "../../content/styles/Page.styles";
import NextGenKendoGrid from "../../components/shared/grid/nextgenkendogrid";
import { IGridProps } from "../../components/shared/grid/IGridProps";
import { getLoggedinUserRoleDetails, getURLParamValue, readGridConfig, getEntityDataFromSession, getRuleValidationMessage } from "../../components/shared/common/util";
import { GridType } from "../../models/GridType";
import { UIControlsText } from "../../content/UIControlsText";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { ApplicationConstants } from "../../models/ApplicationConstants";
import { IUserRoleDetail } from "../../models/IUserRoleDetail";
import { nextGenService } from "../../services/NextGenService";
import { IAccount } from "../../models/IAccount";
import _ from "lodash";
import { ICountry } from "../../models/ICountry";
import { SessionStorageConsts } from '../../models/SessionStorageConsts';
import { MessageTypeText } from '../../models/MessageTypeText';
import { useToSetFocusToHeader as mdppAccessibilityToSetFocusToHeader } from "../../hooks/accessiblity-hooks";
import { useToSetRoleToLoadingMessage as mdppAccessibilityToSetRoleToLoadingMessage } from "../../hooks/accessiblity-hooks";

const stackTokens: IStackTokens = { childrenGap: 24 };
export const ViewResellers: React.FunctionComponent = () => {
    const [isResellerLoading, setIsResellerLoading] = useState<boolean>(false);
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    let history = useHistory();
    let location:any = useLocation();
    let country:any = null;
    let isEUEFTA:any = null;
    const closeBTn = () => {
        let currentUrl = window.location.href
        if (currentUrl.includes("oppNumber")) {
            let prevNavigation = location.state.mdppAccessibilityNavigation;
            let isFromChannelTab = prevNavigation && prevNavigation === "from channel tab grid link";

            if(isFromChannelTab){
                location.state.mdppAccessibilityNavigation = "back to channel tab";
            }

            history.push("/managecase/cpstrategic?oppNumber=" + getURLParamValue(ApplicationConstants.OpportunityNumberParamName), location.state);
        }else{
            history.push("/dashboard");
        }
    }
    if(location.state !== undefined){
        country = location.state.countryName.country;
        isEUEFTA = location.state.isEUEFTA.isEUEFTA;
    }

    const [viewResellerConfig, setviewResellerConfig] =
        useState<IGridProps>(readGridConfig(GridType.ViewResellers));
    const resellerAccounts = useRef<IAccount[]>([]);
    useEffect(() => {
        (async () => {
        setIsResellerLoading(true);
        const oppNumber = getURLParamValue(ApplicationConstants.OpportunityNumberParamName);
        let resellerCountries:string[] = [];
        let opportunityProducytype: string = "00000000-0000-0000-0000-000000000000";
        if(oppNumber !== null && oppNumber.length > 0){
            let allCountriesInSession: ICountry[] = JSON.parse(sessionStorage.getItem(SessionStorageConsts.countries)  || '{}'); 
            opportunityProducytype = JSON.parse(sessionStorage.getItem(SessionStorageConsts.opportunityProductType) || '{}');
            if(isEUEFTA == null || !isEUEFTA)
            { 
                if(country != undefined && country.length > 0){
                    let selectedCountry: ICountry[] = allCountriesInSession.filter(con => con.countryName === country);  
                    resellerCountries = selectedCountry.map(function(con) { return con.countryId}).join(',').split(",");  
                }
            }
            else
            {
                let isEUEFTACountries: ICountry[] = allCountriesInSession.filter(country => country.isEUEFTA==true);           
                resellerCountries = isEUEFTACountries.map(function(country) { return country.countryId}).join(',').split(",");   
            }   
        }
        else
        {
            
            const userRoleDetails : IUserRoleDetail[]= getLoggedinUserRoleDetails();
            resellerCountries = userRoleDetails.map(function(userRoleDetail) { return userRoleDetail.countryId}).join(',').split(",");
        }
        if(resellerCountries.filter(country => country !== "" && country !== null).length > 0){
            let response = await Promise.resolve(nextGenService.getAllActiveResellersByProductCountriesAndType(opportunityProducytype, resellerCountries));
            if (response.status === 200) {
                resellerAccounts.current = resellerAccounts.current.concat(response.data);
                resellerAccounts.current = _.uniqBy(resellerAccounts.current, 'accountId');
                setIsResellerLoading(false);
            }
        }
        else
        {
            setIsResellerLoading(false);
        }
        let resellerConfig = { ...viewResellerConfig };
        resellerConfig.kendoGridProps.data = resellerAccounts.current;
        resellerConfig.kendoGridProps.totalRecords = resellerAccounts.current.length;
        setviewResellerConfig({ ...viewResellerConfig });
    })();
    }, []);

    mdppAccessibilityToSetFocusToHeader();
    mdppAccessibilityToSetRoleToLoadingMessage();
    
    return (
        <>
            <h1 tabIndex={-1}> {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ViewReseller", UIControlsText.ViewReseller)}</h1>
            {isResellerLoading &&
                    <Stack {...defaultColumnProps}>
                        <div>
                            <Spinner label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LoadingReseller", UIControlsText.LoadingReseller)} />
                        </div>
                    </Stack>
                }
            <Stack horizontal tokens={stackTokens} styles={stackGridStyles}>

                <NextGenKendoGrid 
                    {...{
                        kendoGridProps: { ...viewResellerConfig.kendoGridProps }
                    }}
                />
            </Stack>
            <PrimaryButton
                title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}
                aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}
                text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}
                onClick={closeBTn}
            />
        </>

    )
}

function productTypes(productTypes: any) {
    throw new Error("Function not implemented.");
}
