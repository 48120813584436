import { IStyle, IIconStyles, IDatePickerStyles, mergeStyleSets, IButtonStyles, ILabelStyles, labelProperties, ITextFieldStyles, IStyleFunctionOrObject, ITextFieldStyleProps } from 'office-ui-fabric-react';
import { getScrollBarWidth } from '@cseo/controls';
import { IDropdownStyles, IBreadcrumbStyles, IStackProps } from '@fluentui/react';
import { IStackStyles } from "@fluentui/react";
import { mergeStyles } from 'office-ui-fabric-react';
import Background from '../images/Background_Surface_Portfolio_Lineup_Login.jpg';
import { navCollapsedWidth } from '@m365-admin/nav';
import zIndex from '@mui/material/styles/zIndex';

const scrollablePaneStyles = {
  position: "fixed",
  top: 40,
  bottom: 0,
  right: 0,
};

export const stackStyles: Partial<IStackStyles> = { root: { width: 550, paddingLeft: 8 }, };
export const viewStackStyles: Partial<IStackStyles> = { root: { width: 550, paddingLeft: 6 }, };
export const stackGridStyles: Partial<IStackStyles> = { root: { width: 1000 } };
export const stackGridStyleswithPadding: Partial<IStackStyles> = {
  root:
  {
    padding: "2px 4px 2px 4px",
    width: "1000",
  }
};

export const stackGridStyleswithBelowPadding: Partial<IStackStyles> = {
  root:
  {
    padding: "0px 0px 6px 0px",
  }
};

export const stackGridStyleswithBorder: Partial<IStackStyles> = {
  root: {
    border: "1.5px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
  }
};

export const stackGridStylesButton: Partial<IStackStyles> = {
  root:
  {
    padding: "8px 4px 4px 4px",
    width: "1000",
  }
};

export const stackGridStylesLabel: Partial<IStackStyles> = {
  root:
  {
    padding: "6px 4px 4px 4px",
    width: "1000",
  }
};

export const stackButtonStyles: Partial<IStackStyles> = {
  root: {
    width: 100,
    paddingRight: 16
  },
};

export const buttonStyles: Partial<IButtonStyles> = {
  root: {
    minWidth: 0,
    padding: '0 4px',
    alignSelf: 'stretch',
    height: 'auto',
    marginTop: 5
  },
};

export const ActionButtonStyle: Partial<IButtonStyles> = { 
  root : {
    backgroundColor: 'black', 
    width:'27px',
    height: '27px',
    borderRadius: '27px',
    display: 'inline-block'
  }
};

export const chooseColumnStyles = {
  checkboxStyles: {
    root: {
      margin: 8,
      marginLeft: 12
    }
  },
};

let scrollBarWidth = 0;
let currentZoomLevel = 0;

const calculcateScrollBarWidth = () => {
  [scrollBarWidth, currentZoomLevel] = getScrollBarWidth(scrollBarWidth, currentZoomLevel, window.devicePixelRatio);
  return scrollBarWidth;
}

export const _Styles = mergeStyleSets({
    scrollablePaneCollapsed: {
        ...scrollablePaneStyles,
        left: navCollapsedWidth + calculcateScrollBarWidth() + 10
    } as IStyle,
    scrollablePaneExpand: {
        ...scrollablePaneStyles,
        left: 228 + calculcateScrollBarWidth() + 10
    } as IStyle,
    rootDiv: {
        paddingRight: '30px',
        paddingLeft: '10px',
        paddingTop: '30px',
    } as IStyle,
    dividerLine: {
        width: '100%',
        height: '1px',
        backgroundColor: 'black',
        marginBottom: '20px'
    } as IStyle,
    rowGap: {
        height: '30px'
    } as IStyle,
    contentStyle: {
        textAlign: 'center',
        height: '100vh',
        backgroundImage: `url(${Background})`,
        backgroundPosition: 'center',
        backgroundSize:'cover',
        backgroundRepeat:'noRepeat',
        backgroundClip: 'border-box'
    } as IStyle,
    homePageStyle: {
        marginLeft:'-12px',
        textAlign: 'center',
        height: '92vh',
        backgroundPosition: 'center',
        backgroundSize:'cover',
        backgroundRepeat:'noRepeat',
        backgroundClip: 'border-box',
        backgroundColor: "#F5F5F5"
    } as IStyle,
    bannerNotificationStyle: {
        position: 'relative',
        right: '0px',
        left: '0px',
        width: '100 %',
        backgroundColor: '#FFF19D',
        border: '1px solid #DDDDDD',
        overflow: 'auto',
        padding: '5px',
        fontFamily: 'Segoe UI!important',
        fontSize: '12px',
        margin: '0px',
        color: '#000000',
        marginLeft: '20px',
        marginRight: '20px'
    } as IStyle,
    bannerNotificationCenter: {
        width: '90%',
        overflow: 'hidden'
    } as IStyle,
    bannerNotificationMargin: {
        paddingLeft: '20px',
        paddingRight: '20px'
    } as IStyle,
    bannerNotificationHdrLabel: {
        fontSize: '11pt',
        color: 'red',
        fontWeight: 'bold',
        marginLeft: '20px',
        marginRight: '20px'
    } as IStyle,
    bannerNotificationLabel: {
        fontSize: '10pt',
        color: 'red',
        marginLeft: '20px',
        marginRight: '20px'
    } as IStyle,
    bannerHeaderFont: {
        fontSize: '12pt',
    } as IStyle,
    DRStyleExpand: {
        marginLeft: '15em'
    } as IStyle,
    DRStyleCollapse: {
        marginLeft: '4em'
    } as IStyle,
    DeleteRow: {
        color: 'red'
    } as IStyle,
    fileAttachment: {
        marginTop: '2.5em'
    } as IStyle,
    legalEntityStyles: {
        margin: '27px',
    } as IStyle,
    legalTextStyles: {
        marginTop: '27px'
    } as IStyle,
    legalSearchTextStyles: {
        margin: '0px',
        width: '800px'
    } as IStyle,
    legalLinkStyles: {
        margin: '29px',
        width: '300px'
    } as IStyle,
    dropdown: {
        borderstyle: 'none !important'
    } as IStyle,

  AddButtonStyle: {
    margin: '5px 8px 0px 8px',
    padding: '2px'
  } as IStyle,
  DeleteButtonStyle: {
    color: 'red',
    border: 'none',
    width: '20px'
  } as IStyle,
  AddDealIconStyles: {
    border: 'none',
    backgroundColor: 'White',
    marginLeft: '-20px',
  } as IStyle,
  LegalEntityLabel: {
    padding: '5px 0px 10px 5px'
  } as IStyle,
  DealTextStyles: {
    width: '250px',
    margin: '10px'
  } as IStyle,
  DealLinkStyles: {
    marginTop: '36px'
  } as IStyle,
  DealLabels: {
    margin: '5px'
  } as IStyle,
  DealGrid: {
    margin: '10px',
    height: 'auto'
  } as IStyle,
  ChannelGrid: {
    margin: '10px',
    height: 'auto'
  } as IStyle,
  ChannelLabels: {
    margin: '7px'
  } as IStyle,
  ChanneltopRow: {
    paddingBottom: '20px'
  } as IStyle,
  ChannelMSCheckbox: {
    paddingTop: '20px'
  } as IStyle,
  GridStyle: {
    maxHeight: '750px',
    margin: '10px'
  } as IStyle,
  DealDetailsGrid: {
    height: '50px',
    width: '100%',
    paddingTop: '30px'
  } as IStyle,
  legalEntityStyles_Upload: {
    margin: '0px 0px 10px 10px',
  } as IStyle,
  DealDetailsGridDiv: {
    position: 'absolute',
    width: '100%'
  } as IStyle,
  Dealvolumntext: {
    width: '40px'
  } as IStyle,
  PrimarySelection: {
    paddingLeft: '5px',
    fontSize: '12px',
    fontWeight: 500,
  } as IStyle,
  SearchBarContainer: {
    display: "flex",
    justifyContent: "left",
  } as IStyle,
  DialogueCheckBox: {
    width: '525px'
  } as IStyle,

  DashboardButtons: {
    display: 'flex'
  } as IStyle,

  DashboardBtnText: {
    lineHeight: '20px',
    textAlign: 'center',
    fontSize: '14px'
  } as IStyle,

  DashboardIcons: {
    fontSize: '16px',
    paddingRight: '8px',
    color: '#0078D4'
  } as IStyle,

  CommandBarButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '6px 8px',
  } as IStyle,

  SpanStyle: {
    left: '10%',
    padding: '0px'
  } as IStyle,

  SplitterStyle: {
    height: '70vh'
  } as IStyle,

  PrimaryLegalEntityStyle: {
    marginBottom: '10px'
  } as IStyle,
  LegalEntitySearchGrid: {
    height: '35vh',
    overflow: 'auto',
    paddingTop: '5px'
  } as IStyle,
  DealDetailsSearchGrid: {
    height: '45vh',
    overflow: 'auto',
  } as IStyle,
  LegalEntityForm: {
    overflow: 'hidden'
  } as IStyle,
  FooterStyle: {
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: '#575352',
    color: 'white',
    height: '2.5em',
    position: 'fixed',
    textAlign: 'right',
    verticalAlign: 'middle'
  } as IStyle,
  AnchorSection: {
    color: 'white',
    textDecorationLine: 'unset',
    fontSize: '12px',
    padding: '5px',
  } as IStyle,
  LoginInnerPanel: {
    float: 'right',
    width: '620px',
    marginTop: '180px',
    marginRight: '80px',
    padding: '0',
    paddingLeft: '40px',
    paddingTop: '40px',
    paddingRight: '40px',
    paddingBottom: '30px',
    borderRadius: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  } as IStyle,
  LoginOuterPanel: {
    overflow: 'auto',
    textAlign: 'left',
    fontSize: '12px',
  } as IStyle,
  LoginInnerSign: {
    backgroundColor: 'white',
    width: '500px',
    height: '195px',
    marginBottom: '18px',
    boxShadow: '1px 2px 2px #888888',
    marginRight: '10px',
    borderRadius: '6px',
  } as IStyle,
  LoginInnerSignh2: {
    fontWeight: 'bold',
    lineHeight: '1.0em',
    fontSize: '1.0em',
    color: '#004FA3',
    padding: '15px 15px 0px 15px',
    font: "100 1.5em/1 'Segoe UI Light','Segoe UI',sans-serif",
  } as IStyle,
  LoginInnerSignh4: {
    margin: '15px 0 15px 0px',
    fontWeight: 'normal',
    lineHeight: '1.222em',
    fontSize: '.85em',
    color: 'black',
    padding: '0px 15px 0px 15px',
    font: '1.0em/1.2 Segoe UI,Sans-Serif',
  } as IStyle,
  LoginInnerPanelh1: {
    margin: '0px',
    padding: '0',
    font: "100 3.5em/1 'Segoe UI Light','Segoe UI',sans-serif",
    color: 'white',
    letterSpacing: '-0.025em',
  } as IStyle,
  LoginInnerPanelh2: {
    margin: '15px 0 15px 0px',
    padding: '0',
    fontWeight: 'normal',
    lineHeight: '1.333em',
    fontSize: '1.2em',
    color: 'wheat',
    font: "100 2.6em/1 'Segoe UI Light','Segoe UI',sans-serif",
  } as IStyle,
  LoginTextStyle: {
    marginBottom: '20px',
  } as IStyle,
  SkipTextBoxStyle: {
    width: '615px',
  } as IStyle,
  ViewResellerTextStyle: {
    marginTop: '24px',
    marginBottom: '16px',
  } as IStyle,
  emailErrorStyle: {
    color: 'red'
  } as IStyle,
  customLabel: {
    padding: '2px',
    width: '100%',
    fontWeight: 'normal',
  } as IStyle,
  customLabelHeading: {
    padding: '2px',
    width: '100%',
    fontWeight: 'bold',
  } as IStyle,
  PaddingCustomer: {
    padding: '24px',
  } as IStyle,
  FontIconDisabled: {
    fontSize: '16px',
    paddingRight: '8px',
    color: '#d3d3d3',
    opacity: 0.9,
    cursor: "not-allowed"
  }as IStyle,
});

export const DefaultPageStyles = {
  defaultDropdownStyles: {
    dropdown: { width: 300 },
  },
  customDropdownStyles: {
    dropdown: { width: 250 },
  },
  threeColumnWidthStyles: {
    root: { width: 900 }
  },
  oneColumnWidthStyles: {
    root: { width: 300 }
  },
  customWidthForStackLabels: {
    root: { width: 237 }
  },
  customWidthStyles: {
    root: { width: 200 }
  },
  customWidthStyleAuto: {
    root: { width: "100%" }
  },
  iconStyleColor: {
    root: { color: "white" , marginLeft:'2px'}
  },
  redColor: {
    root: { color: "red" },
  },
  blackColor: {
    root: { color: "black" },
  },
  grayColor: {
    root: { color: "gray" },
  },
  oneColumnMinWidthStyles: {
    root: { minWidth: 300 }
  },
  noColumnMinWidthStyles: {
    root: { minWidth: 0 }
  },
  customWidthForDealTeamLabels: {
    root: { minWidth: 270 }
  },
  customWidth190Styles: {
    root: { minWidth: 190 }
  },
  customWidth175Styles: {
    root: { minWidth: 175 }
  },
  customWidth125Styles: {
    root: { minWidth: 125 }
  },
  customWidth70Styles: {
    root: { minWidth: 70 }
  },
  customWidth40Styles: {
    root: { minWidth: 40 }
  },
  paddingTop3px: {
    paddingTop: "3px"
  },
  paddingTopLeft: {
    paddingTop: "3px",
    paddingLeft: "8px"
  },
  paddingLeftOnly: {
    paddingLeft: "20px"
  },
  iconBlueColor: {
    color: 'darkblue',
    cursor: "pointer"
  },
  attachmentGridHeight: {
    maxHeight: "120px"
  },
  paddingRight10: {
    paddingRight: "10px"
  },
  attachementFileNameWidth: {
    width: 170,
    moreWidth: 250
  },
  attachementFileSizeWidth: {
    width: 150,
    moreWidth: 170
  },
  marginRight: {
    marginRight: 5
    },
    paddingTop8: {
        paddingTop: 8
    },
    paddingBottom16: {
        paddingBottom: 16
    },
    padding8: {
        padding: 8
    },
    ProductSearchWidthStyles: {
        root: {
            width: 100,
        }
    },
  BGcustomWidthStyles: {
    root: { width: 150 }
  },
  UserPromptBackground:{
    root:{
      backgroundColor: 'rgb(255, 244, 206)'
    }
  },
  DisplayNone: {
    root: { display: 'none' }
  }
}

export const DeleteButtonIconStyles = {
  root: {
    color: DefaultPageStyles.redColor.root.color,
    height: 20,
    lineHeight: 0
  }
} as IIconStyles

export const DatePickerStyles = {
  icon: { lineHeight: 10 },
  root: DefaultPageStyles.oneColumnWidthStyles.root
} as IDatePickerStyles;

export const BGDatePickerStyles = {
  icon: { lineHeight: 16 },
  root : DefaultPageStyles.BGcustomWidthStyles.root
} as IDatePickerStyles;

export const defaultColumnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: DefaultPageStyles.threeColumnWidthStyles,
};

export const DealContactStyles = {
  DeleteButton: {
    height: '27px',
  },
  CoOwnerCheckBox: {
    paddingTop: "5px",
    width: "50px"
  },
}

export const CommonStyles = {
  blackBorder: {
    border: "1px solid #000"
  },
  entityLookupWidth: {
    width: "200px"
  },
  DeleteIconMargin: {
    marginLeft: 232,
  }
}

export const EntityLookupStyles = {
  textFieldStyles: {
    root: {
      maxWidth: "300px",
      display: 'block',
      marginBottom: '20px',
      height: '40px',
      margin: 0
    },
  },
  TextFieldStyle: mergeStyles({
    display: 'block',
    marginBottom: '20px',
    height: '40px'
  }),
  columnContentStyles: mergeStyles({
    color: 'black !important',
    padding: 0
  }),
}

export const reactSelectDropDrownStyles = {
  control: (styles?: any) => ({
    ...styles,
    width: "250px",
    borderColor: "black",
    minHeight: "25px",
    height: "29px",
    borderRadius: "0px",
  }),
  option: (styles: any, { isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "blue" : isFocused ? "#ebebeb" : undefined,
    };
  },
  valueContainer: (styles?: any) => ({
    ...styles,
    margin: "0 0 29px 3px",
    padding: "0px",
    fontSize: "12px",
    fontWeight: "400",
    width: 250,
    height: 29,
    overflow: "hidden",
  }),
  multiValue: (styles?: any) => ({
    ...styles,
    display: "inline-flex",
  }),
  indicatorsContainer: () => ({
    margin: "0 0 24px 3px",
    display: "inline-flex",
  }),
};


export const AddCasToDashboardStyles: Partial<ITextFieldStyles> = {
  root: {
    label: {
      fontSize: "14px",
      fontWeight: "600",
    },
  },
};

export const selectStyles = {
  control: (base: any) => ({
    ...base,
    fontFamily: "Segoe UI",
    fontSize: "12px",
    width: "200px",
    textAlign: "left",
    menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
    zIndex: 9999
  }),
  menu: (base: any) => ({
    ...base,
    fontFamily: "Segoe UI",
    fontSize: "12px",
    textAlign: "left",
    zIndex: 9999
  }),
};

export const AddCaseOverlayStyles = {
  clearButton: {
    left: '425px'
  },
  searchButton: {
    left: '450px'
  },
  textSpacingStyle: {
    display: "flex"
  },
  texfieldfontStyle: {
    fontSize: "14px",
    width: '298px'

  },
  radioButtonStyle: {
    width: "20px",
    height: "16px"
  },
  proofOfResellerFont: {
    root: {
      fontSize: "11px",
      fontWeight: 'inherit !important'
    }
  } as ILabelStyles,
  labelStyle: {
    fontSize: "14px",
    fontWeight: 600,
  },
  screen2SearchButton: {
    left: '545px'
  },
};

export const ChoiceGroupStyles = {
  ChoiceGroupHorizontal: {
    flexContainer: { display: "flex", alignItems: 'center', gap: '15px' },
  },
}

export const ButtonStyles = {
  borderRadius: '3px',
  backgroundColor: 'rgba(0,120,212,1)',
  margin: '5px 8px 0px 8px',
  padding: '3px',
  width: '210px',
  fontSize: '.10em'
}


export const HeaderStrip = {
  shadow: {
    '-webkit-box-shadow': '0 8px 6px -6px black',
    '-moz-box-shadow': '0 8px 6px -6px black',
    'box-shadow': '0 8px 6px -6px black',
  }
}

export const BreadCrumbStyles: IBreadcrumbStyles = {
  itemLink: {
    fontFamily: 'Segoe UI',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#605E5C',
    marginTop: "0px"
  },
  root: undefined,
  list: undefined,
  listItem: undefined,
  chevron: undefined,
  overflow: undefined,
  overflowButton: undefined,
  item:{
    fontSize : '14px',
    lineHeight :'20px',
  }
}

export const HelpAndSupportStyles = {
  paddingStyle: {
    padding: '30px',
  },
  contentStyle: {
    fontSize: "24px",
    marginRight: "25px",
    fontWeight: 400,
  },
  contentStyleBottomLine: {
    fontSize: "24px",
    marginRight: "25px",
    'list-style-type': 'none',
    marginBottom:"30px"
  },
  contentStyleBottomLineBlueText: {
    fontSize: "24px",
    marginRight: "25px",
    'list-style-type': 'none',
    marginBottom:"30px",
    color: "#0000FF"
  },
  contentStyleBlueText: {
    fontSize: "24px",
    marginRight: "25px",
    'list-style-type': 'none',
    color: "#0000FF",
    display: "flex",
    flexDirection: "row" as "row"
  },
  HelpAndSupportHeaderStyles: {
    fontSize: "40px",
    marginRight: "25px",
    color: "#274b47",
    fontWeight: 600,
    marginBottom:"60px"
  },
  marginLeft: {
    marginLeft: "30px"
  }

}

export const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 'auto' },
  dropdownOptionText: {
    fontFamily: 'Segoe UI',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '32px',
    border: 'none',
    color: '#201F1E'
  },
  caretDown: {
    fontSize: "16px",
    verticalAlign: "middle",
  },
  callout: {
    minWidth: "300px",
  },
  title: {
    width :"auto",
    border: 'none',
    fontFamily: 'Segoe UI',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '32px',
    color: '#201F1E'
  }
};

export const PaperStyle = {
  root: {
    padding: 10,
    margin: 50
  }
}
export const PaperStyle2 = {
  paddingTop: 1000,
    padding: 500,
    margin: 10,
    height:20,
}

export const ActionButtonStyles: IButtonStyles = {
  root: {
    backgroundColor: 'inherit',
  },
  rootDisabled: {
    backgroundColor: 'inherit',
  },
  label: {
    fontSize : '14px',
    fontWeight: 400
  },
  labelDisabled: {
    fontWeight: 400
  },
  icon :{
    margin : '0 2px',
  }
};

export const HoverCardStyles = mergeStyleSets({

  hoverText: {
    fontWeight: '400',
    display: 'inline-block',
    padding: 5,
    borderRadius: 2,
  },
});

export const PartnerSearchStyles = mergeStyleSets({
  locationIconClass: {
    fontSize: 25,
    height: 18,
    width: 15,
    marginRight: 10,
    marginTop: 10,
    marginLeft: 10,
    color: "blue"
  },
  partnerRoleIconClass: {
    fontSize: 20,
    height: 18,
    width: 15,
    marginRight: 10,
    marginTop: 10,
    marginLeft: 10,
    color: "blue"
  },
  productGroupIconClass: {
    fontSize: 25,
    height: 18,
    width: 15,
    marginRight: 20,
    marginTop: 10,
    color: "blue"
  }
})

export const MarginStyles = {
  marginTop_10: {
    marginTop: '10px'
  },
  marginTop_15: {
    marginTop: '15px'
  },
  marginTop_16: {
    marginTop: '16px'
  }
}

export const expireDialog = {
  fontWeight: 400,
  fontSize: '14px'
}

export const extendDialog = {
  fontWeight: 400
}

export const textFieldStylesOneColumn = {
  root: { width: 300 },
}

export const textFieldStylesTwoColumn = {
  root: { width: 250 },
}

export const extendDialogButtonStyles = {
  root: {
    margin: 8
  }
}

export const EnabledToolbarIcons = {
  color: '#0078D4'
}

export const pageTitleStyles = {
  fontSize: "20px",
  verticalAlign: "bottom",
  dashboardTitle: {
    paddingTop: "16px",
    width:"100%",
  },
};

export const separatorStyles = {
  root: {
    fontSize: "0px",
    padding: "8px 0 4px 0",
    width: "100%",
  }
}


export const ModalDialogStyles = {
  main:
  {
    maxWidth: 750,
  }
}


export const DashboardHeaderStyle = {
  fontSize : "24px",
  lineHeight : "32px",
  color : "#201F1E",
  fontWeight : "700",
  margin : "0 4px 0 0",
  
};

export const showDiscountButtonStyles = {
  root: {
      marginTop : "28px",
      marginBottom:"8px"
  }
}

export const BreadcrumbLinks = {
  
}

export const RefreshButtonStyles : IButtonStyles = {
  root : {
    marginLeft : "4px"
  },
  icon :{
    transform: "rotate(90deg)"
  }
};
