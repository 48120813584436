import React, { useState, useEffect, useRef } from "react";
import { useBoolean } from '@fluentui/react-hooks';
import { IActionMenuProps } from "./IActionMenuProps";
import {
    CommandBarButton, DefaultButton, IStackTokens, PrimaryButton, Stack, MessageBar,
    MessageBarType, DatePicker, defaultDatePickerStrings, IStackProps, TextField, Dropdown, IDropdownOption
} from "@fluentui/react";
import {
    ActionButtonStyles, _Styles, stackStyles, stackGridStyles, expireDialog,
    stackButtonStyles, extendDialogButtonStyles, extendDialog, DatePickerStyles, textFieldStylesOneColumn, PaperStyle
} from '../../../content/styles/Page.styles';
import { CssClassConstants } from "../../../content/CssClassConstants";
import { UIControlsText } from "../../../content/UIControlsText";
import { SessionStorageConsts } from "../../../models/SessionStorageConsts";
import { PageMode } from "../../../models/PageMode";
import { PageSubMode } from "../../../models/PageSubMode";
import { JavaScriptExtensions } from "../../../infrastructure/JavaScriptExtensions";
import { IActionButtonConfig } from "./IActionButtonConfig";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import NextGenKendoGrid from "../grid/nextgenkendogrid";
import { downloadDatabyInputFormat, getLoggedinUserRoleDetails, getUserRole, readGridConfig, getUserDetails, getAllDealTypes, getAllDealQuantityThresholds, getStatusNameByCode, getAllDealDurationConfigs, getDealDurationConfig, getDealVolumes, isNullOrEmpty, getUserSession, getDistinctUserRole, IsInternalPricingProgram, IsExternalPricingProgram } from "../common/util";
import { IGridProps } from "../grid/IGridProps";
import { GridType } from "../../../models/GridType";
import { IUserRoleDetail } from "../../../models/IUserRoleDetail";
import { nextGenService } from "../../../services/NextGenService";
import { StateCode } from "../../../models/StateCode";
import { OpportunityDealStatusCodes } from "../../../models/OpportunityDealStatusCodes";
import { DealType } from "../../../models/DealType";
import { PartnerType } from "../../../models/PartnerType";
import { Label, Panel, Spinner } from "office-ui-fabric-react";
import { CaseActions } from "./case-actions";
import { ErrorMessages } from "../../../content/ErrorMessages";
import { IDealExtensionRequest } from "../../../models/IDealExtensionRequest";
import { OpportunityDealStatusNames } from "../../../models/OpportunityDealStatusNames";
import { IDealCopyRequest } from "../../../models/IDealCopyRequest";
import { IDealActionRequest } from "../../../models/IDealActionRequest";
import { ApplicationConstants } from "../../../models/ApplicationConstants";
import { ExtensionStatus } from "../../../models/DealExtensionStatusNames";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { Card } from "@cseo/controls";
import { CardBody } from "@fluentui/react-northstar";
import Row from "react-bootstrap/esm/Row";
import Paper from "@mui/material/Paper";
import { getRuleValidationMessage, getEntityDataFromSession } from "../common/util";
import { MessageTypeText } from "../../../models/MessageTypeText";
import { IDealRevertRequest } from "../../../models/IDealRevertRequest";
import { CaseDecisionLetterTypeOptions } from "../../../services/StaticData";
import { CaseDecisionLetterType } from "../../../models/CaseDecisionLetterType";
import { useCommonPPContext } from '../../../pages/pricingprogram/common/context/common-pp-context';
import { useToShowActionButtonTextOnHoverOrFocusInReflow } from "../../../hooks/accessiblity-hooks";

const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

export const ActionMenu: React.FunctionComponent<IActionMenuProps> = (configData: IActionMenuProps) => {
    const { actionConfig, selectedData } = configData;
    const config = { enableEdit: false, enableExpire: false, enableExtend: false, enableRenew: false, enableCopy: false, enableDelete: false, enableCPStrategicLetters: false, showCPStrategicLetters: true, showCPCaseDecisionLetter: true, enableCPCaseDecisionLetter: false, enableRevert: false };
    const [actionsConfig, setActionsConfig] = React.useState<IActionButtonConfig | undefined>(config);
    const [cpStrategicLettersConfig, setcpStrategicLettersConfig] = React.useState<IGridProps>(readGridConfig(GridType.CPStrategicLetters));

    const stackButtonTokens: IStackTokens = { childrenGap: 16 };
    const stackTokens: IStackTokens = { childrenGap: 24 };
    const columnProps: Partial<IStackProps> = {
        tokens: { childrenGap: 15 },
        styles: { root: { width: "auto", paddingRight: 20, paddingBottom: 20 } },
    };

    const [extensionReason, setExtensionReason] = useState<string>("");
    const [expectedUnits, setExpectedUnits] = useState<number>(0);

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [dialogConfig, setDialogConfig] = useState<any>({});
    const [selectedDeal, setSelectedDeal] = useState<any>();
    const [selectedAction, setSelectedAction] = useState<CaseActions>();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [readonly, setReadonly] = useState<boolean | undefined>(false);
    const [maxExpirationDate, setMaxExpirationDate] = useState<Date>(new Date());
    const [showExtensionWarning, setShowExtensionWarning] = useState<boolean>(false);
    const [showMaxExtensionWarning, setShowMaxExtensionWarning] = useState<boolean>(false);
    const [showExtensionFailure, setShowExtensionFailure] = useState<boolean>(false);
    const [showExtensionLimit, setShowExtensionLimit] = useState<boolean>(false);
    const [minUnitsForAutoApproval, setMinUnitsForAutoApproval] = useState<number>(0);
    const [showExtensionSuccess, setShowExtensionSuccess] = useState<boolean>(false);
    const [showExtensionReview, setShowExtensionReview] = useState<boolean>(false);
    const [disableConfirmBtn, setDisableConfirmBtn] = useState<boolean>(false);
    const [triggerExtend, setTriggerExtend] = useState<boolean>(false);
    const [showBackButton, setShowBackButton] = useState<boolean>(false);
    const [showMessageBanner, setShowMessageBanner] = useState<boolean>(false);
    const [extensionInProgress, setExtensionInProgress] = useState<boolean>(false);
    const [copyInProgress, setCopyInProgress] = useState<boolean>(false);
    const [renewInProgress, setRenewInProgress] = useState<boolean>(false);
    const [isDecisionLetterOpen, setIsDecisionLetterOpen] = useState<boolean>(false);
    const [isDecisionLetterSelectedWithSKU, setIsDecisionLetterSelectedWithSKU] = useState<boolean>(true);
    const [isLetterBeingDownloaded, setIsLetterBeingDownloaded] = useState<boolean>(false);

    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [manufacturersLetterButtonText, SetManufacturersLetterButtonText] = useState<string>(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CPStrategicLetters", UIControlsText.CPStrategicLetters))
    const [decisionLetterButtonText, SetDecisionLetterButtonText] = useState<string>(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CPCaseDecisionLetter", UIControlsText.CPCaseDecisionLetter))
    const [userRoles] = useState<string>(getUserRole());


    let userDetails = getUserDetails()

    useEffect(() => {
        setShowExtensionLimit(false)
        setShowMaxExtensionWarning(false)

        if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(configData.actionConfig)) {
            setActionsConfig(Object.assign(config, configData.actionConfig));
        }

        if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(configData.selectedData)) {
            setSelectedDeal(selectedData)
        }
    }, [configData.actionConfig])

    useEffect(() => {
        if (triggerExtend) {
            submitExtend()
        }
    }, [triggerExtend])

    //redirect to case submission edit mode.
    const onEditCase = () => {
        sessionStorage.setItem(SessionStorageConsts.pageMode, PageMode.Edit);
        sessionStorage.removeItem(SessionStorageConsts.pageSubMode);

        if (selectedData.dealType.name) {
            if (selectedData.dealType.name === ApplicationConstants.DealRegistration || selectedData.dealType.name === ApplicationConstants.SpecialPricing) {
                window.location.href = "/managecase/pricingprogram?oppNumber=" + selectedData.opportunityName + "&oppDealNumber=" + selectedData.name
            } else if (selectedData.dealType.name === ApplicationConstants.CPStrategic) {
                window.location.href = "/managecase/cpstrategic?oppNumber=" + selectedData.opportunityName
            } else {
                window.location.href = "/managecase/otle?oppNumber=" + selectedData.opportunityName
            }
        }
    };

    const onCPStrategicLetters = async (action: CaseActions) => {
        setSelectedAction(action);
        SetManufacturersLetterButtonText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LoadingMCL", UIControlsText.LoadingMCL));
        let newActionConfig = { ...actionConfig };
        newActionConfig.enableCPStrategicLetters = false;
        setActionsConfig(newActionConfig);
        if (selectedData.dealType?.name.toLowerCase() === DealType.CPStrategic.toLowerCase()) {
            var userRole = getUserRole();
            var userSubRole = userRole.split(",");
            let response = await nextGenService.getCPStrategicLettersByOpportunityDealId(selectedData.id, userSubRole[0])
            if (response.data.length !== 0) {
                let cpStrategicConfig = { ...cpStrategicLettersConfig };
                cpStrategicConfig.kendoGridProps.data = response.data;
                cpStrategicConfig.kendoGridProps.totalRecords = response.data.length;
                setcpStrategicLettersConfig({ ...cpStrategicConfig });
            }

            setIsDialogOpen(true);
        }

        SetManufacturersLetterButtonText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CPStrategicLetters", UIControlsText.CPStrategicLetters));
        newActionConfig.enableCPStrategicLetters = true;
        setActionsConfig(newActionConfig);
    }; 

    /**
    * DecisionLetterType Options change Callback function.
    * @method
    * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
    * @param {IDropdownOption} item Clicked Option from Dropdown.
    */
    const onDecisionLetterOptionChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item && item.key == CaseDecisionLetterType.WithoutSKU) {
            setIsDecisionLetterSelectedWithSKU(false);
        }
        else {
            setIsDecisionLetterSelectedWithSKU(true);
        }
    }

    const onCaseDecisionLetter = async (action: CaseActions) => {
        if (selectedData?.dealType?.name == UIControlsText.DealRegistrationText) {
            setIsDecisionLetterOpen(true);
            openDialog(action);
        }
        else {
            getCaseDecisionLetter(action)
        }
    };

    const getCaseDecisionLetter = async (action: CaseActions) => {
        var initialFilter: CompositeFilterDescriptor | undefined
        setSelectedAction(action)
        SetDecisionLetterButtonText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Downloading", UIControlsText.Downloading));
        let newActionConfig = { ...actionConfig };
        newActionConfig.enableCPCaseDecisionLetter = false;
        setActionsConfig(newActionConfig);
        try {
            const userSession = getUserSession();
            let loggedInUserInfo = JSON.parse(userSession);
            let roles = userRoles.split(",");
            var filters: { field: any; operator: string; value: any; }[] = []
            filters.push(
                {
                    "field": "name",
                    "operator": "eq",
                    "value": selectedData.name
                },
            )
            initialFilter = {
                logic: "and", filters: [
                    {
                        "logic": "and",
                        "filters": filters
                    }
                ]
            };
            const userRoleDetails: IUserRoleDetail[] = getLoggedinUserRoleDetails();
            const dashboardRequest = {
                accountsIds: userRoleDetails.map(function (userRoleDetail: IUserRoleDetail) { return userRoleDetail.accountId }).join(','),
                userRoles: roles.join(","),
                userRoleDetails: userRoleDetails,
                dealType: selectedData.dealType?.name,
                email: loggedInUserInfo.email,
                isLocalEmpowerment : selectedData.isLocalEmpowerment,
                tpiDs: [],
                filter: JSON.stringify(initialFilter),
                pageNumber: 1,
                recordsToFetch: ApplicationConstants.recordsToFetch,
            };
            let response = await nextGenService.getCaseLetters(selectedData.id, userRoleDetails, selectedData.dealType?.name, dashboardRequest, isDecisionLetterSelectedWithSKU)        
            var FileName = ""; 
            var dealType = selectedData.dealType.name?selectedData.dealType.name.toLowerCase():"";
            switch(dealType)
            {
                case DealType.DealRegistration.toLowerCase():
                case DealType.SpecialPricing.toLowerCase():                 
                    if(!selectedData.resellerName)
                    {       
                        var opportunityDetails = await Promise.resolve(nextGenService.getOpportunityCommon(selectedData.opportunityName, userRoleDetails, selectedData.name));
                        selectedData.resellerName = opportunityDetails?.data?.opportunityAccounts?.filter(pt=>pt.partnerType == 2 || pt.partnerType==3)[0]?.accountCompanyName;
                    }
                    FileName = JavaScriptExtensions.stringFormat(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DRSPCaseDecisionLetterFileName", UIControlsText.DRSPCaseDecisionLetterFileName),selectedData.opportunityName,selectedData.resellerName? "-".concat(selectedData.resellerName):"",selectedData.customerName?"-".concat(selectedData.customerName):"");
                    break;
                default:
                    FileName = JavaScriptExtensions.stringFormat(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseDecisionLetterFileName", UIControlsText.CaseDecisionLetterFileName),selectedData.opportunityName);
                    break; 
                }  
             downloadDatabyInputFormat(FileName.replace(/[\/:*?"<>|]/g,''),response);

        } catch (error) {
            SetDecisionLetterButtonText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CPCaseDecisionLetter", UIControlsText.CPCaseDecisionLetter));
            newActionConfig = { ...actionConfig };
            newActionConfig.enableCPCaseDecisionLetter = true;
            setActionsConfig(newActionConfig);
        }
        SetDecisionLetterButtonText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CPCaseDecisionLetter", UIControlsText.CPCaseDecisionLetter));
        newActionConfig = { ...actionConfig };
        newActionConfig.enableCPCaseDecisionLetter = true;
        setActionsConfig(newActionConfig);
    };

    const ExtendMessage = (p: IExampleProps) => (
        <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={true}
            onDismiss={p.close}
            dismissButtonAriaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}> {getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ExtendWarningMessage", UIControlsText.Extend.WarningMessage)}
        </MessageBar>
    );

    const SuccessMessage = (p: IExampleProps) => (
        <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={true}
            onDismiss={p.close}
            dismissButtonAriaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}> {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendExtensionSuccessMessage", UIControlsText.Extend.ExtensionSuccessMessage)}
        </MessageBar>
    );
    const ReviewMessage = (p: IExampleProps) => (
        <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={true}
            onDismiss={p.close}
            dismissButtonAriaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}> {getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ExtendExtensionReviewMessage", UIControlsText.Extend.ExtensionReviewMessage)}
        </MessageBar>
    );

    const ExtendLimitMessage = (p: IExampleProps) => (
        <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={true}
            onDismiss={p.close}
            dismissButtonAriaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}> {getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ExtendMaxExtensionLimitMessage", UIControlsText.Extend.MaxExtensionLimitMessage)}
        </MessageBar>
    );

    const MaxExtendMessage = (p: IExampleProps) => (
        <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={true}
            onDismiss={p.close}
            dismissButtonAriaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}> {getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ExtendMaxExtensionMessage", UIControlsText.Extend.MaxExtensionMessage)}
        </MessageBar>
    );

    const ExtendFailureMessage = (p: IExampleProps) => (
        <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={true}
            onDismiss={p.close}
            dismissButtonAriaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}> {getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ExtendExtensionFailureMessage", UIControlsText.Extend.ExtensionFailureMessage)}
        </MessageBar>
    );

    interface IExampleProps {
        close?: () => void;
    }

    const onRenderFooterContent = React.useCallback(
        () => (
            <div>
                <PrimaryButton
                    text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendConfirmButton", UIControlsText.Extend.ConfirmButton)}
                    ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendConfirmButton", UIControlsText.Extend.ConfirmButton)}
                    onClick={onExtend}
                    styles={extendDialogButtonStyles}
                    disabled={disableConfirmBtn}
                />
                <DefaultButton
                    text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendCancelButton", UIControlsText.Extend.CancelButton)}
                    ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendCancelButton", UIControlsText.Extend.CancelButton)}
                    onClick={onDismiss}
                    styles={extendDialogButtonStyles}
                />
            </div>
        ),
        [dismissPanel],
    );

    const onRenderFooterContentBack = React.useCallback(
        () => (
            <div>
                <PrimaryButton
                    text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BackButtonText", UIControlsText.BackButtonText)}
                    ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BackButtonText", UIControlsText.BackButtonText)}
                    onClick={onDismiss}
                    styles={extendDialogButtonStyles}
                />
            </div>
        ),
        [dismissPanel],
    );

    async function openExtendDialog() {
        if (IsInternalPricingProgram(selectedDeal?.dealType?.name)) {
            return
        }
        
        setShowExtensionFailure(false)
        setShowExtensionSuccess(false)
        setShowExtensionWarning(false)
        setShowExtensionReview(false)
        setShowMessageBanner(false)
        setShowBackButton(false)
        setExtensionReason('')
        setExpectedUnits(0)

        setReadonly(false)
        sessionStorage.setItem(SessionStorageConsts.selectedDeal, JSON.stringify(selectedDeal))

        let dealVolumes = getDealVolumes(selectedDeal?.dealType?.name, selectedDeal?.productType, selectedDeal?.dealVertical, selectedDeal?.customerCountry)
        if (!isNullOrEmpty(dealVolumes) && selectedDeal?.dealType?.name == DealType.SpecialPricing) {
            let committedUnits = selectedDeal?.dealVolume || 0
            let autoApprovalUnits = Math.round((dealVolumes?.extensionMinimumUnitsTransacted || 0) * committedUnits / 100)
            setMinUnitsForAutoApproval(autoApprovalUnits)
        }

        let extensionConfig = await nextGenService.getDealExtensionConfig(selectedDeal?.id)
        if (extensionConfig.status === 200) {
            if (!extensionConfig.data.isExtensionAllowed) {
                setShowExtensionLimit(true)
                return
            }
        }

        let caseStartDate = selectedDeal?.caseStartDate
        let dealDurations = getDealDurationConfig(selectedDeal?.dealType?.name, selectedDeal?.customerCountry)
        if (isNullOrEmpty(dealDurations)) {
            return
        }

        let maxCaseDuration = dealDurations?.maximumDurationInMonths || 0;

        let newExpirationDate = new Date(caseStartDate)
        newExpirationDate.setMonth(caseStartDate.getMonth() + maxCaseDuration);

        if (selectedDeal?.expirationDate.getTime() === newExpirationDate.getTime()) {
            setShowMaxExtensionWarning(true)
            return
        }

        setMaxExpirationDate(newExpirationDate)
        setSelectedDate(newExpirationDate)
        // clear the calendar control prior to opening the panel to avoid overlapping
        document.querySelector("#fluent-default-layer-host")?.remove();
        openPanel()
    }

    // workaround for react issue with async functions and state variables when using panels
    const onExtend = () => {
        setTriggerExtend(true)
    }

    const onDismiss = () => {
        setTriggerExtend(false)
        dismissPanel()
    }

    const submitExtend = async () => {
        try {
            setExtensionInProgress(true)
            setReadonly(true)
            let deal = selectedDeal || JSON.parse(sessionStorage.getItem(SessionStorageConsts.selectedDeal) || "")

            let dealVolumes = getDealVolumes(deal?.dealType?.name, deal?.productType, deal?.dealVertical, deal?.customerCountry)
            if (isNullOrEmpty(dealVolumes)) {
                return
            }

            if (deal?.dealType?.name == DealType.DealRegistration) {
                if (deal?.unitsTransactedHardware > (dealVolumes?.extensionMinimumUnitsTransacted || 0)) {
                    const extensionRequest: IDealExtensionRequest = {
                        autoExtend: true,
                        priorExpirationDate: deal?.expirationDate,
                        newExpirationDate: selectedDate,
                        extensionDate: new Date(),
                        opportunityDealId: deal?.id,
                        submitterContact: {
                            email: userDetails?.email,
                            firstName: userDetails?.firstName,
                            lastName: userDetails?.lastName
                        }
                    };

                    let response = await nextGenService.extendOpportunityDeal(extensionRequest)
                    if (response.status === 200) {
                        setShowBackButton(true)
                        setShowMessageBanner(true)
                        setShowExtensionSuccess(true)
                    }
                } else {
                    setShowBackButton(true)
                    setShowMessageBanner(true)
                    setShowExtensionFailure(true)
                }
            }

            if (deal?.dealType?.name == DealType.SpecialPricing) {
                let extensionRequest: IDealExtensionRequest = {
                    expectedUnits: expectedUnits,
                    priorExpirationDate: deal?.expirationDate,
                    newExpirationDate: selectedDate,
                    extensionDate: new Date(),
                    extensionReason: extensionReason,
                    opportunityDealId: deal.id,
                    submitterContact: {
                        email: userDetails?.email,
                        firstName: userDetails?.firstName,
                        lastName: userDetails?.lastName
                    }
                };

                let transactedUnits = deal?.unitsTransactedHardware || 0
                let committedUnits = deal?.dealVolume || 0
                if (transactedUnits >= Math.round((dealVolumes?.extensionMinimumUnitsTransacted || 0) * committedUnits / 100)) {
                    extensionRequest.autoExtend = true;
                } else {
                    extensionRequest.autoExtend = false;
                }

                let response = await nextGenService.extendOpportunityDeal(extensionRequest)
                if (response.status === 200) {
                    setShowBackButton(true)
                    setShowMessageBanner(true)
                    extensionRequest.autoExtend ? setShowExtensionSuccess(true) : setShowExtensionReview(true)
                }
            }

            setExtensionInProgress(false)
            sessionStorage.removeItem(SessionStorageConsts.selectedDeal)
        } catch (error) {
            setExtensionInProgress(false);
        }
    }

    /**
     * Extension reason change callback function.
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event. 
     * @param {string} newValue updated value from textField. 
     */
    const onExtensionReasonChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        setExtensionReason(newValue as string);
    }

    /**
     * Expected units change callback function
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event. 
     * @param {string} newValue updated value from textField.
     */
    const onExpectedUnitsChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        setExpectedUnits(newValue ? parseInt(newValue) : 0);
    }

    const validateExpirationDate = (date?: Date | null): void => {
        setShowExtensionWarning(false)
        if (date == undefined || maxExpirationDate == undefined) {
            setSelectedDate(new Date())
            return
        }

        if (date > maxExpirationDate) {
            setShowExtensionWarning(true)
            setDisableConfirmBtn(true)
        } else {
            setSelectedDate(date)
            setDisableConfirmBtn(false)
        }
    }

    const onCopy = async () => {
        try {
            setCopyInProgress(true)

            const userRoleDetails: IUserRoleDetail[] = getLoggedinUserRoleDetails();
            let countries = "";
            let accounts = "";
            countries = userRoleDetails.map(function (userRoleDetail) { return userRoleDetail.countryId }).join(',');
            accounts = userRoleDetails.map(function (userRoleDetail) { return userRoleDetail.accountId }).join(',');
            let userRole = getDistinctUserRole(userRoleDetails);
            sessionStorage.setItem(SessionStorageConsts.userRole, userRole.toString());

            const copyRequest: IDealCopyRequest = {
                opportunityNumber: selectedDeal?.opportunityName,
                opportunityDealNumber: selectedDeal?.name,
                submitterContact: {
                    email: userDetails?.email,
                    firstName: userDetails?.firstName,
                    lastName: userDetails?.lastName
                },
                userRoleDetails: userRoleDetails
            };

            copyRequest.countries = countries;
            copyRequest.accounts = accounts;
            copyRequest.userRole = userRole;

            var response = await nextGenService.copyOpportunity(copyRequest);
            setCopyInProgress(false)

            if (response.status === 200 && response.data) {
                sessionStorage.setItem(SessionStorageConsts.pageMode, PageMode.Edit);
                sessionStorage.setItem(SessionStorageConsts.pageSubMode, PageSubMode.Copy);

                window.location.href = '/managecase/pricingprogram?oppNumber=' + response.data
            }
        } catch (error) {
            setCopyInProgress(false)
        }

    };

    const onRenew = async () => {
        try {
            setRenewInProgress(true)

            const renewRequest: IDealActionRequest = {
                opportunityNumber: selectedDeal?.opportunityName,
                opportunityDealNumber: selectedDeal?.name,
                dealTypeName: selectedDeal?.dealType?.name
            };

            var response = await nextGenService.renewOpportunity(renewRequest);

            if (response.status === 200 && response.data) {
                let oppNumber = Object(response.data)["item1"]
                let oppDealNumber = Object(response.data)["item2"]

                setRenewInProgress(false)
                sessionStorage.setItem(SessionStorageConsts.pageMode, PageMode.Edit);
                sessionStorage.setItem(SessionStorageConsts.pageSubMode, PageSubMode.Renew);

                window.location.href = '/managecase/pricingprogram?oppNumber=' + oppNumber + '&oppDealNumber=' + oppDealNumber
            }

        } catch (error) {
            setRenewInProgress(false)
        }
    };

    /** Open, close, submit dialog methods for delete, revert, expire actions */
    function openDialog(action: CaseActions) {
        setSelectedAction(action)

        if (action === CaseActions.Expire) {
            setDialogConfig({ title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpireActionName", UIControlsText.Expire.ActionName)
            , body: getRuleValidationMessage(sessionResourceData, MessageTypeText.PopupMessageText, "ExpireMessage", UIControlsText.Expire.ExpireMessage ) })
        }

        if (action === CaseActions.Revert) {
            setDialogConfig({ title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RevertActionName", UIControlsText.Revert.ActionName), body: getRuleValidationMessage(sessionResourceData, MessageTypeText.PopupMessageText, "RevertMessage", UIControlsText.Revert.RevertMessage) })
        }

        if (action === CaseActions.Delete) {
            setDialogConfig({ title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeleteActionName", UIControlsText.Delete.ActionName), body: getRuleValidationMessage(sessionResourceData, MessageTypeText.PopupMessageText, "DeleteMessage", UIControlsText.Delete.DeleteMessage) })
        }

        if (action === CaseActions.Renew) {
            setDialogConfig({ title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RenewActionName", UIControlsText.Renew.ActionName), body: getRuleValidationMessage(sessionResourceData, MessageTypeText.PopupMessageText, "RenewRenewalMessage", UIControlsText.Renew.RenewalMessage) })
        }

        if (action === CaseActions.Copy) {
            setDialogConfig({ title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CopyActionName", UIControlsText.Copy.ActionName), body: getRuleValidationMessage(sessionResourceData, MessageTypeText.PopupMessageText, "CopyMessage", UIControlsText.Copy.CopyMessage) })
        }

        if (action === CaseActions.DownloadCaseLetters) {
            setIsDecisionLetterSelectedWithSKU(false)
            setDialogConfig({ title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DownloadCaseLettersActionName", UIControlsText.DownloadCaseLetters.ActionName), body: getRuleValidationMessage(sessionResourceData, MessageTypeText.PopupMessageText, "DownloadCaseLettersMessage", UIControlsText.DownloadCaseLetters.CopyMessage) })
        }

        setIsDialogOpen(true);
    }

    const closeDialog = () => {
        setIsDecisionLetterOpen(false);
        setIsDialogOpen(false);
    };

    async function submitDialog() {
        
        if (selectedDeal == undefined) {
            return
        }

        let fullName = userDetails?.firstName + ',' + userDetails?.lastName

        try {
            switch (selectedAction) {
                case CaseActions.Expire:
                    await nextGenService.setOpportunityDealStatus(selectedDeal.id, StateCode.Active, OpportunityDealStatusCodes.ForcedExpire, fullName, userDetails?.email)
                    break;

                case CaseActions.Revert:
                    const userRoleDetails: IUserRoleDetail[] = getLoggedinUserRoleDetails();
                    let countries = "";
                    let accounts = "";
                    countries = userRoleDetails.map(function (userRoleDetail) { return userRoleDetail.countryId }).join(',');
                    accounts = userRoleDetails.map(function (userRoleDetail) { return userRoleDetail.accountId }).join(',');
                    let userRole = getDistinctUserRole(userRoleDetails);
                    sessionStorage.setItem(SessionStorageConsts.userRole, userRole.toString());
                    
                    const revertRequest: IDealRevertRequest = {
                        opportunityNumber: selectedDeal?.opportunityName,
                        opportunityDealNumber: selectedDeal?.name,
                        opportunityDealId: selectedDeal.id,
                        dealTypeName: selectedDeal?.dealType?.name,
                        fullName: fullName,
                        email: userDetails?.email
                    };
        
                    revertRequest.countries = countries;
                    revertRequest.accounts = accounts;
                    revertRequest.userRole = userRole;

                    await nextGenService.revertOpportunityDealStatus(revertRequest)
                    break;

                case CaseActions.Delete:
                    await nextGenService.setOpportunityDealStatus(selectedDeal.id, StateCode.Active, OpportunityDealStatusCodes.Cancelled, fullName, userDetails.email)
                    break;

                case CaseActions.Renew:
                    if (IsExternalPricingProgram(selectedDeal?.dealType?.name)) {
                        onRenew()
                    }
                    break;

                case CaseActions.Copy:
                    if (IsExternalPricingProgram(selectedDeal?.dealType?.name)) {
                        onCopy()
                    }
                    break;

                case CaseActions.DownloadCaseLetters:
                    setIsLetterBeingDownloaded(true);
                    await getCaseDecisionLetter(CaseActions.DownloadCaseLetters)
                    setIsLetterBeingDownloaded(false);
                    break;
            }

            setIsDialogOpen(false);
        } catch (error) {
            setIsDialogOpen(false);
        }
    }

    useToShowActionButtonTextOnHoverOrFocusInReflow();

    return (
           
        <>
            <Stack id="mdppAccessibility-action-menu" horizontal styles={{ root: { height: 28 } }}>
                <CommandBarButton text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EditText", UIControlsText.EditText)}
                    onClick={onEditCase}
                    styles={ActionButtonStyles}
                    disabled={!actionsConfig?.enableEdit}
                    iconProps={{ iconName: CssClassConstants.EditIconName }} />
                <CommandBarButton text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendText", UIControlsText.ExtendText)}
                    onClick={openExtendDialog}
                    styles={ActionButtonStyles}
                    disabled={!actionsConfig?.enableExtend}
                    iconProps={{ iconName: CssClassConstants.ExtendIconName }} />
                <CommandBarButton text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpireText", UIControlsText.ExpireText)}
                    onClick={() => openDialog(CaseActions.Expire)}
                    styles={ActionButtonStyles}
                    disabled={!actionsConfig?.enableExpire}
                    iconProps={{ iconName: CssClassConstants.ExpireIconName }} />
                <CommandBarButton text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RenewText", UIControlsText.RenewText)}
                    onClick={() => openDialog(CaseActions.Renew)}
                    styles={ActionButtonStyles}
                    disabled={!actionsConfig?.enableRenew}
                    iconProps={{ iconName: CssClassConstants.RenewIconName }} />
                <CommandBarButton text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CopyText", UIControlsText.CopyText)}
                    onClick={() => openDialog(CaseActions.Copy)}
                    styles={ActionButtonStyles}
                    disabled={!actionsConfig?.enableCopy}
                    iconProps={{ iconName: CssClassConstants.CopyIconName }} />
                <CommandBarButton text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeleteText", UIControlsText.DeleteText)}
                    onClick={() => openDialog(CaseActions.Delete)}
                    styles={ActionButtonStyles}
                    disabled={!actionsConfig?.enableDelete}
                    iconProps={{ iconName: CssClassConstants.DeleteIconName }} />
                <CommandBarButton text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RevertCaseText", UIControlsText.RevertCaseText)}
                    onClick={() => openDialog(CaseActions.Revert)}
                    styles={ActionButtonStyles}
                    disabled={!actionsConfig?.enableRevert}
                    iconProps={{ iconName: CssClassConstants.UndoIconName }} />
                <CommandBarButton hidden={!actionsConfig?.showCPCaseDecisionLetter}
                    text={decisionLetterButtonText}
                    onClick={() => onCaseDecisionLetter(CaseActions.DownloadCaseLetters)}
                    styles={ActionButtonStyles} disabled={!actionsConfig?.enableCPCaseDecisionLetter}
                    iconProps={{ iconName: CssClassConstants.DownloadIconName }} />
                <CommandBarButton hidden={!actionsConfig?.showCPStrategicLetters}
                    text={manufacturersLetterButtonText}
                    onClick={() => onCPStrategicLetters(CaseActions.ViewCaseLetters)}
                    styles={ActionButtonStyles} disabled={!actionsConfig?.enableCPStrategicLetters}
                    iconProps={{ iconName: CssClassConstants.CPStrategicLetters }} />
            </Stack>

            {showExtensionLimit &&
                <Stack {...columnProps}>
                    <ExtendLimitMessage close={() => setShowExtensionLimit(false)} />
                </Stack>
            }

            {showMaxExtensionWarning &&
                <Stack {...columnProps}>
                    <MaxExtendMessage close={() => setShowMaxExtensionWarning(false)} />
                </Stack>
            }

            {
                copyInProgress &&
                <Stack>
                    <div>
                        <Spinner label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CopyProgress", UIControlsText.CopyProgress)} />
                    </div>
                </Stack>
            }
            {
                renewInProgress &&
                <Stack>
                    <div>
                        <Spinner label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RenewProgress", UIControlsText.RenewProgress)} />
                    </div>
                </Stack>
            }
            {isDialogOpen && (
                <Dialog style={_Styles} onClose={closeDialog} autoFocus={true}>

                    {selectedAction === CaseActions.ViewCaseLetters ? (
                        <>
                            <Stack horizontal tokens={stackTokens} styles={stackGridStyles}>
                                <NextGenKendoGrid
                                    {...{
                                        kendoGridProps: { ...cpStrategicLettersConfig.kendoGridProps }
                                    }}
                                />
                            </Stack>

                            <DialogActionsBar>
                                <Stack horizontal tokens={stackTokens} styles={stackButtonStyles}>
                                    <PrimaryButton
                                        title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}
                                        aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}
                                        text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}
                                        onClick={closeDialog}
                                    />
                                </Stack>
                            </DialogActionsBar>
                        </>) : (<>
                            <Stack horizontal={false} tokens={stackTokens} styles={stackStyles}>
                                <Stack>
                                    <h4> {dialogConfig.title}</h4>
                                </Stack>

                                <Stack>
                                    <Label style={expireDialog} className={_Styles.DialogueCheckBox}>
                                        {dialogConfig.body}
                                    </Label>
                                </Stack>
                            </Stack>
                            {isDecisionLetterOpen &&
                                <DialogActionsBar>
                                    <Stack>
                                        <Dropdown
                                            options={CaseDecisionLetterTypeOptions}
                                            defaultSelectedKey={CaseDecisionLetterType.WithoutSKU}
                                            onChange={onDecisionLetterOptionChange}
                                        />
                                    </Stack>
                                </DialogActionsBar>
                            }
                            <DialogActionsBar>
                                <Stack horizontal horizontalAlign="end" tokens={stackButtonTokens} styles={stackButtonStyles}>
                                    {
                                        isLetterBeingDownloaded &&
                                        <Stack>
                                            <div>
                                                <Spinner label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Downloading", UIControlsText.Downloading)} />
                                            </div>
                                        </Stack>
                                    }
                                    <PrimaryButton
                                        text={
                                            getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseActionsDialogConfirmButton", UIControlsText.CaseActionsDialog.ConfirmButton)}
                                        ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseActionsDialogConfirmButton", UIControlsText.CaseActionsDialog.ConfirmButton)}
                                        onClick={submitDialog}
                                        styles={extendDialogButtonStyles}
                                        disabled={isLetterBeingDownloaded}
                                    />
                                    <DefaultButton
                                        text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseActionsDialogCancelButton", UIControlsText.CaseActionsDialog.CancelButton)}
                                        ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseActionsDialogCancelButton", UIControlsText.CaseActionsDialog.CancelButton)}
                                        onClick={closeDialog}
                                        styles={extendDialogButtonStyles}
                                    />
                                </Stack>
                            </DialogActionsBar>
                        </>)
                    }
                </Dialog>
            )}
            
            {
                <Panel
                    headerText={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendActionName", UIControlsText.Extend.ActionName)}
                    isOpen={isOpen}
                    onDismiss={onDismiss}
                    closeButtonAriaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}
                    isFooterAtBottom={true}
                    onRenderFooterContent={showBackButton ? onRenderFooterContentBack : onRenderFooterContent}>
                    {
                        extensionInProgress &&
                        <Stack>
                            <div>
                                <Spinner label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtensionProgress", UIControlsText.ExtensionProgress)} />
                            </div>
                        </Stack>
                    }
                    {
                        selectedDeal?.dealType?.name === DealType.DealRegistration && !showMessageBanner &&
                        <>
                            <Label style={extendDialog}>{getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ExtendDealRegDescription", UIControlsText.Extend.DealRegDescription)}</Label>
                            <Stack {...columnProps}>
                                <Label>
                                    <DatePicker
                                        styles={DatePickerStyles}
                                        strings={defaultDatePickerStrings}
                                        minDate={new Date(Date.now())}
                                        highlightSelectedMonth={true}
                                        value={selectedDate}
                                        onSelectDate={validateExpirationDate}
                                        textField={{
                                            validateOnLoad: false, validateOnFocusOut: true,
                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendExpirationDate", UIControlsText.Extend.ExpirationDate),
                                            placeholder: Date.now().toString(),
                                            required: true,
                                            onGetErrorMessage: () => { return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredFieldError", ErrorMessages.RequiredFieldError) },
                                        }}
                                    />
                                </Label>
                            </Stack>
                            {showExtensionFailure && showMessageBanner &&
                                <Stack {...columnProps}>
                                    <ExtendFailureMessage close={() => setShowExtensionFailure(false)} />
                                </Stack>
                            }
                            {showExtensionWarning &&
                                <Stack {...columnProps}>
                                    <ExtendMessage close={() => setShowExtensionWarning(false)} />
                                </Stack>
                            }
                            {showExtensionSuccess && showMessageBanner &&
                                <Stack {...columnProps}>
                                    <SuccessMessage close={() => setShowExtensionSuccess(false)} />
                                </Stack>
                            }
                        </>
                    }
                    {
                        selectedDeal?.dealType?.name === DealType.SpecialPricing && !showMessageBanner &&
                        <>
                            <Label style={extendDialog}>{JavaScriptExtensions.stringFormat(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendSpecialPricingDescription", UIControlsText.Extend.SpecialPricingDescription), minUnitsForAutoApproval.toString())}</Label>
                            <Stack {...columnProps}>
                                <Label>
                                    <DatePicker
                                        styles={DatePickerStyles}
                                        strings={defaultDatePickerStrings}
                                        minDate={new Date(Date.now())}
                                        highlightCurrentMonth={true}
                                        textField={{
                                            validateOnLoad: false, validateOnFocusOut: true,
                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendExpirationDate", UIControlsText.Extend.ExpirationDate),
                                            placeholder: Date.now().toString(),
                                            required: true,
                                            onGetErrorMessage: () => { return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredFieldError", ErrorMessages.RequiredFieldError) },
                                        }}
                                        value={selectedDate}
                                        onSelectDate={validateExpirationDate}
                                        highlightSelectedMonth={true}
                                    />
                                </Label>
                            </Stack>
                            <Stack {...columnProps}>
                                <Label>
                                    <TextField
                                        disabled={readonly}
                                        label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendReasonForExtension", UIControlsText.Extend.ReasonForExtension)}
                                        ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendReasonForExtension", UIControlsText.Extend.ReasonForExtension)}
                                        placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendReasonForExtension", UIControlsText.Extend.ReasonForExtension)}
                                        styles={textFieldStylesOneColumn}
                                        onGetErrorMessage={(value) => { if (!value) { return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredFieldError", ErrorMessages.RequiredFieldError) } }}
                                        validateOnFocusOut
                                        multiline
                                        rows={3}
                                        resizable={false}
                                        validateOnLoad={false}
                                        required={true}
                                        maxLength={2000}
                                        value={extensionReason}
                                        onChange={onExtensionReasonChange}
                                    />
                                </Label>
                            </Stack>
                            <Stack {...columnProps}>
                                <Label>
                                    <TextField
                                        disabled={true}
                                        label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendTotalUnitsTransacted", UIControlsText.Extend.TotalUnitsTransacted)}
                                        ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendTotalUnitsTransacted", UIControlsText.Extend.TotalUnitsTransacted)}
                                        placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendTotalUnitsTransacted", UIControlsText.Extend.TotalUnitsTransacted)}
                                        styles={textFieldStylesOneColumn}
                                        onGetErrorMessage={(value) => { if (!value) { return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredFieldError", ErrorMessages.RequiredFieldError) } }}
                                        validateOnFocusOut
                                        validateOnLoad={false}
                                        value={selectedDeal.unitsTransacted?.toString()}
                                        type={"number"}
                                        required={true}
                                        min={0}
                                    />
                                </Label>
                            </Stack>
                            <Stack {...columnProps}>
                                <Label>
                                    <TextField
                                        disabled={readonly}
                                        label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendExpectedUnitTransactions", UIControlsText.Extend.ExpectedUnitTransactions)}
                                        ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendExpectedUnitTransactions", UIControlsText.Extend.ExpectedUnitTransactions)}
                                        placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExtendExpectedUnitTransactions", UIControlsText.Extend.ExpectedUnitTransactions)}
                                        styles={textFieldStylesOneColumn}
                                        onGetErrorMessage={(value) => { if (!value) { return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredFieldError", ErrorMessages.RequiredFieldError) } }}
                                        validateOnFocusOut
                                        validateOnLoad={false}
                                        type={"number"}
                                        value={expectedUnits.toString()}
                                        onChange={onExpectedUnitsChange}
                                        required={true}
                                        min={0}
                                    />
                                </Label>
                            </Stack>
                            {showExtensionWarning &&
                                <Stack {...columnProps}>
                                    <ExtendMessage close={() => setShowExtensionWarning(false)} />
                                </Stack>
                            }
                        </>
                    }
                    {showExtensionFailure && showMessageBanner &&
                        <Stack {...columnProps}>
                            <ExtendFailureMessage close={() => setShowExtensionFailure(false)} />
                        </Stack>
                    }
                    {
                        showExtensionSuccess && showMessageBanner &&
                        <Stack {...columnProps}>
                            <SuccessMessage close={() => setShowExtensionSuccess(false)} />
                        </Stack>
                    }
                    {
                        showExtensionReview && showMessageBanner &&
                        <Stack {...columnProps}>
                            <ReviewMessage close={() => setShowExtensionReview(false)} />
                        </Stack>
                    }
                </Panel>
            }
        </>
    );
};