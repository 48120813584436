import { Reducer } from "react";
import { isNullOrEmpty } from "../../../../components/shared/common/util";
import { Guid } from "../../../../infrastructure/Guid";
import { JavaScriptExtensions } from "../../../../infrastructure/JavaScriptExtensions";
import { IDealState } from "../state/IDealState";
import { IPageState } from "../state/IPageState";
import { CaseActionTypes } from "./CaseActionTypes";
import { ICommonPPActions } from "./ICommonPPActions";

/**
 * Common pricing program Reducer.
 * @function
 */
const commonPPReducer: Reducer<IPageState, ICommonPPActions> =
    (state: IPageState, action: ICommonPPActions) => {
        switch (action.type) {
            case CaseActionTypes.dataLoadingStart:
                return { ...state, dataLoading: true };

            case CaseActionTypes.dataLoadingEnd:
                return { ...state, dataLoading: false };

            case CaseActionTypes.debugModeOn:
                return { ...state, debugMode: true };

            case CaseActionTypes.casePivotChanged:
                return { ...state, selectedPivot: action.selectedPivot };

            case CaseActionTypes.pivotTabData:
                {
                    let newState = { ...state };
                    if(!JavaScriptExtensions.isNullOrUndfinedOrEmpty(action.pivotTabDetails)) {
                        newState.pivotTabs = action.pivotTabDetails;
                    }

                    return newState;
                }

            case CaseActionTypes.saveDraft:
                return { ...state, saveDraft: action.boolValue };

            case CaseActionTypes.submitCaseAsync:
                return { ...state, submitCaseAsync: action.boolValue };

            case CaseActionTypes.isFinalOrAdditionalResellerSelected:
                return { ...state, isFinalOrAdditionalReseller: action.boolValue };

            case CaseActionTypes.draftCaseLoaded:
                {
                    let newState = { ...state };
                    newState = Object.assign(newState, action.case as IPageState);;
                    return newState;
                }

            case CaseActionTypes.productGroupChanged:
                return { ...state, productGroup: action.productGroupValue };

            case CaseActionTypes.dealFieldValueChanged:
                return updateDealFieldState(state, action);

            case CaseActionTypes.dealCustomerValueChanged:
                return updateDealCustomerState(state, action);

            case CaseActionTypes.dealAddressValueChanged:
                return updateDealAddressState(state, action);

            case CaseActionTypes.channelContactChanged:
                return { ...state, opportunityChannelContacts: action.channelContacts };

            case CaseActionTypes.rfpRfqPublicTenderValueChanged:
                return { ...state, opportunityRfp: action.inputNumber };

            case CaseActionTypes.rfpRfqPublicTenderJustificationValueChanged:
                return { ...state, rfpJustification: action.inputValue };

            case CaseActionTypes.attachmentChanged:
                return { ...state, attachments: action.attachments };

            case CaseActionTypes.channelAccountChanged:
                return { ...state, opportunityAccounts: action.opportunityAccounts };

            case CaseActionTypes.pricingProgramSelectionChanged:
                return { ...state, opportunityDeals: action.opportunityDeals || [] };

            case CaseActionTypes.pricingProgramDealType:
                return { ...state, applicableDealTypes: action.applicableDealTypes || [] };

            case CaseActionTypes.SelectedDealChanged:
                return { ...state, selectedOptyDealCaseNumber: action.selectedDealNumber };

            case CaseActionTypes.selectedLegalEntityChanged:
                {
                    let newState = { ...state };
                    let index = newState.opportunityDeals.findIndex(x => x.id === action.dealCustomers?.key);
                    if (index >= 0)
                        newState.opportunityDeals[index].dealCustomers = action.dealCustomers?.value;
                    return newState
                }

            case CaseActionTypes.partnerDetailsChanged:
                return { ...state, partnerDetails: action.partnerDetails };

            case CaseActionTypes.selectedProductFamilyChanged: {
                let newState = { ...state };
                let index = newState.opportunityDeals.findIndex(x => x.id === action.products?.key);
                if (index >= 0)
                    newState.opportunityDeals[index].products = action.products?.value;
                return newState;
            }

            case CaseActionTypes.closureDecisionChanged:
                {
                    let newState = { ...state };
                    let index = newState.opportunityDeals.findIndex(x => x.id === action.closureDecision?.key);
                    if (index >= 0) {
                        newState.opportunityDeals[index].closureDecision = action.closureDecision?.value;
                    }
                    return newState
                }  
        }

        return state;
    }

/**
 * update Deal Field State.
 * @param {IPageState} state state object
 * @param {ICommonPPActions} action action object
 * @param {string} fieldName field to be changed
 */
const updateDealFieldState = (state: IPageState, action: ICommonPPActions): IPageState => {
    let newState = { ...state };
    if (action && !isNullOrEmpty(action.dealInputControlValue)) {
        var opportunityDeals = newState.opportunityDeals.filter(t => isNullOrEmpty(action?.dealInputControlValue?.id) || t.id === action?.dealInputControlValue?.id);
        if (opportunityDeals) {
            opportunityDeals.forEach(x => (x as any)[action?.dealInputControlValue?.key || ""] = action?.dealInputControlValue?.value);
        }
    }

    return newState;
}

/**
 * update Deal Customer State.
 * @param {IPageState} state state object
 * @param {ICommonPPActions} action action object
 */
const updateDealCustomerState = (state: IPageState, action: ICommonPPActions): IPageState => {
    let newState = { ...state };
    if (action && !isNullOrEmpty(action.dealInputControlValue)) {
        let dealCustomer: any = action.dealInputControlValue;
        var opportunityDeals = newState.opportunityDeals.filter(t => isNullOrEmpty(action?.dealInputControlValue?.id) || t.id === action?.dealInputControlValue?.id);
        if (opportunityDeals) {
            opportunityDeals.forEach((deal?: IDealState) => {
                deal?.dealCustomers?.forEach(x => (x as any)[dealCustomer.key] = dealCustomer.value)
            });
        }
    }

    return newState;
}

/**
 * update Deal Address State.
 * @param {IPageState} state state object
 * @param {ICommonPPActions} action action object
 */
const updateDealAddressState = (state: IPageState, action: ICommonPPActions): IPageState => {
    let newState = { ...state };
    if (action && !isNullOrEmpty(action.dealInputControlValue)) {
        let dealAddress: any = action.dealInputControlValue;
        var opportunityDeals = newState.opportunityDeals.filter(t => isNullOrEmpty(action?.dealInputControlValue?.id) || t.id === action?.dealInputControlValue?.id);
        if (opportunityDeals) {
            opportunityDeals.forEach((deal?: IDealState) => {
                if (deal && JavaScriptExtensions.isNullOrUndfinedOrEmpty(deal?.dealAddress)) {
                    deal.dealAddress = { id: Guid.newGuid() };
                }

                (deal?.dealAddress as any)[dealAddress.key] = dealAddress.value;
            });
        }
    }

    return newState;
}

export default commonPPReducer;