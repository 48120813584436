import { FC, useEffect, useState } from 'react';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import { useHistory } from 'react-router';
import { IBreadCrumbProps } from '../props/IBreadCrumbProps';
import BreadCrumbData from './breadcrumb.config.json'
import { BreadCrumbStyles } from '../../../content/styles/Page.styles';
import { UIControlsText } from '../../../content/UIControlsText';
import React from 'react';
import { SessionStorageConsts } from '../../../models/SessionStorageConsts';
import { getEntityDataFromSession, getRuleValidationMessage } from '../common/util';
import { MessageTypeText } from '../../../models/MessageTypeText';

export const Breadcrumbs: FC<IBreadCrumbProps> = (props: IBreadCrumbProps) => {
    const [items, setItems] = useState<IBreadcrumbItem[]>([])
    const history = useHistory();
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

    useEffect(() => {
        setItems([])
        props.items.map((item: { text: string; key: string; }, index: number) => setItems((prevState: IBreadcrumbItem[]) => [...prevState, { text: item.text, key: item.key, onClick: onBreadcrumbItemClicked }]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    function onBreadcrumbItemClicked(ev?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem): void {
        // eslint-disable-next-line array-callback-return
        Object.keys(BreadCrumbData.routing).map((key, index) => {
            if (key === item?.key) {
                history.push((BreadCrumbData.routing as any)[item?.key]);
            }
        })
    }

    return (
        <React.Fragment>
            <Breadcrumb
                items={items}
                maxDisplayedItems={10}
                ariaLabel={ getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BreadcrumbAriaLabelText", UIControlsText.BreadcrumbAriaLabelText)}
                overflowAriaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BreadcrumbAriaLabelText", UIControlsText.BreadcrumbAriaLabelText)}
                styles={BreadCrumbStyles}
            />
        </React.Fragment>
    );
};