import { defaultColumnProps, _Styles } from '../../../../content/styles/Page.styles';
import { ILegalEntity } from '../../../../models/ILegalEntity';
import { useInternalPricingContext } from '../../CPStrategic/context/internalpricing-context';
import DocumentCardContainerComponent from '../../../shared/ComponentTypes/CustomTileCardContainer';
import { Stack } from 'office-ui-fabric-react/lib/components/Stack';
import { PageStyles } from '../../common/content/Page.Styles';
import { smallAlignmentTokens, stackItemCustomerStyles } from '../../PricingProgramForm/PricingProgramFormUtils';
import { getEntityDataFromSession, getRuleValidationMessage, isFieldVisible } from '../../../../components/shared/common/util';
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { UIControlsText } from '../../../../content/UIControlsText';
import { UIControlType } from '../../../../models/UIControlType';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { ApplicationConstants } from '../../../../models/ApplicationConstants';

export function OtleCustomerView() {
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const { internalPricingContextState } = useInternalPricingContext();
    let customerDetail: ILegalEntity = {} as ILegalEntity;
    
    // to read customer information from context
    if (internalPricingContextState?.opportunityDeals[0].dealCustomers && internalPricingContextState?.opportunityDeals[0].dealCustomers.length > 0) {
        customerDetail = internalPricingContextState.opportunityDeals[0].dealCustomers[0];
    }

    //validate customer type and visible fields accordingly
    const isManagedCustomer = (isManagedCustomerField: boolean): boolean => {
        var isManaged = customerDetail?.isManaged;
        return isManagedCustomerField === isManaged
    }
    //validate customer type and visible fields accordingly
    const isCustomerExists = (): boolean => {
        var isExists = true 
        if(!customerDetail?.country)
        {
            isExists = false;
        }
        return isExists;
    }

    const CustomerDetailsJson = {
        components:
        [
        {
            type: "Stack",
            data: {
                id: "StackForCustDetails",
                wrap: true,
                horizontal: true,
                visible: isCustomerExists(),
                disableShrink:true,
                tokens: smallAlignmentTokens,
                components: [
                    {
                        type: 'StackItem',
                        data: {
                            id: 'EndCustomercountry',
                            visible: isCustomerExists(),
                            horizontal: "true",
                            disableshrink: 'true',
                            components: [
                                {
                                    type: "CustomTileCardComponent",
                                    data: {
                                        id: "ViewCardCustomer",
                                        baseState: internalPricingContextState,
                                        name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDetails", UIControlsText.CustomerDetails),
                                        iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDetailsIcon", UIControlsText.CustomerDetailsIcon),
                                        components: [
                                            {
                                                type: "Stack",
                                                data: {
                                                    id: "StackForAllCustomerStackItems",
                                                    wrap: true,
                                                    horizontal: true,
                                                    components: [
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                styles: stackItemCustomerStyles,
                                                                align: "start",
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerCountryTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerCountry", UIControlsText.CustomerCountry),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerCountry", UIControlsText.CustomerCountry),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.country,
                                                                            styles: PageStyles.textFieldStylesFourColumn
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerName',
                                                                visible: isManagedCustomer(true),
                                                                horizontal: "true",
                                                                disableshrink: 'true',
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerNameValueField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerNameText", UIControlsText.CustomerNameText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerNameText", UIControlsText.CustomerNameText),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.legalEntityName,
                                                                            styles: PageStyles.textFieldStylesFourColumn
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerDomain',
                                                                visible: isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDomainText", UIControlsText.CustomerDomainText), internalPricingContextState.pageMode, ApplicationConstants.otlePageName),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerDomainTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDomainText", UIControlsText.CustomerDomainText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDomainText", UIControlsText.CustomerDomainText),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            styles: PageStyles.textFieldStylesFourColumn,
                                                                            value: customerDetail?.dealCustomerDomain,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'TPID',
                                                                visible: isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "TPID", UIControlsText.TPID), internalPricingContextState.pageMode, ApplicationConstants.otlePageName),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "TPIDTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "TPID", UIControlsText.TPID),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "TPID", UIControlsText.TPID),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            styles: PageStyles.textFieldStylesFourColumn,
                                                                            value: customerDetail?.tpid,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'CRMChildAccountId',
                                                                visible: isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CRMAccountID", UIControlsText.CRMAccountID), internalPricingContextState.pageMode, ApplicationConstants.otlePageName),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CRMAccountIDTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CRMAccountID", UIControlsText.CRMAccountID),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CRMAccountID", UIControlsText.CRMAccountID),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            styles: PageStyles.textFieldStylesFourColumn,
                                                                            value: customerDetail?.crmAccountId,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'Segment',
                                                                visible: isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerSegment", UIControlsText.CustomerSegment), internalPricingContextState.pageMode, ApplicationConstants.otlePageName),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "SegmentTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerSegment", UIControlsText.CustomerSegment),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerSegment", UIControlsText.CustomerSegment),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            styles: PageStyles.textFieldStylesFourColumn,
                                                                            value: customerDetail?.segment,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerIndustry',
                                                                visible: isManagedCustomer(false) && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Industry", UIControlsText.Industry), internalPricingContextState.pageMode, ApplicationConstants.otlePageName),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerIndustryTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Industry", UIControlsText.Industry),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Industry", UIControlsText.Industry),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.industry,
                                                                            styles: PageStyles.textFieldStylesFourColumn
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerName',
                                                                visible: isManagedCustomer(false) && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerNameEnglish", UIControlsText.CustomerNameEnglish), internalPricingContextState.pageMode, ApplicationConstants.otlePageName),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerIndustryTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerNameEnglish", UIControlsText.CustomerNameEnglish),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerNameEnglish", UIControlsText.CustomerNameEnglish),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.legalEntityName,
                                                                            styles: PageStyles.textFieldStylesOneColumn
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerName',
                                                                visible: isManagedCustomer(false) && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerNameLocalLanguage", UIControlsText.CustomerNameLocalLanguage), internalPricingContextState.pageMode, ApplicationConstants.otlePageName),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerIndustryTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerNameLocalLanguage", UIControlsText.CustomerNameLocalLanguage),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerNameLocalLanguage", UIControlsText.CustomerNameLocalLanguage),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.organizationNameInLocalLanguage,
                                                                            styles: PageStyles.textFieldStylesOneColumn,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerAddress',
                                                                visible: isManagedCustomer(false) && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddressFacetText", UIControlsText.AddressFacetText), internalPricingContextState.pageMode, ApplicationConstants.otlePageName),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerAddressTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddressFacetText", UIControlsText.AddressFacetText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddressFacetText", UIControlsText.AddressFacetText),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.address,
                                                                            styles: PageStyles.textFieldStylesFourColumn,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerVertical',
                                                                visible: isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Vertical", UIControlsText.Vertical), internalPricingContextState.pageMode, ApplicationConstants.otlePageName),
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerVerticalTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Vertical", UIControlsText.Vertical),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Vertical", UIControlsText.Vertical),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.vertical,
                                                                            styles: PageStyles.textFieldStylesFourColumn,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerVerticalCategory',
                                                                visible: isManagedCustomer(false) && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "VerticalCategory", UIControlsText.VerticalCategory), internalPricingContextState.pageMode, ApplicationConstants.otlePageName),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerVerticalCategoryTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "VerticalCategory", UIControlsText.VerticalCategory),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "VerticalCategory", UIControlsText.VerticalCategory),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.verticalCategory,
                                                                            styles: PageStyles.textFieldStylesFourColumn
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerWebPageURL',
                                                                visible: isManagedCustomer(false) && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "WebPageUrl", UIControlsText.WebPageUrl), internalPricingContextState.pageMode, ApplicationConstants.otlePageName),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerWebpageTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "WebPageUrl", UIControlsText.WebPageUrl),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "WebPageUrl", UIControlsText.WebPageUrl),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.webPageURL,
                                                                            styles: PageStyles.textFieldStylesFourColumn,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'EndCustomerEmailDomain',
                                                                visible: isManagedCustomer(false) && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailDomain", UIControlsText.EmailDomain), internalPricingContextState.pageMode, ApplicationConstants.otlePageName),
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CustomerEmailDomainTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailDomain", UIControlsText.EmailDomain),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailDomain", UIControlsText.EmailDomain),                               
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: customerDetail?.emailDomain,
                                                                            styles: PageStyles.textFieldStylesFourColumn,
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                ]
            }
        },
    ]};
    return (
        <div>
            <Stack horizontal {...defaultColumnProps} styles={PageStyles.labelStyles}>
                <DocumentCardContainerComponent {...CustomerDetailsJson} />
            </Stack>
        </div>
    );    
}