/**
 * Provides functionality related to Array process
 * @class 
 */
export class ArrayExtensions {
    /**
     * Merge the two arrays.
     * @method
     * @param inputArray1 {[]} The input array 1.
     * @param inputArray2 {[]} The input array 2.
     * @returns {[]} Merged Array.
     */
    public static mergeArrays<T>(inputArray1: T[], inputArray2: T[]): T[] {
        let jointArray: T[] = []
        let arrays = [inputArray1, inputArray2];

        arrays.forEach(array => {
            jointArray = [...jointArray, ...array]
        })
        const uniqueArray = jointArray.filter((item, index) => jointArray.indexOf(item) === index)

        return uniqueArray as T[];
    }

    /**
     * remove the undefined value from arrays.
     * @method
     * @param inputArray {[]} The input array.
     * @returns {[]} New Array.
     */
    public static removeundefinedfromArray<T>(inputArray: T[]): T[] {
        let newArray: T[] = []

        newArray = inputArray.filter(n=>n!==undefined);

        return newArray;   
    }
}