import {useEffect} from "react";
import { GridColumnMenuFilter, GridColumnMenuCheckboxFilter, GridColumnMenuProps } from "@progress/kendo-react-grid";
import { useFilterMenuEscCloseAndFocus } from "../../../hooks/accessiblity-hooks";
import { rootFilterOrDefault } from "@progress/kendo-react-grid/dist/npm/columnMenu/GridColumnMenuFilter";
import { CompositeFilterDescriptor, isCompositeFilterDescriptor } from "@progress/kendo-data-query";

//method to show filter in grid column as context menu
export const FilterMenu = (props: GridColumnMenuProps) => {
    /*
    * Accessibility - Focus filter menu icon on focus out
    */
    useFilterMenuEscCloseAndFocus(props);

    return (
        <div className="k-animation-container">
            <GridColumnMenuFilter {...props} expanded={true}/>
        </div>
    );
};

//method to show filter in grid column as context menu with checkbox selection
export const FilterMenuCheckboxFilter = (props: GridColumnMenuProps, data: any[]) => {
    return (
        <div>
            <GridColumnMenuCheckboxFilter
                {...props}
                data={data || []}
                expanded={true}
            />
        </div>
    );
};

export const FilterGroupByField = (field: string, filter: any) => {
    var rootFilter = rootFilterOrDefault(filter);
    var compositeFilters = rootFilter.filters
        .filter(function (f) {
        if (isCompositeFilterDescriptor(f)) {
            return f.filters &&
                f.filters.length &&
                f.filters.length >= 1 &&
                !f.filters.find(function (nf) { return isCompositeFilterDescriptor(nf) || nf.field !== field; });
        }
        return false;
    });
    return compositeFilters[0] || null;
};

export const IsFilterActive = (field: string, filter: CompositeFilterDescriptor | undefined) => {
    return !!FilterGroupByField(field, filter);
};