import React, { useEffect, useState} from "react";
import { stackGridStyleswithPadding, stackButtonStyles, selectStyles, stackGridStylesButton, stackGridStyleswithBelowPadding, stackGridStyleswithBorder, stackGridStylesLabel } from '../../content/styles/Page.styles';
import { Spinner, Stack, DefaultButton, PrimaryButton } from "office-ui-fabric-react";
import { getAllCountries, getLoggedinUserRoleDetails, getEntityDataFromSession, getRuleValidationMessage } from "../../components/shared/common/util";
import { SessionStorageConsts } from "../../models/SessionStorageConsts";
import { MessageTypeText } from '../../models/MessageTypeText';
import { UIControlsText } from "../../content/UIControlsText";
import { IAccount } from "../../models/IAccount";
import { IUserRoleDetail } from "../../models/IUserRoleDetail";
import { UserSubRole } from "../../models/UserSubRole";
import { ICountry } from "../../models/ICountry";
import { Label } from "@fluentui/react";
import { _Styles } from "../../content/styles/Page.styles";
import { default as ReactSelect } from "react-select";
import { IDashboardSearchProps } from "../../components/shared/grid/IDashboardSearchProps";
import { PartnerType } from "../../models/PartnerType";
import { nextGenService } from "../../services/NextGenService";

export const DashboardSearch = (props: IDashboardSearchProps) => {

    const [addAccounts, setADDAccounts] = React.useState<IAccount[] | undefined>([]);
    const [allCountries, setAllCountries] = useState<ICountry[] | undefined>([]);
    const [allResellers, setAllResellers] = useState<IAccount[] | undefined>([]);
    const [selectedResellerCountry, setSelectedResellerCountry] = useState<ICountry | undefined>();
    const [selectedResellersByGeo, setSelectedResellersByGeo] = useState<IAccount[] | undefined>([]);
    const [selectedReseller, setSelectedReseller] = useState<IAccount | undefined>();
    const [disablePartnerFilter, setDisablePartnerFilter] = useState<boolean>(true);
    const [selectedADD, setSelectedADD] = useState<IAccount | undefined>();
    const [allADDAccounts, setAllADDAccounts] = useState<IAccount[] | undefined>([]);
    const [allResellersWithPartnerType, setAllResellersWithPartnerType] = useState<IAccount[] | undefined>([]);
    const [selectedADDWithPartnerType, setSelectedADDWithPartnerType] = useState<IAccount[] | undefined>([]);
    const [selectedResellerWithPartnerType, setSelectedResellerWithPartnerType] = useState<IAccount[] | undefined>([]);
    const [selectedPartners, setSelectedPartners] = useState<IAccount[] | undefined>(undefined);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [isCleared, setIsCleared] = useState<boolean>(false);
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

    useEffect(() => {
        getADDAccounts(getLoggedinUserRoleDetails());
        getAllActiveResellers();
    },[])

    useEffect(() => {
        if(isSubmitted){
            setIsSubmitted(false);
            setSelectedPartners([...selectedADDWithPartnerType?selectedADDWithPartnerType : [], ...selectedResellerWithPartnerType? selectedResellerWithPartnerType : []]);
        }else if(isCleared){
            setIsCleared(false);
            setSelectedPartners([]);
        }
    },[isSubmitted, isCleared])

    useEffect(() => {
        props?.onfilterByPartner?.(selectedPartners);
    },[selectedPartners])

    const getAllActiveResellers = async() => {
        let resellerData = await Promise.resolve(nextGenService.getAllResellers());
        const uniqueResellerData = [...new Map(resellerData.data.map(item =>
            [item["accountId"], item])).values()];
        setAllResellersWithPartnerType(resellerData.data);
        setAllResellers(uniqueResellerData);
        setDisablePartnerFilter(false);

    }

    const getADDAccounts = async (loggedInUserInfo: Array<IUserRoleDetail>): Promise<IAccount[]> => {
        let allCountries = await Promise.resolve(getAllCountries());
        setAllCountries(allCountries);
        let userRoleDetails = loggedInUserInfo?.filter((user: IUserRoleDetail) => user.userSubRole === UserSubRole.ADD);
        var userAccounts = userRoleDetails?.map(ua => {
            return {
                accountId: ua.accountId,
                countryId: ua.countryId,
                country: allCountries.find(x => x.countryId === ua.countryId)?.countryName,
                accountName: ua.organizationName,
                productTypeId: ua.productType?.productId,
                partnerType: PartnerType.ADD
            } as IAccount
        });
        setAllADDAccounts(userAccounts);
        var distinctUserAccounts: IAccount[] = [];
        userAccounts.forEach(function(account: IAccount){
            if(distinctUserAccounts.findIndex(acc => acc.accountId == account.accountId) == -1){
                distinctUserAccounts.push(account);
            }
        });
        setADDAccounts(distinctUserAccounts);
        return userAccounts;
    }

    const getSelectedResellersBasedonCountry = (adrCountryId : string) => {
        let applicablePriceListGeo = allCountries?.find(d => d.countryId === adrCountryId)?.applicablePriceListGeo;
        let selectedResellerGeo = allCountries?.find(d => d.countryId === selectedResellerCountry?.countryId)?.applicablePriceListGeo;
        if (applicablePriceListGeo !== selectedResellerGeo)
        {
            let selectedCountries = allCountries?.filter(d => d.applicablePriceListGeo === applicablePriceListGeo);
            let finalfilteredResellers:IAccount[] = [];
            selectedCountries?.forEach((d) => {
                let filteredlist = allResellers?.filter(e => e.countryId === d.countryId);
                filteredlist && finalfilteredResellers?.push(...filteredlist)
            });
            setSelectedResellersByGeo(finalfilteredResellers || []);
        }
    }

    const onADDAccountSelectChange = (event: any) => {
        if (!event){
            setSelectedADD(undefined);
            setSelectedADDWithPartnerType([])
            return;
        } 
        setSelectedADD(addAccounts?.find(d => d.accountId === event.value));
        setSelectedADDWithPartnerType(allADDAccounts?.filter(d => d.accountId === event.value));
    };

    const onResellerCountrySelectionChange = (event: any) => {
        if (!event){
            setSelectedResellerCountry(undefined);
            setSelectedReseller(undefined)
            setSelectedResellerWithPartnerType([]);
            return;
        } 
        if(event)
        {
            setSelectedResellerCountry(allCountries?.find(d => d.countryId === event.value));
            getSelectedResellersBasedonCountry(event.value);
            setSelectedReseller(undefined);
        }
    }

    const filterGridData = () => {
        setIsSubmitted(true);
    }

    const clearFilter = () => {
        setIsCleared(true);
        setSelectedReseller(undefined);
        setSelectedResellerCountry(undefined);
        setSelectedADD(undefined);
        setSelectedResellersByGeo([]);
        setSelectedPartners([]);
        setSelectedADDWithPartnerType([]);
        setSelectedResellerWithPartnerType([]);
    }

    const onResellerSelectionChange = (event: any) => {
        if (!event){
            setSelectedResellerWithPartnerType([]);
            setSelectedReseller(undefined)
            return;
        } 
        var account = selectedResellersByGeo?.find(d => d.accountId === event.value);
        var resellerAccount: IAccount = {
                accountId: account?.accountId || '',
                accountName: account?.accountName || '',
                countryId: selectedResellerCountry?.countryId,
                countryName: selectedResellerCountry?.countryName,
                companyName: account?.companyName,
                productType: account?.productTypeId
                
            };
        setSelectedReseller(resellerAccount);
        setSelectedResellerWithPartnerType(allResellersWithPartnerType?.filter(d => d.accountId === resellerAccount?.accountId).map(ua => {
                                                                                                                                return {
                                                                                                                                    accountId : ua.accountId, 
                                                                                                                                    accountName : ua.accountName, 
                                                                                                                                    productTypeId : ua.productTypeId, 
                                                                                                                                    countryId : selectedResellerCountry?.countryId, 
                                                                                                                                    countryName : selectedResellerCountry?.countryName,
                                                                                                                                    partnerType : PartnerType.Reseller
                                                                                                                                }
                                                                                                                            }));
    }

    const onDealContactAliasTextChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        let input = newValue;
    }

    return(
        <React.Fragment >
            <Stack horizontal styles={stackGridStyleswithBelowPadding}>
                <Stack horizontal styles={stackGridStyleswithBorder}>
                    <Stack horizontal styles={stackGridStyleswithPadding}>
                        <Stack styles={stackGridStylesLabel}>
                            <Label>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ADD", UIControlsText.ADD)}</Label>
                        </Stack>
                        <Stack styles={stackGridStyleswithPadding}>
                            <ReactSelect
                                aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SelectADDAccountName", UIControlsText.SelectADDAccountName)}
                                placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SelectADDAccountName", UIControlsText.SelectADDAccountName)}
                                options={(addAccounts && addAccounts.map(add => ({ value:add.accountId, label:add.accountName}))) || []}
                                closeMenuOnSelect={true}
                                hideSelectedOptions={false}
                                onChange={onADDAccountSelectChange}
                                styles={selectStyles}
                                isDisabled={disablePartnerFilter}
                                value={selectedADD && { value:selectedADD.accountId, label:selectedADD.accountName} || ''}
                                isClearable={false}
                                
                            />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack horizontal styles={stackGridStyleswithPadding}>
                </Stack>
                <Stack styles={stackGridStyleswithBorder} horizontal>
                    <Stack horizontal styles={stackGridStyleswithPadding}>
                        <Stack styles={stackGridStylesLabel}>
                            <Label>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Reseller", UIControlsText.Reseller)}</Label>
                        </Stack>
                        <Stack styles={stackGridStyleswithPadding}>
                            <ReactSelect
                                aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SelectReseller", UIControlsText.SelectReseller)}
                                options={(allCountries && allCountries?.map(country => ({ value:country.countryId, label:country.countryName}))) || []}
                                placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SelectResellerCountry", UIControlsText.SelectResellerCountry)}
                                closeMenuOnSelect={true}
                                hideSelectedOptions={false}
                                maxMenuHeight={250}
                                onChange={onResellerCountrySelectionChange}
                                styles={selectStyles}
                                isDisabled={disablePartnerFilter}
                                value={ selectedResellerCountry && { value:selectedResellerCountry.countryId, label:selectedResellerCountry.countryName} || ''}
                                isClearable={false}
                            />
                        </Stack>
                        <Stack styles={stackGridStyleswithPadding}>
                            <ReactSelect
                                id={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SelectReseller", UIControlsText.SelectReseller)}
                                options={(selectedResellersByGeo && selectedResellersByGeo.map(adr => ({ value:adr.accountId, label:adr.companyName}))) || []}
                                placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SelectReseller", UIControlsText.SelectReseller)}
                                styles={selectStyles}
                                closeMenuOnSelect={true}
                                isDisabled={disablePartnerFilter}
                                onChange={onResellerSelectionChange}
                                value={selectedReseller && { value:selectedReseller.accountId, label:selectedReseller.companyName} || ''}
                                isClearable={false}
                            />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack horizontal styles={stackGridStyleswithPadding}> 
                    <Stack styles={stackGridStylesButton}>
                        <PrimaryButton
                            text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Search", UIControlsText.Search)}
                            ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Search", UIControlsText.Search)}
                            onClick={() => { filterGridData();}}
                        />
                    </Stack>
                    <Stack styles={stackGridStylesButton}>
                        <DefaultButton
                            styles={stackButtonStyles}
                            text="Clear"
                            onClick={() => {
                                clearFilter();
                            }}
                        />
                    </Stack>
                    {disablePartnerFilter &&
                        <Stack>
                            <div>
                                <Spinner label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LoadingFilterData", UIControlsText.LoadingFilterData)} />
                            </div>
                        </Stack>
                    }
                </Stack>
            </Stack>
        </React.Fragment>
    );
}