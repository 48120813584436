import NextGenKendoGrid from "../../../components/shared/grid/nextgenkendogrid";
import { IUnmanagedCustomer } from "../../../models/IUnmanagedCustomer";
import { nextGenService } from "../../../services/NextGenService";
import { IGridProps } from "../../../components/shared/grid/IGridProps";
import { GridType } from "../../../models/GridType";
import { RadioGridCell } from "../../../components/shared/grid/radioGridCell"
import { Checkbox, DefaultButton, Dropdown, IDropdownOption, Label, PrimaryButton, Stack, TextField } from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { UIControlsText } from "../../../content/UIControlsText";
import { countriesFilter, getCustomerIndustries, isNullOrEmpty, readGridConfig, getRuleValidationMessage, getEntityDataFromSession } from "../../../components/shared/common/util";
import { ICountry } from "../../../models/ICountry";
import { SessionStorageConsts } from "../../../models/SessionStorageConsts";
import { ModalDialogStyles, _Styles, stackStyles, stackButtonStyles } from "../../../content/styles/Page.styles";
import { ErrorMessages } from "../../../content/ErrorMessages";
import { IStackTokens, MaskedTextField, MessageBar, MessageBarType } from "@fluentui/react";
import { IIndustryDropdown } from "../../../models/IIndustryDropdown";
import { IVerticalDropdown } from "../../../models/IVerticalDropdown";
import { GuidanceText } from "../../../models/GuidanceText";
import { ICreateUnmanagedCustomerProps } from "./props/ICreateUnmanagedCustomerprops";
import { ILegalEntity } from "../../../models/ILegalEntity";
import { useCommonPPContext } from "../common/context/common-pp-context";
import { PageStyles } from "../common/content/Page.Styles";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { PageMode } from "../../../models/PageMode";
import { MessageTypeText } from '../../../models/MessageTypeText';
import { useInternalPricingContext } from '../../pricingprogram/CPStrategic/context/internalpricing-context';
import { DealType } from "../../../models/DealType";

const CreateUnmanagedCustomer: React.FunctionComponent<ICreateUnmanagedCustomerProps> = (props: ICreateUnmanagedCustomerProps) => {
    const [customerLocalName, setCustomerLocalName] = useState<string>("");
    const [customerName, setCustomerName] = useState<string>("");
    const [addressLine1, setAddressLine1] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [postalCode, setPostalCode] = useState<any>("");
    const [country, setCountry] = useState<any>("");
    const [selectedCountry, setSelectedCountry] = useState<IDropdownOption | null>();
    const [industry, setIndustry] = useState<any>("");
    const [vertical, setVertical] = useState<any>("");
    const [verticalCategory, setVerticalCategory] = useState<any>("");
    const [webPageURL, setWebPageURL] = useState<string>("");
    const [emailDomain, setEmailDomain] = useState<string>("");
    const [isCustomerNameChecked, setIsCustomerNameChecked] = useState<boolean>(false);
    const [toggleCustomerLoading, setToggleCustomerLoading] = useState<boolean>(false);
    const [toggleCustomerHardDuplicate, setToggleCustomerHardDuplicate] = useState<boolean>(false);
    const [toggleCustomerSoftDuplicate, setToggleCustomerSoftDuplicate] = useState<boolean>(false);
    const [toggleCustomerNewRecord, setToggleCustomerNewRecord] = useState<boolean>(false);
    const [CustomerNameRequired, setCustomerNameRequired] = useState<string>();
    const [CustomerLocalNameRequired, setCustomerLocalNameRequired] = useState<string>();
    const [countryRequired, setCountryRequired] = useState<string>();
    const [addressLine1Required, setAddressLine1Required] = useState<string>();
    const [cityRequired, setCityRequired] = useState<string>();
    const [postalCodeRequired, setPostalCodeRequired] = useState<string>();
    const [industryRequired, setIndustryRequired] = useState<string>();
    const [verticalRequired, setVerticalRequired] = useState<string>();
    const [verticalCategoryRequired, setVerticalCategoryRequired] = useState<string>();
    const [webPageUrlRequired, setWebPageUrlRequired] = useState<string>();
    const [webPageUrlPattern, setWebPageUrlPattern] = useState<string>();
    const [emailDomainPattern, setEmailDomainPattern] = useState<string>();
    const [stateRequired, setStateRequired] = useState<string>();
    const [industryDropdown, setIndustryDropdown] = useState<IIndustryDropdown[]>([]);
    const [verticalDropdown, setVerticalDropdown] = useState<IVerticalDropdown[]>([]);
    const [verticalCategoriesDropdown, setVerticalCategoriesDropdown] = useState<string[]>([]);
    const [enableCloneEnglishName, setEnableCloneEnglishName] = useState<boolean>(false);
    const [toggleSubmissionActions, setToggleSubmissionActions] = useState<boolean>(false);
    const [customerSearchGridConfig, setCustomerSearchGridConfig] = useState<IGridProps>(readGridConfig(GridType.DuplicateCustomerResults));
    const [isCustomerSelected, setIsCustomerSelected] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [showMessageBar, setShowMessageBar] = useState<boolean>(false)
    const [showNoteMessageBar, setShowNoteMessageBar] = useState<boolean>(true)
    const [selectedCustomerId, setSelectedCustomerId] = useState<string | undefined>();
    const [selectedCustomer, setSelectedCustomer] = useState<IUnmanagedCustomer | undefined>();
    const { commonPPContextState, commonPPContextDispatch } = useCommonPPContext();
    const [countriesDropDown, setCountriesDropdownData] = useState(countriesFilter());
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const [saveButtonText, setsaveButtonText] = useState<string>(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitNewCustomerRecord", UIControlsText.SubmitNewCustomerRecord));
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const stackButtonTokens: IStackTokens = { childrenGap: 16 };
    const stackTokens: IStackTokens = { childrenGap: 24 };
    const [isCountryDisabled, setIsCountryDisabled] = useState<boolean>(false);
    const [createUnmanagedCustomerGuidanceText, setCreateUnmanagedCustomerGuidanceText] = useState<string>();

    // Context for OTLE
    const { internalPricingContextState, internalPricingContextDispatch } = useInternalPricingContext();

    /* On partner location change countries dropdown will be reset */
    useEffect(() => {
        if (commonPPContextState != undefined) {
            let countries = countriesFilter(commonPPContextState.partnerDetails?.countryId)
            setCountry(commonPPContextState.partnerDetails?.countryId);
            setGuidanceText();

            setCountriesDropdownData(countries);
            let selectedCountry = countries.find(x => x.countryId === commonPPContextState.partnerDetails?.countryId);
            if (selectedCountry) {
                setSelectedCountry({ key: selectedCountry.countryId, text: selectedCountry.countryName ? selectedCountry.countryName : "" })
            }
            if (commonPPContextState.pageMode === PageMode.Edit) {
                setsaveButtonText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Save", UIControlsText.Save));
                setIsCountryDisabled(true);
            }
        }
    }, [commonPPContextState?.partnerDetails?.countryId, commonPPContextState?.selectedOptyDealCaseNumber]);

    useEffect(() => {
        if (internalPricingContextState != undefined) {
            let opportunitydeal = internalPricingContextState.opportunityDeals;
            let countries = countriesFilter(opportunitydeal[0].dealCustomerCountry);
            setCountry(opportunitydeal[0].dealCustomerCountry);
            setCountriesDropdownData(countries);
            setGuidanceText();

            let selectedCountry = countries.find(x => x.countryId === internalPricingContextState.opportunityDeals[0]?.dealCustomerCountry);

            if (selectedCountry) {
                setSelectedCountry({ key: selectedCountry.countryId, text: selectedCountry.countryName ? selectedCountry.countryName : "" })
            }
            if (internalPricingContextState?.pageMode === PageMode.Edit) {
                setsaveButtonText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Save", UIControlsText.Save));
            }
            setIsCountryDisabled(true);
        }
    }, [internalPricingContextState?.opportunityDeals[0]?.dealCustomerCountry]);

    useEffect(() => {

        //Getting all industries, verticals and vertical dropdown values
        Promise.allSettled([
            getCustomerIndustries()
        ]).then(function (responses: any) {
            let industries: IIndustryDropdown[] = [];
            for (let key in responses[0]?.value) {
                industries.push({
                    industryName: key,
                    verticals: responses[0]?.value[key]
                });
            }
            setIndustryDropdown(industries);
            if ((commonPPContextState?.pageMode === PageMode.Edit || internalPricingContextState?.pageMode === PageMode.Edit) && props.savedCustomer?.isManaged === false) {
                var industry = props.savedCustomer?.industry || "";
                var vertical = props.savedCustomer?.vertical || "";
                var VerticalCategory = props.savedCustomer?.verticalCategory || "";
                let industryOption = industries.find(x => x.industryName === industry);
                var verticals: IVerticalDropdown[] = [];

                if (industryOption) {
                    verticals = populateVerticalfromIndustry(industryOption);
                }

                let selectedvertical = verticals.find(x => x.verticalName == vertical);
                if (selectedvertical) {
                    setVerticalCategoriesDropdown(selectedvertical.verticalCategories);
                }

                setIndustry(industry);
                setVertical(vertical);
                setVerticalCategory(VerticalCategory);
                setCustomerName(props.savedCustomer?.legalEntityName || "");
                setCustomerLocalName(props.savedCustomer?.organizationNameInLocalLanguage || "");
                var address = props.savedCustomer?.address || "";
                var allAddress = address.split(" ");
                if (allAddress[0]) {
                    setAddressLine1(allAddress[0])
                }
                if (allAddress[1]) {
                    setCity(allAddress[1])
                }
                if (allAddress[2]) {
                    setState(allAddress[2])
                }
                if (allAddress[3]) {
                    setPostalCode(allAddress[3])
                }
                setWebPageURL(props.savedCustomer?.webPageURL || "");
                setEmailDomain(props.savedCustomer?.emailDomain || "");
            }
        });
    }, []);

    /**
     * customer name input change  function
     * if local name same as english ios checked making necessary changes
     * @param event 
     */
    const handleInputCustomerName = (event: any) => {
        let val = event.target.value;
        setCustomerName(val);
        setCustomerNameRequired("");
        if (isCustomerNameChecked) {
            setCustomerLocalName(val);
        }
        if (checkInput(val)) {
            if (event.target.value.length <= parseInt(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Text500", UIControlsText.Text500.toString()))) {
                setEnableCloneEnglishName(true);
            }
            else {
                setCustomerNameRequired(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "Max500CharErrorMessage", UIControlsText.Max500CharErrorMessage))
            }
        }
        else {
            setCustomerNameRequired(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "CustomerNameEnglishValidationMsg", ErrorMessages.CustomerNameEnglishValidationMsg))
        }
        if (event.target.value.length == 0) {
            setIsCustomerNameChecked(false);
            setEnableCloneEnglishName(false);
        }
    }

    const checkInput = (name: string) => {
        var alphaNumeric = /^(?:[\d,\/().]*[a-zA-Z][a-zA-Z\d,\/(). -]*)?$/;
        if (alphaNumeric.test(name)) {
            return true;
        }
        return false;
    }


    /**
     * on local customer name changes checking length and showing error message
     * @param event 
     */
    const handleInputCustomerLocalNameName = (event: any) => {
        if (event.target.value.length <= parseInt(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Text500", UIControlsText.Text500.toString()))) {
            setCustomerLocalName(event.target.value);
            setCustomerLocalNameRequired("");
        }
        else {
            setCustomerLocalNameRequired(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "Max500CharErrorMessage", UIControlsText.Max500CharErrorMessage))
        }
    }

    /**
     * on country dropdown change event , setting to local variable 
     * and removing error message 
     * @param event 
     * @param option 
     */
    const onCountryChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        setCountry(option?.key.toString());
        setSelectedCountry(option);
        setCountryRequired("");
    }

    /**
     * on industry change setting industry and, fetching related verticals of it
     * @param event 
     * @param option 
     */
    const onIndustryChange = (event?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        setIndustry(option?.key.toString());
        setVerticalDropdown([])
        setIndustryRequired("");
        setVerticalCategory(null);
        setVertical(null);
        let industry = industryDropdown.find(x => x.industryName === option?.key.toString());
        if (industry) {
            populateVerticalfromIndustry(industry);
        }
        setVerticalCategoriesDropdown([]);
    }
    //populate vertical based on industry
    const populateVerticalfromIndustry = (industry: IIndustryDropdown): IVerticalDropdown[] => {
        let verticals: IVerticalDropdown[] = [];
        if (industry) {
            for (let key in industry.verticals) {
                verticals.push({
                    verticalName: key,
                    verticalCategories: industry.verticals[key]
                });
            }
            setVerticalDropdown(verticals);
        }
        return verticals;
    }

    /**
     * on vertical change setting vertical anf fetching related vertical categories
     * @param event 
     * @param option 
     */
    const onVerticalChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        setVertical(option?.key.toString());
        setVerticalCategory(null);
        setVerticalRequired("");
        let vertical = verticalDropdown.find(x => x.verticalName == option?.key.toString());
        if (vertical) {
            setVerticalCategoriesDropdown(vertical.verticalCategories);
        }
    }

    /**
     * on vertical category change setting selected vertical
     * @param event 
     * @param option 
     */
    const onVerticalCategoryChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        setVerticalCategory(option?.key.toString());
        setVerticalCategoryRequired("");
    }

    /**
     * on address line input setting and validating address line
     * @param event 
     */
    const onAddressLineChange = (event: any) => {
        setAddressLine1Required("");
        if (event.target.value.length <= parseInt(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Text200", UIControlsText.Text200.toString()))) {
            setAddressLine1(event.target.value);
        }
        else {
            setAddressLine1Required(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "Max200CharErrorMessage", UIControlsText.Max200CharErrorMessage));
        }

    }

    /**
     * on city input setting and validating
     * @param event 
     */
    const onCityChange = (event: any) => {
        setCityRequired("");
        let val = event.target.value;
        if (val.length <= parseInt(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Text100", UIControlsText.Text100.toString()))) {
            setCity(event.target.value);
        }
        else {
            setCityRequired(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "Max100CharErrorMessage", UIControlsText.Max100CharErrorMessage));
        }
    }

    /**
    * on state input setting and validating
    * @param event 
    */
    const onStateChange = (event: any) => {
        setStateRequired("");
        let val = event.target.value;
        if (val.length <= parseInt(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Text100", UIControlsText.Text100.toString()))) {
            setState(event.target.value);
        }
        else {
            setCityRequired(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "Max100CharErrorMessage", UIControlsText.Max100CharErrorMessage));
        }
    }

    /**
    * on postal code input setting and validating
    * @param event 
    */
    const onPostalCodeChange = (event: any) => {
        if (event.target.value.length == 0) {
            setPostalCode(event.target.value);
        }
        else if (event.target.value.length <= parseInt(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Text100", UIControlsText.Text100.toString()))) {

            setPostalCode(event.target.value);
            setPostalCodeRequired("");
        }
        else {
            setPostalCodeRequired(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "Max100CharErrorMessage", UIControlsText.Max100CharErrorMessage));
        }
    }

    /**
    * on web page url input setting and validating
    * @param event 
    */
    const onWebpageUrlChange = (event: any) => {
        if (event.target.value.length <= parseInt(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Text200", UIControlsText.Text200.toString()))) {
            setWebPageURL(event.target.value);
            setWebPageUrlRequired("");
            setWebPageUrlPattern("");
        }
        else {
            setWebPageUrlRequired(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Max200CharErrorMessage", UIControlsText.Max200CharErrorMessage));
        }
    }

    /**
     *  on email domain input setting and validating
     * @param event 
     */
    const onEmailDomainChange = (event: any) => {
        if (event.target.value.length <= parseInt(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Text100", UIControlsText.Text100.toString()))) {
            setEmailDomain(event.target.value);
            setEmailDomainPattern("");
        }
        else {
            setWebPageUrlRequired(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "Max100CharErrorMessage", UIControlsText.Max100CharErrorMessage));
        }
    }

    /**
     * 
     * @returns on submit validating the add customer form
     */
    const validate = () => {
        let errorMessages: string[] = [];
        //if already existing error messages are there pushing them to error messages array
        if (countryRequired) {
            errorMessages.push(countryRequired);
        }
        if (CustomerNameRequired) {
            errorMessages.push(CustomerNameRequired);
        }
        if (CustomerLocalNameRequired) {
            errorMessages.push(CustomerLocalNameRequired);
        }
        if (cityRequired) {
            errorMessages.push(cityRequired);
        }
        if (postalCodeRequired) {
            errorMessages.push(postalCodeRequired);
        }
        if (addressLine1Required) {
            errorMessages.push(addressLine1Required);
        }
        if (stateRequired) {
            errorMessages.push(stateRequired);
        }

        //checking if values are null or not and pushing error messages to array
        if (!country) {
            errorMessages.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "CountryRequired", ErrorMessages.CountryRequired));
            setCountryRequired(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "CountryRequired", ErrorMessages.CountryRequired));
        }
        if (!customerLocalName) {
            errorMessages.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "CustomerLocalNameRequired", ErrorMessages.CustomerLocalNameRequired));
            setCustomerLocalNameRequired(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "CustomerLocalNameRequired", ErrorMessages.CustomerLocalNameRequired));

        }
        if (!customerName) {
            errorMessages.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "CustomerNameRequired", ErrorMessages.CustomerNameRequired));
            setCustomerNameRequired(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "CustomerNameRequired", ErrorMessages.CustomerNameRequired));
        }
        if (!addressLine1) {
            errorMessages.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "AddressLine1Required", ErrorMessages.AddressLine1Required));
            setAddressLine1Required(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "AddressLine1Required", ErrorMessages.AddressLine1Required));
        }
        if (!city) {
            errorMessages.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "CityRequired", ErrorMessages.CityRequired));
            setCityRequired(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "CityRequired", ErrorMessages.CityRequired));
        }
        if (!state) {
            errorMessages.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "StateRequired", ErrorMessages.StateRequired));
            setStateRequired(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "StateRequired", ErrorMessages.StateRequired));
        }
        if (!postalCode) {
            errorMessages.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "PostalCodeRequired", ErrorMessages.PostalCodeRequired));
            setPostalCodeRequired(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "PostalCodeRequired", ErrorMessages.PostalCodeRequired));
        }
        if (!industry) {
            errorMessages.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "IndustryRequired", ErrorMessages.IndustryRequired));
            setIndustryRequired(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "IndustryRequired", ErrorMessages.IndustryRequired));
        }
        if (!vertical) {
            errorMessages.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "VerticalRequired", ErrorMessages.VerticalRequired));
            setVerticalRequired(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "VerticalRequired", ErrorMessages.VerticalRequired));
        }
        if (!verticalCategory) {
            errorMessages.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "VerticalCategoryRequired", ErrorMessages.VerticalCategoryRequired));
            setVerticalCategoryRequired(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "VerticalCategoryRequired", ErrorMessages.VerticalCategoryRequired));
        }
        if (webPageURL && !isValidURL(webPageURL)) {
            errorMessages.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "WebPageURLpattern", ErrorMessages.WebPageURLpattern));
            setWebPageUrlPattern(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "WebPageURLpattern", ErrorMessages.WebPageURLpattern));
        }

        if (emailDomain && !isValidEmailDomain(emailDomain)) {
            errorMessages.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "EmailPattern", ErrorMessages.EmailPattern));
            setEmailDomainPattern(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "EmailPattern", ErrorMessages.EmailPattern));
        }
        return errorMessages;
    }

    /**
     * validating url format
     * @param url 
     * @returns {boolean}
     */
    const isValidURL = (url: string) => {
        var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return (res !== null)
    };

    /**
     * 
     * @param email validating email domain
     * @returns {boolean}
     */
    const isValidEmailDomain = (email: string) => {
        var res = email.match(/^@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return (res !== null)
    }

    /**
     * removing trailing spaces of customer name
     * @param value 
     * @returns {string}
     */
    const trimCustomerNameEnglish = (value: string) => {
        setCustomerName(customerName.trim());
        if (value.trim().length == 0) {
            return "";
        }
        else if (checkInput(value)) {
            setCustomerNameRequired("");
        }
        return;
    }


    /**
    * removing trailing spaces of customer local name
    * @param value 
    * @returns {string}
    */
    const trimCustomerNameLocal = (value: string) => {
        setCustomerLocalName(customerLocalName.trim());
        if (value.trim().length == 0) {
            return "";
        }
        else if (!checkInput(value)) {
            setCustomerLocalNameRequired("");
        }
        return;
    }

    /**
    * removing trailing spaces of customer address
    * @param value 
    * @returns {string}
    */
    const trimCustomerAddress = (value: string) => {
        setAddressLine1(addressLine1.trim());
        if (value.trim().length == 0) {
            return "";
        }
        else {
            setAddressLine1Required("");
        }
        return;
    }

    /**
        * removing trailing spaces of customer state
        * @param value 
        * @returns {string}
        */
    const trimState = (value: string) => {
        setState(state.trim());
        if (value.trim().length == 0) {
            return "";
        }
        else {
            setStateRequired("");
        }
        return;
    }

    /**
    * removing trailing spaces of customer city
    * @param value 
    * @returns {string}
    */
    const trimCity = (value: string) => {
        setCity(city.trim());
        if (value.trim().length == 0) {
            return "";
        }
        else {
            setCityRequired("");
        }
        return;
    }

    const [toggleCustomerAdd, setToggleCustomerAdd] = useState<boolean>(true);

    /**
     * on submit validating form and sending details to API
     * @returns 
     */
    const onSubmit = async () => {
        let errors = validate();
        if (errors.length > 0) {
            return;
        }
        setToggleCustomerLoading(true);
        setToggleCustomerAdd(false);
        let request: IUnmanagedCustomer = {
            customerId: "00000000-0000-0000-0000-000000000000",
            legalEntityName: customerName,
            organizationNameInLocalLanguage: customerLocalName,
            country: selectedCountry ? selectedCountry.text : "",
            countryId: selectedCountry ? selectedCountry.key.toString() : null,
            addressLine1: addressLine1,
            city: city,
            stateProvince: state,
            postalCode: postalCode.replaceAll("_", ""),
            industry: industry,
            vertical: vertical,
            verticalCategory: verticalCategory,
            webPageURL: webPageURL,
            emailDomain: emailDomain,
            localNameSameAsEnglish: isCustomerNameChecked,
            saveAsNewRecord: false,
            tpid: 0,
            crmAccountId: "",
            customerType: 0,
            segment: "",
            unitsCommitted: 0,
        }

        if ((commonPPContextState?.pageMode === PageMode.Edit || internalPricingContextState?.pageMode === PageMode.Edit) && props.savedCustomer?.customerId != undefined && props.savedCustomer?.isManaged === false) {
            var customer = request;
            customer.id = props.savedCustomer?.customerId;
            customer.customerId = props.savedCustomer?.customerId || ""
            await updateCustomer(customer)
        }
        else {
            await callCreateCustomer(request);
        }
    }

    const [duplicateCustomers, setDuplicateCustomers] = useState<IUnmanagedCustomer[]>([]);

    /**
     *sending form details to API and setting duplicate customer if any
     * showing message of save os success
     * @param request 
     */
    const callCreateCustomer = async (request: IUnmanagedCustomer) => {

        let res: any = await nextGenService.createUnmanagedCustomer(request);

        if (res && res.status === 200) {
            setToggleCustomerLoading(false);

            if (res.data.isSuccess) {
                // setToggleCustomerNewRecord(true);
                request.customerId = res.data.customerId;
                request.isManaged = false;
                setSelectedCustomer(request);
                setToggleCustomerHardDuplicate(false);
                setToggleCustomerSoftDuplicate(false);
                let legalEntity: ILegalEntity = {
                    country: request.country,
                    customerId: request.customerId,
                    legalEntityName: request.legalEntityName,
                    tpid: request.tpid ? request.tpid : 0,
                    crmAccountId: "",
                    customerType: 0,
                    segment: "",
                    unitsCommitted: 0,
                    isManaged: request.isManaged,
                    vertical: request.vertical,
                    verticalCategory: request.verticalCategory

                }
                props.onSubmitToPricingProgramCallBack && props.onSubmitToPricingProgramCallBack(legalEntity);

            }
            else if (res.data.isHardCopy) {
                setDuplicateCustomers(res.data.duplicateCustomers);
                setToggleCustomerAdd(false);
                customerSearchGridConfig.kendoGridProps.data = [...res.data.duplicateCustomers];
                setCustomerSearchGridConfig({ ...customerSearchGridConfig });
                setToggleCustomerHardDuplicate(true);
            }
            else {
                setDuplicateCustomers(res.data.duplicateCustomers);

                setToggleCustomerAdd(false);
                customerSearchGridConfig.kendoGridProps.data = [...res.data.duplicateCustomers];
                setCustomerSearchGridConfig({ ...customerSearchGridConfig });
                setToggleCustomerSoftDuplicate(true);
            }
        }
        else if (res.status === 422) {
            setShowMessageBar(true);
            setToggleCustomerLoading(false);
            let error = "";
            for (let key in res.data) {
                error = error + res.data[key].ruleValidationResults[0].error;
            }
            setErrorMessage(error);
            setToggleCustomerAdd(true);
        }
        else {
            setShowMessageBar(true);
            setToggleCustomerLoading(false);
            let error = "";
            for (let key in res.data) {
                error = error + res.data[key].ruleValidationResults[0].error;
            }
            setErrorMessage(error);
        }
    }

    const updateCustomer = async (request: IUnmanagedCustomer) => {

        let res: any = await nextGenService.updateUnmanagedCustomer(request);

        if (res && res.status === 200) {
            setToggleCustomerLoading(false);

            if (res.data) {
                request.isManaged = false;
                setSelectedCustomer(request);
                var address = request.addressLine1?.concat(" ").concat(request.city).concat(" ").concat(request.stateProvince).concat(" ").concat(request.postalCode);
                let legalEntity: ILegalEntity = {
                    country: request.country,
                    customerId: request.customerId,
                    id: request.customerId,
                    legalEntityName: request.legalEntityName,
                    tpid: request.tpid ? request.tpid : 0,
                    crmAccountId: "",
                    customerType: 0,
                    segment: "",
                    unitsCommitted: 0,
                    isManaged: request.isManaged,
                    verticalCategory: request.verticalCategory,
                    vertical: request.vertical,
                    emailDomain: request.emailDomain,
                    industry: request.industry,
                    organizationNameInLocalLanguage: request.organizationNameInLocalLanguage,
                    address: address,
                    webPageURL: request.webPageURL,

                }
                props.onSubmitToPricingProgramCallBack && props.onSubmitToPricingProgramCallBack(legalEntity);
            }
        }
    }

    /**
     * on soft duplicate if user click create customer
     */
    const createCustomer = () => {
        setToggleCustomerLoading(true);
        setToggleCustomerAdd(false);
        let request: IUnmanagedCustomer = {
            customerId: "00000000-0000-0000-0000-000000000000",
            legalEntityName: customerName,
            organizationNameInLocalLanguage: customerLocalName,
            country: selectedCountry ? selectedCountry.text : "",
            countryId: selectedCountry ? selectedCountry.key.toString() : null,
            addressLine1: addressLine1,
            city: city,
            stateProvince: state,
            postalCode: postalCode.replaceAll("_", ""),
            industry: industry,
            vertical: vertical,
            verticalCategory: verticalCategory,
            webPageURL: webPageURL,
            emailDomain: emailDomain,
            localNameSameAsEnglish: isCustomerNameChecked,
            saveAsNewRecord: true,
            tpid: 0,
            crmAccountId: "",
            customerType: 0,
            segment: "",
            unitsCommitted: 0,
        }
        callCreateCustomer(request);
    }

    /**
     * if local name is same as english is checked setting local name as customer name
     * @param ev 
     * @param isChecked 
     */
    const cloneName = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
        setCustomerLocalNameRequired("");
        if (isChecked) {
            setCustomerLocalName(customerName);
            setIsCustomerNameChecked(isChecked);
        }
        else {
            setCustomerLocalName("");
            setIsCustomerNameChecked(false);
        }
    }

    const resetMessageBarChoice = React.useCallback(() => setShowMessageBar(false), []);
    const resetMessageBarNote = React.useCallback(() => setShowNoteMessageBar(false), []);

    /**
     * radio button cell for hard/soft duplicate grid
     * @param selectedRowData 
     * @returns 
     */
    const onCustomCellRenderCallBack = (selectedRowData: any) => {
        let option = [{ key: selectedRowData.dataItem.customerId, text: "" }];
        return (
            <RadioGridCell
                cellId={selectedRowData.dataItem.customerId}
                options={option}
                selectedOption={selectedCustomerId}
                itemSelectionUpdate={onHandleIsManagedCallBack}
            />
        );
    }


    /**
     * submit selected  or created customer to pricing program callback
     */
    const submitToPricingProgram = () => {
        if (selectedCustomer) {
            let legalEntity: ILegalEntity = {
                country: selectedCustomer.country,
                customerId: selectedCustomer.customerId,
                legalEntityName: selectedCustomer.legalEntityName,
                tpid: selectedCustomer.tpid ? selectedCustomer.tpid : 0,
                crmAccountId: "",
                customerType: 0,
                segment: "",
                unitsCommitted: 0,
                isManaged: selectedCustomer.isManaged
            }
            props.onSubmitToPricingProgramCallBack && props.onSubmitToPricingProgramCallBack(legalEntity);
        }
        else {
            setErrorMessage(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "DuplicateGridCustomerSelectErrorMessage", GuidanceText.DuplicateGridCustomerSelectErrorMessage));
            setShowMessageBar(true);
        }
    }

    /**
     * on radio selected in hard/soft grid setting selected user 
     * @param cellId 
     */
    const onHandleIsManagedCallBack = (cellId: string): void => {
        if (!isNullOrEmpty(cellId)) {

            let index = duplicateCustomers.findIndex((obj => obj["customerId"] == cellId));
            if (index != -1) {
                //set selected customer data
                setSelectedCustomerId(cellId);
                setSelectedCustomer(duplicateCustomers[index]);
                setIsCustomerSelected(true);
                setToggleSubmissionActions(true);
            }
        }
    }

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const openDialog = () => {
        if (selectedCustomer) {
            setIsDialogOpen(true);
        }
        else {
            setErrorMessage(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "DuplicateGridCustomerSelectErrorMessage", GuidanceText.DuplicateGridCustomerSelectErrorMessage));
            setShowMessageBar(true);
        }
    };
    
    const setGuidanceText = () => {
        if (internalPricingContextState?.opportunityDeals[0]?.dealType?.name === DealType.OTLE.toString()) {
            setCreateUnmanagedCustomerGuidanceText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "CreateUnmanagedCustomerGuidanceTextOTLE", GuidanceText.CreateUnmanagedCustomerGuidanceTextOTLE))
        }
        else {
            setCreateUnmanagedCustomerGuidanceText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "CreateUnmanagedCustomerGuidanceText", GuidanceText.CreateUnmanagedCustomerGuidanceText))
        }
    }

    const handleWebPageNotAvaiable = () => {
        
        var webPageUrlInputField = document.querySelector("#" + getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "WebPageUrl", UIControlsText.WebPageUrl).replace(" ", ""));
        var webPageUrlLabel = document.querySelector('[for=' + '"' + webPageUrlInputField?.id + '"'+ ']');
        var webPageUrlLabelSiblingDiv = document.querySelector('label[for=' + '"' + webPageUrlInputField?.id + '"] ~ div');
        var className = "no-webpage-available";
        
        if(webPageUrlInputField?.hasAttribute("disabled")){
            webPageUrlInputField?.removeAttribute("disabled");
            webPageUrlInputField?.classList.remove(className);
            webPageUrlLabel?.classList.remove(className);
            webPageUrlLabelSiblingDiv?.classList.remove(className);
        }else{
            webPageUrlInputField?.setAttribute("disabled", "true");
            webPageUrlInputField?.classList.add(className);
            webPageUrlLabel?.classList.add(className);
            webPageUrlLabelSiblingDiv?.classList.add(className);
        }
    }

    return (
        <React.Fragment>
            <Stack>
                {showMessageBar &&
                    <div>
                        <MessageBar messageBarType={MessageBarType.error} onDismiss={resetMessageBarChoice} dismissButtonAriaLabel="Close" isMultiline={true}>
                            {errorMessage}
                        </MessageBar>
                    </div>
                }
            </Stack>
            {showNoteMessageBar &&
                <div>
                    <MessageBar messageBarType={MessageBarType.info} onDismiss={resetMessageBarNote} dismissButtonAriaLabel="Close" isMultiline={true}>
                        {toggleCustomerAdd ? createUnmanagedCustomerGuidanceText : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "CreateUnmanagedDuplicateCustomerGuidanceText", GuidanceText.CreateUnmanagedDuplicateCustomerGuidanceText) }
                    </MessageBar>
                </div>
            }
            {toggleCustomerAdd &&
                <Stack className="add-customer-form">
                    <div className="form-group row">
                        <div className="col-sm-6 ps-5">
                            <Dropdown
                                label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerCountry", UIControlsText.CustomerCountry)}
                                ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerCountry", UIControlsText.CustomerCountry)}
                                placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerCountry", UIControlsText.CustomerCountry)}
                                title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealCountryPlaceHolderText", UIControlsText.DealCountryPlaceHolderText)}
                                options={countriesDropDown.map((pt: ICountry) => ({ key: pt.countryId, text: pt.countryName } as IDropdownOption))}
                                styles={PageStyles.dropdownStyles}
                                className={_Styles.dropdown}
                                multiSelect={false}
                                onChange={onCountryChange}
                                required={true}
                                errorMessage={countryRequired}
                                selectedKey={country}
                                disabled={isCountryDisabled}
                            />
                        </div>
                        <div className="col-sm-6">
                            <Dropdown
                                label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Industry", UIControlsText.Industry)}
                                ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "IndustryPlaceholder", UIControlsText.IndustryPlaceholder)}
                                placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "IndustryPlaceholder", UIControlsText.IndustryPlaceholder)}
                                title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "IndustryPlaceholder", UIControlsText.IndustryPlaceholder)}
                                options={industryDropdown.map((pt: IIndustryDropdown) => ({ key: pt.industryName, text: pt.industryName } as IDropdownOption))}
                                styles={PageStyles.dropdownStyles}
                                className={_Styles.dropdown}
                                multiSelect={false}
                                onChange={onIndustryChange}
                                required={true}
                                errorMessage={industryRequired}
                                selectedKey={industry}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-6  ps-5">
                            <Label>
                                <TextField
                                    label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerNameEnglish", UIControlsText.CustomerNameEnglish)}
                                    ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "CustomerNameEnglishPlaceHolder", UIControlsText.CustomerNameEnglishPlaceHolder)}
                                    placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "CustomerNameEnglishPlaceHolder", UIControlsText.CustomerNameEnglishPlaceHolder)}
                                    styles={PageStyles.textFieldStylesOneColumn}
                                    resizable={false}
                                    validateOnLoad={false}
                                    defaultValue={customerName}
                                    onChange={handleInputCustomerName}
                                    required={true}
                                    errorMessage={CustomerNameRequired}
                                    value={customerName}
                                    onGetErrorMessage={trimCustomerNameEnglish}
                                    validateOnFocusOut={true}
                                />

                            </Label>
                        </div>

                        <div className="col-sm-6">
                            <Dropdown
                                label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Vertical", UIControlsText.Vertical)}
                                ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "VerticalPlaceholder", UIControlsText.VerticalPlaceholder)}
                                placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "VerticalPlaceholder", UIControlsText.VerticalPlaceholder)}
                                title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "VerticalPlaceholder", UIControlsText.VerticalPlaceholder)}
                                options={verticalDropdown.map((pt: IVerticalDropdown) => ({ key: pt.verticalName, text: pt.verticalName } as IDropdownOption))}
                                styles={PageStyles.dropdownStyles}
                                className={_Styles.dropdown}
                                multiSelect={false}
                                onChange={onVerticalChange}
                                required={true}
                                errorMessage={verticalRequired}
                                selectedKey={vertical}
                                disabled={verticalDropdown.length === 0}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div  className="col-sm-6  ps-5">
                            <Label>
                                <TextField
                                    label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerNameLocalLanguage", UIControlsText.CustomerNameLocalLanguage)}
                                    ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "CustomerNameLocalLanguagePlaceHolder", UIControlsText.CustomerNameLocalLanguagePlaceHolder)}
                                    placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "CustomerNameLocalLanguagePlaceHolder", UIControlsText.CustomerNameLocalLanguagePlaceHolder)}
                                    styles={PageStyles.textFieldStylesOneColumn}
                                    resizable={false}
                                    validateOnLoad={false}
                                    disabled={isCustomerNameChecked}
                                    onChange={handleInputCustomerLocalNameName}
                                    required={true}
                                    errorMessage={CustomerLocalNameRequired}
                                    value={customerLocalName}
                                    onGetErrorMessage={trimCustomerNameLocal}
                                    validateOnFocusOut={true}
                                />
                                <div style={PageStyles.paddingTop8px}>
                                    <Checkbox disabled={!enableCloneEnglishName} className="createCustomerCheckBox" styles={PageStyles.textFieldStylesOneColumn} label="Same as Customer Name (English)" onChange={cloneName} checked={isCustomerNameChecked} />
                                </div>
                            </Label>

                        </div>
                        <div className="col-sm-6">
                            <Dropdown
                                label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "VerticalCategory", UIControlsText.VerticalCategory)}
                                ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "VerticalCategoryPlaceholder", UIControlsText.VerticalCategoryPlaceholder)}
                                placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "VerticalCategoryPlaceholder", UIControlsText.VerticalCategoryPlaceholder)}
                                title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "VerticalCategoryPlaceholder", UIControlsText.VerticalCategoryPlaceholder)}
                                options={verticalCategoriesDropdown.map((pt: string) => ({ key: pt, text: pt } as IDropdownOption))}
                                styles={PageStyles.dropdownStyles}
                                className={_Styles.dropdown}
                                multiSelect={false}
                                onChange={onVerticalCategoryChange}
                                required={true}
                                errorMessage={verticalCategoryRequired}
                                selectedKey={verticalCategory}
                                disabled={verticalCategoriesDropdown.length === 0}
                            />
                        </div>

                    </div>
                    <div className="form-group row">
                        <div className="col-sm-6 ps-5">
                            <Label>
                                <TextField
                                    label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddressLine1", UIControlsText.AddressLine1)}
                                    ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "AddressLine1PlaceHolder", UIControlsText.AddressLine1PlaceHolder)}
                                    placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "AddressLine1PlaceHolderText", UIControlsText.AddressLine1PlaceHolderText)}
                                    styles={PageStyles.textFieldStylesOneColumn}
                                    resizable={false}
                                    validateOnLoad={false}
                                    onChange={onAddressLineChange}
                                    required={true}
                                    errorMessage={addressLine1Required}
                                    value={addressLine1}
                                    onGetErrorMessage={trimCustomerAddress}
                                    validateOnFocusOut={true}
                                />
                            </Label>
                        </div>
                        <div className="col-sm-6">
                            <Label>
                                <TextField
                                    label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "City", UIControlsText.City)}
                                    ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "CityPlaceHolder", UIControlsText.CityPlaceHolder)}
                                    placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "CityPlaceHolderText", UIControlsText.CityPlaceHolderText)}
                                    styles={PageStyles.textFieldStylesOneColumn}
                                    resizable={false}
                                    validateOnLoad={false}
                                    onChange={onCityChange}
                                    required={true}
                                    errorMessage={cityRequired}
                                    value={city}
                                    onGetErrorMessage={trimCity}
                                    validateOnFocusOut={true}
                                />
                            </Label>
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-sm-6 ps-5">
                            <Label>
                                <TextField
                                    label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "State", UIControlsText.State)}
                                    ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "StatePlaceHolder", UIControlsText.StatePlaceHolder)}
                                    placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "StatePlaceHolderText", UIControlsText.StatePlaceHolderText)}
                                    styles={PageStyles.textFieldStylesOneColumn}
                                    resizable={false}
                                    validateOnLoad={false}
                                    onChange={onStateChange}
                                    required={true}
                                    errorMessage={stateRequired}
                                    value={state}
                                    onGetErrorMessage={trimState}
                                    validateOnFocusOut={true}
                                />
                            </Label>
                        </div>
                        <div className="col-sm-6">
                            <Label>
                                <TextField
                                    label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PostalCode", UIControlsText.PostalCode)}
                                    ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "PostalCodePlaceHolder", UIControlsText.PostalCodePlaceHolder)}
                                    placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "PostalCodePlaceHolder", UIControlsText.PostalCodePlaceHolder)}
                                    styles={PageStyles.textFieldStylesOneColumn}
                                    validateOnFocusOut
                                    resizable={false}
                                    validateOnLoad={false}
                                    onChange={onPostalCodeChange}
                                    required={true}
                                    errorMessage={postalCodeRequired}
                                    value={postalCode}
                                />
                            </Label>
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-sm-6  ps-5">
                            <Label>
                                <TextField
                                    id={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "WebPageUrl", UIControlsText.WebPageUrl).replace(" ", "")}
                                    label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "WebPageUrl", UIControlsText.WebPageUrl)}
                                    ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "WebPageUrlPlaceHolder", UIControlsText.WebPageUrlPlaceHolder)}
                                    placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "WebPageUrlPlaceHolderText", UIControlsText.WebPageUrlPlaceHolderText)}
                                    styles={PageStyles.textFieldStylesOneColumn}
                                    validateOnFocusOut
                                    resizable={false}
                                    validateOnLoad={false}
                                    onChange={onWebpageUrlChange}
                                    errorMessage={webPageUrlRequired || webPageUrlPattern}
                                    value={webPageURL}
                                />
                            </Label>
                        </div>
                        <div className="col-sm-6">
                            <Label>
                                <TextField
                                    label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailDomain", UIControlsText.EmailDomain)}
                                    ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "EmailDomainPlaceHolder", UIControlsText.EmailDomainPlaceHolder)}
                                    placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "EmailDomainPlaceHoldertText", UIControlsText.EmailDomainPlaceHoldertText)}
                                    styles={PageStyles.textFieldStylesOneColumn}
                                    validateOnFocusOut
                                    resizable={false}
                                    validateOnLoad={false}
                                    onChange={onEmailDomainChange}
                                    errorMessage={emailDomainPattern}
                                    value={emailDomain}
                                />
                            </Label>
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-sm-6  ps-5">
                            <Label>
                                <Checkbox
                                    id={getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "WebpageNotAvailable", UIControlsText.WebpageNotAvailable).replaceAll(" ", "")}
                                    label={getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "WebpageNotAvailable", UIControlsText.WebpageNotAvailable)}
                                    ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "WebpageNotAvailable", UIControlsText.WebpageNotAvailable)}
                                    styles={PageStyles.textFieldStylesOneColumn}
                                    className={"createCustomerCheckBox"}
                                    onChange={handleWebPageNotAvaiable}
                                />
                            </Label>
                        </div>
                    </div>
                </Stack>}
            {toggleCustomerLoading &&
                <React.Fragment>
                    <hr></hr>
                    <span className="customer-load">{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "CreateUnmanagedCustomerSavingText", GuidanceText.CreateUnmanagedCustomerSavingText)}</span>
                </React.Fragment>
            }
            {(toggleCustomerSoftDuplicate || toggleCustomerHardDuplicate) &&
                <div>
                     {toggleCustomerSoftDuplicate && 
                        <p className="mt-2 font-italic paragraph-color p-3">{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "CreateUnmanagedCustomerSoftDuplicateText", GuidanceText.CreateUnmanagedCustomerSoftDuplicateText)}</p>
                    }
                    {toggleCustomerHardDuplicate &&
                        <p className="mt-2 font-italic paragraph-color p-3">{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "CreateUnmanagedCustomerHardDuplicateText", GuidanceText.CreateUnmanagedCustomerHardDuplicateText)}</p>
                    }

                    <NextGenKendoGrid {...{ kendoGridProps: { ...customerSearchGridConfig.kendoGridProps }, onCustomCellRenderCallBack: onCustomCellRenderCallBack }} />

                </div>
            }
            {toggleCustomerNewRecord &&
                <React.Fragment>
                    <hr></hr>
                    <p className="mt-2 create-customer-success paragraph-color p-3">{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "CreateUnmanagedCustomerSuccessText", GuidanceText.CreateUnmanagedCustomerSuccessText)}</p>

                </React.Fragment>
            }
            {isDialogOpen && (
                <Dialog style={_Styles} onClose={closeDialog}>
                    <>
                        <Stack horizontal={false} tokens={stackTokens} styles={stackStyles}>
                            <Stack>
                                <h6>{getRuleValidationMessage(sessionResourceData, MessageTypeText.PopupMessageText, "ConfirmSelectedDuplicateCustomer", UIControlsText.ConfirmSelectedDuplicateCustomer)}</h6>
                            </Stack>
                        </Stack>

                        <DialogActionsBar>
                            <Stack horizontal horizontalAlign="end" tokens={stackButtonTokens} styles={stackButtonStyles}>
                                <PrimaryButton
                                    text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseActionsDialogConfirmButton", UIControlsText.CaseActionsDialog.ConfirmButton)}
                                    ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseActionsDialogConfirmButton", UIControlsText.CaseActionsDialog.ConfirmButton)}
                                    onClick={submitToPricingProgram}
                                />
                                <DefaultButton
                                    text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseActionsDialogCancelButton", UIControlsText.CaseActionsDialog.CancelButton)}
                                    ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseActionsDialogCancelButton", UIControlsText.CaseActionsDialog.CancelButton)}
                                    onClick={closeDialog}
                                />
                            </Stack>
                        </DialogActionsBar>
                    </>
                </Dialog>
            )}
            <div className="add-new-customer-footer">
                {!toggleCustomerLoading &&
                    <div className="float-right ">
                        {(!toggleCustomerHardDuplicate && !toggleCustomerSoftDuplicate) && !toggleCustomerNewRecord &&
                            <>
                                <PrimaryButton onClick={onSubmit} className="me-2"> {saveButtonText}</PrimaryButton>
                                <DefaultButton onClick={props.onCloseCallBack} > {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)} </DefaultButton>
                            </>
                        }
                        {(toggleCustomerHardDuplicate || toggleCustomerSoftDuplicate) &&
                            <>
                                <PrimaryButton onClick={openDialog} className="me-2">  {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PricingProgramBtnText", UIControlsText.PricingProgramBtnText)} </PrimaryButton>
                                {!toggleCustomerHardDuplicate &&
                                    <PrimaryButton onClick={createCustomer} > {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitNewCustomerRecord", UIControlsText.SubmitNewCustomerRecord)} </PrimaryButton>
                                }
                            </>
                        }

                        {toggleCustomerNewRecord &&
                            <>
                                <PrimaryButton onClick={submitToPricingProgram} className="me-2"> {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitNewCustomerRecord", UIControlsText.SubmitNewCustomerRecord)} </PrimaryButton>
                                <DefaultButton onClick={props.onCloseCallBack} > {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)} </DefaultButton>
                            </>
                        }

                    </div >
                }
            </div>
        </React.Fragment>
    )
}

export default CreateUnmanagedCustomer;