//MenuItems and Dialogue Component
import React from "react";
import { IContextualMenuProps } from "@fluentui/react/lib/ContextualMenu";
import { IconButton, DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { useConst } from "@fluentui/react-hooks";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Checkbox, Stack, IStackTokens, TextField, Spinner } from "@fluentui/react";
import { _Styles, stackStyles, stackButtonStyles, stackGridStyles } from "../../../content/styles/Page.styles";
import { nextGenService } from '../../../services/NextGenService'
import { UIControlsText } from '../../../content/UIControlsText'
import { OpportunityDealStatusCodes } from '../../../models/OpportunityDealStatusCodes'
import { VerticalMenuProps } from "./VerticalMenuProps";
import { StateCode } from "../../../models/StateCode";
import { MenuOption } from "../../../models/MenuOption";
import { CssClassConstants } from "../../../content/CssClassConstants";
import { PageMode } from "../../../models/PageMode";
import { SessionStorageConsts } from "../../../models/SessionStorageConsts";
import { PageStyles } from "../../../pages/pricingprogram/common/content/Page.Styles";
import NextGenKendoGrid from "../grid/nextgenkendogrid";
import { IGridProps } from "../grid/IGridProps";
import { downloadDatabyInputFormat, getLoggedinUserRoleDetails,  getUserSession, readGridConfig } from "../common/util";
import { GridType } from "../../../models/GridType";
import { getUserRole } from "../common/util";
import {ApplicationConstants} from"../../../models/ApplicationConstants"
import { IUserRoleDetail } from "../../../models/IUserRoleDetail";
import { UserSubRole } from "../../../models/UserSubRole";
import { getRuleValidationMessage, getEntityDataFromSession } from "../common/util";
import { MessageTypeText } from "../../../models/MessageTypeText";
import { IDealRevertRequest } from "../../../models/IDealRevertRequest";

const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

const stackTokens: IStackTokens = { childrenGap: 24 };
const stackButtonTokens: IStackTokens = { childrenGap: 16 };
export const MenuItems: React.FunctionComponent<VerticalMenuProps> = (configData) => {
    // Reading data from CaseManagement component
    const userRoleDetails: IUserRoleDetail[] = getLoggedinUserRoleDetails();
    const [cpStrategicLettersConfig, setcpStrategicLettersConfig] =
        React.useState<IGridProps>(readGridConfig(GridType.CPStrategicLetters));
    const { dashBoardConfig, dashBoardData, selectedData } = configData;
    const [hideDialog, setHideDialog] = React.useState<boolean>(false);
    const [showMessageDialog, setShowMessageDialog] = React.useState<boolean>(false);
    const [checkedOption, setCheckedOption] = React.useState<number>(0);
    const [disabled, setDisabled] = React.useState(true);
    const [userCountries] = React.useState<string>(userRoleDetails.filter(country => country.countryId && country.countryId !== null && country.countryId !== "").map(function (userRoleDetail) { return userRoleDetail.countryId }).join(','));
    const [userAccounts] = React.useState<string>(userRoleDetails.filter(accounts => accounts.accountId && accounts.accountId !== null && accounts.accountId !== "").map(function (userRoleDetail) { return userRoleDetail.accountId }).join(','));
    const [isSubmitWorking, setIsSubmitWorking] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>("");

    const decisionLetterVisible = (): boolean => {
        var isvisible = false;

        if (userRoleDetails[0].userSubRole === UserSubRole.MSStoreUser) {
            isvisible = true;
        }
        else if ((userRoleDetails[0].userSubRole === UserSubRole.ADD || userRoleDetails[0].userSubRole === UserSubRole.ADR || userRoleDetails[0].userSubRole === UserSubRole.DMP)
            && userCountries !== "" && userAccounts !== "") {
            isvisible = true
        }
        return isvisible;
    }
    const [userRoles] = React.useState<string>(getUserRole());
    const [decisionLetterDownloading, setDecisionLetterDownloading] = React.useState<boolean>(false);
    let url=window.location.href

    var userDetails=getUserSession()
    let fullName=""
    let email=""
    if (userDetails != null) {
        let loggedInUserInfo = JSON.parse(userDetails);
         fullName=loggedInUserInfo.firstName +','+ loggedInUserInfo.lastName
         email=loggedInUserInfo.email
    }
    
    // redirect to case submission view mode.
    const viewDetails = () => {
        window.location.href = "/managecase/cpstrategic?oppNumber=" + selectedData.opportunityName
        sessionStorage.setItem(SessionStorageConsts.pageMode, PageMode.View);
    };
    //redirect to case submission edit mode.
    const editCase = () => {
        window.location.href = "/managecase/cpstrategic?oppNumber=" + selectedData.opportunityName
        sessionStorage.setItem(SessionStorageConsts.pageMode, PageMode.Edit);
    };

    // Methods for Showing Dialogue Component
    const revertDialogue = () => {
        setCheckedOption(MenuOption.Revert);
        setHideDialog(true);
    };
    const expireDialogue = () => {
        setCheckedOption(MenuOption.Expire);
        setHideDialog(true);
    };
    const deleteDialogue = () => {
        setCheckedOption(MenuOption.Delete);
        setHideDialog(true);
    };
    const lettersDialogue = async () => {
        var userRole=getUserRole();
        var userSubRole = userRole.split(",");
        setCheckedOption(MenuOption.Letters);
        let response = await nextGenService.getCPStrategicLettersByOpportunityDealId(selectedData.id,userSubRole[0] )
        if(response.data.length!==0){
            let cpStrategicConfig = { ...cpStrategicLettersConfig };
            cpStrategicConfig.kendoGridProps.data = response.data;
            cpStrategicConfig.kendoGridProps.totalRecords = response.data.length;
            setcpStrategicLettersConfig({ ...cpStrategicConfig });
        }
        setHideDialog(true);
    };
    //Method to download case decision letter on context menu click
    const decisionLetterDownload = async () => {
        try {
            setDecisionLetterDownloading(true);
            const userRoleDetails: IUserRoleDetail[] = getLoggedinUserRoleDetails();
            const userSession = getUserSession();
                let loggedInUserInfo = JSON.parse(userSession);
                let roles = userRoles.split(",");
                let filter: any
                var filters: { field: any; operator: string; value: any; }[] = []
                filters.push(
                    {
                        "field": "name",
                        "operator": "eq",
                        "value": selectedData.name
                    },
                )
                filter = {
                    logic: "and", filters: [
                        {
                            "logic": "or",
                            "filters": filters
                        }
                    ]
                };
                const dashboardRequest = {
                    accountsIds: userRoleDetails.map(function (userRoleDetail: IUserRoleDetail) { return userRoleDetail.accountId }).join(','),
                    userRoles: roles.join(","),
                    email: loggedInUserInfo.email,
                    tpiDs: [],
                    filter: filter,
                    pageNumber: 1,
                    recordsToFetch: 1000,
                };
                console.log('menuItem', selectedData);
            let response = await nextGenService.getCaseLetters(selectedData.id, userRoleDetails, selectedData.dealType?.name, dashboardRequest)
            var FileName = "Case Decision - " + selectedData.opportunityName + ".pdf";
            downloadDatabyInputFormat(FileName, response);
            setDecisionLetterDownloading(false);
        } catch (error) {
            setDecisionLetterDownloading(false);
        }
    }
    const submitData = () => {
        if (checkedOption === MenuOption.Revert) {
            setDisabled(true);
            return submitRevert();
        } else if (checkedOption === MenuOption.Expire) {
            setDisabled(true);
            return submitExpire();
        } else if (checkedOption === MenuOption.Delete) {
            setDisabled(true);
            return submitDelete();
        }
    };
    const closeMainDialogAndShowMessage = () =>{
        setShowMessageDialog(false);
        window.location.href = url;
    };
    const submitRevert = async () => {
        setIsSubmitWorking(true);
        setMessage(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RevertInProgress", UIControlsText.RevertInProgress));
        try {
            const revertRequest: IDealRevertRequest = {
                opportunityNumber: selectedData.opportunityName,
                opportunityDealId: selectedData.id,
                dealTypeName: selectedData.dealType?.name,
                fullName: fullName,
                email: email
            };

            await nextGenService.revertOpportunityDealStatus(revertRequest);
            setShowMessageDialog(true);
            setIsSubmitWorking(false);
            setHideDialog(false);
            setDisabled(true);
        } catch (error: any) {
            setMessage(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RevertError", UIControlsText.RevertError));
            setDisabled(false);
        }
    };

    const submitExpire = async () => {
        await nextGenService.setOpportunityDealStatus(selectedData.id, StateCode.Active, OpportunityDealStatusCodes.ForcedExpire,fullName,email)
        setHideDialog(false);
        setDisabled(true);
        window.location.href = url;
    };
    const submitDelete = async () => {
        await nextGenService.setOpportunityDealStatus(selectedData.id, StateCode.Active, OpportunityDealStatusCodes.Cancelled,fullName,email)
        setHideDialog(false);
        setDisabled(true);
        window.location.href = url;
    };

    // CheckBox OnChange Method
    const checkBoxOnChange = (e: any) => {
        if (e === true) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };
    //Rendering MenuItems Based On Status and UserRole
    let menuItems: any = []
    if (
        dashBoardConfig?.allowCaseView[0].userRoles?.some((r: any) => userRoles.includes(r)) &&
        dashBoardConfig?.allowCaseView[0].statusValues?.indexOf(
            dashBoardData.dataItem.statusCodeName
        ) !== -1
    ) {
        menuItems.push({
            key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ViewCaseText", UIControlsText.ViewCaseText),
            iconProps: { iconName: CssClassConstants.ViewIconName },
            text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ViewCaseText", UIControlsText.ViewCaseText),
            onClick: viewDetails,
            ariarole: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ViewCaseText", UIControlsText.ViewCaseText)
        });
    }
    if (
        dashBoardConfig?.allowCaseEdit[0].userRoles?.some((r: any) => userRoles.includes(r)) &&
        dashBoardConfig?.allowCaseEdit[0].statusValues?.indexOf(
            dashBoardData.dataItem?.statusCodeName
        ) !== -1 && dashBoardConfig?.allowCaseEdit[0].permissions?.indexOf(
            dashBoardData?.dataItem?.accessType?.find((x: string) => x === "Edit")
        ) !== -1 && (selectedData.closureDecisionString!==ApplicationConstants.EditPendingReview)
    ) {
        menuItems.push({
            key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EditCaseText", UIControlsText.EditCaseText),
            iconProps: { iconName: CssClassConstants.EditIconName },
            text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EditCaseText", UIControlsText.EditCaseText),
            onClick: editCase,
            ariarole: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EditCaseText", UIControlsText.EditCaseText)
        });
    }
    if (
        dashBoardConfig?.allowCaseRevert[0].userRoles?.some((r: any) => userRoles.includes(r)) &&
        dashBoardConfig?.allowCaseRevert[0].statusValues?.indexOf(
            dashBoardData.dataItem.statusCodeName
        ) !== -1
    ) {
        menuItems.push({
            key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RevertCaseText", UIControlsText.RevertCaseText),
            iconProps: { iconName: CssClassConstants.UndoIconName },
            text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RevertCaseText", UIControlsText.RevertCaseText),
            onClick: revertDialogue,
            ariarole: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RevertCaseText", UIControlsText.RevertCaseText)
        });
    }
    if (
        dashBoardConfig?.allowCaseDelete[0].userRoles?.some((r: any) => userRoles.includes(r)) &&
        dashBoardConfig?.allowCaseDelete[0].statusValues?.indexOf(
            dashBoardData.dataItem.statusCodeName
        ) !== -1
    ) {
        menuItems.push({
            key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeleteCaseText", UIControlsText.DeleteCaseText),
            iconProps: { iconName: CssClassConstants.DeleteIconName },
            text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeleteCaseText", UIControlsText.DeleteCaseText),
            onClick: deleteDialogue,
            ariarole: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeleteCaseText", UIControlsText.DeleteCaseText)
        });
    }
    if (
        dashBoardConfig?.allowCaseExpire[0].userRoles?.some((r: any) => userRoles.includes(r)) &&
        dashBoardConfig?.allowCaseExpire[0].statusValues?.indexOf(
            dashBoardData.dataItem.statusCodeName
        ) !== -1
    ) {
        menuItems.push({
            key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpireCaseText", UIControlsText.ExpireCaseText),
            iconProps: { iconName: CssClassConstants.IncidentTriangleIconName },
            text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpireCaseText", UIControlsText.ExpireCaseText),
            onClick: expireDialogue,
            ariarole: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpireCaseText", UIControlsText.ExpireCaseText)
        });
    }
    if (
        dashBoardConfig?.allowCaseLetters[0].userRoles?.some((r: any) => userRoles.includes(r)) &&
        dashBoardConfig?.allowCaseLetters[0].statusValues?.indexOf(
            dashBoardData.dataItem.statusCodeName
        ) !== -1
    ) {
        menuItems.push({
            key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CPStrategicLetters", UIControlsText.CPStrategicLetters),
            iconProps: { iconName: CssClassConstants.CPStrategicLetters },
            text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CPStrategicLetters", UIControlsText.CPStrategicLetters),
            onClick: lettersDialogue,
            ariarole: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CPStrategicLetters", UIControlsText.CPStrategicLetters)
        });
    }
    if (
        dashBoardConfig?.allowCaseDecisionLetter[0].userRoles?.some((r: any) => userRoles.includes(r)) &&
        dashBoardConfig?.allowCaseDecisionLetter[0].statusValues?.indexOf(
            dashBoardData.dataItem.statusCodeName
        )  !== -1  && decisionLetterVisible()
    ) {
        menuItems.push({
            key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CPCaseDecisionLetter", UIControlsText.CPCaseDecisionLetter),
            iconProps: { iconName: CssClassConstants.DownloadIconName },
            text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CPCaseDecisionLetter", UIControlsText.CPCaseDecisionLetter),
            onClick: decisionLetterDownload,
            ariarole: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CPCaseDecisionLetter", UIControlsText.CPCaseDecisionLetter)
        });
    }
    const rowMenuProps = useConst<IContextualMenuProps>(() => ({
        shouldFocusOnMount: true,
        items: menuItems,
    }));

    // Rendering CheckBox Based on Selected Menu Option
    const renderLabel =
        checkedOption === MenuOption.Revert
            ? getRuleValidationMessage(sessionResourceData, MessageTypeText.PopupMessageText, "RevertCheckBoxLabel", UIControlsText.RevertCheckBoxLabel)
            : checkedOption === MenuOption.Expire
                ? getRuleValidationMessage(sessionResourceData, MessageTypeText.PopupMessageText, "ExpireCheckBoxLabel", UIControlsText.ExpireCheckBoxLabel)
                : getRuleValidationMessage(sessionResourceData, MessageTypeText.PopupMessageText, "DeleteCheckBoxLabel", UIControlsText.DeleteCheckBoxLabel);
    // Dialogue Submit Button
    const dialogueSubmitButtonText =
        checkedOption === MenuOption.Expire
            ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpireSubmit", UIControlsText.ExpireSubmit)
            : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitText", UIControlsText.SubmitText)
    //Dialogue CancelButton 
    const dialogueCancelButtonText =
        checkedOption === MenuOption.Expire
            ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpireCancel", UIControlsText.ExpireCancel)
            : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)
    // Rendering CheckBox Based on Selected Menu Option
    const CheckBox = (
        <Checkbox
            ariaLabel={renderLabel}
            className={_Styles.DialogueCheckBox}
            label={renderLabel}
            onChange={(e, selectedOption) => {
                checkBoxOnChange(selectedOption);
            }}
        />
    );
    return (
        <>
            {/* MenuItems */}
            <IconButton
                aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MoreActionsText", UIControlsText.MoreActionsText)}
                menuIconProps={{ iconName: CssClassConstants.MoreVerticalIconName }}
                menuProps={rowMenuProps}
            />
            {decisionLetterDownloading &&
              <div>
                <Spinner />
              </div>
            }
            {/* Dialogue */}
            {showMessageDialog && (
                <Dialog style={_Styles} onClose={closeMainDialogAndShowMessage}>
                    {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RevertCaseMessage", UIControlsText.RevertCaseMessage)}
                    <DialogActionsBar>
                        <Stack horizontal horizontalAlign="end" tokens={stackButtonTokens} styles={stackButtonStyles}>
                            <Stack>
                                <DefaultButton
                                    title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "OkText", UIControlsText.OkText)}
                                    aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "OkText", UIControlsText.OkText)}
                                    text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "OkText", UIControlsText.OkText)}
                                    onClick={() => {
                                        closeMainDialogAndShowMessage();
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </DialogActionsBar>
                </Dialog>
            )}
            {hideDialog && (
                <Dialog style={_Styles} onClose={() => {
                    setHideDialog(false);
                }} >
                    <Stack styles={stackStyles}>
                        <>
                            {checkedOption === MenuOption.Revert && <h4> {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RevertOption", UIControlsText.RevertOption)}</h4>}
                            {checkedOption === MenuOption.Expire && <h4> {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpireOption", UIControlsText.ExpireOption)}</h4>}
                            {checkedOption === MenuOption.Delete && <h4> {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeleteOption", UIControlsText.DeleteOption)}</h4>}
                            {checkedOption === MenuOption.Letters && <h4> {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CPStrategicLetters", UIControlsText.CPStrategicLetters)} - <h5>{selectedData.opportunityName}</h5> </h4>}
                        </>
                    </Stack>
                    {checkedOption !== MenuOption.Letters ? (<>
                        <Stack horizontal={false} tokens={stackTokens} styles={stackStyles}>
                            <Stack horizontal tokens={stackTokens}>
                                <Stack>
                                    <TextField
                                        styles={PageStyles.textFieldStylesTwoColumn}
                                        aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseIdText", UIControlsText.CaseIdText)}
                                        label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseIdText", UIControlsText.CaseIdText)}
                                        readOnly
                                        value={selectedData.opportunityName}
                                    />
                                </Stack>
                                <Stack>
                                    <TextField
                                        styles={PageStyles.textFieldStylesTwoColumn}
                                        aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "StatusText", UIControlsText.StatusText)}
                                        label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "StatusText", UIControlsText.StatusText)}
                                        readOnly
                                        value={selectedData.statusCodeName}
                                    />
                                </Stack>
                            </Stack>
                            <Stack>{CheckBox}</Stack>
                        </Stack>
                        <DialogActionsBar>
                        {isSubmitWorking &&
                            <Stack>
                                <div className="revertMessage">
                                    {message}
                                </div>
                            </Stack>
                            }
                            <Stack horizontal horizontalAlign="end" tokens={stackButtonTokens} styles={stackButtonStyles}>
                                <PrimaryButton
                                    title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitCaseButtonTitle", UIControlsText.SubmitCaseButtonTitle)}
                                    aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitText", UIControlsText.SubmitText)}
                                    text={dialogueSubmitButtonText}
                                    onClick={submitData}
                                    disabled={disabled}
                                />
                                <DefaultButton
                                    title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelCaseButtonTitle", UIControlsText.CancelCaseButtonTitle)}
                                    aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)}
                                    text={dialogueCancelButtonText}
                                    onClick={() => {
                                        setHideDialog(false);
                                    }}
                                />
                            </Stack>
                        </DialogActionsBar>
                    </>) :
                        <>
                            <Stack horizontal tokens={stackTokens} styles={stackGridStyles}>
                                <NextGenKendoGrid
                                    {...{
                                        kendoGridProps: { ...cpStrategicLettersConfig.kendoGridProps }
                                    }}
                                />
                            </Stack>

                            <DialogActionsBar>
                                <Stack horizontal tokens={stackTokens} styles={stackButtonStyles}>
                                    <PrimaryButton
                                        title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}
                                        aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}
                                        text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}
                                        onClick={() => {
                                            setHideDialog(false);
                                        }}
                                    />
                                </Stack>
                            </DialogActionsBar>

                        </>}
                </Dialog>
            )}
        </>
    );
};
