import { DirectionalHint, Icon, mergeStyleSets, Stack, TooltipHost } from "@fluentui/react";
import { DefaultPageStyles } from "../../content/styles/Page.styles";
import { JavaScriptExtensions } from "../../infrastructure/JavaScriptExtensions";

const Tooltip: React.FunctionComponent<any> = (props?: any) => {

    const classNames = mergeStyleSets({
        deepSkyBlue: [DefaultPageStyles.iconBlueColor],
    });

    const calloutProps = { gapSpace: 0 };

    return (
        <>
            {props && !JavaScriptExtensions.isEmptyOrNullOrUndefined(props?.guidanceText) &&
                <TooltipHost
                    content={props?.guidanceText}
                    // Give the user more time to interact with the tooltip before it closes
                    closeDelay={200}
                    id={'TooltipHost' + props.id}
                    calloutProps={calloutProps}>
                    <Icon tabIndex={0} iconName="Info" ariaLabel={props?.guidanceText} className={classNames.deepSkyBlue} />
                </TooltipHost>
            }
        </>
    );
}

export default Tooltip;