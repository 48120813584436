import React, { useState, useEffect } from 'react';
import { IDropdownOption, Spinner, defaultDatePickerStrings, MessageBarType, mergeStyles, ActionButton, Label, MessageBar, addDays, ImageIcon, Icon, FontIcon } from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import { IStackTokens, Stack, StackItem } from '@fluentui/react/lib/Stack';
import { useInternalPricingContext } from '../context/internalpricing-context';
import { IProductType } from '../../../../models/IProductType';
import { ICountry } from '../../../../models/ICountry';
import { DealGeoCountryType } from '../../../../models/DealGeoCountryType';
import { CaseActionTypes } from '../../common/reducer/CaseActionTypes';
import { AffiliationType } from '../../../../models/AffiliationType';
import { ErrorMessages } from '../../../../content/ErrorMessages';
import { PageStyles } from '../../common/content/Page.Styles';
import { UIConfigIdentifierConstants, UIControlsText } from '../../../../content/UIControlsText';
import { DatePickerStyles, defaultColumnProps, DefaultPageStyles, _Styles } from '../../../../content/styles/Page.styles';
import { AffiliationTypeOptions, DealGeoCoverageOptions, SingleDealCoverageOption } from '../../../../services/StaticData';
import { ILegalEntity } from '../../../../models/ILegalEntity';
import { getDatePart, getsavedContext, getSelectedCountry, getUserRole, isFieldDisabled, isFieldVisible, getRuleValidationMessage, getEntityDataFromSession, getDealType, isValidEmailWithSeperator, isDateNotWithinThreshold, isNullOrEmpty, isValidEmailMicrosoftDomain } from "../../../../components/shared/common/util"
import { IPageState } from '../../common/state/IPageState';
import { PageMode } from '../../../../models/PageMode';
import { OpportunityDealStatusCodes } from '../../../../models/OpportunityDealStatusCodes';
import { CustomerType } from '../../../../models/CustomerType';
import { IProductFamily } from '../../../../models/IProductFamily';
import { ActionType } from '../../../../models/ActionType';
import { CssClassConstants } from '../../../../content/CssClassConstants';
import { Guid } from '../../../../infrastructure/Guid';
import { IAttachment } from '../../../../models/IAttachment';
import { IDealContact } from '../../../../models/IDealContact';
import { IKeyValue } from '../../../../models/IKeyValue';
import { IVertical } from '../../../../models/IVertical';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { nextGenService } from '../../../../services/NextGenService';
import { ArrayExtensions } from '../../../../infrastructure/ArrayExtensions';
import { ContactRoles } from '../../../../models/ContactRoles';
import Moment from 'moment';
import { IInternalPricingDealOverviewProps } from '../props/IInternalPricingDealOverviewProps';
import { UserSubRole } from '../../../../models/UserSubRole';
import { MessageTypeText } from '../../../../models/MessageTypeText';
import _ from 'lodash';
import { PageConstants } from '../../../pageconstants/Constants';
import { smallAlignmentTokens, stackContainerStyles, stackItemCustomerStyles,stackContainerStylesShift, stackStyles } from '../../PricingProgramForm/PricingProgramFormUtils';
import { IDealType } from '../../../../models/IDealType';
import { JavaScriptExtensions } from '../../../../infrastructure/JavaScriptExtensions';
import { UIControlType } from '../../../../models/UIControlType';
import { FormRenderingService, IComponent } from '../../../shared/Components/DynamicFormRendering';
import { useForm } from 'react-hook-form';
import { AttachmentDisplayMode } from '../../../../models/AttachmentDisplayMode';
import DealContact from '../../../../components/shared/deal-contact';
import Col from 'react-bootstrap/esm/Col';
import { Paper } from '@mui/material';
import InnerHTMLContainer from '../../../shared/ComponentTypes/InnerHTMLContainer';
import { GuidanceText } from '../../../../models/GuidanceText';
import { DealType } from '../../../../models/DealType';
import { cardHeaderIcon } from '../../../../hooks/accessiblity-hooks';

const stackTokens: IStackTokens = { childrenGap: 20 };

/**
 * CP Deal and OTLE - InternalPricingDealOverview Component.
 * @function component
 */
export function InternalPricingDealOverview(props: IInternalPricingDealOverviewProps) {
    const { register } = useForm({});
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const [isCountryMultiSelect, setIsCountryMultiSelect] = useState<boolean>(false);
    const [selectedCountries, setSelectedCountries] = useState<ICountry[]>([]);
    const [isAffiliationTypeOthersVisible, setIsAffiliationTypeOthersVisible] = useState<boolean>(false);
    const {internalPricingContextState, internalPricingContextDispatch} = useInternalPricingContext();
    const [oppdealId, setOppdealId] = useState<string>((internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].id) || "");
    const [dealContact, setDealContact] = useState<IDealContact[]>([]);
    const [contactRoles, setContactRoles] = useState<IKeyValue[]>([]);
    const savedAttachments = getsavedContext()?.attachments;
    const [attachmentList, setAttachmentList] = useState<Array<IAttachment | undefined>>(internalPricingContextState.attachments?.filter(x => x?.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachment", UIControlsText.Attachment)) || []);
    const [endCustomerAttachment, setEndCustomerAttachmentList] = useState<Array<IAttachment | undefined>>(internalPricingContextState.attachments?.filter(x => x?.source === "Proof of End Customer's Preference") || []);
    const [gridName, setGridName] = useState(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVTeamText", UIControlsText.DealVTeamText))
    const [dealVTeamText, setDealVTeamText] = useState(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealVTeamInfo", UIControlsText.DealVTeamInfo))    
    const [warningMessageForDealVolume, setWarningMessageForDealVolume] = useState<string>('');
    const [emailRecipientsValue, setEmailRecipientsValue] = useState<string | undefined>((internalPricingContextState?.opportunityChannelContacts && internalPricingContextState?.opportunityChannelContacts.find(t => t.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText))?.channelContactEmails) || "");
    const today = useConst(new Date(Date.now()));
    const [maxDateForExpirationField, setMaxDateForExpirationField] = useState<Date | null>(null);

    useEffect(() => {
        //Only for OTLE
        if (props?.internalPricingDealType === PageConstants.OTLEDealtype) {
            internalPricingContextDispatch({ type: CaseActionTypes.dealGeoCoverageChanged, boolValue: false });
            let otleDeal: IDealType = getDealType(PageConstants.OTLEDealtype);
            setMaxDateForExpirationField(addDays(today, otleDeal.defaultDurationInDays || 93));
        }
    }, [internalPricingContextDispatch, internalPricingContextState.opportunityDeals, props?.internalPricingDealType]) ;


    useEffect(() => {
        if (internalPricingContextState?.opportunityDeals[0]?.id) {
            setOppdealId(internalPricingContextState?.opportunityDeals[0]?.id);
        }

        if (internalPricingContextState?.opportunityDeals[0]?.id && internalPricingContextState.pageMode === PageMode.View) {
            var userRole = getUserRole();
            var userSubRole = userRole.split(",");
            if (userSubRole[0] === UserSubRole.ADD || userSubRole[0] === UserSubRole.ADR || userSubRole[0] === UserSubRole.DMP || userSubRole[0] === UserSubRole.MSStoreUser) {
                setGridName(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MicrosoftContacts", UIControlsText.MicrosoftContacts))
                setDealVTeamText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealInfo", UIControlsText.DealInfo))
            }
        }
    }, [internalPricingContextState?.opportunityDeals[0]?.id]);

    useEffect(() => {
        if (internalPricingContextState?.opportunityDeals && internalPricingContextState?.opportunityDeals[0]?.affiliationType == AffiliationType.Others) {
            setIsAffiliationTypeOthersVisible(true);
        }

        if (internalPricingContextState?.isMultiNational) {
            setIsCountryMultiSelect(internalPricingContextState?.isMultiNational);
        }

        if (internalPricingContextState?.opportunityDeals[0]?.geoCountries) {
            setSelectedCountries(internalPricingContextState?.opportunityDeals[0]?.geoCountries.filter(c => c.action !== ActionType.Delete));
        }
    }, [internalPricingContextState?.opportunityDeals[0]?.affiliationType, internalPricingContextState?.isMultiNational, internalPricingContextState?.opportunityDeals[0]?.geoCountries]);

    useEffect(() => {
        if (internalPricingContextState?.opportunityDeals[0].dealContacts) {
            setDealContact(internalPricingContextState?.opportunityDeals[0].dealContacts.filter((item) => item.action !== ActionType.Delete));
        }

        var contactRoles = sessionStorage.getItem(SessionStorageConsts.contactRoles);
        if (!contactRoles) {
            nextGenService.getContactRoleOptions()
                .then((response) => {
                    sessionStorage.setItem(SessionStorageConsts.contactRoles, JSON.stringify(response.data));
                    setContactRoles(response.data);
                });
        }
        else {
            setContactRoles(JSON.parse(contactRoles));
        }

        if (internalPricingContextState.attachments && internalPricingContextState.attachments.length > 0) {
            setAttachmentList(internalPricingContextState.attachments?.filter(x => x?.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachment", UIControlsText.Attachment)));
            setEndCustomerAttachmentList(internalPricingContextState.attachments?.filter(x => x?.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProofOfPreference", UIControlsText.ProofOfPreference)))
        }
    }, [sessionStorage.getItem(SessionStorageConsts.contactRoles), internalPricingContextState?.opportunityDeals[0].dealContacts, internalPricingContextState.attachments, internalPricingContextState.selectedPivot]);
    
    useEffect(() => {
        internalPricingContextState?.opportunityChannelContacts 
        && internalPricingContextState.opportunityChannelContacts?.length 
        && setEmailRecipientsValue(internalPricingContextState?.opportunityChannelContacts
                    .find(t => t.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText))
                    ?.channelContactEmails || "");
        
    }, [internalPricingContextState?.opportunityChannelContacts]);

    useEffect(() => {
        if (internalPricingContextState?.isMultiNational === true && internalPricingContextState?.opportunityDeals[0]?.geoCountries && internalPricingContextState?.opportunityDeals[0]?.geoCountries?.filter(c => c.action !== ActionType.Delete).length === 1) {
            internalPricingContextDispatch({ type: CaseActionTypes.missingFieldTab, inputArray: [getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText)] });
            internalPricingContextDispatch({ type: CaseActionTypes.validationErrors, inputArray: [getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "MultipleDealCountriesError", ErrorMessages.MultipleDealCountriesError)] });
        }
        else {
            internalPricingContextDispatch({ type: CaseActionTypes.missingFieldTab, inputArray: [] });
            internalPricingContextDispatch({ type: CaseActionTypes.validationErrors, inputArray: [] });
        }
    }, [internalPricingContextState?.isMultiNational, internalPricingContextState?.opportunityDeals[0]?.geoCountries && internalPricingContextState?.opportunityDeals[0]?.geoCountries])

    /**
     * DealCoverage change Callback function.if page is edit page mode and trying to change from multiple to single then don't allow
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {IDropdownOption} item Clicked Option from Dropdown.
     */
    const onDealCoverageChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            if (item.key === DealGeoCountryType.MultiNationalDeal) {
                setIsCountryMultiSelect(true);
                internalPricingContextDispatch({ type: CaseActionTypes.dealGeoCoverageChanged, boolValue: true });
            }
            else {
                setIsCountryMultiSelect(false);
                setSelectedCountries([]);
                internalPricingContextDispatch({ type: CaseActionTypes.dealGeoCoverageChanged, boolValue: false });
                internalPricingContextDispatch({ type: CaseActionTypes.dealCountryChanged, countries: { key: oppdealId, value: [] } });
            }
        }
    }

    /**
     * ProductGroup change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {IDropdownOption} item Clicked Option from Dropdown.
     */
    const onProductGroupChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            internalPricingContextDispatch({ type: CaseActionTypes.productGroupChanged, productGroupValue: item.key })
            if(item.key !== internalPricingContextState.productGroup){
                internalPricingContextDispatch({ type: CaseActionTypes.selectedProductFamilyChanged, products: { key: oppdealId, value: [] } });
            }
        }
    }

    /**
     * Country change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {IDropdownOption} item Clicked Option from Dropdown.
     */
    const onCountryChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            if (isCountryMultiSelect) {
                let newSelection = props.countries.find((country) => country.countryId == item.key) as ICountry;
                let saved = getsavedContext().opportunityDeals && getsavedContext().opportunityDeals[0].geoCountries?.find((country) => country.countryId == item.key);
                if (internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()) {
                    if (!item.selected) {
                        newSelection.action = ActionType.Delete
                    }
                    else if (item.selected) {
                        newSelection.action = ActionType.Add;
                    }
                }
                else {
                    newSelection.action = item.selected ? ActionType.Add : "";
                }
                setSelectedCountries((prevState: ICountry[]) => {
                    let newState = item.selected ? [...prevState, newSelection] : prevState.filter(key => key.countryId !== newSelection?.countryId);
                    let newList = saved ? [...prevState.filter(key => key.countryId !== newSelection?.countryId), newSelection] : newState;
                    internalPricingContextDispatch({ type: CaseActionTypes.dealCountryChanged, countries: { key: oppdealId, value: newList } });
                    return newState.filter(c => c.action !== ActionType.Delete);
                });
            }
            else {
                let newSelection = props.countries.find((country) => country.countryId == item.key) as ICountry;
                newSelection.action = ActionType.Add;
                setSelectedCountries((prevState: ICountry[]) => {
                    let newList = [newSelection];
                    if (internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()) {
                        let saved = getsavedContext().opportunityDeals[0].geoCountries?.map(c => {
                            c.action = ActionType.Delete;
                            return c
                        }) as ICountry[];
                        newList = [...newList, ...saved];
                    }
                    internalPricingContextDispatch({ type: CaseActionTypes.dealCountryChanged, countries: { key: oppdealId, value: newList } });
                    return [newSelection];
                });
            }
            if (!item.selected || !isCountryMultiSelect) {
                validateCountry(getSelectedCountry([item.key as string])[0]);
            }
        }
    };

    ///this function used to validate country selected/changed already added in legal entry or products selection list and alert user
    const validateCountry = (item: IDropdownOption): void => {
        if (internalPricingContextState.pageMode?.toLowerCase() === PageMode.Create.toLowerCase() ||
            internalPricingContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.Draft) {

            var isExist = false;
            if ((internalPricingContextState.opportunityDeals[0].dealCustomers || [])
                .filter((selectedItem: ILegalEntity) => selectedItem.country === item.text).length > 0) {
                isExist = true;
            }
            else if ((internalPricingContextState.opportunityDeals[0].products || [])
                .filter((selectedItem: IProductFamily) => selectedItem.country === item.text).length > 0) {
                isExist = true;
            }
            if (isExist) {
                alert(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "LegalEntityAndProductsSelectionErrorMessage", UIControlsText.LegalEntityAndProductsSelectionErrorMessage));
            }
        }
    }
    /**
     * AffiliationType change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {IDropdownOption} item Clicked Option from Dropdown.
     */
    const onAffiliationTypeChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            if (item.key === AffiliationType.Others) {
                setIsAffiliationTypeOthersVisible(true);
            }
            else {
                setIsAffiliationTypeOthersVisible(false);
            }
            internalPricingContextDispatch({ type: CaseActionTypes.dealAffiliationTypeChanged, dealDropDownValue: { key: oppdealId, value: item.key } });
            if (item.key === AffiliationType.AffiliateCompanies) {
                internalPricingContextDispatch({ type: CaseActionTypes.dealAffiliationTypeOtherChanged, dealInputValue: { key: oppdealId, value: "" } });
            }
        }
    }

    /**
     * Deal AffiliationType Others change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {string} newValue updated value from textField.
     */
    const onAffiliationTypeOthersChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        internalPricingContextDispatch({ type: CaseActionTypes.dealAffiliationTypeOtherChanged, dealInputValue: { key: oppdealId, value: newValue } })
    }

    if (internalPricingContextState.dataLoading) {
        return (
            <Stack>
                <div>
                    <Spinner label="Getting Data..." />
                </div>
            </Stack>
        );
    }
    //function to validate against saved country and enable / disable based on page mode and/or opp deal case status
    const isNonEditableCountry = (country: string): boolean => {
        var isDisabled = false;
        var PrimaryCountry = undefined;
        //in case of view page mode, return true to disable country option
        if (internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase()) {
            return true;
        }
        var savedSate = getsavedContext() as IPageState;
        if (internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase() && savedSate.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.PendingSecondaryReview) {
            return true;
        }

        if (internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()) {
            PrimaryCountry = internalPricingContextState.opportunityDeals[0].dealCustomers?.find(c => c.customerType === CustomerType.Primary)?.country;
        }
        if (internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase() &&
            savedSate.isMultiNational && internalPricingContextState.opportunityDeals[0].statusCode !== OpportunityDealStatusCodes.Draft
        ) {
            if (savedSate.opportunityDeals[0].geoCountries) {
                if (savedSate.opportunityDeals[0].geoCountries.filter(c => c.action !== ActionType.Delete).findIndex(x => x.countryName === country) !== -1) {
                    isDisabled = true;
                }
            }
        }
        else if (internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase() &&
            internalPricingContextState.opportunityDeals[0].statusCode !== OpportunityDealStatusCodes.Draft &&
            !internalPricingContextState.isMultiNational
        )
            isDisabled = true;
        //disable if isprimary selected else based on status.
        return PrimaryCountry === country ? true : isDisabled;
    }

    /**
    * Remove Deal Contact Callback function.
    * @method
    * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
    */
    const removeDealContact = (id?: string | undefined): void => {
        var newList = getDeletedContactItems(dealContact.filter((item) => item.id !== id));
        var exceptDelete = newList.filter((item) => item.action !== ActionType.Delete);
        setDealContact(exceptDelete);
        internalPricingContextDispatch({ type: CaseActionTypes.dealDetailsContactChanged, dealContacts: { key: oppdealId, value: newList } })
    }
    /**
     * Add/Update Deal Contact Callback function.
     * @method
     * @param {IDealContact} contact updated deal contact.
     */
    const getDeletedContactItems = (dealContancts: IDealContact[]): IDealContact[] => {
        var deletedContacts = dealContancts;
        if (internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].statusCode != OpportunityDealStatusCodes.Draft
            && internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()) {
            getsavedContext().opportunityDeals[0].dealContacts?.map((item: IDealContact) => {
                item.action = ActionType.Delete;
                var findItem = deletedContacts.find(x => x.id === item.id);
                if (findItem === undefined)
                    deletedContacts.push(item);
            });
        }
        return deletedContacts;
    }
    /**
     * Add/Update Deal Contact Callback function.
     * @method 
     * @param {IDealContact} contact updated deal contact.
     */
    const updateDealContact = (contact?: IDealContact): void => {
        var newList = getDeletedContactItems(dealContact);
        var updatedContact;
        /* eslint-disable */
        if (contact) {
            var idx = dealContact.findIndex((item) => item.id === contact?.id);
            if (internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()) {
                var savedContext = getsavedContext();
                if (savedContext.opportunityDeals[0].dealContacts?.findIndex((item) => item.id === contact?.id) !== -1) {
                    var savedindex = savedContext.opportunityDeals[0].dealContacts?.findIndex((item) => item.id === contact?.id);
                    if (savedContext.opportunityDeals[0].dealContacts && savedindex !== undefined) {
                        var saveditem = savedContext.opportunityDeals[0].dealContacts[savedindex];
                        if (saveditem.email !== contact.email || saveditem.firstName !== contact.firstName ||
                            saveditem.lastName !== contact.lastName || saveditem.contactType !== contact.contactType ||
                            saveditem.isCoOwner !== contact.isCoOwner) {
                            contact.action = ActionType.Update
                        }
                        else {
                            contact.action = saveditem.action
                        }
                    }
                    else {
                        contact.action = ActionType.Add
                    }
                }
                else {
                    contact.action = ActionType.Add
                }
                updatedContact = contact
                newList[idx] = updatedContact;
            }
            else {
                contact.action = ActionType.Add
                updatedContact = contact
                newList[idx] = updatedContact;
            }
        }
        setDealContact(newList.filter((item) => item.action !== ActionType.Delete));
        internalPricingContextDispatch({ type: CaseActionTypes.dealDetailsContactChanged, dealContacts: { key: oppdealId, value: newList } })
    }

    /**
     * DealName change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {string} newValue updated value from textField.
     */
    const onDealNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        internalPricingContextDispatch({ type: CaseActionTypes.dealNameInputChanged, dealInputValue: { key: oppdealId, value: newValue } })
    }

    /**
     * Deal Overview change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {string} newValue updated value from textField.
     */
    const onDealOverviewChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        internalPricingContextDispatch({ type: CaseActionTypes.dealOverviewInputChanged, dealInputValue: { key: oppdealId, value: newValue } })
    }

    /**
     * Additional Information Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {string} newValue updated value from textField.
     */
    const onAdditionalInfoChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        internalPricingContextDispatch({ type: CaseActionTypes.dealAdditionalInfoInputChanged, dealInputValue: { key: oppdealId, value: newValue } })
    }

    /**
     * Deal Volume change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {string} newValue updated value from textField.
     */
    const onDealVolumeChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        internalPricingContextDispatch({ type: CaseActionTypes.totalunitsTransactedChanged, dealVolume: { key: oppdealId, value: newValue ? parseInt(newValue) : 0 } });
    }

    /**
     * Deal Volume change Callback function.
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     */
    const onDealVolumeChangeSetWarning =  (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): string => {
        //Validation for OTLE
        if(props?.internalPricingDealType == PageConstants.OTLEDealtype) {
            if(internalPricingContextState?.productGroup && internalPricingContextState.opportunityDeals[0] && internalPricingContextState.opportunityDeals[0]?.id && internalPricingContextState.pageMode) {
                const dealQuantityThresholdForDealType : number = Number(sessionStorage.getItem(SessionStorageConsts.dealQuantityThresholdConfigs));
                let dealVolumeValue : number | undefined = internalPricingContextState.opportunityDeals[0]?.dealVolume;
                if(dealVolumeValue !== undefined) {
                    if(dealVolumeValue < dealQuantityThresholdForDealType) {
                        setWarningMessageForDealVolume(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "DealVolumeThresholdWarningForOTLE", ErrorMessages.DealVolumeThresholdWarningForOTLE));
                    }
                    else {
                        setWarningMessageForDealVolume("");
                    }
                }
                else {
                    setWarningMessageForDealVolume("");
                }
            }
        }
        return '';
    }

    /**
     * Deal vertical change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {IDropdownOption} item Clicked Option from Dropdown.
     */
    const onDealVerticalChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            internalPricingContextDispatch({ type: CaseActionTypes.dealVerticalChanged, dealDropDownValue: { key: oppdealId, value: item.key } })
        }
    }
    
    /**
     * Deal Expiration date change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {string} newValue updated value from textField.
     */
    const onExpirationDateJustification = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        internalPricingContextDispatch({ type: CaseActionTypes.dealDetailsExpirationDateJustificationChanged, dealInputValue: { key: oppdealId, value: newValue } })
    }

    /**
     * Exception Request change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {string} newValue updated value from textField.
     */
    const onDealRequestedExpirationDate = (newValue?: Date | null): void => {
        var formatedValue = Moment(newValue).format('YYYY-MM-DD');
        internalPricingContextDispatch({ type: CaseActionTypes.dealDetailsRequestedExpirationDateChanged, inputDate: { key: oppdealId, value: formatedValue } })
    }

    /**
     * Get TextField ErrorMessage.
     * @method
     * @param {string} value Text Box value.
     * @@returns {string} Error Message.
     */
    const getExpirationDateErrorMessage = (value: string): string => {
        if(JavaScriptExtensions.isNullOrUndfinedOrEmpty(value)) {
            return "";
        }

        let internalPricingDealType: IDealType = getDealType(PageConstants.OTLEDealtype);
        let minExpirationDateInDays : any = internalPricingDealType?.defaultDurationInDays;
        let error : string | undefined = isDateNotWithinThreshold(value, internalPricingDealType) || "";
        if(JavaScriptExtensions.isDefined(error) && !isNullOrEmpty(error)) {
            return error;
        }
        return internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase() ? '' :
            !value ? getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredFieldError", ErrorMessages.RequiredFieldError) : '';
    }

    /**
    * Textbox EmailCCNotification Change Callback function.
    * @method
    * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
    * @param {string} newValue updated value from textField.
    */
    const onEmailCCNotificationTextBoxChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(JavaScriptExtensions.isNullOrUndfinedOrEmpty(newValue)) {
            return;
        }
        setEmailRecipientsValue(event.currentTarget.value);
    }

    /**
    * Textbox EmailCCNotification Blur Callback function.
    * @method
    * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
    * @param {string} newValue updated value from textField.
    */
    const onEmailCCNotificationTextBoxBlur = (newValue?: string) => {
        if(JavaScriptExtensions.isNullOrUndfinedOrEmpty(newValue)) {
            return;
        }
        const updatedDealContacts = [];
        let error = isValidEmailMicrosoftDomain(newValue);
        if(error) {
            return error;
        }
        
        const isComplete = isValidEmailWithSeperator(newValue || "", ';');
        updatedDealContacts.push({ action: ActionType.Add, id: Guid.newGuid(), 
            source: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText), 
            channelContactEmails: newValue?.replace(',',';'),
            isComplete: isComplete
            });

        internalPricingContextDispatch({ type: CaseActionTypes.channelContactChanged, channelContacts: updatedDealContacts });
    }

    /**
     * Exception Request change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {string} newValue updated value from textField.
     */
    const onExceptionRequestChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        internalPricingContextDispatch({ type: CaseActionTypes.dealDetailsExceptionRequestChanged, dealInputValue: { key: oppdealId, value: newValue } })
    }

    /**
     * Attachment change Callback function.
     * @param {Array<IAttachment | undefined>} attachmentList Updated list of attachments 
     */
    const onAttachmentChange = (attachmentList: Array<IAttachment | undefined>): void => {
        setAttachmentList(attachmentList.filter(item => item?.action !== ActionType.Delete));
        let allAttachments: (IAttachment | undefined)[] | undefined = internalPricingContextState?.attachments || [];
        let otherAttachments = attachmentList?.filter(x => x?.source == getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachment", UIControlsText.Attachment)) || [];
        if (JavaScriptExtensions.isDefined(otherAttachments)) {
            internalPricingContextDispatch({ type: CaseActionTypes.attachmentChanged, attachments: ArrayExtensions.mergeArrays(otherAttachments, allAttachments) });
        }
    }

    /**
     * Attachment change Callback function.
     * @param {Array<IAttachment | undefined>} attachmentList Updated list of attachments 
     */
    const onEndCustomerProofAttachmentChange = (endCustomerAttachmentList: Array<IAttachment | undefined>): void => {
        setEndCustomerAttachmentList (endCustomerAttachmentList.filter(item => item?.action !== ActionType.Delete));
        let allAttachments: (IAttachment | undefined)[] | undefined = internalPricingContextState?.attachments || [];
        let proofOfCustomerPreference = endCustomerAttachmentList?.filter(x => x?.source == getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProofOfPreference", UIControlsText.ProofOfPreference)) || [];
        if (JavaScriptExtensions.isDefined(proofOfCustomerPreference)) {
            internalPricingContextDispatch({ type: CaseActionTypes.attachmentChanged, attachments: ArrayExtensions.mergeArrays(proofOfCustomerPreference, allAttachments) });
        }
    }

    /**
     * Get TextField ErrorMessage.
     * @method
     * @param {string} value Text Box value.
     * @@returns {string} Error Message.
     */
    const getTextFieldErrorMessage = (value: string): string => {
        return internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase() ? '' :
            !value ? getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredFieldError", ErrorMessages.RequiredFieldError) : '';
    };
    
    const getInputQuantityZeroErrorMessage = (value: string): string => {
        if (props?.internalPricingDealType == PageConstants.OTLEDealtype) {
            let dealVolumeValue : number | undefined = internalPricingContextState.opportunityDeals[0]?.dealVolume || Number(value);
            if(dealVolumeValue == 0) {
                return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "InputQuantityShouldNotBeZero", ErrorMessages.InputQuantityShouldNotBeZero);
            }
        }
        getTextFieldErrorMessage(value);
        return "";
    }

    //this method is to hide required field error message on view mode
    const isRequired = (): boolean => {
        return internalPricingContextState.pageMode?.toLowerCase() !== PageMode.View.toLowerCase()
    }
    //this method will validate page mode and status and return to true/false to make deal vteam visible
    const isDealteamSectionVisible = (): boolean => {
        return internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase() ?
            isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVTeamText", UIControlsText.DealVTeamText), internalPricingContextState.pageMode,  props?.internalPricingDealType) :
            !isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVTeamText", UIControlsText.DealVTeamText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType);
    }

    const isMicrosoftContactSectionVisible = (): boolean => {
        return internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase() && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MicrosoftContactText", UIControlsText.MicrosoftContactText), internalPricingContextState.pageMode,  props?.internalPricingDealType) 
    }

    const otherContactIndex = dealContact.findIndex(item => item.contactType === ContactRoles.Others);
    const otherLabelStyle = otherContactIndex !== -1 ? DefaultPageStyles.customWidthForDealTeamLabels : DefaultPageStyles.noColumnMinWidthStyles;
    //display field "Segment(s)" based on pagemode and user role
    const segmentVisible = (): boolean =>{
        return internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase() && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Segments", UIControlsText.Segments), internalPricingContextState.pageMode,  props?.internalPricingDealType) 
    }
    //get distinct segmentvalue from legal entity 
    const segmentValue = (): Array<string> => {
        return _.uniqBy(internalPricingContextState.opportunityDeals[0].dealCustomers, "segment").map(s => s.segment);
    }

    const DealDetailsJsonForCreateEdit : IComponent = 
        {
            type: "Stack",
            data: 
            {
                id: "StackMain",
                components: [
                    {
                        type: "Stack",
                        data: {
                            id: "StackTileForDeal",
                            horizontal:true,
                            verticalAlign: "start",
                            styles: stackContainerStyles,                                 
                            components: [
                                {
                                    type: "CustomTileCardComponent",
                                    data: {
                                        id: "CreateCardForDeal",
                                        baseState: internalPricingContextState,
                                        name: UIControlsText.DealDetailsText, 
                                        iconName: UIControlsText.DealDetailsIcon,
                                        components: [
                                            {
                                                type: 'Stack',
                                                data: {
                                                    id: 'DealsDetailsContainer',
                                                    vertical: true,
                                                    styles: stackContainerStyles,        
                                                    components: [
                                                        {
                                                            type: "Stack",
                                                            data: {
                                                                id: "StackForDealitems",
                                                                horizontal: true,
                                                                components: [
                                                                    {
                                                                        type: "StackItem",
                                                                        data: {
                                                                            id: UIConfigIdentifierConstants.StackDealsNameRowId,
                                                                            styles: stackItemCustomerStyles,
                                                                            align: "auto",
                                                                            components: [
                                                                                {
                                                                                    type: "Dropdown",
                                                                                    visible:  !isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealProductGroupText", UIControlsText.DealProductGroupText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                                    data: {
                                                                                        id: "DealProductGroupText",
                                                                                        label:getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealProductGroupText", UIControlsText.DealProductGroupText),
                                                                                        ariaLabel:getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealProductGroupText", UIControlsText.DealProductGroupText),
                                                                                        placeholder:getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealProductGroupPlaceHolderText", UIControlsText.DealProductGroupPlaceHolderText),
                                                                                        title:getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealProductGroupPlaceHolderText", UIControlsText.DealProductGroupPlaceHolderText),
                                                                                        options:props.productTypes.map((pt: IProductType) => ({ key: pt.productId, text: pt.productName } as IDropdownOption)),
                                                                                        styles:PageStyles.bgdropdownStyles,
                                                                                        onChange:onProductGroupChange,
                                                                                        selectedKey:internalPricingContextState.productGroup && internalPricingContextState.productGroup,
                                                                                        required:true,
                                                                                        disabled:isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealProductGroupText", UIControlsText.DealProductGroupText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                        
                                                                                    }
                                                                                },
                                                                                {
                                                                                    type: "InnerHTMLContainer",
                                                                                    visible: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealProductGroupText", UIControlsText.DealProductGroupText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                                    data: {
                                                                                        id: "DealProductGroupTextPageModeEdit",
                                                                                        contolType: UIControlType.LabelValueView,
                                                                                        label:getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealProductGroupText", UIControlsText.DealProductGroupText),
                                                                                        ariaLabel:getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealProductGroupText", UIControlsText.DealProductGroupText),
                                                                                        title:getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealProductGroupPlaceHolderText", UIControlsText.DealProductGroupPlaceHolderText),
                                                                                        styles:PageStyles.dropdownStyles,
                                                                                        value: props?.productTypes?.filter(x => x.productId  === internalPricingContextState.productGroup)[0]?.productName
                                                                                    }
                                                                                },                                                                                
                                                                            ]
                                                                        }
                                                                    },
                                                                    {
                                                                        type: "StackItem",
                                                                        data: {
                                                                            id: "StackItemContainerDealNameText",
                                                                            styles: stackContainerStylesShift,
                                                                            align: "auto",
                                                                            components: [
                                                                                {
                                                                                    type: "TextField",
                                                                                    visible: !isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealNameText", UIControlsText.DealNameText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                                    data: {
                                                                                        id: "DealNameText",
                                                                                        label:getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealNameText", UIControlsText.DealNameText),
                                                                                        ariaLabel:getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealNameText", UIControlsText.DealNameText),
                                                                                        placeholder:getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealNamePlaceHolderText", UIControlsText.DealNamePlaceHolderText),
                                                                                        styles: PageStyles.textFieldStylesOneColumn,
                                                                                        validateOnFocusOut: true,
                                                                                        multiline: true,
                                                                                        rows:3,
                                                                                        resizable:false,
                                                                                        validateOnLoad:false,
                                                                                        onGetErrorMessage:getTextFieldErrorMessage,
                                                                                        onChange: onDealNameChange,
                                                                                        value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].dealName,
                                                                                        maxLength: 100,
                                                                                        disabled: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealNameText", UIControlsText.DealNameText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                                        required:isRequired(),
                                                                                    }
                                                                                },
                                                                                {
                                                                                    type: "InnerHTMLContainer",
                                                                                    visible: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealNameText", UIControlsText.DealNameText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                                    data: {
                                                                                        id: "DealNameTextPageModeEdit",
                                                                                        contolType: UIControlType.LabelValueView,
                                                                                        label:getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealNameText", UIControlsText.DealNameText),
                                                                                        ariaLabel:getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealNameText", UIControlsText.DealNameText),
                                                                                        styles: PageStyles.textFieldStylesOneColumn,
                                                                                        value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].dealName,
                                                                                    }
                                                                                },
                                                                            ]
                                                                        }
                                                                    },
                                                                    
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'Stack',
                                                            data: {
                                                                id: "StackContainerForDealNameText",
                                                                horizontal: true,
                                                                styles: stackContainerStyles, 
                                                                components: [
                                                                    {
                                                                        type: 'StackItem',
                                                                        data: {
                                                                            id: 'StackItemForDealVerticalDropdown',
                                                                            styles: stackItemCustomerStyles,
                                                                            align: "auto",
                                                                            components: [
                                                                                {
                                                                                    type: "Dropdown",
                                                                                    visible: !isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVerticalText", UIControlsText.DealVerticalText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                                    data: {
                                                                                        id: "DealVerticalDropdown",
                                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVerticalText", UIControlsText.DealVerticalText),
                                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVerticalText", UIControlsText.DealVerticalText),
                                                                                        placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealVerticalPlaceHolderText", UIControlsText.DealVerticalPlaceHolderText),
                                                                                        options: (props?.verticals && props.verticals.map((pt: IVertical) => ({ key: pt.id, text: pt.name } as IDropdownOption))) || [],
                                                                                        styles:PageStyles.bgdropdownStyles,
                                                                                        onChange: onDealVerticalChange,
                                                                                        selectedKey: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].dealVertical,
                                                                                        disabled:props?.internalPricingDealType === DealType.OTLE.toLowerCase()? false :
                                                                                            (((internalPricingContextState.opportunityDeals[0]?.dealCustomers || [])
                                                                                            .filter((selectedItem: ILegalEntity) => selectedItem.customerType === CustomerType.Primary).length === 0) ||
                                                                                            isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVerticalText", UIControlsText.DealVerticalText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType)),
                                                                                        required: isRequired(),
                                                                                    }
                                                                                },
                                                                                {
                                                                                    type: "InnerHTMLContainer",
                                                                                    visible: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVerticalText", UIControlsText.DealVerticalText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                                    data: {
                                                                                        id: "DealVerticalDropdown",
                                                                                        contolType: UIControlType.LabelValueView,
                                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVerticalText", UIControlsText.DealVerticalText),
                                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVerticalText", UIControlsText.DealVerticalText),
                                                                                        options: (props?.verticals && props.verticals.map((pt: IVertical) => ({ key: pt.id, text: pt.name } as IDropdownOption))) || [],
                                                                                        styles: PageStyles.dropdownStyles,
                                                                                        value: props?.verticals?.find((v: IVertical) => v.id == (internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0]?.dealVertical))?.name,
                                                                                    }
                                                                                },
                                                                            ]
                                                                        }
                                                                    },
                                                                    {
                                                                        type: 'StackItem',
                                                                        visible: isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText), internalPricingContextState.pageMode,  props?.internalPricingDealType),
                                                                        data: {
                                                                            id: 'StackItemForDealOverviewText',
                                                                            styles: stackContainerStylesShift,
                                                                            components: [
                                                                                {
                                                                                    type: "TextField",
                                                                                    visible: !isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                                    data: {
                                                                                        id: "DealOverviewText",
                                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText),
                                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText),
                                                                                        placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealOverviewPlaceHolderText", UIControlsText.DealOverviewPlaceHolderText),
                                                                                        styles: PageStyles.textFieldStylesOneColumn,
                                                                                        onGetErrorMessage: getTextFieldErrorMessage,
                                                                                        validateOnFocusOut: true,
                                                                                        multiline: true,
                                                                                        rows: 3,
                                                                                        resizable: false,
                                                                                        validateOnLoad: false,
                                                                                        onChange: onDealOverviewChange,
                                                                                        value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].dealOverview,
                                                                                        disabled: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                                        required: isRequired(),
                                                                                        maxLength: 2000,
                                                                                    }
                                                                                },
                                                                                {
                                                                                    type: "InnerHTMLContainer",
                                                                                    visible: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                                    data: {
                                                                                        id: "DealOverviewTextPageModeEdit",
                                                                                        contolType: UIControlType.LabelValueView,
                                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText),
                                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText),
                                                                                        styles: PageStyles.innerHtmlMinWidth,
                                                                                        value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0]?.dealOverview,
                                                                                    }
                                                                                },
                                                                            ]
                                                                        }
                                                                    },
                                                                ]
                                                            }
                                                        },                                                             
                                                    ]
                                                }
                                            },
                                        ]
                                    }
                                },
                                {
                                    type: "Stack",
                                    data: {
                                        id: "StackForCase",
                                        styles: stackContainerStyles, 
                                        components: [
                                            {
                                                type: "CustomTileCardComponent",
                                                data: {
                                                    id: "CustomCardForCaseDetails",
                                                    baseState: internalPricingContextState,
                                                    name: UIControlsText.CaseDetailsText,
                                                    iconName: UIControlsText.CaseDetailsIcon,
                                                    components: [
                                                        {
                                                            type: "Stack",
                                                            data: {
                                                                id: "StackForCaseDetails",
                                                                horizontal: true,
                                                                styles: stackContainerStyles, 
                                                                components: [
                                                                    {
                                                                        type: 'StackItem',
                                                                        data: {
                                                                            id: "StackItemForDealGeoCoverageText",
                                                                            styles: stackItemCustomerStyles,
                                                                            align: "auto",
                                                                            components: [
                                                                                {
                                                                                    type: "Dropdown",
                                                                                    visible: !isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealGeoCoverageText", UIControlsText.DealGeoCoverageText), internalPricingContextState.pageMode, internalPricingContextState?.opportunityDeals[0]?.statusCode, isCountryMultiSelect, undefined, props?.internalPricingDealType),
                                                                                    data: {
                                                                                        id: "DealGeoCoverageText",
                                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealGeoCoverageText", UIControlsText.DealGeoCoverageText),
                                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealGeoCoverageText", UIControlsText.DealGeoCoverageText),
                                                                                        placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealGeoCoveragePlaceHolderText", UIControlsText.DealGeoCoveragePlaceHolderText),
                                                                                        title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealGeoCoveragePlaceHolderText", UIControlsText.DealGeoCoveragePlaceHolderText),
                                                                                        options: props?.internalPricingDealType == PageConstants.OTLEDealtype ? SingleDealCoverageOption : DealGeoCoverageOptions,
                                                                                        styles:PageStyles.bgdropdownStyles,
                                                                                        onChange:onDealCoverageChange,
                                                                                        selectedKey: (props?.internalPricingDealType == PageConstants.OTLEDealtype) ? Number(internalPricingContextState.isMultiNational) : Number(internalPricingContextState.isMultiNational),
                                                                                        required: true,
                                                                                    }
                                                                                },
                                                                                {
                                                                                    type: "InnerHTMLContainer",
                                                                                    visible: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealGeoCoverageText", UIControlsText.DealGeoCoverageText), internalPricingContextState.pageMode, internalPricingContextState?.opportunityDeals[0]?.statusCode, isCountryMultiSelect, undefined, props?.internalPricingDealType),
                                                                                    data: {
                                                                                        id: "DealGeoCoverageTextDisplay",
                                                                                        contolType: UIControlType.LabelValueView,
                                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealGeoCoverageText", UIControlsText.DealGeoCoverageText),
                                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealGeoCoverageText", UIControlsText.DealGeoCoverageText),
                                                                                        styles: PageStyles.textFieldStylesWidth146,
                                                                                        value: DealGeoCoverageOptions.filter(x => x.key == Number(internalPricingContextState.isMultiNational))[0]?.text,
                                                                                    }
                                                                                },
                                                                            ]
                                                                        }
                                                                    },
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: "Stack",
                                                            data: {
                                                                id: "StackforDealCountry",
                                                                horizontal: true,
                                                                styles: stackContainerStyles,
                                                                components: [
                                                                    {
                                                                        type: 'StackItem',
                                                                        data: {
                                                                            id: "DealCountryStackItem",
                                                                            styles: stackItemCustomerStyles,
                                                                            align: "auto",
                                                                            components: [
                                                                                {
                                                                                    type: "Dropdown",
                                                                                    data: {
                                                                                        id: CssClassConstants.DealCountryDropdownId,
                                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealCountryText", UIControlsText.DealCountryText),
                                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealCountryText", UIControlsText.DealCountryText),
                                                                                        placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealCountryPlaceHolderText", UIControlsText.DealCountryPlaceHolderText),
                                                                                        title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealCountryPlaceHolderText", UIControlsText.DealCountryPlaceHolderText),
                                                                                        options: props.countries.map((pt: ICountry) => ({ key: pt.countryId, text: pt.countryName, disabled: internalPricingContextState.pageMode?.toLowerCase() === PageMode.Create.toLowerCase() ? false : isNonEditableCountry(pt.countryName || "") } as IDropdownOption)),
                                                                                        styles:PageStyles.bgdropdownStyles,
                                                                                        multiSelect: isCountryMultiSelect,
                                                                                        selectedKey: selectedCountries.map(c => c.countryId),
                                                                                        selectedKeys: selectedCountries.map(c => c.countryId),
                                                                                        onChange: onCountryChange,
                                                                                        required: true,
                                                                                    }
                                                                                },
                                                                                {
                                                                                    type: "InnerHTMLContainer",
                                                                                    visible: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealCountryText", UIControlsText.DealCountryText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, getsavedContext()?.isMultiNational, undefined, props?.internalPricingDealType),
                                                                                    data: {
                                                                                        id: CssClassConstants.DealCountryDropdownId,
                                                                                        contolType: UIControlType.LabelValueView,
                                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealCountryText", UIControlsText.DealCountryText),
                                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealCountryText", UIControlsText.DealCountryText),
                                                                                        styles: PageStyles.textFieldStylesWidth146,
                                                                                        value: selectedCountries?.map((c: ICountry) => { return c.countryName} )?.join(","),                                                                                }
                                                                                },
                                                                                {
                                                                                    type: 'StackItem',
                                                                                    visible: props?.internalPricingDealType == PageConstants.CPStrategicDealtype?.toLowerCase() &&
                                                                                        isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeText", UIControlsText.DealAffiliationTypeText), internalPricingContextState.pageMode,  props?.internalPricingDealType),
                                                                                    data: {
                                                                                        id: 'StackItemForOther',
                                                                                        styles: stackItemCustomerStyles,
                                                                                        align: "auto",
                                                                                        components: [
                                                                                            {
                                                                                                type: "Dropdown",
                                                                                                visible: !(isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeText", UIControlsText.DealAffiliationTypeText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0].statusCode, undefined, undefined, props?.internalPricingDealType)),
                                                                                                data: {
                                                                                                    id: "DealAffiliationTypeText",
                                                                                                    label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeText", UIControlsText.DealAffiliationTypeText),
                                                                                                    ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeText", UIControlsText.DealAffiliationTypeText),
                                                                                                    placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealAffiliationTypePlaceHolderText", UIControlsText.DealAffiliationTypePlaceHolderText),
                                                                                                    title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealAffiliationTypePlaceHolderText", UIControlsText.DealAffiliationTypePlaceHolderText),
                                                                                                    options: AffiliationTypeOptions,
                                                                                                    styles: PageStyles.bgdropdownStyles,
                                                                                                    onChange: onAffiliationTypeChange,
                                                                                                    defaultSelectedKey: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].affiliationType,
                                                                                                    selectedKey: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].affiliationType,
                                                                                                    required: true,
                                                                                                    disabled: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeText", UIControlsText.DealAffiliationTypeText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0].statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                type: "InnerHTMLContainer",
                                                                                                visible: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeText", UIControlsText.DealAffiliationTypeText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0].statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                                                data: {
                                                                                                    id: "DealAffiliationTypeText",
                                                                                                    contolType: UIControlType.LabelValueView,
                                                                                                    label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeText", UIControlsText.DealAffiliationTypeText),
                                                                                                    ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeText", UIControlsText.DealAffiliationTypeText),
                                                                                                    styles: PageStyles.bgdropdownStyles,
                                                                                                    value: AffiliationTypeOptions.filter(x => x?.key == (internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0]?.affiliationType))[0]?.text,
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                type: "TextField",
                                                                                                visible: isAffiliationTypeOthersVisible && !(isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeOtherText", UIControlsText.DealAffiliationTypeOtherText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0].statusCode, undefined, undefined, props?.internalPricingDealType)),
                                                                                                data: {
                                                                                                    id: "DealAffiliationTypeOtherText",
                                                                                                    label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeOtherText", UIControlsText.DealAffiliationTypeOtherText),
                                                                                                    ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeOtherText", UIControlsText.DealAffiliationTypeOtherText),
                                                                                                    styles: PageStyles.textFieldStylesOneColumn,
                                                                                                    placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealAffiliationTypeOtherPlaceHolderText", UIControlsText.DealAffiliationTypeOtherPlaceHolderText),
                                                                                                    validateOnFocusOut: true,
                                                                                                    validateOnLoad: false,
                                                                                                    onGetErrorMessage: getTextFieldErrorMessage,
                                                                                                    onChange: onAffiliationTypeOthersChange,
                                                                                                    value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].affiliationTypeOthers,
                                                                                                    maxLength: 100,
                                                                                                    disabled: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeOtherText", UIControlsText.DealAffiliationTypeOtherText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0].statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                                                    required: true,
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                type: "InnerHTMLContainer",
                                                                                                visible: isAffiliationTypeOthersVisible && isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeOtherText", UIControlsText.DealAffiliationTypeOtherText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                                                data: {
                                                                                                    id: "DealAffiliationTypeOtherText",
                                                                                                    contolType: UIControlType.LabelValueView,
                                                                                                    label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeOtherText", UIControlsText.DealAffiliationTypeOtherText),
                                                                                                    ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeOtherText", UIControlsText.DealAffiliationTypeOtherText),
                                                                                                    styles: PageStyles.textFieldStylesOneColumn,
                                                                                                    value: AffiliationTypeOptions.filter(x => x.key == (internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0]?.affiliationTypeOthers))[0]?.text,
                                                                                                }
                                                                                            },
                                                                                        ]
                                                                                    }
                                                                                },
                                                                                {
                                                                                    type: "StackItem",
                                                                                    data: {
                                                                                        id: "StackItemForEmailCC",
                                                                                        styles: stackItemCustomerStyles,
                                                                                        components: [
                                                                                            {
                                                                                                type: "TextField",
                                                                                                visible: (props?.internalPricingDealType == PageConstants.OTLEDealtype?.toLowerCase()) && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailCCNotificationRecipients", UIControlsText.EmailCCNotificationRecipients), internalPricingContextState.pageMode, props?.internalPricingDealType) && internalPricingContextState?.opportunityDeals[0]?.statusCode !== OpportunityDealStatusCodes.PendingSecondaryReview,
                                                                                                data: {
                                                                                                    id: "EmailRecipientsTextField",
                                                                                                    label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailCCNotificationRecipients", UIControlsText.EmailCCNotificationRecipients),
                                                                                                    ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailCCNotificationRecipients", UIControlsText.EmailCCNotificationRecipients),
                                                                                                    styles: PageStyles.textFieldStylesOneColumn,
                                                                                                    placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "EmailCCNotificationRecipients", UIControlsText.EmailCCNotificationRecipients),
                                                                                                    multiline: true,
                                                                                                    validateOnLoad: false,
                                                                                                    maxLength: 5000,
                                                                                                    value: emailRecipientsValue, 
                                                                                                    onChange: onEmailCCNotificationTextBoxChange,
                                                                                                    onBlur: onEmailCCNotificationTextBoxBlur,
                                                                                                    onGetErrorMessage: onEmailCCNotificationTextBoxBlur,
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                type: "StackItem",
                                                                                                visible: internalPricingContextState?.pageMode === PageMode.Edit && internalPricingContextState?.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.PendingSecondaryReview ? true : false,
                                                                                                data: {
                                                                                                    id: "StackItemForEmailCCView",
                                                                                                    styles: stackItemCustomerStyles,
                                                                                                    components: [
                                                                                                        {
                                                                                                            type: "InnerHTMLContainer",
                                                                                                            data: {
                                                                                                                id: "EmailRecipientsTextFieldView",
                                                                                                                contolType: UIControlType.LabelValueView,
                                                                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailCCNotificationRecipients", UIControlsText.EmailCCNotificationRecipients),
                                                                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailCCNotificationRecipients", UIControlsText.EmailCCNotificationRecipients),
                                                                                                                styles: PageStyles.textFieldStylesTwoColumn,
                                                                                                                value: internalPricingContextState?.opportunityChannelContacts && internalPricingContextState?.opportunityChannelContacts.find(t => t.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText))?.channelContactEmails || UIControlsText.None
                                                                                                            }
                                                                                                        },
                                                                                                    ]
                                                                                                }
                                                                                            },
                                                                                        ]
                                                                                    }
                                                                                },
                                                                            ]
                                                                        }
                                                                    },
                                                                    
                                                                ]
                                                            }
                                                        }                                            
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    type: "Stack",
                                    data: {
                                        id: "StackForDealVolAndAttachments",
                                        styles: stackContainerStyles,
                                        components: [
                                            {
                                                type: "CustomTileCardComponent",
                                                data: {
                                                    id: "CardForCommittedDealVolume",
                                                    baseState: internalPricingContextState,
                                                    name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CommittedDealVolumeText", UIControlsText.CommittedDealVolumeText),
                                                    iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CommittedDealVolumeIcon", UIControlsText.CommittedDealVolumeIcon),
                                                    components: [
                                                        {
                                                            type: "Stack",
                                                            data: {
                                                                id: "StackForDealVolItems",
                                                                styles: stackContainerStyles,
                                                                components: [
                                                                    {
                                                                        type: 'StackItem',
                                                                        data: {
                                                                            id: 'StackItemForDealVolumeText',
                                                                            styles: stackItemCustomerStyles,
                                                                            align: "auto",
                                                                            components: [
                                                                                {
                                                                                    type: "TextField",
                                                                                    visible: !(internalPricingContextState.pageMode?.toLowerCase() === PageMode.View || internalPricingContextState.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.PendingSecondaryReview ? true :
                                                                                    (internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].affiliationType === AffiliationType.AffiliateCompanies)),
                                                                                    data: {
                                                                                        id: "DealVolumeText",
                                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVolume", UIControlsText.DealVolume),
                                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVolume", UIControlsText.DealVolume),
                                                                                        placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealVolumePlaceHolderText", UIControlsText.DealVolumePlaceHolderText),
                                                                                        styles: PageStyles.textFieldStylesFourColumn,
                                                                                        onGetErrorMessage: getInputQuantityZeroErrorMessage,
                                                                                        validateOnFocusOut: true,
                                                                                        validateOnLoad: false,
                                                                                        onChange: onDealVolumeChange,
                                                                                        onBlur: onDealVolumeChangeSetWarning,
                                                                                        type: "number",
                                                                                        min: 0,
                                                                                        value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].dealVolume?.toString(),
                                                                                        required: props?.internalPricingDealType == PageConstants.OTLEDealtype,
                                                                                    }
                                                                                },
                                                                                {
                                                                                    type: "InnerHTMLContainer",
                                                                                    visible: !JavaScriptExtensions.isNullOrUndfinedOrEmpty(warningMessageForDealVolume) && warningMessageForDealVolume.length > 1 && props?.internalPricingDealType == PageConstants.OTLEDealtype
                                                                                        && !(internalPricingContextState.pageMode?.toLowerCase() === PageMode.View ? true :
                                                                                        (internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].affiliationType === AffiliationType.AffiliateCompanies)),
                                                                                    data: {
                                                                                        id: "MessageBarForWarning",
                                                                                        ariaLabel: warningMessageForDealVolume,
                                                                                        contolType: UIControlType.MessageBar,
                                                                                        message: warningMessageForDealVolume,
                                                                                        styles: mergeStyles(PageStyles.warningMessageWidth),
                                                                                        messageBarType: MessageBarType.error,
                                                                                    }
                                                                                },
                                                                                {
                                                                                    type: "InnerHTMLContainer",
                                                                                    visible: internalPricingContextState.pageMode?.toLowerCase() === PageMode.View || internalPricingContextState.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.PendingSecondaryReview ? true :
                                                                                    (internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0]?.affiliationType === AffiliationType.AffiliateCompanies),
                                                                                    data: {
                                                                                        id: "DealVolumeTextPageModeEdit",
                                                                                        contolType: UIControlType.LabelValueView,
                                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVolume", UIControlsText.DealVolume),
                                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVolume", UIControlsText.DealVolume),
                                                                                        styles: PageStyles.textFieldStylesFourColumn,
                                                                                        value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0]?.dealVolume?.toString(),
                                                                                    }
                                                                                }
                                                                            ]
                                                                        }
                                                                    },
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        type: "Stack",
                        data: {
                            id: "StackForExpiration",
                            horizontal: true,
                            styles: stackContainerStyles, 
                            components: [
                                {   
                                    type: 'CustomTileCardComponent',
                                    data: {
                                        id: "CustomCardForExpiration",
                                        baseState: internalPricingContextState,
                                        name: "Expiration Details",
                                        iconName: UIControlsText.DateCalendarIcon,
                                        components:[ 
                                            {
                                                type: 'Stack',
                                                data: {
                                                    id: "StackContainerForExpiration",
                                                    horizontal: true,
                                                    verticalAlign: "start",
                                                    styles: stackContainerStyles, 
                                                    components: [
                                                        {
                                                            type: "StackItem",
                                                            data: {
                                                                id: 'StackItemForDatePicker',
                                                                verticalAlign: "start",
                                                                styles: stackItemCustomerStyles,
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "DatePicker",
                                                                        visible: isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateText", UIControlsText.DealExpirationDateText), internalPricingContextState.pageMode,  props?.internalPricingDealType) &&
                                                                            ! (isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateText", UIControlsText.DealExpirationDateText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType)),
                                                                        data: {
                                                                            id: "DealExpirationDateText",
                                                                            styles: DatePickerStyles,
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateText", UIControlsText.DealExpirationDateText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateText", UIControlsText.DealExpirationDateText),
                                                                            // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                                                            strings: defaultDatePickerStrings,
                                                                            minDate: new Date(Date.now()),
                                                                            maxDate: maxDateForExpirationField,
                                                                            highlightCurrentMonth: true,
                                                                            highlightSelectedMonth: true,
                                                                            onSelectDate: onDealRequestedExpirationDate,
                                                                            value: getDatePart(internalPricingContextState.opportunityDeals[0].expirationDate),
                                                                            onGetErrorMessage: (props?.internalPricingDealType == PageConstants.OTLEDealtype ? getExpirationDateErrorMessage : getTextFieldErrorMessage),
                                                                            disabled: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateText", UIControlsText.DealExpirationDateText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                            textField: {
                                                                                validateOnLoad: false, validateOnFocusOut: true,
                                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateText", getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateText", UIControlsText.DealExpirationDateText)),
                                                                                placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealExpirationDatePlaceHolderText", UIControlsText.DealExpirationDatePlaceHolderText),
                                                                                required: isRequired(),
                                                                                onGetErrorMessage: (props?.internalPricingDealType == PageConstants.OTLEDealtype ? getExpirationDateErrorMessage : getTextFieldErrorMessage),
                                                                            }
                                                                        }
                                                                    },
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        visible: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateText", UIControlsText.DealExpirationDateText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                        data: {
                                                                            id: "DealExpirationDateText",
                                                                            contolType: UIControlType.LabelValueView,
                                                                            styles: DatePickerStyles,
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateText", UIControlsText.DealExpirationDateText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateText", UIControlsText.DealExpirationDateText),
                                                                            value: internalPricingContextState.opportunityDeals[0]?.expirationDate && Moment(internalPricingContextState.opportunityDeals[0]?.expirationDate)?.format('MM/DD/YYYY'),
                                                                        }
                                                                    },
                                                                    {
                                                                        type: 'StackItem',
                                                                        visible: props?.internalPricingDealType == PageConstants.OTLEDealtype && internalPricingContextState.pageMode == PageMode.Edit,
                                                                        data: {
                                                                            id: "StackItemForDealCasesStartDateText",
                                                                            styles: stackItemCustomerStyles,
                                                                            components: [
                                                                                {
                                                                                    type: "InnerHTMLContainer",
                                                                                    data: {
                                                                                        id: "DealCasesStartDateText",
                                                                                        contolType: UIControlType.LabelValueView,
                                                                                        styles: PageStyles.textFieldStylesWidth146,
                                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealCasesStartDateText", UIControlsText.DealCasesStartDateText),
                                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealCasesStartDateText", UIControlsText.DealCasesStartDateText),
                                                                                        value: internalPricingContextState.opportunityDeals[0]?.caseStartDate && Moment(internalPricingContextState.opportunityDeals[0]?.caseStartDate)?.format('MM/DD/YYYY'),
                                                                                    }
                                                                                },
                                                                            ]
                                                                        }
                                                                    },
                                                                    
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            visible: props?.internalPricingDealType == PageConstants.CPStrategicDealtype?.toLowerCase() 
                                                                    && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateJustificationText", UIControlsText.DealExpirationDateJustificationText), internalPricingContextState.pageMode,  props?.internalPricingDealType),
                                                            data: {
                                                                id: "StackItemForDealExpirationDateJustificationText",
                                                                styles: stackContainerStylesShift,
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "TextField",
                                                                        visible: !isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateJustificationText", UIControlsText.DealExpirationDateJustificationText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                        data: 
                                                                        {
                                                                            id: "DealExpirationDateJustificationText",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateJustificationText", UIControlsText.DealExpirationDateJustificationText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateJustificationText", UIControlsText.DealExpirationDateJustificationText),
                                                                            placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealExpirationDateJustificationPlaceHolderText", UIControlsText.DealExpirationDateJustificationPlaceHolderText),
                                                                            validateOnFocusOut: true,
                                                                            validateOnLoad: false,
                                                                            onGetErrorMessage: getTextFieldErrorMessage,
                                                                            onChange: onExpirationDateJustification,
                                                                            styles: DefaultPageStyles.oneColumnWidthStyles,
                                                                            value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0]?.expirationDateJustification,
                                                                            maxLength: 2000,
                                                                            disabled: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateJustificationText", UIControlsText.DealExpirationDateJustificationText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                            required: isRequired(),
                                                                        }
                                                                    },
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        visible: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateJustificationText", UIControlsText.DealExpirationDateJustificationText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                        data: 
                                                                        {
                                                                            id: "DealExpirationDateJustificationTextPageModeEdit",
                                                                            contolType: UIControlType.LabelValueView,
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateJustificationText", UIControlsText.DealExpirationDateJustificationText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateJustificationText", UIControlsText.DealExpirationDateJustificationText),
                                                                            styles: DefaultPageStyles.oneColumnWidthStyles,
                                                                            value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0]?.expirationDateJustification,
                                                                        }
                                                                    },
                                                                ]
                                                            }
                                                        },
                                                    ]
                                                }
                                            },
                                            {
                                                type: "Stack",
                                                visible: isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExceptionRequest", UIControlsText.ExceptionRequest), internalPricingContextState.pageMode,  props?.internalPricingDealType),
                                                data: {
                                                    id: "StackForExceptionRequest",
                                                    styles: stackContainerStyles, 
                                                    components: [
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'StackItemForExceptionRequest',
                                                                styles: stackItemCustomerStyles,
                                                                components: [
                                                                    {
                                                                        type: "TextField",
                                                                        visible: !isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExceptionRequest", UIControlsText.ExceptionRequest), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                        data: {
                                                                            id: "ExceptionRequest",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExceptionRequest", UIControlsText.ExceptionRequest),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExceptionRequest", UIControlsText.ExceptionRequest),
                                                                            placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "ExceptionRequestPlaceHolderText", UIControlsText.ExceptionRequestPlaceHolderText),
                                                                            onChange: onExceptionRequestChange,
                                                                            styles: DefaultPageStyles.oneColumnWidthStyles,
                                                                            value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0]?.exceptionRequest,
                                                                            maxLength: 100,
                                                                            multiline: true,
                                                                            rows: 3,
                                                                            resizable: false,
                                                                            disabled: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExceptionRequest", UIControlsText.ExceptionRequest), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                
                                                                        }
                                                                    },
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        visible: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExceptionRequest", UIControlsText.ExceptionRequest), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                        data: {
                                                                            id: "ExceptionRequest",
                                                                            contolType: UIControlType.LabelValueView,
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExceptionRequest", UIControlsText.ExceptionRequest),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExceptionRequest", UIControlsText.ExceptionRequest),
                                                                            styles: DefaultPageStyles.oneColumnWidthStyles,
                                                                            value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0]?.exceptionRequest,
                                                                        }
                                                                    },
                                                                ]
                                                            }
                                                        },
                                                    ]
                                                }
                                            }                                            
                                        ]
                                    }
                                },
                                {
                                    type: 'CustomTileCardComponent',
                                    data: {
                                        id: "CustomCardForAttachment",
                                        baseState: internalPricingContextState,
                                        name: "Attachment Details",
                                        iconName: UIControlsText.AttachIcon,
                                        styles: PageStyles.multiLineTextWidth,
                                        components: [
                                            {
                                                type: "Stack",
                                                data: {
                                                    id: "StackForAttachments",
                                                    horizontal: true,
                                                    styles: stackContainerStyles, 
                                                    components: [
                                                        {
                                                            type: 'StackItem',
                                                            visible: props?.internalPricingDealType == PageConstants.OTLEDealtype && 
                                                                isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProofOfPreference", UIControlsText.ProofOfPreference), internalPricingContextState.pageMode,  props?.internalPricingDealType),
                                                            data: {
                                                                id: 'StackItemForProofofEndCustomerPreference',
                                                                styles: stackItemCustomerStyles,
                                                                components: [
                                                                    {
                                                                        type: "Attachment",
                                                                        data: {
                                                                            id: "AttachmentListforProofEndCustomerPreference",
                                                                            labelText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProofOfPreference", UIControlsText.ProofOfPreference),
                                                                            source: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProofOfPreference", UIControlsText.ProofOfPreference),
                                                                            attachmentList: endCustomerAttachment,
                                                                            handleAttachmentChanges: onEndCustomerProofAttachmentChange,
                                                                            required: true,
                                                                            pageMode: internalPricingContextState.pageMode,
                                                                            savedAttachments: endCustomerAttachment || savedAttachments?.filter(x => x?.source == getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProofOfPreference", UIControlsText.ProofOfPreference)) || [],
                                                                            attachmentDisplayMode: AttachmentDisplayMode.Grid,
                                                                            opportunityDealStatusCode: internalPricingContextState.opportunityDeals[0].statusCode,
                                                                            disabled: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProofOfPreference", UIControlsText.ProofOfPreference), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                            allowedFileExtensions: UIControlsText.AllowedFileExtensionsForEndCustomerProof.split(','),
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            visible: isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachment", UIControlsText.Attachment), internalPricingContextState.pageMode,  props?.internalPricingDealType),
                                                            data: {
                                                                id: 'StackItemForAttachmentList',
                                                                styles: stackItemCustomerStyles,
                                                                components: [
                                                                    {
                                                                        type: "Attachment",
                                                                        data: {
                                                                            id: "AttachmentList",
                                                                            labelText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachment", UIControlsText.Attachment),
                                                                            source: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachment", UIControlsText.Attachment),
                                                                            attachmentList: attachmentList,
                                                                            handleAttachmentChanges: onAttachmentChange,
                                                                            pageMode: internalPricingContextState.pageMode,
                                                                            savedAttachments: attachmentList || savedAttachments?.filter(x => x?.source == getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachment", UIControlsText.Attachment)) || [],
                                                                            attachmentDisplayMode: AttachmentDisplayMode.Grid,
                                                                            opportunityDealStatusCode: internalPricingContextState.opportunityDeals[0].statusCode,
                                                                            disabled: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachment", UIControlsText.Attachment), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                            allowedFileExtensions: props?.internalPricingDealType == PageConstants.CPStrategicDealtype?.toLowerCase() ? UIControlsText.AllowedFileExtensionsForCPStrategic.split(',') : UIControlsText.AllowedFileExtensionsForOTLE.split(','),
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                },
                            ]
                        }
                    },
                    {
                        type: "Stack",
                        visible: isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalInformation", UIControlsText.AdditionalInformation), internalPricingContextState.pageMode,  props?.internalPricingDealType),
                        data: {
                            id: "StackForAdditionalInformation",
                            horizontal: true,
                            styles: stackContainerStyles, 
                            components: [
                                {   
                                    type: 'CustomTileCardComponent',
                                    data: {
                                        id: "CustomCardForAdditionalInformation",
                                        baseState: internalPricingContextState,
                                        name: "Additional Information",
                                        iconName: UIControlsText.CaseDetailsIcon,
                                        components:[ 
                                            {
                                                type: "Stack",
                                                visible: isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalInformation", UIControlsText.AdditionalInformation), internalPricingContextState.pageMode,  props?.internalPricingDealType),
                                                data: {
                                                    id: "StackForAdditionalInformation",
                                                    styles: stackContainerStyles, 
                                                    components: [
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'StackItemForAdditionalInformation',
                                                                styles: stackItemCustomerStyles,
                                                                components: [
                                                                    {
                                                                        type: "TextField",
                                                                        visible: isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalInformation", UIControlsText.AdditionalInformation), internalPricingContextState.pageMode,  props?.internalPricingDealType),
                                                                        data: {
                                                                            id: "AdditionalInformation",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalInformation", UIControlsText.AdditionalInformation),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalInformation", UIControlsText.AdditionalInformation),
                                                                            placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "AdditionalInformationPlaceHolderText", UIControlsText.AdditionalInformationPlaceHolderText),
                                                                            onChange: onAdditionalInfoChange,
                                                                            styles: DefaultPageStyles.oneColumnWidthStyles,
                                                                            value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0]?.additionalInformation,
                                                                            maxLength: 100,
                                                                            multiline: true,
                                                                            rows: 3,
                                                                            resizable: false,
                                                                            disabled: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalInformation", UIControlsText.AdditionalInformation), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                        }
                                                                    },
                                                                ]
                                                            }
                                                        },
                                                    ]
                                                }
                                            }                                            
                                        ]
                                    }
                                },
                            ]
                        }
                    },
                ]
            }
        };

    const DealDetailsJsonForView : IComponent = 
    {
        type: "Stack",
        data: 
        {
            id: "StackMain",
            components: [
                {
                    type: "Stack",
                    data: {
                        id: "StackTileForDeal",
                        horizontal: true,
                        verticalAlign: "start",
                        styles: stackContainerStyles, 
                        components: [
                            {
                                type: "CustomTileCardComponent",
                                data: {
                                    id: "CreateCardForDeal",
                                    baseState: internalPricingContextState,
                                    name: UIControlsText.DealDetailsText,
                                    iconName: UIControlsText.DealDetailsIcon,
                                    components: [
                                        {
                                            type: 'Stack',
                                            data: {
                                                id: 'DealsDetailsContainer',
                                                vertical: true,
                                                components: [
                                                    {
                                                        type: "Stack",
                                                        data: {
                                                            id: "StackForDealitems",
                                                            horizontal: true,
                                                            components: [
                                                                {
                                                                    type: "StackItem",
                                                                    data: {
                                                                        id: UIConfigIdentifierConstants.StackDealsNameRowId,
                                                                        styles: stackItemCustomerStyles,
                                                                        align: "auto",
                                                                        components: [
                                                                            {
                                                                                type: "InnerHTMLContainer",
                                                                                data: {
                                                                                    id: "DealProductGroupText",
                                                                                    contolType: UIControlType.LabelValueView,
                                                                                    label:getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealProductGroupText", UIControlsText.DealProductGroupText),
                                                                                    ariaLabel:getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealProductGroupText", UIControlsText.DealProductGroupText),
                                                                                    title:getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealProductGroupPlaceHolderText", UIControlsText.DealProductGroupPlaceHolderText),
                                                                                    styles:PageStyles.textFieldStylesWidth146,
                                                                                    value: props.productTypes?.filter(x => x.productId  === internalPricingContextState.productGroup)[0]?.productName
                                                                                }
                                                                            },                                                                                
                                                                        ]
                                                                    }
                                                                },
                                                                {
                                                                    type: "StackItem",
                                                                    data: {
                                                                        id: "StackItemContainerDealNameText",
                                                                        styles: stackItemCustomerStyles,
                                                                        align: "auto",
                                                                        components: [
                                                                            {
                                                                                type: "InnerHTMLContainer",
                                                                                data: {
                                                                                    id: "DealNameText",
                                                                                    contolType: UIControlType.LabelValueView,
                                                                                    label:getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealNameText", UIControlsText.DealNameText),
                                                                                    ariaLabel:getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealNameText", UIControlsText.DealNameText),
                                                                                    styles: PageStyles.textFieldStylesTwoColumn,
                                                                                    value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0]?.dealName,
                                                                                }
                                                                            },
                                                                        ]
                                                                    }
                                                                },                                                                
                                                            ]
                                                        }
                                                    },
                                                    {
                                                        type: 'Stack',
                                                        data: {
                                                            id: "StackContainerForDealNameText",
                                                            horizontal: true,
                                                            verticalAlign: "start",
                                                            styles: stackContainerStyles, 
                                                            components: [
                                                                {
                                                                    type: 'StackItem',
                                                                    data: {
                                                                        id: 'StackItemForDealVerticalDropdown',
                                                                        styles: stackItemCustomerStyles,
                                                                        components: [
                                                                            {
                                                                                type: "InnerHTMLContainer",
                                                                                data: {
                                                                                    id: "DealVerticalDropdown",
                                                                                    contolType: UIControlType.LabelValueView,
                                                                                    label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVerticalText", UIControlsText.DealVerticalText),
                                                                                    ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVerticalText", UIControlsText.DealVerticalText),
                                                                                    options: (props?.verticals && props.verticals.map((pt: IVertical) => ({ key: pt.id, text: pt.name } as IDropdownOption))) || [],
                                                                                    styles: PageStyles.textFieldStylesWidth146,
                                                                                    value: props?.verticals?.filter((v: IVertical) => v.id == (internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0]?.dealVertical))?.map(verticals => { return verticals?.name }),
                                                                                }
                                                                            },
                                                                        ]
                                                                    }
                                                                },
                                                                {
                                                                    type: 'StackItem',
                                                                    visible: isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText), internalPricingContextState.pageMode,  props?.internalPricingDealType), 
                                                                    data: {
                                                                        id: 'StackItemForDealOverviewText',
                                                                        styles: stackItemCustomerStyles,
                                                                        align: "auto",
                                                                        components: [
                                                                            {
                                                                                type: "InnerHTMLContainer",
                                                                                data: {
                                                                                    id: "DealOverviewText",
                                                                                    contolType: UIControlType.LabelValueView,
                                                                                    label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText),
                                                                                    ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText),
                                                                                    styles: PageStyles.textFieldStylesTwoColumn,
                                                                                    value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0]?.dealOverview,
                                                                                }
                                                                            },
                                                                        ]
                                                                    }
                                                                },
                                                                {
                                                                    type: 'StackItem',
                                                                    visible: isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Segments", UIControlsText.Segments), internalPricingContextState.pageMode,  props?.internalPricingDealType)
                                                                        && props?.internalPricingDealType == PageConstants.CPStrategicDealtype?.toLowerCase() 
                                                                        && (segmentValue() != null && segmentValue()?.length > 0),
                                                                    data: {
                                                                        id: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Segments", UIControlsText.Segments),
                                                                        styles: isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Segments", UIControlsText.Segments), internalPricingContextState.pageMode,  props?.internalPricingDealType)
                                                                            && props?.internalPricingDealType == PageConstants.CPStrategicDealtype?.toLowerCase() ? DefaultPageStyles.DisplayNone : stackItemCustomerStyles,
                                                                        components: [
                                                                            {
                                                                                type: "UnorderList",
                                                                                visible: (segmentValue()?.length > 0),
                                                                                data: {
                                                                                    id: "UnorderListSegmentValues",
                                                                                    dataList: segmentValue(),
                                                                                    title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Segments", UIControlsText.Segments),
                                                                                }
                                                                            }
                                                                        ]
                                                                    }
                                                                },                                                            
                                                            ]
                                                        }
                                                    },   
                                                    {
                                                        type: 'Stack',
                                                        data: {
                                                            id: "StackItemForDealStackability",
                                                            horizontal: true,
                                                            verticalAlign: "start",
                                                            styles: stackContainerStyles, 
                                                         components: [
                                                            {
                                                                type: 'StackItem',
                                                                visible: props?.internalPricingDealType == PageConstants.CPStrategicDealtype?.toLowerCase()
                                                                    && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealStackability", UIControlsText.DealStackability), internalPricingContextState.pageMode,  props?.internalPricingDealType),
                                                                data: {
                                                                    id: 'StackItemForDealStackability',
                                                                    styles: stackItemCustomerStyles,
                                                                    components: [
                                                                        {
                                                                            type: "InnerHTMLContainer",
                                                                            data: {
                                                                                id: "DealStackability",
                                                                                contolType: UIControlType.LabelValueView,
                                                                                styles: PageStyles.textFieldStylesFourColumn,
                                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealStackability", UIControlsText.DealStackability),
                                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealStackability", UIControlsText.DealStackability),
                                                                                value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].dealStackability?.toString() || UIControlsText.None,
                                                                            }
                                                                        },
                                                                    ]
                                                                }
                                                            },
                                                         ]
                                                        }
                                                    }                                                   
                                                ]
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: "Stack",
                                data: {
                                    id: "StackForCase",
                                    horizontal: true,
                                    styles: stackContainerStyles, 
                                    components: [
                                        {
                                            type: "CustomTileCardComponent",
                                            data: {
                                                id: "CustomCardForCase",
                                                baseState: internalPricingContextState,
                                                name: UIControlsText.CaseDetailsText,
                                                iconName: UIControlsText.CaseDetailsIcon,
                                                components: [
                                                    {
                                                        type: 'Stack',
                                                        data: {
                                                            id: "StackForCaseDetails",
                                                            horizontal: true,
                                                            verticalAlign: "start",
                                                            components: [
                                                                {
                                                                    type: 'StackItem',
                                                                    data: {
                                                                        id: "StackItemForDealGeoCoverageText",
                                                                        styles: stackItemCustomerStyles,
                                                                        align: "auto",
                                                                        components: [
                                                                            {
                                                                                type: "InnerHTMLContainer",
                                                                                data: {
                                                                                    id: "DealGeoCoverageText",
                                                                                    contolType: UIControlType.LabelValueView,
                                                                                    label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealGeoCoverageText", UIControlsText.DealGeoCoverageText),
                                                                                    ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealGeoCoverageText", UIControlsText.DealGeoCoverageText),
                                                                                    styles: PageStyles.textFieldStylesWidth146,
                                                                                    value: DealGeoCoverageOptions.filter(x => x.key == Number(internalPricingContextState.isMultiNational))[0]?.text,
                                                                                }
                                                                            },
                                                                        ]
                                                                    }
                                                                },
                                                                {
                                                                    type: 'StackItem',
                                                                    data: {
                                                                        id: UIConfigIdentifierConstants.StackItemDealOverviewId,
                                                                        styles: stackItemCustomerStyles,
                                                                        align: "auto",
                                                                        components: [
                                                                            {
                                                                                type: "InnerHTMLContainer",
                                                                                data: {
                                                                                    id: CssClassConstants.DealCountryDropdownId,
                                                                                    contolType: UIControlType.LabelValueView,
                                                                                    label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealCountryText", UIControlsText.DealCountryText),
                                                                                    ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealCountryText", UIControlsText.DealCountryText),
                                                                                    styles: PageStyles.textFieldStylesWidth146,
                                                                                    value: selectedCountries?.map((c: ICountry) => { return c.countryName} )?.join(","),                                                                                }
                                                                            },
                                                                        ]
                                                                    }
                                                                },
                                                            ]
                                                        }
                                                    },
                                                    {
                                                        type: "Stack",
                                                        data: {
                                                            id: "SecondRowForCase",
                                                            components: [
                                                                {
                                                                    type: "StackItem",
                                                                    visible: isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailCCNotificationRecipients", UIControlsText.EmailCCNotificationRecipients), internalPricingContextState.pageMode, props?.internalPricingDealType),
                                                                    data: {
                                                                        id: "StackItemForEmailCCView",
                                                                        styles: stackItemCustomerStyles,
                                                                        components: [
                                                                            {
                                                                                type: "InnerHTMLContainer",
                                                                                data: {
                                                                                    id: "EmailRecipientsTextFieldView",
                                                                                    contolType: UIControlType.LabelValueView,
                                                                                    label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailCCNotificationRecipients", UIControlsText.EmailCCNotificationRecipients),
                                                                                    ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailCCNotificationRecipients", UIControlsText.EmailCCNotificationRecipients),
                                                                                    styles: PageStyles.textFieldStylesTwoColumn,
                                                                                    value: internalPricingContextState?.opportunityChannelContacts && internalPricingContextState?.opportunityChannelContacts.find(t => t.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText))?.channelContactEmails || UIControlsText.None
                                                                                }
                                                                            },
                                                                        ]
                                                                    }
                                                                },
                                                                {
                                                                    type: 'Stack',
                                                                    data: {
                                                                        id: 'StackForAffiliationandClosure',
                                                                        horizontal: true, 
                                                                        components: [
                                                                            {
                                                                                type: 'StackItem',
                                                                                visible: props?.internalPricingDealType == PageConstants.CPStrategicDealtype?.toLowerCase(),
                                                                                data: {
                                                                                    id: 'StackItemForOther',
                                                                                    styles: stackItemCustomerStyles,
                                                                                    align: "auto",
                                                                                    components: [
                                                                                        {
                                                                                            type: "InnerHTMLContainer",
                                                                                            data: {
                                                                                                id: "DealAffiliationTypeText",
                                                                                                contolType: UIControlType.LabelValueView,
                                                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeText", UIControlsText.DealAffiliationTypeText),
                                                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeText", UIControlsText.DealAffiliationTypeText),
                                                                                                styles: PageStyles.textFieldStylesWidth146,
                                                                                                value: AffiliationTypeOptions.filter(x => x.key == (internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0]?.affiliationType))[0]?.text,
                                                                                            }
                                                                                        },
                                                                                        {
                                                                                            type: "InnerHTMLContainer",
                                                                                            data: {
                                                                                                id: "DealAffiliationTypeOtherText",
                                                                                                contolType: UIControlType.LabelValueView,
                                                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeOtherText", UIControlsText.DealAffiliationTypeOtherText),
                                                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealAffiliationTypeOtherText", UIControlsText.DealAffiliationTypeOtherText),
                                                                                                styles: PageStyles.textFieldStylesWidth146,
                                                                                                value: AffiliationTypeOptions.filter(x => x.key == (internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].affiliationTypeOthers))[0]?.text || UIControlsText.None,
                                                                                            }
                                                                                        },
                                                                                    ]
                                                                                }
                                                                            },
                                                                            {
                                                                                type: 'StackItem',
                                                                                visible: (internalPricingContextState.opportunityDeals[0]?.statusCode !== OpportunityDealStatusCodes.Draft) 
                                                                                                && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ClosureDecisionCommentsText", UIControlsText.ClosureDecisionCommentsText), internalPricingContextState.pageMode,  props?.internalPricingDealType),
                                                                                data: {
                                                                                    id: "StackItemForClosureDecisionCommentsText",
                                                                                    styles: stackItemCustomerStyles,
                                                                                    align: "auto",
                                                                                    components: [
                                                                                        {
                                                                                            type: "InnerHTMLContainer",
                                                                                            
                                                                                            data: {
                                                                                                id: "ClosureDecisionCommentsText",
                                                                                                contolType: UIControlType.LabelValueView,
                                                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ClosureDecisionCommentsText", UIControlsText.ClosureDecisionCommentsText),
                                                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ClosureDecisionCommentsText", UIControlsText.ClosureDecisionCommentsText),
                                                                                                styles: PageStyles.textFieldStylesTwoColumn,
                                                                                                value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0]?.closureDecisionComments
                                                                                            }
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            },
                                                                        ]
                                                                    }
                                                                },
                                                                
                                                            ]
                                                        }
                                                    },
                                                ]
                                            }
                                        },
                                        {
                                            type: "CustomTileCardComponent",
                                            data: {
                                                id: "ViewCardForCommittedDealVolumeView",
                                                baseState: internalPricingContextState,
                                                name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CommittedDealVolumeText", UIControlsText.CommittedDealVolumeText),
                                                iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CommittedDealVolumeIcon", UIControlsText.CommittedDealVolumeIcon),
                                                components: [
                                                    {
                                                        type: 'Stack',
                                                        data: {
                                                            id: 'StackForDealVolumeTextView',
                                                            styles: stackContainerStyles,
                                                            components: [
                                                                {
                                                                    type: 'StackItem',
                                                                    data: {
                                                                        id: 'StackItemForDealVolumeText',
                                                                        styles: stackItemCustomerStyles,
                                                                        components: [
                                                                            {
                                                                                type: "InnerHTMLContainer",
                                                                                data: {
                                                                                    id: "DealVolumeText",
                                                                                    contolType: UIControlType.LabelValueView,
                                                                                    label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVolume", UIControlsText.DealVolume),
                                                                                    ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVolume", UIControlsText.DealVolume),
                                                                                    value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].dealVolume?.toString(),
                                                                                }
                                                                            }
                                                                        ]
                                                                    }
                                                                },
                                                                {
                                                                    type: "InnerHTMLContainer",
                                                                    visible: (internalPricingContextState.opportunityDeals[0]?.statusCode !== OpportunityDealStatusCodes.Unassigned) ||
                                                                        isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealTransactedVolumeText", UIControlsText.DealTransactedVolumeText), internalPricingContextState.pageMode,  props?.internalPricingDealType),//Decision Pending Status should not be visible
                                                                    data: {
                                                                        id: "DealTransactedVolumeText",
                                                                        contolType: UIControlType.LabelValueView,
                                                                        styles: PageStyles.textFieldStylesTwoColumn,
                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealTransactedVolumeText", UIControlsText.DealTransactedVolumeText),
                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealTransactedVolumeText", UIControlsText.DealTransactedVolumeText),
                                                                        value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].unitsTransacted?.toString() || UIControlsText.None
                                                                    }
                                                                },
                                                            ]
                                                        }
                                                    },
                                                ]
                                            }
                                        },
                                    ]
                                }
                            }
                        ]
                    }
                },
                {
                    type: "Stack",
                    data: {
                        id: "StackFor",
                        horizontal: true,
                        styles: stackContainerStyles, 
                        components: [
                            {   
                                type: 'CustomTileCardComponent',
                                data: {
                                    id: "ViewCardForExpiration",
                                    baseState: internalPricingContextState,
                                    name: "Expiration Details",
                                    iconName: UIControlsText.DateCalendarIcon,
                                    components:[ 
                                        {
                                            type: 'Stack',
                                            data: {
                                                id: "StackContainerForExpiration",
                                                horizontal: true,
                                                verticalAlign: "start",
                                                styles: stackContainerStyles, 
                                                components: [
                                                    {
                                                        type: "StackItem",
                                                        visible: isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateText", UIControlsText.DealExpirationDateText), internalPricingContextState.pageMode,  props?.internalPricingDealType),
                                                        data: {
                                                            id: 'StackItemForDatePicker',
                                                            styles: stackItemCustomerStyles,
                                                            components: [
                                                                {
                                                                    type: "InnerHTMLContainer",
                                                                    data: {
                                                                        id: "DealExpirationDateText",
                                                                        contolType: UIControlType.LabelValueView,
                                                                        styles: PageStyles.textFieldStylesWidth146,
                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateText", UIControlsText.DealExpirationDateText),
                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateText", UIControlsText.DealExpirationDateText),
                                                                        value: internalPricingContextState.opportunityDeals[0]?.expirationDate && Moment(internalPricingContextState.opportunityDeals[0]?.expirationDate)?.format('MM/DD/YYYY'),
                                                                    }
                                                                },
                                                            ]
                                                        }
                                                    },
                                                    {
                                                        type: 'StackItem',
                                                        data: {
                                                            id: "StackItemForDealCasesStartDateText",
                                                            styles: stackItemCustomerStyles,
                                                            components: [
                                                                {
                                                                    type: "InnerHTMLContainer",
                                                                    data: {
                                                                        id: "DealCasesStartDateText",
                                                                        contolType: UIControlType.LabelValueView,
                                                                        styles: PageStyles.textFieldStylesWidth146,
                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealCasesStartDateText", UIControlsText.DealCasesStartDateText),
                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealCasesStartDateText", UIControlsText.DealCasesStartDateText),
                                                                        value: internalPricingContextState.opportunityDeals[0]?.caseStartDate && Moment(internalPricingContextState.opportunityDeals[0]?.caseStartDate)?.format('MM/DD/YYYY'),
                                                                    }
                                                                },
                                                            ]
                                                        }
                                                    },
                                                    {
                                                        type: 'StackItem',
                                                        data: {
                                                            id: "StackItemForDealCasesEndDateText",
                                                            styles: stackItemCustomerStyles,
                                                            components: [
                                                                {
                                                                    type: "InnerHTMLContainer",
                                                                    data: {
                                                                        id: "DealCasesEndDateText",
                                                                        contolType: UIControlType.LabelValueView,
                                                                        styles: PageStyles.textFieldStylesWidth146,
                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealCasesEndDateText", UIControlsText.DealCasesEndDateText),
                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealCasesEndDateText", UIControlsText.DealCasesEndDateText),
                                                                        value: internalPricingContextState.opportunityDeals[0]?.caseEndDate && Moment(internalPricingContextState.opportunityDeals[0]?.caseEndDate).format('MM/DD/YYYY'),
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    {
                                                        type: 'StackItem',
                                                        visible: props?.internalPricingDealType == PageConstants.CPStrategicDealtype?.toLowerCase() &&
                                                            isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateJustificationText", UIControlsText.DealExpirationDateJustificationText), internalPricingContextState.pageMode,  props?.internalPricingDealType),          
                                                        data: {
                                                            id: "StackItemForDealExpirationDateJustificationText",
                                                            styles: stackItemCustomerStyles,
                                                            components: [
                                                                {
                                                                    type: "InnerHTMLContainer",
                                                                    visible: props?.internalPricingDealType == PageConstants.CPStrategicDealtype?.toLowerCase(),
                                                                    data: 
                                                                    {
                                                                        id: "DealExpirationDateJustificationText",
                                                                        contolType: UIControlType.LabelValueView,
                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateJustificationText", UIControlsText.DealExpirationDateJustificationText),
                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealExpirationDateJustificationText", UIControlsText.DealExpirationDateJustificationText),
                                                                        styles: PageStyles.textFieldStylesTwoColumn,
                                                                        value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0]?.expirationDateJustification,
                                                                    }
                                                                },
                                                            ]
                                                        }
                                                    },
                                                ]
                                            }
                                        },
                                        {
                                            type: "Stack",
                                            visible: isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExceptionRequest", UIControlsText.ExceptionRequest), internalPricingContextState.pageMode,  props?.internalPricingDealType),         
                                            data: {
                                                id: "StackForExceptionView",
                                                styles: stackContainerStyles, 
                                                components: [
                                                    {
                                                        type: 'StackItem',
                                                        data: {
                                                            id: 'StackItemForExceptionRequest',
                                                            styles: stackItemCustomerStyles,
                                                            components: [
                                                                {
                                                                    type: "InnerHTMLContainer",
                                                                    data: {
                                                                        id: "ExceptionRequest",
                                                                        contolType: UIControlType.LabelValueView,
                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExceptionRequest", UIControlsText.ExceptionRequest),
                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExceptionRequest", UIControlsText.ExceptionRequest),
                                                                        styles: DefaultPageStyles.oneColumnWidthStyles,
                                                                        value: internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0]?.exceptionRequest || UIControlsText.None,
                                                                    }
                                                                },
                                                            ]
                                                        }
                                                    },
                                                ]
                                            }
                                        },
                                        {
                                            type: 'Stack',
                                            data: {
                                                id: 'emptyHeight31',
                                                components: [
                                                ]
                                            },
                                        },
                                        
                                    ]
                                }
                            },
                            {
                                type: "Stack",
                                visible: (attachmentList?.length > 0 && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachment", UIControlsText.Attachment), internalPricingContextState.pageMode,  props?.internalPricingDealType))
                                    || (endCustomerAttachment?.length > 0 && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProofOfPreference", UIControlsText.ProofOfPreference), internalPricingContextState.pageMode,  props?.internalPricingDealType)),
                                data: {
                                    id: "StackForCustomCardForAttachmentView",
                                    styles: stackContainerStyles,
                                    components: [
                                        {
                                            type: 'CustomTileCardComponent',
                                            data: {
                                                id: "CustomCardForAttachment",
                                                baseState: internalPricingContextState,
                                                name: "Attachment Details",
                                                iconName: UIControlsText.AttachIcon,
                                                components: [
                                                    {
                                                        type: "Stack",
                                                        data: {
                                                            id: "StackforAttachmentsViewMode",
                                                            horizontal: true,
                                                            components: [
                                                                {
                                                                    type: 'StackItem',
                                                                    visible: props?.internalPricingDealType == PageConstants.OTLEDealtype
                                                                            && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProofOfPreference", UIControlsText.ProofOfPreference), internalPricingContextState.pageMode,  props?.internalPricingDealType),
                                                                    data: {
                                                                        id: 'StackItemForProofofEndCustomerPreferenceView',
                                                                        styles: stackItemCustomerStyles,
                                                                        components: [
                                                                            {
                                                                                type: "Attachment",
                                                                                data: {
                                                                                    id: "AttachmentListforProofEndCustomerPreferenceView",
                                                                                    labelText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProofOfPreference", UIControlsText.ProofOfPreference),
                                                                                    source: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProofOfPreference", UIControlsText.ProofOfPreference),
                                                                                    attachmentList: endCustomerAttachment,
                                                                                    pageMode: internalPricingContextState.pageMode,
                                                                                    savedAttachments: endCustomerAttachment || savedAttachments?.filter(x => x?.source == "Proof of End Customer's Preference") || [],
                                                                                    attachmentDisplayMode: AttachmentDisplayMode.Grid,
                                                                                    opportunityDealStatusCode: internalPricingContextState.opportunityDeals[0].statusCode,
                                                                                    disabled: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachment", UIControlsText.Attachment), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                                }
                                                                            }
                                                                        ]
                                                                    }
                                                                },
                                                                {
                                                                    type: 'StackItem',
                                                                    visible: attachmentList.length > 0 
                                                                        && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachment", UIControlsText.Attachment), internalPricingContextState.pageMode,  props?.internalPricingDealType),
                                                                    data: {
                                                                        id: 'StackItemForAttachmentList',
                                                                        styles: stackItemCustomerStyles,
                                                                        components: [
                                                                            {
                                                                                type: "Attachment",
                                                                                data: {
                                                                                    id: "AttachmentList",
                                                                                    labelText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachment", UIControlsText.Attachment),
                                                                                    source: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText),
                                                                                    attachmentList: attachmentList,
                                                                                    handleAttachmentChanges: onAttachmentChange,
                                                                                    pageMode: internalPricingContextState.pageMode,
                                                                                    savedAttachments: attachmentList || savedAttachments?.filter(x => x?.source == getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachment", UIControlsText.Attachment)) || [],
                                                                                    attachmentDisplayMode: AttachmentDisplayMode.Grid,
                                                                                    opportunityDealStatusCode: internalPricingContextState.opportunityDeals[0].statusCode,
                                                                                    disabled: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachment", UIControlsText.Attachment), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, props?.internalPricingDealType),
                                                                                }
                                                                            }
                                                                        ]
                                                                    }
                                                                },
                                                            ]
                                                        }
                                                    }
                                                ]
                                                
                                            }
                                        },
                                    ]
                                }
                            },
                        ]
                    }
                },        
            ]
        }
    };

    return (
        <>
                <Stack id="mdppAccessibility-cardStack" horizontal {...defaultColumnProps} styles={PageStyles.labelStyles}>
                <Stack>
                    {FormRenderingService((internalPricingContextState.pageMode == PageMode.Create || internalPricingContextState.pageMode == PageMode.Edit) ?  DealDetailsJsonForCreateEdit : DealDetailsJsonForView, register, internalPricingContextState)}
                        {   <><hr></hr>
                            <Stack styles={{ root: { width: 1300, paddingRight: 30, paddingBottom: 20} }}>
                            <Paper id="CardForCustomerDetails" elevation={1} style={{border: `0px solid `, borderLeftWidth: "4px" }}>
                                <Stack horizontal wrap verticalAlign='start'>
                                    <StackItem grow styles={{ root: {paddingLeft: 10} }}>
                                        <Stack id="ContainerForDetails" horizontal wrap tokens={smallAlignmentTokens} >
                                            <StackItem>
                                                {cardHeaderIcon(UIControlsText.ContactIconName, "Deal V-Team", UIControlsText.DealVTeamMemberAddtext + 1)}
                                            </StackItem>
                                            <StackItem>
                                                <InnerHTMLContainer id="DetailsField" value={"Deal V-Team"} contolType={UIControlType.SubHeading} styles={{verticalAlign:'center', display:"inline-block", fontWeight:'bold'}}>
                                                </InnerHTMLContainer>
                                            </StackItem>
                                        </Stack>
                                        <div>
                                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                        {isDealteamSectionVisible() && (
                                            <div>
                                                {dealContact.length > 0 && (
                                                    <Stack className={_Styles.LegalEntityLabel} horizontal {...defaultColumnProps}>
                                                        <Col>
                                                            <Label>
                                                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealContactAliasText", UIControlsText.DealContactAliasText)}
                                                            </Label>
                                                        </Col>
                                                        <Col>
                                                            <Label styles={DefaultPageStyles.customWidthForDealTeamLabels}>
                                                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealContactName", UIControlsText.DealContactName)}
                                                            </Label>
                                                        </Col>
                                                        <Col>
                                                            <Label>
                                                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealContactRoleText", UIControlsText.DealContactRoleText)}
                                                            </Label>
                                                        </Col>
                                                        {otherContactIndex !== -1 &&
                                                            <Col>
                                                                <Label styles={DefaultPageStyles.customWidth40Styles}>
                                                                    {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOtherRoleText", UIControlsText.DealOtherRoleText)}
                                                                </Label>
                                                            </Col>}
                                                        <Col>
                                                            <Label styles={DefaultPageStyles.customWidth40Styles}>
                                                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealContactCoOwner", UIControlsText.DealContactCoOwner)}
                                                            </Label>
                                                        </Col>
                                                    </Stack>
                                                )
                                                }
                                                <Stack className={_Styles.LegalEntityLabel} {...defaultColumnProps}>
                                                    {dealContact.map((k, i) => (
                                                        <div key={k.id} style={{ margin: 0 }}>
                                                            <DealContact
                                                                {...{
                                                                    id: k.id,
                                                                    dealContact: k,
                                                                    contactRoles,
                                                                    removeDealContact,
                                                                    updateDealContact,
                                                                    otherRoleStyle: otherLabelStyle,
                                                                    pageMode: internalPricingContextState.pageMode,
                                                                    opportunityDealStatusCode: internalPricingContextState.opportunityDeals[0].statusCode,
                                                                }}
                                                            />
                                                        </div>
                                                    ))}
                                                </Stack>
                                                {isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVTeamMemberAddtext", UIControlsText.DealVTeamMemberAddtext), internalPricingContextState.pageMode,  props?.internalPricingDealType) &&
                                                    <Stack>
                                                        <ActionButton name={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVTeamMemberAddtext", UIControlsText.DealVTeamMemberAddtext)}
                                                            className={CssClassConstants.AddItemButtonClass}
                                                            iconProps={{ iconName: CssClassConstants.AddItemIconName }}
                                                            style={PageStyles.addItemButtonStyles}
                                                            disabled={isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVTeamMemberAddtext", UIControlsText.DealVTeamMemberAddtext), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0].statusCode, undefined, undefined, props?.internalPricingDealType)}
                                                            onClick={() => {
                                                                setDealContact([
                                                                    ...dealContact,
                                                                    { id: Guid.newGuid() },
                                                                ]);
                                                            }}>
                                                            {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVTeamMemberAddtext", UIControlsText.DealVTeamMemberAddtext)}
                                                        </ActionButton>
                                                    </Stack>
                                                }
                                                {internalPricingContextState?.pageMode == PageMode.View && dealContact?.length == 0 &&
                                                    <Stack styles={{ root: { paddingBottom: 20} }}>
                                                        <MessageBar messageBarType={MessageBarType.info}>
                                                            {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "NoDealVTeamMessage", GuidanceText.NoDealVTeamMessage)}
                                                        </MessageBar>
                                                    </Stack>
                                                }
                                            </div>
                                        )}
                                    </Stack>
                                </div>
                                    </StackItem>
                                </Stack>
                            </Paper>
                            </Stack>
                            </>
                        }
                        {   isMicrosoftContactSectionVisible() && (<><hr></hr>
                            <Stack styles={{ root: { width: 1300, paddingRight: 30, paddingBottom: 20} }}>
                            <Paper id="CardForCustomerDetails" elevation={1} style={{border: `0px solid `, borderLeftWidth: "4px" }}>
                                <Stack horizontal wrap verticalAlign='start'>
                                    <StackItem grow styles={{ root: {paddingLeft: 10} }}>
                                        <Stack id="ContainerForDetails" horizontal wrap tokens={smallAlignmentTokens} >
                                            <StackItem>
                                                {cardHeaderIcon(UIControlsText.ContactIconName, "Microsoft Contact")}
                                            </StackItem>
                                            <StackItem>
                                                <InnerHTMLContainer id="DetailsField" value={"Microsoft Contact"} contolType={UIControlType.SubHeading} styles={{verticalAlign:'center', display:"inline-block", fontWeight:'bold'}}>
                                                </InnerHTMLContainer>
                                            </StackItem>
                                        </Stack>
                                        <div>
                                            <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                                {
                                                    <div>
                                                        {
                                                            <Stack className={_Styles.LegalEntityLabel} horizontal {...defaultColumnProps}>
                                                                <Col>
                                                                    <Label>
                                                                        {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealContactAliasText", UIControlsText.DealContactAliasText)}
                                                                    </Label>
                                                                </Col>
                                                                <Col>
                                                                    <Label styles={DefaultPageStyles.customWidthForDealTeamLabels}>
                                                                        {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealContactName", UIControlsText.DealContactName)}
                                                                    </Label>
                                                                </Col>
                                                                <Col>
                                                                    <Label></Label>
                                                                </Col>
                                                                <Col>
                                                                    <Label styles={DefaultPageStyles.customWidth40Styles}></Label>
                                                                </Col>
                                                            </Stack>
                                                        }
                                                        <Stack className={_Styles.LegalEntityLabel} {...defaultColumnProps}>
                                                                    <DealContact
                                                                        {...{
                                                                            dealContact: 
                                                                            {
                                                                                email: internalPricingContextState?.submitterDetails?.email || "",
                                                                                firstName: internalPricingContextState?.submitterDetails?.firstName || "",
                                                                                lastName: internalPricingContextState?.submitterDetails?.lastName || "",
                                                                            },
                                                                            otherRoleStyle: DefaultPageStyles.noColumnMinWidthStyles,
                                                                            pageMode: internalPricingContextState.pageMode,
                                                                            isMicrosoftContact: true
                                                                        }}
                                                                    />
                                                        </Stack>
                                                    </div>
                                                }
                                            </Stack>
                                        </div>
                                    </StackItem>
                                </Stack>
                            </Paper>
                            </Stack>
                            </>
                        )}
                </Stack>
                </Stack>
        </>
    );
}

export default InternalPricingDealOverview;