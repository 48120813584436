import { getTheme, Stack, StackItem } from "office-ui-fabric-react";
import { IBasePageState } from "../../pricingprogram/common/state/IBasePageState";
import { FormRenderingService } from "../Components/DynamicFormRendering";

const DocumentCardContainerComponent = (jsonDataComponents: any | undefined) => {
    let registerProp = jsonDataComponents?.register;
    let baseStateProp = jsonDataComponents?.baseState;
    // Don't render if template is undefined
    if (!jsonDataComponents || jsonDataComponents === undefined) return null;

    return (

        <Stack styles={{ root: { width: jsonDataComponents?.styles, paddingRight: 30, paddingBottom: 20 } }} className={jsonDataComponents?.className}>
            <Stack horizontal wrap verticalAlign='start'>
                <StackItem grow styles={{ root: { paddingLeft: 10 } }}>
                    {jsonDataComponents?.components?.map((eachItem: any, index: number) => {
                        return (
                            <>
                                {FormRenderingService(eachItem, registerProp, baseStateProp as IBasePageState)}
                            </>
                        );
                    })}
                </StackItem>
            </Stack>
        </Stack>
    )
}

export default DocumentCardContainerComponent;