import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { IDropdownFilterCellProps } from "./IDropdownFilterCellProps";
import { UIControlsText } from "../../../content/UIControlsText";
import { MessageTypeText } from '../../../models/MessageTypeText';
import { getRuleValidationMessage, getEntityDataFromSession } from "../../../components/shared/common/util";
import { SessionStorageConsts } from '../../../models/SessionStorageConsts';

const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings)
/**
 * DropdownFilterCell component.
 * @function component
 */
export const DropdownFilterCell = (props: IDropdownFilterCellProps) => {
  let hasValue: any = (value: any) => Boolean(value && value !== props.defaultItem);

  const onChange = (event: DropDownListChangeEvent) => {
    hasValue = hasValue(event.target.value);
    props.onChange({
      value: hasValue ? event.target.value : '',
      operator: hasValue ? 'eq' : '',
      syntheticEvent: event.syntheticEvent
    });
  }

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: '',
      operator: '',
      syntheticEvent: event
    });
  }
  return (
    <div className="k-filtercell">
      <DropDownList
        data={props.data}
        onChange={onChange}
        value={props.value || props.defaultItem}
        defaultItem={props.defaultItem}
      />
      <button
        className="k-button k-button-icon k-clear-button-visible"
        title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ClearAll", UIControlsText.ClearAll)}
        disabled={!hasValue(props.value)}
        onClick={onClearButtonClick}
      >
        <span className="k-icon k-i-filter-clear" />
      </button>
    </div>
  );
}