/**
 * Enum for DealGeoCountryType.
 * @readonly
 * @enum {number}
 */
export enum DealGeoCountryType {

    /**
     * SingleCountryDeal value.
     */
    SingleCountryDeal = 0,

    /**
     * MultiNationalDeal value.
     */
    MultiNationalDeal = 1,
}