/**
 * Enum for All selection dropdown options.
 * @readonly
 * @enum {Enum}
 */
export enum AccountAllType {
    /**
    * All.
    */
    All = 1,
    /**
     * All EUEFTA.
     */
    AllEuefta = 2,
    /**
     * Default.
     */
    None = 3,
}