import { IComponent } from '../../../shared/Components/DynamicFormRendering';
import { DealDetails } from './DealDetails';
import { IPricingProgramProps } from '../props/IPricingProgramProps';
import { UIControlsText, UIConfigIdentifierConstants } from '../../../../content/UIControlsText';
import { Channel } from './Channel';
import { PageStyles } from '../../common/content/Page.Styles';
import { useCommonPPContext } from '../../common/context/common-pp-context';
import { PageMode } from '../../../../models/PageMode';
import { backToDashboard, getFieldConfig, getStatusNameByCode, getRequestorsChannelFunctionalities, isNullOrEmpty, isValidEmailWithSeperator, getRuleValidationMessage, getEntityDataFromSession, getUserDetails, isRequestorViewing, getLoggedinUserRoleDetails } from '../../../../components/shared/common/util';
import { useEffect, useState } from 'react';
import { OpportunityDealStatusCodes } from '../../../../models/OpportunityDealStatusCodes';
import { PartnerType } from '../../../../models/PartnerType';
import { CaseActionTypes } from '../../common/reducer/CaseActionTypes';
import { IAccount } from '../../../../models/IAccount';
import { ErrorMessages } from '../../../../content/ErrorMessages';
import { UIControlType } from '../../../../models/UIControlType';
import { IDropdown, IHoverCard, IPlainCardProps, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { HoverCardStyles, DefaultPageStyles, BreadCrumbStyles, separatorStyles } from '../../../../content/styles/Page.styles';
import { JavaScriptExtensions } from '../../../../infrastructure/JavaScriptExtensions';
import { nextGenService } from '../../../../services/NextGenService';
import { ProductSearch } from './ProductSearch';
import { ChannelSubType } from '../../../../models/ChannelSubType';
import { PartnerTypeLabel, PartnerTypeName } from '../../../../models/PartnerTypeLabel';
import { FieldMapMode } from '../../../../models/FieldMapMode';
import { CustomerSearch } from './CustomerSearch';
import { IPivotTab } from '../../../../models/IPivotTab';
import { StatePropertiesText } from '../../common/state/StatePropertiesText';
import { ApplicationConstants } from '../../../../models/ApplicationConstants';
import { CssClassConstants } from '../../../../content/CssClassConstants';
import { IFacetData } from '../../../../models/IFacetData';
import { getDealQuantityThreshold, isCaseContainsDealType, getSelectedDealDetails, initialChannelDisplayOnEdit, setPartnerDetails, showActionMenuHelper, validateMSStoreEmailErrorMessage } from '../PricingProgramFormUtils';
import { IActionMenuProps } from '../../../../components/shared/action-menu/IActionMenuProps';
import { IOpportunityDealDetails } from '../../../../models/IOpportunityDealDetails';
import { enableActionMenu } from '../../../../components/shared/action-menu/actionMenuUtil';
import { IDealState } from '../../common/state/IDealState';
import { IOpportunityAccount } from '../../../../models/IOpportunityAccount';
import { cloneDeep, isEmpty } from 'lodash';
import { DealType } from '../../../../models/DealType';
import { Guid } from '../../../../infrastructure/Guid';
import { IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import { OpportunityDealClosureDecisionType } from '../../../../models/OpportunityDealClosureDecisionType';
import { ActionType } from '../../../../models/ActionType';
import { PageSubMode } from '../../../../models/PageSubMode';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { IUserRoleDetail } from "../../../../models/IUserRoleDetail";
import _ from 'lodash';

export function CreateForm(props: IPricingProgramProps) {
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const { commonPPContextState, commonPPContextDispatch } = useCommonPPContext();
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const [oppNumber, setOppNumber] = useState<string>("");
    const [showContinueButton, setShowContinueButton] = useState<boolean>(true);
    const [showSubmitButton, setShowSubmitButton] = useState<boolean>(true);
    const [showCommandButtons, setShowCommandButtons] = useState<boolean>(commonPPContextState.selectedPivot !== getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerText", UIControlsText.CustomerText));
    const [isCaseSaving, setIsCaseSaving] = useState(false);
    const [isDraftSaving, setIsDraftSaving] = useState(false);
    const [isSaveDraftButtonClicked, setIsSaveDraftButtonClicked] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
    const [MPNOrgID, setMPNOrgID] = useState<string>("");
    const [MPNLocationID, setMPNLocationID] = useState<string>("");
    const [productGroup, setProductGroup] = useState<string>("");
    const [partnerRole, setPartnerRole] = useState<string>("");
    const [dealNameAction, setdealNameAction] = useState<string>("");
    const [isPartnerDetailHidden, setisPartnerDetailHidden] = useState(false);
    const [caseStatus, setcaseStatus] = useState<string>("");
    const [actionMenuConfig, setActionMenuConfig] = useState<IActionMenuProps>();
    const tabNames: string[] = [getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerText", UIControlsText.CustomerText), getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealInformationText", UIControlsText.DealInformationText), getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText), getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductsText", UIControlsText.ProductsText)];
    const [country, setCountry] = useState<string>("");
    const [isRequestor, setIsRequestor] = useState<boolean>(true);
    useEffect(() => {
        let pivotTabs: IPivotTab[] = [];
        tabNames.forEach(t => pivotTabs.push({ tabName: t, errors: new Map<string, string>(), isDisabled: t === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerText", UIControlsText.CustomerText) ? false : true }))
        commonPPContextDispatch({ type: CaseActionTypes.pivotTabData, pivotTabDetails: pivotTabs })

    }, [])

    /**
     * Submit button click event handler.
     * @method
     */
    const onSubmit = () => {
        if (isCaseComplete()) {
            setIsSaveDraftButtonClicked(false);
            setOpportunityDealProductsGuidToEmpty();
            dispatchBDReviewNeeded();
            commonPPContextDispatch({ type: CaseActionTypes.submitCaseAsync, boolValue: true });
        }
    }

    const dispatchBDReviewNeeded = (): void => {
        if (commonPPContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()) {
            commonPPContextState.opportunityDeals?.forEach(t => {
                if(t.statusCode || "" === OpportunityDealStatusCodes.Approved.toString()) {
                    if ((t?.dealVolume?.toString() !== t?.savedDealVolume?.toString()) || 
                    ((t.products?.filter(p => p.action?.toLowerCase() === ActionType.Add.toLowerCase()).length || 0) > 0)) {
                        commonPPContextDispatch({ type: CaseActionTypes.closureDecisionChanged, closureDecision: { key: t?.id, value: OpportunityDealClosureDecisionType.EditPendingReview } });
                    }
                }
            });
        }
    }

    //onSaveDraft click event
    const onSaveDraft = () => {
        setIsSaveDraftButtonClicked(true);
        setOpportunityDealProductsGuidToEmpty();
        commonPPContextDispatch({ type: CaseActionTypes.saveDraft, boolValue: true });
    }

    const setOpportunityDealProductsGuidToEmpty = () => {
        for (let i = 0; i < commonPPContextState.opportunityDeals.length; i++) {
            let opportunityDeal = cloneDeep(commonPPContextState.opportunityDeals[i]);
            if (opportunityDeal.products) {
                let products = [...opportunityDeal.products];
                let deal = opportunityDeal?.dealType?.name || "";
                if (deal === DealType.SpecialPricing) {
                    products.forEach((item) => (item.businessUnitId = Guid.emptyGuid()));
                }
                else if (deal === DealType.DealRegistration) {
                    products.forEach((item) => (item.productFamilyId = Guid.emptyGuid(), item.name = item.businessUnitName || ""));
                }
                commonPPContextState.opportunityDeals[i].products = [...products];
            }
        }
    }

    //onContinue click event
    const onContinue = () => {
        let tabName = commonPPContextState.selectedPivot;
        if (tabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealInformationText", UIControlsText.DealInformationText)) {
            tabName = getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText);
        }
        else if (tabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)) {
            tabName = getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductsText", UIControlsText.ProductsText);
        }

        commonPPContextDispatch({ type: CaseActionTypes.casePivotChanged, selectedPivot: tabName });
    }

    //Note : Need to add the Validations
    const isCaseComplete = (): boolean | undefined => {
        let validationErrors: string[] = [];
        isDealInformationTabIncomplete(validationErrors);
        isChannelTabIncomplete(validationErrors);
        isCutomerTabIncomplete(validationErrors);
        isProductTabIncomplete(validationErrors);

        validationErrors = _.uniq(validationErrors);
        if (validationErrors.filter(t => t !== "").length === 0) {
            return true
        }
        else {
            const pivotTab = commonPPContextState?.pivotTabs?.find(t => t.isValid === false);
            commonPPContextDispatch({ type: CaseActionTypes.casePivotChanged, selectedPivot: pivotTab?.tabName });
            return false;
        }
    }

    const isProductTabIncomplete = (errors: string[]): boolean | undefined => {
        let productTabErrors: string[] = [];
        let optyDeals : IDealState[] = [];
        if(commonPPContextState && !JavaScriptExtensions.isEmptyOrNullOrUndefined(commonPPContextState?.selectedOptyDealCaseNumber)) {
            optyDeals = commonPPContextState.opportunityDeals.filter(t => t.name === (commonPPContextState.selectedOptyDealCaseNumber)) || [];
        }
        else {
            optyDeals = commonPPContextState.opportunityDeals;
        }

        const pivotTabsData = commonPPContextState.pivotTabs;
        let pivotTab = pivotTabsData?.find(t => t.tabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductsText", UIControlsText.ProductsText));
        let message = getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText);

        optyDeals?.forEach(t => {
            if(message === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText) && (JavaScriptExtensions.isNullOrUndfinedOrEmpty(t?.products) || t?.products?.length === 0)) {
                message = '';
            }
        });

        productTabErrors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidateProduct", ErrorMessages.ValidateProduct), StatePropertiesText.ValidateProduct, message, pivotTab));
        productTabErrors = productTabErrors.filter(t => !JavaScriptExtensions.isEmptyOrNullOrUndefined(t));
        if (pivotTab) {
            pivotTab.isValid = productTabErrors.length === 0;
        }
        commonPPContextDispatch({ type: CaseActionTypes.pivotTabData, pivotTabDetails: pivotTabsData });
        errors.push(...productTabErrors);
        return errors.length > 0
    }

    const isCutomerTabIncomplete = (errors: string[]): boolean | undefined => {
        let customerTabErrors: string[] = [];
        let optyDeals : IDealState[] = [];
        if(commonPPContextState && !JavaScriptExtensions.isEmptyOrNullOrUndefined(commonPPContextState?.selectedOptyDealCaseNumber)) {
            optyDeals = commonPPContextState.opportunityDeals.filter(t => t.name === (commonPPContextState.selectedOptyDealCaseNumber)) || [];
        }
        else {
            optyDeals = commonPPContextState.opportunityDeals;
        }
        const pivotTabsData = commonPPContextState.pivotTabs;
        let pivotTab = pivotTabsData?.find(t => t.tabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerText", UIControlsText.CustomerText));
        let message = getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText);

        optyDeals?.forEach(t => {
            if(message === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText) && (JavaScriptExtensions.isNullOrUndfinedOrEmpty(t?.dealCustomers) || t?.dealCustomers?.length === 0)) {
                message = '';
            }
        });

        customerTabErrors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidateCustomer", ErrorMessages.ValidateCustomer), StatePropertiesText.ValidateCustomer, message, pivotTab));
        customerTabErrors = customerTabErrors.filter(t => !JavaScriptExtensions.isEmptyOrNullOrUndefined(t));
        if (pivotTab) {
            pivotTab.isValid = customerTabErrors.length === 0;
        }
        commonPPContextDispatch({ type: CaseActionTypes.pivotTabData, pivotTabDetails: pivotTabsData });
        errors.push(...customerTabErrors);
        return errors.length > 0
    }

    const isDealInformationTabIncomplete = (errors: string[]): boolean | undefined => {
        let dealInfoTabErrors: string[] = [];
        let optyDeals: IDealState[] = [];
        const pivotTabsData = commonPPContextState.pivotTabs;
        var pivotTab = pivotTabsData?.find(t => t.tabName === UIControlsText.DealInformationText);
        const dealData = commonPPContextState?.opportunityDeals.find((t: IDealState) => t.statusCode !== OpportunityDealStatusCodes.Draft);
        if(commonPPContextState && dealData && !_.isEmpty(dealData) && !JavaScriptExtensions.isEmptyOrNullOrUndefined(commonPPContextState?.selectedOptyDealCaseNumber)) {
            optyDeals = commonPPContextState.opportunityDeals.filter(t => t.name === (commonPPContextState.selectedOptyDealCaseNumber));
            optyDeals?.forEach((t: IDealState) => {
                if(t?.dealType?.name === DealType.DealRegistration) {
                    dealInfoTabErrors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoPreSalesRequired", ErrorMessages.DealInfoPreSalesRequired), StatePropertiesText.PreSalesActivitiesText, t?.preSalesActivity, pivotTab));
                }
            });
        }
        else {
            optyDeals = commonPPContextState.opportunityDeals;
            optyDeals?.forEach((t: IDealState) => {
                if(commonPPContextState.opportunityDeals?.length > 0 && isCaseContainsDealType(commonPPContextState, DealType.DealRegistration)) {
                    dealInfoTabErrors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoPreSalesRequired", ErrorMessages.DealInfoPreSalesRequired), StatePropertiesText.PreSalesActivitiesText, t?.preSalesActivity, pivotTab));
                }
            });
        }
        
        const selectedDealMinimumThreshold = getDealQuantityThreshold(commonPPContextState?.opportunityDeals, commonPPContextState?.partnerDetails?.productGroupName || "", commonPPContextState?.selectedOptyDealCaseNumber || "", commonPPContextState?.pageMode || "") || -1;
        optyDeals?.forEach((t: IDealState) => {
            const dealCustomer = t?.dealCustomers && t?.dealCustomers[0];
            dealInfoTabErrors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoDealNameRequired", ErrorMessages.DealInfoDealNameRequired), StatePropertiesText.DealNameText, t?.dealName, pivotTab));
            dealInfoTabErrors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoDealVerticalRequired", ErrorMessages.DealInfoDealVerticalRequired), StatePropertiesText.DealVerticalText, t?.dealVertical, pivotTab));
            dealInfoTabErrors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoCustomerDomainRequired", ErrorMessages.DealInfoCustomerDomainRequired), StatePropertiesText.CustomerDomain, dealCustomer?.dealCustomerDomain, pivotTab));
            dealInfoTabErrors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoDeviceShipToAddressRequired", ErrorMessages.DealInfoDeviceShipToAddressRequired), StatePropertiesText.DeviceShipToAddressText, t?.dealAddress?.address1, pivotTab));
            dealInfoTabErrors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoCityRequired", ErrorMessages.DealInfoCityRequired), StatePropertiesText.DeviceShipToCityText, t?.dealAddress?.city, pivotTab));
            dealInfoTabErrors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoStateRequired", ErrorMessages.DealInfoStateRequired), StatePropertiesText.DeviceShipToStateProvinceText, t?.dealAddress?.stateOrProvince, pivotTab));
            dealInfoTabErrors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoPostalCodeRequired", ErrorMessages.DealInfoPostalCodeRequired), StatePropertiesText.DeviceShipToPostalCodeText, t?.dealAddress?.postalCode, pivotTab));
            dealInfoTabErrors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoDealOverviewRequired", ErrorMessages.DealInfoDealOverviewRequired), StatePropertiesText.DealOverviewText, t?.dealOverview, pivotTab));
            dealInfoTabErrors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoCommittedBlankDealVolume", ErrorMessages.DealInfoCommittedBlankDealVolume), StatePropertiesText.DealVolumeText, t?.dealVolume?.toString(), pivotTab));
            if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(t?.dealVolume?.toString())) {
                const dealVolume = t?.dealVolume?.toString() || "0";
                if (parseInt(dealVolume) === 0) {
                    dealInfoTabErrors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoCommittedZeroDealVolume", ErrorMessages.DealInfoCommittedZeroDealVolume), StatePropertiesText.DealVolumeText, "", pivotTab));
                }
                else if (parseInt(dealVolume) < selectedDealMinimumThreshold && selectedDealMinimumThreshold !== -1) {
                    dealInfoTabErrors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoCommittedMinimumThreshold", ErrorMessages.DealInfoCommittedMinimumThreshold), StatePropertiesText.DealVolumeText, "", pivotTab));
                }
            }
        });

        if (commonPPContextState.opportunityRfp) {
            const dealInfoAttachments = commonPPContextState.attachments?.filter(x => x?.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText)) || [];
            if (JavaScriptExtensions.isNullOrUndfinedOrEmpty(dealInfoAttachments) || dealInfoAttachments?.length === 0) {
                dealInfoTabErrors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoRfpJustificationRequired", ErrorMessages.DealInfoRfpJustificationRequired), StatePropertiesText.RfpJustification, commonPPContextState?.rfpJustification, pivotTab));
            }
        }

        const emailCC = commonPPContextState?.opportunityChannelContacts && commonPPContextState?.opportunityChannelContacts.find(t => t.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText))?.channelContactEmails;
        if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(emailCC)) {
            const isComplete = isValidEmailWithSeperator(emailCC || "", ';');
            if (!isComplete) {
                dealInfoTabErrors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidEmailError", ErrorMessages.ValidEmailError), StatePropertiesText.EmailCCNotificationRecipients, '', pivotTab));
            }
        }

        dealInfoTabErrors = dealInfoTabErrors.filter(t => !JavaScriptExtensions.isEmptyOrNullOrUndefined(t));
        if (pivotTab) {
            pivotTab.isValid = dealInfoTabErrors.length === 0;
        }
        commonPPContextDispatch({ type: CaseActionTypes.pivotTabData, pivotTabDetails: pivotTabsData });
        errors.push(...dealInfoTabErrors);
        return errors.length > 0
    }

    const isChannelTabIncomplete = (errors: string[]): boolean | undefined => {
        let requestorsChannelFunctionality = getRequestorsChannelFunctionalities(commonPPContextState.partnerDetails?.userRole || "", commonPPContextState?.partnerDetails?.countryName || "")
        initialChannelDisplayOnEdit(commonPPContextState, requestorsChannelFunctionality);
        const pivotTabsData = commonPPContextState.pivotTabs;
        let pivotTab = pivotTabsData?.find(t => t.tabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)) || {};
        let channelTabErrors: string[] = [];
        if (requestorsChannelFunctionality.MSStore) {
            validateChannelMsStore(channelTabErrors, pivotTab);
        }

        else if (requestorsChannelFunctionality.FinalTierAndDistributors) {
            if (commonPPContextState.isFinalOrAdditionalReseller) {
                validatePartner(channelTabErrors, [PartnerType.FinalTierReseller], getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidateFinalReseller", ErrorMessages.ValidateFinalReseller), StatePropertiesText.ValidateFinalOrAdditionalReseller, pivotTab)
            }
            validatePartner(channelTabErrors, [PartnerType.ADD], getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidatePrimaryDistributorOnly", ErrorMessages.ValidatePrimaryDistributorOnly), StatePropertiesText.ValidateMultipleDistributors, pivotTab);
            validatePartner(channelTabErrors, [PartnerType.ADR, PartnerType.DMP], getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidatePrimaryResellersOnly", ErrorMessages.ValidatePrimaryResellersOnly), StatePropertiesText.ValidatePrimaryResellers, pivotTab);
        }

        else if (requestorsChannelFunctionality.AdditionalAndDistributors) {
            if (commonPPContextState.isFinalOrAdditionalReseller) {
                validatePartner(channelTabErrors, [PartnerType.AdditionalTierReseller], getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidateAdditionalReseller", ErrorMessages.ValidateAdditionalReseller), StatePropertiesText.ValidateFinalOrAdditionalReseller, pivotTab)
            }
            validatePartner(channelTabErrors, [PartnerType.ADD], getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidatePrimaryDistributorOnly", ErrorMessages.ValidatePrimaryDistributorOnly), StatePropertiesText.ValidateMultipleDistributors, pivotTab);
            validatePartner(channelTabErrors, [PartnerType.ADR, PartnerType.DMP], getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidatePrimaryResellersOnly", ErrorMessages.ValidatePrimaryResellersOnly), StatePropertiesText.ValidatePrimaryResellers, pivotTab);
        }

        else if (requestorsChannelFunctionality.PrimaryandAdditionalResellers) {
            if (commonPPContextState.isFinalOrAdditionalReseller) {
                validatePartner(channelTabErrors, [PartnerType.AdditionalTierReseller], getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidateAdditionalReseller", ErrorMessages.ValidateAdditionalReseller), StatePropertiesText.ValidateFinalOrAdditionalReseller, pivotTab)
            }
            validatePartner(channelTabErrors, [PartnerType.ADR, PartnerType.DMP], getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidatePrimaryResellersOnly", ErrorMessages.ValidatePrimaryResellersOnly), StatePropertiesText.ValidatePrimaryResellers, pivotTab)
            validatePartner(channelTabErrors, [PartnerType.ADD], getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidatePrimaryDistributorOnly", ErrorMessages.ValidatePrimaryDistributorOnly), StatePropertiesText.ValidateMultipleDistributors, pivotTab);
        }

        else if (requestorsChannelFunctionality.PrimaryAndFinalTierResellers) {
            if (commonPPContextState.isFinalOrAdditionalReseller) {
                validatePartner(channelTabErrors, [PartnerType.FinalTierReseller], getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidateFinalReseller", ErrorMessages.ValidateFinalReseller), StatePropertiesText.ValidateFinalOrAdditionalReseller, pivotTab)
            }
            validatePartner(channelTabErrors, [PartnerType.ADR, PartnerType.DMP], getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidatePrimaryResellersOnly", ErrorMessages.ValidatePrimaryResellersOnly), StatePropertiesText.ValidatePrimaryResellers, pivotTab);
            validatePartner(channelTabErrors, [PartnerType.ADD], getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidatePrimaryDistributorOnly", ErrorMessages.ValidatePrimaryDistributorOnly), StatePropertiesText.ValidateMultipleDistributors, pivotTab);
        }

        else if (requestorsChannelFunctionality.PrimaryResellersOnly) {
            validatePartner(channelTabErrors, [PartnerType.ADR, PartnerType.DMP], getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidatePrimaryResellersOnly", ErrorMessages.ValidatePrimaryResellersOnly), StatePropertiesText.ValidatePrimaryResellers, pivotTab);
            validatePartner(channelTabErrors, [PartnerType.ADD], getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidatePrimaryDistributorOnly", ErrorMessages.ValidatePrimaryDistributorOnly), StatePropertiesText.ValidateMultipleDistributors, pivotTab);
        }

        else if (requestorsChannelFunctionality.MultipleDistributorsOnly) {
            validatePartner(channelTabErrors, [PartnerType.ADD], getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidatePrimaryDistributorOnly", ErrorMessages.ValidatePrimaryDistributorOnly), StatePropertiesText.ValidateMultipleDistributors, pivotTab)
            validatePartner(channelTabErrors, [PartnerType.ADR, PartnerType.DMP], getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidatePrimaryResellersOnly", ErrorMessages.ValidatePrimaryResellersOnly), StatePropertiesText.ValidatePrimaryResellers, pivotTab)
        }

        if (!requestorsChannelFunctionality.MSStore) {
            const requesterAccount = commonPPContextState.opportunityAccounts?.find(t => t.channelSubType === ChannelSubType.Requester);
            const optyChannelContact = commonPPContextState?.opportunityChannelContacts?.filter(t => t.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText) && requesterAccount?.id !== t.opportunityAccountId);
            optyChannelContact?.forEach(t => validateChannelContactEmails(t?.channelContactEmails || "", t?.opportunityAccountId, channelTabErrors, pivotTab));
        }

        channelTabErrors = channelTabErrors.filter(t => !JavaScriptExtensions.isEmptyOrNullOrUndefined(t));
        if (pivotTab) {
            pivotTab.isValid = channelTabErrors.length === 0;
        }
        commonPPContextDispatch({ type: CaseActionTypes.pivotTabData, pivotTabDetails: pivotTabsData });
        errors.push(...channelTabErrors);
        return errors.length > 0
    }

    const validatePartner = (errors: string[], partnerTypes: PartnerType[], errorMessage: string, targetProperty: string, pivotTab: IPivotTab) => {
        const optyAccounts = commonPPContextState?.opportunityAccounts?.filter(t => partnerTypes.find(x => t.partnerType === x) != null) || [];
        /** Name validation for Distributor and Reseller addressing bug - 10132737,370957598 Reseller Name is blank during Case Submission **/
        let validAccount = partnerTypes.find(x => PartnerType.AdditionalTierReseller === x || PartnerType.FinalTierReseller === x) != null ? optyAccounts?.filter(item => item && item.accountCompanyName) : 
                optyAccounts?.filter(item=>item.accounts != null && item.accounts.length && (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(item.accounts[0].accountId)));
        let message = getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText);
        if (JavaScriptExtensions.isNullOrUndfinedOrEmpty(optyAccounts) || optyAccounts.length <= 0 || validAccount.length <= 0) {
            message = '';
        }
 
            errors.push(validateData(errorMessage, targetProperty, message, pivotTab));
    }

    const validateChannelMsStore = (errors: string[], pivotTab: IPivotTab) => {
        const optyAccounts = commonPPContextState.opportunityAccounts;
        var msStoreAccount = optyAccounts?.find(t => t.channelSubType === ChannelSubType.DigitalStore || t.channelSubType === ChannelSubType.DSS);
        var channelContactEmail = commonPPContextState?.opportunityChannelContacts?.find(c => c.opportunityAccountId === msStoreAccount?.id)?.channelContactEmails || "";
        const hubErrorMessage = msStoreAccount?.channelSubType === ChannelSubType.DSS ? getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredMSStoreHub", ErrorMessages.RequiredMSStoreHub) : getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredMSStoreDigitalStore", ErrorMessages.RequiredMSStoreDigitalStore);
        errors.push(validateData(hubErrorMessage, StatePropertiesText.PartnerAddress, msStoreAccount?.address, pivotTab));
        errors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredCity", ErrorMessages.RequiredCity), StatePropertiesText.PartnerCity, msStoreAccount?.city, pivotTab));
        if (msStoreAccount?.channelSubType === ChannelSubType.DigitalStore) {
            errors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredState", ErrorMessages.RequiredState), StatePropertiesText.PartnerState, msStoreAccount?.stateProvince, pivotTab));
        }
        errors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredMSStoreSellerEmail", ErrorMessages.RequiredMSStoreSellerEmail), StatePropertiesText.PartnerSellerName, msStoreAccount?.accountCompanyName, pivotTab));
        errors.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredMSStoreSellerEmail", ErrorMessages.RequiredMSStoreSellerEmail), StatePropertiesText.ValidateEmailText + msStoreAccount?.id, channelContactEmail, pivotTab));
        if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(channelContactEmail)) {
            const errorMessage = validateMSStoreEmailErrorMessage(channelContactEmail);
            const message = JavaScriptExtensions.isEmptyOrNullOrUndefined(errorMessage) ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText) : '';
            errors.push(validateData(errorMessage, StatePropertiesText.ValidateEmailText + msStoreAccount?.id, message, pivotTab));
        }

    }

    const validateChannelContactEmails = (email: string, optyAccountId?: string, errors?: string[], pivotTab?: IPivotTab) => {
        let message = getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText));
        if (JavaScriptExtensions.isEmptyOrNullOrUndefined(email) || !isValidEmailWithSeperator(email || "", ';')) {
            message = '';
        }
        errors?.push(validateData(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidEmailError", ErrorMessages.ValidEmailError), StatePropertiesText.ValidateEmailText + optyAccountId, message, pivotTab));
    }

    const validateData = (errorMessage: string, targetPropertyId: string, value: string | undefined, pivotTab?: IPivotTab): string => {
        if (JavaScriptExtensions.isEmptyOrNullOrUndefined(value)) {
            pivotTab?.errors?.set(targetPropertyId, errorMessage);
            return errorMessage;
        }
        else {
            pivotTab?.errors?.delete(targetPropertyId);
            return '';
        }
    }

    useEffect(() => {
        if (commonPPContextState.submitCaseAsync) {
            (async () => {
                // don't save again while we are saving
                if (isCaseSaving) {
                    return
                }

                try {
                    setIsCaseSaving(true);
                    setIsFormLoading(true);
                    const userRoleDetails: IUserRoleDetail[] = getLoggedinUserRoleDetails();
                    let opportunityData = {...commonPPContextState};
                    opportunityData.opportunityDeals?.forEach(deal => {
                        if(deal.statusCode === OpportunityDealStatusCodes.DraftRenew || (deal.statusCode === OpportunityDealStatusCodes.Unassigned && deal.isRenewed)){
                            deal?.dealCustomers?.forEach(c => {
                                c.action = "";
                            });
                            deal?.geoCountries?.forEach(g => {
                                g.action = "";
                            });
                            deal?.products?.forEach(g => {
                                g.action = "";
                            });
                        }
                    })
                    let optyDeal: IDealState = {};
                    if(commonPPContextState && !JavaScriptExtensions.isEmptyOrNullOrUndefined(commonPPContextState?.selectedOptyDealCaseNumber)) {
                        optyDeal = commonPPContextState.opportunityDeals.find(t => t.name === (commonPPContextState.selectedOptyDealCaseNumber)) || {};
                    }
                    else {
                        optyDeal = commonPPContextState.opportunityDeals && commonPPContextState.opportunityDeals[0];
                    }
        
                    if(optyDeal.statusCode === OpportunityDealStatusCodes.DraftRenew) {
                        optyDeal.statusCode = OpportunityDealStatusCodes.Draft;
                        optyDeal.geoCountries?.forEach(g => {
                            g.action = ActionType.Add;
                        });
                        optyDeal?.dealCustomers?.forEach(c => {
                            c.action = ActionType.Add;
                        });
                        optyDeal?.products?.forEach(p => {
                            p.action = ActionType.Add;;
                        });
                    }
                    
                    if (userRoleDetails !== undefined && userRoleDetails.length > 0 && opportunityData.submitterDetails !== undefined) {
                        opportunityData.submitterDetails.userRoleDetails = userRoleDetails;
                    }

                    var isSaved = await nextGenService.submitOpportunityAsync(opportunityData);
                    if (isSaved.status === 200) {
                        sessionStorage.setItem(SessionStorageConsts.pageMode, PageMode.View);
                        setOppNumber(isSaved.data);
                        if (opportunityData?.pageMode === PageMode.Create || opportunityData?.opportunityDeals?.filter(t => t.statusCode === OpportunityDealStatusCodes.Draft)?.length > 0) {
                            setIsFormLoading(false);
                            setShowSuccessMessage(true);
                        }
                        else {
                            backToDashboard()
                        }
                    }
                }
                catch (error: any) {
                    setIsFormLoading(false);
                    if (error && error.response.status === 422) {
                        //resetLegalEntities(error.response);
                        alert('Error' + error);
                    }
                    else {
                        alert('Error' + error);
                    }
                }
                finally {
                    setIsCaseSaving(false);
                    commonPPContextDispatch({ type: CaseActionTypes.submitCaseAsync, boolValue: false });
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commonPPContextState.submitCaseAsync])

    useEffect(() => {
        (async () => {
            if (commonPPContextState.saveDraft) {
                // don't save again while we are saving
                if (isDraftSaving) {
                    return
                }

                try {
                    setIsDraftSaving(true);
                    setIsFormLoading(true);
                    var isSaved = await nextGenService.saveDraftOpportunity(commonPPContextState);
                    if (isSaved.status === 200) {
                        sessionStorage.setItem(SessionStorageConsts.pageMode, PageMode.View);
                        setIsFormLoading(false);
                        setOppNumber(isSaved.data);
                        setShowSuccessMessage(true);
                    }
                }
                catch (error: any) {
                    if (error && error.response.status === 422) {
                        setIsFormLoading(false);
                        alert('Error' + error);
                        //resetLegalEntities(error.response);
                    }
                    else {
                        alert(error);
                    }
                }
                finally {
                    setIsDraftSaving(false);
                    commonPPContextDispatch({ type: CaseActionTypes.saveDraft, boolValue: false });
                }
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commonPPContextState.saveDraft])

    useEffect(() => {
        if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(commonPPContextState.opportunityDeals)
            && commonPPContextState.opportunityDeals.length > 0
            && !JavaScriptExtensions.isEmptyOrNullOrUndefined(commonPPContextState.opportunityDeals[0].dealType?.name)) {
            const pivotTabsData = commonPPContextState.pivotTabs;
            pivotTabsData?.forEach(t => t.isDisabled = false);
            commonPPContextDispatch({ type: CaseActionTypes.pivotTabData, pivotTabsData: pivotTabsData });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commonPPContextState.opportunityDeals && commonPPContextState.opportunityDeals[0] && commonPPContextState.opportunityDeals[0].dealType])

    const [resellers, setResellers] = useState<IAccount[]>([]);

    const distributorItems = {
        current: JSON.parse(sessionStorage.distributors)
    }

    const onResellers = (resellers: IAccount[]) => {
        setResellers(resellers);
    }

    useEffect(() => {
        //to read partner details in ui
        if (props.pageContext?.pageMode === PageMode.Edit) {
            const oppDealNumber = commonPPContextState.selectedOptyDealCaseNumber;
            if (commonPPContextState.name && oppDealNumber && oppDealNumber.length > 0) {
                var oppDeal = commonPPContextState.opportunityDeals.filter(d => d.name === oppDealNumber)[0];
                if (oppDeal) {
                    let partnerdetails = setPartnerDetails(commonPPContextState, props.productTypes, props.countries);
                    var requester = commonPPContextState.opportunityAccounts?.find(acc => acc.channelSubType === ChannelSubType.Requester);
                    if (requester && requester.accounts) {
                        setMPNOrgID(requester.accounts[0].organizationId || "");
                        var MPNLocAndCountry = (requester.accounts[0].locationId || "").concat(requester.accounts[0].locationId ? " / " : "").concat(partnerdetails.countryName || "")
                        setMPNLocationID(MPNLocAndCountry);
                        setCountry(requester.accounts[0].countryName || "");
                        var RoleName = PartnerTypeName.get(requester?.partnerType || 0) || "";
                        var RoleValue = PartnerTypeLabel.get(requester?.partnerType || 0) || "";
                        setPartnerRole(RoleName.concat(" (", RoleValue, ")"));
                    }
                    setIsRequestor(isRequestorViewing(commonPPContextState.submitterDetails?.email));
                    commonPPContextDispatch({ type: CaseActionTypes.partnerDetailsChanged, partnerDetails: partnerdetails })
                    if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(requester)) {
                        const oppDealdetails = showActionMenuHelper(commonPPContextState, oppDeal, requester);
                        const actionMenuConfig = enableActionMenu(oppDealdetails);
                        actionMenuConfig.showCPStrategicLetters = false // hiding manufacturers confirmation letter action when viewing DR/SP case
                        setActionMenuConfig({ actionConfig: actionMenuConfig, selectedData: getSelectedDealDetails(oppDealdetails,  oppDeal)});
                    }

                    //to read case status from context
                    var caseStatus = "Case Status - " + getStatusNameByCode(oppDeal.statusCode);
                    setcaseStatus(caseStatus);

                    // to read case name with action from context and show in ui
                    var dealNameAndAction = ''
                    if (props.pageContext?.pageSubMode === PageSubMode.Copy || props.pageContext?.pageSubMode === PageSubMode.Renew) {
                        dealNameAndAction = commonPPContextState.name ? commonPPContextState.name + " | " + oppDeal.dealType?.name : "";
                    } else {
                        dealNameAndAction = commonPPContextState.name ? commonPPContextState.name + " | " + getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EditCaseText", UIControlsText.EditCaseText) + " | " + oppDeal.dealType?.name : "";
                    }
                    
                    setdealNameAction(dealNameAndAction);

                    //to show partner details only for Partners
                    var ispartnerHidden = getFieldConfig("commonPP", commonPPContextState.pageMode || "", oppDeal.statusCode || 0, oppDeal.dealType || {}, getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerInformation", UIControlsText.PartnerInformation), FieldMapMode.Hidden) || false;

                    setisPartnerDetailHidden(ispartnerHidden);
                }
            }
        }
    }, [commonPPContextState.opportunityAccounts, resellers, commonPPContextState.pageMode, commonPPContextState.selectedOptyDealCaseNumber]);

    useEffect(() => {
        if (!isNullOrEmpty(props?.partnerDetails)) {
            commonPPContextDispatch({ type: CaseActionTypes.productGroupChanged, productGroupValue: props?.partnerDetails?.productGroupId })
            commonPPContextDispatch({ type: CaseActionTypes.partnerDetailsChanged, partnerDetails: props?.partnerDetails })
        }
    }, [props?.partnerDetails]);

    // to show action menu on edit screen
    const showActionMenu = (oppDeal: IDealState, requester?: IOpportunityAccount) => {
        //start: show action menu in view form object
        let oppDealdetails: IOpportunityDealDetails = {} as IOpportunityDealDetails
        oppDealdetails.id = oppDeal.id || "";
        if (commonPPContextState.opportunityAccounts) {
            oppDealdetails.opportunityAccounts = commonPPContextState.opportunityAccounts;
            oppDealdetails.dealContacts = oppDeal.dealContacts;
            oppDealdetails.submitterDetails = commonPPContextState.submitterDetails;
        }
        oppDealdetails.dealName = oppDeal.dealName || "";
        oppDealdetails.dealType = oppDeal.dealType || {};
        oppDealdetails.statusCodeName = getStatusNameByCode(oppDeal?.statusCode);
        if (oppDeal?.statusCode) {
            oppDealdetails.statusCode = oppDeal?.statusCode;
        }
        if (requester) {
            var usertype: Array<string> = [];
            usertype.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Requester", UIControlsText.Requester))
            oppDealdetails.userType = usertype;
        }
        oppDealdetails.opportunityName = commonPPContextState.name || "";
        oppDealdetails.dealExtensionCount = oppDeal.dealExtensionCount || 0
        oppDealdetails.unitsTransacted = oppDeal.unitsTransacted || 0
        oppDealdetails.unitsCommitted = oppDeal.dealVolume || 0
        oppDealdetails.expirationDate = oppDeal.expirationDate
        var selectedData: any = oppDealdetails;
        selectedData.name = oppDeal.name;
        var ISToffSet = 330;
        var offset = ISToffSet * 60 * 1000;
        var casestartDate = new Date(oppDeal.caseStartDate || "" + offset);
        if (casestartDate) {
            selectedData.caseStartDate = casestartDate;
        }
        const actionMenuConfig = enableActionMenu(oppDealdetails);
        actionMenuConfig.showCPStrategicLetters = false // hiding manufacturers confirmation letter action when editing DR/SP case

        setActionMenuConfig({ actionConfig: actionMenuConfig, selectedData: selectedData });
        //end: show action menu in view form object
    }

    /**
    * gets partner details tool tip 
    * @returns partner details template
    */
    const renderPartnerDetails = () => {
        if (isNullOrEmpty(props?.partnerDetails)) {
            return (
                <div className="table-content">
                </div>
            );
        }
        else {
            return (
                <div className="table-content">
                    {props?.partnerDetails?.organizationId && props?.partnerDetails?.organizationId.length > 0 && <div className="table-row">
                        <span className="table-cell">{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MPNOrgID", UIControlsText.MPNOrgID)}</span> <span className="table-cell"> {props?.partnerDetails?.organizationId} </span></div>
                    }
                    {props?.partnerDetails?.mpnLocation && props?.partnerDetails?.mpnLocation.length > 0 && <div className="table-row">
                        <span className="table-cell">{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MPNLocationID", UIControlsText.MPNLocationID)}</span> <span className="table-cell">{props?.partnerDetails?.mpnLocation}</span> </div>
                    }
                    {props?.partnerDetails?.productGroupName && props?.partnerDetails?.productGroupName.length > 0 && <div className="table-row">
                        <span className="table-cell">{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealProductGroupText", UIControlsText.DealProductGroupText)}:</span> <span className="table-cell"> {props?.partnerDetails?.productGroupName}</span></div>
                    }
                    {props?.partnerDetails?.userRole && props?.partnerDetails?.userRole.length > 0 && <div className="table-row">
                        <span className="table-cell">{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerRole", UIControlsText.PartnerRole)}:</span> <span className="table-cell">{props?.partnerDetails?.userRole}</span></div>
                    }
                </div>
            );
        }

    }

    const hoverCard = React.useRef<IHoverCard>(null);
    /**
    * hover card props
    */
    const plainCardProps: IPlainCardProps = {
        onRenderPlainCard: renderPartnerDetails,
    };

    /**
    * tooltip icon styles
    */
    const iconStyles = mergeStyleSets({
        blue: [DefaultPageStyles.marginRight, DefaultPageStyles.iconBlueColor],
    })

    useEffect(() => {
        showButtonsBasedonTabChange(commonPPContextState.selectedPivot || "");
    }, [commonPPContextState.selectedPivot])

    const handleTabSelectionChanges = (selectedTabName: string) => {
        showButtonsBasedonTabChange(selectedTabName);
        commonPPContextDispatch({ type: CaseActionTypes.casePivotChanged, selectedPivot: selectedTabName });
    }

    const showButtonsBasedonTabChange = (selectedTabName: string) => {
        const selectedDeal = commonPPContextState?.opportunityDeals.find(t => t.name === commonPPContextState.selectedOptyDealCaseNumber);
        setShowContinueButton(selectedTabName !== getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductsText", UIControlsText.ProductsText));
        setShowCommandButtons(selectedTabName !== getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerText", UIControlsText.CustomerText));
        if((commonPPContextState.pageMode === PageMode.Create && selectedTabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductsText", UIControlsText.ProductsText)) 
            || (commonPPContextState.pageMode === PageMode.Edit && (selectedDeal?.statusCode === OpportunityDealStatusCodes.Draft || selectedDeal?.statusCode === OpportunityDealStatusCodes.DraftRenew)  && selectedTabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductsText", UIControlsText.ProductsText)) 
            || (commonPPContextState.pageMode === PageMode.Edit && selectedDeal?.statusCode === OpportunityDealStatusCodes.Incomplete && selectedTabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductsText", UIControlsText.ProductsText) && selectedDeal.closureDecision === OpportunityDealClosureDecisionType.InsufficientInformation) 
            || (commonPPContextState.pageMode === PageMode.Edit && selectedDeal?.statusCode === OpportunityDealStatusCodes.PendingSecondaryReview && selectedTabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductsText", UIControlsText.ProductsText) && selectedDeal.closureDecision === OpportunityDealClosureDecisionType.RequireAdditionalInformation) 
            || (commonPPContextState.pageMode === PageMode.Edit && selectedDeal?.statusCode === OpportunityDealStatusCodes.Approved)) {
            setShowSubmitButton(true);
        }
        else {
            setShowSubmitButton(false);
        }
    }

    const facetList = (facetNames: string[]): string[] => {
        let facetCount = ApplicationConstants.FacetCount;
        let facetString: string[] = [];

        for (let i = 0; i < facetNames.length; i++) {
            facetString.push(facetNames[i] + ",count:" + facetCount);
        }

        return facetString;
    }

    const createFilterExpression = (facetNames: string[], facetName: string, selectedValue: any[], allSelectedFacets: any[]): string => {
        let filterStringExpression = "";
        for (var i = 0; i < facetNames.length; i++) {
            if (facetNames[i] !== facetName) {
                if (allSelectedFacets[i].length > 0) {
                    filterStringExpression = filterStringExpression + "(" + allSelectedFacets[i].map((item: IFacetData) => {
                        if (item.value.indexOf("'") > -1) {
                            return " " + facetNames[i] + " " + ApplicationConstants.Eq + " '" + item.value.replace(/'/g, "''") + "' "
                        }
                        else {
                            return " " + facetNames[i] + " " + ApplicationConstants.Eq + " '" + item.value + "' "
                        }
                    }).join(" " + ApplicationConstants.OrOperator + " '") + ") " + ApplicationConstants.AndOperator + " ";
                }
            }
            else {
                if (selectedValue.length > 0) {
                    filterStringExpression = filterStringExpression + "(" + selectedValue.map(item => {
                        if (item.value.indexOf("'") > -1) {
                            return " " + facetName + " " + ApplicationConstants.Eq + " '" + item.value.replace(/'/g, "''") + "' "
                        }
                        else {
                            return " " + facetName + " " + ApplicationConstants.Eq + " '" + item.value + "' "
                        }
                    }).join(" " + ApplicationConstants.OrOperator + " '") + ") " + ApplicationConstants.AndOperator + " ";
                }
            }
        }

        return filterStringExpression;

    }

    const createGlobalFilterExpression = (filterNames: string[], filterValues: any[], euFilter: boolean): string => {
        let filterStringExpression = "";
        for (let i = 0; i < filterValues.length; i++) {
            if (filterValues[i].length > 0) {
                if (euFilter && filterNames[i] === ApplicationConstants.CustomerSearchCountryGlobalIndex) {
                    filterStringExpression = filterStringExpression + "(" + ApplicationConstants.CustomerSearchCascadeCountryGlobalIndex + " " + ApplicationConstants.Eq + " 'EU' " + ApplicationConstants.OrOperator + " " + filterValues[i].map((item: string) => " " + filterNames[i] + " eq '" + item + "' ").join(" " + ApplicationConstants.OrOperator + " '") + ") " + ApplicationConstants.AndOperator + " ";
                }
                else {
                    filterStringExpression = filterStringExpression + "(" + filterValues[i].map((item: string) => " " + filterNames[i] + " " + ApplicationConstants.Eq + " '" + item + "' ").join(" " + ApplicationConstants.OrOperator + " '") + ") " + ApplicationConstants.AndOperator + " ";
                }
            }
            else if (euFilter) {
                filterStringExpression = filterStringExpression + "(" + ApplicationConstants.CustomerSearchCascadeCountryGlobalIndex + " " + ApplicationConstants.Eq + " 'EU' )" + ApplicationConstants.AndOperator + " ";
            }
        }

        return filterStringExpression;
    }

    const createFormJson: IComponent = {
        type: 'FormGroup',
        data: {
            id: 'RootContainer',
            components: [
                {
                    type: 'Stack',
                    data: {
                        id: 'CreateFormLoading',
                        visible: isFormLoading,
                        components: [
                            {
                                type: 'Spinner',
                                data: {
                                    id: "CreateFormLoadingSpinner",
                                    ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProcessingText", UIControlsText.ProcessingText),
                                    styles: PageStyles.spinnerLoadingIcon
                                }
                            }
                        ]
                    }
                },
                {
                    type: "Stack",
                    data: {
                        id: "action-menu-details",
                        visible: props.pageContext?.pageMode === PageMode.Edit,
                        components: [{
                            type: "InnerHTMLContainer",
                            data: {
                                id: "partnerDetailsTooltip",
                                contolType: UIControlType.ActionMenu,
                                actionMenuConfig
                            }
                        }]
                    }
                },
                {
                   type: 'CaseDetails',
                   visible: props.pageContext?.pageMode === PageMode.Edit,
                   data: {
                       id: 'CaseDetailsSection',
                       caseName: dealNameAction,
                       caseStatus: caseStatus,
                       orgID: MPNOrgID,
                       locationID: MPNLocationID,
                       productGroup: commonPPContextState?.partnerDetails?.productGroupName,
                       partnerRole: partnerRole,
                       isPartner: isPartnerDetailHidden,
                       isRequestor: isRequestor,
                       country: country,
                       labelText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerDetailsForCaseText", UIControlsText.PartnerDetailsForCaseText),
                   }
                },
                /*{
                    type: "Stack",
                    className: CssClassConstants.partnerDetailCase,
                   visible: !showSuccessMessage,
                   data: {
                       id: "partnerDetails",
                      visible: props.pageContext?.pageMode === PageMode.Create,
                       components: [{
                           type: "InnerHTMLContainer",
                           data: {
                                id: "partnerDetailsTooltip",
                              contolType: UIControlType.HoverCardPlain,
                              plainCardProps: plainCardProps,
                              text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerDetailsForCaseText", UIControlsText.PartnerDetailsForCaseText),
                               iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContactInfoIconName", UIControlsText.ContactInfoIconName),
                               hoverTextStyles: HoverCardStyles.hoverText,
                               componentRef: hoverCard,
                               cardDismissDelay: 300,
                              iconClassName: iconStyles
                            }
                        }]
                    }
                },*/
                {
                    type: "PivotContainer",
                    visible: !showSuccessMessage,
                    data: {
                        id: 'RootPivotContainerForAllTabs',
                        baseState: props.pageContext,
                        selectedTabName: commonPPContextState.selectedPivot || "",
                        handleTabSelectionChanges,
                        tabNames: commonPPContextState.pivotTabs,
                        items: [
                            CustomerSearch(),
                            DealDetails({ verticals: props.verticals, dealTypes: props.dealTypes, countries: props.countries }),
                            Channel({ verticals: props.verticals, dealTypes: props.dealTypes, countries: props.countries }, onResellers), //replace with Channel Tab 
                            ProductSearch({ verticals: props.verticals, dealTypes: props.dealTypes, countries: props.countries, facetProps: { facetList, createFilterExpression, createGlobalFilterExpression } }),
                        ]
                    }
                },
                {
                    type: 'Stack',
                    visible: !showSuccessMessage && showCommandButtons,
                    data: {
                        id: 'StackCommondButtons',
                        horizontal: "true",
                        disableshrink: 'true',
                        components: [
                            
                            {
                                type: "StackItem",
                                visible: showContinueButton,
                                data: {
                                    id: "StackItemContinueButton",
                                    components: [
                                        {
                                            type: 'PrimaryButton',
                                            data: {
                                                id: 'ContinueButton',
                                                text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContinueText", UIControlsText.ContinueText),
                                                styles: PageStyles.commandButtonPadding,
                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContinueText", UIControlsText.ContinueText),
                                                title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContinueText", UIControlsText.ContinueText),
                                                onClick: onContinue
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: "StackItem",
                                visible: showSubmitButton,
                                data: {
                                    id: "StackItemSubmitButton",
                                    components: [
                                        {
                                            type: 'PrimaryButton',
                                            data: {
                                                id: 'SubmitButton',
                                                text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitText", UIControlsText.SubmitText),
                                                styles: PageStyles.commandButtonPadding,
                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitText", UIControlsText.SubmitText),
                                                title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitText", UIControlsText.SubmitText),
                                                onClick: onSubmit
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: "StackItem",
                                data: {
                                    id: UIConfigIdentifierConstants.StackItemSaveDraftButtonId,
                                    components: [
                                        {
                                            type: 'DefaultButton',
                                            data: {
                                                id: 'SaveDraftButton',
                                                text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SaveDraftText", UIControlsText.SaveDraftText),
                                                styles: PageStyles.commandButtonPadding,
                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SaveDraftText", UIControlsText.SaveDraftText),
                                                title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SaveDraftText", UIControlsText.SaveDraftText),
                                                onClick: onSaveDraft
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: "StackItem",
                                data: {
                                    id: "StackItemCancelButton",
                                    components: [
                                        {
                                            type: 'DefaultButton',
                                            data: {
                                                id: 'CancelButton',
                                                text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText),
                                                styles: PageStyles.commandButtonPadding,
                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText),
                                                title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText),
                                                onClick: backToDashboard
                                            }
                                        },
                                    ]
                                }
                            },
                        ]
                    },
                },
                {
                    type: 'Stack',
                    visible: showSuccessMessage,
                    data: {
                        id: 'StackSuccessPage',
                        disableshrink: 'true',
                        components: [
                            {
                                type: "StackItem",
                                data: {
                                    id: "StackItemSuccessPage",
                                    components: [
                                        {
                                            type: 'SuccessPage',
                                            data: {
                                                id: "SuccessPage",
                                                caseNumber: oppNumber,
                                                isSavingDraft: isSaveDraftButtonClicked,
                                                opportunityId: commonPPContextState.id,
                                                navigateTo: "/partnerdetail",
                                                dealType: commonPPContextState.opportunityDeals[0].dealType?.name
                                            }
                                        },
                                    ]
                                }
                            },
                        ]
                    },
                },
                {
                    type: 'Stack',
                    data: {
                        id: 'footerPadding',
                        components: [
                        ]
                    },
                },
            ]
        }
    }

    return createFormJson;
}