import {
  DefaultPalette,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
} from "@fluentui/react";
import { getDistinctUserRole, getLoggedinUserRoleDetails, getStatusNameByCode, isMsStoreUser, isValidEmail, sortArrayData, getEntityDataFromSession, getRuleValidationMessage, getUserRole, getDealVolumes, isNullOrEmpty, IsInternalPricingProgram, IsExternalPricingProgram, getDealClosureDecisionNameByCode } from "../../../components/shared/common/util";
import { ArrayExtensions } from "../../../infrastructure/ArrayExtensions";
import { JavaScriptExtensions } from "../../../infrastructure/JavaScriptExtensions";
import { ICountry } from "../../../models/ICountry";
import { ApplicationConstants } from "../../../models/ApplicationConstants";
import { SessionStorageConsts } from "../../../models/SessionStorageConsts";
import { IPivotTab } from "../../../models/IPivotTab";
import { IDealQuantityThresholds } from "../../../models/IDealQuantityThresholds";
import { IDealState } from "../common/state/IDealState";
import { ErrorMessages } from "../../../content/ErrorMessages";
import { IPartnerDetails } from "../../../models/IPartnerDetails";
import { IPageState } from "../common/state/IPageState";
import { ChannelSubType } from "../../../models/ChannelSubType";
import { UserRole } from "../../../models/UserRole";
import { PartnerTypeLabel } from "../../../models/PartnerTypeLabel";
import { IProductType } from "../../../models/IProductType";
import { PageMode } from "../../../models/PageMode";
import { OpportunityDealStatusCodes } from "../../../models/OpportunityDealStatusCodes";
import _ from "lodash";
import { IOpportunityAccount } from "../../../models/IOpportunityAccount";
import { OpportunityDealStatusNames } from "../../../models/OpportunityDealStatusNames";
import { UIControlsText } from "../../../content/UIControlsText";
import { UserSubRole } from "../../../models/UserSubRole";
import { PartnerType } from "../../../models/PartnerType";
import { IOpportunityDealDetails } from "../../../models/IOpportunityDealDetails";
import { IChannelFunctionalityDisplayState } from "../../shared/ComponentTypes/IChannelFunctionalityDisplayState";
import { IAccount } from "../../../models/IAccount";
import { MessageTypeText } from '../../../models/MessageTypeText';
import { DealType } from "../../../models/DealType";
import { OpportunityDealClosureDecisionName } from "../../../models/OpportunityDealClosureDecisionName";

const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

export const onPivotChange = (item: any): void => {
  console.log("Tab changed...");
};

export const stackContainerStyles: IStackStyles = {
  root: {
    background: DefaultPalette.whiteTranslucent40,
    color: DefaultPalette.white,
    paddingBottom: 5,
    paddingRight: 5
  },
};

export const stackContainerStylesShift: IStackStyles = {
  root: {
    background: DefaultPalette.whiteTranslucent40,
    color: DefaultPalette.white,
    paddingBottom: 5,
    paddingLeft: 14,
  },
};

export const stackItemStyles: IStackItemStyles = {
  root: {
    background: DefaultPalette.whiteTranslucent40,
    color: DefaultPalette.white,
    padding: 5
  },
};

export const stackItemCustomerStyles: IStackItemStyles = {
  root: {
    background: DefaultPalette.whiteTranslucent40,
    color: DefaultPalette.white,
    padding: 2,
  },
};
export const viewStackItemCustomerStyles: IStackItemStyles = {
  root: {
    background: DefaultPalette.whiteTranslucent40,
    color: DefaultPalette.white,
    padding: 5,
  },
};

// Tokens definition
export const smallAlignmentTokens: IStackTokens = {
  childrenGap: 2,
  padding: 2,
};

export const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 5,
  padding: 10,
};

export const itemAlignmentsStackTokens10: IStackTokens = {
  childrenGap: 15,
  padding: 20,
};

export const stackStyles: IStackStyles = {
  root: {
    background: DefaultPalette.white,
  },
};

//Get Text Field required error message
export const getTextFieldRequiredErrorMessage = (errorMessage: string) => (value: string): string => {
  return JavaScriptExtensions.isEmptyOrNullOrUndefined(value) ? errorMessage : '';
}

// Get regional countries, if selected country is based of EU/EFTA or MEA region
export function getRegionalCountries(allCountries: ICountry[], selectedGeoCountries: ICountry[]) {
  let countries = allCountries?.filter(x => selectedGeoCountries && selectedGeoCountries?.filter(y => y.countryId == x.countryId).length > 0);

  if (countries && countries.length > 0) {
    let countriesList: ICountry[] = [];
    // Handle EU/EFTA region
    let isEueftaCountrySelected = countries.some(x => x.isEUEFTA);
    if (isEueftaCountrySelected) {
      let eueftaCountries = allCountries?.filter(x => x.isEUEFTA) || [];
      let resellerCountries: any = [...countries];

      if (eueftaCountries && eueftaCountries.length > 0) {
        resellerCountries = ArrayExtensions.mergeArrays(resellerCountries, eueftaCountries);
      }
      countriesList = sortArrayData(resellerCountries, 'name', 'countryName');
      return countriesList;
    }

    // Handle MEA region for Distributors -- If not Saudi Arabia/ Egypt / South Africa then select UAE
    let isMEARegionSelected = countries.some(x => x.region === ApplicationConstants.MiddleEastRegion);
    if (isMEARegionSelected 
          && countries[0].countryName !== ApplicationConstants.SaudiArabia 
          && countries[0].countryName !== ApplicationConstants.Egypt
          && countries[0].countryName !== ApplicationConstants.SouthAfrica) {
      let uaeCountry = allCountries?.filter(x => x.countryName === ApplicationConstants.UAE) || [];
      if (uaeCountry) {
        return uaeCountry;
      }
    }
    return countries;
  }
}

//TODO: TEMP Config. Will be read from config file or CRM
export const channelConfig = {
  Config: {
    PrimaryResellerCount: 1,
  }
}

export const setErrorsDataIntoContext = (pivotTabsData: IPivotTab[], value: string, errorMessage: string, targetPropertyId: string, tabName: string) => {
  var pivotTab = pivotTabsData?.find(t => t.tabName === tabName);
  if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(value)) {
    pivotTab?.errors?.delete(targetPropertyId);
  }
  else {
    pivotTab?.errors?.set(targetPropertyId, errorMessage);
  }
}

//taking minimum threshold value in the multiple deal scenario
//taking minimum threshold value in the multiple deal scenario
export const getDealQuantityThreshold = (opportunityDeals: IDealState[], productGroupName: string, selectedOptyDealCaseNumber: string, pageMode: string): number => {
  let quantityThreshold: number = -1;
  const draftDeal = opportunityDeals.find((t: IDealState) => t.statusCode !== OpportunityDealStatusCodes.Draft);
  let countryName = (opportunityDeals && opportunityDeals[0]?.dealCustomers && opportunityDeals[0]?.dealCustomers[0]?.country) || "";
  if (pageMode === PageMode.Edit && !JavaScriptExtensions.isNullOrUndfinedOrEmpty(draftDeal)) {
    const optyDeal = opportunityDeals.find((t: IDealState) => t.name === selectedOptyDealCaseNumber);
    let countryName = (optyDeal && optyDeal?.dealCustomers && optyDeal?.dealCustomers[0]?.country) || "";
    const dealVolumeConfig = getDealVolumes(optyDeal?.dealType?.name || "", productGroupName || "", optyDeal?.dealVertical, countryName)
    return !isNullOrEmpty(dealVolumeConfig) ? dealVolumeConfig?.minimumUnitsCommitted || 0 : quantityThreshold;
  }
  else {
    opportunityDeals?.forEach((optyDeal: IDealState) => {
      let countryName = (optyDeal && optyDeal?.dealCustomers && optyDeal?.dealCustomers[0]?.country) || "";
      const dealVolumeConfig = getDealVolumes(optyDeal?.dealType?.name || "", productGroupName || "", optyDeal?.dealVertical, countryName)
        let selectedDealTypeThreshold = JavaScriptExtensions.isNullOrUndfinedOrEmpty(dealVolumeConfig) ? quantityThreshold : dealVolumeConfig?.minimumUnitsCommitted;
        /**Max value to be considered as threshold value fo DR+SP - feature 10271293 **/
      if (quantityThreshold < (selectedDealTypeThreshold || 0)) {
        quantityThreshold = selectedDealTypeThreshold || 0;
      }
    });
  }

  return quantityThreshold;
}

export const getDealQuantityThresholdValue = (opportunityDeals: IDealState[], productGroupId: string, dealType: string): number => {
  let quantityThreshold: number = -1;
  let productGroupName: string = "";
  let countryName = (opportunityDeals && opportunityDeals[0]?.geoCountries && opportunityDeals[0]?.geoCountries[0]?.countryName) || "";
  
  if(opportunityDeals?.length == 0 || JavaScriptExtensions.isNullOrUndfinedOrEmpty(productGroupId) || JavaScriptExtensions.isNullOrUndfinedOrEmpty(dealType)) {
    return quantityThreshold;
  }

  let productTypes : IProductType[] = [];
  let productTypesInSession = sessionStorage.getItem(SessionStorageConsts.productTypes);
  if (productTypesInSession !== null) {
    productTypes = JSON.parse(productTypesInSession);
    let productTypeSelected : IProductType[] = productTypes.filter(p => p.productId == productGroupId);
    productGroupName = productTypeSelected[0]?.productName
  }

  const selectedConfig = getDealVolumes(dealType || "",  productGroupName || "", opportunityDeals[0]?.dealVertical, countryName);

  if(JavaScriptExtensions.isNullOrUndfinedOrEmpty(selectedConfig)) {
    return quantityThreshold;
  }

  let selectedDealTypeThreshold : any = selectedConfig?.minimumUnitsCommitted;
  return selectedDealTypeThreshold;
}



// Get MSStore Email TextField ErrorMessage.
export const validateMSStoreEmailErrorMessage = (value: string): string => {
  let message = '';
  if (!value) {
    message = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredMSStoreSellerEmail", ErrorMessages.RequiredMSStoreSellerEmail);
  }
  else if (!isValidEmail(value)) {
    message = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "InvalidMSStoreSellerEmail", ErrorMessages.InvalidMSStoreSellerEmail);
  }
  else if (value.indexOf("@") !== -1 && value.indexOf(ApplicationConstants.microsoftEmail) === -1) {
    message = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "InvalidMicrosoftMSStoreSellerEmail", ErrorMessages.InvalidMicrosoftMSStoreSellerEmail);
  }

  return message;
};

export const setPartnerDetails = (pageContext: IPageState, productTypes: IProductType[], countries: ICountry[]): IPartnerDetails => {
  let partnerdetails: IPartnerDetails = {} as IPartnerDetails;
  let productGroupName = '';
  if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(pageContext.productGroup)) {
    productGroupName = productTypes.find(p => p.productId === pageContext.productGroup)?.productName || "";
  }
  partnerdetails.productGroupName = productGroupName;
  partnerdetails.productGroupId = pageContext.productGroup;
  var requester = pageContext.opportunityAccounts?.find(acc => acc.channelSubType === ChannelSubType.Requester);
  if (isMsStoreUser()) {
    partnerdetails.userRole = UserRole.MSStore;
    var geoCountry = pageContext?.opportunityDeals && pageContext?.opportunityDeals[0] && pageContext?.opportunityDeals[0].geoCountries &&
      pageContext?.opportunityDeals[0].geoCountries[0];
    partnerdetails.countryName = geoCountry?.countryName || "";
    partnerdetails.countryId = geoCountry?.countryId || "";
  }
  else if (requester && requester.accounts) {
    var RoleValue = PartnerTypeLabel.get(requester?.partnerType || 0) || "";
    //dispatch partner details
    partnerdetails.mpnLocation = requester.accounts[0].locationId;
    partnerdetails.countryName = countries.find(t => t.countryId === requester?.countryId)?.countryName;
    partnerdetails.countryId = requester?.countryId;
    partnerdetails.organizationId = requester.accounts[0].organizationId;
    partnerdetails.userRole = RoleValue;
  }
  else {
    partnerdetails.userRole = getUserRole();
  }

  return partnerdetails;
}

export const isMultiDeal = (pageContext: IPageState): boolean => {
  let dealTypes: string[] = [];
  pageContext.opportunityDeals?.forEach(t => dealTypes.push(t?.dealType?.name || ""));
  return _.uniq(dealTypes)?.length > 1;
}

export const isCaseContainsDealType = (pageContext: IPageState, dealTypeName: string): boolean => {
  var dealType = pageContext.opportunityDeals?.filter(t => t?.dealType?.name === dealTypeName);
  return dealType?.length > 0;
}

// to show action menu on edit screen
export const showActionMenuHelper = (pageContext: IPageState, oppDeal: IDealState, requester?: IOpportunityAccount): IOpportunityDealDetails => {
  //start: show action menu in view form object
  let oppDealDetails: IOpportunityDealDetails = {} as IOpportunityDealDetails
  oppDealDetails.id = oppDeal.id || "";
  if (pageContext.opportunityAccounts) {
    oppDealDetails.opportunityAccounts = pageContext.opportunityAccounts;
    oppDealDetails.dealContacts = oppDeal.dealContacts;
    oppDealDetails.submitterDetails = pageContext.submitterDetails;
  }
  oppDealDetails.isLocalEmpowerment = oppDeal.isLocalEmpowerment || false;
  oppDealDetails.dealName = oppDeal.dealName || "";
  oppDealDetails.dealType = oppDeal.dealType || {};
  oppDealDetails.id = oppDeal.id || ""
  oppDealDetails.customerCountry = ""

  var statusName = getStatusNameByCode(oppDeal?.statusCode);
  if (statusName === OpportunityDealStatusNames.Approved && oppDeal?.expirationDate && new Date(oppDeal?.expirationDate) < new Date()) {
    oppDealDetails.statusCodeName = OpportunityDealStatusNames.Expired
  } else {
    oppDealDetails.statusCodeName = statusName
  }

  if (oppDeal?.statusCode) {
    oppDealDetails.statusCode = oppDeal?.statusCode;
  }

  if (oppDeal?.dealCustomers && oppDeal.dealCustomers.length > 0) {
    oppDealDetails.customerCountry = oppDeal.dealCustomers[0].country || ""
  }

  var closureDecisionName = getDealClosureDecisionNameByCode(oppDeal?.closureDecision);
  oppDealDetails.closureDecisionString = closureDecisionName;
  oppDealDetails.userType = getUserType(oppDealDetails);
  oppDealDetails.opportunityName = pageContext.name || "";
  oppDealDetails.caseStartDate = oppDeal.caseStartDate;
  oppDealDetails.dealExtensionCount = oppDeal.dealExtensionCount || 0
  oppDealDetails.unitsTransactedOnDeal = oppDeal.unitsTransactedOnDeal || 0
  oppDealDetails.unitsTransactedHardware = oppDeal.unitsTransactedHardware || 0
  oppDealDetails.unitsCommitted = oppDeal.dealVolume || 0
  oppDealDetails.dealVolume = oppDeal.dealVolume || 0
  oppDealDetails.dealVertical = oppDeal.dealVertical || ""
  oppDealDetails.productType = oppDeal.productType || ""
  oppDealDetails.relatedDeal = oppDeal.relatedDeal

  if (oppDeal.expirationDate) {
    oppDealDetails.expirationDate = new Date(oppDeal?.expirationDate)
  } else {
    oppDealDetails.expirationDate = undefined;
  }

  if (pageContext.opportunityDeals && pageContext.opportunityDeals.length > 0) {
    // set renewal eligibility
    var currentDeal = pageContext.opportunityDeals.find(d => d?.id === oppDeal?.id)
    if (currentDeal && currentDeal.statusCode === OpportunityDealStatusCodes.Approved) {
      if (JavaScriptExtensions.isEmptyGuid(currentDeal?.relatedDeal)) {
        oppDealDetails.isRenewalEligible = true
      } else {
        let relatedDeal = pageContext.opportunityDeals.find(d => d?.id === currentDeal?.relatedDeal)
        if (relatedDeal && relatedDeal.statusCode === OpportunityDealStatusCodes.Expired) {
          currentDeal.isRenewalEligible = true
        } else {
          currentDeal.isRenewalEligible = false
        }
      }
    }

    // set revert eligibility
    if (pageContext.opportunityDeals?.length === 1) {
      let deal = pageContext.opportunityDeals[0]
      if (deal?.statusCode === OpportunityDealStatusCodes.Unassigned && oppDealDetails?.id === deal?.id) {
        oppDealDetails.isRevertEligible = true
      }
    }

    if (pageContext.opportunityDeals?.length === 2) {
      let deal1 = pageContext.opportunityDeals[0]
      let deal2 = pageContext.opportunityDeals[1]

      if (deal1?.statusCode === OpportunityDealStatusCodes.Unassigned && deal2?.statusCode === OpportunityDealStatusCodes.Unassigned) {
        if (deal1?.id === oppDealDetails?.id || deal2?.id === oppDealDetails?.id) {
          oppDealDetails.isRevertEligible = true
        }
      }
    }
  }

  return oppDealDetails;
}

export const getSelectedDealDetails = (oppDealdetails: IOpportunityDealDetails, oppDeal: IDealState): any => {
  var selectedData: any = oppDealdetails;
  selectedData.name = oppDeal.name;
  var ISToffSet = 330;
  var offset = ISToffSet * 60 * 1000;
  var caseStartDate = new Date(oppDeal.caseStartDate || "" + offset);
  if (caseStartDate) {
    selectedData.caseStartDate = caseStartDate;
  }

  return selectedData;
}

export const initialChannelDisplayOnEdit = (pageContext: IPageState, initialChannelDisplay: IChannelFunctionalityDisplayState): IChannelFunctionalityDisplayState => {
  initialChannelDisplay.MultipleDistributorsOnly = true;
  initialChannelDisplay.PrimaryResellersOnly = true;
  return initialChannelDisplay;
}

export const selectCountryBasedOnRegion = (endCustomerCountryId: string, accountCountryId: string, countries: ICountry[], type: PartnerType | undefined): string => {
  const selectedCountry = countries.find(t => t.countryId === endCustomerCountryId);
  let countryId: string = "";
  if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(selectedCountry) && selectedCountry?.region?.toLowerCase() === ApplicationConstants.MiddleEastRegion.toLowerCase()
    && selectedCountry.countryName !== ApplicationConstants.SaudiArabia
    && selectedCountry.countryName !== ApplicationConstants.Egypt
    && selectedCountry.countryName !== ApplicationConstants.SouthAfrica && PartnerType.ADD === type) {
    countryId = countries.find(t => t.countryName === ApplicationConstants.UAE)?.countryId || "";
  }
  else if (PartnerType.MsStore === type || PartnerType.AdditionalTierReseller === type
    || PartnerType.FinalTierReseller === type) {
    countryId = endCustomerCountryId;
  }

  return JavaScriptExtensions.isEmptyOrNullOrUndefined(countryId) ? endCustomerCountryId : countryId;
}

export const replaceMEToUAEForDistributors = (countries: ICountry[]): IAccount[] => {
  var distributors = getEntityDataFromSession<IAccount>(SessionStorageConsts.distributors);
  let uaeCountry = countries?.find(x => x.countryName === ApplicationConstants.UAE);
  let middleEastCountry = countries?.find(x => x.countryName === ApplicationConstants.MiddleEastCountry);
  distributors.forEach(t => {
    if (t.countryId === middleEastCountry?.countryId) {
      t.countryId = uaeCountry?.countryId;
      t.countryName = uaeCountry?.countryName;
    }
  })
  return distributors;
}

export function getUserType(oppDealDetails: IOpportunityDealDetails): string[] {
  var userTypes: string[] = [];
  userTypes.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Default", UIControlsText.Default))
  const loggedInUserEmail = sessionStorage.getItem(SessionStorageConsts.userEmail);
  const loggedInUserRoleDetails = getLoggedinUserRoleDetails();

  if (oppDealDetails.dealType != null && IsInternalPricingProgram(oppDealDetails.dealType.name)) {
    if (!isNullOrEmpty(oppDealDetails.closureDecisionString) && oppDealDetails.closureDecisionString.toLocaleLowerCase() === OpportunityDealClosureDecisionName.EditPendingReview.toLocaleLowerCase()) {
        return userTypes
    }

    if (oppDealDetails.submitterDetails != null && oppDealDetails.submitterDetails?.email != null && oppDealDetails.submitterDetails.email.toLocaleLowerCase() === loggedInUserEmail?.toLocaleLowerCase()) {
      userTypes.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Requester", UIControlsText.Requester))
    }

    if (IsInternalPricingProgram(oppDealDetails.dealType.name) &&
        oppDealDetails.dealContacts != null && oppDealDetails.dealContacts.filter((c: any) => c.isCoOwner && c.email.toLocaleLowerCase() === loggedInUserEmail?.toLocaleLowerCase()).length > 0) {
      userTypes.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CoOwner", UIControlsText.CoOwnerText))
    }
  }
  else if (oppDealDetails.dealType != null && IsExternalPricingProgram(oppDealDetails.dealType.name)) {
    if (loggedInUserRoleDetails.filter((t: any) => t?.userRole !== UserRole.MSSeller).length > 0 && !oppDealDetails.isLocalEmpowerment) {
      var loggedInUserAccountIds = [... new Set(loggedInUserRoleDetails.map((a: any) => a.accountId))]
      var loggedInUserOppAccounts = oppDealDetails.opportunityAccounts.filter(oppAcc => oppAcc.accounts != null && loggedInUserAccountIds.includes(oppAcc.accounts[0].accountId));
      var coOwnerDetails = oppDealDetails.opportunityAccounts.filter(oppAcc => oppAcc.channelSubType === ChannelSubType.CoOwner && !oppAcc.accounts?.filter(acc => loggedInUserAccountIds.includes(acc.accountId)));

      if ((oppDealDetails.statusCode === OpportunityDealStatusCodes.Draft || loggedInUserRoleDetails.filter((x: any) => x.userSubRole === UserSubRole.MSStoreUser.toString() || x.UserSubRole === UserSubRole.MSStoreUser.toString()).length > 0) &&
        oppDealDetails.submitterDetails !== null && oppDealDetails.submitterDetails?.email !== null && oppDealDetails.submitterDetails?.email.toLocaleLowerCase() === loggedInUserEmail?.toLocaleLowerCase()) {
        userTypes.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Requester", UIControlsText.Requester))
      }
      else if (loggedInUserOppAccounts != null && loggedInUserOppAccounts.length > 0) {
        if (loggedInUserRoleDetails.filter((x: any) => x.userSubRole === UserSubRole.ADD).length > 0 && (oppDealDetails.statusCode === OpportunityDealStatusCodes.Approved
          || oppDealDetails.statusCode === OpportunityDealStatusCodes.Expired)) {
          if (coOwnerDetails.filter((oppAccount: any) => oppAccount.channelSubType === ChannelSubType.CoOwner).length > 0) {
            userTypes.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Default", UIControlsText.Default))
          }
          else if (loggedInUserOppAccounts.filter((oppAccount: any) => oppAccount.channelSubType === ChannelSubType.CoOwner).length > 0) {
            userTypes.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CoOwner", UIControlsText.CoOwnerText))
          }
          else if (loggedInUserOppAccounts.filter((oppAccount: any) => oppAccount.channelSubType === ChannelSubType.Requester && oppAccount.isRequesterIsOwner).length > 0) {
            userTypes.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Requester", UIControlsText.Requester))
          }
        }
        else if (loggedInUserRoleDetails.filter((x: any) => x.userSubRole === UserSubRole.ADR || x.userSubRole === UserSubRole.DMP).length > 0
          && (oppDealDetails.statusCode === OpportunityDealStatusCodes.Approved || oppDealDetails.statusCode === OpportunityDealStatusCodes.Expired)) {
          userTypes.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Requester", UIControlsText.Requester))
        }
        else if (oppDealDetails.statusCode === OpportunityDealStatusCodes.Unassigned || oppDealDetails.statusCode === OpportunityDealStatusCodes.Incomplete || oppDealDetails.statusCode === OpportunityDealStatusCodes.PendingSecondaryReview) {
          if (loggedInUserOppAccounts.filter((oppAccount: any) => oppAccount.channelSubType === ChannelSubType.Requester).length > 0) {
            userTypes.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Requester", UIControlsText.Requester))
          }
        }
      }
    }
  }

  return userTypes;
}