/**
 * Text, button, aria text for UI controls.
 * @constant
 */
export const UIControlsText = {
    CancelText: "Cancel",
    OkText: "OK",
    None: "None",
    ConfirmTitle: "Confirm",
    ConfirmCancelMessage: "There are unsaved changes on the page. Are you sure you want to Cancel and navigate to Home?",
    SaveDraftText: "Save as Draft",
    ContinueText: "Continue",
    DealNameText: "Deal Name",
    DealNamePlaceHolderText: "Enter the Deal Name",
    DealOverviewText: "Deal Overview",
    DealOverviewPlaceHolderText: "Enter the Deal Overview",
    DealVolumePlaceHolderText: "Enter the Deal Volume",
    DealProductGroupText: "Product Group",
    DealProductGroupPlaceHolderText: "Select the Product Group",
    DealGeoCoverageText: "Deal Geo Coverage",
    DealGeoCoveragePlaceHolderText: "Select the Deal Geo Coverage",
    DealCountryText: "Deal Country(s)",
    DealCountryColumn: "Deal Country",
    DealCountryPlaceHolderText: "Select the Country(s)",
    DealAffiliationTypeText: "Affiliation Type",
    DealAffiliationTypePlaceHolderText: "Select the Affiliation Type",
    DealAffiliationTypeOtherText: "Affiliation Type Others",
    DealAffiliationTypeOtherPlaceHolderText: "Enter the Affiliation Type Others",
    DealVerticalText: "Deal Vertical",
    DealVerticalPlaceHolderText: "Select the Deal Vertical",
    DealExpirationDateText: "Requested Expiration Date",
    DealExpirationDatePlaceHolderText: "Enter the date in MM/DD/YYYY",
    DealExpirationDateJustificationText:
        "Requested Expiration Date Justification",
    DealExpirationDateJustificationPlaceHolderText:
        "Enter Justification for Expiration Date",
    DealTransactedVolumeText: "Transacted Volume(Total)",
    DealCasesStartDateText: "Case Start Date",
    DealCasesEndDateText: "Case End Date",
    LegalEntitiesText: "Legal Entities",
    ChannelText: "Channel",
    DealDetailsText: "Deal Details",
    CaseDetailsText: "Case Details",
    SubmitText: "Submit",
    NextText: "Next",
    CloseText: "Close",
    CaseIdText: "Case ID",
    StatusText: "Status",
    RevertCheckBoxLabel: `Are you sure you want to revert this case 
      back to “draft” status? If you revert the case, it will be
       removed from its current queue position, and upon your next 
       submittal, the case will be placed at the lowest queue position.`,
    ExpireCheckBoxLabel: `Are you sure you want to expire this case ? 
      An expired case will no longer be under “approved” status, 
      and claims will only be accepted if the ADD invoice date is ON OR 
      BEFORE the case’s expiration date.`,
    DeleteCheckBoxLabel: `Are you sure you want to delete this case? 
      Once the case is deleted, all the case submission data will be lost, 
      and the case will be removed from the dashboard.`,
    ViewCaseText: "View Case",
    EditCaseText: "Edit Case",
    RevertCaseText: "Revert Case",
    DeleteCaseText: "Delete Case",
    ExpireCaseText: "Expire Case",
    ExpireSubmit: "Understood-Expire the Case",
    ExpireCancel: "Abort-return to dashboard",
    CPCaseDecisionLetter: "Case Decision Letter",
    CPStrategicLetters: "Manufacturer's Confirmation Letter",
    ChannelOverviewText: "Channel Overview",
    ChannelOverviewPlaceHolderText: "Enter the Channel Overview",
    ChannelJustificationText: "Channel Justification",
    ChannelJustificationPlaceHolderText: "Enter the Channel Justification",
    AddAddtext: "Add ADD",
    AdrAddtext: "Add Reseller(s)",
    AddResellerText: "Add Reseller",
    AddPrimaryResellerText: "Add Reseller",
    Reseller: "Reseller",
    Distributor: "Distributor",
    AddDistributorText: "Add Distributor(s)",
    AddCountryText: "ADD Country",
    AddLookupText: "ADD Lookup",
    AddCountryPlaceholderText: "Select the ADD Country",
    ResellerCountryText: "Reseller Country",
    ResellerCountryPlaceholderText: "Select the Reseller Country",
    ResellerNameText: "Reseller Name",
    ResellerNamePlaceholderText: "Select the Reseller Name",
    ResellerLookupText: "Reseller Lookup",
    AddNameText: "ADD Name",
    AddNamePlaceHolderText: "Select the ADD Name",
    ChannelStructureText: "Channel Structure",
    ChannelStructurePlaceholderText: "Select the Channel Structure",
    SkipChannelReasonText: "Skip Channel Reason",
    SkipChannelReasonPlaceHolderText: "Enter the Skip Channel Reason",
    PartnerTypeText: "Partner Type",
    PartnerTypePlaceHolderText: "Select the Partner Type",
    DealVTeamText: "Deal V-Team",
    MicrosoftContactText: "Microsoft Contact",
    DealVTeamMemberAddtext: "Add V-Team Member",
    DealContactAliasText: "Alias/Email",
    DealContactAliasPlaceholderText: "Enter the Alias/Email",
    DealContactName: "Name",
    DealContactCoOwner: "Co-Owner",
    CoOwnerText: "Co-Owner",
    DealContactRoleText: "Role",
    DealOtherRoleText: "Other Role",
    DealContactRolePlaceholderText: "Select the Role",
    DistributorCountryPlaceholderText: "Select the Distributor Country",
    ExceptionRequest: "Exception Request",
    ExceptionRequestPlaceHolderText: "Enter any required Exceptions",
    AdditionalInformationPlaceHolderText: "Enter any additional information about the deal",
    Attachments: "Attachments",
    ProofOfPreference: "Proof of End Customer's Preference",
    AttachmentBadgeTitle: "Click to see Attachment(s)",
    NoLegalEntitySelected: "Please select atleast one legal entity to add.",
    NoProductSelected: "Please select atleast one product to add.",
    NoPrimaryEntitySelected: "Atleast one primary legal entity to be selected.",
    Country: "Country",
    SearchFilter: "Search Filter",
    LegalEntitiesSearchResult: "Legal Entities Search Result",
    AddSelectedLegalEntites: "Add Selected Legal Entites",
    LegalEntityName: "Legal Entity Name",
    TPID: "TPID",
    CRMAccountID: "CRM AccountID",
    CustomerSegment: "Customer Segment",
    SelectedLegalEntities: "Selected Legal Entities",
    DealVolume: "Deal Volume Total",
    DeleteColumn: "Delete",
    Attachment: "Attachment",
    AdditionalInformation: "Additional Information",
    SearchTabText: "Search Legal Entity",
    UploadTabText: "Upload Legal Entity",
    IsPrimary: "Is Primary",
    CustomerVertical: "Customer Vertical",
    UploadRequiredErrorMessage:
        "Required fields are not provided for these fields : ",
    UploadCountryGEOErrorMessage:
        "Country does not match with any of the selected Geo Countries.",
    Search: "Search",
    DistributorText: "Distributor(s)",
    ResellerText: "Reseller",
    LegalEntityCountry: "Legal Entity Country",
    MsStoreText: "MS Store",
    AllAddsText: "All ADDs in the Country",
    AllAddsEuText: "All ADDs in EU Region",
    AllResellersText: "All Resellers in the Country",
    AllResellersEuText: "All Resellers in EU Region",
    DefaultLegalOption: "search",
    UploadSegmentErrorMessage:
        "Customer Segment is invalid as it does not contains 'SMB'.",
    LegalEntityAndProductsSelectionErrorMessage:
        "The Legal Entities and Products added related to this country will be removed.",
    SubmitSync: "SubmitSync",
    RequiredFieldMissing:
        "Enter all the required fields before submiting the case.",
    SuccessText: "Success",
    SaveDraftSuccessMessage: "Opportunity {0} saved as Draft.",
    SubmitSuccessMessage: "Opportunity {0} Submitted.",
    NavigateDashboardTitle: "Click to Navigate to Dashboard.",
    CancelAndNavigateDashboardTitle:
        "Click to cancel the changes and Navigate to Dashboard.",
    CopyCASText: "Copy Opportunity Number",
    CopyCASTitle: "Click to Copy Opportunity Number to Clipboard.",
    SaveOppDraftButtonTitle: "Click to Save Deal as Draft.",
    SubmitOppButtonTitle: "Click to Submit the Deal.",
    SubmitSyncOppButtonTitle: "Click to Submit the Deal synchronously.",
    UploadFileSizeErrorMessage: "Please select a file less than 10mb",
    CRMChildAccountFacetText: "CRM Child Account Name",
    AddressFacetText: "Address",
    CityFacetText: "City",
    PostalCodeFacetText: "Postal Code",
    StateProvinceFacetText: "State Province",
    CRMIDFacetText: "Customer Account ID",
    TPIDFacetText: "Customer TPID",
    GPIDFacetText: "Customer GPID",
    DunsNumberFacetText: "Duns ID",
    ProductFamilyCodeFacetText: "Product Family Code",
    ProductFamilyNameFacetText: "Product Family Name",
    BusinessUnitFacetText: "Business Unit",
    SkuNameFacetText: "Sku Name",
    SkuPartNumberFacetText: "Sku Part Number",
    CountryFacetText: "Country",
    MpnIdText: "MPN ID",
    SavedSuccessfully: "Saved Successfully.",
    ErrorOccured: "Error Occured.",
    ErrorValidationMessage: "Please correct the errors.",
    SubmitCaseButtonTitle: "Click to Submit",
    CancelCaseButtonTitle: "Click to Cancel",
    RevertOption: "Revert Case",
    ExpireOption: "Expire Case",
    DeleteOption: "Delete Case",
    SearchButton: "Search",
    ResellerRegistration: "Reseller Registration",
    ProductSearch: "Product Search",
    ProductSelection: "Product Selection",
    ChannelContacts: "Channel Contacts",
    PartnerContacts: "Partner Contacts",
    ChannelContactsPlaceHolderText: "Enter the partner emails",
    PartnerNameText: "Partner Name",
    PartnerEmailContactsText: "Email Contact(s)",
    CustomerSearch: "Customer Search",
    CustomerSearchResults: "Customer Search Result(s)",
    ErrorText: "ErrorText",
    InvalidCaseMessage: "Opportunity Number not found.",
    AddFilterPlaceHolderText: "Filter By Add Name",
    ResellerFilterPlaceHolderText: "Filter By Reseller Name",
    LegalEntitiesSearchElements: "Legal Entities Search Elements",
    PrimaryLegalEntity: "Primary Legal Entity",
    PrimaryLegalEntityTPID: "Primary Legal Entity TPID",
    PrimaryLegalEntityCRMAccountID: "Primary Legal Entity CRM Account ID",
    AdditionalLegalEntities: "End Customer Information",
    ProductSearchElements: "Product Search Elements",
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
    AddCaseAriaLabel: "AddCase",
    DownloadAriaLabel: "DownloadDocument",
    FilterAriaLabel: "QueryList",
    NoMatchFoundText: "No matches found.",
    FileSizeError: "File size should be less than {0}",
    FileSizeEmptyError: "File is empty, please upload a valid file.",
    AllowedFileExtensionsForCPStrategic: ".msg, .pdf, , .xls, .doc, .docx, .txt, .pptx",
    AllowedFileExtensionsForOTLE: ".docx, .xlsx, .pdf, .pptx, .eml,.msg",
    AllowedFileExtensionsForEndCustomerProof: ".pdf,.eml,.msg",
    LoadingReseller: "Loading Resellers",
    ProcessingText: "Processing Case Submission",
    CustomerSearchPlaceHolderText:
        "Search by Country, Customer Name, CRMChildAccountName, Address, Customer Account ID, Customer TPID, Customer GPID, Duns ID.",
    ProductSearchPlaceHolderText:
        "The search is against the fields:- Country, Business Unit, Product Family Code, Product Family Name, Sku Name, Sku Part Number.",
    DealDetailsDealVolumeElements: "Deal Volume Elements",
    ADDRegistration: "ADD Registration",
    MoreActionsText: "More Actions",
    Welcome: "Welcome!",
    MDPP: "Microsoft Devices Pricing Program",
    SigninMessage: "Sign in with your Work Account.",
    WorkAccountDescription:
        "Enter your Work Account or Azure Active Directory(AAD) Account using the 'Sign in' button below to access the portal.",
    Signin: "Sign in",
    SigninExample: "Example: you@yourcompany.com",
    SigninGuidance: "For any additional guidance, please click ",
    ClickHere: "here.",
    ContactUs: "Contact Us",
    PrivacyAndCookies: "Privacy and Cookies",
    DataPrivacyLink: "Data Privacy",
    TermsOfUse: "Terms of use",
    TradeMark: "Trademarks",
    CopyRightMicrosoft: "© 2022 Microsoft",
    DealCommitted: "Deal Committed",
    PartnerAddDisclaimerText:
        "Because a DMP has been selected as an eligible reseller in one of the countries, the following Distributors will receive case notification & case dashboard access, to enable transaction with the selected DMP.",
    NoCountrySelectedPlaceHolderText: "Please Select Country to see Records....",
    SomeThingWrong: "Something went wrong...",
    BackToLogin: "Back To Login",
    OrganizationInfoMissing: "Organization Information Is Missing",
    NotFoundApiError: "404 - API Error Occured",
    AddNew: "Add New",
    Clear: "Clear Search",
    ClearAll: "Clear All Selection",
    ViewReseller: "View Resellers",
    DownloadExcel: "Download Excel",
    ExportToExcel: "Export to Excel",
    Downloading: "Downloading...",
    HomePageImage: "Home Page content image",
    ProductSearchID: "ProductSearch",
    CustomerSearchID: "CustomerSearch",
    NoResellerInfo: "No ADR's/DMP's are associated to your countries",
    NoDistributorInfo:
        "Please begin contacting preferred distributor(s) in your region to share this CP (Strategic) CAS ID. The Distributor must add this CAS-ID to their dashboard to begin transacting on the case.",
    NoChannelInfo:
        "This case has been cascaded to ADDs, and as such no channel information is viewable to resellers. You may be contacted by an ADD who has been selected to transact this case, at which point you may work with the distributor.",
    ClosureDecisionCommentsText: "Closure Decision Comments",
    LegalEntityOptions: "CustomerOption",
    UploadLegalOption: "upload",
    QuickView: "Quick View",
    SelectedPFAM: "Selected PFAM",
    PageHeaderLabel: "Microsoft Devices Pricing Programs",
    PageHeaderLabelTestEnv: "NON PROD - For Test Purposes only.",
    TestEnvType: "Test",
    ExpandedView: "Expanded View",
    CompactView: "Compact View",
    Owner: "Owner",
    NotCaseOwnerDialogTitle: "Not Case Owner",
    NotCaseOwnerDialogMessage: "Only Owners/Co-Owner(s) can edit case.",
    LoadingCases: "Loading Cases",
    SubmittingAddCaseToDashboardRequest: "Submitting Request",
    ExtensionProgress: "Extension in progress",
    RenewProgress: "Case renewal in progress",
    CopyProgress: "Copying the case",
    SearchDuplicateDeals: "Searching Duplicate Deals",
    SelectADDAccountText: "Account Country",
    SelectADDAccountAndCountryText: "Distributor Name / Country",
    DropdownText: "Dropdown",
    SelectADDAccountName: "Select Distributor",
    ADD: "ADD",
    SelectReseller: "Select Reseller",
    LoadingFilterData: "Loading Filter Data",
    SelectADDAccountPlaceholderTextOnDashboard: "Select distributor",
    SelectResellerCountry: "Select Reseller Country",
    SelectADDAccountPlaceholderText:
        "Please select an account country to be associated.",
    SelectADDAccountAndCountryPlaceholderText:
        "Please select the desired distributor.",
    UploadLegalEntityIncorrectTemplate:
        "Incorrect template or incorrect file format, Please download the template and try again.",
    UploadLegalEntityNoData: "Please upload with atleast one legal entity.",
    DealVTeamInfo: "No Deal V-Team Contacts were added to show",
    ChannelContactEmailText: "Distributor E-mail(s)",
    ChannelContactEmailPlaceholderText: "e.g. abc@xyz.com; pqr@xyz.com",
    ResellerNameHeading: "Select Reseller",
    UnauthorizedMessage:
        "Unauthorized: You do not have permission to view this page.",
    DataLoading: "Downloading...",
    HrefAttribute: "href",
    DownloadAttribute: "download",
    RadioButton: "Radio Button",
    DeleteAttachmentWarning: "Attachment Delete Warning",
    AttachmentDeleteMessage:
        "Deleting of attachment is permanent, Are you sure you want to Delete?.",
    PrimaryLegalCountry: "Primary Legal Country",
    ExpandAll: "Expand All",
    CollapseAll: "Collapse All",
    MicrosoftContacts: "Microsoft Contacts",
    DealInfo: "No Microsoft Contacts were added to show",
    PaginationEllipsisLabel: "Click for pages 11 and up",
    IDColumnName: "id",
    DealStackability: "Deal Stackability",
    ShowSearchFilter: "Show Search Filters",
    HideSearchFilter: "Hide Search Filters",
    BuildNumberText: "Build Number",
    HelpAndSupport: "Help + Support",
    ToolSupportContent: "Tool Support – please contact",
    RatingLabelText: "Please rate your experience",
    RatingCommentLabelText: "What could have been better?",
    RatingCommentPlaceHolderText: "Enter Comments",
    EmailCCNotificationRecipients: "Email Recipients",
    EmailCCNotificationRecipientsPlaceHolderText:
        "Please enter emails with (;) seperator.",
    DashboardView: "Dashboard View",
    PagerDropdownAriaLabel: "Select items per page dropdown",
    WebpageNotAvailable: "Webpage Not Available",
    MDPPHelpAndSupport: "MDPP Help & Support",
    HelpAndSupportResourceGuidance: "Depending on the nature of your question, please use the appropriate resources as follows:",
    CaseSpecificContacts: "Case-specific questions (such as pricing and discounts)",
    SpecialPricingContactNorthAmerica: "North America",
    SpecialPricingContactEUMiddleEastAfrica: "Europe, Middle East, and Africa",
    SpecialPricingContactAsia: "Asia (including India and Australia and New Zealand)",
    CustomPricingStrategic: "Custom Pricing (Strategic)",
    CPContactGuidance: "Please reach out to the Microsoft contact(s) listed in the CP(Strategic) case.",
    CPContact: "Alternatively, contact ",
    ClaimsSupportGuidance: "Claims support & MDPP Portal (tool) issues",
    ClaimsContact: "Contact",
    ContactByRegion: "Contact By region:",
    ProductQuantityWarningText: "Units entered does not meet the unit threshold value for the selected product group",

    OTLE: "One-Time Local Empowerment (OTLE)",
    OTLEContactGuidance: "Reach out to the Microsoft contact(s) listed in the OTLE case.",
    //Deal Registration texts
    OpportunityDetailsText: "Opportunity Details",
    EndCustomerText: "End Customer",
    CustomerCountryText: "Customer Country",
    CustomerNameText: "Customer Name",
    CustomerDomainText: "Customer Domain",
    CustomerDomainPlaceholderText: "Enter the Customer Domain",
    NewCustomerText: "New Customer Information",
    ResellerTypeText: "Reseller Type",
    ContactEmailText: "Contact Email",
    DistributorCountryText: "Distributor Country",
    DistributorNameText: "Distributor Name",
    DistributorContactEmailText: "Distributor Contact Email",
    EpcText: "EPC",
    ExceptionProgramCodeText: "Exception Program Code (EPC)",
    SelectDealType: "Select Deal Type",
    LoyaltyPricingText: "Loyalty Pricing",
    DealRegistrationText: "Deal Registration",
    SpecialPricingText: "Special Pricing",
    SameAsLegalCustomerRecord: "Same As Legal Customer Record address",
    DealsHeadingText: "Deals",
    DeviceShipToAddressText: "Street Address",
    DeviceShipToAddressCardText: "Device Ship-To-Address",
    PreSalesActivitiesText: "Pre-Sales Activities",
    PreSalesActivitiesPlaceholderText: "Enter pre-sales activities",
    AdditionalInformationsPlaceholderText: "Enter additional information about the deal",
    CityText: "City",
    StateProvinceText: "State / Province",
    DeviceShipToStateProvinceText: "State or Province",
    PostalCodeText: "Postal Code",
    ProductsText: "Products",
    PricingAndDiscountsText: "Pricing & Discounts",
    CommittedDealVolumeText: "Committed Deal Volume",
    PartcularsText: "Particulars",
    TotalCommittedVolumeText: "Total Committed Volume",
    YesText: "Yes",
    NoText: "No",
    AttachmentsText: "Attachment(s)",
    JustificationText: "Justification",
    MonthsText: "months",
    CustomPricingVolumewithinText: "Custom Pricing Volume within",
    QuantityText: "Quantity",
    DRText: "DR",
    DealRegistrationVolumewithinText: "Deal Registration Volume within",
    ProductSelectText: "Select Products",
    RfpRfqPublicTenderText: "RFP/ RFQ/ Public Tender",
    DealContactsText: "Deal Contacts",
    EmailDomain: "Email Domain",
    EmailDomainPlaceHolder: "Enter the email domain",
    EmailDomainPlaceHoldertText: "e.g., “@example.com”",
    WebPageUrl: "Webpage URL",
    WebPageUrlPlaceHolder: "Enter the webpage URL",
    WebPageUrlPlaceHolderText: "e.g., ”https://www.example.com”",
    CustomerCountry: "Customer Country",
    Industry: "Industry",
    IndustryPlaceholder: "Select Industry",
    CustomerNameEnglish: "Customer Name (English)",
    CustomerNameEnglishPlaceHolder: "Enter the Customer Name (English)",
    CustomerNameEnglishPlaceHolderText: "e.g., 'Toyota'",
    CustomerNameLocalLanguage: "Customer Name (Local Language)",
    CustomerNameLocalLanguagePlaceHolder: "Enter the Customer Name (Local Language)",
    CustomerNameLocalLanguagePlaceHolderText: "e.g., 'トヨタ'",
    SameAsCustomerNameEnglish: "Same as Customer Name (English)",
    AddressLine1: "Address Line 1",
    AddressLine1PlaceHolder: "Enter the customer Address Line 1",
    AddressLine1PlaceHolderText: "e.g., “1, TOYOTA-CHO”",
    City: "City",
    CityPlaceHolder: "Enter the City",
    CityPlaceHolderText: "e.g., “Aichi”",
    PostalCode: "ZIP / Postal Code",
    DeviceShipToPostalCodeText: "Postal Code",
    PostalCodePlaceHolder: "Enter the ZIP / Postal Code",
    PostalCodeMask: "9999999",
    State: "State / Province",
    StatePlaceHolder: "Enter the State / Province",
    StatePlaceHolderText: "e.g., “Tokyo”",
    Vertical: "Vertical",
    VerticalPlaceholder: "Select Vertical",
    VerticalPlaceholderText: "e.g., 'High tech Electronics'",
    VerticalCategory: "Vertical Category",
    VerticalCategoryPlaceholder: "Select Vertical Category",
    VerticalCategoryPlaceholderText: "e.g., 'Communication Equipments'",
    DealNamePlaceHolderTextExample: `e.g., "Walgreens DR Deal"`,
    CustomerDomainPlaceholderTextExample: `e.g., "@example.com"`,
    DeviceShipToAddressPlaceholderTextExample: `e.g., 200 WILMOT RD`,
    DeviceShipToCityPlaceholderTextExample: `e.g., Deerfield`,
    DeviceShipToStatePlaceholderTextExample: `e.g., Illinois`,
    DeviceShipTopostalPlaceholderTextExample: `e.g., 471-8734.`,
    EmailCCRecipientsPlaceholderTextExample: `e.g. "sample@example.com;"`,
    DealOverviewPlaceholderTextExample: 'Enter deal overview',
    RfpRfqPublicTenderJustificationText: "RFP / RFQ / Public Tender  Justification",
    RfpRfqPublicTenderJustificationPlaceholderText: "Enter RFP / RFQ / Public Tender justification",
    RfpRfqPublicTenderAttachmentText: "RFP / RFQ / Public  Tender Attachment(s)",
    DealVolumeBasedOnDealTypes: "{0} Volume within {1} month(s)",
    AllowedFileExtensionsForDRAndSP: ".msg, .pdf, .xlsx, .xls, .doc, .docx, .txt",
    AttachmentFileNameText: "File Name",
    AttachmentFileSizeText: "File Size(bytes)",
    CasesDropdownPlaceHolderText: "Select an option",
    BreadcrumbAriaLabelText: "Breadcrumb with items rendered as buttons",
    BreadcrumbOverflowAriaLabelText: "More links",

    //DR + CP Channel texts
    GuidanceTextForMSStore: "Please select the Microsoft Store Sales Channel and provide associated information.",
    MSStoreChannel: "Microsoft Store Sales Channel",
    DSSText: "Direct Sales and Support (DSS)",
    DigitalStore: "Digital Store",
    Hub: "Hub Name",
    DigitalStoreName: "Digital Store Name",
    MSStoreSellerName: "Microsoft  Store Seller Name",
    MSStoreSellerEmail: "Microsoft Store Seller Email",
    MSStoreSellerNamePlaceholderText: "Enter MS Store seller name",
    MSStoreSellerEmailPlaceholderText: `e.g. "sample@example.com"`,
    MsStoreLabeltext: "Is MS-Store a Reseller?",
    AdditionalResellersInformation: "Additional Resellers Information",
    ResellerInformation: "Reseller Information",
    GuidanceTextForReseller: "Please add Reseller to this opportunity and provide associated information.",
    GuidanceTextForMultipleReseller: "Please add one or more Reseller(s) to this opportunity and provide associated information.",
    AdditionalResellerChoiceQuestion: "Is this opportunity part of a Public Sector (PS)/ SOE Deal?",
    AddAdditionalResellers: 'Add Additional Reseller(s)',
    FinalTierResellerQuestion: "Are you selling to a Final Tier Reseller(s)?",
    FinalTierResellerInformation: "Final Tier Reseller Information",
    AddFinalTierResellers: "Add Final Tier Reseller(s)",
    DistributorInformation: "Distributor Information",
    GuidanceTextForDistributor: "Click below to add additional Distributors to this case.",
    AddDistributors: "Add Distributor(s)",
    DistributorNames: "Distributor Name(s)",
    DistributorOwner: "Distributor Owner",
    AssociatedReseller: "Associated Reseller",
    ContactEmailIds: "Contact Email Id(s)",
    ResellerNames: "Reseller Name",
    ContactName: "Contact Name",
    ZipPostalCode: "ZIP / Postal Code",
    CaseDecisionJustification: "Case Decision Justification / Comments",
    DealInfoCaseStartDateText: "Start Date",
    DealInfoCaseExpireDateText: "Expiration Date",
    DealInfoUnitsTransactedText: "Transacted Volume",
    ResellerNameErrorMessage: 'Reseller Name should not be blank, please select a Reseller.',
    DistributorNameErrorMessage: 'Select atleast one Distributor',
    NoDistributorFoundInEufta: "No records found. Please select an alternate country to locate your desired distributor.",
    EditText: "Edit",
    ExtendText: "Extend",
    ExpireText: "Expire",
    RenewText: "Renew",
    CopyText: "Copy & Submit New",
    DeleteText: "Delete",
    DRSPCaseDecisionLetterFileName:"Partner Case Processed-{0}{1}{2}.pdf",
    CaseDecisionLetterFileName: "Case Decision - {0}.pdf",
    Back: "Back",
    CaseName: "CaseName",
    MPNOrgID: "MPN OrganizationID:",
    MPNLocationID: "MPN LocationID / Country:",
    ProductGroup: "Product Group:",
    PartnerRole: "Partner Role",
    NewCaseSubmissionText: "New Case Submission",
    HomeText: "Home",
    NewCaseKeyText: "NewCase",
    ViewCaseKeyText: "ViewCase",
    DashboardText: "Dashboard",
    SelectPricingProgramText: "Select Pricing Program(s)",
    CustomerText: "Customer",
    DealInformationText: "Deal Information",
    BackButtonText: "Back",
    ChooseColumnsLabel: "Choose Columns",
    Resetbtn: "Reset to default",
    ColumnDivider: "divider",
    ColumnsHeader: "Columns",
    DealInfoEndDateText: "End Date",
    SuccessCaseSubmissionText: "has been submitted successfully.",
    SuccessDraftCaseSubmissionText: "has been successfully saved in your dashboard as a draft, and will remain in draft status for a maximum of <b>14 calendar days.</b> Please note that if the case has not been submitted after 14 calendar days, <u>it will be deleted from your dashboard</u>. You will not be able to re-access the case once it has been deleted.",
    ThankyouSubmissionText: "Thank you for your submittal! Microsoft will begin review of the opportunity and you will be notified when a decision is made.",
    CreateNewCaseText: "Create New Case",
    //Final Tier Fields
    ResellerNamePlaceHolder: 'e.g. \"Open System Trading Co., Ltd.\"',
    ContactNamePlaceHolder: 'e.g. \"Open System Trading\"',
    ContactEmailPlaceHolder: 'e.g. \"abc@example.com\"',
    AddressPlaceHolder: 'e.g. \"No.295 Zhongshan Rd\"',
    CityTextFieldPlaceHolder: 'e.g. \"Hangzhou\"',
    StateOrProvincePlaceHolder: 'e.g. \"Shanghai\"',
    ZipOrPostalCodePlaceHolder: 'e.g. \"201103\"',
    CancelDialogText: 'Do you want to save your changes before leaving this page?',
    SaveAndAddNextButton: 'Save and Add Next',
    //Additional
    AddAdditionResellersText: 'Add Additional Reseller(s)',
    AddFinalTier: 'Add Final Tier Reseller(s)',
    FinalTierReseller: 'Final Tier Reseller',
    AdditionalReseller: 'Additional Reseller',
    CustomerSearchGuidance: "To create a Surface device opportunity, firstly you will need to search the customer. Once you’ve identified the customer , you can proceed with Case submission process.If you do not find the customer through search results, please create a new customer using the ”Submit a New Customer” button below.",
    CustomerSearchGuidanceOTLE: " To create a Surface device opportunity, firstly you need to search the customer, you can proceed with Case submission process. If you don't find the customer through search results, please create a new customer using the \"Submit a New Customer\" button below.",
    CustomerSearchResultsGuidance: "The MDPP Portal’s customer search results are displayed at a CUSTOMER LEGAL ENTITY level – this means that it is possible that the search results you see will not be identical to the search criteria you entered. If your specific search criteria is found, and that record is part of a larger Legal Entity, the system will display the Legal Entity HQ information, as the search result. You must identify and select the customer legal entity associated with your search criteria.  During case submission, you will provide End Customer Shipping Information (i.e. specific end customer location associated with the deal) within the submission form.",
    PartnerInformation: "PartnerInformation",
    SubmitNewCustomer: "Submit a New Customer Record",
    MPNLocationIdCountry: "MPN Partner Organization / Country",
    MPNLocationIdCountryPlaceHolder: "Select Partner Organization / Country",    
    VerifyDetailsHeader: "Verify Details for Case Submission",
    TextSizeXLarge: "xLarge",
    POIIconName: "POI",
    ProductVariantIConName: "ProductVariant",
    ContactIconName: "Contact",
    ContactInfoIconName: "ContactInfo",
    AccountManagementIcon: "AccountManagementIcon",
    DateCalendarIcon: "CalendarReply",
    NoteReplyIcon: "NoteReply",
    AttachIcon: "Attach",
    CaseSubmissionText: "Case Submission",
    NoADDAsscociatedWithADRText: "You are not seeing any ADD's as no ADDs are associated to this case for this country.",
    RevertInProgress: "Submitting Revert is in-progress...",
    RevertError: "Error occured while reverting the case. Please try again later.",
    RevertCaseMessage: "Revert case is successfully submitted. Please check case status after few minutes.",
    ConfirmSelectedDuplicateCustomer: "Do you want to continue with the new case submission with existing Customer record?",
    Segments: "Segment(s)",
    DeleteVTeamText: "DeleteVTeam",
    DeleteChannelAccount: "DeleteChannelAccount",
    CaseActionsDialog: {
        CancelButton: 'Cancel',
        ConfirmButton: 'Confirm'
    },
    Extend: {
        ActionName: 'Extend',
        ExpirationDate: 'Expiration Date',
        ReasonForExtension: 'Reason for extension',
        TotalUnitsTransacted: 'Total unit transactions to date',
        ExpectedUnitTransactions: 'Expected unit transactions in the next extension period',
        ConfirmButton: 'Confirm',
        CancelButton: 'Cancel',
        BackButton: 'Back',
        ExtensionSuccessMessage: 'Case have been extended successfully.',
        ExtensionFailureMessage: 'Case extension failed! Expected units are not transacted for extension.',
        ExtensionReviewMessage: 'Case have been successfully submitted for review.',
        DealRegDescription: `Please choose a new expiration date for this Deal Registration case. Your case can be extended up to the date shown below. 
        You may request a shorter extension period, if desired. Your request will be auto-approved if you have transacted 1 or more units on this case.`,
        SpecialPricingDescription: `Please choose a new expiration date for this Special Pricing case. 
        Your case can be extended up to the date shown below. You may request a shorter extension period, if desired. 
        Your request will be auto-approved if you have transacted atleast {0} units total.`,
        WarningMessage: `The expiration date exceeds the maximum possible expiration date for the case. Please choose a different one.`,
        MaxExtensionLimitMessage: `Selected case has already been auto extended maximum possible times.`,
        MaxExtensionMessage: `Selected case has already been set to expire at the maximum possible expiration date.`
    },
    Renew: {
        ActionName: 'Renew',
        RenewalMessage: `Case Renewal applies to each pricing program individually (Deal Registration or Special Pricing). 
        Your renewal request, once submitted, will be forwarded to Microsoft for review. 
        If approved, your renewed Deal Registration or Special Pricing will appear as a latest item in your case dashboard under the same Case ID, 
        and your prior DR or SP case will transition to 'expired' status.`
    },
    Expire: {
        ActionName: 'Expire',
        ExpireMessage: `This 'expire' function will immediately transition the case from “approved” to “expired” status, 
          and any future claims submitted will be validated against the case’s early expiration date.`
    },
    Delete: {
        ActionName: 'Delete',
        DeleteMessage: `Are you sure you wish to delete this case? All information will be lost, and this case will no longer be accessible in your dashboard. 
          Select 'Confirm' to delete this case, or 'Cancel', to return to your case dashboard.`
    },
    Revert: {
        ActionName: 'Revert',
        RevertMessage: `Are you sure you want to revert this case back to “draft” status? 
          If you revert the case, it will be removed from its current queue position, and upon your next submittal, 
          the case will be placed at the lowest queue position.`
    },
    Copy: {
        ActionName: 'Copy and Submit New',
        CopyMessage: `Please confirm to Copy and Submit New Case.`
    },
    DownloadCaseLetters: {
        ActionName: 'Download Case Decision Letter',
        CopyMessage: `Please confirm the type of decision letter.`
    },
    ConfirmFormOutTitle: "Confirm",
    ConfirmFormOutMessage: "Do you want to save your changes before leaving this page?  Save, Discard changes. Discard changes will cancel all the information without saving. Save would save the information on the form.",
    CreateCustomerSubmitBtnText: "Submit new customer record for review",
    PricingProgramBtnText: "Submit Selected Customer ",
    CreatNewRecordText: "Create New record",
    AddNewCustomerText: "Add New Customer",
    EditCustomerText: "Edit Customer",
    PanelCustomerWidth55Percent: "55%",
    HasWebPageURLLabel: "Has Web Page URL",
    Max500CharErrorMessage: "Max 500 characters allowed.",
    Max200CharErrorMessage: "Max 200 characters allowed.",
    Max100CharErrorMessage: "Max 100 characters allowed.",
    Text500: 500,
    Text200: 200,
    Text100: 100,
    BoosterDiscountColumnName: "boosterDiscountValue",
    TotalDiscountColumnName: "totalDiscountValue",
    InactiveCustomer: "InActive Customer",
    SelectedCustomer: "Selected Customer: ",
    CustomerDetails: "Customer Details",
    NewCustomerDetails: "New Customer Details",
    SubmitNewCustomerRecord: "Submit New Customer Record",
    SmallPanel: "30%",
    Filter: "Filter",
    LoadingMCL: "Loading...",
    InactiveProducts: "InActive Product(s)",
    Save: "Save",
    Requester: "Requester",
    Default: "Default",
    CaseCadeProductTitle: "Product(s) CaseCade",
    InactiveProductsSubTitle: "Following are the InActive",
    CustomerSearchText: "Search by Customer Name or DUNS ID",
    InvalidPfamError:"The selected PFAM '{0}' is not applicable for Special Pricing",
    MultipleInvalidPfamError:"Some of the selected PFAMs cannot be added as those are not applicable for Special Pricing",

    //BidGrid texts
    BGShowDiscountsText: "Show Discounts",
    BGProductGroup: "Product Group",
    BGProductGroupPlaceholder: "Select Product Group",
    BGCountryLabel: "End Customer Country",
    BGCountryLabelPlaceholder: "Select End Customer Country",
    BGDatePickerLabel: "Date",
    BGSearchPlaceholder: "Search by SKU Number",
    DatePlaceHolder: "Select Date",
    BGComboBoxID: "BGComboBox",
    ExportSkuTOExcel: "Export SKUs to Excel",
    NoRecordsFound: " No Cases available for your Partner Organization and Location.",
    ProductinformationFooterText: " DISCLAIMER - Updates can happen to NetMsRP, List Price, ERP, discount % - all subject to change @ Microsoft discretion",
    ProductFamilyNameExcelColumnTitle: "Product Family Name",
    BusinessUnitExcelColumnTitle: "Business Unit",
    SkuNameExcelColumnTitle: "SKU Name",
    skuNumberExcelColumnTitle: "SKU Number",
    DealDetailsIcon: "Commitments",
    CaseDetailsIcon: "EntitlementPolicy",
    AddressIcon: "MapPin",
    PublicTenderPolicyIcon: "MultiSelect",
    PreSalesIcon: "Generate",
    CustomerDetailsIcon: "ContactInfo",
    DistributorIcon: "UserEvent",
    ResellersIcon: "TeamFavorite",
    MSStoreIcon: "Telemarketer",
    PricingAndDiscountsIcon: "CalculatorPercentage",
    ProductSearchIcon: "SearchAndApps",
    CommittedDealVolumeIcon: "Quantity",
    GeoCoverageIcon: "World",
    PartnerDetailsForCaseText: "Partner details for the case",
    NoRecordsAvailableText: "No records available.",
    DuplicateLEMessage: "For uploading of legal entities, please ensure that your upload file does not contain duplicates of legal entities that were previously submitted for this case. If a duplicate legal entity name is found in your file for a given country, it will not be added to the case. Only one legal entity name will be allowed to exist in this case, for a given country.",
    MDPPTransitionBannerNotificationText: "Notice to Partners: As part of the transition of the Surface Pricing Program to the MDPP Portal on October 31st, 2022, MDPP Portal will temporarily close on Saturday, October 29th, at 8 AM (Pacific Daylight Time). The portal will re-open on Monday, October 31st, at 8 AM (Pacific Daylight Time), at which the legacy Surface Pricing Portal will be closed. All cases must be accessed from MDPP portal.",
    OTLENewCaseSubmission: "OTLE Case Submission",
    CPNewCaseSubmission: "CP Strategic Case Submission",
}

export const UIConfigIdentifierConstants = {
    StackForCaseDecisionRowId: "StackForCaseDecisionRow",
    StackDealsNameRowId: "StackDealsNameRow",
    PartnerInformationId: "PartnerInformation",
    StackItemSaveDraftButtonId: "StackItemSaveDraftButton",
    StackDeviceShipToAddressRowId: "StackDeviceShipToAddressRow",
    StackItemDealOverviewId: "StackItemDealOverview",
    StackItemPreSalesActivitiesId: "StackItemPreSalesActivities",
    RfpRfqPublicTenderId: "RfpRfqPublicTender",
    StackItemPublicTenderJustificationId: "StackItemPublicTenderJustification",
    StackItemEmailCCNotificationRecipientsId: "StackItemEmailCCNotificationRecipients",
    DealOverviewTextFieldId: "DealOverviewTextField",
    ChannelContainerForMSStoreId: "ChannelContainerForMSStore",
    PrimaryResellerContainerId: "PrimaryResellerContainer",
    AdditionalResellersChoiceGroupId: "AdditionalResellersChoiceGroup",
    FinalTierResellersChoiceGroupId: "FinalTierResellersChoiceGroup",
    GuidanceTextForMSStoreId: "GuidanceTextForMSStore",
    GuidanceTextForResellerId: "GuidanceTextForReseller",
    StackItemForDealVerticalDropdownId: "StackItemForDealVerticalDropdown",
    StackContainerDomainId: "StackContainerDomainId",
    ExpirationHeading: "Expiration Details",
    AdditionalInformation: "Additional Information",
    StackItemAdditionalInformation: "StackItemAdditionalInformation",
}
