/**
 * Enum for CaseActions
 * @readonly
 * @enum {number}
 */
 export enum CaseActions {
    /**
     * Revert
     */
   Revert = "Revert",

   /**
    * Expire
    */
   Expire = "Expire",

   /**
    * Delete
    */
   Delete = "Delete",

   /**
    * Copy
    */
   Copy = "Copy",

   /**
    * Renew
    */
   Renew = "Renew",

   /**
    * Extend
    */
   Extend = "Extend",

   /**
    * ViewCaseLetters
    */
   ViewCaseLetters = "ViewCaseLetters",

   /**
    * DownloadCaseLetters
    */
   DownloadCaseLetters = "DownloadCaseLetters",

   /**
    * Edit
    */
   Edit = "Edit",

   /**
    * View
    */
   View = "View"
}