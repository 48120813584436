/**
 * ErrorMessage Constants.
 * @constant 
 */
export const ErrorMessages = {
    RequiredProductGroup: "Product Group is a required field.",
    RequiredDealGeoCoverage: "Deal Geo Coverage is a required field.",
    RequiredDealCountries: "Deal Country(s) is a required field.",
    MultipleDealCountriesError: "Please select more than one Geo Country for Multi-National Deal.",
    RequiredAffiliationType: "Affiliation Type is a required field.",
    RequiredAffiliationTypeOthers: "Affiliation Type Others is a required field.",
    RequiredFieldError: "This is required field.",
    DealContactInputError: "Enter valid Microsoft email or alias.",
    DealContactNameInputError: "No contact found for given alias/email.",
    RequiredLegalEntity: "Please select at least one Legal Entity per each selected Geo Country.",
    SingleRequiredLegalEntity: "Please select a Legal Entity.",
    ValidLegalEntityName: "Please select the Legal Entity with valid Legal Entity Name.",
    PrimaryLegalEntityCustomer: "Please select the Primary Legal Entity.",
    LegalEntityDealVolume: "Please select valid Deal Volume for legal entities.",
    RequiredChannelOverview: "Channel Overview is a required field.",
    RequiredChannelStructure: "Channel Structure is a required field.",
    DuplicateADRErrorText: "Duplicate Country and Partner Type are not allowed.",
    RequiredChannelSkip: "Channel Skip Reason is a required field.",
    RequiredChannelJustification: "Channel Justification is a required field.",
    RequiredChannelPartner: "One of the Channel Partners row has not valid input.",
    RequiredResellerPartner: "Please select at least one Reseller.",
    RequiredChannelException: "Please provide minimum one attachment as proof of Partner Selected Channel exception.",
    RequiredAttachementException: "Please provide minimum one attachment as proof in Channel.",
    RequiredProofOfAttachementException: "Please provide minimum one attachment - Proof of End Customer's Preference.",
    RequiredDealName: "Deal Name is a required field.",
    RequiredDealOverview: "Deal Overview is a required field.",
    RequiredDealVertical: "Deal Vertical is a required field.",
    RequiredExpirationDate: "Expiration Date is a required field.",
    RequiredExpirationDateJustification: "Expiration Date Justification is a required field.",
    RequiredProduct: "Product is required field.",
    RequiredProductQuantity: "Invalid Quantity, please input valid quantity value.",
    RequiredProductGeoCountry: "Please add at least one product for per selected Geo Country.",
    SingleRequiredProductGeoCountry: "Please add at least one product.",
    RequiredDealContacts: "One of the selected Deal Contacts has missing required field(s).",
    LegalEntityValidationError: "Please fix the errors in the selected Legal Entities.",
    APIErrorMessage: "An error occurred while processing your request. Please try after some time.",
    TotalDealVolume: "Deal Volume should not be 0. Please enter valid Deal Volume.",
    RequiredLengthForResellerName: "Please provide minimum of 3 characters.",
    ValidEmailError: "Please enter valid email address(es).",
    ValidPostalCodeError: "Please enter valid postal code.",
    ValidCustomerDomainError: "Please enter valid customer domain.",
    RequiredChannelContactError: "Please enter valid email address(es) for all channel contacts.",
    ValidEmailCCRecipientsError: "Please enter valid email CC Notification Recipient(s).",
	CountryRequired: "Value required for 'Customer Country', please select country for Customer Search.",
    CustomerNameRequired: "Value required for 'Customer Name (English)', please input Customer Name (English).",
    CustomerLocalNameRequired: "Value required for 'Customer Name (Local Language)', please input Customer Name (Local Language).",
    AddressLine1Required: "Value required for 'Address', please input Address.",
    PostalCodeRequired: "Value required for 'ZIP / Postal Code', please input ZIP / Postal Code.",
    CityRequired: "Value required for 'City', please input City.",
    StateRequired: "Value required for 'State / Province', please input State / Province.",
    IndustryRequired: "Value required for 'Industry', please select Industry for Customer.",
    VerticalRequired: "Value required for 'Vertical', please select Vertical for Customer.",
    VerticalCategoryRequired: "Value required for 'Vertical Category', please select Vertical Category for Customer.",
    WebPageURLRequired: "Please Enter web page url",
    WebPageURLpattern: "Please check the web page url",
    EmailPattern: "Please check the email pattern, Required email pattern @xyz.com",
    CustomerNameEnglishValidationMsg:"\"Account Name\" entered by user is not eligible, and an English name must be entered.",
    FileExtensionError: "File type {0} is not allowed. Allowed file types are {1}.",
    DealInfoDealNameRequired: "Value required for 'Deal Name', please input Deal Name.",
    DealInfoDealVerticalRequired: "Value required for 'Deal Vertical', please input Deal Vertical.",
    DealInfoCustomerDomainRequired: "Value required for 'Customer Domain'.",
    DealInfoDeviceShipToAddressRequired: "Value required for 'Street Address', please input Street Address.",
    DealInfoCityRequired: "Value required to 'City', please input City.",
    DealInfoStateRequired: "Value required for 'State or Province', please input State or Province.",
    DealInfoPostalCodeRequired: "Value required for 'Postal Code', please input Postal Code.",
    DealInfoDealOverviewRequired: "Value required for 'Deal Overview', please input Deal Overview.",
    DealInfoPreSalesRequired: "Value required for 'Pre-sales Activities', please input Pre-sales Activities.",
    DealInfoRfpJustificationRequired: "Value required for 'Justification', please input Justification.",
    DealInfoCommittedBlankDealVolume: "Committed Deal Volume should not be blank, please input quantity.", 
    DealInfoCommittedZeroDealVolume: "Committed Volume should not be Zero, please input quantity." ,
    DealInfoCommittedMinimumThreshold: "Commited Volume does not meet minimum threshold." ,
    RequiredMSStoreHub: "Value required for 'Hub Name', please input Hub name.",
    RequiredMSStoreDigitalStore: "Value required for 'Digital Store Name', please input Digital Store Name.",
    RequiredCity: "Value required for 'City', please input City name.",
    RequiredState: "Value required for 'State/Province/Region', please input State/Province/Region.",
    RequiredMSStoreSellerEmail: "MS Store Seller Email should not be blank.",
    InvalidMSStoreSellerEmail: "Invalid MS Store Seller Email, please input valid email id.",
    InvalidMicrosoftMSStoreSellerEmail: "MS Store Seller Email must end with '@microsoft.com', please input valid email id.",
    RequiredMSStoreSellerName: "Value required for 'MS Store Seller Name', please input Seller Name.",
    PricingProgramSelection: "Value required for 'Pricing Program', please input Pricing Program.",
    RequiredResellerEmail: "Contact Email should not be blank.",
    InvalidResellerEmail: "Invalid Contact Email, please input valid Contact Email.",
    UnavailableChannelPartner: "Unavailable Partner",
    UnavailablePartner: "Unavailable Partner(s): ",
    InvalidResellerAccounts: "Please Note: The selected channel partner is no longer available for inclusion in this case. Please edit your channel section to select available partner(s).",
    InvalidDistributorAccounts: "Please Note: The selected channel partner is no longer available for inclusion in this case. Please edit your channel section to select available partner(s).",
    InActiveManagedCustomerMessage :"Please Note: The current customer record is no longer available for inclusion in this case. Please create a new case submission and search for the latest customer record or submit a new customer record for review.",
    InActiveUnManagedCustomerMessage :"Please Note: The current customer record is no longer available for inclusion in this case. Please create a new case submission and search for the latest customer record or submit a new customer record for review.",
    ValidatePrimaryResellersOnly: "Select a Reseller.",
    ValidatePrimaryDistributorOnly: "Select atleast one Distributor.",
    ValidateCustomer: "Please Select Customer.",
    ValidateProduct: "Please Select Product.",
    ValidateAdditionalReseller: "Select atleast one Additional Reseller.",
    ValidateFinalReseller: "Select atleast one Final Reseller.",
    InactiveProductError: "One or More products are InActive.",
    InActiveProductErrorMessage: "Please Note: One or more of the previously selected products (listed below) are no longer available for inclusion in this case. Please update your product selection before submitting this case. If you believe the unavailable products are a result of an error, please contact sppsup@microsoft.com and share the exact product information, as well as the case details for this opportunity",
    ProductsCascadeMessageHeaderText: "Notice - Regarding Product Additions",
    ProductsCascadeMessage :"Your request to add the following product families to your Special Pricing case will also submit a parallel edit request to Microsoft, to add the corresponding Business Units for these Product Families, to your DR case. Review decisions and associated pricing/discounts will be available in the respective DR/SP cases in your dashboard.",
    ExpirationDateBeyondThresholdDate: "Expiration date should be within {0} days from today.",
    ExpirationDateShouldNotBeEqualToCurrentDate: "You must select a date in the future.",
    DealVolumeThresholdWarningForOTLE: "The input quantity does not meet the unit threshold for the selected product group.",
    EmailShouldBeMicrosoftDomain: "Microsoft alias is only accepted.",
    InputQuantityShouldNotBeZero: "Input quantity cannot be zero"
}