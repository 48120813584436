/**
 * Page Style for InternalPricing.
 * @constant
 */
export const PageStyles = {
    disabledTabs: {
        opacity: 0.5,
        cursor: "default"
    },
    missingFieldTabs: {
        borderBottom: "solid 3px red"
    },
    commandButtonStyles: {
        root: { marginTop: 20 },
    },
    commandButtonPadding: {
        root: { marginRight: 16 },
    },
    labelStyles: {
        root: { marginTop: 10 },
    },
    dropdownStyles: {
        dropdown: { width: 300 },
    },
    dropdownStylesTwoColumn: {
        dropdown: { width: 400 },
    },
    addItemButtonStyles: {
        height: "25px",
        width: "150px",
        padding: 0
    },
    addResellerAlignement: {
        height: "25px",
        width: "150px",
        padding: 0,
        marginTop : 0,
        marginBottom: 15
    },
    textFieldStylesOneColumn: {
        root: { width: 300 },
    },
    textFieldStylesTwoColumn: {
        root: { width: 250 },
    },
    textFieldStylesFourColumn: {
        root: { width: 140 },
    },
    dealContactAddButton: {
        height: "25px",
        width: "150px",
        padding: 0
    },
    ErrorMessageStyles: {
        paddingBottom: '30px'
    },
    missingFieldHighlight: {
        border: "solid 1px red"
    },
    gridCellDropDownStyles: {
        dropdown: { width: 80 },
    },
    paddingBottom15: {
        paddingBottom: '15px'
    }
}