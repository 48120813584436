import { Fragment, FormEvent, useState, useEffect, FC, useCallback } from "react";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import { CaseDropdown } from "../../components/shared/searchablecombobox/casedropdowncontrol";
import { DashboardViewTypesValues, DashboardViewTypesValuesForMSSeller } from "../../services/StaticData";
import { Breadcrumbs } from "../../components/shared/breadcrumb/breadcrumb";
import { DashboardHeaderStyle, pageTitleStyles, separatorStyles, _Styles } from "../../content/styles/Page.styles";

import { getUserMainRole } from "../../components/shared/common/util";
import { Separator } from "@fluentui/react/lib/Separator";
import Row from "react-bootstrap/esm/Row";
import { UserRole } from "../../models/UserRole";
import { ApplicationConstants } from "../../models/ApplicationConstants";
import { useHistory } from "react-router-dom";

const items: IBreadcrumbItem[] = [
    {
        text: 'Home', key: 'home'
    },
    {
        text: 'Dashboard', key: 'dashboard'
    },
]

export const CaseManagement: FC = () => {
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>(items);
    const [userMainRole] = useState<string>(getUserMainRole());
    const [selectedKey, setSelectedKey] = useState<number>();
    const history = useHistory();

    useEffect(() => {
        const item: IDropdownOption<any> = {
            key: "",
            text: ""
        }

        if(window.location.href.endsWith(ApplicationConstants.OTLERoute)) {
            item.title = ApplicationConstants.OTLERoute;
            item.text = ApplicationConstants.OTLECases;
            setSelectedKey(6);
        } else if (userMainRole !== UserRole.MSSeller && window.location.href.endsWith(ApplicationConstants.SpecialPricingRoute)) {
            item.title = ApplicationConstants.SpecialPricingRoute
            item.text = `${ApplicationConstants.SpecialPricing} ${ApplicationConstants.Cases}`
            setSelectedKey(2);
        } else if (userMainRole !== UserRole.MSSeller && window.location.href.endsWith(ApplicationConstants.DealRegistrationRoute)) {
            item.title = ApplicationConstants.DealRegistrationRoute;
            item.text = `${ApplicationConstants.DealRegistration} ${ApplicationConstants.Cases}`
            setSelectedKey(3);
        } else if (window.location.href.endsWith(ApplicationConstants.CPStrategicRoute)) {
            item.title = ApplicationConstants.CPStrategicRoute
            setSelectedKey(4);
            item.text = ApplicationConstants.CPStrategicCases;
        } else if (userMainRole !== UserRole.MSSeller && window.location.href.endsWith(ApplicationConstants.SPLocalEmpowerment)) {
            item.title = ApplicationConstants.SPLocalEmpowerment
            setSelectedKey(5);
            item.text = ApplicationConstants.SPLocalEmpowermentCases;
        } else {
            item.title = ApplicationConstants.AllCasesRoute
            setSelectedKey(1);
            item.text = ApplicationConstants.AllCases
        }
        onChangeHandler(undefined, item);
    }, [])

    function onChangeHandler(event?: FormEvent<HTMLDivElement>, item?: IDropdownOption<any>) {
        const route = item?.title!
        history.push(route)
        setBreadcrumbItems([...items, { text: item?.text, key: item?.title }]);
    };

    return (
        <Fragment>
            <div className="headerStyle">
                <Row>
                    <Breadcrumbs items={breadcrumbItems} />
                </Row>
                <Separator styles={separatorStyles} />
                <Row>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex">
                            <h1 className="header" style={DashboardHeaderStyle}>Dashboard</h1> <span style={pageTitleStyles}> | </span> {selectedKey && <CaseDropdown options={userMainRole === UserRole.MSSeller ? DashboardViewTypesValuesForMSSeller : DashboardViewTypesValues} onChange={onChangeHandler} disabled={false} defaultSelectedKey={selectedKey} />}
                        </div>
                    </div>
                </Row>
                <div style={pageTitleStyles.dashboardTitle}></div>
            </div>
        </Fragment>
    );
};
