import { useEffect, useState } from 'react';
import { UIControlsText, UIConfigIdentifierConstants } from '../../../../content/UIControlsText';
import { PageStyles, DealDetailsStyles, OpportunityDetailsStyles } from '../../common/content/Page.Styles';
import { useCommonPPContext } from '../../common/context/common-pp-context';
import { DistributorGridColumnConfig, PrimaryResellerGridColumnConfig, FinalTierResellerGridColumnConfig, PrimaryResellerGridItemsType, DistributorGridItemsType, IColumnConfig, AdditionalResellerViewGridColumnConfig } from '../../../shared/ComponentTypes/DetailListEditableGridTypes';
import { ChoiceGroupStyles, DefaultPageStyles, PaperStyle, _Styles } from '../../../../content/styles/Page.styles';
import { getDistinctUserRole, getLoggedinUserRoleDetails, getRequestorsChannelFunctionalities, getEntityDataFromSession, getRuleValidationMessage } from '../../../../components/shared/common/util';
import { stackItemStyles, itemAlignmentsStackTokens10, itemAlignmentsStackTokens, initialChannelDisplayOnEdit } from '../PricingProgramFormUtils';
import { PartnerTypeLabel } from '../../../../models/PartnerTypeLabel';
import { ChannelSubType } from '../../../../models/ChannelSubType';
import _ from 'lodash';
import { PartnerType } from '../../../../models/PartnerType';
import { IInitialFinalTierResellerValue } from '../../../../models/IInitialFinalTierResellerValue';
import { IInitialAdditionalResellerValue } from '../../../../models/IInitialAdditionalResellerValue';
import { ActionButton } from '@fluentui/react';
import { ChoiceGroup, IColumn } from 'office-ui-fabric-react';
import { CssClassConstants } from '../../../../content/CssClassConstants';
import { IChannelFunctionalityDisplayState } from '../../../shared/ComponentTypes/IChannelFunctionalityDisplayState';
import { JavaScriptExtensions } from '../../../../infrastructure/JavaScriptExtensions';
import { UserSubRole } from '../../../../models/UserSubRole';
import { UIControlType } from '../../../../models/UIControlType';
import { PageMode } from '../../../../models/PageMode';
import InnerHTMLContainer from '../../../shared/ComponentTypes/InnerHTMLContainer';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { MessageTypeText } from '../../../../models/MessageTypeText';

export function Channel() {
    const { commonPPContextState } = useCommonPPContext();
    const opportunityAccounts = commonPPContextState && commonPPContextState?.opportunityAccounts;
    const msStoreAccount = opportunityAccounts?.filter(t => t.partnerType === PartnerType.MsStore && t.channelSubType != ChannelSubType.Requester)?.[0];
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const opportunityChannelContacts = commonPPContextState?.opportunityChannelContacts?.filter(t => t.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText));
    const [primaryResellerGridItems, setPrimaryResellerGridItems] = useState<PrimaryResellerGridItemsType[]>([]);
    const [additionalResellerGridItems, setAdditionalResellerGridItems] = useState<IInitialAdditionalResellerValue[]>([]);
    const [finalTierResellerGridItems, setFinalTierResellerGridItems] = useState<IInitialFinalTierResellerValue[]>([]);
    const [distributorListGridItems, setDistributorGridItemsState] = useState<any[]>([]);
    //Show the content appropriate to the Channel Requestor Role and other combinations
    const [showChannelContentBasedOnRoleType, setShowChannelContentBasedOnRoleType] = useState<IChannelFunctionalityDisplayState>();
    const loggedInUserRole = getDistinctUserRole(getLoggedinUserRoleDetails());

    useEffect(() => {
        if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(commonPPContextState?.partnerDetails?.countryName) ||
            !JavaScriptExtensions.isEmptyOrNullOrUndefined(commonPPContextState?.partnerDetails?.userRole)) {
            let channelFunctionalityDisplayState: IChannelFunctionalityDisplayState = getRequestorsChannelFunctionalities(commonPPContextState.partnerDetails?.userRole || "", commonPPContextState?.partnerDetails?.countryName || "");
            initialChannelDisplayOnEdit(commonPPContextState, channelFunctionalityDisplayState);
            setShowChannelContentBasedOnRoleType(channelFunctionalityDisplayState);
        }
    }, [commonPPContextState.partnerDetails?.userRole, commonPPContextState?.partnerDetails?.countryName])

    const getPrimaryResellerGridItems = () => {
        return opportunityAccounts?.filter(reseller => reseller.partnerType === PartnerType.ADR || reseller.partnerType === PartnerType.DMP)
            .map((optyAcc) => {
                const contact = commonPPContextState.opportunityChannelContacts?.find((c) => c.opportunityAccountId === optyAcc.id);
                return {
                    id: optyAcc.id,
                    resellerCountry: optyAcc.accounts && optyAcc.accounts[0]?.countryName,
                    resellerType: optyAcc.partnerType ? PartnerTypeLabel.get(optyAcc.partnerType) : "",
                    resellerNames: optyAcc.accounts && optyAcc.accounts[0]?.companyName,
                    resellerEmailContacts: optyAcc?.channelSubType === ChannelSubType.Requester ? optyAcc.contactEmail : contact?.channelContactEmails,
                    deleteAction: optyAcc.id
                } as PrimaryResellerGridItemsType
            });
    }

    const getFinalResellerGridItems = () => {
        return opportunityAccounts?.filter(reseller => reseller.partnerType === PartnerType.FinalTierReseller)
            .map((optyAcc, idx) => {
                return {
                    id: optyAcc.id,
                    ftrNo: idx + 1,
                    resellerName: optyAcc.opportunityAccountName,
                    contactName: optyAcc.accountCompanyName,
                    contactEmail: optyAcc?.contactEmail,
                    address: optyAcc.address,
                    city: optyAcc.city,
                    stateProvince: optyAcc.stateProvince,
                    zipPostalCode: optyAcc.zipPostalCode,
                } as IInitialFinalTierResellerValue
            });
    }

    const getAdditionalResellerGridItems = () => {
        return opportunityAccounts?.filter(reseller => reseller.partnerType === PartnerType.AdditionalTierReseller)
            .map((optyAcc, idx) => {
                return {
                    id: optyAcc.id,
                    arNo: idx + 1,
                    resellerName: optyAcc.opportunityAccountName,
                    contactName: optyAcc.accountCompanyName,
                    contactEmail: optyAcc?.contactEmail,
                    address: optyAcc.address,
                    city: optyAcc.city,
                    stateProvince: optyAcc.stateProvince,
                    zipPostalCode: optyAcc.zipPostalCode,
                    attachments: commonPPContextState.attachments && commonPPContextState.attachments.filter(t => t?.relatedEntityId === optyAcc.id),
                    attachmentName: commonPPContextState.attachments?.filter(a => a?.relatedEntityId === optyAcc.id)?.map(x => x?.name).join(', '),
                } as IInitialAdditionalResellerValue
            });
    }

    const getDistributorGridItems = () => {
        return opportunityAccounts?.filter(optyAccount => optyAccount.partnerType === PartnerType.ADD)
            .map((optyAcc) => {
                const contact = commonPPContextState.opportunityChannelContacts?.find((c) => c.opportunityAccountId === optyAcc.id);
                const distiAccounts = optyAcc?.accounts && optyAcc?.accounts[0];
                return {
                    id: optyAcc.id,
                    distributorCountry: distiAccounts?.countryName,
                    distributorOwner: optyAcc?.channelSubType === ChannelSubType.CoOwner ? true : false,
                    distributorNames: distiAccounts?.companyName,
                    distributorEmailContacts: optyAcc?.channelSubType === ChannelSubType.Requester ? optyAcc.contactEmail : contact?.channelContactEmails
                } as DistributorGridItemsType
            });
    }



    // Attach Primary Reseller grid callbacks
    const attachFinalAdditionalResellerGridCallbacks = (columnConfig: IColumnConfig[]): IColumnConfig[] => {
        columnConfig.filter((item) => item.key == 'editAction').map((item) => item.hidden = true);
        columnConfig.filter((item) => item.key == 'deleteAction').map((item) => item.onRender = (item?: any, index?: number, column?: IColumn) => onRenderDelete(item, index, column));
        return columnConfig;
    };


    // Overridden OnRender event for Email textbox with validations
    function onRenderDistributorOwner(item?: any, index?: number, column?: IColumn): JSX.Element {
        return (
            <ChoiceGroup defaultSelectedKey={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText)}
                options={[{ key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText), text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText) }, { key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText), text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText) }]}
                styles={OpportunityDetailsStyles.HorizontalChoiceGroup}
                required={true}
                disabled={true}
                selectedKey={item.distributorOwner === false ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText)}
            />
        );
    };

    //Render Method for showing view of choice group control
    function onRenderViewDistributorOwner(item?: any, index?: number, column?: IColumn): JSX.Element {
        return (
            <InnerHTMLContainer contolType={UIControlType.Label} value={item.distributorOwner === false ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText)} />
        );
    }

    // Overridden OnRender event for Distributor Delete button
    function onRenderDelete(item?: any, index?: number, column?: IColumn): JSX.Element {
        return <ActionButton
            id='distributorDeleteButton'
            iconProps={{
                iconName: CssClassConstants.DeleteIconName, ariaLabel: CssClassConstants.DeleteIconName,
                styles: DefaultPageStyles.grayColor
            }}
            disabled={true}
        >
        </ActionButton>
    }

    // Attach Primary Reseller grid callbacks
    const attachPrimaryResellerCallbacks = (columnConfig: IColumnConfig[]): IColumnConfig[] => {
        columnConfig.filter((item) => item.key === 'deleteAction').map((item) => item.onRender = onRenderDelete);
        if (commonPPContextState.pageMode === PageMode.View) {
            columnConfig.forEach((item, index) => {
                if (item.key === 'deleteAction') {
                    columnConfig.splice(index, 1);
                }
            });
        }
        return columnConfig;
    };

    // Attach Distributor grid callbacks
    const attachDistributorCallbacks = (columnConfig: IColumnConfig[]): IColumnConfig[] => {
        columnConfig.filter((item) => item.key === 'distributorOwner').map((item) => item.onRender = (commonPPContextState.pageMode == PageMode.View ? onRenderViewDistributorOwner : onRenderDistributorOwner));
        columnConfig.filter((item) => item.key === 'deleteAction').map((item) => item.onRender = onRenderDelete);
        if (commonPPContextState.pageMode === PageMode.View) {
            columnConfig.forEach((item, index) => {
                if (item.key === 'deleteAction') {
                    columnConfig.splice(index, 1);
                }
            });
        }
        return columnConfig;
    };

    useEffect(() => {
        setPrimaryResellerGridItems(getPrimaryResellerGridItems() || []);
        setFinalTierResellerGridItems(getFinalResellerGridItems() || []);
        setAdditionalResellerGridItems(getAdditionalResellerGridItems() || []);
        setDistributorGridItemsState(getDistributorGridItems() || []);
    }, [opportunityAccounts])
    /**
   * Content for the Channel tab with JSON format
   * @json
   */
    const ChannelJson = [
        {
            type: "CustomTileCardComponent",
            data: {
                id: "ViewCardForMSStore",                
                visible: showChannelContentBasedOnRoleType?.MSStore,
                baseState: commonPPContextState,
                name: "MSStore Details",
                iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MSStoreIcon", UIControlsText.MSStoreIcon),
                components: [
                    {
                        type: 'Stack',
                        data: {
                            id: UIConfigIdentifierConstants.ChannelContainerForMSStoreId,
                            visible: showChannelContentBasedOnRoleType?.MSStore,
                            verticalAlign: "start",
                            styles: stackItemStyles,
                            align: "auto",
                            tokens: itemAlignmentsStackTokens,
                            'data-nextgen-parent-group': "View - MS Store Details",
                            'data-nextgen-parentid': "View - MS Store Details",
                            components: [
                                {
                                    type: 'Stack',
                                    data: {
                                        id: 'MSStoreChannelSection',
                                        horizontal: true,
                                        tokens: itemAlignmentsStackTokens,
                                        disableShrink: true,
                                        visible: showChannelContentBasedOnRoleType?.MSStore,
                                        components: [
                                            {
                                                type: 'StackItem',
                                                data: {
                                                    id: 'StackItemMSStoreChannel',
                                                    styles: stackItemStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                id: "MSStoreChannel",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MSStoreChannel", UIControlsText.MSStoreChannel),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MSStoreChannel", UIControlsText.MSStoreChannel),
                                                                contolType: UIControlType.LabelValueView,
                                                                //value: msStoreAccount?.channelSubType,
                                                                value: msStoreAccount?.channelSubType === ChannelSubType.DSS ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DSSText", UIControlsText.DSSText) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DigitalStore", UIControlsText.DigitalStore),
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                        ]
                                    },
                                },
                                {
                                    type: 'Stack',
                                    data: {
                                        id: 'MSStoreAddressSection',
                                        horizontal: true,
                                        disableshrink: true,
                                        components: [
                                            {
                                                type: 'Stack',
                                                data: {
                                                    id: 'StackItemMSStoreAddress',
                                                    styles: stackItemStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                id: "MSStoreAddressTextField",
                                                                label: msStoreAccount?.channelSubType == ChannelSubType.DSS ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Hub", UIControlsText.Hub) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DigitalStoreName", UIControlsText.DigitalStoreName),
                                                                ariaLabel: msStoreAccount?.channelSubType == ChannelSubType.DSS ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Hub", UIControlsText.Hub) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DigitalStoreName", UIControlsText.DigitalStoreName),
                                                                contolType: UIControlType.LabelValueView,
                                                                value: msStoreAccount?.address,
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                type: 'Stack',
                                                data: {
                                                    id: 'StackItemMSStoreCity',
                                                    styles: stackItemStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                id: "MSStoreCityTextField",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CityText", UIControlsText.CityText),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CityText", UIControlsText.CityText),
                                                                contolType: UIControlType.LabelValueView,
                                                                value: msStoreAccount?.city,
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                type: 'Stack',
                                                data: {
                                                    id: 'StackItemMSStoreState',
                                                    styles: stackItemStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                id: "MSStoreStateTextField",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "StateProvinceText", UIControlsText.StateProvinceText),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "StateProvinceText", UIControlsText.StateProvinceText),
                                                                contolType: UIControlType.LabelValueView,
                                                                value: msStoreAccount?.stateProvince
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                        ]
                                    }
                                },
                                {
                                    type: 'Stack',
                                    data: {
                                        id: 'MSStoreContact',
                                        horizontal: true,
                                        disableshrink: true,
                                        components: [
                                            {
                                                type: 'Stack',
                                                data: {
                                                    id: 'StackItemMSStoreSellerName',
                                                    styles: stackItemStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                id: "MSStoreSellerNameTextField",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MSStoreSellerName", UIControlsText.MSStoreSellerName),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MSStoreSellerName", UIControlsText.MSStoreSellerName),
                                                                contolType: UIControlType.LabelValueView,
                                                                value: msStoreAccount?.accountCompanyName
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                type: 'Stack',
                                                data: {
                                                    id: 'StackItemMSStoreSellerEmail',
                                                    styles: stackItemStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                id: "MSStoreSellerEmailTextField",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MSStoreSellerEmail", UIControlsText.MSStoreSellerEmail),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MSStoreSellerEmail", UIControlsText.MSStoreSellerEmail),
                                                                styles: PageStyles.textFieldStylesOneColumn,
                                                                contolType: UIControlType.LabelValueView,
                                                                value: opportunityChannelContacts?.find(c => c.opportunityAccountId === msStoreAccount?.id)?.channelContactEmails,
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                        ]
                                    },
                                },
                            ]
                        }
                    }
                ]
            }
        },
        {
            type: "CustomTileCardComponent",
            data: {
                id: "ViewCardForReseller",                
                visible: !(showChannelContentBasedOnRoleType?.MSStore) && (showChannelContentBasedOnRoleType?.FinalTierAndDistributors || showChannelContentBasedOnRoleType?.AdditionalAndDistributors || showChannelContentBasedOnRoleType?.PrimaryandAdditionalResellers || showChannelContentBasedOnRoleType?.PrimaryAndFinalTierResellers || showChannelContentBasedOnRoleType?.PrimaryResellersOnly || showChannelContentBasedOnRoleType?.PrimaryandAdditionalResellers),
                baseState: commonPPContextState,
                name: "Reseller Details",
                iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellersIcon", UIControlsText.ResellersIcon),
                components: [
                    {
                        type: 'Stack',
                        data: {
                            id: 'StackReseller',
                            'data-nextgen-parent-group': "View - Reseller Details",
                            'data-nextgen-parentid': "View - Reseller Details",
                            visible: showChannelContentBasedOnRoleType?.FinalTierAndDistributors || showChannelContentBasedOnRoleType?.AdditionalAndDistributors || showChannelContentBasedOnRoleType?.PrimaryandAdditionalResellers || showChannelContentBasedOnRoleType?.PrimaryAndFinalTierResellers || showChannelContentBasedOnRoleType?.PrimaryResellersOnly || showChannelContentBasedOnRoleType?.PrimaryandAdditionalResellers,
                            styles: stackItemStyles,
                            components: [
                                //Reseller Grid
                                {
                                    type: 'Stack',
                                    data: {
                                        id: 'StackItemForAddChannelRoleBasedContentForReseller',
                                        visible: showChannelContentBasedOnRoleType?.FinalTierAndDistributors || showChannelContentBasedOnRoleType?.AdditionalAndDistributors || showChannelContentBasedOnRoleType?.PrimaryandAdditionalResellers || showChannelContentBasedOnRoleType?.PrimaryAndFinalTierResellers || showChannelContentBasedOnRoleType?.PrimaryResellersOnly || showChannelContentBasedOnRoleType?.PrimaryandAdditionalResellers,
                                        components: [
                                            {
                                                type: "StackItem",
                                                data: {
                                                    id: UIConfigIdentifierConstants.PrimaryResellerContainerId,
                                                    visible: showChannelContentBasedOnRoleType?.PrimaryAndFinalTierResellers || showChannelContentBasedOnRoleType?.PrimaryResellersOnly || showChannelContentBasedOnRoleType?.PrimaryandAdditionalResellers,
                                                    components: [
                                                        {
                                                            type: "DetailListEditableGrid",
                                                            data: {
                                                                id: "DetailsListGridForPrimaryReseller",
                                                                key: "DetailsListGridForPrimaryReseller",
                                                                items: getPrimaryResellerGridItems(),
                                                                columns: attachPrimaryResellerCallbacks(PrimaryResellerGridColumnConfig),
                                                                enableDefaultEditMode: false,
                                                                height: "110px",
                                                                styles: PageStyles.textFieldPanelWidth,
                                                                visible: primaryResellerGridItems?.length !== 0,
                                                                width: "100%"
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                type: "StackItem",
                                                data: {
                                                    id: "AdditionalResellersContainer",
                                                    label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalResellersInformation", UIControlsText.AdditionalResellersInformation),
                                                    visible: showChannelContentBasedOnRoleType?.AdditionalAndDistributors || showChannelContentBasedOnRoleType?.PrimaryandAdditionalResellers,
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                id: UIConfigIdentifierConstants.AdditionalResellersChoiceGroupId,
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalResellersInformation", UIControlsText.AdditionalResellerChoiceQuestion),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalResellersInformation", UIControlsText.AdditionalResellerChoiceQuestion),
                                                                contolType: UIControlType.LabelValueView,
                                                                value: additionalResellerGridItems?.length > 0 ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText),
                                                                styles: PageStyles.textFieldPanelWidth,
                                                            }
                                                        },
                                                        {
                                                            type: "DetailListEditableGrid",
                                                            data: {
                                                                id: "DetailsListCustomColumnsForAdditionalReseller",
                                                                items: additionalResellerGridItems,
                                                                columns: attachFinalAdditionalResellerGridCallbacks(AdditionalResellerViewGridColumnConfig),
                                                                position: 'relative',
                                                                visible: additionalResellerGridItems?.length !== 0,
                                                                height: (85 + (40 * additionalResellerGridItems?.length)) + "px",
                                                                styles: PageStyles.textFieldPanelWidth,
                                                                width: "100%"
                                                                //Disabled enabled based on above choice
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                type: "StackItem",
                                                data: {
                                                    id: "FinalTierResellerContainer",
                                                    horizontal: true,
                                                    disableshrink: true,
                                                    visible: showChannelContentBasedOnRoleType?.FinalTierAndDistributors || showChannelContentBasedOnRoleType?.PrimaryAndFinalTierResellers,
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                id: UIConfigIdentifierConstants.FinalTierResellersChoiceGroupId,
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "FinalTierResellerQuestion", UIControlsText.FinalTierResellerQuestion),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "FinalTierResellerQuestion", UIControlsText.FinalTierResellerQuestion),
                                                                contolType: UIControlType.LabelValueView,
                                                                value: finalTierResellerGridItems?.length > 0 ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText),
                                                                styles: PageStyles.textFieldPanelWidth,
                                                            }
                                                        },
                                                        {
                                                            type: "CustomViewModeComponent",
                                                            data: {
                                                                id: "LabelFinalTierResellerInformation",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "FinalTierResellerInformation", UIControlsText.FinalTierResellerInformation),
                                                                styles: PageStyles.textFieldStylesTwoColumn,
                                                                visible: finalTierResellerGridItems?.length !== 0,
                                                            }
                                                        },
                                                        {
                                                            type: "DetailListEditableGrid",
                                                            data: {
                                                                id: "DetailsListCustomColumnsForFinalTierReseller",
                                                                items: finalTierResellerGridItems,
                                                                columns: FinalTierResellerGridColumnConfig,
                                                                visible: finalTierResellerGridItems?.length !== 0,
                                                                height: (85 + (30 * finalTierResellerGridItems?.length)) + "px",
                                                                styles: PageStyles.textFieldPanelWidth,
                                                                width: "100%",
                                                                //Disabled enabled based on above choice
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        {
            type: "CustomTileCardComponent",
            data: {
                id: "ViewCardForDistributor",                
                visible: showChannelContentBasedOnRoleType?.MultipleDistributorsOnly,
                baseState: commonPPContextState,
                name: "Distributor Details",
                iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.UserEvent, "DistributorIcon", UIControlsText.DistributorIcon),
                components: [
                    {
                        type: 'Stack',
                        data: {
                            id: 'ChannelStackForDisti',
                            'data-nextgen-parent-group': "View - Distributor Details",
                            'data-nextgen-parentid': "View - Distributor Details",
                            horizontal: true,
                            components: [
                                //Distributor Grid
                                {
                                    type: 'StackItem',
                                    data: {
                                        id: 'StackItemForAddChannelRoleBasedContentForDistributor',
                                        align: "auto",
                                        components: [
                                            {
                                                type: "DetailListEditableGrid",
                                                data: {
                                                    id: "DetailsListCustomColumnsForDistributor",
                                                    key: "DetailsListCustomColumnsForDistributor",
                                                    items: distributorListGridItems,
                                                    columns: attachDistributorCallbacks(DistributorGridColumnConfig),
                                                    gridConfigOptions: { enableCellEdit: false },
                                                    styles: PageStyles.textFieldPanelWidth,
                                                    visible: distributorListGridItems?.length > 0,
                                                    height: (90 + (30 * distributorListGridItems?.length)) + "px",
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ];

    return ChannelJson;
}