/**
 * Enum for ErrorCode.
 * @readonly
 * @enum {number}
 */
export enum ErrorCode {

    /**
     * Invalid Case Number.
     */
    InvalidCase = 1,
}