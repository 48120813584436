/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { IStackTokens, Label, Panel, PanelType, Stack, TextField } from "office-ui-fabric-react";
import { IEntityLookupProps } from "../../../../components/shared/props/IEntityLookupProps";
import { useBoolean } from '@fluentui/react-hooks';
import { UIControlsText } from "../../../../content/UIControlsText";
import NextGenKendoGrid from "../../../../components/shared/grid/nextgenkendogrid";
import { IGridProps } from "../../../../components/shared/grid/IGridProps";
import { isEditableAccount, readGridConfig } from "../../../../components/shared/common/util";
import { GridType } from "../../../../models/GridType";
import { getter } from "@progress/kendo-data-query";
import { sortArray, getRuleValidationMessage, getEntityDataFromSession } from "../../../../components/shared/common/util";
import { IEntityLookupColumnConfig } from "../../../../models/IEntityLookupColumnConfig";
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { MdppAccessibilityPanelGrid, useToFixAriaRowIndexNan } from "../../../../hooks/accessiblity-hooks";

const stackTokens: Partial<IStackTokens> = { childrenGap: 20 };
const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

export const EntityLookup: React.FunctionComponent<IEntityLookupProps> = (props: IEntityLookupProps) => {
    const [showPanel, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [accountsListConfig, setAccountsListConfig] = useState<IGridProps>(readGridConfig(GridType.AccountsList));
    const idGetter = getter(accountsListConfig.kendoGridProps.dataItemKey);

    const onRowSelectionCallBack = (selectedState: any) => {
        const selectedItems: any = [];
        const accountGridDatawithSelection = accountsListConfig.kendoGridProps.data.map((item: any) => (
            {
                ...item, [accountsListConfig.kendoGridProps.selectedField]:
                    isEditableAccount(props.countryId, props.PageMode, props.statusCode, { ...item, accountId: item.key }, props.partnerType) ? true : selectedState[idGetter(item)],
            }));
        accountGridDatawithSelection.map((item: any) => {
            if (item.selected === true) {
                selectedItems.push(Object.assign(item));
            }
        });
        defaultStateUpdate(selectedItems);
        reOrderSelectedItems(accountGridDatawithSelection);
    };

    const reOrderSelectedItems = (gridSelectedItems: any) => {
        let selectedAccountListConfig = { ...accountsListConfig };
        const selectedValues = gridSelectedItems.filter((item: any) => item.selected);
        const nonSelectedValues = gridSelectedItems.filter((item: any) => !item.selected);
        const modifiedData = selectedValues.concat(nonSelectedValues);
        if (props?.itemSelectionUpdate) {
            props?.itemSelectionUpdate(selectedValues);
        }
        selectedAccountListConfig = {
            kendoGridProps: {
                ...selectedAccountListConfig.kendoGridProps,
                data: modifiedData,
            }
        };
        const listUpdated = { ...selectedAccountListConfig };
        setAccountsListConfig(listUpdated)
        return listUpdated;
    }

    //Function to call to set the selected values
    const setSelectedItems = () => {
        const keysSelected = props.selectedItems.map((item: any) => item.key);
        const columns = accountsListConfig.kendoGridProps.data;
        const sortedData = columns?.length ? columns.map((val: any) => {
            const selected = keysSelected.includes(val.key) ? true : undefined;
            const item = props.selectedItems.filter((item: any) => item.key === val.key)
            var iseditMode = val.editMode;
            if (item.length > 0) {
                iseditMode = item[0].editMode
            }
            return {
                ...val,
                selected,
                editMode: iseditMode
            };
        }) : [];
        let list = reOrderSelectedItems(sortedData);
        const updatedConfig = defaultStateUpdate(props.selectedItems);
        list.kendoGridProps.defaultMainGridSelectionstate = updatedConfig;
        return list;
    }

    const defaultStateUpdate = (selectedValues: any) => {
        const state: any = {};
        selectedValues.forEach((item: any) => { state[idGetter(item)] = true });
        return accountsListConfig.kendoGridProps.defaultMainGridSelectionstate = state;
    }

    const updateColumnConfig = () => {
        let selectedAccountListConfig = { ...accountsListConfig };
        //add selected property
        const modifiedData = props?.items.map((val: any) => {
            return {
                ...val,
                selected: val.selected || undefined,
            };
        });
        const sortedData: any = sortArray(modifiedData);
        selectedAccountListConfig.kendoGridProps.data = sortedData;
        //setMultiple columns -- displayOrder is the unique Key 
        let updatedResellerColumns: any = [];
        const columns: IEntityLookupColumnConfig[] = props.columnProps || [];
        selectedAccountListConfig.kendoGridProps.columns.map((item: any, i) => {
            const selectedIndex: any = columns.findIndex(x => x.displayOrder === item.displayOrder);
            const obj = {
                ...item,
                ...columns[selectedIndex],
            };
            updatedResellerColumns = [...updatedResellerColumns, obj];
            return updatedResellerColumns;
        });
        selectedAccountListConfig.kendoGridProps.columns = updatedResellerColumns;
        selectedAccountListConfig.kendoGridProps.totalRecords = props.items.length;
        setAccountsListConfig(selectedAccountListConfig)
    }

    //To enable interaction with the popup - Click on close button will dismiss the panel
    const onDismiss = React.useCallback(
        (ev?: React.SyntheticEvent<HTMLElement>) => {
            if (!ev) return;
            // eslint-disable-next-line
            const srcElement = ev.nativeEvent.srcElement as Element | null;
            if ((srcElement && srcElement.className.indexOf('ms-Button-flexContainer') !== -1)
                || (srcElement && srcElement.className.indexOf('ms-Button-icon') !== -1)) {
                dismissPanel();
            } else return;
        },
        [dismissPanel],
    );


    useEffect(() => {
        //Fixing Accessibility issue for PanelGrid checkbox
        var gridCheckbox = document.getElementsByClassName(".k-checkbox");
        for (let i = 0; i < gridCheckbox.length; i++) {
            gridCheckbox[i].setAttribute('aria-label', 'checkbox');
        }

        //Fixing Accessibility issue for PanelGrid FilterOption
        var gridFilter = document.querySelectorAll("#EntityLookupPanel .k-dropdown-wrap .k-input");
        for (let i = 0; i < gridFilter.length; i++) {
            gridFilter[i].setAttribute('role', 'option');
            gridFilter[i].setAttribute('title', 'option');
        }

        //Fixing Accessibility issue for Panel grid filterTextbox
        const filterTextFieldColumns = accountsListConfig.kendoGridProps.columns.filter(item => item.allowFilter && !item.categoryFilterCell && item.isVisible === true);
        var filterTextbox = document.querySelectorAll("#EntityLookupPanel .k-filtercell .k-filtercell-wrapper > .k-textbox");
        filterTextbox && filterTextbox.forEach(c => {
            for (let i = 0; i < filterTextbox.length; i++) {
                if (filterTextFieldColumns[i]) {
                    filterTextbox[i].setAttribute('aria-label', `${ filterTextFieldColumns[i].columnTitle }`);
                }
            }
        });
    });

    return (
        <Stack tokens={stackTokens}>
            <TextField
                placeholder={props.lookUpTextFieldProps.placeholder}
                title={props.lookUpTextFieldProps.title}
                required={props.lookUpTextFieldProps.required}
                disabled={props.lookUpTextFieldProps.disabled}
                onClick={openPanel}
                value={props.lookUpTextFieldProps.value}
                style={props.lookUpTextFieldProps.style}
            />
            <Panel
                id="EntityLookupPanel"
                isOpen={showPanel}
                type={PanelType.custom}
                customWidth='610px'
                onDismiss={onDismiss}
                closeButtonAriaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}
                onOpen={() => {
                    updateColumnConfig();
                    if (props.selectedItems && props.selectedItems.length > 0) {
                        const updatedItems = setSelectedItems();
                        setAccountsListConfig(updatedItems);
                    }
                }}
                headerText={props.headerText}>
                {props.subHeaderText &&
                    <Label><div>{props.subHeaderText}</div></Label>
                }
                {props.isCountrySelected ?
                    <MdppAccessibilityPanelGrid
                        {...{
                            kendoGridProps: { ...accountsListConfig.kendoGridProps },
                            onRowSelectionCallBack,
                            showFilterTextBox: true,
                            disableSelectAllCheckbox: props.disableSelectAllSelection,
                            dropDownFilterContent: Array.from(new Set(props.items.map((p: any) => p.data ? p.data : ''))),
                        }}
                    /> : <div>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "NoCountrySelectedPlaceHolderText", UIControlsText.NoCountrySelectedPlaceHolderText)}</div>
                }
            </Panel>
        </Stack >
    );
};