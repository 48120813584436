/**
 * Enum for AffiliationTypes.
 * @readonly
 * @enum {number}
 */
export enum AffiliationType {

    /**
     * AffiliateCompanies value.
     */
    AffiliateCompanies = 1,

    /**
     * ConsortiumBuying value.
     */
    ConsortiumBuying = 2,

    /**
     * Framework Agreement value.
     */
    FrameworkAgreement = 3,

    /**
     * AffiliateCompanies value.
     */
    Others = 4,
}