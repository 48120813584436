import { GridDetailRowProps } from "@progress/kendo-react-grid";
import { Stack } from "office-ui-fabric-react";
import { Fragment, useEffect } from "react";
import { PageStyles } from "../../pages/pricingprogram/common/content/Page.Styles";
import Highlighter from "react-highlight-words";
import { SKUGridProps } from "./props/ISkuCustomKendogridDetailProps";

const SkuComponent = (props: SKUGridProps) => {
    const { dataItem } = props;
    useEffect(() => {
    }, [props.filterValue])
    return (
        <Fragment>
            <Stack id="BGskuList">
                <tr style={PageStyles.bgSKUStyles.paddingLeft250}>
                    <td style={PageStyles.bgSKUStyles.header1}><span>SKU Number</span></td>
                    <td style={PageStyles.bgSKUStyles.header2}><span>SKU Name</span></td>
                </tr>
                {dataItem.productSku.map((skuData: any) => {
                    return <tr style={PageStyles.bgSKUStyles.paddingLeft250}>
                        {skuData.partNumber && <td style={PageStyles.bgSKUStyles.header1}><Highlighter
                            highlightClassName="mark"
                            searchWords={[props.filterValue]}
                            autoEscape={true}
                            textToHighlight={skuData.partNumber}
                        /></td>}
                        <td style={PageStyles.bgSKUStyles.productNames}><span>{skuData.name}</span></td>
                    </tr>
                })}
            </Stack>
        </Fragment>
    )
}

export default SkuComponent;