import { Icon, Label, Link, mergeStyles, mergeStyleSets, PrimaryButton, Stack } from "@fluentui/react"
import { getUserSession, navigateToPage, getRuleValidationMessage, getEntityDataFromSession } from "../../../../components/shared/common/util";
import { Ratings } from "../../../../components/shared/ratings";
import { UIControlsText } from "../../../../content/UIControlsText";
import { IUserVoice } from "../../../../models/IUserVoice";
import { nextGenService } from "../../../../services/NextGenService";
import { PageStyles } from "../../common/content/Page.Styles";
import { browserName } from "react-device-detect";
import { useCommonPPContext } from "../../common/context/common-pp-context";
import { ApplicationConstants } from "../../../../models/ApplicationConstants";
import { SessionStorageConsts } from "../../../../models/SessionStorageConsts";
import { PageMode } from "../../../../models/PageMode";
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { DealType } from "../../../../models/DealType";

export const SuccessPage: React.FunctionComponent<any> = (props: any) => {
    const { commonPPContextState } = useCommonPPContext();
    const iconClass = mergeStyles(PageStyles.completedSolidIconStyle);
    const classNames = mergeStyleSets({ green: [PageStyles.greenColor, iconClass] });
    const isMultiDeal = commonPPContextState?.opportunityDeals?.length > 1;
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const handleRatingChanges = async (rating?: number, comments?: string) => {
        try {
            var userDetails = JSON.parse(getUserSession() || "{}");
            const userVoice: IUserVoice = {};
            userVoice.browserName = browserName;
            userVoice.caseNumber = props?.caseNumber;
            userVoice.rating = rating;
            userVoice.comments = comments;
            userVoice.opportunityId = props?.opportunityId;
            userVoice.userDetails = { email: userDetails?.email, firstName: userDetails?.firstName, lastName: userDetails?.lastName };
            await Promise.resolve(nextGenService.saveUserVoice(userVoice));
        }
        catch (error: any) {
        }
    }

    const onLinkClick = () => {
        sessionStorage.setItem(SessionStorageConsts.pageMode, PageMode.View);
        if (isMultiDeal) {
            window.location.href = '/dashboard';
        }
        else {
            if (props?.dealType?.toLocaleLowerCase() === DealType.DealRegistration.toLocaleLowerCase() || props?.dealType?.toLocaleLowerCase() === DealType.SpecialPricing.toLocaleLowerCase()) {
                window.location.href = `/managecase/pricingprogram?${ApplicationConstants.OpportunityNumberParamName}=${props?.caseNumber}`;
            } else {
                window.location.href = `${props.navigateTo}?${ApplicationConstants.OpportunityNumberParamName}=${props?.caseNumber}`;
            }
        }
    }

    return (
        <>
            <Stack horizontalAlign="center">
                <Label>
                    <Icon aria-label="CompletedSolid" iconName="CompletedSolid" className={classNames.green} />
                </Label>
            </Stack>
            {props?.isSavingDraft &&
                <Stack horizontalAlign="center" styles={PageStyles.successDraftPadding} >
                    <Label>
                        <span><Link onClick={onLinkClick} style={PageStyles.successCaseColor}>{props?.caseNumber}</Link><span>&nbsp;<span dangerouslySetInnerHTML={{ __html: getRuleValidationMessage(sessionResourceData, MessageTypeText.PopupMessageText, "SuccessDraftCaseSubmissionText", UIControlsText.SuccessDraftCaseSubmissionText)}}></span></span></span>
                    </Label>
                </Stack>
            }
            {!props?.isSavingDraft &&
                <div>
                    <Stack horizontalAlign="center">
                        <Label>
                            <span><Link onClick={onLinkClick} style={PageStyles.successCaseColor}>{props?.caseNumber}</Link><span style={PageStyles.successBlackColor}>&nbsp;{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SuccessCaseSubmissionText", UIControlsText.SuccessCaseSubmissionText)}</span></span>
                        </Label>
                    </Stack>
                    <Stack horizontalAlign="center">
                        <Label>
                            <span style={PageStyles.ThankyouFontSize}>{getRuleValidationMessage(sessionResourceData, MessageTypeText.PopupMessageText, "ThankyouSubmissionText", UIControlsText.ThankyouSubmissionText)}</span>
                        </Label>
                    </Stack>
                    <Stack horizontalAlign="center" style={PageStyles.paddingTop20px}>
                        <Ratings {...
                            {
                                ratingLabelText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RatingLabelText", UIControlsText.RatingLabelText),
                                ratingCommentLabelText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RatingCommentLabelText", UIControlsText.RatingCommentLabelText),
                                minimalRatingToShowComments: 2,
                                ratingCommentPlaceHolderText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "RatingCommentPlaceHolderText", UIControlsText.RatingCommentPlaceHolderText),
                                handleRatingChanges: handleRatingChanges
                            }
                        } />
                    </Stack>
                </div>
            }
            <Stack horizontalAlign="center" style={PageStyles.paddingTop20px}>
                <PrimaryButton
                    title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CreateNewCaseText", UIControlsText.CreateNewCaseText)}
                    aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CreateNewCaseText", UIControlsText.CreateNewCaseText)}
                    text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CreateNewCaseText", UIControlsText.CreateNewCaseText)}
                    onClick={() => navigateToPage(props.navigateTo)}
                />
            </Stack>
        </>
    )
}
