import React from "react";
import { listProps } from "./unorderprops";

const UnorderList: React.FunctionComponent<listProps> = (props: listProps) => {
    const dataItems = props.dataList.map((item: any) => {
        return <li role="listitem">{item}</li>;
    });

    return (
        <React.Fragment>
            <div className="ms-LabelValue">
                {props.title}
            </div>

            <div className="listitems">
                <ul role="list">{dataItems}</ul>
            </div>
        </React.Fragment>
    );
};
export default UnorderList;
