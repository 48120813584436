import React, { useState, useEffect, useRef } from 'react';
import { UIControlsText, UIConfigIdentifierConstants } from '../../../../content/UIControlsText';
import { PageStyles, DealDetailsStyles, OpportunityDetailsStyles } from '../../common/content/Page.Styles';
import { Dropdown, IDropdownOption, MessageBarType, IChoiceGroupOption, Link, ActionButton, TextField } from '@fluentui/react';
import { LocalStorageConsts } from '../../../../models/LocalStorageConsts';
import { useCommonPPContext } from '../../common/context/common-pp-context';
import { DistributorGridItemsType, DistributorGridColumnConfig, PrimaryResellerGridColumnConfig, AdditionalResellerGridColumnConfig, FinalTierResellerGridColumnConfig, PrimaryResellerGridItemsType } from '../../../shared/ComponentTypes/DetailListEditableGridTypes';
import { ChoiceGroupStyles, DefaultPageStyles } from '../../../../content/styles/Page.styles';
import { useBoolean } from '@uifabric/react-hooks';
import { CaseActionTypes } from '../../common/reducer/CaseActionTypes';
import { getAction, isEditableRow, isValidEmail, isValidEmailWithSeperator, getTextFieldErrorMessage, getFieldConfigByState, getUserSession, getEmailFieldErrorMessage, getPostalCodeTextFieldErrorMessage, getRequestorsChannelFunctionalities, downloadFile, isMsStoreUser, getsavedContext, getDistinctUserRole, getLoggedinUserRoleDetails } from '../../../../components/shared/common/util';
import { stackItemStyles, getRegionalCountries, getTextFieldRequiredErrorMessage, channelConfig, itemAlignmentsStackTokens10, validateMSStoreEmailErrorMessage, smallAlignmentTokens, initialChannelDisplayOnEdit, selectCountryBasedOnRegion, replaceMEToUAEForDistributors } from '../PricingProgramFormUtils';
import { Guid } from '../../../../infrastructure/Guid';
import { IOpportunityAccount } from '../../../../models/IOpportunityAccount';
import { AccountAllType } from '../../../../models/AccountAllType';
import { IOpportunityChannelContact } from '../../../../models/IOpportunityChannelContact';
import { nextGenService } from '../../../../services/NextGenService';
import { JavaScriptExtensions } from '../../../../infrastructure/JavaScriptExtensions';
import { PartnerTypeLabel } from '../../../../models/PartnerTypeLabel';
import { ErrorMessages } from '../../../../content/ErrorMessages';
import { ApplicationConstants } from '../../../../models/ApplicationConstants';
import { ChannelSubType } from '../../../../models/ChannelSubType';
import { StatePropertiesText } from '../../common/state/StatePropertiesText';
import { IDealInfoProps } from '../props/IDealInfoProps';
import { IEntityLookupColumnConfig } from '../../../../models/IEntityLookupColumnConfig';
import { IAccount } from '../../../../models/IAccount';
import { PageMode } from '../../../../models/PageMode';
import { partnerTypeValues } from '../../../../services/StaticData';
import { GridType } from '../../../../models/GridType';
import { CssClassConstants } from '../../../../content/CssClassConstants';
import _ from 'lodash';
import { ICountry } from '../../../../models/ICountry';
import { FinalTierFields } from '../../../shared/ComponentTypes/Components';
import { GridSelectionType } from '../../../../components/shared/grid/SelectionType';
import { PartnerType } from '../../../../models/PartnerType';
import { IAttachment } from '../../../../models/IAttachment';
import { ActionType } from '../../../../models/ActionType';
import { IInitialAdditionalResellerValue } from '../../../../models/IInitialAdditionalResellerValue';
import { IInitialFinalTierResellerValue } from '../../../../models/IInitialFinalTierResellerValue';
import { IColumnConfig } from 'fluentui-editable-grid';
import { ChoiceGroup, IColumn } from 'office-ui-fabric-react';
import { UserSubRole } from '../../../../models/UserSubRole';
import { FieldMapMode } from '../../../../models/FieldMapMode';
import { countriesFilter, getRuleValidationMessage, getEntityDataFromSession } from '../../../../components/shared/common/util';
import { AttachmentDisplayMode } from '../../../../models/AttachmentDisplayMode';
import { ArrayExtensions } from '../../../../infrastructure/ArrayExtensions';
import { UIControlType } from '../../../../models/UIControlType';
import { OpportunityDealStatusCodes } from '../../../../models/OpportunityDealStatusCodes';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { IUserDetail } from '../../../../models/IUserDetail';
import { IUserRoleDetail } from '../../../../models/IUserRoleDetail';

const initialFinalTierResellerValue: IInitialFinalTierResellerValue = {
    id: '',
    ftrNo: 1,
    resellerName: '',
    contactName: '',
    contactEmail: '',
    address: '',
    city: '',
    stateProvince: '',
    zipPostalCode: '',
};

const initialAdditionalResellerValue: IInitialAdditionalResellerValue = {
    id: '',
    arNo: 1,
    resellerName: '',
    contactName: '',
    contactEmail: '',
    address: '',
    city: '',
    stateProvince: '',
    zipPostalCode: '',
    attachments: [],
    attachmentName: '',
};

export function Channel(props: IDealInfoProps, onResellerLoaded: any) {
    const { commonPPContextState, commonPPContextDispatch } = useCommonPPContext();
    const currentDealStatusCode = commonPPContextState.opportunityDeals.find(t => t.name === commonPPContextState.selectedOptyDealCaseNumber)?.statusCode === OpportunityDealStatusCodes.Approved ? OpportunityDealStatusCodes.Approved : 0;
    const loggedInUserRole = getDistinctUserRole(getLoggedinUserRoleDetails());
    const defaultMSStoreSellerName = JSON.parse(getUserSession()).firstName + " " + JSON.parse(getUserSession()).lastName;
    const regionalCountries = getRegionalCountries(props?.countries || [], commonPPContextState.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.geoCountries || []) || [];
    const [endCustomerCountry, setEndCustomerCountry] = useState<ICountry>();
    const [primaryResellerGridItems, setPrimaryResellerGridItems] = useState<PrimaryResellerGridItemsType[]>([]);
    const [additionalResellerGridItems, setAdditionalResellerGridItems] = useState<IInitialAdditionalResellerValue[]>([]);
    const [addtionalResellerEditGridItems, setAddtionalResellerEditGridItems] = useState<IInitialAdditionalResellerValue[]>([]);
    const [finalTierResellerGridItems, setFinalTierResellerGridItems] = useState<IInitialFinalTierResellerValue[]>([]);
    const [distributorListGridItems, setDistributorGridItemsState] = useState<any[]>([]);
    const [requesterAccount, setRequesterAccount] = useState<IOpportunityAccount | undefined>({});
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    //Show the content appropriate to the Channel Requestor Role and other combinations
    const [showChannelContentBasedOnRoleType, setShowChannelContentBasedOnRoleType] = useState(() => {
        return getChannelPersona();
    });
    const productGroup = commonPPContextState?.productGroup || "";
    const distributorItems = {
        current: replaceMEToUAEForDistributors(props?.countries || [])?.filter((x: any) => x.productTypeId === productGroup)
    }

    const [resellerRegionalCountries, setResellerRegionalCountries] = useState<ICountry[]>();
    const [initialDistributorCountry, setInitialDistributorCountry] = useState<string>('');
    const [resellers, setResellers] = useState<any>([]); // To update with resellers
    const [selectedResellerCountry, setSelectedResellerCountry] = useState<any>('');
    const [selectedDistributorCountry, setSelectedDistributorCountry] = useState<any>('');
    const [distributors, setDistributors] = useState<any>({});
    const [selectedResellerType, setSelectedResellerType] = useState<any>();
    const [initialResellerType, setInitialResellerType] = useState<any>();
    const [selectedResellers, setSelectedResellers] = useState<any[]>([]);
    const [selectedDistributors, setSelectedDistributors] = useState<any[]>([]);
    const [panelType, setPanelType] = useState<string>('');
    const [currentFinalTierIndex, setCurrentFinalTierIndex] = useState<number>(0);
    const [addFinalResellerButtonStatus, setAddFinalResellerButtonStatus] = useState<boolean>(true);
    const [addSaveNextButtonStatus, setAddSaveNextButtonStatus] = useState<boolean>(true);
    const [addAdditionalResellerButtonStatus, setAdditionalFinalResellerButtonStatus] = useState<boolean>(true);
    const [additonalReselleraddSaveNextButtonStatus, setAdditionalResllerAddSaveNextButtonStatus] = useState<boolean>(true);
    const [currentAdditionalResellerIndex, setCurrentAdditionalResellerIndex] = useState<number>(0);
    const [errorFields, setErrorFields] = useState<Array<string>>([]);
    const [additionalErrorFields, setAdditionalErrorFields] = useState<Array<string>>([]);
    const [showFinalResellerPanel, { setTrue: openFinalResellerPanel, setFalse: dismissFinalResellerPanel }] = useBoolean(false);
    const [showAddtionalResellerPanel, { setTrue: openAddtionalResellerPanel, setFalse: dismissAddtionalResellerPanel }] = useBoolean(false);
    const pivotTab = commonPPContextState.pivotTabs?.find(t => t.tabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText));
    const [isInitial, setIsInitial] = useState<boolean>(true);
    const [opportunityAccounts, setOpportunityAccounts] = useState<IOpportunityAccount[]>([]);
    const [opportunityChannelContacts, setOpportunityChannelContacts] = useState<IOpportunityChannelContact[]>(commonPPContextState && commonPPContextState?.opportunityChannelContacts || []);
    const [isResellerLoading, setIsResellerLoading] = useState<boolean>(false);
    const [isDistributorLoading, setIsDistributorLoading] = useState<boolean>(false);
    const resellerAccounts = useRef<IAccount[]>([]);
    const [msStoreSalesChannel, setMsStoreSalesChannel] = useState<number>(ChannelSubType.DSS);
    const msStoreAccount = opportunityAccounts && opportunityAccounts.find(a => a.partnerType === PartnerType.MsStore && a.channelSubType !== ChannelSubType.Requester);
    const [finalTierReseller, setFinalTierReseller] = useState<string>(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText));
    const [addtionalReseller, setAddtionalReseller] = useState<string>(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText));

    useEffect(() => {
        const distributorCountry = (endCustomerCountry?.region === ApplicationConstants.MiddleEastRegion) ? regionalCountries?.length > 0 && regionalCountries[0]?.countryId || '' : endCustomerCountry?.countryId;
        const updatedDistributors = distributorItems.current.filter((item: any) => item.countryId === distributorCountry);
        setDistributors({ current: updatedDistributors });
        setInitialDistributorCountry(distributorCountry || "");
        setSelectedDistributorCountry(distributorCountry);
        setSelectedResellerCountry(endCustomerCountry?.countryId);
        if (endCustomerCountry && endCustomerCountry.countryId) {
            setResellerRegionalCountries(countriesFilter(endCustomerCountry.countryId));
        }
    }, [endCustomerCountry && endCustomerCountry.countryId]);

    const onPanelClick = (value: string) => {
        setPanelType(value);
    };

    const setSelectedResellersOrDistributors = () => {
        if (commonPPContextState?.opportunityAccounts && commonPPContextState?.opportunityAccounts.length > 0) {
            //Block to update selected Resellers and Distributors in edit scenario 
            if (showChannelContentBasedOnRoleType?.MultipleDistributorsOnly || showChannelContentBasedOnRoleType?.FinalTierAndDistributors || showChannelContentBasedOnRoleType?.AdditionalAndDistributors) {
                const initialSelectedDistributors: any = commonPPContextState?.opportunityAccounts?.filter(reseller => reseller.partnerType === PartnerType.ADD && reseller.action !== ActionType.PanelAdd).map((x: any) => ({
                    accountId: x.accounts.length > 0 && x.accounts[0].accountId,
                    companyName: x.accountCompanyName,
                    countryId: x.countryId,
                    countryName: x.countryName,
                    partnerType: x.partnerType,
                    organizationId: x.accounts.length > 0 && x.accounts[0].organizationId,
                    action: x.action
                }))
                setSelectedDistributors(initialSelectedDistributors || [])
            }
            if (showChannelContentBasedOnRoleType?.PrimaryAndFinalTierResellers || showChannelContentBasedOnRoleType?.PrimaryResellersOnly || showChannelContentBasedOnRoleType?.PrimaryandAdditionalResellers) {
                const initialSelectedResellers = commonPPContextState?.opportunityAccounts?.filter(reseller => (reseller.partnerType === PartnerType.ADR || reseller.partnerType === PartnerType.DMP) && reseller.action !== ActionType.PanelAdd).map((x: any) => ({
                    accountId: x.accounts.length > 0 && x.accounts[0].accountId,
                    companyName: x.accountCompanyName,
                    countryId: x.countryId,
                    countryName: x.countryName,
                    partnerType: x.partnerType,
                    organizationId: x.accounts.length > 0 && x.accounts[0].organizationId,
                    action: x.action
                }))
                setSelectedResellers(initialSelectedResellers || [])
            }
        }
    }
    useEffect(() => {
        setSelectedResellersOrDistributors();
    }, [showChannelContentBasedOnRoleType])

    const onPanelFieldClick = (value: string) => {
        if (value === 'additionalReseller') {
            openAddtionalResellerPanel();
        }
        else if (value === 'finalTierReseller') {
            openFinalResellerPanel();
        }
    };

    const onResellerTypeChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
        if (item) {
            let filteredValues = resellerAccounts.current.filter((val: any) => val.partnerType === item.key);
            if (endCustomerCountry?.countryId) {
                filteredValues = filteredValues.filter((val: any) => val.applicablePriceListGeo === endCustomerCountry.applicablePriceListGeo);
            }
            const updatedResellers = {
                current: filteredValues
            };
            setSelectedResellerType(item.key);
            setResellers(updatedResellers);
        }
    };

    const onCountryChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
        const [list, setSelectedCountry, setSelectedList] = panelType === 'reseller' ?
            [resellerAccounts, setSelectedResellerCountry, setResellers] :
            [distributorItems, setSelectedDistributorCountry, setDistributors];
        if (item) {
            let filteredValues = list.current.filter((val: any) => val.countryId === item.key);
            if (panelType === 'reseller' && selectedResellerType) {
                filteredValues = filteredValues.filter((val: any) => val.partnerType === selectedResellerType)
            }
            const updatedList = {
                current: filteredValues
            };
            setSelectedCountry(item.key);
            setSelectedList(updatedList);
        }
    }

    /**
     * Get TextField ErrorMessage.
     * @method
     * @param {string>} value Text Box value.
     * @@returns {string} Error Message.
     */
    const getTextFieldErrorMessageMsStore = (errorMessage: string, targetPropertyId: string) => (value: string): string => {
        const message = !value ? errorMessage : '';
        setErrorsDataIntoContext(message, targetPropertyId)
        return message;
    };

    const onAdditionalNextButtonClicked = () => {
        setCurrentAdditionalResellerIndex(prevState => prevState + 1);
    };

    const onAdditionalBackButtonClicked = () => {
        setCurrentAdditionalResellerIndex(prevState => prevState - 1);
    }
    const onNextClicked = () => {
        setCurrentFinalTierIndex(prevState => prevState + 1);
    };

    const onBackClicked = () => {
        setCurrentFinalTierIndex(prevState => prevState - 1);
    };

    const onFinalTierFieldChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        const fieldId = event.currentTarget.id;
        setFinalTierResellerGridItems((prevState: any) => {
            return prevState.map(((item: any) => {
                return item.ftrNo === currentFinalTierIndex + 1 ? { ...item, [fieldId]: newValue } : item;
            }));
        });
        setAddFinalResellerButtonStatus(false);
        setAddSaveNextButtonStatus(false);
    };

    const onAdditionalResellerChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        const fieldId = event.currentTarget.id;
        setAdditionalResellerGridItems((prevState: any) => {
            return prevState.map(((item: any) => {
                return item.arNo === currentAdditionalResellerIndex + 1 ? { ...item, [fieldId]: newValue } : item;
            }));
        });
        setAdditionalFinalResellerButtonStatus(false);
        setAdditionalResllerAddSaveNextButtonStatus(false);
    };

    /**
    * Attachment change Callback function.
    * @param {Array<IAttachment | undefined>} attachmentList Updated list of attachments 
    */
    const handleAttachmentChanges = (attachmentList: Array<IAttachment | undefined>): void => {
        const filteredAttachments = attachmentList.filter(item => item?.action !== ActionType.Delete);
        setAdditionalResellerGridItems((prevState: any) => {
            return prevState.map(((item: any) => {
                return item.arNo === currentAdditionalResellerIndex + 1 ? { ...item, attachments: filteredAttachments, attachmentName: filteredAttachments && filteredAttachments[0]?.name } : item;
            }));
        });
        setAdditionalFinalResellerButtonStatus(false);
        setAdditionalResllerAddSaveNextButtonStatus(false);
    };

    const resellerFieldMapping = {
        resellerName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameText", UIControlsText.ResellerNameText),
        contactName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContactName", UIControlsText.ContactName),
        contactEmail: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContactEmailText", UIControlsText.ContactEmailText),
        address: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddressFacetText", UIControlsText.AddressFacetText),
        city: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CityText", UIControlsText.CityText),
        stateProvince: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "StateProvinceText", UIControlsText.StateProvinceText),
        zipPostalCode: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ZipPostalCode", UIControlsText.ZipPostalCode),
        attachments: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AttachmentsText", UIControlsText.AttachmentsText)
    };

    const fieldKeys = Object.keys(resellerFieldMapping);
    const onAddSaveNextClick = () => {
        const errorValues = finalTierErrorValues();
        const error = errorValues.filter(val => val);
        if (error.length === 0) {
            const index = finalTierResellerGridItems.findIndex(x => x.ftrNo === currentFinalTierIndex + 2);
            const newFormField = { ...initialFinalTierResellerValue, ftrNo: currentFinalTierIndex + 2 };
            const values = index === -1 ? [...finalTierResellerGridItems, newFormField] : finalTierResellerGridItems;
            setCurrentFinalTierIndex((prevState => prevState + 1));
            setFinalTierResellerGridItems(values);
        }
        setErrorFields(error);
    }

    const onAddAdditionalResellerSaveNextClick = () => {
        const errorValues: any = additionalResellerErrorValues();
        const error: any = errorValues.filter((val: any) => val).filter((itm: any) => itm.length > 0);

        if (error.length === 0) {
            const index = additionalResellerGridItems.findIndex(x => x.arNo === currentAdditionalResellerIndex + 2);
            const newFormField = { ...initialAdditionalResellerValue, arNo: currentAdditionalResellerIndex + 2 };
            const values = index === -1 ? [...additionalResellerGridItems, newFormField] : additionalResellerGridItems;
            setCurrentAdditionalResellerIndex((prevState => prevState + 1));
            setAdditionalResellerGridItems(values);
        }
        setAdditionalErrorFields(error);
    }

    const onAddFinalTierResellers = () => {
        const errorValues = finalTierErrorValues();
        const error = errorValues.filter(val => val);
        if (error.length === 0) {
            setCurrentFinalTierIndex(0);
            if (finalTierResellerGridItems) {
                const itemsWithoutId = finalTierResellerGridItems.map(item => {
                    const val = _.omit(item, ['id', 'ftrNo']);
                    return {
                        ...val,
                    };
                }).map((key: any) => Object.keys(key).some(i => {
                    const getbool = () => {
                        let val = false;
                        if (i === 'contactEmail') {
                            return val = key[i] == '' || validateContactErrorMessage(key[i]).length !== 0;
                        }
                        if (i === 'zipPostalCode') {
                            return val = key[i] === '' || getPostalCodeTextFieldErrorMessage(key[i]).length !== 0;
                        }
                        return key[i] === '';
                    }
                    const flag = getbool();
                    return flag;
                }));
                const emptyDataIndex = itemsWithoutId.findIndex(i => i);
                const updatedFinalResellerGridItems = emptyDataIndex === -1 ? finalTierResellerGridItems : finalTierResellerGridItems.filter((_, index) => index !== emptyDataIndex);
                //Remove existing accounts and add the updated accounts
                let updatedAccounts = opportunityAccounts.filter(acc => !updatedFinalResellerGridItems.map(f => f.id).includes(acc.id || ''));
                let accounts = updatedFinalResellerGridItems.map((acc) => {
                    return {
                        ...getNewOpportunityAccount(PartnerType.FinalTierReseller),
                        id: acc.id === '' ? Guid.newGuid() : acc.id,
                        action: acc.action || ActionType.Add,
                        ftrNo: acc.ftrNo,
                        address: acc.address, city: acc.city, stateProvince: acc.stateProvince, zipPostalCode: acc.zipPostalCode, contactEmail: acc.contactEmail,
                        opportunityAccountName: acc.resellerName, accountCompanyName: acc.contactName
                    } as IOpportunityAccount
                });
                commonPPContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: [...updatedAccounts, ...accounts] });
                clearErrorFromContext([PartnerType.FinalTierReseller], [...updatedAccounts, ...accounts], StatePropertiesText.ValidateFinalOrAdditionalReseller, getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidateFinalReseller", ErrorMessages.ValidateFinalReseller));
            }
        }
        setErrorFields(error);
    }

    const onAddAdditionalResellers = () => {
        const errorValues: any = additionalResellerErrorValues();
        const error: any = errorValues?.filter((val: any) => val).filter((itm: any) => itm?.length > 0);
        if (error.length === 0) {
            setCurrentAdditionalResellerIndex(0);
            if (additionalResellerGridItems) {
                const itemsWithoutId = additionalResellerGridItems.map(item => {
                    const val = _.omit(item, ['id', 'arNo']);
                    return {
                        ...val,
                    };
                }).map((key: any) => Object.keys(key).some(i => {
                    const getbool = () => {
                        let val = false;
                        if (i === 'contactEmail') {
                            return val = key[i] == '' || validateContactErrorMessage(key[i]).length !== 0;
                        }
                        if (i === 'zipPostalCode') {
                            return val = key[i] === '' || getPostalCodeTextFieldErrorMessage(key[i]).length !== 0;
                        }
                        return key[i] === '';
                    }
                    const flag = getbool();
                    return flag;
                }));
                const emptyDataIndex = itemsWithoutId.findIndex(i => i);
                const updatedAdditionalResellerGridItems = emptyDataIndex === -1 ? additionalResellerGridItems : additionalResellerGridItems.filter((_, index) => index !== emptyDataIndex);
                //Remove existing accounts and add the updated accounts
                const additionalResellers = opportunityAccounts.filter(acc => acc.partnerType === PartnerType.AdditionalTierReseller);
                let attachments = (commonPPContextState.attachments || []).filter(att => !additionalResellers.map(acc => acc.id).includes(att?.relatedEntityId));
                let updatedAccounts = opportunityAccounts.filter(acc => !updatedAdditionalResellerGridItems.map(f => f.id).includes(acc.id || ''));
                let accounts = updatedAdditionalResellerGridItems.map((acc) => {
                    let account = {
                        ...getNewOpportunityAccount(PartnerType.AdditionalTierReseller),
                        id: acc.id === '' ? Guid.newGuid() : acc.id,
                        action: acc.action || ActionType.Add,
                        arNo: acc.arNo,
                        address: acc.address, city: acc.city, stateProvince: acc.stateProvince, zipPostalCode: acc.zipPostalCode, contactEmail: acc.contactEmail,
                        opportunityAccountName: acc.resellerName, accountCompanyName: acc.contactName
                    } as IOpportunityAccount
                    if (acc.attachments) {
                        let att = (acc.attachments as IAttachment[]);
                        const newlist = att.map((x) => { return { ...x, relatedEntityId: account.id } })

                        attachments = ArrayExtensions.mergeArrays(attachments, newlist);
                    }
                    return account;
                });
                commonPPContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: [...updatedAccounts, ...accounts] });
                commonPPContextDispatch({ type: CaseActionTypes.attachmentChanged, attachments: attachments });
                clearErrorFromContext([PartnerType.AdditionalTierReseller], [...updatedAccounts, ...accounts], StatePropertiesText.ValidateFinalOrAdditionalReseller, getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidateAdditionalReseller", ErrorMessages.ValidateAdditionalReseller));
            }
        }
        setAdditionalErrorFields(error);
    }

    const clearErrorFromContext = (partnerTypes?: PartnerType[], optyAccounts?: IOpportunityAccount[], targetPropertyId?: string, errorMessage?: string) => {
        const pivotTabsData = commonPPContextState.pivotTabs;
        let pivotTabChannel = pivotTabsData?.find(t => t.tabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)) || {};
        const optyAccount = optyAccounts?.find(t => (t.channelSubType !== ChannelSubType.Requester) && (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(partnerTypes?.find(x => t.partnerType === x))));
        if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(optyAccount)) {
            pivotTabChannel?.errors?.delete(targetPropertyId || "");
        }
        else {
            pivotTabChannel?.errors?.set(targetPropertyId || "", errorMessage || "");
        }

        commonPPContextDispatch({ type: CaseActionTypes.pivotTabData, pivotTabDetails: pivotTabsData });
    }

    const finalTierErrorValues = () => {
        const errorValues = fieldKeys.filter(x => x !== 'attachments').map((item: any) => {
            const fallbackValue = '';
            const fieldValue = finalTierResellerGridItems.length > 0 ? finalTierResellerGridItems[currentFinalTierIndex][item as keyof IInitialFinalTierResellerValue] : fallbackValue;
            let result = finalTierResellerGridItems.length > 0 && !finalTierResellerGridItems[currentFinalTierIndex][item as keyof IInitialFinalTierResellerValue] ? resellerFieldMapping[item as keyof typeof resellerFieldMapping] : fallbackValue;
            let fieldVal: any = typeof (fieldValue) !== 'undefined' && fieldValue?.toString();
            if (item === 'contactEmail') {
                result = fieldValue === '' ? resellerFieldMapping[item as keyof typeof resellerFieldMapping] : getEmailFieldErrorMessage(fieldVal);
            }
            if (item === 'zipPostalCode') {
                result = fieldValue === '' ? resellerFieldMapping[item as keyof typeof resellerFieldMapping] : getPostalCodeTextFieldErrorMessage(fieldVal);
            }
            return result;
        }).filter(val => val);
        return errorValues;
    }

    const additionalResellerErrorValues = () => {
        const errorValues = fieldKeys.map((item: any) => {
            const fallbackValue = item === 'attachments' ? [] : '';
            let result = (additionalResellerGridItems.length > 0 && additionalResellerGridItems[currentAdditionalResellerIndex][item as keyof typeof resellerFieldMapping] || fallbackValue).length > 0 ? fallbackValue : resellerFieldMapping[item as keyof typeof resellerFieldMapping];
            if (item === 'contactEmail') {
                const fieldValue = additionalResellerGridItems.length > 0 ? additionalResellerGridItems[currentAdditionalResellerIndex].contactEmail : '';
                result = fieldValue === '' ? resellerFieldMapping[item as keyof typeof resellerFieldMapping] : getEmailFieldErrorMessage(fieldValue.toString());
            }
            if (item === 'zipPostalCode') {
                const fieldValue = additionalResellerGridItems.length > 0 ? additionalResellerGridItems[currentAdditionalResellerIndex].zipPostalCode : '';
                result = fieldValue === '' ? resellerFieldMapping[item as keyof typeof resellerFieldMapping] : getPostalCodeTextFieldErrorMessage(fieldValue.toString());
            }
            return result;
        }).filter(val => val).filter(itm => itm.length > 0);
        return errorValues || [];
    }

    const panelContent = (showAttachment: boolean, values: any) => values.map((item: any) => {
        let contextData: any = [];
        let currentIndex = 0;
        if (showAddtionalResellerPanel) {
            contextData = additionalResellerGridItems;
            currentIndex = currentAdditionalResellerIndex;
        }
        if (showFinalResellerPanel) {
            contextData = finalTierResellerGridItems;
            currentIndex = currentFinalTierIndex;
        }
        let isDisabled = false;
        if (contextData && commonPPContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()) {
            isDisabled = contextData[currentIndex] ? item.action === ActionType.Created : false;
        }
        return (
            <div>
                <FinalTierFields
                    ResellerNameTextField={{
                        id: 'resellerName',
                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameText", UIControlsText.ResellerNameText),
                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameText", UIControlsText.ResellerNameText),
                        styles: PageStyles.textFieldPanelWidth,
                        placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "ResellerNamePlaceHolder", UIControlsText.ResellerNamePlaceHolder),
                        required: true,
                        validateOnLoad: false,
                        validateOnFocusOut: true,
                        maxLength: 100,
                        value: item.resellerName,
                        onChange: showAttachment ? onAdditionalResellerChange : onFinalTierFieldChange,
                        onGetErrorMessage: getTextFieldErrorMessage,
                        disabled: isDisabled
                    }}
                    ContactName={{
                        id: 'contactName',
                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContactName", UIControlsText.ContactName),
                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContactName", UIControlsText.ContactName),
                        styles: PageStyles.textFieldStylesOneColumn,
                        placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "ContactNamePlaceHolder", UIControlsText.ContactNamePlaceHolder),
                        required: true,
                        validateOnLoad: false,
                        validateOnFocusOut: true,
                        maxLength: 100,
                        value: item.contactName,
                        onChange: showAttachment ? onAdditionalResellerChange : onFinalTierFieldChange,
                        onGetErrorMessage: getTextFieldErrorMessage,
                        disabled: isDisabled
                    }}
                    ContactEmail={{
                        id: 'contactEmail',
                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContactEmailText", UIControlsText.ContactEmailText),
                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContactEmailText", UIControlsText.ContactEmailText),
                        styles: PageStyles.textFieldStylesOneColumn,
                        placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "ContactEmailPlaceHolder", UIControlsText.ContactEmailPlaceHolder),
                        required: true,
                        validateOnLoad: false,
                        validateOnFocusOut: true,
                        maxLength: 100,
                        value: item.contactEmail,
                        onChange: showAttachment ? onAdditionalResellerChange : onFinalTierFieldChange,
                        onGetErrorMessage: getTextFieldErrorMessage,
                        disabled: isDisabled
                    }}
                    Address={{
                        id: 'address',
                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddressFacetText", UIControlsText.AddressFacetText),
                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddressFacetText", UIControlsText.AddressFacetText),
                        styles: PageStyles.textFieldStylesOneColumn,
                        placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "AddressFacetText", UIControlsText.AddressPlaceHolder),
                        required: true,
                        validateOnLoad: false,
                        validateOnFocusOut: true,
                        maxLength: 100,
                        value: item.address,
                        onChange: showAttachment ? onAdditionalResellerChange : onFinalTierFieldChange,
                        onGetErrorMessage: getTextFieldErrorMessage,
                        disabled: isDisabled
                    }}
                    City={{
                        id: 'city',
                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CityText", UIControlsText.CityText),
                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CityText", UIControlsText.CityText),
                        styles: PageStyles.textFieldStylesOneColumn,
                        placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "CityTextFieldPlaceHolder", UIControlsText.CityTextFieldPlaceHolder),
                        required: true,
                        validateOnLoad: false,
                        validateOnFocusOut: true,
                        maxLength: 100,
                        value: item.city,
                        onChange: showAttachment ? onAdditionalResellerChange : onFinalTierFieldChange,
                        onGetErrorMessage: getTextFieldErrorMessage,
                        disabled: isDisabled
                    }}
                    StateOrProvince={{
                        id: 'stateProvince',
                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "StateProvinceText", UIControlsText.StateProvinceText),
                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "StateProvinceText", UIControlsText.StateProvinceText),
                        styles: PageStyles.textFieldStylesOneColumn,
                        placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "StateOrProvincePlaceHolder", UIControlsText.StateOrProvincePlaceHolder),
                        required: true,
                        validateOnLoad: false,
                        validateOnFocusOut: true,
                        maxLength: 100,
                        value: item.stateProvince,
                        onChange: showAttachment ? onAdditionalResellerChange : onFinalTierFieldChange,
                        onGetErrorMessage: getTextFieldErrorMessage,
                        disabled: isDisabled
                    }}
                    PostalCode={{
                        id: 'zipPostalCode',
                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ZipPostalCode", UIControlsText.ZipPostalCode),
                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ZipPostalCode", UIControlsText.ZipPostalCode),
                        styles: PageStyles.textFieldStylesOneColumn,
                        placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "ZipOrPostalCodePlaceHolder", UIControlsText.ZipOrPostalCodePlaceHolder),
                        required: true,
                        validateOnLoad: false,
                        validateOnFocusOut: true,
                        maxLength: 100,
                        value: item.zipPostalCode,
                        onChange: showAttachment ? onAdditionalResellerChange : onFinalTierFieldChange,
                        onGetErrorMessage: getTextFieldErrorMessage,
                        disabled: isDisabled
                    }}
                    showAttachment={showAttachment}
                    attachment={{
                        labelText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AttachmentsText", UIControlsText.AttachmentsText),
                        source: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText),
                        required: true,
                        attachmentList: item.attachments,
                        handleAttachmentChanges: handleAttachmentChanges,
                        attachmentDisplayMode: AttachmentDisplayMode.Grid,
                        allowedFileExtensions: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AllowedFileExtensionsForDRAndSP", UIControlsText.AllowedFileExtensionsForDRAndSP).split(','),
                        opportunityDealStatusCode: commonPPContextState.opportunityDeals[0]?.statusCode,
                        pageMode: commonPPContextState.pageMode,
                        disabled: isDisabled
                    }}
                />
            </div>)
    });

    const resellerColumnDefinitions: IEntityLookupColumnConfig[] = [
        {
            apiField: 'name',
            key: 'name',
            columnTitle: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameText", UIControlsText.ResellerNameText),
            displayOrder: 2
        }, {
            apiField: 'organizationId',
            key: 'organizationId',
            columnTitle: 'MPN ID',
            displayOrder: 3,
        }];


    const distributorColumnDefinitions: IEntityLookupColumnConfig[] = [
        {
            apiField: 'name',
            key: 'name',
            columnTitle: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DistributorNameText", UIControlsText.DistributorNameText),
            displayOrder: 2
        }];
    const resellerTypes = [PartnerType.ADR, PartnerType.DMP];

    const resellerTableConfigurations = (
        <div className='configContainer'>
            <Dropdown
                label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerTypeText", UIControlsText.ResellerTypeText)}
                ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerTypeText", UIControlsText.ResellerTypeText)}
                options={_.uniqBy(resellerTypes.map((item: any) => ({ key: item, text: partnerTypeValues[item] } as IDropdownOption)), 'key')}
                placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerTypeText", UIControlsText.ResellerTypeText)}
                styles={PageStyles.PaneldropdownStyles}
                onChange={onResellerTypeChange}
                selectedKey={selectedResellerType}
                disabled={false}
                required
            />
            <Dropdown
                label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerCountryText", UIControlsText.ResellerCountryText)}
                ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerCountryText", UIControlsText.ResellerCountryText)}
                options={_.uniqBy(resellerRegionalCountries?.map((item: any) => ({ key: item.countryId, text: item.countryName } as IDropdownOption)), 'key')}
                placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "ResellerCountryPlaceholderText", UIControlsText.ResellerCountryPlaceholderText)}
                styles={PageStyles.PaneldropdownStyles}
                onChange={onCountryChange}
                defaultSelectedKey={endCustomerCountry?.countryId}
                selectedKey={selectedResellerCountry}
                disabled={!endCustomerCountry?.isEUEFTA}
                required
            />
        </div>);

    const distributorTableConfigurations = (
        <div className='configContainer'>
            <Dropdown
                label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DistributorCountryText", UIControlsText.DistributorCountryText)}
                ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DistributorCountryText", UIControlsText.DistributorCountryText)}
                options={_.uniqBy(regionalCountries?.map((item: any) => ({ key: item.countryId, text: item.countryName } as IDropdownOption)), 'key')}
                placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DistributorCountryPlaceholderText", UIControlsText.DistributorCountryPlaceholderText)}
                styles={PageStyles.distributorDropdownStyles}
                onChange={onCountryChange}
                defaultSelectedKey={selectedDistributorCountry}
                selectedKey={selectedDistributorCountry}
                disabled={!endCustomerCountry?.isEUEFTA}
                required
            />
        </div>);

    const onResellerOrDistributorChange = (selectedItems: any[]): void => {
        const [list, setSelectedList, countrySelection] = panelType === 'reseller' ? [resellerAccounts, setSelectedResellers, selectedResellerCountry] : [distributorItems, setSelectedDistributors, selectedDistributorCountry];
        setIsInitial(false);
        if (selectedItems) {
            const updatedValues = list.current.map((item: any) => {
                return {
                    ...item,
                    key: `${item.accountId}-${item.partnerType}`,
                };
            });
            const modifiedList = {
                current: updatedValues,
            };
            let updatedList: IAccount[] = [];
            if (selectedItems) {
                selectedItems.forEach(x => {
                    let value: any;
                    value = modifiedList.current?.find((y: any) => y.key === x.key);
                    if (value) {
                        value.action = JavaScriptExtensions.isNullOrUndfinedOrEmpty(x.action) ? ActionType.PanelAdd : x.action;
                        updatedList.push(value);
                    }
                });
            }
            setSelectedList(updatedList);
        }
    }

    const onClearAll = () => {
        if (panelType === 'reseller') {
            setSelectedResellersOrDistributors();
            const filteredResllers: any = resellerAccounts.current.filter((item: any) => item.applicablePriceListGeo === endCustomerCountry?.applicablePriceListGeo)
            setResellers({ current: filteredResllers })
            setSelectedResellerType(initialResellerType)
            setSelectedResellerCountry(endCustomerCountry?.countryId)
        } else {
            const updatedDistributors = distributorItems.current.filter((item: any) => item.countryId === initialDistributorCountry);
            setSelectedResellersOrDistributors();
            setDistributors({ current: updatedDistributors })
            setSelectedDistributorCountry(initialDistributorCountry)
        }
    };
    const onAddButtonClicked = (isResellerAdd?: boolean) => {
        onPrimaryResellerAdd([...selectedResellers, ...selectedDistributors], isResellerAdd);
    }

    const onCancelClick = () => {
        setCurrentFinalTierIndex(0);
        const currentFinalTierItems = commonPPContextState.pageMode === PageMode.Edit ? finalTierResellerGridItems.filter(x => x.action === ActionType.Created) : [initialFinalTierResellerValue]
        setFinalTierResellerGridItems(currentFinalTierItems);
        if (commonPPContextState.pageMode === PageMode.Create) {
            const updatedAccounts = commonPPContextState.opportunityAccounts?.filter(acc => acc.partnerType !== PartnerType.FinalTierReseller);
            commonPPContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: updatedAccounts });
        }
        setErrorFields([]);
    }

    const onCancelButtonClicked = () => {
        setCurrentAdditionalResellerIndex(0);
        const currentAdditionalItems = commonPPContextState.pageMode === PageMode.Edit ? additionalResellerGridItems.filter(x => x.action === ActionType.Created) : [initialAdditionalResellerValue];
        setAdditionalResellerGridItems(currentAdditionalItems);
        if (commonPPContextState.pageMode === PageMode.Create) {
            const updatedAccounts = commonPPContextState.opportunityAccounts?.filter(acc => acc.partnerType !== PartnerType.AdditionalTierReseller);
            commonPPContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: updatedAccounts });
        }
        setErrorFields([]);
    }

    function getChannelPersona() {
        let initialChannelDisplay;
        if (isMsStoreUser()) {
            initialChannelDisplay = getRequestorsChannelFunctionalities(UserSubRole.MSStoreUser, endCustomerCountry?.countryName || "");
        }
        else if (commonPPContextState.pageMode === PageMode.Create) {
            initialChannelDisplay = getRequestorsChannelFunctionalities(commonPPContextState.partnerDetails?.userRole || "", endCustomerCountry?.countryName || "");
        }
        else {
            let requestor = commonPPContextState?.opportunityAccounts?.find(r => r.channelSubType === ChannelSubType.Requester);
            initialChannelDisplay = getRequestorsChannelFunctionalities(PartnerType[requestor?.partnerType || 0] || "", endCustomerCountry?.countryName || "");
            initialChannelDisplayOnEdit(commonPPContextState, initialChannelDisplay);
        }
        return initialChannelDisplay;
    }

    //Create a new IOpportunityAccount
    function getNewOpportunityAccount(type?: PartnerType, partnerAccount?: IAccount): IOpportunityAccount {
        return { id: Guid.newGuid(), accounts: partnerAccount ? [{ ...partnerAccount, action: ActionType.Add }] : undefined, partnerType: type, isAllSelected: AccountAllType.None, isComplete: true, countryId: selectCountryBasedOnRegion(endCustomerCountry?.countryId || "", partnerAccount?.countryId || "", props?.countries || [], type), action: ActionType.Add } as IOpportunityAccount;
    }

    //Create a new IOpportunityChannelContact
    function getNewOpportunityChannelContact(opportunityAccountId?: string, email?: string): IOpportunityChannelContact {
        return { id: Guid.newGuid(), opportunityAccountId: opportunityAccountId, channelContactEmails: email?.replace(',', ';'), source: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText), action: ActionType.Add, isComplete: email ? isValidEmailWithSeperator(email, ';') : false } as IOpportunityChannelContact;
    }

    async function getResellers(countries: ICountry[]) {
        setIsResellerLoading(true);

        //Remove resellers when the countries are removed.
        resellerAccounts.current = resellerAccounts.current.filter(r => r.productTypeId === productGroup).filter(r => countries.map(c => c.countryId).indexOf(r.countryId as string) >= 0);

        //Newly selected countries
        let newCountries = countries.filter(c => resellerAccounts.current.filter(r => r.productTypeId === productGroup).map(r => r.countryId).indexOf(c.countryId) === -1);
        let count = newCountries.length;

        if (newCountries.length === 0) {
            setIsResellerLoading(false);
        }

        //Get resellers for newly selected countries
        newCountries.forEach(async c => {
            let response = await nextGenService.getAllActiveResellersByProductAndCountry(productGroup, c.countryId);
            count--;
            if (count === 0) {
                setIsResellerLoading(false);
            }

            if (response.status === 200) {
                resellerAccounts.current = resellerAccounts.current.concat(response.data);
                resellerAccounts.current = _.uniqBy(resellerAccounts.current, v => [v.accountId, v.partnerType].join());
                setResellers({ current: resellerAccounts.current });
                if (count === 0) {
                    onResellerLoaded && onResellerLoaded(resellerAccounts.current);
                }
            }
        });
    }

    useEffect(() => {
        const getDistributors = () => {
            //Remove Distributors when the countries are removed.
            distributorItems.current = distributorItems.current.filter((r: any) => r.productTypeId === productGroup).filter((r: any) => r.applicablePriceListGeo === endCustomerCountry?.applicablePriceListGeo);
            return distributorItems.current;
        }
        setDistributors({ current: getDistributors() })
        const selectedGeoCountries = (commonPPContextState.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.geoCountries) || [];

        if (resellerRegionalCountries && resellerRegionalCountries.length > 0 && JavaScriptExtensions.isDefined(productGroup) && selectedGeoCountries.length > 0 
        && (typeof (showChannelContentBasedOnRoleType) != "undefined" && (showChannelContentBasedOnRoleType.PrimaryResellersOnly || showChannelContentBasedOnRoleType.PrimaryAndFinalTierResellers || showChannelContentBasedOnRoleType.PrimaryandAdditionalResellers))) {
            getResellers(resellerRegionalCountries || []);
        }
        if (JavaScriptExtensions.isDefined(productGroup) && selectedGeoCountries.length > 0 && (typeof (showChannelContentBasedOnRoleType) != "undefined" && !showChannelContentBasedOnRoleType.MSStore)) {
            getDistributors();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endCustomerCountry?.countryId, commonPPContextState?.productGroup, showChannelContentBasedOnRoleType]);

    useEffect(() => {
        if (commonPPContextState.opportunityAccounts) {
            //Set IsComplete Flag on initialization
            let accounts = [...commonPPContextState.opportunityAccounts];
            setRequesterAccount(commonPPContextState.opportunityAccounts?.find(t => t.channelSubType === ChannelSubType.Requester));
            if (commonPPContextState.opportunityAccounts.length > 0 && commonPPContextState.opportunityAccounts.filter(acc => !JavaScriptExtensions.isDefined(acc.isComplete)).length > 0) {
                accounts.filter(acc => acc.partnerType !== PartnerType.MsStore && !JavaScriptExtensions.isDefined(acc.isComplete)).map((acc) => acc.isComplete = true);
                accounts.filter(acc => acc.partnerType === PartnerType.MsStore && !JavaScriptExtensions.isDefined(acc.isComplete)).map((ms) => updateMSStoreCompleteFlag(ms));
                commonPPContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: accounts });
            }
            setOpportunityAccounts(accounts);

            if (msStoreAccount?.channelSubType) {
                setMsStoreSalesChannel(msStoreAccount.channelSubType);
            }

            let resellerItems = commonPPContextState.opportunityAccounts.filter(reseller => reseller.partnerType === PartnerType.ADR || reseller.partnerType === PartnerType.DMP)
                .map((acc) => {
                    const contact = commonPPContextState.opportunityChannelContacts?.find((c) => c.opportunityAccountId === acc.id);
                    return {
                        id: acc.id,
                        resellerCountry: acc.accounts && acc.accounts[0].countryName,
                        resellerType: acc.partnerType ? PartnerTypeLabel.get(acc.partnerType) : "",
                        resellerNames: acc.accounts && acc.accounts[0].companyName,
                        resellerEmailContacts: acc?.channelSubType === ChannelSubType.Requester ? acc.contactEmail : contact?.channelContactEmails,
                        deleteAction: acc.id,
                        action: acc.accounts && acc.accounts[0]?.action
                    } as PrimaryResellerGridItemsType
                });
            setPrimaryResellerGridItems(resellerItems);

            let finalResellerItems = commonPPContextState.opportunityAccounts.filter(reseller => reseller.partnerType === PartnerType.FinalTierReseller)
                .map((acc: any, index: number) => {
                    return {
                        id: acc.id,
                        ftrNo: acc.ftNo || index + 1,
                        resellerName: acc.opportunityAccountName,
                        contactName: acc.accountCompanyName,
                        contactEmail: acc.contactEmail,
                        address: acc.address,
                        city: acc.city,
                        stateProvince: acc.stateProvince,
                        zipPostalCode: acc.zipPostalCode,
                        action: acc.action
                    } as IInitialFinalTierResellerValue
                });
            setFinalTierResellerGridItems(finalResellerItems.length > 0 ? finalResellerItems : [initialFinalTierResellerValue]);

            finalResellerItems.length > 0 ? setFinalTierReseller(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText)) : setFinalTierReseller(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText))

            let additionalResellerItems = commonPPContextState.opportunityAccounts.filter(reseller => reseller.partnerType === PartnerType.AdditionalTierReseller)
                .map((acc: any, index: number) => {
                    return {
                        id: acc.id,
                        arNo: acc.arNo || index + 1,
                        resellerName: acc.opportunityAccountName,
                        contactName: acc.accountCompanyName,
                        contactEmail: acc.contactEmail,
                        address: acc.address,
                        city: acc.city,
                        stateProvince: acc.stateProvince,
                        zipPostalCode: acc.zipPostalCode,
                        attachments: commonPPContextState.attachments?.filter(a => a?.relatedEntityId === acc.id),
                        attachmentName: commonPPContextState.attachments?.filter(a => a?.relatedEntityId === acc.id)?.map(x => x?.name).join(', '),
                        action: acc.action
                    } as IInitialAdditionalResellerValue
                });
            setAddtionalResellerEditGridItems(additionalResellerItems.length > 0 ? additionalResellerItems : [initialAdditionalResellerValue]);
            setAdditionalResellerGridItems(additionalResellerItems.length > 0 ? additionalResellerItems : [initialAdditionalResellerValue]);

            additionalResellerItems.length > 0 ? setAddtionalReseller(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText)) : setAddtionalReseller(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText))

            let distributorItems = commonPPContextState.opportunityAccounts.filter(reseller => reseller.partnerType === PartnerType.ADD)
                .map((acc) => {
                    const contact = commonPPContextState.opportunityChannelContacts?.find((c) => c.opportunityAccountId === acc.id);
                    const distiAccounts = acc?.accounts && acc?.accounts[0];
                    return {
                        id: acc.id,
                        distributorCountry: distiAccounts?.countryName,
                        distributorOwner: acc?.channelSubType === ChannelSubType.CoOwner ? true : false,
                        distributorNames: distiAccounts?.companyName,
                        distributorEmailContacts: acc?.channelSubType === ChannelSubType.Requester ? acc.contactEmail : contact?.channelContactEmails,
                        action: acc.action
                    } as DistributorGridItemsType
                });
            setDistributorGridItemsState(distributorItems);
        }
    }, [commonPPContextState.opportunityAccounts]);

    useEffect(() => {
        if (commonPPContextState.opportunityChannelContacts) {
            //Set IsComplete Flag on initialization
            let contacts = [...commonPPContextState.opportunityChannelContacts]
            contacts.filter(c => !JavaScriptExtensions.isDefined(c.isComplete)).map(c => commonPPContextState.opportunityAccounts?.find(a => a.id === c.opportunityAccountId)?.partnerType === PartnerType.MsStore
                ? c.isComplete = getMSStoreEmailErrorMessage(c.channelContactEmails || '') === ''
                : c.isComplete = validateContactErrorMessage(c.channelContactEmails || '', StatePropertiesText.ValidateEmailText + c.opportunityAccountId) === '');

            setOpportunityChannelContacts(contacts);
        }
    }, [commonPPContextState.opportunityChannelContacts]);


    //Remove the initial template item from Grid display
    const getGridDisplayItems = (items: any[]) => {
        if (items && items.length > 0) {
            return items.filter(x => x.id !== ''); //Remove the initial template used by PanelFieldContainer component from grid display
        }
    }

    // Attach Primary Reseller grid callbacks
    const attachFinalAdditionalResellerGridCallbacks = (columnConfig: IColumnConfig[], type: number): IColumnConfig[] => {
        columnConfig.filter((item) => item.key == 'editAction').map((item) => item.onRender = (item?: any, index?: number, column?: IColumn) => onRenderFinalAddtionalResellerEdit(item, index, column, type));
        columnConfig.filter((item) => item.key == 'deleteAction').map((item) => item.onRender = (item?: any, index?: number, column?: IColumn) => onRenderFinalAddtionalResellerDelete(item, index, column, type));
        return columnConfig;
    };

    // Overridden OnRender event for Primary Reseller Delete button
    function onRenderFinalAddtionalResellerEdit(item?: any, index?: number, column?: IColumn, type?: number): JSX.Element {
        const id = (item?.id || '') as string;
        const isFieldDisabled = getFieldConfigByState(commonPPContextState, "PrimaryResellerContainer", FieldMapMode.Disable);
        const iconName = CssClassConstants.EditIconName;
        let gridItems: IInitialFinalTierResellerValue[] = finalTierResellerGridItems?.filter((acc: any) => acc && acc.id === id && acc.action === ActionType.Created);

        return renderActionButton(id, 'primaryResellerEditButton', iconName, isFieldDisabled, (() => onFinalAddtionalResellerEdit(item, id, type)), gridItems);
    }

    // Overridden OnRender event for Primary Reseller Delete button
    function onRenderFinalAddtionalResellerDelete(item?: any, index?: number, column?: IColumn, type?: number): JSX.Element {
        const id = (item?.id || '') as string;
        const isFieldDisabled = getFieldConfigByState(commonPPContextState, "PrimaryResellerContainer", FieldMapMode.Disable);
        const iconName = CssClassConstants.DeleteIconName;
        let gridInitialItems: IInitialAdditionalResellerValue[] | IInitialFinalTierResellerValue[] = (type === PartnerType.FinalTierReseller) ? finalTierResellerGridItems.filter((acc) => acc && acc.id === id && acc.action === ActionType.Created) : additionalResellerGridItems.filter((acc) => acc && acc.id === id && acc.action === ActionType.Created);

        return renderActionButton(id, 'primaryResellerDeleteButton', iconName, isFieldDisabled, (() => onFinalAddtionalResellerDelete(id, type)), gridInitialItems);
    }

    function onFinalAddtionalResellerEdit(item: any, id: string, type?: number, ev?: React.FormEvent<HTMLElement | HTMLInputElement>) {
        if (type === PartnerType.FinalTierReseller) {
            setCurrentFinalTierIndex(item?.ftrNo - 1);
            openFinalResellerPanel();
        }
        else {
            setCurrentAdditionalResellerIndex(item?.arNo - 1);
            openAddtionalResellerPanel();
        }
    }

    //Common helper function --> to handle render Action Button Delete or Edit type
    function renderActionButton(rowId: string, buttonId: string, iconName: string, isFieldDisabled: boolean | undefined, onRenderClickFunction: any
        , gridInitialItems: DistributorGridItemsType[] | PrimaryResellerGridItemsType[] | IInitialAdditionalResellerValue[] | IInitialFinalTierResellerValue[]): JSX.Element {
        let isDisabledForExistingItems = getFieldStatusBasedOnExistingItemsActionFlag(rowId, gridInitialItems);
        let isActionButtonDisabled = isDisabledForExistingItems || ((typeof (isFieldDisabled) == 'undefined') ? false : isFieldDisabled);

        return <ActionButton
            id={buttonId}
            iconProps={{
                iconName: iconName, ariaLabel: iconName,
                styles: isActionButtonDisabled ? DefaultPageStyles.grayColor : DefaultPageStyles.redColor
            }}
            disabled={isActionButtonDisabled}
            onClick={() => { onRenderClickFunction(); console.log(); }}>
        </ActionButton>
    }

    // Primary Reseller Delete button event handler
    function onFinalAddtionalResellerDelete(id: string, type?: number, ev?: React.FormEvent<HTMLElement | HTMLInputElement>) {
        if (commonPPContextState.opportunityAccounts && id) {
            //Update Panel related variables
            if (type === PartnerType.FinalTierReseller) {
                let updatedResellers = finalTierResellerGridItems.filter((reseller) => reseller.id !== id);
                setFinalTierResellerGridItems(updatedResellers);
            }
            else if (type === PartnerType.AdditionalTierReseller) {
                let updatedResellers = additionalResellerGridItems.filter((reseller) => reseller.id !== id);
                setAdditionalResellerGridItems(updatedResellers);
            }

            //Update context
            let oppAccounts = [...commonPPContextState.opportunityAccounts].filter((acc) => acc.id !== id);
            commonPPContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: oppAccounts });

            if (type === PartnerType.AdditionalTierReseller) {
                const attachments = commonPPContextState.attachments?.filter(att => att && att.relatedEntityId !== id);
                commonPPContextDispatch({ type: CaseActionTypes.attachmentChanged, attachments: attachments });
            }

            const errorMessage = type === PartnerType.AdditionalTierReseller ? getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidateAdditionalReseller", ErrorMessages.ValidateAdditionalReseller) : getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidateFinalReseller", ErrorMessages.ValidateFinalReseller);
            clearErrorFromContext([type as PartnerType], oppAccounts, StatePropertiesText.ValidateFinalOrAdditionalReseller, errorMessage);
        }
    }

    // Attach Primary Reseller grid callbacks
    const attachPrimaryResellerCallbacks = (columnConfig: IColumnConfig[]): IColumnConfig[] => {
        const isFieldDisabled = getFieldConfigByState(commonPPContextState, "PrimaryResellerContainer", FieldMapMode.Disable);
        columnConfig.filter((item) => item.key === 'resellerEmailContacts').map((item) => item.onRender = onRenderResellerEmail);
        columnConfig.filter((item) => item.key === 'deleteAction').map((item) => item.onRender = onRenderResellerDelete);
        if (isFieldDisabled) {
            columnConfig.forEach((item, index) => {
                if (item.key === 'deleteAction') {
                    columnConfig.splice(index, 1);
                }
            });
        }
        return columnConfig;
    };

    // Attach Distributor grid callbacks
    const attachDistributorCallbacks = (columnConfig: IColumnConfig[]): IColumnConfig[] => {
        columnConfig.filter((item) => item.key === 'distributorOwner').map((item) => item.onRender = onRenderDistributorOwner);
        columnConfig.filter((item) => item.key === 'distributorEmailContacts').map((item) => item.onRender = onRenderDistributorEmail);
        columnConfig.filter((item) => item.key === 'deleteAction').map((item) => item.onRender = onRenderDistributorDelete);
        if (loggedInUserRole === UserSubRole.ADD) {
            columnConfig.forEach((item, index) => {
                if (item.key === 'deleteAction') {
                    columnConfig.splice(index, 1);
                }
            });
        }

        return columnConfig;
    };

    // Overridden OnRender event for Email textbox with validations
    function onRenderResellerEmail(item?: any, index?: number, column?: IColumn): JSX.Element {
        const fieldContent = item[column?.fieldName || ''] as string;
        const isDisabled = getFieldConfigByState(commonPPContextState, "PrimaryResellerContainer", FieldMapMode.Disable);
        let gridInitialItems: PrimaryResellerGridItemsType[] = primaryResellerGridItems.filter((acc) => acc && acc.id === item?.id && acc.action === ActionType.Created);

        return renderTextFieldElement_HelperFunction(fieldContent, isDisabled, gridInitialItems, item, column);
    };

    // Overridden OnRender event for Email textbox with validations
    function onRenderDistributorOwner(item?: any, index?: number, column?: IColumn): JSX.Element {
        const id = (item?.id || '') as string;
        let isDisabled = loggedInUserRole === UserSubRole.ADD || (requesterAccount != null && item.id === requesterAccount?.id && requesterAccount?.channelSubType === ChannelSubType.Requester);
        return (
            <ChoiceGroup defaultSelectedKey={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText)}
                options={[{ key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText), text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText) }, { key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText), text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText) }]}
                styles={OpportunityDetailsStyles.HorizontalChoiceGroup}
                required={true}
                onChange={(ev: any, option: any) => onDistributorOwnerChange(ev, option, id)}
                selectedKey={item.distributorOwner === false ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText)}
                disabled={isDisabled}
            />
        );
    };

    const onDistributorOwnerChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption, id?: string): void => {
        if (option && option.key && opportunityAccounts) {
            let existingOpportunityAccounts = [...opportunityAccounts];
            var existingOwner = existingOpportunityAccounts.filter(t => t.partnerType === PartnerType.ADD && t.channelSubType === ChannelSubType.CoOwner);
            if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(existingOwner) && existingOwner?.length > 0) {
                existingOwner.forEach(x => {
                    x.channelSubType = undefined;
                    x.action = x.action === ActionType.Created ? ActionType.Update : x.action;
                    x.accounts?.forEach(act => act.action = x?.action);
                });
            }
            let distributorAccount = existingOpportunityAccounts.find(acc => acc.id === id);
            if (distributorAccount) {
                distributorAccount.channelSubType = option.key === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText) ? ChannelSubType.CoOwner : undefined;
                distributorAccount.action = distributorAccount.action === ActionType.Created ? ActionType.Update : distributorAccount.action;
                distributorAccount?.accounts?.forEach(act => act.action = distributorAccount?.action);
                commonPPContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: existingOpportunityAccounts });
            }
        }
    }

    // Overridden OnRender event for Email textbox with validations
    function onRenderDistributorEmail(item?: any, index?: number, column?: IColumn): JSX.Element {
        const fieldContent = item[column?.fieldName || ''] as string;
        const isDisabled = getFieldConfigByState(commonPPContextState, "ChannelStackForDistributor", FieldMapMode.Disable);
        let gridInitialItems: DistributorGridItemsType[] = distributorListGridItems.filter((acc) => acc && acc.id === item?.id && acc.action === ActionType.Created);

        return renderTextFieldElement_HelperFunction(fieldContent, (isDisabled && item?.action !== ActionType.Add), gridInitialItems, item, column);
    };

    //Common Helper function to render Multiline TextField Email 
    function renderTextFieldElement_HelperFunction(fieldContent: string, isDisabled: boolean | undefined, gridInitialItems: DistributorGridItemsType[] | PrimaryResellerGridItemsType[] | IInitialAdditionalResellerValue[] | IInitialFinalTierResellerValue[], item?: any, column?: IColumn): JSX.Element {
        let isDisabledForExistingItems = getFieldStatusBasedOnExistingItemsActionFlag(item?.id, gridInitialItems);
        let isActionButtonDisabled = isDisabledForExistingItems || ((typeof (isDisabled) == 'undefined') ? false : isDisabled);

        return <TextField
            id={item[column?.key || '']}
            ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelContacts", UIControlsText.ChannelContacts)}
            placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "EmailCCRecipientsPlaceholderTextExample", UIControlsText.EmailCCRecipientsPlaceholderTextExample)}
            validateOnFocusOut
            required={true}
            multiline
            maxLength={1000}
            validateOnLoad={false}
            onGetErrorMessage={getEmailContactErrorMessage(StatePropertiesText.ValidateEmailText + item?.id)}
            onChange={onEmailContactsChange(item?.id)}
            disabled={isActionButtonDisabled}
            value={(requesterAccount != null && item.id === requesterAccount?.id) ? requesterAccount.contactEmail : commonPPContextState?.opportunityChannelContacts?.find(t => t.opportunityAccountId === item.id && t.source === "Channel")?.channelContactEmails}
            errorMessage={pivotTab?.errors?.get(StatePropertiesText.ValidateEmailText + item?.id)}
        />
    }

    // Reseller contact email change event handler
    const onEmailContactsChange = (accountId: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        let contacts: any[] = commonPPContextState.opportunityChannelContacts || [];
        if (contacts) {
            updateChannelContact(contacts, accountId, newValue);
            commonPPContextDispatch({ type: CaseActionTypes.channelContactChanged, channelContacts: contacts });
        }
    }

    // Overridden OnRender event for Primary Reseller Delete button
    function onRenderResellerDelete(item?: any, index?: number, column?: IColumn): JSX.Element {
        const accountId = item[column?.fieldName || ''] as string;
        const isFieldDisabled = getFieldConfigByState(commonPPContextState, "PrimaryResellerContainer", FieldMapMode.Disable);
        const iconName = CssClassConstants.DeleteIconName;
        let gridInitialItems: PrimaryResellerGridItemsType[] = primaryResellerGridItems.filter((acc) => acc && acc.id === accountId && acc.action === ActionType.Created);

        return renderActionButton(accountId, 'primaryResellerDeleteButton', iconName, isFieldDisabled, () => onResellerDelete(accountId), gridInitialItems);
    }

    // Overridden OnRender event for Distributor Delete button
    function onRenderDistributorDelete(item?: any, index?: number, column?: IColumn): JSX.Element {
        const accountId = item.id as string;
        const isFieldDisabled = getFieldConfigByState(commonPPContextState, "StackItemForDistributorGrid", FieldMapMode.Disable);
        const iconName = CssClassConstants.DeleteIconName;
        let gridInitialItems: DistributorGridItemsType[] = distributorListGridItems.filter((acc) => acc && acc.id === accountId && acc.action === ActionType.Created);;

        return renderActionButton(accountId, 'distributorDeleteButton', iconName, (item?.action !== ActionType.Add), (() => { onDistributorsDelete(accountId) }), gridInitialItems);
    }

    // Primary Reseller Delete button event handler
    function onResellerDelete(accountId: string, ev?: React.FormEvent<HTMLElement | HTMLInputElement>) {
        if (commonPPContextState.opportunityAccounts && accountId) {
            //Update Panel related variables
            let accounts = [...commonPPContextState.opportunityAccounts].find((acc) => acc.id === accountId)?.accounts || [];
            let updatedAccounts = selectedResellers.filter((account) => account.accountId !== accounts[0].accountId);
            setSelectedResellers(updatedAccounts);

            //Update context
            let oppAccounts = [...commonPPContextState.opportunityAccounts].filter((acc) => acc.id !== accountId);
            let contacts = [...commonPPContextState.opportunityChannelContacts || []].filter((contact) => contact.opportunityAccountId !== accountId);
            commonPPContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: oppAccounts });
            commonPPContextDispatch({ type: CaseActionTypes.channelContactChanged, channelContacts: contacts });
            clearErrorFromContext([PartnerType.ADR], oppAccounts, StatePropertiesText.ValidatePrimaryResellers, getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidatePrimaryResellersOnly", ErrorMessages.ValidatePrimaryResellersOnly));
        }
    }

    // Distributor Delete button event handler
    function onDistributorsDelete(accountId: string, ev?: React.FormEvent<HTMLElement | HTMLInputElement>) {
        if (commonPPContextState.opportunityAccounts && accountId) {
            //Update Panel related variables
            let accounts = [...commonPPContextState.opportunityAccounts].find((acc) => acc.id === accountId)?.accounts || [];
            let updatedAccounts = selectedDistributors.filter((account) => account.accountId !== accounts[0].accountId)
            setSelectedDistributors(updatedAccounts);

            //Update context
            let oppAccounts = [...commonPPContextState.opportunityAccounts].filter((acc) => acc.id !== accountId);
            let contacts = [...commonPPContextState.opportunityChannelContacts || []].filter((contact) => contact.opportunityAccountId !== accountId);
            commonPPContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: oppAccounts });
            commonPPContextDispatch({ type: CaseActionTypes.channelContactChanged, channelContacts: contacts });
            clearErrorFromContext([PartnerType.ADD, PartnerType.DMP], oppAccounts, StatePropertiesText.ValidateMultipleDistributors, ErrorMessages.ValidatePrimaryDistributorOnly);
        }
    }

    //Common Helper function to check if the existing row's delete button needs to be disabled.
    function getFieldStatusBasedOnExistingItemsActionFlag(rowAccountId: string, gridInitialItems: DistributorGridItemsType[] | PrimaryResellerGridItemsType[] | IInitialAdditionalResellerValue[] | IInitialFinalTierResellerValue[]) {
        let isDisabled: boolean = false;

        if (gridInitialItems?.length > 0) {
            isDisabled = true;
        }
        return isDisabled;
    }

    //Add new IOpportunityAccount
    const addAccounts = (selectedAccounts: IAccount[]) => {
        //Remove uncheched accounts & its associated contacts
        let updatedAccounts = opportunityAccounts.filter(acc => acc.channelSubType === ChannelSubType.Requester || acc.partnerType === PartnerType.FinalTierReseller || acc.partnerType === PartnerType.AdditionalTierReseller || selectedAccounts.some(s => acc.accounts && acc.accounts[0]?.accountId === s.accountId));
        let updatedContacts = opportunityChannelContacts.filter(c => c.source !== getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText) || (c.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText) && updatedAccounts.some(a => a.id === c.opportunityAccountId)));

        let newAccounts = selectedAccounts.filter(ac => !opportunityAccounts.find((opp) => opp.accounts && opp.accounts[0]?.accountId === ac?.accountId))
            .map((acc) => { return getNewOpportunityAccount(acc.partnerType, acc); });
        let newContacts = newAccounts.map((acc) => { return getNewOpportunityChannelContact(acc?.id); });

        commonPPContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: [...updatedAccounts, ...newAccounts] });
        commonPPContextDispatch({ type: CaseActionTypes.channelContactChanged, channelContacts: [...updatedContacts, ...newContacts] });
        if (opportunityAccounts.find(t => (t.channelSubType !== ChannelSubType.Requester) && (t.partnerType === PartnerType.ADD || t.partnerType === PartnerType.ADR || t.partnerType === PartnerType.DMP)) !== null) {
            const pivotTabsData = commonPPContextState.pivotTabs;
            let pivotTabChannel = pivotTabsData?.find(t => t.tabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)) || {};
            pivotTabChannel?.errors?.delete(StatePropertiesText.ValidatePrimaryResellers);
            pivotTabChannel?.errors?.delete(StatePropertiesText.ValidateMultipleDistributors);
            commonPPContextDispatch({ type: CaseActionTypes.pivotTabData, pivotTabDetails: pivotTabsData });
        }
    }

    //callback method to add new ADDs from Panel
    const onPrimaryResellerAdd = (selectedItems: any[], isResellerAdd?: boolean): void => {
        if (selectedItems) {
            const selectedData = [...selectedItems];
            selectedData.forEach(t => t.action = (t.action === ActionType.PanelAdd) ? ActionType.Add : t.action)
            if (isResellerAdd) {
                setSelectedResellers(selectedData);
            }
            else {
                setSelectedDistributors(selectedData);
            }

            addAccounts([...selectedData]);
        }
    }

    useEffect(() => {
        let partnerType = PartnerType.ADR;
        if (commonPPContextState.partnerDetails?.userRole === UserSubRole.ADD || commonPPContextState.partnerDetails?.userRole === UserSubRole.DMP) {
            partnerType = PartnerType.ADR;
        }
        else if (commonPPContextState.partnerDetails?.userRole === UserSubRole.ADR) {
            partnerType = PartnerType.DMP;
        }
        setSelectedResellerType(partnerType);
        setInitialResellerType(partnerType);
        setShowChannelContentBasedOnRoleType(getChannelPersona());

    }, [commonPPContextState.partnerDetails?.userRole, endCustomerCountry]);

    useEffect(() => {
        if (commonPPContextState?.opportunityDeals[0]?.geoCountries) {
            const countryId = commonPPContextState?.opportunityDeals[0]?.geoCountries[0] && commonPPContextState?.opportunityDeals[0]?.geoCountries[0].countryId;
            var endCustomerCountry = props.countries?.find(c => c.countryId === countryId);
            if (endCustomerCountry) {
                setEndCustomerCountry(endCustomerCountry);
            }
        }

    }, [commonPPContextState?.opportunityDeals[0]?.geoCountries]);

    useEffect(() => {
        //Initialize MSStore Opportunity Account if it doesn't exists and login persona is MSStore.
        if (showChannelContentBasedOnRoleType && commonPPContextState.opportunityAccounts && commonPPContextState.opportunityAccounts.length === 0
            && commonPPContextState.partnerDetails?.userRole !== undefined && endCustomerCountry) {
            //Add IOpportunityAccount for login user for requestor
            let requestor = addRequestorAccount();
            if (requestor) {
                if (showChannelContentBasedOnRoleType?.MSStore) {

                    let msStore = getNewOpportunityAccount(PartnerType.MsStore);
                    msStore.channelSubType = msStoreSalesChannel;
                    msStore.accountCompanyName = defaultMSStoreSellerName;
                    updateMSStoreCompleteFlag(msStore);
                    let msContact = getNewOpportunityChannelContact(msStore.id as string);

                    commonPPContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: [requestor, msStore] });
                    commonPPContextDispatch({ type: CaseActionTypes.channelContactChanged, channelContacts: [msContact] });
                }
                else {
                    commonPPContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: [requestor] });
                }
            }
        }
    }, [commonPPContextState.partnerDetails, showChannelContentBasedOnRoleType, endCustomerCountry, distributors.current, resellerAccounts.current]);

    const addRequestorAccount = () => {
        let account: IAccount | undefined;
        {
            if (commonPPContextState.partnerDetails?.userRole === UserSubRole.ADD) {
                account = distributorItems.current && distributorItems.current.find((acc: IAccount) => acc.accountId === commonPPContextState.partnerDetails?.accountId?.trim());
            }
            else if (commonPPContextState.partnerDetails?.userRole === UserSubRole.ADR || commonPPContextState.partnerDetails?.userRole === UserSubRole.DMP) {
                var loggedUserInfo: IUserDetail = JSON.parse(getUserSession());
                var user = loggedUserInfo.userRoleDetails.find((acc: IUserRoleDetail) => acc.accountId === commonPPContextState.partnerDetails?.accountId?.trim());
                if (user) {
                    account = {
                        accountId: user.accountId,
                        accountName: user.organizationName || "",
                        companyName: user.organizationName || "",
                        organizationId: user.organizationId,
                        locationId: user.locationId,
                        countryId: commonPPContextState.partnerDetails?.countryId,
                        countryName: commonPPContextState.partnerDetails?.countryName,
                        productType: commonPPContextState.partnerDetails?.productGroupName,
                        productTypeId: commonPPContextState.partnerDetails?.productGroupId
                    };
                }
            }

            type PartnerDataType = keyof typeof PartnerType;
            let requestorAccount = getNewOpportunityAccount(JavaScriptExtensions.isEmptyOrNullOrUndefined(commonPPContextState.partnerDetails?.userRole) ? PartnerType.MsStore : PartnerType[commonPPContextState.partnerDetails?.userRole as PartnerDataType], account);
            requestorAccount.channelSubType = ChannelSubType.Requester;
            requestorAccount.contactEmail = sessionStorage.getItem(SessionStorageConsts.userEmail) || "";
            return requestorAccount;
        }
    }

    // MSStoreSalesChannel change event handler
    const onMSStoreChannelChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
        if (option && option.key && opportunityAccounts) {
            let msStore = msStoreAccount;
            if (msStore) {
                msStore.channelSubType = parseInt(option.key) as ChannelSubType;
                updateMSStoreCompleteFlag(msStore);
                setMsStoreSalesChannel(msStore.channelSubType);
                let accounts = commonPPContextState.opportunityAccounts?.filter(acc => acc.id !== msStore?.id) || [];
                commonPPContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: [...accounts, msStore] });

                //Update error message for MS Store address
                getTextFieldErrorMessageMsStore(msStore.channelSubType === ChannelSubType.DSS ? getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredMSStoreHub", ErrorMessages.RequiredMSStoreHub) : getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredMSStoreDigitalStore", ErrorMessages.RequiredMSStoreDigitalStore), StatePropertiesText.PartnerAddress)(msStore?.address || '');
            }
        }
    }

    //Common event handler for MSStore text-boxes
    const onMSStoreTextFieldChange = (targetPropertyName: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        let msStore: any = msStoreAccount;
        msStore[targetPropertyName] = newValue;
        updateMSStoreCompleteFlag(msStore);
        let accounts = commonPPContextState.opportunityAccounts?.filter(acc => acc.id !== msStore.id) || [];
        commonPPContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: [...accounts, msStore] });
        if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(newValue) && !JavaScriptExtensions.isEmptyOrNullOrUndefined(pivotTab?.errors?.get(targetPropertyName || ""))) {
            setErrorsDataIntoContext('', targetPropertyName || "");
        }
    }

    const updateMSStoreCompleteFlag = (msStore: IOpportunityAccount): void => {
        msStore.isComplete = !(!JavaScriptExtensions.isDefined(msStore.channelSubType) || JavaScriptExtensions.isEmptyOrNullOrUndefined(msStore.address) || JavaScriptExtensions.isEmptyOrNullOrUndefined(msStore.city) || JavaScriptExtensions.isEmptyOrNullOrUndefined(msStore.stateProvince) || JavaScriptExtensions.isEmptyOrNullOrUndefined(msStore.accountCompanyName))
    }

    //Event handler for MSStore email
    const onMSStoreContactEmailChange = () => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (opportunityAccounts) {
            const msStore = msStoreAccount;
            if (msStore) {
                let contacts: IOpportunityChannelContact[] = commonPPContextState?.opportunityChannelContacts || [];
                if (contacts) {
                    updateChannelContact(contacts, msStore.id, newValue);
                    commonPPContextDispatch({ type: CaseActionTypes.channelContactChanged, channelContacts: contacts });
                }
                if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(newValue) && !JavaScriptExtensions.isEmptyOrNullOrUndefined(pivotTab?.errors?.get(StatePropertiesText.MSStoreContactEmail || ""))) {
                    setErrorsDataIntoContext('', StatePropertiesText.MSStoreContactEmail || "");
                }
            }
        }
    }

    // Get MSStore Email TextField ErrorMessage.
    const getMSStoreEmailErrorMessage = (value: string): string => {
        let message = validateMSStoreEmailErrorMessage(value);
        if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(StatePropertiesText.MSStoreContactEmail)) {
            setErrorsDataIntoContext(message, (StatePropertiesText.ValidateEmailText + msStoreAccount?.id));
        }

        return message;
    };

    // Get Email Contacts TextField ErrorMessage.
    const getEmailContactErrorMessage = (targetPropertyId?: string) => (value: string): string => {
        return validateContactErrorMessage(value, targetPropertyId)
    };

    const validateContactErrorMessage = (value: string, targetPropertyId?: string): string => {
        let message = '';
        if (!value) {
            message = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredResellerEmail", ErrorMessages.RequiredResellerEmail);
        }
        else if (!isValidEmailWithSeperator(value, ";")) {
            message = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "InvalidResellerEmail", ErrorMessages.InvalidResellerEmail);
        }

        if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(targetPropertyId)) {
            setErrorsDataIntoContext(message, targetPropertyId || "");
        }
        return message;
    }

    const setErrorsDataIntoContext = (message: string, targetPropertyId: string) => {
        const pivotTabsData = commonPPContextState.pivotTabs;
        var pivotTab = pivotTabsData?.find(t => t.tabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText));
        if (JavaScriptExtensions.isEmptyOrNullOrUndefined(message)) {
            pivotTab?.errors?.delete(targetPropertyId);
        }
        else {
            pivotTab?.errors?.set(targetPropertyId, message);
        }
        commonPPContextDispatch({ type: CaseActionTypes.pivotTabData, pivotTabDetails: pivotTabsData })
    }

    //Updates the contact, based on the id provided
    function updateChannelContact(contacts: IOpportunityChannelContact[], id?: string, email?: string, isMSStore?: boolean) {
        let updatedContact = contacts.find(c => c.opportunityAccountId === id);
        email = email?.replace(',', ';');
        if (updatedContact) {
            updatedContact.channelContactEmails = email?.replace(',', ';');
            updatedContact.isComplete = isMSStore ? getMSStoreEmailErrorMessage(email || '') === '' : validateContactErrorMessage(email || '', StatePropertiesText.ValidateEmailText + id) === '';
        } else {
            //This indicates that in the list of contacts (accounts) that is available in the state, there is no existing account available.
            updatedContact = getNewOpportunityChannelContact(id, email);
            contacts.push(updatedContact);
        }
    }

    // FinalTierReseller Choice change event handler
    const onFinalTierResellerChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
        if (option && option.key) {
            setFinalTierReseller(option.key);
            if (option.key === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText)) {
                let updatedAccounts = opportunityAccounts.filter(acc => acc.partnerType !== PartnerType.FinalTierReseller);
                commonPPContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: updatedAccounts });
                setErrorsDataIntoContext('', StatePropertiesText.ValidateFinalOrAdditionalReseller);
            }
            commonPPContextDispatch({ type: CaseActionTypes.isFinalOrAdditionalResellerSelected, boolValue: option.key === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText) });
        }
    }

    // AddtionalReseller Choice change event handler
    const onAddtionalResellerChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
        if (option && option.key) {
            setAddtionalReseller(option.key);
            if (option.key === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText)) {
                let updatedAccounts = opportunityAccounts.filter(acc => acc.partnerType !== PartnerType.AdditionalTierReseller);
                commonPPContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: updatedAccounts });
                setErrorsDataIntoContext('', StatePropertiesText.ValidateFinalOrAdditionalReseller);
            }

            commonPPContextDispatch({ type: CaseActionTypes.isFinalOrAdditionalResellerSelected, boolValue: option.key === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText) });
        }
    }

    const text = <span style={{ fontSize: 12, marginTop: 10 }}>For additional resellers you must complete a copy of <Link onClick={(event) => OnFlowDownLinkClick(event, "Flowdown Terms Application.pdf")}>Flow Down Terms Application</Link > which must be submitted as attachment in the form.</span>;

    const OnFlowDownLinkClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLElement>, fileName: string): void => {
        nextGenService.getDocumentFromBlob(fileName)
            .then((response) => {
                downloadFile(fileName, response.data);
            });
    };

    const [addSaveResellerButtonInEdit, addAdditionalResellerButtonInEdit] = additionalResellerGridItems.length && commonPPContextState.pageMode === PageMode.Edit ? [false, false] : [true, true];
    const [addSaveFinalButtonInEdit, addFinalResellerButtonInEdit] = finalTierResellerGridItems.length && commonPPContextState.pageMode === PageMode.Edit ? [false, false] : [true, true];

    /**
    * Content for the Channel tab with JSON format
    * @json
    */
    const ChannelJson = [
        {
            type: 'Stack',
            data: {
                id: 'ChannelContainerLoadingSpinner',
                visible: isResellerLoading,
                components: [
                    {
                        type: 'Spinner',
                        data: {
                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LoadingReseller", UIControlsText.LoadingReseller)
                        }
                    }
                ]
            }
        },
        {
            type: 'CustomTileCardComponent',
            data: {
                id: 'DocCardContainerForMSStore',
                visible: showChannelContentBasedOnRoleType?.MSStore,
                baseState: commonPPContextState,
                name: "MS Store Details",
                'data-nextgen-parent-group': "Create - MS Store Details",
                'data-nextgen-parentid': "Create - MS Store Details",
                iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MsStoreText", UIControlsText.MsStoreText),
                components: [
                    {
                        type: 'Stack',
                        data: {
                            id: UIConfigIdentifierConstants.ChannelContainerForMSStoreId,
                            visible: showChannelContentBasedOnRoleType?.MSStore,
                            styles: stackItemStyles,
                            align: "auto",
                            tokens: itemAlignmentsStackTokens10,
                            components: [
                                {
                                    type: 'Stack',
                                    data: {
                                        id: 'MSStoreChannelSection',
                                        horizontal: true,
                                        visible: showChannelContentBasedOnRoleType?.MSStore,
                                        components: [
                                            {
                                                type: 'StackItem',
                                                data: {
                                                    id: 'StackItemMSStoreChannel',
                                                    styles: stackItemStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "GuidanceTextInformation",
                                                            data: {
                                                                id: UIConfigIdentifierConstants.GuidanceTextForMSStoreId,
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "GuidanceTextForMSStore", UIControlsText.GuidanceTextForMSStore),
                                                                styles: PageStyles.textFieldStylesTwoColumn,
                                                            }
                                                        },
                                                        {
                                                            type: "ChoiceGroup",
                                                            data: {
                                                                id: "MSStoreChannel",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MSStoreChannel", UIControlsText.MSStoreChannel),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MSStoreChannel", UIControlsText.MSStoreChannel),
                                                                styles: DealDetailsStyles.horizontalChoiceGroup,
                                                                options: [{ key: ChannelSubType.DSS, text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DSSText", UIControlsText.DSSText) }, { key: ChannelSubType.DigitalStore, text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DigitalStore", UIControlsText.DigitalStore), styles: { field: DealDetailsStyles.marginLeft10 } }],
                                                                required: true,
                                                                defaultSelectedKey: msStoreSalesChannel,
                                                                selectedKey: msStoreSalesChannel,
                                                                onChange: onMSStoreChannelChange
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                        ]
                                    },
                                },
                                {
                                    type: 'Stack',
                                    data: {
                                        id: 'MSStoreAddressSection',
                                        horizontal: true,
                                        components: [
                                            {
                                                type: 'Stack',
                                                data: {
                                                    id: 'StackItemMSStoreAddress',
                                                    styles: stackItemStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "TextField",
                                                            data: {
                                                                id: "MSStoreAddressTextField",
                                                                name: "MSStoreAddressTextField",
                                                                label: msStoreSalesChannel == ChannelSubType.DSS ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Hub", UIControlsText.Hub) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DigitalStoreName", UIControlsText.DigitalStoreName),
                                                                ariaLabel: msStoreSalesChannel == ChannelSubType.DSS ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Hub", UIControlsText.Hub) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DigitalStoreName", UIControlsText.DigitalStoreName),
                                                                styles: PageStyles.textFieldStylesOneColumn,
                                                                placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DeviceShipToAddressPlaceholderTextExample", UIControlsText.DeviceShipToAddressPlaceholderTextExample),
                                                                required: true,
                                                                maxLength: 100,
                                                                onGetErrorMessage: getTextFieldErrorMessageMsStore(msStoreSalesChannel === ChannelSubType.DSS ? getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredMSStoreHub", ErrorMessages.RequiredMSStoreHub) : getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredMSStoreDigitalStore", ErrorMessages.RequiredMSStoreDigitalStore), StatePropertiesText.PartnerAddress),
                                                                validateOnLoad: false,
                                                                validateOnFocusOut: true,
                                                                value: msStoreAccount?.address,
                                                                onChange: onMSStoreTextFieldChange(StatePropertiesText.PartnerAddress),
                                                                errorMessage: pivotTab?.errors?.get(StatePropertiesText.PartnerAddress)
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                type: 'Stack',
                                                data: {
                                                    id: 'StackItemMSStoreCity',
                                                    styles: stackItemStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "TextField",
                                                            data: {
                                                                id: "MSStoreCityTextField",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CityText", UIControlsText.CityText),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CityText", UIControlsText.CityText),
                                                                styles: PageStyles.textFieldStylesOneColumn,
                                                                placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DeviceShipToCityPlaceholderTextExample", UIControlsText.DeviceShipToCityPlaceholderTextExample),
                                                                defaultValue: "",
                                                                required: true,
                                                                onGetErrorMessage: getTextFieldErrorMessageMsStore(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredCity", ErrorMessages.RequiredCity), StatePropertiesText.PartnerCity),
                                                                validateOnLoad: false,
                                                                validateOnFocusOut: true,
                                                                maxLength: 100,
                                                                value: msStoreAccount?.city,
                                                                onChange: onMSStoreTextFieldChange(StatePropertiesText.PartnerCity),
                                                                errorMessage: pivotTab?.errors?.get(StatePropertiesText.PartnerCity)
                                                            }
                                                        },
                                                    ]
                                                }
                                            },
                                            {
                                                type: 'Stack',
                                                data: {
                                                    id: 'StackItemMSStoreState',
                                                    styles: stackItemStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "TextField",
                                                            data: {
                                                                id: "MSStoreStateTextField",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "StateProvinceText", UIControlsText.StateProvinceText),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "StateProvinceText", UIControlsText.StateProvinceText),
                                                                styles: PageStyles.textFieldStylesOneColumn,
                                                                placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DeviceShipToStatePlaceholderTextExample", UIControlsText.DeviceShipToStatePlaceholderTextExample),
                                                                defaultValue: "",
                                                                required: msStoreSalesChannel === ChannelSubType.DigitalStore,
                                                                onGetErrorMessage: msStoreSalesChannel === ChannelSubType.DigitalStore ? getTextFieldErrorMessageMsStore(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredState", ErrorMessages.RequiredState), StatePropertiesText.PartnerState) : "",
                                                                validateOnLoad: false,
                                                                validateOnFocusOut: true,
                                                                maxLength: 100,
                                                                value: msStoreAccount?.stateProvince,
                                                                onChange: onMSStoreTextFieldChange(StatePropertiesText.PartnerState),
                                                                errorMessage: msStoreSalesChannel === ChannelSubType.DigitalStore ? pivotTab?.errors?.get(StatePropertiesText.PartnerState) : ""
                                                            }
                                                        },
                                                    ]
                                                }
                                            },
                                        ]
                                    }
                                },
                                {
                                    type: 'Stack',
                                    data: {
                                        id: 'MSStoreContact',
                                        horizontal: true,
                                        disableshrink: true,
                                        components: [
                                            {
                                                type: 'Stack',
                                                data: {
                                                    id: 'StackItemMSStoreSellerName',
                                                    styles: stackItemStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "TextField",
                                                            data: {
                                                                id: "MSStoreSellerNameTextField",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MSStoreSellerName", UIControlsText.MSStoreSellerName),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MSStoreSellerName", UIControlsText.MSStoreSellerName),
                                                                styles: PageStyles.textFieldStylesOneColumn,
                                                                placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MSStoreSellerNamePlaceholderText", UIControlsText.MSStoreSellerNamePlaceholderText),
                                                                defaultValue: defaultMSStoreSellerName,
                                                                required: true,
                                                                onGetErrorMessage: getTextFieldErrorMessageMsStore(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredMSStoreSellerName", ErrorMessages.RequiredMSStoreSellerName), StatePropertiesText.PartnerSellerName),
                                                                validateOnLoad: false,
                                                                maxLength: 100,
                                                                validateOnFocusOut: true,
                                                                value: msStoreAccount?.accountCompanyName,
                                                                onChange: onMSStoreTextFieldChange(StatePropertiesText.PartnerSellerName),
                                                                errorMessage: pivotTab?.errors?.get(StatePropertiesText.PartnerSellerName)
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                type: 'Stack',
                                                data: {
                                                    id: 'StackItemMSStoreSellerEmail',
                                                    styles: stackItemStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "TextField",
                                                            data: {
                                                                id: "MSStoreSellerEmailTextField",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MSStoreSellerEmail", UIControlsText.MSStoreSellerEmail),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MSStoreSellerEmail", UIControlsText.MSStoreSellerEmail),
                                                                styles: PageStyles.textFieldStylesOneColumn,
                                                                placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "MSStoreSellerEmailPlaceholderText", UIControlsText.MSStoreSellerEmailPlaceholderText),
                                                                defaultValue: "",
                                                                required: true,
                                                                onGetErrorMessage: getMSStoreEmailErrorMessage,
                                                                validateOnLoad: false,
                                                                maxLength: 1000,
                                                                validateOnFocusOut: true,
                                                                value: opportunityChannelContacts.find(c => c.opportunityAccountId === msStoreAccount?.id)?.channelContactEmails,
                                                                onChange: onMSStoreContactEmailChange(),
                                                                errorMessage: pivotTab?.errors?.get(StatePropertiesText.ValidateEmailText + msStoreAccount?.id)
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                        ]
                                    },
                                },
                            ]
                        }
                    }
                ]
            }
        },
        {
            type: 'CustomTileCardComponent',
            data: {
                id: 'DocCardContainerForReseller',
                baseState: commonPPContextState,
                name: "Reseller Details",
                'data-nextgen-parent-group': "Create - Reseller Details",
                'data-nextgen-parentid': "Create - Reseller Details",
                iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellersIcon", UIControlsText.ResellersIcon),
                visible: !(showChannelContentBasedOnRoleType?.MSStore) && !isResellerLoading && (showChannelContentBasedOnRoleType?.FinalTierAndDistributors || showChannelContentBasedOnRoleType?.AdditionalAndDistributors || showChannelContentBasedOnRoleType?.PrimaryandAdditionalResellers || showChannelContentBasedOnRoleType?.PrimaryAndFinalTierResellers || showChannelContentBasedOnRoleType?.PrimaryResellersOnly || showChannelContentBasedOnRoleType?.PrimaryandAdditionalResellers),
                components: [
                    {
                        type: 'Stack',
                        data: {
                            id: 'StackReseller',
                            visible: !(showChannelContentBasedOnRoleType?.MSStore) && !isResellerLoading && (showChannelContentBasedOnRoleType?.FinalTierAndDistributors || showChannelContentBasedOnRoleType?.AdditionalAndDistributors || showChannelContentBasedOnRoleType?.PrimaryandAdditionalResellers || showChannelContentBasedOnRoleType?.PrimaryAndFinalTierResellers || showChannelContentBasedOnRoleType?.PrimaryResellersOnly || showChannelContentBasedOnRoleType?.PrimaryandAdditionalResellers),
                            styles: stackItemStyles,
                            align: "auto",
                            tokens: smallAlignmentTokens,
                            wrap: true,
                            components: [
                                //Reseller Grid
                                {
                                    type: 'Stack',
                                    data: {
                                        id: 'StackItemForAddChannelRoleBasedContentForReseller',
                                        vertical: true,
                                        wrap: true,
                                        visible: showChannelContentBasedOnRoleType?.FinalTierAndDistributors || showChannelContentBasedOnRoleType?.AdditionalAndDistributors || showChannelContentBasedOnRoleType?.PrimaryandAdditionalResellers || showChannelContentBasedOnRoleType?.PrimaryAndFinalTierResellers || showChannelContentBasedOnRoleType?.PrimaryResellersOnly || showChannelContentBasedOnRoleType?.PrimaryandAdditionalResellers,
                                        components: [
                                            {
                                                type: "StackItem",
                                                data: {
                                                    id: UIConfigIdentifierConstants.PrimaryResellerContainerId,
                                                    visible: showChannelContentBasedOnRoleType?.PrimaryAndFinalTierResellers || showChannelContentBasedOnRoleType?.PrimaryResellersOnly || showChannelContentBasedOnRoleType?.PrimaryandAdditionalResellers,
                                                    components: [
                                                        {
                                                            type: "CustomViewModeComponent",
                                                            data: {
                                                                id: "LabelResellerInformation",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerInformation", UIControlsText.ResellerInformation),
                                                                styles: PageStyles.sectionHeading,
                                                            }
                                                        },
                                                        {
                                                            type: "GuidanceTextInformation",
                                                            data: {
                                                                id: UIConfigIdentifierConstants.GuidanceTextForResellerId,
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "GuidanceTextForReseller", UIControlsText.GuidanceTextForReseller),
                                                                styles: PageStyles.textFieldStylesWidth146,
                                                                visible: true
                                                            }
                                                        },
                                                        {
                                                            type: "PanelTableContainer",
                                                            data: {
                                                                invokePanelProps: {
                                                                    iconProps: {
                                                                        iconName: CssClassConstants.AddItemIconName,
                                                                        ariaLabel: CssClassConstants.AddIconName
                                                                    },
                                                                    buttonStyles: PageStyles.buttonStylesLong,
                                                                    buttonText: showChannelContentBasedOnRoleType?.PrimaryResellersOnly ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddResellerText", UIControlsText.AddResellerText) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddPrimaryResellerText", UIControlsText.AddPrimaryResellerText),
                                                                    onButtonClick: () => onPanelClick('reseller'),
                                                                    disabled: (opportunityAccounts.filter(acc => acc.partnerType === PartnerType.ADR || acc.partnerType === PartnerType.DMP).length >= channelConfig.Config.PrimaryResellerCount)
                                                                        || getFieldConfigByState(commonPPContextState, "PrimaryResellerContainer", FieldMapMode.Disable)
                                                                        || commonPPContextState.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.PendingSecondaryReview
                                                                },
                                                                messageBar: {
                                                                    messageBarType: MessageBarType.error,
                                                                    isMultiline: false,
                                                                    dismissButtonAriaLabel: "Close"
                                                                },
                                                                panelType: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Reseller", UIControlsText.Reseller),
                                                                errorMessage: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameErrorMessage", UIControlsText.ResellerNameErrorMessage) || '',
                                                                headerText: GridType.ResellersList,
                                                                sortingDataType: 'number',
                                                                propertyName: 'organizationId',
                                                                onItemSelection: onResellerOrDistributorChange,
                                                                onClearAll: onClearAll,
                                                                tableConfigurationFields: resellerTableConfigurations,
                                                                selectionType: GridSelectionType.Single,
                                                                columnDefinitions: resellerColumnDefinitions,
                                                                tableContent: resellers && resellers.current && resellers.current.length > 0 && resellers.current.map((pt: any) => ({
                                                                    key: `${pt.accountId}-${pt.partnerType}`, id: pt.accountId, name: pt.companyName, data: partnerTypeValues[`${pt.partnerType}`], partnerType: pt.partnerType, country: pt.countryName, countryId: pt.countryId, organizationId: pt.organizationId, countryGeo: pt.applicablePriceListGeo,
                                                                    editMode: false
                                                                })) || [],
                                                                countrySelected: selectedResellerCountry,
                                                                geoSelected: endCustomerCountry?.applicablePriceListGeo,
                                                                partnerTypeSelected: selectedResellerType,
                                                                selectedItems: selectedResellers && selectedResellers.map((x: any) => ({
                                                                    key: `${x.accountId}-${x.partnerType}`, id: x.accountId, name: x.companyName, data: partnerTypeValues[`${x.partnerType}`], partnerType: x.partnerType, country: x.countryName, countryId: x.countryId, countryGeo: x.applicablePriceListGeo, organizationId: x.organizationId, action: x.action,
                                                                    editMode: isEditableRow(selectedResellerCountry, commonPPContextState?.pageMode, currentDealStatusCode, x, x.partnerType)
                                                                } as any)) || [],
                                                                disableSelectAllSelection: false,
                                                                primaryButtonProps: {
                                                                    ariaLabel: 'AddReseller',
                                                                    text: showChannelContentBasedOnRoleType?.PrimaryResellersOnly ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddResellerText", UIControlsText.AddResellerText) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddPrimaryResellerText", UIControlsText.AddPrimaryResellerText),
                                                                    onClick: () => onAddButtonClicked(true),
                                                                    style: PageStyles.panelButtonStyle,
                                                                },
                                                                onDefaultButtonClick: onClearAll,
                                                                footerTextField: `${selectedResellers && selectedResellers.length} Resellers selected`,
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            visible: !JavaScriptExtensions.isEmptyOrNullOrUndefined(pivotTab?.errors?.get(StatePropertiesText.ValidatePrimaryResellers)),
                                                            data: {
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            contolType: UIControlType.Label,
                                                                            id: "ValidatePrimaryResellerError",
                                                                            value: pivotTab?.errors?.get(StatePropertiesText.ValidatePrimaryResellers),
                                                                            className: 'ms-LabelValueInvalid',
                                                                            styles: DefaultPageStyles.customWidthStyleAuto
                                                                        }
                                                                    },
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: "DetailListEditableGrid",
                                                            data: {
                                                                id: "DetailsListGridForPrimaryReseller",
                                                                key: "DetailsListGridForPrimaryReseller",
                                                                styles: { root: { height: "100%" } },
                                                                items: primaryResellerGridItems,
                                                                columns: attachPrimaryResellerCallbacks(PrimaryResellerGridColumnConfig),
                                                                gridConfigOptions: {
                                                                    enableCellEdit: commonPPContextState?.pageMode == PageMode.Create,
                                                                },
                                                                enableDefaultEditMode: commonPPContextState?.pageMode == PageMode.Create,
                                                                height: "140px",
                                                                width: "100%",
                                                                visible: primaryResellerGridItems?.length !== 0,
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                type: "StackItem",
                                                data: {
                                                    id: "AdditionalResellersContainer",
                                                    label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalResellersInformation", UIControlsText.AdditionalResellersInformation),
                                                    styles: stackItemStyles,
                                                    visible: showChannelContentBasedOnRoleType?.AdditionalAndDistributors || showChannelContentBasedOnRoleType?.PrimaryandAdditionalResellers,
                                                    components: [
                                                        {
                                                            type: "ChoiceGroup",
                                                            data: {
                                                                id: UIConfigIdentifierConstants.AdditionalResellersChoiceGroupId,
                                                                defaultSelectedKey: addtionalReseller,
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalResellerChoiceQuestion", UIControlsText.AdditionalResellerChoiceQuestion),
                                                                styles: ChoiceGroupStyles.ChoiceGroupHorizontal,
                                                                options: [{ key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText), text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText) }, { key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText), text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText) }],
                                                                selectedKey: addtionalReseller,
                                                                required: true,
                                                                onChange: onAddtionalResellerChange,
                                                            }
                                                        },
                                                        {
                                                            type: "CustomViewModeComponent",
                                                            data: {
                                                                id: "LabelAdditionalResellerInformation",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalResellersInformation", UIControlsText.AdditionalResellersInformation),
                                                                styles: PageStyles.sectionHeading,
                                                                visible: addtionalReseller === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText),
                                                            }
                                                        },
                                                        {
                                                            type: "PanelFieldContainer",
                                                            data: {
                                                                invokePanelProps: {
                                                                    iconProps: {
                                                                        iconName: CssClassConstants.AddItemIconName,
                                                                        ariaLabel: CssClassConstants.AddItemIconName
                                                                    },
                                                                    buttonStyles: PageStyles.buttonStylesLong,
                                                                    buttonText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddAdditionResellersText", UIControlsText.AddAdditionResellersText),
                                                                    disabled: addtionalReseller !== getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText)
                                                                                || commonPPContextState.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.PendingSecondaryReview,
                                                                    onButtonClick: () => onPanelFieldClick('additionalReseller'),
                                                                },
                                                                headerText: `${getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalReseller", UIControlsText.AdditionalReseller)} ${currentAdditionalResellerIndex + 1} `,
                                                                text: text,
                                                                panelContent: panelContent(true, additionalResellerGridItems)[currentAdditionalResellerIndex],
                                                                openPanel: showAddtionalResellerPanel,
                                                                primaryButtonFields: {
                                                                    ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddAdditionResellersText", UIControlsText.AddAdditionResellersText),
                                                                    text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddAdditionResellersText", UIControlsText.AddAdditionResellersText),
                                                                    disabled: addAdditionalResellerButtonStatus && addAdditionalResellerButtonInEdit,
                                                                    style: PageStyles.panelButtonStyle,
                                                                    onClick: onAddAdditionalResellers,
                                                                },
                                                                secondaryButtonFields: {
                                                                    ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SaveAndAddNextButton", UIControlsText.SaveAndAddNextButton),
                                                                    text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SaveAndAddNextButton", UIControlsText.SaveAndAddNextButton),
                                                                    disabled: (additonalReselleraddSaveNextButtonStatus && addSaveResellerButtonInEdit) || currentAdditionalResellerIndex === 4,
                                                                    style: PageStyles.panelButtonStyle,
                                                                    onClick: onAddAdditionalResellerSaveNextClick
                                                                },
                                                                onNextButtonClick: onAdditionalNextButtonClicked,
                                                                onBackButtonClick: onAdditionalBackButtonClicked,
                                                                onCancel: onCancelButtonClicked,
                                                                onDismissed: dismissAddtionalResellerPanel,
                                                                isBackButtonDisabled: currentAdditionalResellerIndex === 0,
                                                                isNextButtonDisabled: additionalResellerGridItems.length - 1 === currentAdditionalResellerIndex,
                                                                showErrorMessage: additionalErrorFields.length > 0 && additionalResellerErrorValues().length > 0,
                                                                errorMessage: `Missing Fields: ${additionalErrorFields.join(' , ')} `,
                                                                messageBar: {
                                                                    messageBarType: MessageBarType.error,
                                                                    isMultiline: true,
                                                                    dismissButtonAriaLabel: "Close"
                                                                },
                                                                isError: additionalResellerErrorValues().length > 0,
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            visible: !JavaScriptExtensions.isEmptyOrNullOrUndefined(pivotTab?.errors?.get(StatePropertiesText.ValidateFinalOrAdditionalReseller)),
                                                            data: {
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            contolType: UIControlType.Label,
                                                                            id: "FinalOrAdditionalResellerError",
                                                                            value: pivotTab?.errors?.get(StatePropertiesText.ValidateFinalOrAdditionalReseller),
                                                                            className: 'ms-LabelValueInvalid',
                                                                            styles: DefaultPageStyles.customWidthStyleAuto
                                                                        }
                                                                    },
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: "DetailListEditableGrid",
                                                            data: {
                                                                id: "DetailsListCustomColumnsForAdditionalReseller",
                                                                styles: PageStyles.textFieldStylesOneColumn,
                                                                items: getGridDisplayItems(additionalResellerGridItems),
                                                                columns: attachFinalAdditionalResellerGridCallbacks(AdditionalResellerGridColumnConfig, PartnerType.AdditionalTierReseller),
                                                                position: 'relative',
                                                                visible: getGridDisplayItems(additionalResellerGridItems)?.length !== 0,
                                                                height: (85 + (30 * additionalResellerGridItems?.length)) + "px",
                                                                width: "100%"
                                                                //Disabled enabled based on above choice
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                type: "StackItem",
                                                data: {
                                                    id: "FinalTierResellerContainer",
                                                    styles: stackItemStyles,
                                                    visible: showChannelContentBasedOnRoleType?.FinalTierAndDistributors || showChannelContentBasedOnRoleType?.PrimaryAndFinalTierResellers,
                                                    components: [
                                                        {
                                                            type: "ChoiceGroup",
                                                            data: {
                                                                id: UIConfigIdentifierConstants.FinalTierResellersChoiceGroupId,
                                                                styles: ChoiceGroupStyles.ChoiceGroupHorizontal,
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "FinalTierResellerQuestion", UIControlsText.FinalTierResellerQuestion),
                                                                defaultSelectedKey: finalTierReseller,
                                                                options: [{ key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText), text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText) }, { key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText), text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText) }],
                                                                required: true,
                                                                selectedKey: finalTierReseller,
                                                                onChange: onFinalTierResellerChange,
                                                            }
                                                        },
                                                        {
                                                            type: "CustomViewModeComponent",
                                                            data: {
                                                                id: "LabelFinalTierResellerInformation",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "FinalTierResellerInformation", UIControlsText.FinalTierResellerInformation),
                                                                styles: PageStyles.textFieldStylesTwoColumn
                                                            }
                                                        },
                                                        {
                                                            type: "PanelFieldContainer",
                                                            data: {
                                                                invokePanelProps: {
                                                                    iconProps: {
                                                                        iconName: CssClassConstants.AddItemIconName,
                                                                        ariaLabel: CssClassConstants.AddItemIconName
                                                                    },
                                                                    buttonStyles: PageStyles.buttonStylesLong,
                                                                    buttonText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddFinalTier", UIControlsText.AddFinalTier),
                                                                    disabled: finalTierReseller != getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText)
                                                                                || commonPPContextState.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.PendingSecondaryReview,
                                                                    onButtonClick: () => onPanelFieldClick('finalTierReseller'),
                                                                },
                                                                headerText: `${getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "FinalTierReseller", UIControlsText.FinalTierReseller)} ${currentFinalTierIndex + 1} `,
                                                                panelContent: panelContent(false, finalTierResellerGridItems)[currentFinalTierIndex],
                                                                openPanel: showFinalResellerPanel,
                                                                primaryButtonFields: {
                                                                    ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddFinalTierResellers", UIControlsText.AddFinalTierResellers),
                                                                    text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddFinalTierResellers", UIControlsText.AddFinalTierResellers),
                                                                    disabled: addFinalResellerButtonStatus && addFinalResellerButtonInEdit,
                                                                    style: PageStyles.panelButton,
                                                                    onClick: onAddFinalTierResellers,
                                                                },
                                                                secondaryButtonFields: {
                                                                    ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SaveAndAddNextButton", UIControlsText.SaveAndAddNextButton),
                                                                    text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SaveAndAddNextButton", UIControlsText.SaveAndAddNextButton),
                                                                    disabled: (addSaveNextButtonStatus && addSaveFinalButtonInEdit) || currentFinalTierIndex === 1,
                                                                    onClick: onAddSaveNextClick,
                                                                    style: PageStyles.panelButton
                                                                },
                                                                onNextButtonClick: onNextClicked,
                                                                onBackButtonClick: onBackClicked,
                                                                onCancel: onCancelClick,
                                                                onDismissed: dismissFinalResellerPanel,
                                                                isBackButtonDisabled: currentFinalTierIndex === 0,
                                                                isNextButtonDisabled: finalTierResellerGridItems.length - 1 === currentFinalTierIndex,
                                                                showErrorMessage: errorFields.length > 0 && finalTierErrorValues().length > 0,
                                                                errorMessage: `Missing Fields: ${errorFields.join(' , ')} `,
                                                                messageBar: {
                                                                    messageBarType: MessageBarType.error,
                                                                    isMultiline: true,
                                                                    dismissButtonAriaLabel: "Close"
                                                                },
                                                                isError: finalTierErrorValues().length > 0,
                                                            }
                                                        },
                                                        {
                                                            type: "DetailListEditableGrid",
                                                            data: {
                                                                id: "DetailsListCustomColumnsForFinalTierReseller",
                                                                styles: PageStyles.textFieldPanelWidth,
                                                                items: getGridDisplayItems(finalTierResellerGridItems),
                                                                columns: attachFinalAdditionalResellerGridCallbacks(FinalTierResellerGridColumnConfig, PartnerType.FinalTierReseller),
                                                                visible: getGridDisplayItems(finalTierResellerGridItems)?.length !== 0,
                                                                height: (85 + (30 * finalTierResellerGridItems?.length)) + "px",
                                                                width: "100%"
                                                                //Disabled enabled based on above choice
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        {
            type: 'CustomTileCardComponent',
            data: {
                id: 'DocCardContainerForDistributor',
                baseState: commonPPContextState,
                name: "Distributor Details",
                'data-nextgen-parent-group': "Create - Distributor Details",
                'data-nextgen-parentid': "Create - Distributor Details",
                iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DistributorIcon", UIControlsText.DistributorIcon),
                visible: showChannelContentBasedOnRoleType?.MultipleDistributorsOnly || showChannelContentBasedOnRoleType?.FinalTierAndDistributors || showChannelContentBasedOnRoleType?.AdditionalAndDistributors,
                components: [
                    {
                        type: 'Stack',
                        data: {
                            id: 'ChannelStackForDisti',
                            visible: showChannelContentBasedOnRoleType?.MultipleDistributorsOnly || showChannelContentBasedOnRoleType?.FinalTierAndDistributors || showChannelContentBasedOnRoleType?.AdditionalAndDistributors,
                            styles: stackItemStyles,
                            align: "auto",
                            tokens: smallAlignmentTokens,
                            verticalAlign: "start",
                            horizontal: true,
                            components: [
                                //Distributor Grid
                                {
                                    type: 'Stack',
                                    data: {
                                        id: 'StackItemForAddChannelRoleBasedContentForDistributor',
                                        styles: stackItemStyles,
                                        align: "auto",
                                        tokens: smallAlignmentTokens,
                                        components: [
                                            {
                                                type: "GuidanceTextInformation",
                                                visible: loggedInUserRole !== UserSubRole.ADD,
                                                data: {
                                                    id: "GuidanceTextForDistributor",
                                                    label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "GuidanceTextForDistributor", UIControlsText.GuidanceTextForDistributor),
                                                    styles: DefaultPageStyles.customWidthStyleAuto
                                                }
                                            },
                                            {
                                                type: "PanelTableContainer",
                                                data: {
                                                    invokePanelProps: {
                                                        iconProps: {
                                                            iconName: CssClassConstants.AddItemIconName,
                                                            ariaLabel: CssClassConstants.AddItemIconName
                                                        },
                                                        buttonStyles: PageStyles.buttonStylesLong,
                                                        disabled: loggedInUserRole === UserSubRole.ADD || commonPPContextState.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.PendingSecondaryReview,
                                                        className: "me-2",
                                                        buttonText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddDistributors", UIControlsText.AddDistributors),
                                                        onButtonClick: () => onPanelClick('distributor'),
                                                    },
                                                    headerText: GridType.DistributorList,
                                                    sortingDataType: 'name',
                                                    propertyName: 'name',
                                                    messageBar: {
                                                        messageBarType: MessageBarType.error,
                                                        isMultiline: false,
                                                        dismissButtonAriaLabel: "Close"
                                                    },
                                                    panelType: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Distributor", UIControlsText.Distributor),
                                                    errorMessage: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DistributorNameErrorMessage", UIControlsText.DistributorNameErrorMessage) || '',
                                                    onItemSelection: onResellerOrDistributorChange,
                                                    onClearAll: onClearAll,
                                                    tableConfigurationFields: distributorTableConfigurations,
                                                    selectionType: GridSelectionType.Mulitple,
                                                    columnDefinitions: distributorColumnDefinitions,
                                                    tableContent: distributors.current && distributors.current.length > 0 && distributors.current.map((pt: any) => ({
                                                        key: `${pt.accountId}-${pt.partnerType}`, id: pt.accountId, name: pt.companyName, country: pt.countryName, countryId: pt.countryId, organizationId: pt.organizationId, partnerType: pt.partnerType,
                                                        editMode: false
                                                    })) || [],
                                                    countrySelected: selectedDistributorCountry,
                                                    selectedItems: selectedDistributors && selectedDistributors.map(x => ({
                                                        key: `${x.accountId}-${x.partnerType}`, id: x.accountId, name: x.companyName, country: x.countryName, countryId: x.countryId, organizationId: x.organizationId, partnerType: x.partnerType, action: x.action,
                                                        editMode: isEditableRow(selectedDistributorCountry, commonPPContextState.pageMode, currentDealStatusCode, x, x.partnerType)
                                                    })) || [],
                                                    disableSelectAllSelection: false,
                                                    primaryButtonProps: {
                                                        ariaLabel: 'AddDistributor',
                                                        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddDistributorText", UIControlsText.AddDistributorText),
                                                        onClick: () => onAddButtonClicked(false),
                                                        style: PageStyles.panelButtonStyle
                                                    },
                                                    onDefaultButtonClick: onClearAll,
                                                    PageMode: commonPPContextState.pageMode,
                                                    footerTextField: `${selectedDistributors && selectedDistributors.length} Distributors selected`,
                                                }
                                            },
                                            {
                                                type: 'StackItem',
                                                visible: !JavaScriptExtensions.isEmptyOrNullOrUndefined(pivotTab?.errors?.get(StatePropertiesText.ValidateMultipleDistributors)),
                                                data: {
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                contolType: UIControlType.Label,
                                                                id: "ValidateMultipleDistributorError",
                                                                value: pivotTab?.errors?.get(StatePropertiesText.ValidateMultipleDistributors),
                                                                className: 'ms-LabelValueInvalid',
                                                                styles: DefaultPageStyles.customWidthStyleAuto
                                                            }
                                                        },
                                                    ]
                                                }
                                            },
                                            //Distributor Grid
                                            {
                                                type: 'StackItem',
                                                data: {
                                                    id: 'StackItemForDistributorGrid',
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "DetailListEditableGrid",
                                                            data: {
                                                                id: "DetailsListCustomColumnsForDistributor",
                                                                key: "DetailsListCustomColumnsForDistributor",
                                                                styles: PageStyles.textFieldStylesOneColumn,
                                                                items: distributorListGridItems,
                                                                columns: attachDistributorCallbacks(DistributorGridColumnConfig),
                                                                gridConfigOptions: { enableCellEdit: true },
                                                                visible: distributorListGridItems?.length > 0,
                                                                width: "885px",
                                                                height: (120 + (45 * distributorListGridItems?.length)) + "px",
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
    ];

    return ChannelJson;
}