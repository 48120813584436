import { ActionButton, DefaultButton, Dropdown, IDropdownOption, IStackTokens, Label, MessageBar, PrimaryButton, Spinner, Stack, TextField } from "@fluentui/react";
import { SetStateAction, useEffect, useRef, useState } from "react";
import Reseller from "../../../../components/shared/reseller";
import { ErrorMessages } from "../../../../content/ErrorMessages";
import { defaultColumnProps, DefaultPageStyles, _Styles } from "../../../../content/styles/Page.styles";
import { UIControlsText } from "../../../../content/UIControlsText";
import { Guid } from "../../../../infrastructure/Guid";
import { JavaScriptExtensions } from "../../../../infrastructure/JavaScriptExtensions";
import { ChannelStructure } from "../../../../models/ChannelStructure";
import { IAccount } from "../../../../models/IAccount";
import { SessionStorageConsts } from "../../../../models/SessionStorageConsts";
import { nextGenService } from "../../../../services/NextGenService";
import { useInternalPricingContext } from "../context/internalpricing-context";
import { PageStyles } from "../../common/content/Page.Styles";
import { CaseActionTypes } from "../../common/reducer/CaseActionTypes";
import { IAttachment } from "../../../../models/IAttachment";
import Attachment from "../../../../components/shared/attachment";
import { IOpportunityAccount } from "../../../../models/IOpportunityAccount";
import { ArrayExtensions } from "../../../../infrastructure/ArrayExtensions";
import { PartnerType } from "../../../../models/PartnerType";
import { ActionType } from "../../../../models/ActionType";
import { CssClassConstants } from "../../../../content/CssClassConstants";
import { ICountry } from "../../../../models/ICountry";
import { Grid, GridCellProps, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { ChannelStructureOptions, partnerTypeValues } from "../../../../services/StaticData";
import ChannelAccounts from "./cp-strategic-channel-accounts";
import { IBaseProps } from "../../common/props/IBaseProps";
import { AccountAllType } from "../../../../models/AccountAllType";
import { mergeStyles } from "@uifabric/merge-styles";
import { PageMode } from "../../../../models/PageMode";
import { DropDownGridCell } from "../../../../components/shared/grid/dropDownGridCell";
import _ from "lodash";
import { getsavedContext, isFieldDisabled, isFieldVisible, getLoggedinUserRoleDetails, getPartnerAction, getParentAccountAction, isPartnerAccount, isRoleBasedFilterRequired, hideResellerSectionsInViewModeForReseller, addExportExcelHeader, getUserRole, getURLParamValue, getRuleValidationMessage, getEntityDataFromSession } from "../../../../components/shared/common/util";
import { IUserRoleDetail } from "../../../../models/IUserRoleDetail";
import { UserSubRole } from "../../../../models/UserSubRole";
import { OpportunityDealStatusCodes } from "../../../../models/OpportunityDealStatusCodes";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { UserRole } from "../../../../models/UserRole";
import { PartnerTypeLabel } from "../../../../models/PartnerTypeLabel";
import { ApplicationConstants } from "../../../../models/ApplicationConstants";
import { useHistory } from "react-router";
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { useToHandleSettingViewResellerNavState as mdppaccessibilitySetNavForFocus, MdppAccessibilityLink as Link } from "../../../../hooks/accessiblity-hooks";
import { PageConstants } from "../../../pageconstants/Constants";
import { expireDialog, extendDialogButtonStyles, stackButtonStyles, stackStyles } from '../../../../content/styles/Page.styles';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ChannelSubType } from "../../../../models/ChannelSubType";
const stackTokens: IStackTokens = { childrenGap: 24 };
/**
 * CP Strategic Channel Component.
 * @function component
 */
const CPStrategicChannel: React.FunctionComponent<IBaseProps> = (props: IBaseProps) => {
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const { internalPricingContextState, internalPricingContextDispatch } = useInternalPricingContext();
    const msStoreClass = mergeStyles({ width: "85px" });
    const getInitialAccountsState = (structureChange: boolean, partnerType: PartnerType, isComplete: boolean, accountAllType?: AccountAllType): IOpportunityAccount[] => {
        let oppAccounts: IOpportunityAccount[] = [{ id: Guid.newGuid() }];
        if (!structureChange) { //Append only if channel structure is not changed.
            if (partnerType === PartnerType.Reseller || partnerType === PartnerType.ADR) {
                oppAccounts = [...resellers, ...msStores];
            }
            else if (partnerType === PartnerType.ADD) {
                oppAccounts = [...distributors];
            }
        }

        oppAccounts = oppAccounts.filter(a => internalPricingContextState.opportunityDeals[0].geoCountries && internalPricingContextState.opportunityDeals[0].geoCountries?.filter(c => c.action !== ActionType.Delete).map(c => c.countryId).indexOf(a?.countryId as string) >= 0);

        let newOppAccounts = (internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].geoCountries
            && internalPricingContextState.opportunityDeals[0].geoCountries.filter(c => c.action !== ActionType.Delete).filter(x => oppAccounts.map(a => a.countryId).indexOf(x.countryId) === -1).map(x =>
            ({
                id: Guid.newGuid(),
                partnerType,
                countryId: x.countryId,
                isAllSelected: accountAllType ? accountAllType : (x.isEUEFTA ? AccountAllType.AllEuefta : AccountAllType.All),
                isComplete,
                applicablePriceListGeo: x.applicablePriceListGeo,
            } as IOpportunityAccount)));

        if (newOppAccounts && newOppAccounts.length > 0) {
            oppAccounts = oppAccounts.concat(newOppAccounts);
        }

        return oppAccounts.filter(x => x.partnerType !== PartnerType.MsStore);
    }
    
    const stackButtonTokens: IStackTokens = { childrenGap: 16 };
    const [oppdealId, setOppdealId] = useState<string>((internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].id) || "");
    let history = useHistory();
    /**
     * Get TDistributor name from session storage.
     * @method
     * @@returns {IAccount[]} List of Distributor Accounts.
     */
    const getDistributorAccounts = (): IAccount[] => {
        var allDistributors = sessionStorage.getItem(SessionStorageConsts.distributors);
        if (allDistributors !== null) {
            return JSON.parse(allDistributors);
        }

        return [];
    };

    const [productTypeId, setProductTypeId] = useState<string>("");
    const [distributors, setDistributors] = useState<IOpportunityAccount[]>([]);
    const [resellers, setResellers] = useState<IOpportunityAccount[]>([]);
    const [msStores, setMsStores] = useState<IOpportunityAccount[]>([]);
    const [resellerChannelAccounts, setResellerChannelAccounts] = useState<IOpportunityAccount[]>([]);
    const [availableCountries, setAvailableCountries] = useState<ICountry[]>([]);
    const [isChannelInfoVisible, setIsChannelInfoVisible] = useState<boolean>((internalPricingContextState.opportunityDeals[0] && internalPricingContextState.opportunityDeals[0].channelStructure) ? (internalPricingContextState.opportunityDeals[0].channelStructure !== ChannelStructure.Skip) : false);
    const [isCasToReseller, setCasToReseller] = useState<boolean>((internalPricingContextState.opportunityDeals[0] && internalPricingContextState.opportunityDeals[0].channelStructure) ? internalPricingContextState.opportunityDeals[0].channelStructure === ChannelStructure.Customer : false);
    const [isChannelJustificationVisible, setChannelJustificationVisible] = useState<boolean>(false);
    const [isAttachmentVisible, setAttachmentVisible] = useState<boolean>(false);
    const [isAttachmentRequired, setAttachmentRequired] = useState<boolean>(true);
    const [isSkipReasoningVisible, setIsSkipReasoningVisible] = useState<boolean>((internalPricingContextState.opportunityDeals[0] && internalPricingContextState.opportunityDeals[0].channelStructure) ? internalPricingContextState.opportunityDeals[0].channelStructure === ChannelStructure.Skip : false);
    const resellerAccounts = useRef<IAccount[]>([]);
    const [distributorAccounts] = useState<IAccount[]>(getDistributorAccounts());
    const [attachmentList, setAttachmentList] = useState<Array<IAttachment | undefined>>(internalPricingContextState.attachments?.filter(x => x?.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)) || []);
    const [isResellerLoading, setIsResellerLoading] = useState<boolean>(false);
    const userRole = sessionStorage.getItem(SessionStorageConsts.userRole) || "";
    const [addtionalADRIdList, setAddtionalADRIdList] = useState<Array<string>>([]);
    const [oppAccountAvailableCountries, setOppAccountAvailableCountries] = useState<ICountry[]>([]);
    const [partnerAccounts, setPartnerAccounts] = useState<{ [key: string]: IAccount[]; }>();
    const [invalidResellers, setInvalidResellers] = useState<string>("");
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        async function getResellers(countries: ICountry[]) {
            setIsResellerLoading(true);

            //Remove resellers when the countries are removed.
            resellerAccounts.current = resellerAccounts.current.filter(r => r.productTypeId === productGroup).filter(r => countries.map(c => c.countryId).indexOf(r.countryId as string) >= 0);

            //Newly selected countries
            let newCountries = countries.filter(c => resellerAccounts.current.filter(r => r.productTypeId === productGroup).map(r => r.countryId).indexOf(c.countryId) === -1);
            let count = newCountries.length;

            if (newCountries.length === 0) {
                setIsResellerLoading(false);
            }

            //Get resellers for newly selected countries
            newCountries.forEach(async c => {
                let response = await nextGenService.getAllActiveResellersByProductCountriesAndType(productGroup, new Array(c.countryId));
                count--;
                if (count === 0) {
                    setIsResellerLoading(false);
                }

                if (response.status === 200) {
                    resellerAccounts.current = resellerAccounts.current.concat(response.data);
                    resellerAccounts.current = _.uniqBy(resellerAccounts.current, v => [v.accountId, v.partnerType].join());
                }
            });
        }
        let selectedGeoCountries = (internalPricingContextState.opportunityDeals && internalPricingContextState?.opportunityDeals[0]?.geoCountries && internalPricingContextState?.opportunityDeals[0]?.geoCountries.filter(c => c.action !== ActionType.Delete)) || [];
        let userRelatedCountries: SetStateAction<ICountry[]> = [];
        let userCountries: SetStateAction<ICountry[]> = [];
        if (selectedGeoCountries.length > 0) {
            let countries = props.countries.filter(x => selectedGeoCountries.filter(y => y.countryId == x.countryId).length > 0);
            if (isRoleBasedFilterRequired(userRole || "")) {
                const userRoleDetails: IUserRoleDetail[] = getLoggedinUserRoleDetails();
                const userCountriesIds = userRoleDetails.map(function (userRoleDetail) { return userRoleDetail.countryId }).join(',');
                userCountries = countries.filter(country => userCountriesIds.includes(country.countryId));
                if (userRole === UserSubRole.Reseller) {
                    if (internalPricingContextState.opportunityDeals[0].channelStructure === ChannelStructure.Customer) {
                        userRelatedCountries = countries.filter(country => internalPricingContextState?.opportunityAccounts?.find(({ countryId, partnerType, partnerAccounts }) => (countryId === country.countryId && (partnerType === PartnerType.ADD || Object.keys(partnerAccounts || []).length > 0))));

                        const resellerAccountContries = countries.filter(country => internalPricingContextState?.opportunityAccounts?.find(({ countryId }) => (countryId === country.countryId)));
                        setOppAccountAvailableCountries(resellerAccountContries);
                    }
                }
                else if (userRole === UserSubRole.ADD) {
                    userRelatedCountries = countries.filter(country => internalPricingContextState?.opportunityAccounts?.find(({ countryId, partnerType }) => (countryId === country.countryId && (partnerType === PartnerType.ADR || partnerType === PartnerType.DMP || partnerType === PartnerType.Reseller))));
                    setOppAccountAvailableCountries(userRelatedCountries);
                }
                else {
                    userRelatedCountries = userCountries;
                    setOppAccountAvailableCountries(userRelatedCountries);
                }
                sessionStorage.setItem(SessionStorageConsts.userCountries, JSON.stringify(userCountries.map(country => country.countryId).join(',')));
            }
            else {
                userRelatedCountries = countries;
                sessionStorage.setItem(SessionStorageConsts.userCountries, JSON.stringify(countries));
                setOppAccountAvailableCountries(userRelatedCountries);
            }
        }

        let productGroup = internalPricingContextState?.productGroup || "";
        setProductTypeId((prevState: string) => {
            if (prevState !== productGroup) {
                if (isChannelInfoVisible) {
                    initializeAccounts(true);
                }
            }

            return productGroup;
        });
        setAvailableCountries(userRelatedCountries);
        if (internalPricingContextState?.opportunityDeals[0]?.id) {
            setOppdealId(internalPricingContextState?.opportunityDeals[0]?.id);
        }

        if (isChannelInfoVisible) {
            initializeAccounts(false);
        }

        if (JavaScriptExtensions.isDefined(productGroup) && selectedGeoCountries.length > 0) {
            let countries = props.countries.filter(x => selectedGeoCountries.filter(y => y.countryId == x.countryId).length > 0);
            let isEueftaCountrySelected = countries.some(x => x.isEUEFTA);
            let eueftaCountries = isEueftaCountrySelected ? props.countries.filter(x => x.isEUEFTA) : [];
            let resellerContries = props.countries.filter(x => selectedGeoCountries.filter(y => y.applicablePriceListGeo === x.applicablePriceListGeo).length > 0);

            if (eueftaCountries.length > 0) {
                resellerContries = ArrayExtensions.mergeArrays(resellerContries, eueftaCountries);
            }

            if (internalPricingContextState.pageMode === PageMode.Edit || internalPricingContextState.pageMode === PageMode.Create)
            {
                getResellers(resellerContries);
            }            
        }

        function initializeAccounts(structureChange: boolean) {
            if (isCasToReseller) {
                setResellers(getInitialAccountsState(structureChange, PartnerType.ADR, false, AccountAllType.None));
                setMsStores(msStores.filter(ms => selectedGeoCountries.map(c => c.countryId).includes(ms.countryId || "")));
            }
            else {
                setResellers(getInitialAccountsState(structureChange, PartnerType.Reseller, true));
                setDistributors(getInitialAccountsState(structureChange, PartnerType.ADD, true));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalPricingContextState?.opportunityDeals && internalPricingContextState?.opportunityDeals[0]?.geoCountries, internalPricingContextState?.opportunityDeals[0]?.id, internalPricingContextState?.productGroup]);

    useEffect(() => {
        if (internalPricingContextState.opportunityAccounts && internalPricingContextState.opportunityAccounts?.length > 0) {
            //update iscompleted flag to avoid validation error on edit
            if (internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()) {
                internalPricingContextState.opportunityAccounts.map(oppAccount => {
                    if ((oppAccount.isAllSelected === AccountAllType.None && oppAccount?.accounts && oppAccount.accounts?.length > 0) ||
                        oppAccount.isAllSelected !== AccountAllType.None ||
                        oppAccount.partnerType === PartnerType.MsStore) {
                        oppAccount.isComplete = true;
                    }
                });
                internalPricingContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: internalPricingContextState.opportunityAccounts })
            }
            if (internalPricingContextState.opportunityDeals[0].channelStructure === ChannelStructure.Customer) {
                setResellers(internalPricingContextState.opportunityAccounts.filter(acc => acc.partnerType === PartnerType.ADR || acc.partnerType === PartnerType.DMP));
                if (userRole == UserSubRole.Reseller && internalPricingContextState.pageMode?.toLowerCase() !== PageMode.Create.toLowerCase()) {
                    setDistributors(internalPricingContextState.opportunityAccounts.filter(acc => acc.partnerType === PartnerType.ADD));
                }
            }
            else {
                setResellers(internalPricingContextState.opportunityAccounts.filter(acc => acc.partnerType === PartnerType.Reseller || acc.partnerType === PartnerType.ADR || acc.partnerType === PartnerType.DMP));
                setDistributors(internalPricingContextState.opportunityAccounts.filter(acc => acc.partnerType === PartnerType.ADD));
            }

            if (internalPricingContextState.opportunityDeals[0].channelStructure === ChannelStructure.Customer
                && internalPricingContextState.opportunityAccounts.some(acc => acc.partnerType === PartnerType.ADR)
                && !internalPricingContextState.opportunityAccounts.some(acc => acc.partnerType === PartnerType.DMP)) {
                setChannelJustificationVisible(false);
                setAttachmentVisible(true);
            }
            else if (internalPricingContextState.opportunityDeals[0].channelStructure === ChannelStructure.Customer
                && internalPricingContextState.opportunityAccounts.some(acc => acc.partnerType === PartnerType.DMP)) {
                let modifiedPartners = internalPricingContextState.opportunityAccounts
                    .filter(x => availableCountries.map(c => c.countryId).indexOf(x.countryId as string) >= 0)
                    .filter(x => x.accounts && x.accounts.length > 0 && x.partnerType === PartnerType.DMP)
                    .map((value: IOpportunityAccount) => {
                        return value.accounts?.some((account: IAccount) =>
                        (value?.availableRelatedAccounts && value?.availableRelatedAccounts[account.accountId]
                            && value?.partnerAccounts && value?.partnerAccounts[account.accountId]
                            && value?.availableRelatedAccounts[account.accountId].length !== value?.partnerAccounts[account.accountId].length)
                        );
                    });

                if (modifiedPartners.some(x => x && x === true)) {
                    setChannelJustificationVisible(true);
                    setAttachmentVisible(true);
                }
                else {
                    setChannelJustificationVisible(false);
                    setAttachmentVisible(true);
                }
            }
            else if (internalPricingContextState.opportunityDeals[0].channelStructure === ChannelStructure.Distributor) {
                setChannelJustificationVisible(true);
                setAttachmentRequired(false);
                if (internalPricingContextState.opportunityAccounts.some(acc => acc.isAllSelected === AccountAllType.None && acc.partnerType !== PartnerType.MsStore)) {
                    setAttachmentVisible(true);
                }
                else {
                    setAttachmentVisible(false);
                }
            }
            else if (internalPricingContextState.opportunityDeals[0].channelStructure === ChannelStructure.Skip) {
                setChannelJustificationVisible(false);
                setAttachmentVisible(false);
            }

            let msStore = internalPricingContextState.opportunityAccounts.filter(acc => acc.partnerType === PartnerType.MsStore);
            if (msStore && msStore.length > 0) {
                msStore[0].isComplete = true;
            }
            setMsStores(msStore);
            setIsChannelInfoVisible(internalPricingContextState.opportunityDeals[0].channelStructure !== ChannelStructure.Skip);
            setCasToReseller(internalPricingContextState.opportunityDeals[0].channelStructure === ChannelStructure.Customer);

            if (internalPricingContextState.opportunityDeals[0].channelStructure === ChannelStructure.Customer) {
                let dmpAccounts: string[] = [];
                let relatedAccounts: [{ [key: string]: IAccount[]; }] = [{}];
                internalPricingContextState?.opportunityAccounts?.filter(opp => opp.partnerType === PartnerType.DMP).map(acc => acc?.accounts?.map(ac => dmpAccounts.push(ac.accountId)));
                internalPricingContextState?.opportunityAccounts?.filter(opp => opp.partnerType === PartnerType.DMP && opp.availableRelatedAccounts).forEach(acc => acc.availableRelatedAccounts && relatedAccounts.push(acc.availableRelatedAccounts));
                let dmpsWithParters: string[] = [];
                relatedAccounts.forEach(acc => Object.entries(acc).map(x => dmpsWithParters.push(x[0])))

                let newDMPs = dmpAccounts.filter(x => !dmpsWithParters.includes(x));

                if (newDMPs.length > 0) {
                    nextGenService.getAddsAccountsByDmpAccountIds(internalPricingContextState?.productGroup || "", _.uniq(newDMPs) || [])
                        .then((response) => {
                            let accounts: { [key: string]: IAccount[]; } = JSON.parse(JSON.stringify(response.data));

                            newDMPs.forEach(dmp => {
                                if (accounts[dmp]) {
                                    accounts[dmp] = accounts[dmp].map(ac => { return { ...ac, partnerType: PartnerType.ADD } });
                                }
                                else {
                                    accounts[dmp] = [];
                                }
                            })
                            setPartnerAccounts(accounts);
                            let oppAccounts = internalPricingContextState.opportunityAccounts;
                            oppAccounts && oppAccounts.filter(opp => opp.partnerType === PartnerType.DMP)
                                .map(acc => acc.accounts?.map(x => {
                                    if (accounts.hasOwnProperty(x.accountId)) {
                                        let relatedAccount = accounts[x.accountId];
                                        if (relatedAccount && relatedAccount.length) {
                                            if (!acc.availableRelatedAccounts) {
                                                acc.availableRelatedAccounts = {};
                                            }

                                            acc.availableRelatedAccounts[x.accountId] = relatedAccount;
                                        }
                                    }
                                }));

                            internalPricingContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: oppAccounts });
                        });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalPricingContextState.opportunityAccounts]);


    useEffect(() => {
        if (internalPricingContextState.attachments && internalPricingContextState.attachments.length > 0) {
            setAttachmentList(internalPricingContextState.attachments?.filter(x => x?.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)));
        }

    }, [internalPricingContextState.attachments]);

    useEffect(() => {
        internalPricingContextDispatch({ type: CaseActionTypes.channelJustificationRequiredChanged, dealBoolValue: { key: oppdealId, value: isChannelJustificationVisible } })
    }, [isChannelJustificationVisible]);

    //Accessibility fix to set role of button
    useEffect(() => {
        accessibilityHook();
    });
    function accessibilityHook() {
        var gridCell = document.getElementsByClassName('ms-Link ps-2');
        for (let i = 0; i < gridCell.length; i++) {
            gridCell[i].setAttribute('role', 'link');
        }
    }

    /**
     * Get TextField ErrorMessage.
     * @method
     * @param {string} value Text Box value.
     * @@returns {string} Error Message.
     */
    const getTextFieldErrorMessage = (value: string): string => {
        return internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase() ? '' :
            !value ? getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredFieldError", ErrorMessages.RequiredFieldError) : '';
    };

    /**
     * ChannelOverview change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {string} newValue updated value from textField.
     */
    const onChannelOverviewChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        internalPricingContextDispatch({ type: CaseActionTypes.channelOverviewInputChanged, dealInputValue: { key: oppdealId, value: newValue } })
    }

    /**
     * ChannelJustification change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {string} newValue updated value from textField.
     */
    const onChannelJustificationChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        internalPricingContextDispatch({ type: CaseActionTypes.channelJustificationInputChanged, dealInputValue: { key: oppdealId, value: newValue } })
    }

    /**
     * ChannelStructure change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {IDropdownOption} item Clicked Option from Dropdown.
     */
    const onChannelStructureChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            switch (item.key) {
                case ChannelStructure.Skip:
                    setIsChannelInfoVisible(false);
                    setIsSkipReasoningVisible(true);
                    setAttachmentVisible(false);
                    setChannelJustificationVisible(false);
                    setCasToReseller(false);
                    setDistributors([]);
                    internalPricingContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: [] });
                    break;
                case ChannelStructure.Customer:
                    setIsChannelInfoVisible(true);
                    setAttachmentRequired(true);
                    setAttachmentVisible(true);
                    setChannelJustificationVisible(false);
                    setIsSkipReasoningVisible(false);
                    setCasToReseller(true);
                    setResellers(getInitialAccountsState(true, PartnerType.ADR, false, AccountAllType.None))
                    setDistributors([]);
                    setMsStores([]);
                    internalPricingContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: resellers });
                    break;
                case ChannelStructure.Distributor:
                    setChannelJustificationVisible(true);
                    setIsChannelInfoVisible(true);
                    setIsSkipReasoningVisible(false);
                    setCasToReseller(false);
                    setMsStores([]);
                    let resellersToAdd = getInitialAccountsState(true, PartnerType.Reseller, true);
                    setResellers(resellersToAdd);
                    let addsToAdd = getInitialAccountsState(true, PartnerType.ADD, true);
                    setDistributors(addsToAdd);
                    internalPricingContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: resellersToAdd.concat(addsToAdd) });
                    break;
            }
        }

        internalPricingContextDispatch({ type: CaseActionTypes.channelStructureChanged, dealDropDownValue: { key: oppdealId, value: item?.key } });
    }

    /**
     * Attachment change Callback function.
     * @param {Array<IAttachment | undefined>} attachmentList Updated list of attachments 
     */
    const handleAttachmentChanges = (attachmentList: Array<IAttachment | undefined>): void => {
        setAttachmentList(attachmentList.filter(item => item?.action !== ActionType.Delete));
        let otherAttachments = internalPricingContextState.attachments?.filter(x => x?.source !== getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)) || [];
        internalPricingContextDispatch({ type: CaseActionTypes.attachmentChanged, attachments: ArrayExtensions.mergeArrays(otherAttachments, attachmentList) });
    }

    /**
    * Add/Update accounts entry Callback function.
    * @method
    * @param {IOpportunityAccount} accounts updated accounts details.
    * @param {ActionType} actiontype Action to perform.
    */
    const updateAccounts = (accounts: IOpportunityAccount[], actiontype?: ActionType): void => {
        let newResellerList = resellers;
        let newDistiList = [...distributors];
        let newMsStoreList = [...msStores];
        switch (actiontype) {
            case ActionType.Update:
                {
                    if (accounts.length) {
                        var distiAccount = accounts.find(x => x !== undefined && x.partnerType === PartnerType.ADD);
                        if (distiAccount) {
                            if (distiAccount.isAllSelected != AccountAllType.None) {
                                distiAccount.action = getParentAccountAction(distiAccount?.countryId, PartnerType.ADD);
                            }
                            else {
                                distiAccount.action = '';
                            }

                            let idx = newDistiList.findIndex((item) => item.id === distiAccount?.id);
                            if (idx >= 0 && distiAccount) {
                                newDistiList[idx] = distiAccount;
                            }
                        }

                        var resellerAccount = accounts.find(x => x !== undefined && (x.partnerType === PartnerType.Reseller || x.partnerType === PartnerType.ADR || x.partnerType === PartnerType.DMP));
                        if (resellerAccount) {
                            if (resellerAccount.isAllSelected != AccountAllType.None) {
                                resellerAccount.action = getParentAccountAction(resellerAccount?.countryId, PartnerType.Reseller);
                            }
                            else {
                                resellerAccount.action = '';
                            }

                            let idx = newResellerList.findIndex((item) => item.id === resellerAccount?.id);
                            if (idx >= 0) {
                                newResellerList[idx] = resellerAccount;
                            }
                            else {
                                //In ChannelStructure.Customer, if dropdown is changed from MSStore to pther partner type 
                                newResellerList.push(resellerAccount);
                                newMsStoreList = newMsStoreList.filter(item => item.id !== resellerAccount?.id);
                            }
                        }

                        var msStoreAccount = accounts.find(x => x !== undefined && x.partnerType === PartnerType.MsStore);
                        newMsStoreList = newMsStoreList.filter(item => item.id !== msStoreAccount?.id);
                        if (msStoreAccount && msStoreAccount.isComplete) {
                            //In ChannelStructure.Customer, if dropdown is changed from pther partner type to MSStore 
                            newMsStoreList.push(msStoreAccount);
                            newResellerList = newResellerList.filter(x => x.id !== msStoreAccount?.id);
                        }
                        else if (msStoreAccount && !msStoreAccount.isComplete) {
                            newMsStoreList = newMsStoreList.filter((item) => item.id !== msStoreAccount?.id);
                        }
                    }
                    break;
                }

            case ActionType.Delete:
                {
                    if (accounts.length) {
                        newResellerList = newResellerList.filter((item) => item.id !== accounts[0]?.id);
                        newMsStoreList = newMsStoreList.filter((item) => item.id !== accounts[0]?.id);
                        break;
                    }
                }
        }

        setResellers(newResellerList);
        setDistributors(() => [...newDistiList]);
        setMsStores(() => [...newMsStoreList]);
        internalPricingContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: [...newResellerList, ...newDistiList, ...newMsStoreList] })
    };

    let listItems: any = [];
    if (isChannelInfoVisible && isCasToReseller
        && (internalPricingContextState.opportunityAccounts && internalPricingContextState.opportunityAccounts.length > 0)
    ) {
        const opportunityAvailablecountries = internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase() ? oppAccountAvailableCountries : availableCountries;
        const countries: ICountry[] = JSON.parse(sessionStorage.getItem(SessionStorageConsts.countries) || "{}");
        internalPricingContextState.opportunityAccounts
            .filter(x => opportunityAvailablecountries.map(c => c.countryId).indexOf(x.countryId as string) >= 0)
            .forEach((value: IOpportunityAccount) => {
                if (value.partnerType === PartnerType.MsStore) {
                    let item: any = {
                        key: value.countryId,
                        countryName: countries.find(x => x.countryId === value.countryId)?.countryName,
                        countryId: countries.find(x => x.countryId === value.countryId)?.countryId,
                        country: countries.find(x => x.countryId === value.countryId),
                        partnerType: partnerTypeValues[`${value.partnerType}`],
                        resellerName: partnerTypeValues[`${value.partnerType}`],
                        availableRelatedAdds: [],
                        selectedRelatedAdds: []
                    }
                    listItems.push(item);
                }
                if (value.partnerType !== PartnerType.MsStore) { //MSStore has accounts in View Mode 
                    value.accounts?.forEach((account: IAccount) => {
                        let availableRelatedAccounts = value?.availableRelatedAccounts && value?.availableRelatedAccounts[account.accountId];
                        let selectedRelatedAccounts = value?.partnerAccounts && value?.partnerAccounts[account.accountId];
                        selectedRelatedAccounts = selectedRelatedAccounts?.map((acc: IAccount) => { return { ...acc, action: getPartnerAction(acc, value.countryId, PartnerType.DMP, account.accountId) } });
                        {
                            let item: any = {
                                key: value.countryId + "_" + account.accountId + "_relatedAdds",
                                countryName: countries.find(x => x.countryId === value.countryId)?.countryName,
                                countryId: countries.find(x => x.countryId === value.countryId)?.countryId,
                                country: countries.find(x => x.countryId === value.countryId),
                                partnerType: partnerTypeValues[`${value.partnerType}`],
                                resellerName: account.companyName,
                                accountId: account.accountId,
                                availableRelatedAdds: !JavaScriptExtensions.isNullOrUndfinedOrEmpty(availableRelatedAccounts) ? availableRelatedAccounts : [],
                                selectedRelatedAdds: !JavaScriptExtensions.isNullOrUndfinedOrEmpty(selectedRelatedAccounts) ? selectedRelatedAccounts : [],
                            };
                            listItems.push(item);
                        }
                    });
                }
            });
    }

    /**
     * SkipChannelReason change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {string} newValue updated value from textField.
     */
    const onSkipChannelReason = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        internalPricingContextDispatch({ type: CaseActionTypes.skipChannelReasonChanged, dealInputValue: { key: oppdealId, value: newValue } })
    }

    //this method is to hide required field error message on view mode
    const isRequired = (): boolean => {
        return internalPricingContextState.pageMode?.toLowerCase() !== PageMode.View.toLowerCase()
    }

    /**
     * Related ADD change Callback function.
     * @method
     * @param {string} cellId Grid Cell ID.
     * @param {IDropdownOption[]} selectedAdds List of selected ADDs.
     */
    const onRelatedADDChange = (cellId: string, selectedAdds: IDropdownOption[]): void => {
        let countryID = cellId.split('_')[0];
        let dmpAccountID = cellId.split('_')[1];
        let newRellers = resellers;
        let reseller = newRellers.find(r => r.countryId === countryID && r.partnerType === PartnerType.DMP && r.accounts?.find(a => a.accountId === dmpAccountID));
        let newRelatedAccounts = selectedAdds.map((a: IDropdownOption) => a.data as IAccount);

        newRelatedAccounts = newRelatedAccounts.map(x => { return { ...x, action: getPartnerAction(x, countryID, PartnerType.DMP, dmpAccountID) } as IAccount });
        if (reseller?.partnerAccounts && newRelatedAccounts) {
            reseller!.partnerAccounts[dmpAccountID] = newRelatedAccounts;
        }
        setResellers(newRellers);
        internalPricingContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: [...newRellers, ...distributors, ...msStores] });
    }
    //disable account when case status is not draft or page mdoe is view
    const disableAccount = (partnerAccount: IAccount, accountId: string, countryId: string): boolean => {
        var isDisabled = false;
        var pageMode = internalPricingContextState.pageMode?.toLowerCase();
        var isSaved = isPartnerAccount(countryId, PartnerType.DMP, partnerAccount, accountId)
        if (pageMode === PageMode.View.toLowerCase()) {
            return true;
        }
        else if (pageMode === PageMode.Edit.toLowerCase() && internalPricingContextState.opportunityDeals[0].statusCode !== OpportunityDealStatusCodes.Draft
            && isSaved) {
            isDisabled = true;
        }
        return isDisabled;
    }

    const addDropdownCell = (props: GridCellProps) => {
        const excludeDMPList = ['ADR', 'MS Store'];
        let selectedAccounts: IAccount[] = props.dataItem.availableRelatedAdds && props.dataItem.selectedRelatedAdds.length > 0 ?
            props.dataItem.selectedRelatedAdds :
            [];
        let countryID = props.dataItem.key.split('_')[0];
        let dmpAccountID = props.dataItem.key.split('_')[1];

        selectedAccounts = selectedAccounts.map(x => {
            return {
                ...x, action: getPartnerAction(x, countryID, PartnerType.DMP, dmpAccountID)
            }
        })
        if (internalPricingContextState.pageMode === PageMode.View || internalPricingContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.PendingSecondaryReview) {
            if (internalPricingContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.Draft) {
                selectedAccounts = selectedAccounts.filter(acc => acc.action?.toLowerCase() === ActionType.Add.toLowerCase());
            }
            else {
                selectedAccounts = selectedAccounts.filter(acc => acc.action?.toLowerCase() === ActionType.Created.toLowerCase());
            }

            return (excludeDMPList.includes(props.dataItem.partnerType) ? (userRole == UserSubRole.Reseller ? <div></div> : <div className={CssClassConstants.NotAvailableTextMargin}>N/A(<i>only shown for DMP</i>)</div>) : <DropDownGridCell
                isMultiSelect={true}
                cellId={props.dataItem.key}
                selectedValues={selectedAccounts.map((add: IAccount) => ({ key: add.accountId, data: add, disabled: disableAccount(add, props.dataItem.accountId, props.dataItem.country.countryId) } as IDropdownOption))}
                data={props.dataItem.availableRelatedAdds && props.dataItem.availableRelatedAdds.length > 0 ?
                    props.dataItem.availableRelatedAdds.filter((account: any) => selectedAccounts.some(selectedAcc => selectedAcc.accountId === account.accountId)).map((add: IAccount) => ({ key: add.accountId, text: add.companyName, data: add, disabled: disableAccount(add, props.dataItem.accountId, props.dataItem.country.countryId) } as IDropdownOption)) :
                    []}
                itemSelectionUpdate={onRelatedADDChange}
            />)
        }
        else {
            return (excludeDMPList.includes(props.dataItem.partnerType) ? <div className={CssClassConstants.NotAvailableTextMargin}>N/A(<i>only shown for DMP</i>)</div> : <DropDownGridCell
                isMultiSelect={true}
                cellId={props.dataItem.key}
                selectedValues={selectedAccounts.map((add: IAccount) => ({ key: add.accountId, data: add, disabled: disableAccount(add, props.dataItem.accountId, props.dataItem.country.countryId) } as IDropdownOption))}
                data={props.dataItem.availableRelatedAdds && props.dataItem.availableRelatedAdds.length > 0 ?
                    props.dataItem.availableRelatedAdds.map((add: IAccount) => ({ key: add.accountId, text: add.companyName, data: add, disabled: disableAccount(add, props.dataItem.accountId, props.dataItem.country.countryId) } as IDropdownOption)) : []}
                itemSelectionUpdate={onRelatedADDChange}
            />)
        }
    };

    const getDistributorAccountsByCountry = (country: ICountry) => {
        let distributors: IAccount[] = [];

        if (distributorAccounts && distributorAccounts.length > 0) {
            if (country.isEUEFTA) {
                distributors = distributorAccounts.filter((x: IAccount) => x.countryId === country.countryId
                    || x.isEueftaAccount);
            }
            else {
                distributors = distributorAccounts.filter((x: IAccount) => x.applicablePriceListGeo === country.applicablePriceListGeo);
            }

            distributors = distributors.filter((x: IAccount) => x.productTypeId === internalPricingContextState?.productGroup);
        }
        return distributors;
    }
    //specific validation to disable/enable channel structure dropdown: enable in edit mode only when channel structure is skip and status is pending channel. 
    //otherwise follow as per isFieldDisabled configuration
    const channelStructureDisable = (): boolean => {
        var isChannelStructureDisable = false;
        isChannelStructureDisable =
            isFieldDisabled(UIControlsText.ChannelStructureText, internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0].statusCode, undefined, undefined,  PageConstants.PropCPStrategictype) ?
                (internalPricingContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.DealPricingApprovedPendingChannelStatus &&
                    getsavedContext().opportunityDeals[0].channelStructure === ChannelStructure.Skip &&
                    internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()) ? false : true : false
        return isChannelStructureDisable;
    }

    // method for downloading the excel
    const download = useRef<ExcelExport | null>(null);
    const excelExport = () => {
        if (download && download.current && download.current !== null) {
            addExportExcelHeader(download);
        }
    };

    const onAddADR = () => {
        const newGuidId = Guid.newGuid();
        const newReseller = { id: newGuidId, partnerType: PartnerType.ADR };
        const ADRList = addtionalADRIdList.concat([newGuidId]);
        setResellers([...resellers, newReseller]);
        setAddtionalADRIdList(ADRList);
    }

    const uniqueSelection = resellers.concat(msStores).reduce((unique: any, o) => {
        if (!unique.some((obj: any) => obj.countryId === o.countryId && obj.partnerType === o.partnerType)) {
            unique.push(o);
        }
        return unique;
    }, []);

    const uniqueEntries = uniqueSelection.map((item: any) => item.id);
    const duplicateEntries = resellers.concat(msStores).filter(i => !uniqueEntries.includes(i.id)).map(key => key.id);

    //Holds first reseller id for each country
    const firstResellers = _(resellerChannelAccounts).groupBy('countryId')
        .map(acc => _.first(acc)?.id);

    useEffect(() => {
        //In ChannelStructure.Customer, maintain consolidated list of both MS Store and other partner types
        let resellerChannels = [...resellerChannelAccounts].filter(x => [...resellers, ...msStores].map(r => r.id).includes(x.id)) //Delete removed accounts
        resellerChannels = resellerChannels.map(r => { return [...resellers, ...msStores].find(x => x.id === r.id) || {} }); //Update existing accounts
        let newResellers = resellers.filter(x => !resellerChannels.map(r => r.id).includes(x.id));
        resellerChannels = ArrayExtensions.mergeArrays(resellerChannels, newResellers); //Add new resellers
        let newMSStores = msStores.filter(x => !resellerChannels.map(m => m.id).includes(x.id));
        resellerChannels = ArrayExtensions.mergeArrays(resellerChannels, newMSStores); //Add new MSStores
        setResellerChannelAccounts(resellerChannels);
    }, [resellers, msStores])

    useEffect(() => {
        if (internalPricingContextState.opportunityAccounts) {

            //Validate if selected account is still valid when editing Draft case
            if (internalPricingContextState.pageMode === PageMode.Edit
                && internalPricingContextState.opportunityDeals.length
                && internalPricingContextState.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.Draft) {
                //Reseller account validation
                const invalidResellers = internalPricingContextState.opportunityAccounts.filter(a =>a.accounts?.some(x => JavaScriptExtensions.isDefined(x.isActive) && !x.isActive));

                //Distributor account validation
                const invalidDistributors = internalPricingContextState.opportunityAccounts.filter(a => a.channelSubType !== ChannelSubType.Requester && a.partnerType === PartnerType.ADD && a.accounts?.some(x => JavaScriptExtensions.isDefined(x.isActive) && !x.isActive));

                let updatedAccounts = [...internalPricingContextState.opportunityAccounts];
                let invalidPartners: string = "";
                if (invalidResellers && invalidResellers.length > 0) {
                    //Remove the invalid reseller & Show message
                    updatedAccounts = updatedAccounts.filter(a => !invalidResellers.map(inv => inv?.id).includes(a.id));
                    if(internalPricingContextState.opportunityDeals[0].channelStructure === ChannelStructure.Customer){
                        updatedAccounts.push(...invalidResellers.map(r => ({ id: r.id, partnerType: r.partnerType, countryId: r.countryId, countryName: r.countryName, isComplete: r.isComplete, contactEmail: r.contactEmail, accounts: [] })));
                    }
                    invalidPartners = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "UnavailablePartner", ErrorMessages.UnavailablePartner) + invalidResellers.map(r => r?.accounts?.map(a => a.companyName)).join(', ');
                }

                if (invalidDistributors && invalidDistributors.length > 0) {
                    //Remove the invalid Distributor & Show message
                    updatedAccounts = updatedAccounts.filter(a => !invalidDistributors.map(inv => inv?.id).includes(a.id));
                    invalidPartners = invalidPartners === "" ? getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "UnavailablePartner", ErrorMessages.UnavailablePartner) : invalidPartners + ', ';
                    invalidPartners = invalidPartners + invalidDistributors.map(r => r?.accounts?.map(a => a.companyName)).join(', ')
                }

                if (invalidPartners != "") {
                    setInvalidResellers(invalidPartners);
                    setIsDialogOpen(true);
                    internalPricingContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: updatedAccounts });
                }
            }
        }
    }, [internalPricingContextState.opportunityAccounts]);


    useEffect(() => {
        if (duplicateEntries.length > 0) {
            internalPricingContextDispatch({ type: CaseActionTypes.missingFieldTab, inputArray: [getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)] });
            internalPricingContextDispatch({ type: CaseActionTypes.validationErrors, inputArray: [getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DuplicateADRErrorText", ErrorMessages.DuplicateADRErrorText)] });
        } else {
            internalPricingContextDispatch({ type: CaseActionTypes.missingFieldTab, inputArray: [] });
            internalPricingContextDispatch({ type: CaseActionTypes.validationErrors, inputArray: [] });
        }
    }, [duplicateEntries.length])
    const sortedListItems = _.sortBy(listItems.filter((account: any) => account.partnerType !== PartnerTypeLabel.get(PartnerType.ADD)), ['countryName', 'partnerType', 'resellerName']);

    const distributorNamesCell = (props: GridCellProps) => {
        const excludeDMPList = ['MS Store'];
        let selectedAccounts: IAccount[] = props.dataItem.availableRelatedAdds && props.dataItem.selectedRelatedAdds.length > 0 ?
            props.dataItem.selectedRelatedAdds :
            [];

        let countryID = props.dataItem.key.split('_')[0];
        let dmpAccountID = props.dataItem.key.split('_')[1];

        selectedAccounts = selectedAccounts.map(x => {
            return {
                ...x, action: isPartnerAccountPresent(countryID, x, dmpAccountID)
            }
        })

        selectedAccounts = selectedAccounts.filter(acc => acc.action?.toLowerCase() === ActionType.Created.toLowerCase());
        return (excludeDMPList.includes(props.dataItem.partnerType) ? <div className={CssClassConstants.NotAvailableTextMargin}>N/A(<i>only shown for ADR/DMP</i>)</div> :
            <td>
                {
                    selectedAccounts.length <= 0 ? <div>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoADDAsscociatedWithADRText", UIControlsText.NoADDAsscociatedWithADRText)}</div> :
                        selectedAccounts.length > 1 ? <ul className={CssClassConstants.ResellerDistributorULClass}> {selectedAccounts.map((item: IAccount) => {
                            return <li className={CssClassConstants.ResellerDistributorListClass}>{item.companyName}</li>
                        })}</ul> : selectedAccounts.map((item: IAccount) => {
                            return item.companyName
                        })
                }
            </td>
        )
    };

    const isPartnerAccountPresent = (countryId?: string, accountItem?: IAccount, accountID?: string) => {
        var action = ActionType.Add.toString();
        var dmpAccount = internalPricingContextState.opportunityAccounts?.find(r => r.countryId === countryId && r.accounts?.find(a => a.accountId === accountID));
        if (dmpAccount && dmpAccount?.partnerAccounts) {
            var idx = dmpAccount?.partnerAccounts && dmpAccount?.partnerAccounts[accountID as string]?.findIndex(item => item.accountId && item.accountId === accountItem?.accountId);
            if (idx !== -1 && dmpAccount?.partnerAccounts[accountID as string]) {
                action = dmpAccount?.partnerAccounts && dmpAccount?.partnerAccounts[accountID as string][idx].action || "";
            }
        }
        return action
    }

    const resellerNamesCell = (props: GridCellProps) => {
        const excludeDMPList = ['MS Store'];
        let selectedAccounts: IAccount[] = props.dataItem.selectedRelatedResellers && props.dataItem.selectedRelatedResellers.length > 0 ?
            props.dataItem.selectedRelatedResellers : []

        return (excludeDMPList.includes(props.dataItem.partnerType) ? <div className={CssClassConstants.NotAvailableTextMargin}>N/A(<i>only shown for ADR/DMP</i>)</div> :
            <td>
                {
                    selectedAccounts.length > 1 ? <ul className={CssClassConstants.ResellerDistributorULClass}> {selectedAccounts.map((item: IAccount) => {
                        return <li className={CssClassConstants.ResellerDistributorListClass}>{item.companyName}</li>
                    })}</ul> : selectedAccounts.map((item: IAccount) => {
                        return item.companyName
                    })
                }
            </td>
        )
    };

    let listItemsForADD: any = [];
    if (isChannelInfoVisible && isCasToReseller && userRole == UserSubRole.ADD
        && (internalPricingContextState.opportunityAccounts && internalPricingContextState.opportunityAccounts.length > 0)
    ) {
        const countries: ICountry[] = JSON.parse(sessionStorage.getItem(SessionStorageConsts.countries) || "{}");
        internalPricingContextState.opportunityAccounts
            .filter(x => oppAccountAvailableCountries.map(c => c.countryId).indexOf(x.countryId as string) >= 0)
            .forEach((value: IOpportunityAccount) => {
                if (value.partnerType === PartnerType.MsStore) {
                    let item: any = {
                        key: value.countryId,
                        countryName: countries.find(x => x.countryId === value.countryId)?.countryName,
                        countryId: countries.find(x => x.countryId === value.countryId)?.countryId,
                        country: countries.find(x => x.countryId === value.countryId),
                        partnerType: partnerTypeValues[`${value.partnerType}`],
                        distributorName: partnerTypeValues[`${value.partnerType}`],
                        selectedRelatedResellers: []
                    }
                    listItemsForADD.push(item);
                }
                if (value.partnerType !== PartnerType.MsStore) { //MSStore has accounts in View Mode
                    let partnerAccountIndex = !JavaScriptExtensions.isNullOrUndfinedOrEmpty(value.accounts) && value.accounts?.length && value.accounts?.length > 0 ? value.accounts?.[0]?.accountId as string : "";
                    if (partnerAccountIndex != "") {
                        let addAccount = value.partnerAccounts && value.partnerAccounts.hasOwnProperty(partnerAccountIndex) ? value.partnerAccounts[partnerAccountIndex][0] : null;
                        let selectedRelatedAccounts = value.accounts;
                        selectedRelatedAccounts = selectedRelatedAccounts?.map((acc: IAccount) => { return { ...acc } });
                        {
                            let item: any = {
                                key: value.countryId + "_" + addAccount?.accountId + "_relatedResellers",
                                countryName: countries.find(x => x.countryId === value.countryId)?.countryName,
                                countryId: countries.find(x => x.countryId === value.countryId)?.countryId,
                                country: countries.find(x => x.countryId === value.countryId),
                                partnerType: partnerTypeValues[`${value.partnerType}`],
                                distributorName: addAccount?.companyName,
                                accountId: addAccount?.accountId,
                                selectedRelatedResellers: !JavaScriptExtensions.isNullOrUndfinedOrEmpty(selectedRelatedAccounts) ? selectedRelatedAccounts : [],
                            };
                            listItemsForADD.push(item);
                        }
                    }
                }
            });
    }
    let sortedListItemsForADD = _.sortBy(listItemsForADD.filter((account: any) => account.partnerType !== PartnerTypeLabel.get(PartnerType.ADD)), ['countryName', 'partnerType', 'distributorName']);

    if (isChannelInfoVisible && !isCasToReseller
        && userRole === UserSubRole.ADD
        && internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase()
        && (internalPricingContextState.opportunityAccounts && internalPricingContextState.opportunityAccounts.length > 0)) {

        const countries: ICountry[] = JSON.parse(sessionStorage.getItem(SessionStorageConsts.countries) || "{}");
        const loggedInDistributor = getLoggedinUserRoleDetails().find((userRoleDetails: IUserRoleDetail) => userRoleDetails.userSubRole === UserSubRole.ADD && userRoleDetails.productType?.productId === internalPricingContextState.productGroup);
        const distributorName = getDistributorAccountsByCountry(
            countries.find(c => c.applicablePriceListGeo === loggedInDistributor.applicablePriceListGeo) as ICountry)?.find(acc => acc.accountId === loggedInDistributor.accountId && acc.productTypeId === loggedInDistributor.productType?.productId)?.companyName;

        availableCountries.forEach((value: ICountry) => {
            let resellersList = internalPricingContextState.opportunityAccounts?.filter(acc => acc.countryId === value.countryId) || [];
            let availableResellers = resellersList.find(opp => opp.partnerType === PartnerType.Reseller || opp.partnerType === PartnerType.ADR || opp.partnerType === PartnerType.DMP)?.accounts
            let resellersOption = AccountAllType.All;
            const reseller = resellersList.find(opp => opp.partnerType === PartnerType.Reseller || opp.partnerType === PartnerType.ADR || opp.partnerType === PartnerType.DMP);

            if (reseller && reseller?.isAllSelected) {
                resellersOption = reseller?.isAllSelected;

            } else if (value && value?.isEUEFTA) {
                resellersOption = AccountAllType.AllEuefta
            }

            let allResellers: Array<IAccount> = [];
            if (resellerAccounts.current && resellerAccounts.current.length > 0) {
                allResellers = resellerAccounts.current.filter(resellerAccount => availableResellers?.find(({ accountId }) => resellerAccount.accountId === accountId));
            }
            if (allResellers && allResellers.length > 0) {
                if (value) {
                    if (value.isEUEFTA) {
                        allResellers = allResellers.filter((x: IAccount) => x.countryId === value.countryId
                            || (x.isEueftaAccount));
                    }
                    else {
                        allResellers = allResellers.filter((x: IAccount) => x.applicablePriceListGeo === value.applicablePriceListGeo);
                    }
                }
            }

            if (allResellers && allResellers.length > 0) {
                const groupedResellers = _.groupBy(allResellers, ApplicationConstants.PartnerType);
                Object.entries(groupedResellers).map(([k, r]) => {
                    let item: any = {
                        countryName: value.countryName,
                        opportunityAccounts: [...resellersList],
                        resellerAccounts: r,
                        countries: availableCountries,
                        customerCountry: value,
                        productType: props.productTypes,
                        distiAccounts: distributorAccounts,
                        distributorName: distributorName,
                        resellersOption: resellersOption,
                        partnerType: partnerTypeValues[`${k}`],
                        selectedADDList: resellersList?.find(opp => opp.partnerType === PartnerType.ADD)
                    };
                    listItemsForADD.push(item);
                })
            } else {
                let item: any = {
                    countryName: value.countryName,
                    opportunityAccounts: [...resellersList],
                    resellerAccounts: allResellers,
                    countries: availableCountries,
                    customerCountry: value,
                    productType: props.productTypes,
                    distiAccounts: distributorAccounts,
                    distributorName: distributorName,
                    resellersOption: resellersOption,
                    partnerType: ApplicationConstants.ADRDMPResellerType,
                    selectedADDList: resellersList?.find(opp => opp.partnerType === PartnerType.ADD)
                };
                listItemsForADD.push(item);
            }

        });
        sortedListItemsForADD = _.sortBy(listItemsForADD.filter((account: any) => account.partnerType !== PartnerTypeLabel.get(PartnerType.ADD)), ['countryName', 'partnerType', 'distributorName']);
    }

    const resellerNamesCellForCasToADD = (props: GridCellProps) => {
        const excludeDMPList = ['MS Store'];
        let resellerOption: number = props.dataItem.resellersOption ? props.dataItem.resellersOption : "";
        let resellerColumn = <div></div>;
        let resellerSelection = props.dataItem.opportunityAccounts && props.dataItem.opportunityAccounts.find((x: any) => x.partnerType === PartnerType.Reseller) as IOpportunityAccount;
        let mdppaccessibilityResellerNameLinkId = "mdppaccessibilityResellerName-" + props.dataIndex;

        if (resellerSelection) {
            resellerSelection.isAllSelected = resellerOption as AccountAllType;
            if (resellerSelection.isAllSelected === AccountAllType.All) {
                resellerColumn = (
                    <div>
                        <Link id={mdppaccessibilityResellerNameLinkId} underline className="ps-2" onClick={() => { viewResellers(false, props.dataItem.customerCountry, mdppaccessibilityResellerNameLinkId) }}><i>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AllResellersText", UIControlsText.AllResellersText)}</i></Link>
                    </div>
                )
            }
            else if (resellerSelection.isAllSelected === AccountAllType.AllEuefta) {
                resellerColumn = (
                    <div>
                        <Link id={mdppaccessibilityResellerNameLinkId} underline className="ps-2" onClick={() => { viewResellers(true, {} as ICountry, mdppaccessibilityResellerNameLinkId) }}><i>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AllResellersEuText", UIControlsText.AllResellersEuText)}</i></Link>
                    </div>
                )
            }
            else if (resellerSelection.isAllSelected === AccountAllType.None) {
                resellerColumn = (
                    <div>
                        {
                            resellerSelection.accounts.length > 1 ? <ul className={CssClassConstants.ResellerDistributorULClass}> {resellerSelection.accounts.map((item: IAccount) => {
                                return <li className={CssClassConstants.ResellerDistributorListClass}>{item.companyName}</li>
                            })}</ul> : resellerSelection.accounts.map((item: IAccount) => {
                                return item.companyName
                            })
                        }
                    </div>
                )
            }
        }

        return (excludeDMPList.includes(props.dataItem.partnerType) ? <div className={CssClassConstants.NotAvailableTextMargin}>N/A(<i>only shown for ADR/DMP</i>)</div> :
            <td>
                {resellerColumn}
            </td>
        )
    };

    const distributorNamesCellForCasToADD = (props: GridCellProps) => {
        let distiColumn = <div></div>;
        let distiSelection = props.dataItem.selectedADDList as IOpportunityAccount;
        
        if (distiSelection) {      
            distiColumn = (
                <div>
                    {
                        distiSelection.accounts && distiSelection.accounts.length > 1 ? <ul className={CssClassConstants.ResellerDistributorULClass}> {distiSelection.accounts.map((item: IAccount) => {
                            return <li className={CssClassConstants.ResellerDistributorListClass}>{item.companyName}</li>
                        })}</ul> : distiSelection.accounts?.map((item: IAccount) => {
                            return item.companyName
                        })
                    }
                </div>
            )            
        } 

        return (
            <td>
                {distiColumn}
            </td>
        )
    };
    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    let mdppAccessibilityLocation = mdppaccessibilitySetNavForFocus();
    const viewResellers = (isEUEFTA: boolean, resellerCountry: ICountry, mdppaccessibilityLinkId: string) => {
        var country: any = resellerCountry?.countryName

        history.push({
            pathname: '/viewresellers',
            search: '?' + ApplicationConstants.OpportunityNumberParamName + "=" + getURLParamValue(ApplicationConstants.OpportunityNumberParamName),
            state: { countryName: { country }, isEUEFTA: { isEUEFTA }, mdppAccessibilityNavigation: mdppAccessibilityLocation, mdppAccessibilityLinkId: mdppaccessibilityLinkId }
        });
    }
    return (
        <div data-nextgen-parent-group={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)} data-nextgen-parentid={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)}>
            <Stack horizontal {...defaultColumnProps} styles={PageStyles.labelStyles}>
                {isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelOverviewText", UIControlsText.ChannelOverviewText), internalPricingContextState.pageMode, ApplicationConstants.CPstrategicPageName) &&
                    <TextField
                        label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelOverviewText", UIControlsText.ChannelOverviewText)}
                        ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelOverviewText", UIControlsText.ChannelOverviewText)}
                        placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "ChannelOverviewPlaceHolderText", UIControlsText.ChannelOverviewPlaceHolderText)}
                        validateOnFocusOut
                        validateOnLoad={false}
                        onGetErrorMessage={getTextFieldErrorMessage}
                        onChange={onChannelOverviewChange}
                        multiline
                        rows={3}
                        styles={PageStyles.textFieldStylesOneColumn}
                        value={internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].channelOverview}
                        maxLength={2000}
                        required={isRequired()}
                        disabled={isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelOverviewText", UIControlsText.ChannelOverviewText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0].statusCode, undefined, undefined,  PageConstants.PropCPStrategictype)}
                    />
                }
                {isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelStructureText", UIControlsText.ChannelStructureText), internalPricingContextState.pageMode, ApplicationConstants.CPstrategicPageName) &&
                    <Dropdown
                        label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelStructureText", UIControlsText.ChannelStructureText)}
                        ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelStructureText", UIControlsText.ChannelStructureText)}
                        placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "ChannelStructurePlaceholderText", UIControlsText.ChannelStructurePlaceholderText)}
                        options={ChannelStructureOptions}
                        styles={PageStyles.dropdownStyles}
                        onChange={onChannelStructureChange}
                        selectedKey={internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].channelStructure}
                        disabled={channelStructureDisable()}
                        required={isRequired()} />
                }
            </Stack>
            <Stack horizontal {...defaultColumnProps}>
                {isChannelJustificationVisible && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText), internalPricingContextState.pageMode, ApplicationConstants.CPstrategicPageName) &&
                    <TextField
                        label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelJustificationText", UIControlsText.ChannelJustificationText)}
                        ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelJustificationText", UIControlsText.ChannelJustificationText)}
                        placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "ChannelJustificationPlaceHolderText", UIControlsText.ChannelJustificationPlaceHolderText)}
                        styles={DefaultPageStyles.oneColumnWidthStyles}
                        validateOnFocusOut
                        multiline
                        rows={3}
                        validateOnLoad={false}
                        onGetErrorMessage={getTextFieldErrorMessage}
                        onChange={onChannelJustificationChange}
                        disabled={internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase()}
                        value={internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].channelJustification}
                        required={isRequired()} />
                }

                {isAttachmentVisible && isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.PopupMessageText, "Attachment", UIControlsText.Attachment), internalPricingContextState.pageMode, ApplicationConstants.CPstrategicPageName) &&
                    <Attachment {...
                        {
                            labelText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProofOfPreference", UIControlsText.ProofOfPreference),
                            source: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText),
                            required: isAttachmentRequired,
                            attachmentList: attachmentList,
                            handleAttachmentChanges,
                            opportunityDealStatusCode: internalPricingContextState.opportunityDeals[0]?.statusCode,
                            pageMode: internalPricingContextState.pageMode,
                            savedAttachments: getsavedContext().attachments,
                            disabled: internalPricingContextState.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.PendingSecondaryReview || isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.PopupMessageText, "Attachment", UIControlsText.Attachment), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined,  PageConstants.PropCPStrategictype)
                        }} />
                }

                {isSkipReasoningVisible &&
                    <TextField className={_Styles.SkipTextBoxStyle}
                        label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SkipChannelReasonText", UIControlsText.SkipChannelReasonText)}
                        ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SkipChannelReasonText", UIControlsText.SkipChannelReasonText)}
                        placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "SkipChannelReasonPlaceHolderText", UIControlsText.SkipChannelReasonPlaceHolderText)}
                        validateOnFocusOut
                        validateOnLoad={false}
                        onGetErrorMessage={getTextFieldErrorMessage}
                        onChange={onSkipChannelReason}
                        value={internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].skipChannelReason}
                        maxLength={100}
                        disabled={internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase()}
                        required={isRequired()} />
                }
            </Stack>
            {isResellerLoading &&
                <Stack {...defaultColumnProps}>
                    <div>
                        <Spinner label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LoadingReseller", UIControlsText.LoadingReseller)} />
                    </div>
                </Stack>
            }

            {
                isChannelInfoVisible && (!isCasToReseller || userRole == UserSubRole.Reseller) && !isResellerLoading && availableCountries.length > 0 && userRole !== UserRole.MSStore && userRole !== UserSubRole.Reseller && userRole !== UserSubRole.ADD &&
                <Stack>
                    <Label className="channelLabels" styles={PageStyles.labelStyles}>
                        <Stack horizontal tokens={stackTokens}>
                            <Label styles={DefaultPageStyles.customWidthStyles} >
                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LegalEntityCountry", UIControlsText.LegalEntityCountry)}
                            </Label>
                                {isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.PopupMessageText, "ADDRegistration", UIControlsText.ADDRegistration), internalPricingContextState.pageMode, ApplicationConstants.CPstrategicPageName) &&
                                <Label styles={DefaultPageStyles.customWidthForStackLabels}>
                                    {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddNameText", UIControlsText.AddNameText)}
                                </Label>
                            }
                            {hideResellerSectionsInViewModeForReseller(userRole || "", internalPricingContextState.pageMode) &&
                                <Label styles={DefaultPageStyles.oneColumnWidthStyles}>
                                    {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameText", UIControlsText.ResellerNameText)}
                                </Label>
                            }
                            {hideResellerSectionsInViewModeForReseller(userRole || "", internalPricingContextState.pageMode) &&
                                <Label className={msStoreClass}>
                                    {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MsStoreText", UIControlsText.MsStoreText)}
                                </Label>
                            }
                        </Stack>
                    </Label>
                    <Stack {...defaultColumnProps}>
                        {
                            availableCountries.map(
                                (k, i) =>
                                    <div className="ChannelDistributor" key={k.countryId}>
                                        <ChannelAccounts {
                                            ...{
                                                id: k.countryId,
                                                opportunityAccounts: [...resellers, ...distributors, ...msStores].filter(acc => acc.countryId === k.countryId),
                                                countries: availableCountries,
                                                resellerAccounts: resellerAccounts.current,
                                                customerCountry: k,
                                                updateAccounts,
                                                productType: props.productTypes,
                                                distiAccounts: distributorAccounts
                                            }
                                        } />
                                    </div>
                            )
                        }
                    </Stack>
                </Stack>
            }
            {
                isChannelInfoVisible && isCasToReseller && userRole !== UserSubRole.Reseller && !isResellerLoading && availableCountries.length > 0 && userRole !== UserRole.MSStore && userRole !== UserSubRole.ADD &&
                <Stack>
                    <Label className="channelLabels" styles={PageStyles.labelStyles}>
                        <Stack horizontal {...defaultColumnProps}>
                            <Label styles={DefaultPageStyles.customWidthStyles} >
                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.PopupMessageText, "LegalEntityCountry", UIControlsText.LegalEntityCountry)}
                            </Label>
                            <Label styles={DefaultPageStyles.customWidthStyles}>
                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerTypeText", UIControlsText.PartnerTypeText)}
                            </Label>
                            <Label styles={DefaultPageStyles.customWidthStyles}>
                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameText", UIControlsText.ResellerNameText)}
                            </Label>
                        </Stack >
                    </Label >
                    <Stack {...defaultColumnProps}>
                        {
                            resellerChannelAccounts.map(
                                (k, i) =>
                                    <div className="ChannelReseller" key={k.id} style={{ margin: 0 }}>
                                        <Reseller {
                                            ...{
                                                id: k.id,
                                                opportunityAccounts: [...resellers, ...msStores].filter(acc => acc.countryId === k.countryId),
                                                countries: availableCountries,
                                                resellerAccounts: resellerAccounts.current,
                                                customerCountry: availableCountries.find(x => x.countryId === k.countryId),
                                                updateAccounts,
                                                productType: props.productTypes,
                                                partnerType: k.partnerType || PartnerType.ADR,
                                                hideDeleteIcon: !firstResellers.includes(`${k.id}`),
                                                disableResellerAndMSStoreField: duplicateEntries.includes(`${k.id}`),
                                                pageMode: internalPricingContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.PendingSecondaryReview ? PageMode.View : internalPricingContextState?.pageMode,
                                                productGroup: internalPricingContextState?.productGroup,
                                                opportunityDealStatusCode: internalPricingContextState.opportunityDeals[0]?.statusCode,
                                                partnerAccounts: partnerAccounts
                                            }} />
                                    </div>
                            )
                        }
                            {isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdrAddtext", UIControlsText.AdrAddtext), internalPricingContextState.pageMode, ApplicationConstants.CPstrategicPageName) &&
                            <ActionButton
                                className={CssClassConstants.AddItemButtonClass}
                                iconProps={{ iconName: CssClassConstants.AddItemIconName }}
                                style={PageStyles.addResellerAlignement}
                                disabled={isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdrAddtext", UIControlsText.AdrAddtext), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0].statusCode, undefined, undefined, PageConstants.PropCPStrategictype)}
                                onClick={() => {
                                    onAddADR()
                                }}>
                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdrAddtext", UIControlsText.AdrAddtext)}
                            </ActionButton>
                        }
                    </Stack>
                </Stack >
            }
            {isChannelInfoVisible && isCasToReseller && userRole !== UserSubRole.ADD && sortedListItems.length > 0 && !isResellerLoading && userRole !== UserRole.MSStore && availableCountries.length > 0 && !getUserRole().includes(UserSubRole.ADR) && userRole !== UserSubRole.Reseller &&
                <>
                    {(isCasToReseller && sortedListItems.some((x: any) => x.partnerType === PartnerTypeLabel.get(PartnerType.DMP)) && userRole !== UserSubRole.ADD && userRole !== UserSubRole.Reseller) &&
                        < Stack {...defaultColumnProps}>
                            <MessageBar>
                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerAddDisclaimerText", UIControlsText.PartnerAddDisclaimerText)}
                            </MessageBar>
                        </Stack>
                    }
                    <Stack {...defaultColumnProps}>
                        {internalPricingContextState.pageMode === PageMode.View ? (
                            <ExcelExport data={sortedListItems} ref={download}>
                                <Grid data={sortedListItems} style={{ maxHeight: 320 }}>
                                    <GridToolbar>
                                        <PrimaryButton
                                            aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DownloadExcel", UIControlsText.DownloadExcel)}
                                            className="ms-auto"
                                            text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DownloadExcel", UIControlsText.DownloadExcel)}
                                            title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DownloadExcel", UIControlsText.DownloadExcel)}
                                            onClick={excelExport}
                                        />
                                    </GridToolbar>
                                    <Column field="countryName" title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LegalEntityCountry", UIControlsText.LegalEntityCountry)} />
                                    <Column field="partnerType" title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerTypeText", UIControlsText.PartnerTypeText)} />
                                    <Column field="resellerName" title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerText", UIControlsText.ResellerText)} />
                                    <Column title={UIControlsText.DistributorText} cell={addDropdownCell} />
                                </Grid>
                            </ExcelExport >
                        ) :
                            <Grid data={sortedListItems} style={{ maxHeight: 320 }}>
                                <Column field="countryName" title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LegalEntityCountry", UIControlsText.LegalEntityCountry)} />
                                <Column field="partnerType" title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerTypeText", UIControlsText.PartnerTypeText)} />
                                <Column field="resellerName" title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerText", UIControlsText.ResellerText)} />
                                <Column title={UIControlsText.DistributorText} cell={addDropdownCell} />
                            </Grid>
                        }
                    </Stack >
                </>
            }

            {
                internalPricingContextState.opportunityDeals[0].channelStructure === ChannelStructure.Customer && userRole !== UserSubRole.Reseller && userRole !== UserRole.MSStore && !isResellerLoading && resellers.length <= 0 && internalPricingContextState.pageMode?.toLowerCase() !== PageMode.Create.toLowerCase() &&
                <MessageBar>
                    {getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "NoResellerInfo", UIControlsText.NoResellerInfo)}
                </MessageBar>
            }

            {
                userRole === UserSubRole.Reseller && internalPricingContextState.opportunityDeals[0].channelStructure === ChannelStructure.Customer && !isResellerLoading && (availableCountries.length <= 0 || distributors.length <= 0) && internalPricingContextState.pageMode?.toLowerCase() !== PageMode.Create.toLowerCase() &&
                <MessageBar>
                    {getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "NoDistributorInfo", UIControlsText.NoDistributorInfo)}
                </MessageBar>
            }

            {
                userRole === UserSubRole.Reseller && internalPricingContextState.opportunityDeals[0].channelStructure === ChannelStructure.Distributor && !isResellerLoading && (availableCountries.length <= 0 || distributors.length <= 0) && internalPricingContextState.pageMode?.toLowerCase() !== PageMode.Create.toLowerCase() &&
                <MessageBar>
                    {getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "NoChannelInfo", UIControlsText.NoChannelInfo)}
                </MessageBar>
            }

            {isChannelInfoVisible && isCasToReseller && userRole === UserSubRole.Reseller && sortedListItems.length > 0 && !isResellerLoading && oppAccountAvailableCountries.length > 0 && internalPricingContextState.pageMode?.toLowerCase() == PageMode.View.toLowerCase() &&
                <>
                    {(isCasToReseller && sortedListItems.some((x: any) => x.partnerType === PartnerTypeLabel.get(PartnerType.DMP)) && userRole !== UserSubRole.Reseller) &&
                        < Stack>
                            <MessageBar>
                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerAddDisclaimerText", UIControlsText.PartnerAddDisclaimerText)}
                            </MessageBar>
                        </Stack>
                    }
                    <Stack>
                        <Grid style={{ maxHeight: "450px" }} data={sortedListItems}>
                            <Column className={CssClassConstants.ChannelGridCellContentAlign} width={200} field="countryName" title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LegalEntityCountry", UIControlsText.LegalEntityCountry)} />
                            <Column className={CssClassConstants.ChannelGridCellContentAlign} width={130} field="partnerType" title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerTypeText", UIControlsText.PartnerTypeText)} />
                            <Column className={CssClassConstants.ChannelGridCellContentAlign} field="resellerName" title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerText", UIControlsText.ResellerText)} />
                            <Column className={CssClassConstants.ChannelGridCellContentAlign} title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DistributorText", UIControlsText.DistributorText)} cell={distributorNamesCell} />
                        </Grid>
                    </Stack>
                </>
            }
            {isChannelInfoVisible && isCasToReseller && userRole === UserSubRole.ADD && sortedListItemsForADD.length > 0 && !isResellerLoading && oppAccountAvailableCountries.length > 0 && internalPricingContextState.pageMode?.toLowerCase() == PageMode.View.toLowerCase() &&
                <Stack>
                    <Grid style={{ maxHeight: "450px" }} data={sortedListItemsForADD}>
                        <Column className={CssClassConstants.ChannelGridCellContentAlign} width={200} field="countryName" title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LegalEntityCountry", UIControlsText.LegalEntityCountry)} />
                        <Column className={CssClassConstants.ChannelGridCellContentAlign} width={130} field="partnerType" title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerTypeText", UIControlsText.PartnerTypeText)} />
                        <Column className={CssClassConstants.ChannelGridCellContentAlign} title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameText", UIControlsText.ResellerNameText)} cell={resellerNamesCell} />
                        <Column className={CssClassConstants.ChannelGridCellContentAlign} field="distributorName" title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DistributorText", UIControlsText.DistributorText)} />
                    </Grid>
                </Stack>
            }
            {isChannelInfoVisible && !isCasToReseller && userRole === UserSubRole.ADD && sortedListItemsForADD.length > 0 && !isResellerLoading && oppAccountAvailableCountries.length > 0 && internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase() &&
                <Stack>
                    <Grid style={{ maxHeight: "450px" }} data={sortedListItemsForADD}>
                        <Column className={CssClassConstants.ChannelGridCellContentAlign} width={200} field="countryName" title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LegalEntityCountry", UIControlsText.LegalEntityCountry)} />
                        <Column className={CssClassConstants.ChannelGridCellContentAlign} width={130} field="partnerType" title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerTypeText", UIControlsText.PartnerTypeText)} />
                        <Column className={CssClassConstants.ChannelGridCellContentAlign} title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameText", UIControlsText.ResellerNameText)} cell={resellerNamesCellForCasToADD} />
                        <Column className={CssClassConstants.ChannelGridCellContentAlign} title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DistributorText", UIControlsText.DistributorText)} cell={distributorNamesCellForCasToADD} />
                    </Grid>
                </Stack>
            }
            { isDialogOpen && <Dialog style={_Styles} onClose={closeDialog}>
                <Stack horizontal={false} tokens={stackTokens} styles={stackStyles}>
                    <Stack>
                        <h4> {getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "UnavailableChannelPartner", ErrorMessages.UnavailableChannelPartner)}</h4>
                    </Stack>

                    <Stack>
                        <Label style={expireDialog} className={_Styles.DialogueCheckBox}>
                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "InvalidResellerAccounts", ErrorMessages.InvalidResellerAccounts)}
                            <div>
                                {invalidResellers}
                            </div>
                        </Label>
                    </Stack>
                </Stack>
                <DialogActionsBar>
                    <Stack horizontal horizontalAlign="end" tokens={stackButtonTokens} styles={stackButtonStyles}>
                        <DefaultButton
                            text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "OkText", UIControlsText.OkText)}
                            ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "OkText", UIControlsText.OkText)}
                            onClick={closeDialog}
                            styles={extendDialogButtonStyles}
                        />
                    </Stack>
                </DialogActionsBar>
            </Dialog>
            }
        </div >
    );
}

export default CPStrategicChannel;