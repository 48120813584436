/**
 * Enum for Attachment Display Mode.
 * @readonly
 * @enum {Enum}
 */
 export enum AttachmentDisplayMode {
    /**
    * Panel.
    */
    PanelGrid = 1,
    /**
     * Grid.
     */
    Grid = 2,
}