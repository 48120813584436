import * as React from 'react';
import { Label, Rating, RatingSize, Stack, StackItem, TextField } from '@fluentui/react';
import { IRatingProps } from './props/IRatingProps';

export const Ratings: React.FunctionComponent<IRatingProps> = (props?: IRatingProps) => {
    const [currentRating, setCurrentRating] = React.useState(-1);
    const [comment, setComment] = React.useState("");
    const minimalRatingToShowComments = props?.minimalRatingToShowComments || 2;
    const getRatingAriaLabel = React.useCallback(() => `Rating value is ${currentRating} of 5`, [currentRating]);

    return (
        <Stack>
            <StackItem>
                <Label>
                    {props?.ratingLabelText}
                </Label>
                <Rating
                    max={5}
                    size={RatingSize.Large}
                    allowZeroStars
                    rating={currentRating}
                    getAriaLabel={getRatingAriaLabel}
                    ariaLabelFormat="{0} of {1} stars"
                    onChange={(event: React.FormEvent<HTMLElement>, rating?: number) => {
                        setCurrentRating(rating || -1);
                        if (props && props.handleRatingChanges) {
                            props?.handleRatingChanges(rating, comment)
                        }
                    }}
                />
            </StackItem>
            {currentRating <= minimalRatingToShowComments && currentRating !== -1 &&
                <StackItem>
                    <TextField
                        label={props?.ratingCommentLabelText}
                        name={props?.ratingCommentLabelText}
                        ariaLabel={props?.ratingCommentLabelText}
                        placeholder={props?.ratingCommentPlaceHolderText}
                        validateOnFocusOut
                        multiline
                        rows={3}
                        resizable={false}
                        maxLength={5000}
                        validateOnLoad={false}
                        value={comment}
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => { setComment(newValue || "") }}
                        onBlur={() => {
                            if (props && props.handleRatingChanges) {
                                props?.handleRatingChanges(currentRating, comment)
                            }
                        }}
                    />
                </StackItem>
            }
        </Stack>
    )
}
