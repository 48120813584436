import GenericForm from '../../shared/Components/GenericFormComponent';
import { useForm } from 'react-hook-form';
import { useCommonPPContext } from '../common/context/common-pp-context';
import { IVertical } from '../../../models/IVertical';
import { IDealType } from '../../../models/IDealType';
import { ICountry } from '../../../models/ICountry';
import { IProductType } from '../../../models/IProductType';
import { SessionStorageConsts } from '../../../models/SessionStorageConsts';
import { getEntityDataFromSession } from '../../../components/shared/common/util';
import { CasePartnerDetailForm } from './CreateForm/CasePartnerDetailForm';

function CasePartnerDetail() {
    const onSubmit = (values: any) => console.log(JSON.stringify(values));
    const { formState: { errors, isDirty } } = useForm();
    const { commonPPContextState, commonPPContextDispatch } = useCommonPPContext();
    const verticals = getEntityDataFromSession<IVertical>(SessionStorageConsts.verticals)
    const dealTypes = getEntityDataFromSession<IDealType>(SessionStorageConsts.dealTypes)
    const countries = getEntityDataFromSession<ICountry>(SessionStorageConsts.countries)
    const productTypes = getEntityDataFromSession<IProductType>(SessionStorageConsts.productTypes)

    return (
        <GenericForm
            onSubmit={onSubmit}
            errors={errors}
            formJsonTemplate={CasePartnerDetailForm({ verticals, dealTypes, countries, productTypes, pageContext: commonPPContextState })}
            baseState={commonPPContextState} >
        </GenericForm>
    );
}

export default CasePartnerDetail;
