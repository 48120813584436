
import { _Styles } from '../../../../content/styles/Page.styles';
import { UIControlType } from '../../../../models/UIControlType';
import { PanelBarExpandMode } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { IGridProps } from "../../../../components/shared/grid/IGridProps";
import { getEntityDataFromSession, getLoggedinUserRoleDetails, readGridConfig, getRuleValidationMessage } from '../../../../components/shared/common/util';
import { GridType } from '../../../../models/GridType';
import { useCommonPPContext } from '../../common/context/common-pp-context';
import { IProductFamily } from '../../../../models/IProductFamily';
import { IOpportunityDealProductSku } from '../../../../models/IOpportunityDealProductSku';
import { nextGenService } from '../../../../services/NextGenService';
import { PageMode } from '../../../../models/PageMode';
import { IUserRoleDetail } from '../../../../models/IUserRoleDetail';
import { DealType } from '../../../../models/DealType';
import { IDealType } from '../../../../models/IDealType';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { GetCompactProductData, GetExpandedProductData } from '../../../../components/shared/productDatabyProductview';
import { OpportunityDealStatusCodes } from '../../../../models/OpportunityDealStatusCodes';
import { ProductStructure } from '../../../../models/ProductStructure';
import { UIControlsText } from '../../../../content/UIControlsText';
import { MessageTypeText } from '../../../../models/MessageTypeText';

export function PricingDiscount() {
    const { commonPPContextState } = useCommonPPContext();
    const [expandMode] = useState<PanelBarExpandMode>("single");
    const [BUproductGridConfig, setBUproductGridConfig] = useState<IGridProps>(readGridConfig(GridType.BUView));
    const userRoleDetails: IUserRoleDetail[] = getLoggedinUserRoleDetails();
    const [pfamCompactConfig, setPfamCompactConfig] = useState<IGridProps>(readGridConfig(GridType.DRSPCompactView));
    const [pfamExpandConfig, setPfamExpandConfig] = useState<IGridProps>(readGridConfig(GridType.DRSPExpandView));
    const [dealType, setdealType] = useState<string>("");
    const [expandGridVisible, setExpandGridVisible] = useState<boolean>(false);
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

    //to show BU view in pricing and discount page
    const BUViewGridData = (productData: IProductFamily[], caseNumber: string, dealName: string, customerName: string, customerCountry: string, dealStatusCode: number, dealTypeName?: string) => {
        var FinalData: IOpportunityDealProductSku[] = [];
        if (productData && productData.length > 0) {
            var buProducts = productData.filter(p => p.productStructure !== ProductStructure.BusinessUnit);
            let groupedbyBU = [
                ...new Map(
                    buProducts?.map((item: IProductFamily) => [item.businessUnitName, item])
                ).values(),
            ];
            if (groupedbyBU) {
                FinalData = groupedbyBU.map((item: any) => {
                    return {
                        decisionTypeName: item.decisionTypeName,
                        businessUnitName: item.businessUnitName,
                        productStructure: item.productStructure
                    } as IOpportunityDealProductSku
                })
            }
            let Config = { ...BUproductGridConfig };
            Config.kendoGridProps.data = FinalData;
            Config.kendoGridProps.dealName = dealName;
            Config.kendoGridProps.dealType = dealTypeName;
            Config.kendoGridProps.caseNumber = caseNumber;
            Config.kendoGridProps.customerName = customerName;
            Config.kendoGridProps.customerCountry = customerCountry;
            if (Config.kendoGridProps.actionTypes) {
                Config.kendoGridProps.actionTypes.showDownloadExcelGridAction = true;
            }
            setBUproductGridConfig({ ...Config });
        }
    }

    /// to get compact view product data for SP and/or DR
    const compactGridData = (productData: IOpportunityDealProductSku[], oppDealNumber: string, dealtypeName: string, statusCode: number, caseNumber: string, dealName: string, customerName: string, customerCountry: string, dealStatusCode: number) => {
        var selectedData: IOpportunityDealProductSku[] = [];
        if (productData.length !== 0) {
            selectedData = GetCompactProductData(productData, pfamCompactConfig, dealtypeName, dealStatusCode);
        }
        else {
            var products = commonPPContextState.opportunityDeals.filter(d => d.name === oppDealNumber)[0].products;
            if (products) {
                selectedData = products.map((item: any) => {
                    return {
                        decisionType: item.decisionTypeName,
                        productFamily: item.name,
                        businessUnitName: item.businessUnitName,
                        productStructure: item.productStructure
                    } as IOpportunityDealProductSku
                });
                
                if (statusCode !== OpportunityDealStatusCodes.Draft) {
                    selectedData = dealtypeName === DealType.SpecialPricing ? selectedData.filter(d => d.productStructure === ProductStructure.ProductFamily) : selectedData.filter(d => d.productStructure !== ProductStructure.BusinessUnit);
                }
            }
        }

        let compactGridConfig = { ...pfamCompactConfig };
        if (compactGridConfig.kendoGridProps.actionTypes) {
            compactGridConfig.kendoGridProps.actionTypes.showDownloadExcelGridAction = true;
        }
        compactGridConfig.kendoGridProps.resizable = true;
        compactGridConfig.kendoGridProps.data = selectedData;
        compactGridConfig.kendoGridProps.dealName = dealName;
        compactGridConfig.kendoGridProps.dealType = dealtypeName;
        compactGridConfig.kendoGridProps.caseNumber = caseNumber;
        compactGridConfig.kendoGridProps.customerName = customerName;
        compactGridConfig.kendoGridProps.customerCountry = customerCountry;
        compactGridConfig.kendoGridProps.totalRecords = selectedData.length;

        setPfamCompactConfig({ ...compactGridConfig });
    }

    /// to get expanded view product data for SP and/or DR
    const expandGridData = (productData: IOpportunityDealProductSku[], caseNumber: string, dealName: string, customerName: string, customerCountry: string, dealTypeName?: string) => {
        var selectedData: IOpportunityDealProductSku[] = [];
        if (productData.length !== 0) {
            pfamExpandConfig.kendoGridProps.dealType = dealTypeName;
            selectedData = GetExpandedProductData(productData, pfamExpandConfig);

            let expandGridConfig = { ...pfamExpandConfig };
            if (expandGridConfig.kendoGridProps.actionTypes) {
                expandGridConfig.kendoGridProps.actionTypes.showDownloadExcelGridAction = true;
            }
            var FinalData = selectedData.filter(d => d.businessUnitName != "");
            expandGridConfig.kendoGridProps.data = FinalData;
            expandGridConfig.kendoGridProps.dealName = dealName;
            expandGridConfig.kendoGridProps.dealType = dealTypeName;
            expandGridConfig.kendoGridProps.caseNumber = caseNumber;
            expandGridConfig.kendoGridProps.customerName = customerName;
            expandGridConfig.kendoGridProps.customerCountry = customerCountry;
            expandGridConfig.kendoGridProps.resizable = true;
            expandGridConfig.kendoGridProps.totalRecords = FinalData.length;
            setPfamExpandConfig({ ...expandGridConfig });
        }
    }

    useEffect(() => {
        const oppDealNumber = commonPPContextState.selectedOptyDealCaseNumber;
        if (oppDealNumber && oppDealNumber.length > 0) {
            var oppDeal = commonPPContextState.opportunityDeals.filter(d => d.name === oppDealNumber)[0];
            if (oppDeal) {
                var caseNumber = commonPPContextState.name || "";
                var dealName = oppDeal.dealName || "";
                var customerName = ""
                var customerCountry = ""
                if (oppDeal.dealCustomers && oppDeal.dealCustomers?.length > 0) {
                    customerName = oppDeal.dealCustomers[0].legalEntityName;
                    customerCountry = oppDeal.dealCustomers[0].country;
                }
                const dealTypes = getEntityDataFromSession<IDealType>(SessionStorageConsts.dealTypes);
                const dealTypeName = dealTypes.find(t => t.id === oppDeal.dealType?.id)?.name;
                setdealType(dealTypeName || "");
                BUViewGridData(oppDeal.products || [], caseNumber, dealName, customerName, customerCountry, oppDeal.statusCode || 0, dealTypeName);
                if (dealTypeName?.toLowerCase() === DealType.DealRegistration.toLowerCase() || dealTypeName?.toLowerCase() === DealType.SpecialPricing.toLowerCase()) {
                    const fetchProductsSKUAndPriceListData = async () => {
                        if (oppDeal.id) {
                            let response = await nextGenService.getOpportunityDealDiscountPeriodAndSkuMSRP(oppDeal.id, userRoleDetails, PageMode.View, oppDeal.dealType?.name || "")
                            expandGridData(response.data, caseNumber , dealName, customerName, customerCountry, dealTypeName);
                            compactGridData(response.data, oppDealNumber, dealTypeName, oppDeal.statusCode || 0, caseNumber, dealName, customerName, customerCountry, oppDeal.statusCode || 0);
                            var caseStatus = (oppDeal.statusCode === OpportunityDealStatusCodes.Approved || oppDeal.statusCode === OpportunityDealStatusCodes.DealPricingApprovedPendingChannelStatus);
                            setExpandGridVisible(caseStatus);
                        }
                    }
                    try {
                        fetchProductsSKUAndPriceListData()
                    } catch {
                    }
                }
            }
        }
    }, [commonPPContextState.opportunityDeals[0].products, commonPPContextState.selectedOptyDealCaseNumber])

    const ProductDiscountJson = [
        {
            type: "CustomTileCardComponent",
            data: {
                id: "ViewCardForPricingDetails",                
                baseState: commonPPContextState,
                name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PricingAndDiscountsText", UIControlsText.PricingAndDiscountsText),
                iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PricingAndDiscountsIcon", UIControlsText.PricingAndDiscountsIcon),
                components: [
                    {
                        type: 'Stack',
                        data: {
                            id: 'PricingDiscountsdetails',
                            'data-nextgen-parent-group': "View - Pricing and Discounts",
                            'data-nextgen-parentid': "View - Pricing and Discounts",
                            wrap: true,
                            horizontal: true,
                            components: [
                                {
                                    type: 'Col',
                                    visible: true,
                                    data: {
                                        align: "auto",
                                        components: [
                                            {
                                                type: "InnerHTMLContainer",
                                                data: {
                                                    contolType: UIControlType.PanelBarItemWithGrid,
                                                    expandMode: expandMode,
                                                    items: [{
                                                        title: "Business Unit View",
                                                        gridcongfig: BUproductGridConfig,
                                                        expanded: dealType === DealType.DealRegistration,
                                                        visible: dealType === DealType.DealRegistration
                                                    }, {
                                                        title: "Compact View",
                                                        gridcongfig: pfamCompactConfig,
                                                        expanded: dealType !== DealType.DealRegistration,
                                                        visible: true
                                                    }, {
                                                        title: "Expanded View",
                                                        gridcongfig: pfamExpandConfig,
                                                        expanded: false,
                                                        visible: expandGridVisible
                                                    }]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
        
    ]

    return ProductDiscountJson;
}