/**
 * Enum for Message Type.
 * @readonly
 * @enum {Enum}
 */
export enum MessageType {
    /**
    * ClaimsValidationError.
    */
    ClaimsValidationError = 1,
    /**
    * OpportunityValidationError.
    */
    OpportunityValidationError = 2,
    /**
    * FieldGuidanceText.
    */
    FieldGuidanceText = 3,
    /**
     * FieldPlaceHolderText.
     */
    FieldPlaceHolderText = 4,
    /**
    * FieldLabelText.
    */
    FieldLabelText = 5,
    /**
    * PopupMessageText.
    */
     PopupMessageText = 6,
}