/**
 * Enum for UploadLegalEntity.
 * @readonly
 * @enum {UploadLegalEntity}
 */
export enum UploadLegalEntity {

    /// <summary>
    /// Country
    /// </summary>
    Country = "Country",

    /// <summary>
    /// LegalEntityName
    /// </summary>
    LegalEntityName = "Legal Entity Name",

    /// <summary>
    /// TPID
    /// </summary>
    TPID = "TPID",

    /// <summary>
    /// CRMAccountId
    /// </summary>
    CustomerAccountId = "Customer Account Id",

    /// <summary>
    /// Segment
    /// </summary>
    Segment = "Customer Segment",

    /// <summary>
    /// CustomerVertical
    /// </summary>
    CustomerVertical = "Vertical",
    
    /// <summary>
    /// DealVolume
    /// </summary>
    DealVolume = "Deal Volume",

    /// <summary>
    /// UnmanagedFlag
    /// </summary>
    UnmanagedFlag = "Unmanaged Flag",


    /// <summary>
    /// IsPrimary
    /// </summary>
    IsPrimary = "is Primary",

    /// <summary>
    /// Error 
    /// </summary>
    Error = "error",

    /// <summary>
    /// IsManaged
    /// </summary>
    IsManaged = "Is Managed"
}