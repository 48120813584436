import React from "react";
import { useState } from "react";
import { CoherenceNav } from '@cseo/controls';
import { IRouteDetail } from "../../models/IRouteDetail";
import { getRouteConfig, getUserRole, isNullOrEmpty } from "./common/util";
import { useHistory } from "react-router";
import { INavProps } from "./props/INavProps";
import { ApplicationConstants } from "../../models/ApplicationConstants";
import { SessionStorageConsts } from "../../models/SessionStorageConsts";
import { UIControlsText } from "../../content/UIControlsText";

const LeftMenu: React.FunctionComponent<INavProps> = (props: INavProps) => {
    let history = useHistory();
    const [routeConfig] = useState<IRouteDetail[]>(getRouteConfig());
    const [userRole] = useState<string>(getUserRole());
    let script = document.createElement("script");
    script.src = process.env.REACT_APP_CHATBOT_URI || "";  
    document.head.appendChild(script);
    let isChatbotEnabled = sessionStorage.getItem(SessionStorageConsts.isChatbotEnabled);
    
    const processLeftMenu = () => {
        let menuItems: any = [];
        let routeConfigOptions = routeConfig;
        if(isChatbotEnabled !== UIControlsText.YesText){
            routeConfigOptions = routeConfig.filter(x => x.menuKey !== ApplicationConstants.Chatbot);
        }
        routeConfigOptions.filter(x => x.isMenuItem === true).map((configItem: IRouteDetail) => {
            if (!isNullOrEmpty(configItem.allowMenuItemUserRoles) && (configItem.allowMenuItemUserRoles.length == 0 || configItem.allowMenuItemUserRoles.some(r => userRole.includes(r)))) {
                let eachItem = {
                    name: configItem.menuName,
                    key: configItem.menuKey,
                    href: configItem.menuHref,
                    icon: configItem.menuIcon,
                    isHidden: configItem.isMenuHidden,
                    target: configItem.menuTarget,
                    isLoadNewTab: configItem.isLoadNewTab,
                    onClick: (ev?: React.MouseEvent<HTMLElement>, item?: any) => {
                        if (item.isLoadNewTab) {
                            redirectRoute(ev, item.href, item.target)
                        }
                        else if (item.key === ApplicationConstants.Chatbot) {
                            loadFDAChat()
                        }
                        else {
                            history.push(item.menuHref)
                        }

                    }
                }
                menuItems.push(eachItem)
            }
        });
        return menuItems;
    }
    const redirectRoute = (ev: any, routeLink: string, routeTarget: string): void => {
        switch (routeLink) {
            case "process.env.REACT_APP_CLAIMS_REDIRECT_URI":
                window.open(process.env.REACT_APP_CLAIMS_REDIRECT_URI, routeTarget)
                break
        };

        if (routeLink.indexOf("mailto") !== -1) {
            window.open(routeLink, routeTarget)
        }

        ev?.preventDefault();
    }

    const loadFDAChat = () => {
        (window as any).OpenFDA(`hostappid=`+ process.env.REACT_APP_CHATBOT_KEY,undefined);
    };

    return (
        <React.Fragment>
            <CoherenceNav
                appName={''}
                groups={[{
                    key: 'leftMenu',
                    links: processLeftMenu()
                }]}
                onNavCollapsed={props.onNavCollapsed}
            />
        </React.Fragment>
    );
}

export default LeftMenu;