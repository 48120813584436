import * as React from 'react';
import { useState } from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router';
import { getRouteConfig, getUserRole, getUserSession, isNullOrEmpty, getEntityDataFromSession, getRuleValidationMessage } from '../../components/shared/common/util';
import { ErrorPage } from '../../components/shared/error';
import { UIControlsText } from '../../content/UIControlsText';
import { IRouteDetail } from '../../models/IRouteDetail';
import { SessionStorageConsts } from '../../models/SessionStorageConsts';
import { MessageTypeText } from '../../models/MessageTypeText';

const AuthRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
    let isLoggedIn = useState<string>(getUserSession());
    const [routeConfig] = useState<IRouteDetail[]>(getRouteConfig());
    const [userRole] = useState<string>(getUserRole());
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    let locationObj = useLocation();
    if (!Component) return null;
    return (
        <React.Fragment>
                  <Route {...rest}
                render={() => {
                    let thisRouteConfig = routeConfig.filter(x => x.menuHref?.trim().toLowerCase() == rest.path);
                    if (isNullOrEmpty(isLoggedIn[0]) && locationObj && !isNullOrEmpty(locationObj.pathname)) {
                        sessionStorage.setItem(SessionStorageConsts.userRequestedUrL, locationObj.pathname + locationObj.search);
                    };
                    return !isNullOrEmpty(isLoggedIn[0])
                        ?
                        thisRouteConfig[0]?.allowRoutingUserRoles != null
                            && thisRouteConfig[0].allowRoutingUserRoles?.length != 0
                            && thisRouteConfig[0].allowRoutingUserRoles.some(r => userRole.includes(r)) ?
                            <Route {...rest} render={props => (<Component {...props} />)} />
                            : <ErrorPage {...{ customMessage: getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "UnauthorizedMessage", UIControlsText.UnauthorizedMessage), showBackToLogin: false, showSomethingWrongMessage: false }} />
                        : <Redirect to='/' />
                }} />
        </React.Fragment>
        )
}
export default AuthRoute;