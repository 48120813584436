
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ConstrainMode, DetailsListLayoutMode, SelectionMode } from 'office-ui-fabric-react';
import { GridOperations, IDetailListGridConfigProps, DetailListStyles } from './DetailListEditableGridTypes';
import { EditableGrid, IColumnConfig } from 'fluentui-editable-grid';
import { InitializeInternalGrid } from 'fluentui-editable-grid/dist/editablegrid/editablegridinitialize';
import "./PartnerGrid.css";

export const DetailListEditableGrid = (props: IDetailListGridConfigProps) => {
    const [listOfItems, setItems] = useState<any[]>([]);
    useEffect(() => {
        if(props?.items){
            var data : any[] = InitializeInternalGrid(props.items);
            setItems(data);
        }
        
    }, [props?.items]);

    const onGridSave = (data: any[]): void => {
        setItems([...data.filter(y => y._grid_row_operation_ !== GridOperations.Delete).map(x => {
            return {...x, '_grid_row_operation_': GridOperations.None}
        })]);
        
        //EventEmitter.dispatch(EventType.onSearch, event);
    };

    const attachGridValueChangeCallbacks = (columnConfig : IColumnConfig[]) : IColumnConfig[] => {
        return columnConfig;
    };

    let elements = Array.from(document.getElementsByClassName('ms-CommandBar') as HTMLCollectionOf<HTMLElement>)
        elements.forEach((element) => {
            element.remove();
        });

    return (
        <div className={`editablegridcontainer ${props.className}`}>
            <EditableGrid
                id={1}
                columns={attachGridValueChangeCallbacks(props?.columns)} 
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={ SelectionMode.none}
                constrainMode={ConstrainMode.horizontalConstrained}
                className={DetailListStyles.container}
                items={listOfItems}
                enableCellEdit={props?.gridConfigOptions?.enableCellEdit}
                enableGridRowsDelete={props?.gridConfigOptions?.enableGridRowsDelete}
                enableRowEdit={props?.gridConfigOptions?.enableRowEdit}
                enableRowEditCancel={props?.gridConfigOptions?.enableRowEditCancel}
                enableTextFieldEditMode={props?.gridConfigOptions?.enableTextFieldEditMode}
                enableColumnEdit={props?.gridConfigOptions?.enableColumnEdit}
                enableSave={props?.gridConfigOptions?.enableSave}
                enableUnsavedEditIndicator={props?.gridConfigOptions?.enableUnsavedEditIndicator}
                gridCopyOptions={{enableGridCopy: props?.gridConfigOptions?.enableGridCopy, enableRowCopy: props?.gridConfigOptions?.enableRowCopy}}
                height={props?.height}
                width={props?.width}
                position={'relative'}
                onGridSave={onGridSave}
                onGridUpdate={props.onGridItemsChange}
                enableDefaultEditMode={props.enableDefaultEditMode}
            />
        </div>
    );
}

