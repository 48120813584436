import XLSX from 'xlsx';
import { IGridProps } from "../grid/IGridProps"
import { IKendoGridColumnProps } from "../grid/IKendoGridColumnProps"
import GridConfig from "../grid/gridconfig.json"
import { ILegalEntity } from '../../../models/ILegalEntity';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { ICountry } from '../../../models/ICountry';
import { CustomerType } from '../../../models/CustomerType';
import { ILegalEntityValidationRule } from '../../../models/ILegalEntityValidationRule';
import { IPageState } from '../../../pages/pricingprogram/common/state/IPageState';
import { SessionStorageConsts } from '../../../models/SessionStorageConsts';
import { IPageModeProps } from '../../../pages/pagefieldmap/props/IPageModeProps';
import internalpricingfieldrolemap from "../../../../src/pages/pagefieldmap/internalpricing-fieldrolemap.json";
import { IPageMode } from '../../../pages/pagefieldmap/props/IPageMode';
import { IPageRole } from '../../../pages/pagefieldmap/props/IPageRole';
import { ApplicationConstants } from '../../../models/ApplicationConstants';
import { PageMode } from '../../../models/PageMode';
import { OpportunityDealStatusCodes } from '../../../models/OpportunityDealStatusCodes';
import { OpportunityDealStatusNames } from '../../../models/OpportunityDealStatusNames';
import { IRouteDetail } from '../../../models/IRouteDetail';
import RouteConfig from '../../../pages/home/route-config.json';
import { UIControlsText } from '../../../content/UIControlsText';
import { IAccount } from '../../../models/IAccount';
import { ActionType } from '../../../models/ActionType';
import { nextGenService } from '../../../services/NextGenService';
import { IUserRoleDetail } from '../../../models/IUserRoleDetail';
import { UserRole } from '../../../models/UserRole';
import { UserSubRole } from '../../../models/UserSubRole';
import { JavaScriptExtensions } from '../../../infrastructure/JavaScriptExtensions';
import Moment from 'moment';
import { LocalStorageConsts } from '../../../models/LocalStorageConsts';
import * as CryptoJS from 'crypto-js';
import { AsyncProcessStatus } from '../../../models/AsyncProcessStatus';
import commonfieldmap from "../../../pageConfig/common-fieldmap.json"
import { DealType } from '../../../models/DealType';
import { IFieldMap } from '../../../pageConfig/Props/IFieldMap';
import { FieldMapMode } from '../../../models/FieldMapMode';
import { DealStatusLabel } from '../../../models/DealStatusLabels';
import { IDealType } from '../../../models/IDealType';
import { ErrorMessages } from '../../../content/ErrorMessages';
import { IDealState } from '../../../pages/pricingprogram/common/state/IDealState';
import { Guid } from '../../../infrastructure/Guid';
import { IUserDetail } from '../../../models/IUserDetail';
import { IDealQuantityThresholds } from '../../../models/IDealQuantityThresholds';
import { IKendoGridProps } from '../grid/IKendoGridProps';
import { IMultiSelectDropDownSessionProps } from '../props/IMultiSelectSessionProps';
import { SpecialLocationtype } from '../../../pages/shared/ComponentTypes/SpecialLocationtype';
import { RequestorsRoleType } from '../../../pages/shared/ComponentTypes/RequestorRoleType';
import { IChannelFunctionalityDisplayState } from '../../../pages/shared/ComponentTypes/IChannelFunctionalityDisplayState';
import { ChannelDisplayFunctionalities } from '../../../pages/shared/ComponentTypes/ChannelDisplayFunctionalities';
import { OpportunityDealClosureDecisionType } from '../../../models/OpportunityDealClosureDecisionType';
import { OpportunityDealClosureDecisionName } from '../../../models/OpportunityDealClosureDecisionName';
import { OpportunityDealPortalEditStatusNames } from '../../../models/OpportunityDealPortalEditStatusNames';
import { IDealDurationConfig } from '../../../models/IDealDurationConfig';
import { IProductType } from '../../../models/IProductType';
import { GridColumnMenuProps } from '@progress/kendo-react-grid';
import { StatusCodeFilterValues } from '../../../services/StaticData';
import _ from 'lodash';
import { MessageTypeText } from '../../../models/MessageTypeText';
import { PageConstants } from '../../../pages/pageconstants/Constants';
const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
//funciton helps to process excel file
export function processExcelDataToJson(fileObj: any, callback: any): void {
    const reader = new FileReader(); //read the file
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e: any) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws);
        let jsonData: any = [...data];
        callback(jsonData);
    };
    if (rABS) {
        reader.readAsBinaryString(fileObj.file);
    } else {
        reader.readAsArrayBuffer(fileObj.file);
    };
}

//TextField OnChange Function
export const getTextFieldErrorMessage = (value: string): string => {
    return !value ? getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredFieldError", ErrorMessages.RequiredFieldError) : "";
};

/**
* Get TextField ErrorMessage.
* @method
* @param {string>} value Text Box value.
* @@returns {string} Error Message.
*/
export const getEmailFieldErrorMessage = (value: string): string => {
    if (!value) {
        return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredFieldError", ErrorMessages.RequiredFieldError);
    }
    else {
        let isInvalid = value.split(';').some((email) => !isValidEmail(email));
        if (isInvalid) {
            return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidEmailError", ErrorMessages.ValidEmailError);
        }
    }
    return '';
};

/**
     * Get PostalCode TextField ErrorMessage.
     * @method
     * @param {string>} value Text Box value.
     * @@returns {string} Error Message.
     */
export const getPostalCodeTextFieldErrorMessage = (value: string): string => {
    if (!value) {
        return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "PostalCodeRequired", ErrorMessages.PostalCodeRequired);
    } else {
        let isInvalid = !isValidPostalCode(value);
        if (isInvalid) {
            return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidPostalCodeError", ErrorMessages.ValidPostalCodeError);
        }
    }
    return '';
}

//this function used to read config value from /components/shared/grid/gridconfig and can consume anywhere
export function readGridConfig(gridName: string, colVisible?: boolean, colName?: string): IGridProps {
    var gridConfigs: IGridProps[] = [];
    gridConfigs = GridConfig.map((item: any) => {
        return {
            kendoGridProps: {
                gridName: item.gridName,
                gridDisplayName: item.gridDisplayName,
                selectable: item.selectable,
                resizable: item.resizable,
                pageable: item.pageable,
                dragable: item.dragable,
                sortable: item.sortable,
                filterable: item.filterable,
                groupable: item.groupable,
                noOfColumns: item.noOfColumns,
                noOfRecords: item.noOfRecords,
                initialSortField: item.initialSortField,
                dataItemKey: item.dataItemKey,
                selectedField: item.selectedField,
                selectionType: item.selectionType,
                editField: item.editField,
                className: item.className,
                expandField: item.expandField,
                editMode: item.editMode,
                selectAllRows: item.selectAllRows,
                customDetailComponent: item.customDetailComponent,
                pageSizes: item.pageSizes,
                actionTypes: {
                    showAddNewRowGridAction: item.actionTypes?.showAddNewRowGridAction,
                    showRowEditGridAction: item.actionTypes?.showRowEditGridAction,
                    showRowDeleteGridAction: item.actionTypes?.showRowDeleteGridAction,
                    showRowDownloadGridAction: item.actionTypes?.showRowDownloadGridAction,
                    showSelectAllCheckboxGridAction: item.actionTypes?.showSelectAllCheckboxGridAction,
                    showGridContextMenuAction: item.actionTypes?.showGridContextMenuAction,
                    showClearAllGridAction: item.actionTypes?.showClearAllGridAction,
                    showDownloadExcelGridAction: item.actionTypes?.showDownloadExcelGridAction,
                    showExpandCollapseGridAction: item.actionTypes?.showExpandCollapseGridAction,
                    showActionMenuBar: item.actionTypes?.showActionMenuBar,
                    showRadioSelectionAction: item.actionTypes?.showRadioSelectionAction,
                    showMultiSelectDropDownGridAction: item.actionTypes?.showMultiSelectDropDownGridAction,
                    showAddNewPanelGridAction: item.actionTypes?.showAddNewPanelGridAction,
                    showManufactureConfirmationLetter: item.actionTypes?.showManufactureConfirmationLetter
                },
                additionalColumns: item.additionalColumns?.map((column: IKendoGridColumnProps) => {
                    return {
                        apiField: column.apiField,
                        columnTitle: column.columnTitle,
                        key: column.key,
                        showHeaderCell: column.showHeaderCell,
                        allowFilter: column.allowFilter,
                        width: column.width,
                        isVisible: column.apiField === colName ? colVisible : column.isVisible,
                        cssClass: column.cssClass,
                        excelColumnTitle: column.excelColumnTitle,
                    } as IKendoGridColumnProps
                }),
                data: item.data,
                displayOrder: item.displayOrder,
                allowCaseView: item.allowCaseView,
                allowCaseCreate: item.allowCaseCreate,
                allowCaseDownload: item.allowCaseDownload,
                allowCaseEdit: item.allowCaseEdit,
                allowCaseRevert: item.allowCaseRevert,
                allowCaseDelete: item.allowCaseDelete,
                allowCaseExpire: item.allowCaseExpire,
                allowCaseLetters: item.allowCaseLetters,
                allowCaseDecisionLetter: item.allowCaseDecisionLetter,
                defaultMainGridSelectionstate: item.defaultMainGridSelectionstate,
                defaultDetailGridSelectionstate: item.defaultDetailGridSelectionstate,
                allowCaseCopy: item.allowCaseCopy,
                allowCaseExtend: item.allowCaseExtend,
                allowCaseRenew: item.allowCaseRenew,
                recordsToFetch: item.recordsToFetch,
                excelData: item.excelData,
                excelColumns: item.excelColumns,
                customGridMessage: item.customGridMessage,
                dealName: item.dealName,
                caseNumber: item.caseNumber,
                customerCountry: item.customerCountry,
                customerName: item.customerName,
                filterValue: item.filterValue,
                isRefresh: item.isRefresh,
                isFilterAppliedOrRemoved: item.isFilterAppliedOrRemoved,
                columns: item.columns.map((column: any) => {
                    return {
                        apiField: column.apiField,
                        columnTitle: column.columnTitle,
                        allowColumnView: column.allowColumnView,
                        showHeaderCell: column.showHeaderCell,
                        key: column.key,
                        isEditable: column.isEditable,
                        editorType: column.editorType,
                        editorFormat: column.editorFormat,
                        isVisible: column.apiField === colName ? colVisible : column.isVisible,
                        allowFilter: column.allowFilter,
                        allowSorting: column.allowSorting,
                        allowGroupable: column.allowGroupable,
                        showFooter: column.showFooter,
                        displayOrder: column.displayOrder,
                        width: column.width,
                        cssClass: column.cssClass,
                        footerTitle: column.footerTitle,
                        filterType: column.filterType,
                        categoryFilterCell: column.categoryFilterCell,
                        mandatoryField: column.mandatoryField,
                        isCustomCell: column.isCustomCell,
                        type: column.type,
                        isDefaultSelected: column.isDefaultSelected,
                        isDisabled: column.isDisabled,
                        locked: column.locked,
                        isDynamic: column.isDynamic,
                        isDefaultFilterTobeApplied: column.isDefaultFilterTobeApplied,
                        headerClassName: column.headerClassName
                    } as IKendoGridColumnProps
                }),
                groupByColumn: item.groupByColumn,
                masterData: item.masterData,
                detailGrid: item.detailGrid,
                isServerSideGrid: item.isServerSideGrid,
                width: item.width,
                noRecordsCustomMessage: item.noRecordsCustomMessage,
                addNewButtonText: item.addNewButtonText
            }
        } as IGridProps
    });
    return gridConfigs.filter((item) => item.kendoGridProps.gridName === gridName)[0];
}
//function to validate null or empty
export function isNullOrEmpty(value: any) {
    return value === undefined || value === null || value === "";
}
//common function used to return idropdownoption from string array
export function getSelectedCountry(dealCountries: string[]): IDropdownOption[] {
    var countries = sessionStorage.getItem("countries");
    var countriesDropdownValues: IDropdownOption[] = [];
    if (countries !== null) {
        JSON.parse(countries).map((pt: ICountry) => {
            if (dealCountries.some(x => x === pt.countryId)) {
                countriesDropdownValues.push({ key: pt.countryId, text: pt.countryName } as IDropdownOption);
            }
        });
    }
    return countriesDropdownValues;
}
//function to validate error messages as part of excel upload
export function validateLegalEntityApiResponse(apiValidations: ILegalEntityValidationRule[]): [] {
    let validationResult: any = []
    let errors: any = apiValidations.filter(x => x.passed === false && x.error.length > 0);
    let warnings: any = errors = apiValidations.filter(x => x.passed && x.isWarning && x.error.length > 0);
    validationResult.push({ errors: errors, warnings: warnings });
    return validationResult;
}
//convert excel file to buffer array
export function getFileArrayBuffer(fileObj: any, callback: any): void {
    var reader = new FileReader();
    reader.onload = function () {
        var arrayBuffer = this.result;
        callback(arrayBuffer);
    }
    reader.readAsArrayBuffer(fileObj.file);
}
//common method for search and upload entities to construct final model
export function processLegalEntityModel(selectedLegals: any, primaryLegalID: any): ILegalEntity[] {
    var legalData = selectedLegals.map((item: any) => {
        return {
            id: item.id,
            customerId: item.id,
            country: item.country,
            legalEntityName: item.legalEntityName,
            tpid: item.tpid,
            crmAccountId: item.crmAccountId,
            segment: item.segment,
            unitsCommitted: item.unitsCommitted,
            isManaged: item.isManaged,
            unManaged: !item.isManaged,
            vertical: item.vertical,
            customerType: item.id === primaryLegalID ? CustomerType.Primary : CustomerType.Affiliates,
            errors: item.errors,
            warnings: item.warnings,
            action: item.action
        } as ILegalEntity
    });
    return legalData;
}

export function exportToExcel(excelJsonData: any, excelName: string, sheetName: string): void {
    let today = new Date();
    const fileName = "".concat(excelName, '_', today.getTime().toString(), '.xlsx',);
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([excelJsonData]);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    ws['!cols'] = [{ wch: 10 }, { wch: 20 }, { wch: 10 }, { wch: 15 }, { wch: 15 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, fileName);
}
//get entire saved IPageState from API, this is used to compare saved data in edit mode
export function getsavedContext(): IPageState {
    let savedState: IPageState = {} as IPageState;
    let savedOpportunityInSession = localStorage.getItem(LocalStorageConsts.savedOpportunity);
    if (savedOpportunityInSession !== null) {
        savedState = JSON.parse(savedOpportunityInSession);
    }
    return savedState;
}

//this function used to read config value for role to field level mapping as per json defined in cpstrategic_fieldrolemap.json
export function readPageConfig(pageName: string): IPageModeProps {
    var pageConfigs: IPageModeProps[] = [];
    pageConfigs = internalpricingfieldrolemap.filter((item: any)  => item.pageName === pageName).map((item: any) => {
        return {
            pageName: item.pageName,
            pageMode: item.pageMode.map((pmode: IPageMode) => {
                return {
                    fieldsToReadOnly: pmode.fieldsToReadOnly,
                    modeType: pmode.modeType,
                    roles: pmode.roles?.map((role: IPageRole) => {
                        return {
                            fieldsToHide: role.fieldsToHide,
                            fieldsToReadOnly: role.fieldsToReadOnly,
                            fieldsToEdit: role.fieldsToEdit,
                            roleName: role.roleName
                        } as IPageRole
                    })
                } as IPageMode
            })
        } as IPageModeProps
    });
    return pageConfigs.filter((item) => item.pageName === pageName)[0];
}

export function readCommonConfig(pageName: string) {
    let config = commonfieldmap.map(item => {
        return {
            pageName: item.pageName,
            pageModes: item.modes.map(function (mode: any) {
                return {
                    pageMode: mode.pageMode,
                    dealStatus: mode.dealStatus,
                    dealType: mode.dealType?.map((dt: DealType) => dt as DealType),
                    roles: mode.roles,
                    disabledFields: mode.disabledFields,
                    hiddenFields: mode.hiddenFields
                } as IFieldMap
            })
        }
    });
    return config.find((item) => item.pageName === pageName)?.pageModes;
}

//Common function to get the fieldmap config
export function getFieldConfig(pageName: string, pageMode: string, dealStatus: OpportunityDealStatusCodes, dealType: IDealType, fieldName: string, mode: FieldMapMode, closureDecision?: string | undefined): boolean | undefined {
    let disabled: boolean | undefined = undefined;
    const pageConfig = readCommonConfig(pageName);
    let fieldConfig = pageConfig?.filter(config => config.pageMode === pageMode && config.dealStatus.some(s => s === DealStatusLabel.get(dealStatus)));
    if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(fieldConfig)) {
        //filter the config by dealType, if dealType is defined
        fieldConfig = fieldConfig?.filter(config => JavaScriptExtensions.isNullOrUndfinedOrEmpty(config.dealType) || (config.dealType && config.dealType.some(t => t === dealType?.name)));
        //filter the config by role, if role is defined
        fieldConfig = fieldConfig?.filter(config => JavaScriptExtensions.isNullOrUndfinedOrEmpty(config.roles) || (config.roles && config.roles.some(r => getLoginUserRoles().includes(r))));
        //filter the config by closureDecision, if closureDecision is defined
        fieldConfig = fieldConfig?.filter(config => JavaScriptExtensions.isNullOrUndfinedOrEmpty(config.closureDecision) || (config.closureDecision && config.closureDecision.some(c => c === closureDecision)));
        if (mode === FieldMapMode.Disable) {
            fieldConfig = fieldConfig?.filter(f => f.disabledFields?.some(d => d === fieldName));
        }
        else if (mode === FieldMapMode.Hidden) {
            fieldConfig = fieldConfig?.filter(f => f.hiddenFields?.some(d => d === fieldName));
        }
        if (fieldConfig && fieldConfig.length > 0) {
            disabled = true;
        }
    }
    return disabled;
}

//Common function to get the fieldmap config
export function getFieldConfigByState(state: IPageState, fieldName: string, mode: FieldMapMode): boolean | undefined {
    const selectedDeal = getSelectedDeal(state);
    if (state?.pageMode && selectedDeal?.dealType && selectedDeal?.statusCode) {
        const fieldConfigState = getFieldConfig("commonPP", state?.pageMode, selectedDeal?.statusCode, selectedDeal?.dealType, fieldName, mode);
        return fieldConfigState === true ? true : false;
    }
}

// Get Selected Deal from Dashboard in View/Edit Mode
export function getSelectedDeal(state: IPageState): IDealState | undefined {
    const selectedDeal = state?.pageMode === PageMode.Create ? state?.opportunityDeals[0] :
        state?.opportunityDeals?.find(t => t.name === state?.selectedOptyDealCaseNumber);
    return selectedDeal;
}

//this method used to identify whether filed is editable or not based on config: cpstrategic_fieldrolemap.json
export function isFieldDisabled(fieldName: string, modeType?: string, caseStatus?: number, isMultiNational?: boolean, asyncProcessStatus?: AsyncProcessStatus, dealType?: string): boolean {
    // Handle CP Strategic deal type as it has space in between
    let dealTypePageName = dealType?.trim().replace(/\s+/g,'')?.toLowerCase();

    if (modeType?.toLowerCase() === PageMode.Create.toLowerCase()) {
        let currentUserRoles = getUserRole();
        let routeConfig = getRouteConfig();
        
        let internalPricingConfig = routeConfig.filter(x => (x?.menuHref && x?.menuHref?.indexOf("managecase") > 0) && x.routeName?.trim().toLowerCase() == (dealTypePageName));
        if (!isNullOrEmpty(internalPricingConfig) && internalPricingConfig.length > 0 && !internalPricingConfig[0]?.allowMenuItemUserRoles.some(r => currentUserRoles?.includes(r))) {
            return true;
        }
        else {
            return false;
        }
    }
    if (modeType?.toLowerCase() === PageMode.View.toLowerCase()) {
        return true;
    }
    var isDisabled = true;
    if (modeType?.toLowerCase() === PageMode.Edit.toLowerCase()) {
        if (getsavedContext().opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.PendingSecondaryReview) {
            var fieldsToEnable = [
                getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalInformation", UIControlsText.AdditionalInformation),
                getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachment", UIControlsText.Attachment),
                getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Submit", UIControlsText.SubmitText),
            ]

            return isNullOrEmpty(fieldsToEnable.find(x => x === fieldName));
        }

        var loginRole = getOwnerInfo();
        var pageConfig = readPageConfig(dealTypePageName || "")?.pageMode.filter(m => m.modeType === modeType)
        pageConfig?.map((item: IPageMode, i: number) => {
            if (item.roles[i].roleName.indexOf(loginRole) !== -1) {
                if (caseStatus === OpportunityDealStatusCodes.Draft && asyncProcessStatus != AsyncProcessStatus.InProgress) {
                    isDisabled = false;
                }
                else if (item.roles[i].fieldsToEdit?.indexOf(fieldName) !== -1 && !isMultiNational &&
                    (caseStatus === OpportunityDealStatusCodes.Approved || caseStatus === OpportunityDealStatusCodes.DealPricingApprovedPendingChannelStatus || caseStatus === OpportunityDealStatusCodes.Incomplete || caseStatus === OpportunityDealStatusCodes.PendingSecondaryReview)) {
                    isDisabled = false;
                }
            }
            else
                isDisabled = true;
        });
    }
    return isDisabled;
}

//this method used to get urlparameter by name
export function getURLParamValue(paramName: string): string {
    const search = window.location.search;
    const params = search && new URLSearchParams(search);
    return params && params.get(paramName) as string;
}

//this methed will ideintify whether the page is editmode based on case status 
export function isEditmode(caseStatus?: number, isSavedItem?: boolean): boolean {
    var editmode = false;
    if ((caseStatus === OpportunityDealStatusCodes.Approved || caseStatus === OpportunityDealStatusCodes.DealPricingApprovedPendingChannelStatus || caseStatus === OpportunityDealStatusCodes.PendingSecondaryReview) && isSavedItem) {
        editmode = true;
    }
    else {
        editmode = false;
    }
    return editmode;
}

// this method will return the user role
export function getLoggedinUserRoleDetails() {
    let userSession = getUserSession();
    return JSON.parse(userSession || '{}')[SessionStorageConsts.userRoleDetails];
}

// this method will return the user role
export function getLoggedinUserRole() {
    let userSession = getUserSession();
    return JSON.parse(userSession || '{}')[SessionStorageConsts.userSubRole];
}

// this method is used to validate against logged in person role and return true/false to show the control on a page
export function isFieldVisible(fieldName: string, modeType?: string, dealTypePageName?: string | undefined) {
    let savedOpportunity = getsavedContext() 

    if (fieldName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalInformation", UIControlsText.AdditionalInformation)) {
        if (modeType?.toLowerCase() === PageMode.Create.toLowerCase()) {
            return false
        }

        if (modeType?.toLowerCase() === PageMode.Edit.toLowerCase() && savedOpportunity != null && savedOpportunity.opportunityDeals != null && savedOpportunity.opportunityDeals[0]?.statusCode !== OpportunityDealStatusCodes.PendingSecondaryReview) {
            return false;
        }

        return true;
    }

    if (savedOpportunity != null && savedOpportunity.opportunityDeals != null && savedOpportunity.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.PendingSecondaryReview) {
        if (fieldName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LegalEntitiesSearchElements", UIControlsText.LegalEntitiesSearchElements) || 
            fieldName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Add V-Team Member", UIControlsText.DealVTeamMemberAddtext)) {
            return false;
        }
    }

    var isVisible = true;
    var loginRole = getOwnerInfo();
    if (dealTypePageName && !isNullOrEmpty(dealTypePageName)) {
        let dealtypepage = dealTypePageName?.trim()?.replace(/\s/g, "")?.toLowerCase();        
        var pageConfig = readPageConfig(dealtypepage.trim().toLowerCase())?.pageMode.filter(m => m.modeType.toLowerCase() === modeType);
        if (loginRole === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Owner", UIControlsText.Owner)) {
            if (JavaScriptExtensions.isDefined(pageConfig) && pageConfig?.length > 0) {
                pageConfig[0].roles.forEach((role) => {
                    if (role.roleName.indexOf(loginRole) !== -1) {
                        if (role.fieldsToHide?.indexOf(fieldName) !== -1) {
                            isVisible = false;
                            return;
                        }
                    }
                });
            }
        }
        else {
            var roles = getUserMainRole().concat(",").concat(getUserRole());
            var userRole = roles.split(",");
            if (JavaScriptExtensions.isDefined(pageConfig) && pageConfig?.length > 0) {
                pageConfig[0].roles.forEach((role) => {
                    userRole.forEach((loginRole) => {
                        if (role.roleName.indexOf(loginRole) !== -1) {
                            if (role.fieldsToHide?.indexOf(fieldName) !== -1) {
                                isVisible = false;
                                return;
                            }
                        }
                    });
                });
            }
        }
    }
    return isVisible;
}

// this method will remove the unwanted spaces from the text field
export const removeExtraSpace = (s: string) => s.trim().split(/ +/).join(" ");

// this method is used to return statusCode name from statusCode value
export function getDealClosureDecisionNameByCode(statusCode?: number): any {
    switch (statusCode) {
        case OpportunityDealClosureDecisionType.ApprovedPendingChannel:
            return OpportunityDealClosureDecisionName.ApprovedPendingChannel;

        case OpportunityDealClosureDecisionType.EditPendingReview:
            return OpportunityDealClosureDecisionName.EditPendingReview;

        case OpportunityDealClosureDecisionType.Approved:
            return OpportunityDealClosureDecisionName.Approved;

        case OpportunityDealClosureDecisionType.Denied:
            return OpportunityDealClosureDecisionName.Denied;

        case OpportunityDealClosureDecisionType.InsufficientInformation:
            return OpportunityDealClosureDecisionName.InsufficientInformation;

        case OpportunityDealClosureDecisionType.RequireAdditionalInformation:
            return OpportunityDealClosureDecisionName.RequireAdditionalInformation;

        default:
            break;
    }
}

// this method is used to return statusCode name from statusCode value
export function getStatusNameByCode(statusCode?: number): any {
    switch (statusCode) {
        case OpportunityDealStatusCodes.Cancelled:
            return OpportunityDealStatusNames.Cancelled;

        case OpportunityDealStatusCodes.Draft:
            return OpportunityDealStatusNames.Draft;

        case OpportunityDealStatusCodes.Approved:
            return OpportunityDealStatusNames.Approved;

        case OpportunityDealStatusCodes.ForcedExpire:
            return OpportunityDealStatusNames.ForcedExpire;

        case OpportunityDealStatusCodes.DealPricingApprovedPendingChannelStatus:
            return OpportunityDealStatusNames.DealPricingApprovedPendingChannel;

        case OpportunityDealStatusCodes.InProgress:
        case OpportunityDealStatusCodes.Unassigned:
            return OpportunityDealStatusNames.DecisionPending;

        case OpportunityDealStatusCodes.Denied:
            return OpportunityDealStatusNames.Denied;

        case OpportunityDealStatusCodes.Expired:
            return OpportunityDealStatusNames.Expired;

        case OpportunityDealStatusCodes.Incomplete:
            return OpportunityDealStatusNames.Incomplete;

        case OpportunityDealStatusCodes.DraftRenew:
            return OpportunityDealStatusNames.DraftRenew;

        case OpportunityDealStatusCodes.InsufficientInformation:
            return OpportunityDealStatusNames.InsufficientInformation;

        case OpportunityDealStatusCodes.PendingSecondaryReview:
            return OpportunityDealStatusNames.PendingSecondaryReview;

        default:
            break;
    }
}

export function getUserDetails(): IUserDetail {
    var userDetails = getUserSession()
    if (userDetails == null) {
        return <IUserDetail>{}
    }

    return JSON.parse(userDetails);
}

//Function converts route config json to IRouteDetails and return IRouteDetails information.
export function getRouteConfig(): IRouteDetail[] {
    var routeConfigs: IRouteDetail[] = [];
    routeConfigs = RouteConfig.map((item: any) => {
        return {
            routeName: item.routeName,
            allowRoutingUserRoles: item.allowRoutingUserRoles,
            allowMenuItemUserRoles: item.allowMenuItemUserRoles,
            isMenuItem: item.isMenuItem,
            menuName: item.menuName,
            menuKey: item.menuKey,
            menuHref: item.menuHref,
            menuIcon: item.menuIcon,
            isMenuHidden: item.isMenuHidden,
            menuTarget: item.menuTarget,
            isLoadNewTab: item.isLoadNewTab
        } as IRouteDetail
    });
    return routeConfigs;
}

//Function to  return logged in user sub role information
export function getUserRole(): string {
    let userSession = getUserSession();
    if (!isNullOrEmpty(userSession)) {
        let loggedInUserInfo = JSON.parse(userSession);
        return Array.prototype.map.call(loggedInUserInfo.userRoleDetails, s => s.userSubRole).toString();
    }
    else {
        return "";
    }
}

//this to get user role for logged in person. we will be validating both userrole/sub role for
export function getUserMainRole(): string {
    let userSession = getUserSession();
    if (!isNullOrEmpty(userSession)) {
        let loggedInUserInfo = JSON.parse(userSession);
        var roleArray = Array.prototype.map.call(loggedInUserInfo.userRoleDetails, s => s.userRole);
        let uniqueItems = [...new Set(roleArray)];
        return uniqueItems.toString();
    }
    else {
        return "";
    }
}
//Function to  return User Session Object
export function getUserSession(): any {
    let userSession = sessionStorage.getItem(SessionStorageConsts.loggedInUserInfo);
    if (!isNullOrEmpty(userSession)) {
        return decryptString(JSON.stringify(userSession));
    }
    else {
        return null;
    }
}

//Sort function for Panel in CP Strategic. //Todo Remove later
export const sortArray = (items: Array<any>) => {
    return items.sort((a, b) => {
        return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1;
    });
}

//sort array data by object name and data type
export const sortArrayData = (items: Array<any>, dataType: string, propertyName: string) => {
    return items.sort((a, b): any => {
        switch (dataType) {
            case 'name':
                return (a[propertyName].toLowerCase() > b[propertyName].toLowerCase()) ? 1 : -1;
            case 'number':
                return a[propertyName] - b[propertyName];
            default: break;
        }
    });
}

//returns if IOpportunityAccount is editable based on Id.
export function isEditableAccountById(id?: string) {
    let isSavedItem = getsavedContext().opportunityDeals && getsavedContext().opportunityDeals[0]?.statusCode !== OpportunityDealStatusCodes.Draft && getsavedContext().opportunityAccounts?.some(acc => acc.id === id);
    return !isSavedItem;
}

//this method helps to identify if the account item can editable based on pagemode and case status.
export function isEditableAccount(countryId?: string, pageMode?: string, statusCode?: number, accountItem?: IAccount, partnerType?: number) {
    var editMode = false;
    var isSavedItem = false;
    if (accountItem) {
        //check if item is already saved and not allow to uncheck if case is not in draft status
        isSavedItem = isSavedAccount(countryId, partnerType, accountItem);
    }
    if (pageMode?.toLowerCase() === PageMode.View.toLowerCase()) {
        editMode = true;
    }
    else if (pageMode?.toLowerCase() === PageMode.Edit.toLowerCase() && statusCode !== OpportunityDealStatusCodes.Draft && isSavedItem) {
        editMode = true;
    }
    return editMode;
}

export function isSavedAccount(countryId?: string, partnerType?: number, accountItem?: IAccount) {
    var isSavedItem = false;
    var items = [];
    items = getsavedContext().opportunityAccounts?.filter(o => o.countryId === countryId) || [];
    if (partnerType) {
        items = items?.filter(o => o.partnerType === partnerType) || [];
    }
    if (items?.length > 0 && items[0].accounts) {
        if (items[0].accounts?.findIndex(item => item.accountId && item.accountId === accountItem?.accountId) !== -1) {
            isSavedItem = true;
        }
    }
    return isSavedItem
}
//this method will identify logged in person is owner/ co-owner of the case he is editing/view
export function getOwnerInfo() {
    var userRole = "";
    var savedContext = getsavedContext();
    var submitterInfo = savedContext.submitterDetails;
    var dealContactInfo = savedContext.opportunityDeals && savedContext.opportunityDeals[0].dealContacts;
    var loggedUserInfo = JSON.parse(getUserSession());

    if ((submitterInfo?.email.toLowerCase() === loggedUserInfo.email.toLowerCase()) ||
        (dealContactInfo && dealContactInfo?.findIndex(c => c.isCoOwner === true && c.email?.toLowerCase() === loggedUserInfo.email.toLowerCase()) != -1)) {
        userRole = getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Owner", UIControlsText.Owner);
    }
    return userRole;
}

//validate account is already saved
export function savedAccountAction(countryId?: string, partnerType?: number, accountItem?: IAccount) {
    var action = "";
    var items = getsavedContext().opportunityAccounts?.filter(o => o.countryId === countryId && o.partnerType === partnerType) || [];
    if (items?.length > 0 && items[0].accounts) {
        var idx = items[0].accounts?.findIndex(item => item.accountId && item.accountId === accountItem?.accountId);
        if (idx !== -1) {
            action = items[0].accounts[idx].action || "";
        }
    }
    return action
}

//this method will return action at child level, when already saved, return action from API otherwise "Add" action
export function getAction(account: IAccount, countryId?: string, partnerType?: number) {
    var savedAction = savedAccountAction(countryId, partnerType, account);
    if (savedAction !== "") {
        savedAction = savedAction;
    }
    else {
        savedAction = ActionType.Add;
    }
    return savedAction;
}

//validate account is already saved
export function isPartnerAccount(countryId?: string, partnerType?: number, accountItem?: IAccount, dmpAccountID?: string) {
    var isSaved = false;
    var dmpAccount = getsavedContext().opportunityAccounts?.find(r => r.countryId === countryId && r.partnerType === partnerType && r.accounts?.find(a => a.accountId === dmpAccountID));
    if (dmpAccount && dmpAccount?.partnerAccounts) {
        var idx = dmpAccount?.partnerAccounts && dmpAccount?.partnerAccounts[dmpAccountID as string]?.findIndex(item => item.accountId && item.accountId === accountItem?.accountId);
        if (idx !== -1 && dmpAccount?.partnerAccounts[dmpAccountID as string]) {
            isSaved = true
        }
    }
    return isSaved;
}

//validate account is already saved
export function savedPartnerAccount(countryId?: string, partnerType?: number, accountItem?: IAccount, dmpAccountID?: string) {
    var action = "";
    var dmpAccount = getsavedContext().opportunityAccounts?.find(r => r.countryId === countryId && r.partnerType === partnerType && r.accounts?.find(a => a.accountId === dmpAccountID));
    if (dmpAccount && dmpAccount?.partnerAccounts) {
        var idx = dmpAccount?.partnerAccounts && dmpAccount?.partnerAccounts[dmpAccountID as string]?.findIndex(item => item.accountId && item.accountId === accountItem?.accountId);
        if (idx !== -1 && dmpAccount?.partnerAccounts[dmpAccountID as string]) {
            action = dmpAccount?.partnerAccounts && dmpAccount?.partnerAccounts[dmpAccountID as string][idx].action || "";
        }
    }
    return action
}

//this method will return action at child level, when already saved, return action from API otherwise "Add" action
export function getPartnerAction(account: IAccount, countryId?: string, partnerType?: number, dmpAccountID?: string) {
    var savedAction = savedPartnerAccount(countryId, partnerType, account, dmpAccountID);
    if (savedAction != "") {
        savedAction = savedAction;
    }
    else {
        savedAction = ActionType.Add;
    }
    return savedAction
}

//validate MS store checkbox to be disabled based on case status and pagemode
export function isMSStoreDisable(customerCountry?: ICountry, partnerType?: number, pageMode?: string, statusCode?: number) {
    var isDisable = false;
    if (pageMode === PageMode.View) {
        return true;
    }
    if (pageMode === PageMode.Create) {
        return !customerCountry?.isMsStore;
    }
    if (pageMode === PageMode.Edit) {
        var savedContext = getsavedContext();
        if (savedContext) {
            var items = savedContext.opportunityAccounts?.filter(o => o.countryId === customerCountry?.countryId && o.partnerType === partnerType) || [];
            if (items?.length > 0) {
                if (statusCode != OpportunityDealStatusCodes.Draft) {
                    isDisable = true;
                }
            }
        }
    }
    return isDisable
}
//this method will return action for at Parent level (MS Store, ADD - All Country & All EU), when already saved, return action from API otherwise "Add" action
export function getParentAccountAction(countryId?: string, partnerType?: number) {
    var action = "";
    var items = getsavedContext().opportunityAccounts?.filter(o => o.countryId === countryId && o.partnerType === partnerType) || [];
    if (items?.length > 0) {
        action = items[0].action || "";
    }
    else {
        action = ActionType.Add;
    }
    return action
}
//Function to  return logged in user sub role information
export function getDistinctUserRole(userRoleDetails: IUserRoleDetail[]): string {

    let userSubRoles: Array<string> = [];
    let userRoles: Array<string> = [];
    let userRole = "";
    userRoleDetails.map(userRoleDetail => {
        if (!userSubRoles.includes(userRoleDetail.userSubRole)) {
            userSubRoles.push(userRoleDetail.userSubRole);
        }
        if (!userRoles.includes(userRoleDetail.userRole)) {
            userRoles.push(userRoleDetail.userRole);
        }
    });

    if (userRoles.length === 1 && userRoles.includes(UserRole.MSStore)) {
        userRole = UserRole.MSStore;
    }
    else if (userSubRoles.length === 1 && userSubRoles.includes(UserSubRole.ADD)) {
        userRole = UserSubRole.ADD;
    }
    else if (userSubRoles.length === 1 && (userSubRoles.includes(UserSubRole.ADR) || userSubRoles.includes(UserSubRole.DMP))) {
        userRole = UserSubRole.Reseller;
    }
    else if (userSubRoles.length > 1 && (userSubRoles.includes(UserSubRole.ADR) || userSubRoles.includes(UserSubRole.DMP)) && !userSubRoles.includes(UserSubRole.ADD)) {
        userRole = UserSubRole.Reseller;
    }
    else if (userSubRoles.length > 1 && (userSubRoles.includes(UserSubRole.ADR) || userSubRoles.includes(UserSubRole.DMP)) && userSubRoles.includes(UserSubRole.ADD)) {
        userRole = UserSubRole.ADDReseller;
    }
    return userRole;

}

//Function to  return logged in user sub role information
export function getRequestorTypeRoles(userRoleDetails: IUserRoleDetail[]): string {
    let userSubRoles: Array<string> = [];
    let userRoles: Array<string> = [];
    let userRole = "";
    userRoleDetails.map(userRoleDetail => {
        if (!userSubRoles.includes(userRoleDetail.userSubRole)) {
            userSubRoles.push(userRoleDetail.userSubRole);
        }
        if (!userRoles.includes(userRoleDetail.userRole)) {
            userRoles.push(userRoleDetail.userRole);
        }
    });
    if (userRoles.length === 1 && userRoles.includes(UserRole.MSStore)) {
        userRole = UserRole.MSStore;
    }
    else if (userRoles.length === 1 && userRoles.includes(UserRole.MSSeller)) {
        userRole = UserRole.MSSeller;
    }
    else if (userSubRoles.length === 1 && userSubRoles.includes(UserSubRole.ADD)) {
        userRole = UserSubRole.ADD;
    }
    else if (userSubRoles.length === 1 && (userSubRoles.includes(UserSubRole.ADR))) {
        userRole = UserSubRole.ADR;
    }
    else if (userSubRoles.length === 1 && userSubRoles.includes(UserSubRole.DMP)) {
        userRole = UserSubRole.DMP;
    }
    else if (userSubRoles.length > 1 && userSubRoles.includes(UserSubRole.ADD)) {
        userRole = UserSubRole.ADD;
    }
    return userRole;
}

async function setSessionData() {
    let [productTypes, countries, distributors, verticals, dealTypes, dealDurationConfig, dealVolumeConfig, resourceStrings] = await Promise.allSettled([
        nextGenService.getProductTypes(),
        nextGenService.getAllCountries(),
        nextGenService.getAllDistributors(),
        nextGenService.getAllVerticals(),
        nextGenService.getAllDealTypes(),
        nextGenService.getDealDurationConfig(),
        nextGenService.getDealQuantityThresholds(),
        nextGenService.getAllResourceStrings(),
    ]);

    if (productTypes.status === 'fulfilled') {
        sessionStorage.setItem(SessionStorageConsts.productTypes, JSON.stringify(productTypes.value.data));
    }

    if (countries.status === 'fulfilled') {
        sessionStorage.setItem(SessionStorageConsts.countries, JSON.stringify(countries.value.data));
    }

    if (distributors.status === 'fulfilled') {
        sessionStorage.setItem(SessionStorageConsts.distributors, JSON.stringify(distributors.value.data));
    }

    if (verticals.status === 'fulfilled') {
        var filteredVerticals = verticals.value.data.filter(x => x.code != ApplicationConstants.AllVertical);
        sessionStorage.setItem(SessionStorageConsts.verticals, JSON.stringify(filteredVerticals));
    }

    if (dealTypes.status === 'fulfilled') {
        sessionStorage.setItem(SessionStorageConsts.dealTypes, JSON.stringify(dealTypes.value.data));
    }

    if (dealDurationConfig.status === 'fulfilled') {
        sessionStorage.setItem(SessionStorageConsts.dealDurationConfig, JSON.stringify(dealDurationConfig.value.data));
    }

    if (dealVolumeConfig.status === 'fulfilled') {
        sessionStorage.setItem(SessionStorageConsts.dealVolumeConfig, JSON.stringify(dealVolumeConfig.value.data));
    }

    if (resourceStrings.status === 'fulfilled' && resourceStrings.value && resourceStrings.value.data) {
        sessionStorage.setItem(SessionStorageConsts.resourceStrings, JSON.stringify(resourceStrings.value.data["en-US"]));
    }
}

export async function intializeSessionConstants() {
    if (sessionStorage.getItem(SessionStorageConsts.productTypes) === null
        || sessionStorage.getItem(SessionStorageConsts.countries) === null
        || sessionStorage.getItem(SessionStorageConsts.distributors) === null
        || sessionStorage.getItem(SessionStorageConsts.verticals) === null
        || sessionStorage.getItem(SessionStorageConsts.dealTypes) === null
        || sessionStorage.getItem(SessionStorageConsts.dealDurationConfig) === null
        || sessionStorage.getItem(SessionStorageConsts.dealVolumeConfig) === null
        || sessionStorage.getItem(SessionStorageConsts.resourceStrings) === null) {
        await setSessionData();
    }
}

export async function getAllDealTypes(): Promise<IDealType[]> {
    let dealTypes: IDealType[] = getEntityDataFromSession(SessionStorageConsts.dealTypes)

    if (dealTypes == null || dealTypes.length <= 0) {
        const response = await Promise.resolve(nextGenService.getAllDealTypes());
        if (response.status === 200) {
            dealTypes = response.data;
            if (dealTypes.length > 0) {
                sessionStorage.setItem(SessionStorageConsts.dealTypes, JSON.stringify(dealTypes));
            }
        }
    }

    return dealTypes;
}

export async function getAllDealQuantityThresholds(): Promise<IDealQuantityThresholds[]> {
    let quantityThresholds: IDealQuantityThresholds[] = getEntityDataFromSession(SessionStorageConsts.dealVolumeConfig);

    if (quantityThresholds == null || quantityThresholds.length <= 0) {
        const response = await Promise.resolve(nextGenService.getDealQuantityThresholds());
        if (response.status === 200) {
            quantityThresholds = response.data;
            if (quantityThresholds.length > 0) {
                sessionStorage.setItem(SessionStorageConsts.dealVolumeConfig, JSON.stringify(quantityThresholds));
            }
        }
    }

    return quantityThresholds;
}

export async function getAllDealDurationConfigs(): Promise<IDealDurationConfig[]> {
    let dealDurationConfig: IDealDurationConfig[] = getEntityDataFromSession(SessionStorageConsts.dealDurationConfig);

    if (dealDurationConfig == null) {
        const response = await Promise.resolve(nextGenService.getDealDurationConfig());
        if (response.status === 200) {
            dealDurationConfig = response.data;
            if (dealDurationConfig.length > 0) {
                sessionStorage.setItem(SessionStorageConsts.dealDurationConfig, JSON.stringify(dealDurationConfig));
            }
        }
    }

    return dealDurationConfig;
}

export function getRegionFromCountry(countryName?: string): string {
    let countriesInSession = sessionStorage.getItem(SessionStorageConsts.countries);
    if (countriesInSession !== null) {
        var countryList = JSON.parse(countriesInSession);
        if (!isNullOrEmpty(countryName)) {
            let selectedCountry = countryList.find((x: any) => x.countryName === countryName)
            return selectedCountry.region?.toLowerCase()
        }
    }

    return ""
}

export function getDealDurationConfig(dealType: string, country?: string): IDealDurationConfig | undefined {
    let dealDurationConfigs: IDealDurationConfig[] = getEntityDataFromSession(SessionStorageConsts.dealDurationConfig);
    let regionName = ''
    if (!isNullOrEmpty(country)) {
        regionName = getRegionFromCountry(country)
    }

    let commonConfigs = dealDurationConfigs.filter(config => config.dealTypeName?.toLowerCase() === dealType?.toLowerCase());
    let countryConfig = commonConfigs.find(config => config.countryName?.toLowerCase() === country?.toLowerCase());
    let regionConfig = commonConfigs.find(config => config.regionName?.toLowerCase() === regionName?.toLowerCase());
    let defaultConfig = commonConfigs.find(config => isNullOrEmpty(config.countryName) && isNullOrEmpty(config.regionName));

    if (!isNullOrEmpty(countryConfig)) {
        return countryConfig
    }

    if (isNullOrEmpty(countryConfig) && !isNullOrEmpty(regionConfig)) {
        return regionConfig
    }

    return defaultConfig
}

export function getDealVolumes(dealType: string, productType: string, businessVertical?: string, country?: string): IDealQuantityThresholds | undefined {
    let dealVolumesConfigs: IDealQuantityThresholds[] = getEntityDataFromSession(SessionStorageConsts.dealVolumeConfig);
    let verticalVolumeConfigs = dealVolumesConfigs.filter(config => config?.businessVerticalName?.toLowerCase() === businessVertical?.toLowerCase() && config?.opportunityDealType?.toLowerCase() === dealType?.toLowerCase() && config.productGroupType?.toLowerCase() === productType?.toLowerCase())
    let commonConfigs = dealVolumesConfigs.filter(config => config?.businessVerticalName?.toLowerCase() === undefined && config?.opportunityDealType?.toLowerCase() === dealType?.toLowerCase() && config.productGroupType?.toLowerCase() === productType?.toLowerCase());

    let regionName = ''
    if (!isNullOrEmpty(country)) {
        regionName = getRegionFromCountry(country)
    }

    if (!isNullOrEmpty(verticalVolumeConfigs)) {
        let countryConfig = verticalVolumeConfigs.find(config => config.countryName?.toLowerCase() === country?.toLowerCase());
        let regionConfig = verticalVolumeConfigs.find(config => config.regionName?.toLowerCase() === regionName?.toLowerCase());
        let defaultConfig = verticalVolumeConfigs.find(config => isNullOrEmpty(config.countryName) && isNullOrEmpty(config.regionName))

        if (!isNullOrEmpty(countryConfig)) {
            return countryConfig
        }

        if (isNullOrEmpty(countryConfig) && !isNullOrEmpty(regionConfig)) {
            return regionConfig
        }

        if (!isNullOrEmpty(defaultConfig)) {
            return defaultConfig
        }
    }

    let countryConfig = commonConfigs.find(config => config.countryName?.toLowerCase() === country?.toLowerCase());
    let regionConfig = commonConfigs.find(config => config.regionName?.toLowerCase() === regionName?.toLowerCase());
    let defaultConfig = commonConfigs.find(config => isNullOrEmpty(config.countryName) && isNullOrEmpty(config.regionName));

    if (!isNullOrEmpty(countryConfig)) {
        return countryConfig
    }

    if (isNullOrEmpty(countryConfig) && !isNullOrEmpty(regionConfig)) {
        return regionConfig
    }

    return defaultConfig
}

export async function getAllCountries(): Promise<ICountry[]> {
    let countries: ICountry[] = [];
    let countriesInSession = sessionStorage.getItem(SessionStorageConsts.countries);
    if (countriesInSession !== null) {
        countries = JSON.parse(countriesInSession);
    } else {
        const response = await Promise.resolve(nextGenService.getAllCountries());
        if (response.status === 200) {
            countries = response.data;
            if (countries.length > 0) {
                sessionStorage.setItem(SessionStorageConsts.countries, JSON.stringify(countries));
            }
        }
    }
    return countries;
}

export async function getCustomerIndustries(): Promise<any> {
    let industries: any;
    let industryInSession = sessionStorage.getItem(SessionStorageConsts.industries);
    if (industryInSession !== null) {
        industries = JSON.parse(industryInSession);
    } else {
        const response = await Promise.resolve(nextGenService.getIndustries());
        if (response.status === 200) {
            industries = response.data;
            sessionStorage.setItem(SessionStorageConsts.industries, JSON.stringify(industries));
        }
    }
    return industries;
}

export function refreshSessionStorageKey(key: string, value: string) {
    let sessionValue = sessionStorage.getItem(key);
    if (sessionValue) {
        sessionStorage.removeItem(key);
    }

    if (value) {
        sessionStorage.setItem(key, value);
    }
}

//Function to  return logged in user sub role information
export function isRoleBasedFilterRequired(userRole: string): boolean {
    return userRole === UserSubRole.ADD || userRole === UserSubRole.Reseller || userRole === UserSubRole.ADDReseller || userRole === UserRole.MSStore
}

//Function to validate email address format. Returns true if valid.
export function isValidEmail(email: string): boolean {
    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(email);
}
//this method will return action for at Parent level (MS Store, ADD - All Country & All EU), when already saved, return action from API otherwise "Add" action
export function getChannelContactAction(accountId?: string, statusCode?: number) {
    var action = "";
    var items = getsavedContext().opportunityChannelContacts?.filter(c => c.accountId === accountId) || [];
    if (statusCode != OpportunityDealStatusCodes.Draft && items?.length > 0) {
        action = "";
    }
    else {
        action = ActionType.Add;
    }
    return action
}
//this method will return email id from savedcontext-required when email is available in context but not in selected Oppaccounts
export function getChannelContactEmailFromSavedContext(accountId?: string, statusCode?: number) {
    var email = '';
     var items = getsavedContext().opportunityChannelContacts?.find(c => c.accountId === accountId);
    if (statusCode != OpportunityDealStatusCodes.Draft && !isNullOrEmpty(items) ) {
         email = items?.channelContactEmails || '';
    }
    return email;
}
//this method will return iscomplete from savedcontext based on email id-required when email is available in context but not in selected Oppaccounts
export function getChannelContactIsComplete(accountId?: string, statusCode?: number) {
    var items = getsavedContext().opportunityChannelContacts?.find(c => c.accountId === accountId);
    if (statusCode != OpportunityDealStatusCodes.Draft && !isNullOrEmpty(items)) {
        return true
    }
    return false;
}
export function hideResellerSectionsInViewModeForReseller(userRole: string, modeType?: string) {
    return userRole !== UserSubRole.Reseller || modeType?.toLowerCase() !== PageMode.View.toLowerCase();
}

//Function to Read Excel Headers...
export function readExcelHeader(fileObj: any, callback: any): void {
    const reader = new FileReader(); //read the file
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e: any) => {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        let excelHaders = extractHeader(ws);
        callback(excelHaders);
    };
    if (rABS) {
        reader.readAsBinaryString(fileObj.file);
    } else {
        reader.readAsArrayBuffer(fileObj.file);
    };
}
function extractHeader(ws: any) {
    const header = []
    if (!isNullOrEmpty(ws['!ref'])) {
        const columnCount = XLSX.utils.decode_range(ws['!ref']).e.c + 1
        for (let i = 0; i < columnCount; ++i) {
            header[i] = ws[`${XLSX.utils.encode_col(i)}1`].v
        }
    }
    return header
}

//Function to validate list of email address. Returns true if valid.
export function validateEmails(emails: string, separator: string): boolean {
    var returnValue: boolean = true;
    if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(emails)) {
        var emailsList = emails.split(separator);
        if (emailsList && emailsList.length > 0) {
            for (var email of emailsList) {
                if (!isValidEmail(email.trim())) {
                    returnValue = false;
                    break;
                }
            }
        } else { returnValue = false; }
    } else { returnValue = false; }
    return returnValue;
}

export function isAnyEUCountrySelected(selectedGeoCountries: ICountry[]) {
    return selectedGeoCountries != null && selectedGeoCountries.length > 0 && selectedGeoCountries.filter(country => country.isEUEFTA === true).length > 0
}

//Function to check if the current user has MSSeller role.
export function isMSSeller(): boolean {
    let isMSSeller = false;
    const loggedInUserInfo = JSON.parse(getUserSession());
    if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(loggedInUserInfo)) {
        isMSSeller = loggedInUserInfo.userRoleDetails?.some((userRoleDetail: IUserRoleDetail) => userRoleDetail.userRole === UserRole.MSSeller);
    }
    return isMSSeller;
}

//Method for adding headers to excel export
export function addExportExcelHeader(exportExcel: any, props?: IGridProps): void {
    var savedCPContext = getsavedContext();
    const options = exportExcel.current.workbookOptions()
    if (options && options.sheets) {
        const rows = options.sheets[0].rows;
        options.sheets[0].frozenRows = 5;
        const headerRow1 = {
            height: 20,
            cells: [
                {
                    value: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseIdText", UIControlsText.CaseIdText),
                    fontSize: 15,
                    bold: true,
                },
                {
                    value: props?.kendoGridProps.caseNumber ? props?.kendoGridProps.caseNumber : getURLParamValue(ApplicationConstants.OpportunityNumberParamName),
                    fontSize: 15,
                    colSpan: props !== undefined ? props.kendoGridProps.noOfColumns - 1 : 2,
                },
            ],
        };
        const headerRow2 = {
            height: 20,
            cells: [
                {
                    value: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealNameText", UIControlsText.DealNameText),
                    fontSize: 15,
                    bold: true,
                },
                {
                    value: props?.kendoGridProps.caseNumber ? props?.kendoGridProps.dealName : savedCPContext.opportunityDeals[0]?.dealName,
                    fontSize: 15,
                    colSpan: props !== undefined ? props.kendoGridProps.noOfColumns - 1 : 2,
                },
            ],
        };
        const headerRow3 = {
            height: 20,
            cells: [
                {
                    value: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EndCustomerText", UIControlsText.EndCustomerText) + " Country",
                    fontSize: 15,
                    bold: true,
                },
                {
                    value: props?.kendoGridProps?.customerCountry ? props?.kendoGridProps?.customerCountry : savedCPContext?.opportunityDeals ? savedCPContext?.opportunityDeals[0]?.dealCustomers?.find(c => c?.customerType === CustomerType.Primary)?.country : undefined,
                    fontSize: 15,
                    colSpan: props !== undefined ? props.kendoGridProps.noOfColumns - 1 : 2,
                },
            ],
        };
        const headerRow4 = {
            height: 20,
            cells: [
                {
                    value: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EndCustomerText", UIControlsText.EndCustomerText) + " Name",
                    fontSize: 15,
                    bold: true,
                },
                {
                    value: props?.kendoGridProps?.customerName ? props?.kendoGridProps?.customerName : savedCPContext?.opportunityDeals ? savedCPContext?.opportunityDeals[0]?.dealCustomers?.find(c => c?.customerType === CustomerType.Primary)?.legalEntityName : undefined,
                    fontSize: 15,
                    colSpan: props !== undefined ? props?.kendoGridProps?.noOfColumns - 1 : 2,
                },
            ],
        };
        if (rows) {
            rows.unshift(headerRow4);
            rows.unshift(headerRow3);
            rows.unshift(headerRow2);
            rows.unshift(headerRow1);
        }
        exportExcel.current.save(options);
    }
}

//this method will be common for download according to the filename with extension and response as input param.
export async function downloadDatabyInputFormat(fileName: string, response: any) {
    let responseData: any = response.data;
    if (responseData !== null) {
        var pdfFile = "data:application/pdf;base64," + responseData
        var download = document.createElement("a");
        download?.setAttribute(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "HrefAttribute", UIControlsText.HrefAttribute), pdfFile)
        download?.setAttribute(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DownloadAttribute", UIControlsText.DownloadAttribute), fileName)
        download?.click()
    }
}

//This method removes the timestamp and timezone and returns the date part
export function getDatePart(fullDate?: Date) {
    if (fullDate) {
        let dt = Moment(fullDate.toString());

        let day = dt.date();
        let month = dt.month();
        let year = dt.year();
        let date = new Date(year, month, day, 0, 0, 0, 0); //Remove the timestamp and timezone
        return date;
    }
}


// encrypt string 
export function encryptString(inputString: string): string {
    if (!isNullOrEmpty(inputString)) {
        return CryptoJS.AES.encrypt(inputString, process.env.REACT_APP_ENCRYPT_SECRET_KEY || '').toString();
    }
    else {
        return "";
    }
}

// decrypt string
export function decryptString(inputString: string): string {
    if (!isNullOrEmpty(inputString)) {
        try {
            return CryptoJS.AES.decrypt(JSON.parse(inputString), process.env.REACT_APP_ENCRYPT_SECRET_KEY || '').toString(CryptoJS.enc.Utf8);
        }
        catch (e) {
            return ""; // will be kicked out to login page in case of exception.
        }
    }
    else {
        return "";
    }
}

/**
 * Download the attachment
 * @method
 * @param {string} filename 
 * @param {string} content 
 */
export const downloadFile = (filename: string, content: string) => {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:application/octet-stream;base64,' + content);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

/**
 * to check the logged in user has specific role .
 * @method
 * @returns {boolean}.
 */
export const isLoggedInUserHasSubRole = (subRole: UserSubRole): boolean => {
    var userRole = getUserRole();
    if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(userRole)) {
        return userRole?.toUpperCase().indexOf(subRole) > -1;
    }

    return false;
}

/**
 * Validate email with seperator.
 * @method
 * @param {string} value.
 * @param {string} seperator.
 * @returns {boolean}.
 */
export const isValidEmailWithSeperator = (value: string, seperator: string): boolean => {
    if (JavaScriptExtensions.isEmptyOrNullOrUndefined(value)) {
        return false;
    }
    else {
        let isInvalid = value.split(seperator).some((email) => email.trim() !== "" && !isValidEmail(email.trim()));
        if (isInvalid) {
            return false;
        }
    }

    return true;
};

export function getLoginUserRoles(): string[] {
    var userRole = getUserRole().concat(',').concat(getUserMainRole());
    return userRole.split(",");
}

/**
 * to get master data from Session 
 * @method
 * @param {string} itemKey.
 * @returns {T[]}.
 */
export function getEntityDataFromSession<T>(itemKey: string): T[] {
    var items = new Array<T>();
    let data = sessionStorage.getItem(itemKey);
    if (data !== null) {
        items = JSON.parse(data);
    }

    return items;
}

/**
 * back to dashboard method 
 * @returns {void}.
 */
export function backToDashboard(): void {
    window.location.href = '/dashboard'
}

/**
 * navigate To Page
 * @returns {void}.
 */
export function navigateToPage(href: string): void {
    window.location.href = href;
}

/**
 * Filters Country list based on isMSStore,isEUFTA or Selected Region
 * @returns {ICountry[]}.
 */
export function countriesFilter(selectedCountryId?: string): ICountry[] {
    const userRole = getUserRole();
    let countryList: ICountry[] = [];
    let countriesInSession = sessionStorage.getItem(SessionStorageConsts.countries);
    if (countriesInSession !== null) {
        countryList = JSON.parse(countriesInSession);
        if (userRole.includes(UserSubRole.MSStoreUser)) {
            countryList = countryList.filter(country => country.isMsStore === true);
        } else {
            let selectedCountry = countryList.find(x => x.countryId === selectedCountryId);
            if (!isNullOrEmpty(selectedCountry)) {
                if (selectedCountry?.isEUEFTA) {
                    countryList = countryList.filter(country => country.isEUEFTA === selectedCountry?.isEUEFTA);
                }
                else if (selectedCountry?.region?.toLowerCase() === ApplicationConstants.MiddleEastRegion.toLowerCase()) {
                    countryList = countryList.filter(country => country.region?.toLowerCase() === ApplicationConstants.MiddleEastRegion.toLowerCase());
                }
                else {
                    countryList = countryList.filter(country => country.applicablePriceListGeo === selectedCountry?.applicablePriceListGeo);
                }
            }
        }
    }
    var excludeCountries = process.env.REACT_APP_EXCLUDE_COUNTRIES;
    return countryList.filter(x => !excludeCountries?.split(',')?.includes(x.countryName || ""));
}

/**
 * Filter and Return Selected Country Object by countryId
 * @returns {ICountry}.
 */
export function getCountryById(selectedCountryId: string): ICountry | undefined {
    let countries = getEntityDataFromSession<ICountry>(SessionStorageConsts.countries);
    return countries?.find((t: ICountry) => t?.countryId === selectedCountryId);
}

/**
 * Filter and Return Selected Country Object
 * @returns {ICountry}.
 */
export function getCountryObjByName(selectedCountryName: string) {
    let countryList: ICountry[] = [];
    countryList = getEntityDataFromSession<ICountry>(SessionStorageConsts.countries);
    return countryList.find(x => x.countryName?.toLowerCase() === selectedCountryName.toLowerCase());
}

/**
 * Filter and Return Selected DealType Object
 * @returns {IDealType}.
 */
export function getDealType(dealTypeName: string): IDealType {
    let dealTypes: IDealType[] = getEntityDataFromSession<IDealType>(SessionStorageConsts.dealTypes) || [];
    return dealTypes?.find(x => x.name?.toLocaleLowerCase() === dealTypeName?.toLocaleLowerCase()) || {};
}

export function getPropsDeaType(dealName: string) {
    if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(dealName) && PageConstants.CPStrategicDealtype.replace(/\s+/g,'').toLowerCase()==dealName)
        return PageConstants.CPStrategicDealtype;
    else if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(dealName) && PageConstants.OTLEDealtype.toLowerCase()==dealName)
        return PageConstants.OTLEDealtype;
}

/**
 * Filter and Return Selected ProductType Object
 * @returns {IProductType}.
 */
export function getProductType(productTypeName: string): IProductType {
    let productTypes: IProductType[] = getEntityDataFromSession<IProductType>(SessionStorageConsts.productTypes) || [];
    return productTypes?.find(x => x.productName?.toLocaleLowerCase() === productTypeName?.toLocaleLowerCase()) || { productName: "", productId: "" };
}

//Function to validate postal code format. Returns true if valid.
export function isValidPostalCode(value: string): boolean {
    let pattern = new RegExp(/^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/i);
    if (value) {
        return pattern.test(value);
    }
    return false;
}
// Function to chooseColumns from Grid
export const populateGridColumns = (gridConfig: IKendoGridProps): IKendoGridProps => {
    let selectedColumns = sessionStorage.getItem(SessionStorageConsts.selectedColumns);
    if (selectedColumns === null) {
        gridConfig = LoadDefaultGridData(gridConfig)
        loadSessionData(gridConfig)
    } else {
        gridConfig = loadSessionData(gridConfig)
    }
    return gridConfig
}

const loadSessionData = (gridConfig: IKendoGridProps) => {
    let selectedColumns = sessionStorage.getItem(SessionStorageConsts.selectedColumns);
    if (selectedColumns) {
        let sessionGridConfigData = JSON.parse(selectedColumns)
        let pricingGridColumns = sessionGridConfigData.filter((col: IMultiSelectDropDownSessionProps) => col.gridName === gridConfig.gridName)
        if (pricingGridColumns.length !== 0) {
            gridConfig.columns = pricingGridColumns[0].selectedColumns
        } else {
            let defaultSelectedColumns = LoadDefaultGridData(gridConfig);
            sessionGridConfigData.push({ gridName: gridConfig.gridName, selectedColumns: defaultSelectedColumns.columns })
            sessionStorage.setItem(SessionStorageConsts.selectedColumns, JSON.stringify(sessionGridConfigData))
        }
    } else {
        let defaultSelectedColumns = LoadDefaultGridData(gridConfig)
        let dropDownSessionProps: IMultiSelectDropDownSessionProps[] = []
        dropDownSessionProps.push({ gridName: gridConfig.gridName, selectedColumns: defaultSelectedColumns.columns })
        sessionStorage.setItem(SessionStorageConsts.selectedColumns, JSON.stringify(dropDownSessionProps))
    }
    return gridConfig;
}

export const LoadDefaultGridData = (gridConfig: IKendoGridProps): IKendoGridProps => {
    var columns: IKendoGridColumnProps[] = []
    gridConfig.columns.filter((col) => col.isDefaultSelected === false).map((element) => {
        element.isVisible = false;
        columns.push(element)
    })
    return gridConfig;
}

//Function to validate postal code format. Returns true if valid.
export function isValidInterger(value: string): boolean {
    let pattern = new RegExp(/^[0-9]{1,10}$/i);
    if (value) {
        return pattern.test(value);
    }
    return false;
}

/**
 * Converts a long string of bytes into a readable format e.g KB, MB, GB, TB, YB
 * 
 * @param {Int} num The number of bytes.
 */
export function convertToReadableBytes(bytes: number, decimals: number = 2) {
    if (bytes === 0) return '0 Bytes';
    const kiloByte = 1024;
    const allowedDecimals = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(kiloByte));
    return parseFloat((bytes / Math.pow(kiloByte, i)).toFixed(allowedDecimals)) + ' ' + sizes[i];
}

/**
 * Provides functionality to check for the currently logged in user type whether it is an ADR,ADD,DMP or MSStore user
 * Based on the role and the country selection, the channel functionality is shown respectively for the user.
 * @method 
 */
export function getRequestorsChannelFunctionalities(userRole: string, countryName: string) {
    const userRoleDetails: IUserRoleDetail[] = getLoggedinUserRoleDetails();
    let internalUser = userRoleDetails?.length > 0 && userRoleDetails[0].userRole;
    let userChannelRole = (internalUser === 'MSS' || internalUser === 'MST') ? internalUser : userRole;

    const isSpecialLocationOrWorldwide: SpecialLocationtype = getMPNLocationValue(countryName);
    let requestorType = RequestorsRoleType.Invalid;
    switch (userChannelRole) {
        case 'DMP':
            requestorType = RequestorsRoleType.DMP;
            break;
        case 'ADD':
            requestorType = RequestorsRoleType.ADD;
            break;
        case 'ADR':
            requestorType = RequestorsRoleType.ADR;
            break;
        case 'MST':
        case 'MSS':
            requestorType = RequestorsRoleType.MSStore;
            break;
    }

    let channelDisplayFunctionalities: IChannelFunctionalityDisplayState = {
        MSStore: (requestorType === RequestorsRoleType.MSStore),
        PrimaryResellersOnly: (requestorType & isSpecialLocationOrWorldwide) === ChannelDisplayFunctionalities.PrimaryResellersOnly,
        PrimaryAndFinalTierResellers: (requestorType & isSpecialLocationOrWorldwide) === ChannelDisplayFunctionalities.PrimaryAndFinalTierResellers,
        PrimaryandAdditionalResellers: (requestorType & isSpecialLocationOrWorldwide) === ChannelDisplayFunctionalities.PrimaryandAdditionalResellers,
        AdditionalAndDistributors: (requestorType & isSpecialLocationOrWorldwide) === ChannelDisplayFunctionalities.AdditionalAndDistributors,
        FinalTierAndDistributors: (requestorType & isSpecialLocationOrWorldwide) === ChannelDisplayFunctionalities.FinalTierAndDistributors,
        MultipleDistributorsOnly: (requestorType & isSpecialLocationOrWorldwide) === ChannelDisplayFunctionalities.MultipleDistributorsOnly,
    }
    return channelDisplayFunctionalities;
}

/**
 * Provides functionality to get the Partner's country to handle country related scenarios for different personas.
 * @method 
 */
export function getMPNLocationValue(countryName: string): SpecialLocationtype {
    let locationType: any;

    switch (countryName) {
        case 'China':
            locationType = SpecialLocationtype.China;
            break;
        case 'Japan':
            locationType = SpecialLocationtype.Japan;
            break;
        default:
            locationType = SpecialLocationtype.WorldWide;
            break;
    }
    return locationType;
}

export function isMsStoreUser() {
    const userRole = getUserRole();
    if (userRole.includes(UserSubRole.MSStoreUser)) {
        return true;
    }
    else {
        return false;
    }
}

export const updateDefaultPageSizesInSession = (gridName: string, pageNumber: number): number => {
    let selectedPageSizes = sessionStorage.getItem(SessionStorageConsts.selectedPageSizes);
    if (selectedPageSizes) {
        let sessionPageSize = JSON.parse(selectedPageSizes)
        let pricingPageSize = sessionPageSize.filter((col: IMultiSelectDropDownSessionProps) => col.gridName === gridName)
        if (pricingPageSize.length !== 0) {
            if (pricingPageSize[0].pageSize === pageNumber) {
                pageNumber = pricingPageSize[0].pageSize
            } else {
                pricingPageSize[0].pageSize = pageNumber
                sessionStorage.setItem(SessionStorageConsts.selectedPageSizes, JSON.stringify(sessionPageSize))
            }

        } else {
            sessionPageSize.push({ gridName: gridName, pageSize: pageNumber })
            sessionStorage.setItem(SessionStorageConsts.selectedPageSizes, JSON.stringify(sessionPageSize))
        }
    } else {
        let dropDownSessionProps: IMultiSelectDropDownSessionProps[] = []
        dropDownSessionProps.push({ gridName: gridName, pageSize: pageNumber })
        sessionStorage.setItem(SessionStorageConsts.selectedPageSizes, JSON.stringify(dropDownSessionProps))
    }
    return pageNumber;
}

export const getPageNumberFromSession = (gridConfig: IKendoGridProps): number => {
    let selectedPageSizes = sessionStorage.getItem(SessionStorageConsts.selectedPageSizes);
    if (selectedPageSizes) {
        let sessionPageSize = JSON.parse(selectedPageSizes)
        let pricingPageSize = sessionPageSize.filter((col: IMultiSelectDropDownSessionProps) => col.gridName === gridConfig.gridName)
        if (pricingPageSize.length !== 0) {
            gridConfig.noOfRecords = updateDefaultPageSizesInSession(gridConfig.gridName, pricingPageSize[0].pageSize)
        } else {
            gridConfig.noOfRecords = updateDefaultPageSizesInSession(gridConfig.gridName, gridConfig.noOfRecords)
        }
    } else {
        gridConfig.noOfRecords = updateDefaultPageSizesInSession(gridConfig.gridName, gridConfig.noOfRecords)
    }
    return gridConfig.noOfRecords;
}

//this method helps to identify if the account item can editable based on pagemode and case status.
export function isEditableRow(countryId?: string, pageMode?: string, statusCode?: number, accountItem?: IAccount, partnerType?: number) {
    var editMode = false;
    var isSavedItem = false;
    if (accountItem) {
        //check if item is already saved and not allow to uncheck if case is not in draft status
        isSavedItem = isRowSaved(countryId, partnerType, accountItem);
    }
    if (pageMode?.toLowerCase() === PageMode.View.toLowerCase()) {
        editMode = true;
    }
    else if (pageMode?.toLowerCase() === PageMode.Edit.toLowerCase() && isSavedItem && statusCode !== OpportunityDealStatusCodes.Draft) {
        editMode = true;
    }
    return editMode;
}

export function isRowSaved(countryId?: string, partnerType?: number, accountItem?: any) {
    var isSavedItem = false;
    var items = [];
    items = getsavedContext().opportunityAccounts?.filter(o => o.countryId === countryId) || [];
    if (partnerType) {
        items = items?.filter(o => o.partnerType === partnerType) || [];
    }
    const itemIds = items.map((i: any) => i.accounts && i.accounts.length > 0 && i.accounts[0].accountId);

    isSavedItem = accountItem ? itemIds.includes(accountItem.id) : false;
    return isSavedItem;
};

export function getValuesForCheckboxList(props: GridColumnMenuProps, kendoGridProps: IKendoGridProps): any[] {
    let data: any[] = [];

    switch (props.column.field) {
        case ApplicationConstants.StatusCodeColumnName:
            data = StatusCodeFilterValues.find(x => x.gridName === kendoGridProps.gridName)?.statusValues.map((value: string) => { return { [`${ApplicationConstants.StatusCodeColumnName}`]: value } }) || [];
            break;
        case ApplicationConstants.ClosureDecisionColumnName:
            data = Object.values(OpportunityDealPortalEditStatusNames).map((value: string) => { return { [`${ApplicationConstants.ClosureDecisionColumnName}`]: value } });
            break;
        default:
            data = kendoGridProps.data;
            break;
    }

    return data;
}

export function getRuleValidationMessage(sessionData: any, fieldType: string, key: string, message: string): string {
    let ruleValidationMessage = "";
    if (sessionData && !_.isEmpty(sessionData) && sessionData[fieldType] && sessionData[fieldType][key]) {
        ruleValidationMessage = sessionData[fieldType][key];
    }

    return JavaScriptExtensions.isEmptyOrNullOrUndefined(ruleValidationMessage) ? message : ruleValidationMessage;
}

export function gridAccessibilityFix() {
    //Fixing Accessibility for sortIcons
    (function () {
        var sortIcons = document.querySelectorAll("span[aria-label='Sortable']");
        sortIcons.forEach((element) => {
            element.setAttribute("role", "button");
        });
    })();

    //Fixing Accessibility for GridPagerBtns & gridFootercell
    (function () {
        var pagerBtns = document.getElementsByClassName("k-icon");
        for (let i = 0; i < pagerBtns.length; i++) {
            pagerBtns[i].setAttribute("aria-hidden", "true");
        }
        document.querySelector("[role='columnfooter']")?.setAttribute('role', 'gridcell');
        var gridpager = document.getElementsByClassName("k-dropdown-wrap");
        gridpager[0]?.setAttribute('aria-label', getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PagerDropdownAriaLabel", UIControlsText.PagerDropdownAriaLabel));
        var gridpageroptions = document.querySelectorAll(".k-dropdown-wrap > span");
        gridpageroptions[0]?.setAttribute('role', 'option');
    })();

    //fixing Accessibility issue when no rows selected in grid
    (function () {
        var gridRows = document.getElementsByClassName("k-grid-norecords");
        if (gridRows.length > 0) {
            gridRows[0].removeAttribute('aria-rowindex');
            gridRows[0].setAttribute('role', 'alert');
        }
    })();

    //Fixing NaN Accessibility Issue
    (function () {
        var emptyRow = document.querySelector("tfoot");
        if (emptyRow !== null) {
            emptyRow.innerHTML = "";
        }
    })();

    //fixing Accessibility issue when no rows selected in grid
    (function () {
        var gridHeaders = document.getElementsByClassName('k-header');
        for (let i = 1; i < gridHeaders.length; i++) {
            var headerDiv = (gridHeaders[i].firstElementChild?.firstElementChild) as HTMLElement;
            headerDiv?.setAttribute('tabindex','0');    
        }
    })();

    //Accessibility fix for screen reader not announcing current active page
    (function () {
        var list=document.getElementsByClassName('k-pager-numbers')
        var listItems = list[0].getElementsByTagName('li');
        for (let i = 0; i < listItems.length; i++) {
            var anchor = listItems[i].querySelector("a");
            var label="Page "+ (i+1);
            if(anchor?.classList.contains("k-state-selected")==true){
                label= 'Current active Page is '+(i+1); 
            }
            anchor?.setAttribute('aria-label',label);          
        }
    })();

    //Accessibility fix to remove role for colgroup
    (function () {
        var colGroup = document.querySelectorAll("colgroup");
        colGroup.forEach((element) => {
            element.removeAttribute("role");
        });
    })();

    // Remove invalid aria attributes
    (function () {
        var footerRow=document.querySelector('[class="k-grid-footer-wrap"] table tfoot tr');
        footerRow?.removeAttribute('aria-rowindex');
    })();

    //Accessibility fix to give appropriate name to filter button
    (function () {
        var colHeader = document.querySelectorAll("th");
        colHeader.forEach((element) => {
            var headerVal = element.querySelector('span[class="k-link"]')?.getAttribute('title');
            var filterButton=element.querySelector('div > div');
            var filterName= headerVal +' filter';
            filterButton?.setAttribute('aria-label',filterName);
        });
    })();
};

export function isRequestorViewing(submitterEmail?: string) {
    let loggedInuser = getUserDetails();
    if (!isNullOrEmpty(loggedInuser) && submitterEmail != undefined) {
        if (loggedInuser.email.toLowerCase() === submitterEmail.toLowerCase()) {
            return true;
        } else {
            return false
        }
    } else {
        return true;
    }
}

export function isDateNotWithinThreshold(dateValue: string | undefined, dealType: IDealType) {
    if(JavaScriptExtensions.isNullOrUndfinedOrEmpty(dateValue)) {
        return "";
    }
    var formatedValue = Moment(dateValue).format('YYYY-MM-DD');
    let minExpirationDateInDays : any = dealType?.defaultDurationInDays;
    let inputExpirationDate = new Date(formatedValue?.toString());
    let currentDate = new Date();
    var currentDateFormatted = Moment(currentDate).format('YYYY-MM-DD');
    let expirationDateThresholdDate = new Date();
    expirationDateThresholdDate.setDate(expirationDateThresholdDate.getDate() + minExpirationDateInDays);
    
    if (currentDateFormatted === formatedValue) {
        return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ExpirationDateShouldNotBeEqualToCurrentDate", ErrorMessages.ExpirationDateShouldNotBeEqualToCurrentDate);
    }
    else if(inputExpirationDate > expirationDateThresholdDate) {
        let errorMsg = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ExpirationDateBeyondThresholdDate", ErrorMessages.ExpirationDateBeyondThresholdDate)
                        .replace('{0}', minExpirationDateInDays);
        return errorMsg;
    }
} 

export function isValidEmailMicrosoftDomain(emailValue?: string) {
    if(JavaScriptExtensions.isNullOrUndfinedOrEmpty(emailValue)) {
        return "";
    }

    let regexp = new RegExp('^(([a-zA-Z\-0-9\.]+@microsoft.com)[,;]*)+$');

    if(emailValue && !regexp.test(emailValue)) {
        return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "EmailShouldBeMicrosoftDomain", ErrorMessages.EmailShouldBeMicrosoftDomain);
    }
}

export function IsInternalPricingProgram(dealTypeName?: string) {
    if (!isNullOrEmpty(dealTypeName) && (dealTypeName === DealType.CPStrategic || dealTypeName === DealType.OTLE)) {
        return true
    }

    return false
}

export function IsExternalPricingProgram(dealTypeName?: string) {
    if (!isNullOrEmpty(dealTypeName) && (dealTypeName === DealType.DealRegistration || dealTypeName === DealType.SpecialPricing)) {
        return true
    }

    return false
}