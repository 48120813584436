import * as React from "react";
import { _Styles } from '../../content/styles/Page.styles';
import BackGround from '../../content/images/HomePage.png';
import { UIControlsText } from "../../content/UIControlsText";
import { SessionStorageConsts } from '../../models/SessionStorageConsts';
import { MessageTypeText } from '../../models/MessageTypeText';
import { getEntityDataFromSession, getRuleValidationMessage } from '../../components/shared/common/util';

const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
export const HomePage: React.FunctionComponent = () => {
    const showNotificationBanner: boolean = process.env.REACT_APP_MDPPBANNER_SHOW === 'true';
    return (
        <div>
            <div className={_Styles.homePageStyle}>
                {
                    showNotificationBanner &&
                    <div className={[_Styles.bannerNotificationStyle, _Styles.bannerNotificationCenter, _Styles.bannerNotificationMargin].join(" ")}>
                        <div className={_Styles.bannerNotificationMargin}>
                            <span className={[_Styles.bannerNotificationCenter, _Styles.bannerHeaderFont].join(" ")}><b>Important Message</b></span>
                            <div className={_Styles.bannerNotificationHdrLabel}>Transition Notification Banner on MDPP Portal Homepage:</div>
                            <div className={_Styles.bannerNotificationLabel}>{UIControlsText.MDPPTransitionBannerNotificationText}</div><br />
                        </div>
                    </div>
                }
                <img src={BackGround} style={{ height: "100%", width: "100%" }} alt={UIControlsText.HomePageImage} />
            </div>
        </div>
    )
}