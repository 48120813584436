import * as React from 'react';
import { Customizer, ScrollablePane } from 'office-ui-fabric-react';
import { CoherenceCustomizations } from '@cseo/styles';
import { Header } from '../../components/shared/pageheader';
import { BrowserRouter, Route } from 'react-router-dom';
import { _Styles } from '../../content/styles/Page.styles';
import { ISearchResult, CoherenceSearchDetails, SetOnsearchDetailsView, ISearchSuggestion } from '@cseo/controls';
import LeftMenu from '../../components/shared/leftMenu';
import AuthRoute from '../home/auth-route';
import { getUserSession, isNullOrEmpty } from '../../components/shared/common/util';
import { LoginPage } from '../home/login';
import { AAdAuth } from '../home/authentication';
import { ErrorPage } from '../../components/shared/error';
import { Footer } from '../home/footer';
import { HomePage } from '../home/home';
import ErrorBoundary from '../../ErrorBoundary';
import { InternalPricingProvider } from '../pricingprogram/CPStrategic/context/internalpricing-context';
import { ViewResellers } from '../dashboard/viewResellers';
import InternalPricingCase from '../pricingprogram/CPStrategic/components/internalpricing-case';
import { CaseManagement } from '../dashboard/caseManagement';
import { HelpAndSupport } from '../public/HelpAndSupport';
import PricingProgramFormMain from '../pricingprogram/PricingProgramForm/PricingProgramFormMain';
import { CommonPPProvider } from '../pricingprogram/common/context/common-pp-context';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import CasePartnerDetail from '../pricingprogram/PricingProgramForm/CasePartnerDetail';
import { BidGridCase } from '../dashboard/bid-grid-non-profit-edu-discount-case';
import { AllCaseView } from '../dashboard/all-case-view';
import { CPStrategicCaseViewGrid } from '../dashboard/cp-strategic-case-view';
import { DealRegistrationCase } from '../dashboard/deal-registration-case';
import { SpecialPricingProductInformation } from '../dashboard/productInformation-view';
import { SpecialPricing } from '../dashboard/special-pricing-view';
import {LocalEmpowermentSpecialPricingDashboard} from '../dashboard/localEmpowerment-view';
import { OneTimeLocalEmpowerment } from '../dashboard/otle-view';


interface ISearchData {
    showSearchDetails: boolean;
    searchText: string;
    searchResult: ISearchResult[];
}
interface OwnState {
    isNavCollapsed: boolean;
    searchData: ISearchData;
}
//this component is act as landing page after login, where we configure/add new pages and routes here.
export class Main extends React.Component<{}, OwnState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            isNavCollapsed: false,
            searchData: {
                showSearchDetails: false,
                searchText: '',
                searchResult: []
            }
        };

        const clickPluginInstance = new ClickAnalyticsPlugin();
        // Click Analytics configuration
        const clickPluginConfig = {
            autoCapture: true,
            dataTags: {
                customDataPrefix : 'data-nextgen-',
                useDefaultContentNameOrId: true,
                parentDataTag : 'parent-group',
                captureAllMetaDataContent : true,
            }
        };
        // Application Insights Configuration
        const configObj = {
            instrumentationKey: process.env.REACT_APP_AI_INSTRUMENTATION_KEY,
            extensions: [clickPluginInstance],
            extensionConfig: {
                [clickPluginInstance.identifier]: clickPluginConfig
            },
            autoTrackPageVisitTime: true,
            enableAutoRouteTracking: true,
            disableFetchTracking: false,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
        };

        const appInsights = new ApplicationInsights({ config: configObj });
        appInsights.loadAppInsights();
        appInsights.trackPageView();
        // Log the logged in user details
        const loggedInUserDetails = getUserSession();
        if (!isNullOrEmpty(loggedInUserDetails))
        {
            let loggedInUserInfo = JSON.parse(loggedInUserDetails)
            var validatedId = loggedInUserInfo.email?.replace(/[,;=| ]+/g, "_");
            var validatedAccountId = loggedInUserInfo.organizationId?.replace(/[,;=| ]+/g, "_");
            appInsights.setAuthenticatedUserContext(validatedId, validatedAccountId);
        }    
    }
    render() {
        return (
            <Customizer {...CoherenceCustomizations}>
                {this.renderHeader()}
                <BrowserRouter>
                    <>
                        <div id="leftnav">
                            {this.renderLeftMenu()}
                        </div>
                        <Route path="/" exact component={LoginPage} />
                        <Route path="/home" component={LoginPage} />
                        <Route path="/aadAuth" component={AAdAuth} />
                        <Route path="/errorPage" component={ErrorPage} />
                        <main id='main' tabIndex={-1}>
                            {
                                this.state.searchData.showSearchDetails &&
                                <ScrollablePane className={this.state.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
                                    <div className={_Styles.rootDiv}>
                                        <CoherenceSearchDetails searchText={this.state.searchData.searchText}>
                                        </CoherenceSearchDetails>
                                    </div>
                                </ScrollablePane>
                            }
                            <div className={this.state.isNavCollapsed ? _Styles.DRStyleCollapse : _Styles.DRStyleExpand}>
                                <Route path="/homePage" component={HomePage} />
                                <AuthRoute path="/viewresellers" component={ViewResellers} />
                                <Route
                                    path="/managecase/:dealtype"
                                    render={(props) => (props.match.params.dealtype == 'pricingprogram' ?
                                        <ErrorBoundary>
                                            <CommonPPProvider>
                                                <AuthRoute path="/managecase/pricingprogram" component={PricingProgramFormMain} />
                                            </CommonPPProvider>
                                        </ErrorBoundary> :
                                        <ErrorBoundary>
                                            <InternalPricingProvider dealtype={props.match.params.dealtype}>
                                                <AuthRoute path={`/managecase/${props.match.params.dealtype}`} component={InternalPricingCase} />
                                            </InternalPricingProvider>
                                        </ErrorBoundary>
                                    )}
                                />
                                <Route
                                    path="/partnerdetail"
                                    render={() => (
                                        <ErrorBoundary>
                                            <CommonPPProvider>
                                                <AuthRoute path="/partnerdetail" component={CasePartnerDetail} />
                                            </CommonPPProvider>
                                        </ErrorBoundary>
                                    )}
                                />
                                {<AuthRoute path="/dashboard" component={CaseManagement} />}
                                {<AuthRoute path="/discounts" component={BidGridCase} />}
                                {<AuthRoute path="/dashboard/allcases" component={AllCaseView} />}
                                {<AuthRoute path="/dashboard/dealregistration" component={DealRegistrationCase} />}
                                {<AuthRoute path="/dashboard/specialpricing" component={SpecialPricing} />}
                                {<AuthRoute path="/dashboard/cpstrategic" component={CPStrategicCaseViewGrid} />}
                                {<AuthRoute path="/dashboard/productviewsp" component={SpecialPricingProductInformation} />}
                                {<AuthRoute path="/dashboard/splocalempowerment" component={LocalEmpowermentSpecialPricingDashboard} />}
                                {<AuthRoute path="/dashboard/otle" component={OneTimeLocalEmpowerment} />}
                                {<Route path="/helpsupport" component={HelpAndSupport} />}

                            </div>
                        </main>
                        <div>
                            <Footer />
                        </div>
                    </>
                </BrowserRouter>
            </Customizer >
        );
    }
    //Based on logged in user session left menu component will render
    private renderLeftMenu() {
        const isLoggedIn = getUserSession();
        if (!isNullOrEmpty(isLoggedIn)) {
            return <LeftMenu onNavCollapsed={this._onNavCollapsed} onNavItemClicked={this._goBackClicked} />;
        }
    }
    //Based on logged in user session header nav component will render
    private renderHeader() {
        const isLoggedIn = getUserSession();
        if (!isNullOrEmpty(isLoggedIn)) {
            return <Header onShowMoreResultsCallback={this._onShowMoreResultsCallback} />;
        }
    }

    //this state property used to identify whether left nav bar is collapsed or expanded and make use of this property to across
    private _onNavCollapsed = (isCollapsed: boolean) => {
        this.setState({ isNavCollapsed: isCollapsed });
    };

    private _onShowMoreResultsCallback = (searchText: string, searchResult: ISearchResult[]) => {
        this.setState({
            searchData: {
                showSearchDetails: true,
                searchText: searchText,
                searchResult: searchResult
            }
        });
    }

    private _goBackClicked = () => {
        SetOnsearchDetailsView(false);
        this.setState({
            searchData: {
                showSearchDetails: false,
                searchText: '',
                searchResult: []
            }
        });
    }

    private _onClickSuggestionItem = (item: ISearchSuggestion, category?: string) => {
        console.log('Suggestion item clicked for ', item.text, category);
    }
}