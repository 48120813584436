import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { populateGridColumns, getUserRole, getUserSession, readGridConfig, getPageNumberFromSession, getRuleValidationMessage, getEntityDataFromSession } from "../../components/shared/common/util";
import { GridType } from "../../models/GridType";
import "../../content/styles/AddCaseGrid.css";
import DashboardGrid from "../../components/shared/grid/dashboardgrid";
import { IGridProps } from "../../components/shared/grid/IGridProps";
import { DealType } from "../../models/DealType";
import { IDashboardDataRequest } from "../../models/IDashboardDataRequest";
import { nextGenService } from "../../services/NextGenService";
import { Stack, Spinner, MessageBarType, CommandBarButton, FontIcon, MessageBar, IconButton } from "office-ui-fabric-react";
import { UIControlsText } from "../../content/UIControlsText";
import { JavaScriptExtensions } from "../../infrastructure/JavaScriptExtensions";
import { IDashboardGridProps } from "./props/IDashboardGridProps";
import { unionBy } from "lodash";
import { IOpportunityDealDetails } from "../../models/IOpportunityDealDetails";
import { filterBy } from "@progress/kendo-data-query";
import { UserSubRole } from "../../models/UserSubRole";
import { RefreshButtonStyles, _Styles } from "../../content/styles/Page.styles";
import { PageConstants } from "../pageconstants/Constants";
import { CssClassConstants } from "../../content/CssClassConstants";
import Row from "react-bootstrap/esm/Row";
import { AddToCase } from "./add-to-case";
import { MessageTypeText } from '../../models/MessageTypeText';
import { SessionStorageConsts } from "../../models/SessionStorageConsts";
import { IAccount } from "../../models/IAccount";
import { DashboardSearch } from "./dashboard-search";
import moment from "moment";

export const DealRegistrationCase: FC<IDashboardGridProps> = (props: IDashboardGridProps) => {
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const [dealRegistration, setDealRegistration] = useState<IGridProps>(readGridConfig(GridType.DealRegistration));
    const [isCaseLoading, setIsCaseLoading] = useState<boolean>(false);
    const [isRefreshed, setIsRefreshed] = useState<boolean>(false);
    const [pageNumber, setpageNumber] = useState<number>(1)
    const [userRoles] = useState<string>(getUserRole());
    const [drData, setDRData] = useState<IOpportunityDealDetails[]>([]);
    const [sqlRecordCount, setSqlRecordCount] = useState<number>(0);
    const [msgBarText, setMsgBarText] = useState<string>();
    const [filteredPartnerAccount, setfilteredPartnerAccount] = useState<IAccount[] | undefined>(undefined);
    const [hideDialog, setHideDialog] = useState<boolean>(true);
    const [errorMessageType, setErrorMessageType] = useState(MessageBarType.success);
    const [showMessageBarOnDashboard, setshowMessageBarOnDashboard] = useState<boolean>(false);
    const resetDashboardMessageBarChoice = useCallback(() => setshowMessageBarOnDashboard(false), []);
    const [gridDataState, setGridDataState] = useState<any>();

    useEffect(() => {
        dealRegistration.kendoGridProps = populateGridColumns(dealRegistration.kendoGridProps)
        dealRegistration.kendoGridProps.noOfRecords = getPageNumberFromSession(dealRegistration.kendoGridProps)
        setDealRegistration(dealRegistration)
        getDealRegistration(pageNumber);
    }, []);

    useEffect(() => {
        if (props.onAddToCaseCallBack === GridType.DealRegistration) {
            getDealRegistration(1);
        }
    }, [props.onAddToCaseCallBack]);

    useEffect(() => {
        if(isRefreshed){
            onRefresh()
        }
    }, [isRefreshed]);

    useEffect(() => {
        if(drData.length == 0 && isRefreshed){
            setIsRefreshed(false)
            setSqlRecordCount(0)
            setpageNumber(1)
            getDealRegistration(1, gridDataState)
        }
    }, [drData]);

    useEffect(() => {
        if(filteredPartnerAccount != undefined){
            setIsRefreshed(true);
        }
    }, [filteredPartnerAccount]);

    const getDealRegistration = async (pageNo?: number, dataState?: any, pageChanged?: boolean) => {
        try {
            setIsRefreshed(false)
            const userSession = getUserSession();
            setIsCaseLoading(true);
            let loggedInUserInfo = JSON.parse(userSession);
            let roles = userRoles.split(",");
            let sort: any
            let filter: any
            var searchedCaseNumber = "";
            if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(dataState)) {
                if (dataState.sort[0] && !JavaScriptExtensions.isEmptyOrNullOrUndefined(dataState.sort[0].field)) {
                    sort = dataState.sort[0].field + " " + dataState.sort[0].dir
                }
                Date.prototype.toJSON = function(){ return moment(this).format(); }
                filter = JSON.stringify(dataState.filter);
                searchedCaseNumber = dataState?.filter?.filters[0]?.filters[0]?.field === "opportunityName" 
                                            ? dataState.filter.filters[0].filters[0].value
                                            : "";
                
            }
            
            var resellerOrDistributorFilters : IAccount[] = [];
            if(filteredPartnerAccount != undefined && filteredPartnerAccount.length != 0){
                resellerOrDistributorFilters = filteredPartnerAccount
            }
            const dashboardRequest: IDashboardDataRequest = {
                userRoleDetails: loggedInUserInfo.userRoleDetails,
                dealType: DealType.DealRegistration,
                email: loggedInUserInfo.email,
                userRoles: roles.join(","),
                filter: filter,
                pageNumber: pageNo,
                recordsToFetch: dealRegistration.kendoGridProps.recordsToFetch,
                sort: sort,
                isAllCasesDashboardRequest: false,
                isLocalEmpowerment: false,
                organizationId: loggedInUserInfo.organizationId,
                resellerOrDistributorFilters: resellerOrDistributorFilters
            };
            if(drData.filter(dt => dt.opportunityName === searchedCaseNumber).length == 0){
            let apiReturnedData = await Promise.resolve(nextGenService.getPricingProgramDashboardData(dashboardRequest));
            if (apiReturnedData.status === 200) {
                apiReturnedData.data.item1 = apiReturnedData.data.item1.map((item: IOpportunityDealDetails) => ({
                    ...item,
                    expirationDate: (JavaScriptExtensions.isNullOrUndfinedOrEmpty(item.expirationDate) ? item.expirationDate : new Date(item.expirationDate as Date)),
                    caseStartDate: (JavaScriptExtensions.isNullOrUndfinedOrEmpty(item.caseStartDate) ? item.caseStartDate : new Date(item.caseStartDate as Date)),
                }));
                let DRConfig = { ...dealRegistration };
                var finalData: IOpportunityDealDetails[]
                if(pageChanged === undefined || pageChanged === false) {
                    finalData = apiReturnedData.data.item1;
                }
                else {
                    finalData = [...apiReturnedData.data.item1, ...drData];
                }
                finalData = unionBy(finalData, x => x.id);
                DRConfig.kendoGridProps.data = finalData;
                finalData.forEach((element) => {
                    element.resellerORDistributorNames = element.opportunityAccounts[0].resellerORDistributorNames;
                })
                setDRData(finalData);
                DRConfig.kendoGridProps.totalRecords = finalData.length;
                setDealRegistration(DRConfig);
                setSqlRecordCount(apiReturnedData.data.item2.NonCPStrategicDeals + sqlRecordCount)
                
            }
        }
            dealRegistration.kendoGridProps.customGridMessage = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "NoRecordsFound", UIControlsText.NoRecordsFound)
            setIsCaseLoading(false);
        } catch (error) {
            setIsCaseLoading(false);
        }
    }

    const onGetDataRequest = (dataState: any, isSortingOrFilterAppliedOrRemoved?: boolean) => {
        setGridDataState(dataState);
        if ((((filterBy(drData || [], dataState.filter).length - dataState.skip) <= dataState.take) || isSortingOrFilterAppliedOrRemoved === true)) {
            var pageNo = (!isSortingOrFilterAppliedOrRemoved && isSortingOrFilterAppliedOrRemoved !== undefined && isSortingOrFilterAppliedOrRemoved !== null) ? pageNumber + 1 : 1;
            if(!isSortingOrFilterAppliedOrRemoved && isSortingOrFilterAppliedOrRemoved !== undefined && isSortingOrFilterAppliedOrRemoved !== null)
            {
                setpageNumber(count => count + 1)
            }
            else
            {
                setpageNumber(1);
            }
            getDealRegistration(pageNo, dataState, !isSortingOrFilterAppliedOrRemoved)
        }
    }

    function addCase() {
        return (!userRoles.includes(UserSubRole.ADD)) ? "" : (<CommandBarButton onClick={() => { setHideDialog(false); }}>
            <div className={_Styles.CommandBarButton}>
                <FontIcon className={_Styles.DashboardIcons} aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddCaseAriaLabel", UIControlsText.AddCaseAriaLabel)} iconName={CssClassConstants.CirclePlusIconName}></FontIcon>
                <span className={_Styles.DashboardBtnText}>{PageConstants.AddCasToDashboard}</span>
            </div>
        </CommandBarButton>)
    }

    const initiateRefresh = () => {
        setIsRefreshed(true)
    }
    const onRefresh = () => {
        if(isRefreshed){
            let DrConfig = { ...dealRegistration }
            DrConfig.kendoGridProps.isRefresh = true
            DrConfig.kendoGridProps.data = []
            DrConfig.kendoGridProps = populateGridColumns(dealRegistration.kendoGridProps)
            DrConfig.kendoGridProps.noOfRecords = getPageNumberFromSession(dealRegistration.kendoGridProps)
            DrConfig.kendoGridProps.customGridMessage = ""
            setDealRegistration(DrConfig)
            setDRData([])
        }
    }

    const onfilterByPartner = (account: IAccount[] | undefined) => {
        setfilteredPartnerAccount(account);
    }

    const onSuccess = (showMessageBar: boolean, messageType: any, messageText: string, isReloadData: boolean, resetHideDialog: boolean): void => {

        if (resetHideDialog) {
            setHideDialog(resetHideDialog);
        }

        if (showMessageBar) {
            setErrorMessageType(messageType);
            setMsgBarText(messageText);
            setshowMessageBarOnDashboard(true);
        }
        if (isReloadData) {
            setIsRefreshed(true)
        }
    };

    function addRefreshIcon() {
        return (
            <CommandBarButton onClick={initiateRefresh} iconProps={{ iconName: CssClassConstants.RefreshIconName }} ariaLabel={CssClassConstants.RefreshIconName} title="Refresh" styles={RefreshButtonStyles}>
                <span className={_Styles.DashboardBtnText}>{PageConstants.Refresh}</span>
            </CommandBarButton>
        )

    }
    return (
        <Fragment>
            {(!userRoles.includes(UserSubRole.ADD)) ? (
                <div id="RefreshIconButton" className={_Styles.CommandBarButton}>
                    {addRefreshIcon()}
                </div>
            ) : <div id="RefreshButton" className={_Styles.CommandBarButton}>
                {addRefreshIcon()}
            </div>}
            <div className={CssClassConstants.AddToCaseButtonClass}>
                {addCase()}
            </div>
            <div data-nextgen-parent-group="Dashboard - Deal registration cases" data-nextgen-parentid= "Dashboard - Deal registration cases">
            <Stack >
                { userRoles.includes(UserSubRole.ADD) &&
                    <Row className="text-center mt-2 ms-Label">
                        <Stack>
                            <DashboardSearch  {...{onfilterByPartner : onfilterByPartner } }/>
                        </Stack>
                    </Row>
                }
                <Row>
                    {showMessageBarOnDashboard && (
                        <Stack>
                            <div>
                                <MessageBar messageBarType={errorMessageType} onDismiss={resetDashboardMessageBarChoice} dismissButtonAriaLabel="Close" isMultiline={true}>
                                    {msgBarText}
                                </MessageBar>
                            </div>
                        </Stack>
                    )}
                </Row>
                {isCaseLoading &&
                    <Stack>
                        <div>
                            <Spinner label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LoadingCases", UIControlsText.LoadingCases)} />
                        </div>
                    </Stack>
                }
                <DashboardGrid
                    {...{

                        kendoGridProps: { ...dealRegistration.kendoGridProps }, onGetDataRequest: onGetDataRequest

                    }}
                />
                <div id="footerPadding"></div>
            </Stack>
            </div>            
            {/* Add Case Model */}
            <AddToCase {...{ hideDialog: hideDialog, onSuccess }} />
        </Fragment>
    );
};