/**
 * Enum for PageSubMode.
 * @readonly
 * @enum {number}
 */
 export enum PageSubMode {

    /**
     * Copy.
     */
    Copy = "copy",

    /**
     * Renew.
     */
    Renew = "renew",
}