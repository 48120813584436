import GenericForm from '../../shared/Components/GenericFormComponent';
import { PageMode } from '../../../models/PageMode';
import { useForm } from 'react-hook-form';
import { ViewForm } from './ViewForm/ViewFormObject'
import { CreateForm } from './CreateForm/CreateFormObject';
import { useCommonPPContext } from '../common/context/common-pp-context';
import { IVertical } from '../../../models/IVertical';
import { IDealType } from '../../../models/IDealType';
import { ICountry } from '../../../models/ICountry';
import { IProductType } from '../../../models/IProductType';
import { SessionStorageConsts } from '../../../models/SessionStorageConsts';
import { getEntityDataFromSession, getRuleValidationMessage, getSelectedDeal, isNullOrEmpty } from '../../../components/shared/common/util';
import { IPartnerDetails } from '../../../models/IPartnerDetails';
import { CaseActionTypes } from '../common/reducer/CaseActionTypes';
import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import { OpportunityDealStatusCodes } from '../../../models/OpportunityDealStatusCodes';
import { ChannelSubType } from '../../../models/ChannelSubType';
import { PartnerType } from '../../../models/PartnerType';
import { ErrorMessages } from '../../../content/ErrorMessages';
import { JavaScriptExtensions } from '../../../infrastructure/JavaScriptExtensions';
import { MessageTypeText } from '../../../models/MessageTypeText';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { _Styles, expireDialog, extendDialogButtonStyles, stackButtonStyles, stackStyles } from '../../../content/styles/Page.styles';
import { DefaultButton, IStackTokens, Stack } from '@fluentui/react';
import { Label } from 'office-ui-fabric-react';
import { UIControlsText } from '../../../content/UIControlsText';

function PricingProgramFormMain(props?: IPartnerDetails) {
    const location = useLocation();
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const [invalidResellers, setInvalidResellers] = useState<string>("");
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const partnerProps: any = location?.state;
    const { formState: { errors, isDirty } } = useForm();
    const { commonPPContextState, commonPPContextDispatch } = useCommonPPContext();
    const verticals = getEntityDataFromSession<IVertical>(SessionStorageConsts.verticals);
    const dealTypes = getEntityDataFromSession<IDealType>(SessionStorageConsts.dealTypes);
    const countries = getEntityDataFromSession<ICountry>(SessionStorageConsts.countries);
    const productTypes = getEntityDataFromSession<IProductType>(SessionStorageConsts.productTypes);
    const stackButtonTokens: IStackTokens = { childrenGap: 16 };
    const stackTokens: IStackTokens = { childrenGap: 24 };
    
    useEffect(() => {
        if (commonPPContextState.opportunityAccounts?.length && commonPPContextState.opportunityAccounts?.length > 0) {

            //Validate if selected account is still valid when editing Draft case
            if (commonPPContextState.pageMode === PageMode.Edit && getSelectedDeal(commonPPContextState)?.statusCode === OpportunityDealStatusCodes.Draft) {
                //Reseller account validation
                const invalidResellers = commonPPContextState.opportunityAccounts.filter(a => a.channelSubType !== ChannelSubType.Requester && (a.partnerType === PartnerType.ADR || a.partnerType === PartnerType.DMP) && a.accounts?.some(x => JavaScriptExtensions.isDefined(x.isActive) && !x.isActive));
    
                //Distributor account validation
                const invalidDistributors = commonPPContextState.opportunityAccounts.filter(a => a.channelSubType !== ChannelSubType.Requester && a.partnerType === PartnerType.ADD && a.accounts?.some(x => JavaScriptExtensions.isDefined(x.isActive) && !x.isActive));
    
                let updatedAccounts = [...commonPPContextState.opportunityAccounts];
                let invalidPartners: string = "";
    
                if (invalidResellers && invalidResellers.length > 0) {
                    //Remove the invalid reseller & Show message
                    updatedAccounts = updatedAccounts.filter(a => !invalidResellers.map(inv => inv?.id).includes(a.id));
                    invalidPartners = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "UnavailablePartner", ErrorMessages.UnavailablePartner) + invalidResellers.map(r => r?.accounts?.map(a => a.companyName)).join(', ');
                }
                if (invalidDistributors && invalidDistributors.length > 0) {
                    //Remove the invalid reseller & Show message
                    updatedAccounts = updatedAccounts.filter(a => !invalidDistributors.map(inv => inv?.id).includes(a.id));
                    invalidPartners = invalidPartners === "" ? getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "UnavailablePartner", ErrorMessages.UnavailablePartner) : invalidPartners + ', ';
                    invalidPartners = invalidPartners + invalidDistributors.map(r => r?.accounts?.map(a => a.companyName)).join(', ')
                }
    
                if (invalidPartners != "") {
                    setInvalidResellers(invalidPartners);
                    setIsDialogOpen(true);
                    commonPPContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: updatedAccounts });
                }
            }
        }
    }, [commonPPContextState.opportunityAccounts, commonPPContextState.pageMode, commonPPContextState.selectedOptyDealCaseNumber])

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    return (
        <>
            { isDialogOpen && <Dialog style={_Styles} onClose={closeDialog}>
                <Stack horizontal={false} tokens={stackTokens} styles={stackStyles}>
                    <Stack>
                        <h4> {getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "UnavailableChannelPartner", ErrorMessages.UnavailableChannelPartner)}</h4>
                    </Stack>

                    <Stack>
                        <Label style={expireDialog} className={_Styles.DialogueCheckBox}>
                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "InvalidResellerAccounts", ErrorMessages.InvalidResellerAccounts)}
                            <div>
                                {invalidResellers}
                            </div>
                        </Label>
                    </Stack>
                </Stack>
                <DialogActionsBar>
                    <Stack horizontal horizontalAlign="end" tokens={stackButtonTokens} styles={stackButtonStyles}>
                        <DefaultButton
                            text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "OkText", UIControlsText.OkText)}
                            ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "OkText", UIControlsText.OkText)}
                            onClick={closeDialog}
                            styles={extendDialogButtonStyles}
                        />
                    </Stack>
                </DialogActionsBar>
            </Dialog>
            }

            <GenericForm
                errors={errors}
                formJsonTemplate={commonPPContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase()
                    ? ViewForm({ verticals, dealTypes, countries, productTypes, pageContext: commonPPContextState }) :
                    CreateForm({ verticals, dealTypes, countries, productTypes, pageContext: commonPPContextState, partnerDetails: partnerProps?.partnerDetails })}
                baseState={commonPPContextState} >
            </GenericForm>
        </>
    );
}

export default PricingProgramFormMain;
