/**
 * Session Storage Constants.
 * @constant
 */
 export const SessionStorageConsts = {
  productTypes: "productTypes",
  countries: "countries",
  distributors: "distributors",
  verticals: "verticals",
  contactRoles: "contactRoles",
  dealTypes: "dealTypes",
  dealDurationConfig: "dealDurationConfig",
  dealVolumeConfig: "dealVolumeConfig",
  pageMode: "pageMode",
  pageSubMode: "pageSubMode",
  userDetail: "userDetail",
  loggedInUserInfo: "loggedInUserInfo",
  userRoleDetails: "userRoleDetails",
  userRole: "userRole",
  userSubRole: "userSubRole",
  userCountries: "userCountries",
  opportunityProductType: "opportunityProductType",
  correlationId: "correlationId",
  userEmail: "userEmail",
  industries:"industries",
  guidanceTexts:"guidanceTexts",
  selectedColumns:"selectedColumns",
  opportunityNumber:"oppNumber",
  selectedDeal: "selectedDeal",
  dealQuantityThresholdConfigs: "dealQuantityThresholdConfigs",
  selectedPageSizes: "SelectedPageSizes",
  userRequestedUrL: "userRequestedUrl",
  resourceStrings: "resourceStrings",
  isChatbotEnabled: "isChatbotEnabled",
  nextGenAPIBearerToken: "bearerToken",
  nextGenAPIPCUserToken: "userToken",
}