/**
 * Enum for Channel Type.
 * @readonly
 * @enum {Enum}
 */
export enum ChannelType {
    /**
    * Direct Channel.
    */
    Direct = 1,
    /**
    *  INDIRECT Channel.
    */
    Indirect = 2,
    
}