import { ActionButton, Label, Link, Panel, Stack, StackItem } from "@fluentui/react";
import { Upload, UploadFileInfo, UploadOnAddEvent } from "@progress/kendo-react-upload";
import { Badge, BadgeContainer } from "@progress/kendo-react-indicators";
import { useEffect, useState } from "react";
import { UIControlsText } from "../../content/UIControlsText";
import { useBoolean } from '@fluentui/react-hooks';
import { encode } from "base64-arraybuffer";
import { Guid } from "../../infrastructure/Guid";
import { IAttachmentProps } from "./props/IAttachmentProps";
import { IAttachment } from "../../models/IAttachment";
import { ArrayExtensions } from "../../infrastructure/ArrayExtensions";
import { DefaultPageStyles } from "../../content/styles/Page.styles";
import { Grid, GridCellProps, GridColumn as Column } from "@progress/kendo-react-grid";
import { CssClassConstants } from "../../content/CssClassConstants";
import { convertToReadableBytes, downloadFile } from "./common/util";
import { ActionType } from "../../models/ActionType";
import { PageMode } from "../../models/PageMode";
import { OpportunityDealStatusCodes } from "../../models/OpportunityDealStatusCodes";
import { CoherenceDialog, CoherenceDialogFooter } from "@cseo/controls";
import { DefaultButton, DialogType, PrimaryButton } from "office-ui-fabric-react";
import Tooltip from "./tooltip";
import { AttachmentDisplayMode } from "../../models/AttachmentDisplayMode";
import { JavaScriptExtensions } from "../../infrastructure/JavaScriptExtensions";
import { ErrorMessages } from "../../content/ErrorMessages";
import { getRuleValidationMessage, getEntityDataFromSession } from "./common/util";
import { SessionStorageConsts } from "../../models/SessionStorageConsts";
import { MessageTypeText } from "../../models/MessageTypeText";

/**
 * Attachment component.
 * @function component
 */
const Attachment: React.FunctionComponent<IAttachmentProps> = (props?: IAttachmentProps) => {
    const [files, setFiles] = useState<Array<UploadFileInfo>>([]);
    const [showAttachmentList, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [attachmentList, setAttachmentList] = useState<Array<IAttachment | undefined>>([]);
    const [hideAttachmentDeleteDialog, setHideAttachmentDeleteDialog] = useState<boolean>(true);
    const [attachmentId, setattachmentId] = useState<string>("");
    const displayInPanelGrid: boolean = props?.attachmentDisplayMode === AttachmentDisplayMode.PanelGrid || props?.attachmentDisplayMode === undefined;
    const fileUploadId: string = `${ props?.source?.replace(/\s/g, "") }Attachments`;
    const allowedFileSize: number | undefined = Number(process.env.REACT_APP_AllowedFileSize);
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

    //accessbility issue.
    useEffect(() => {
        // Accessibility fix for "nested-interactive controls"
        var selectFilesButtonContainers = document.querySelectorAll('div[aria-label="Select files..."]');
        selectFilesButtonContainers.forEach((node) => {
            if (node.getAttribute("role") === "button") {
                node.removeAttribute("role");
            }
        });
    }, []);
    
    useEffect(() => {
        if (props?.attachmentList && props?.attachmentList.length >= 0) {
            setAttachmentList(props?.attachmentList.filter(item => item?.action !== ActionType.Delete));
        }
    }, [props?.attachmentList]);
    /**
     * Event to handle new attachment addition
     * @method
     * @param {UploadOnAddEvent} UploadOnAddEvent kendo attachment add event 
     */
    const onAddAttachment = async (event: UploadOnAddEvent) => {
        setFiles(event.newState);
        const attachments = await Promise.all(event.affectedFiles.map(async (file) => {
            if ((props && props?.allowedFileExtensions && props?.allowedFileExtensions.filter(t => t.trim().toLowerCase() === file?.extension?.toLocaleLowerCase()).length <= 0)) {
                window.alert(JavaScriptExtensions.stringFormat(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "FileExtensionError", ErrorMessages.FileExtensionError), file?.extension as string, (props && props?.allowedFileExtensions?.toString()) as string));
            }
            else if (file?.getRawFile) {
                if (file?.size === 0) {
                    window.alert(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "FileSizeEmptyError", UIControlsText.FileSizeEmptyError));
                }
                else if (file?.size && file.size < allowedFileSize) {
                    var bufffer = await file.getRawFile().arrayBuffer();
                    var content = encode(bufffer);

                    var att: IAttachment = {
                        id: Guid.newGuid(),
                        name: file.name,
                        extension: file.extension,
                        size: file.size,
                        source: props?.source,
                        content: content,
                        action: ActionType.Add
                    };
                    return att;
                }
                else {
                    window.alert(JavaScriptExtensions.stringFormat(UIControlsText.FileSizeError, convertToReadableBytes(allowedFileSize, 0)));
                }
            }

        }));
        var newattachments = ArrayExtensions.removeundefinedfromArray(attachments);

        //Removing duplicates from existing list so it can be overritten with new attachment
        var updatedList = attachmentList.filter(a => a?.source === props?.source).filter(a => newattachments.map(n => n?.name).indexOf(a?.name) === -1);
        var deleteditems = attachmentList?.filter((item) => item?.action?.toLowerCase() === ActionType.Delete.toLowerCase() && item.source === props?.source) || [];
        var newlist = ArrayExtensions.mergeArrays(updatedList, newattachments);
        setAttachmentList(newlist);
        var includingDeleted = ArrayExtensions.mergeArrays(newlist, deleteditems);
        props?.handleAttachmentChanges && props.handleAttachmentChanges(includingDeleted);
    }

    /**
     * Event to handle attachment removal
     * @method
     * @param {string} id removed attachment id 
     */
    const removeAttachment = (id?: string | undefined): void => {
        var newList = attachmentList?.filter(a => a?.source === props?.source) || [];  //attachmentList.filter((item) => item?.id !== id);
        var updatedList = [];
        if (props?.pageMode?.toLowerCase() === PageMode.Create.toLowerCase() || 
            (props?.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase() && props?.opportunityDealStatusCode === OpportunityDealStatusCodes.Draft)) {
            var findItem = props?.savedAttachments?.find(x => x?.id === id);
            if (findItem) {
                updatedList = newList.map((item: any) => {
                    if (item.id === id) {
                        item.action = ActionType.Delete;
                    } return item;
                });
            }
            else {
                updatedList = newList.filter(a => a?.id !== id);
            }
        }
        else {
            updatedList = newList.filter(a => a?.id !== id);
        }
        var attachments = updatedList.filter(item => item?.action !== ActionType.Delete);
        setAttachmentList(attachments);
        props?.handleAttachmentChanges && props.handleAttachmentChanges(updatedList);
    }

    let gridData = <>
    <Stack>
        <Grid data={attachmentList} resizable={true} style={displayInPanelGrid ? {} : DefaultPageStyles.attachmentGridHeight}>
            <Column title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AttachmentFileNameText", UIControlsText.AttachmentFileNameText)} width={props?.disabled ? DefaultPageStyles.attachementFileNameWidth.moreWidth : DefaultPageStyles.attachementFileNameWidth.width}
                cell={(cellProps: GridCellProps) => (
                    <td>
                        <Link onClick={() => downloadFile(cellProps?.dataItem?.name, cellProps?.dataItem?.content)}>{cellProps?.dataItem?.name}</Link>
                    </td>
                )}
            />
            <Column field="size" title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AttachmentFileSizeText", UIControlsText.AttachmentFileSizeText)} width={props?.disabled ? DefaultPageStyles.attachementFileSizeWidth.moreWidth : DefaultPageStyles.attachementFileSizeWidth.width} />
            {(!props?.disabled && props?.pageMode !== PageMode.View) &&
            <Column
                width={50}
                cell={(cellProps: GridCellProps) => (
                    <td>
                        <ActionButton
                            id={cellProps?.dataItem.id}
                            iconProps={{
                                ariaLabel: CssClassConstants.DeleteIconName,
                                iconName: CssClassConstants.DeleteIconName,
                                styles: DefaultPageStyles.redColor
                            }}
                            disabled={props?.disabled}
                            style={{ height: "15px" }}
                            onClick={(event) => {
                                if (props?.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase() &&
                                    props?.opportunityDealStatusCode !== OpportunityDealStatusCodes.Draft) {
                                    var findItem = props?.savedAttachments?.find(x => x?.id === cellProps?.dataItem.id);
                                    if (findItem) {
                                        setHideAttachmentDeleteDialog(false);
                                        setattachmentId(cellProps?.dataItem.id);
                                    }
                                    else {
                                        removeAttachment(cellProps?.dataItem.id);
                                    }
                                } else {
                                    removeAttachment(cellProps?.dataItem.id);
                                }
                                event.preventDefault();
                                return;
                            }}
                        >
                        </ActionButton>
                    </td>
                )}
            />
            }
        </Grid>
        </Stack>
        <CoherenceDialog
            hidden={hideAttachmentDeleteDialog}
            onDismiss={() => {
                setHideAttachmentDeleteDialog(true);
            }}
            isBlocking={true}
            dialogContentProps={{
                type: DialogType.normal,
                title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeleteAttachmentWarning", UIControlsText.DeleteAttachmentWarning),
                subText: getRuleValidationMessage(sessionResourceData, MessageTypeText.PopupMessageText, "AttachmentDeleteMessage", UIControlsText.AttachmentDeleteMessage)
            }}
        >
            <CoherenceDialogFooter>
                <PrimaryButton
                    text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "OkText", UIControlsText.OkText)}
                    ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "OkText", UIControlsText.OkText)}
                    onClick={() => {
                        removeAttachment(attachmentId);
                        setHideAttachmentDeleteDialog(true);
                    }}
                />
                <DefaultButton
                    text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)}
                    ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)}
                    onClick={() => {
                        setHideAttachmentDeleteDialog(true);
                    }}
                />
            </CoherenceDialogFooter>
        </CoherenceDialog>
    </>

    return (
        <div>
            <BadgeContainer>
                <Stack horizontal>
                    <StackItem>
                        <Label
                            required={props?.required}
                            htmlFor="dealOverviewAttachments">
                            {props?.labelText}
                        </Label>
                    </StackItem>
                    <StackItem style={props?.required ? DefaultPageStyles.paddingTop3px : DefaultPageStyles.paddingTopLeft}>
                        <Tooltip {...{ guidanceText: props?.guidanceText, id: `${ props?.source?.replace(/ /g, '') }Attachment` }}></Tooltip>
                    </StackItem>
                </Stack>
                <Stack>
                    {props?.pageMode !== PageMode.View  && <Upload
                        batch={false}
                        multiple={true}
                        withCredentials={false}
                        files={files}
                        autoUpload={false}
                        showActionButtons={false}
                        onAdd={onAddAttachment}
                        showFileList={false}
                        disabled={props?.disabled}
                        className={"attachment-container"}
                        id={fileUploadId} />
                    }
                    <Label
                        hidden={!(attachmentList?.length > 0)}
                        onClick={openPanel}>
                        <div  style={DefaultPageStyles.paddingLeftOnly} title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AttachmentBadgeTitle", UIControlsText.AttachmentBadgeTitle)} hidden={!displayInPanelGrid}>
                            <Badge
                                position={"inside"}
                                style={{ cursor: "pointer" }}
                                themeColor={"dark"}
                                align={{
                                    vertical: "top",
                                    horizontal: "end",
                                }}>{attachmentList?.length}</Badge>
                        </div>
                    </Label>
                    {displayInPanelGrid &&
                        <Panel
                            isLightDismiss
                            isOpen={showAttachmentList}
                            onDismiss={dismissPanel}
                            closeButtonAriaLabel="Close"
                            headerText={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachments", UIControlsText.Attachments)}>
                            {
                                gridData
                            }
                        </Panel>
                    }
                    {
                        !displayInPanelGrid && attachmentList.length > 0 && gridData
                    }
                </ Stack>
            </BadgeContainer>
        </div>
    );
}
export default Attachment;