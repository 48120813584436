/**
 * Enum for FieldMapMode.
 * @readonly
 * @enum 
 */
export enum FieldMapMode {
    /**
     * Diabled field config.
     */
    Disable = "Disable",

    /**
    * Hidden field config.
    */
     Hidden = "Hidden",
}