/**
 * Enum for OpportunityDealClosureDecisionType.
 * @readonly
 * @enum {string}
 */
 export enum OpportunityDealClosureDecisionName {
    /**
    * Approved value.
    */
    Approved = "Approved",

    /**
    * ApprovedPendingChannel value.
    */
    ApprovedPendingChannel = "Approved Pending Channel",

    /**
    * EditPendingReview value.
    */
    EditPendingReview = "Edit Pending Review",

    /**
    * Denied value.
    */
    Denied = "Denied",

    /**
    * InsufficientInformation value.
    */
    InsufficientInformation = "Insufficient Information",

    /**
    * RequireAdditionalInformation value.
    */
    RequireAdditionalInformation = "Require Additional Information",
}