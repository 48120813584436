/**
 * Enum for Message Type.
 * @readonly
 * @enum {Enum}
 */
 export enum MessageTypeText {
    /**
    * ClaimsValidationError.
    */
    ClaimsValidationError = "ClaimsValidationError",
    /**
    * OpportunityValidationError.
    */
    OpportunityValidationError = "OpportunityValidationError",
    /**
    * FieldGuidanceText.
    */
    FieldGuidanceText = "FieldGuidanceText",
    /**
     * FieldPlaceHolderText.
     */
    FieldPlaceHolderText = "FieldPlaceHolderText", 
    /**
    * FieldLabelText.
    */
    FieldLabelText = "FieldLabelText",
    /**
    * PopupMessageText.
    */
     PopupMessageText = "PopupMessageText",
     /**
    * UserEvent.
    */
      UserEvent = "UserEvent",
}