import { _Styles } from "../../../../content/styles/Page.styles";
import { ICaseDetailsProps } from "../props/ICaseDetailsProps";
import { Callout } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { UIControlsText } from "../../../../content/UIControlsText";
import { getRuleValidationMessage, getEntityDataFromSession } from '../../../../components/shared/common/util';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { MessageTypeText } from '../../../../models/MessageTypeText';


const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

/**
 * CaseDetails Component.
 * @function component
 */
const CaseDetails: React.FunctionComponent<ICaseDetailsProps> = (props: ICaseDetailsProps) => {
    const [hideDetail, { toggle: setHideDetail }] = useBoolean(false);
    const displayDetails = () => {
        setHideDetail();
    }

    return (
        <div id="parentDetail">
            {/*to show case name */}
            <div>
                {props.caseName}
            </div>

            {/*to show partner details */}
            {hideDetail && (
                <Callout
                    ariaLabelledBy={"labelId"}
                    ariaDescribedBy={"descriptionId"}
                    gapSpace={0}
                    target={'#' + getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerInformation", UIControlsText.PartnerInformation)}
                    onDismiss={setHideDetail}
                    setInitialFocus
                >
                    {props.isRequestor &&
                        <div className="tableContent">
                            <div className="tablerow">
                                <span className="tablecell">{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MPNOrgID", UIControlsText.MPNOrgID)}</span> <span className="tablecell"> {props.orgID} </span></div>
                            <div className="tablerow">
                                <span className="tablecell">{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MPNLocationID", UIControlsText.MPNLocationID)}</span> <span className="tablecell">{props.locationID}</span> </div>
                            <div className="tablerow">
                                <span className="tablecell">{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductGroup", UIControlsText.ProductGroup)} </span> <span className="tablecell"> {props.productGroup}</span></div>
                            <div className="tablerow">
                                <span className="tablecell">{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerRole", UIControlsText.PartnerRole)}:</span> <span className="tablecell">{props.partnerRole}</span></div>
                        </div>
                    }
                    {!props.isRequestor &&
                        <div className="tableContent">
                            <div className="tablerow">
                                <span className="tablecell">{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Country", UIControlsText.Country)}</span> <span className="tablecell">{props.country}</span>
                            </div>
                            <div className="tablerow">
                                <span className="tablecell">{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductGroup", UIControlsText.ProductGroup)} </span> <span className="tablecell"> {props.productGroup}</span></div>
                            <div className="tablerow">
                                <span className="tablecell">{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerRole", UIControlsText.PartnerRole)}:</span> <span className="tablecell">{props.partnerRole}</span></div>
                        </div>
                    }

                </Callout>
            )}

            {/*to show case status */}
            <div id="statusDetail">
                {false && <span onMouseOver={displayDetails} onMouseOut={setHideDetail} onClick={setHideDetail} id={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerInformation", UIControlsText.PartnerInformation)} hidden={props.isPartner}>{props.labelText}</span>}
                {props.caseStatus}
            </div>

        </div>
    )
}
export default CaseDetails;

