import { EditControlType } from 'fluentui-editable-grid';
import { IColumn } from 'office-ui-fabric-react';
import { UIControlsText } from '../../../content/UIControlsText';
import { mergeStyleSets, getTheme } from '@fluentui/react/lib/Styling';
import { IInitialAdditionalResellerValue } from '../../../models/IInitialAdditionalResellerValue';
import { IInitialFinalTierResellerValue } from '../../../models/IInitialFinalTierResellerValue';
import { getEntityDataFromSession, getRuleValidationMessage } from '../../../components/shared/common/util';
import { SessionStorageConsts } from '../../../models/SessionStorageConsts';
import { MessageTypeText } from '../../../models/MessageTypeText';

const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

export enum GridOperations {
    None = 1,
    Add = 2,
    Update = 3,
    Delete = 4
}

export interface GridConfigOptions {
    enableCellEdit: boolean;
    enableRowEdit: boolean;
    enableRowEditCancel: boolean;
    enableBulkEdit: boolean;
    enableColumnEdit: boolean;
    enableExport: boolean;
    enableTextFieldEditMode: boolean;
    enableTextFieldEditModeCancel: boolean;
    enableGridRowsDelete: boolean;
    enableGridRowsAdd: boolean;
    enableColumnFilterRules: boolean;
    enableRowAddWithValues: boolean;
    enableGridCopy: boolean;
    enableRowCopy: boolean;
    enableUnsavedEditIndicator: boolean;
    enableSave: boolean;
    enableGridReset: boolean;
    enableColumnFilters: boolean;
    enableDefaultEditMode: boolean;
}

export interface IDetailListGridConfigProps {
    visible: boolean,
    columns: IColumnConfig[],
    gridConfigOptions: GridConfigOptions,
    items: DistributorGridItemsType[] | PrimaryResellerGridItemsType[] | IInitialAdditionalResellerValue[] | IInitialFinalTierResellerValue[] | any[];
    onGridSave: any,
    onGridItemsChange: any;
    enableDefaultEditMode: boolean,
    height: string,
    width: string,
    className?: string,
}

export interface DistributorGridItemsType {
    id: string;
    distributorCountry: string;
    distributorNames: string;
    distributorOwner: boolean;
    distributorEmailContacts: string;
    action?: string;
    associatedPartner?: string;
};

export interface PrimaryResellerGridItemsType {
    id: string,
    resellerCountry: string;
    resellerType: string;
    resellerNames: string;
    resellerEmailContacts: string;
    action?: string;
};

export const DistributorGridColumnConfig: IColumnConfig[] = [
    {
        key: 'distributorCountry',
        name: 'distributorCountry',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DistributorCountryText", UIControlsText.DistributorCountryText),
        editable: false,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        inputType: EditControlType.TextField,
    },
    {
        key: 'distributorNames',
        name: 'distributorNames',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DistributorNames", UIControlsText.DistributorNames),
        editable: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 200,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'distributorOwner',
        name: 'distributorOwner',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DistributorOwner", UIControlsText.DistributorOwner),
        editable: true,
        dataType: 'string',
        minWidth: 60,
        maxWidth: 120,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'distributorEmailContacts',
        name: 'distributorEmailContacts',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContactEmailIds", UIControlsText.ContactEmailIds),
        required: true,
        editable: true,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 200,
        isResizable: true,
        inputType: EditControlType.MultilineTextField
    },
    {
        key: 'deleteAction',
        name: 'deleteAction',
        text: 'Delete',
        editable: false,
        dataType: 'string',
        minWidth: 60,
        maxWidth: 60,
        isResizable: true,
        //Placeholder Control. This is overridden by onRender
        inputType: EditControlType.Link
    },
];

export const PrimaryResellerGridColumnConfig: IColumnConfig[] = [
    {
        key: 'resellerCountry',
        name: 'resellerCountry',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerCountryText", UIControlsText.ResellerCountryText),
        editable: false,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 140,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'resellerType',
        name: 'resellerType',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerTypeText", UIControlsText.ResellerTypeText),
        editable: false,
        dataType: 'string',
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'resellerNames',
        name: 'resellerNames',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNames", UIControlsText.ResellerNames),
        editable: false,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'resellerEmailContacts',
        name: 'resellerEmailContacts',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContactEmailIds", UIControlsText.ContactEmailIds),
        required: true,
        editable: true,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 200,
        isResizable: true,
        //Placeholder Control. This is overridden by onRender
        inputType: EditControlType.MultilineTextField
    },
    {
        key: 'deleteAction',
        name: 'deleteAction',
        text: 'Delete',
        editable: false,
        dataType: 'string',
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        //Placeholder Control. This is overridden by onRender
        inputType: EditControlType.Link
    },
];

export const AdditionalResellerGridColumnConfig: IColumnConfig[] = [
    {
        key: 'arNo',
        name: 'arNo',
        text: 'AR',
        editable: false,
        dataType: 'number',
        minWidth: 20,
        maxWidth: 20,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'resellerName',
        name: 'resellerName',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameText", UIControlsText.ResellerNameText),
        editable: false,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'contactName',
        name: 'contactName',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContactName", UIControlsText.ContactName),
        editable: false,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'contactEmail',
        name: 'contactEmail',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContactEmailText", UIControlsText.ContactEmailText),
        required: true,
        editable: false,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'address',
        name: 'address',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddressFacetText", UIControlsText.AddressFacetText),
        editable: false,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 140,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'city',
        name: 'city',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CityFacetText", UIControlsText.CityFacetText),
        editable: false,
        dataType: 'string',
        minWidth: 75,
        maxWidth: 75,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'stateProvince',
        name: 'stateProvince',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "StateProvinceFacetText", UIControlsText.StateProvinceFacetText),
        editable: false,
        dataType: 'string',
        minWidth: 75,
        maxWidth: 75,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'zipPostalCode',
        name: 'zipPostalCode',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ZipPostalCode", UIControlsText.ZipPostalCode),
        editable: false,
        dataType: 'string',
        minWidth: 75,
        maxWidth: 75,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'attachmentName',
        name: 'attachmentName',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachments", UIControlsText.Attachments),
        editable: false,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'editAction',
        name: 'editAction',
        text: 'Edit',
        editable: false,
        dataType: 'string',
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        //Placeholder Control. This is overridden by onRender
        inputType: EditControlType.Link
    },
    {
        key: 'deleteAction',
        name: 'deleteAction',
        text: 'Delete',
        editable: false,
        dataType: 'string',
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        //Placeholder Control. This is overridden by onRender
        inputType: EditControlType.Link
    },
];

export const AdditionalResellerViewGridColumnConfig: IColumnConfig[] = [
    {
        key: 'arNo',
        name: 'arNo',
        text: 'AR',
        editable: false,
        dataType: 'number',
        minWidth: 20,
        maxWidth: 20,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'resellerName',
        name: 'resellerName',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameText", UIControlsText.ResellerNameText),
        editable: false,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'contactName',
        name: 'contactName',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContactName", UIControlsText.ContactName),
        editable: false,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'contactEmail',
        name: 'contactEmail',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContactEmailText", UIControlsText.ContactEmailText),
        required: true,
        editable: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'address',
        name: 'address',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddressFacetText", UIControlsText.AddressFacetText),
        editable: false,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 140,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'city',
        name: 'city',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CityFacetText", UIControlsText.CityFacetText),
        editable: false,
        dataType: 'string',
        minWidth: 75,
        maxWidth: 75,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'stateProvince',
        name: 'stateProvince',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "StateProvinceFacetText", UIControlsText.StateProvinceFacetText),
        editable: false,
        dataType: 'string',
        minWidth: 75,
        maxWidth: 75,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'zipPostalCode',
        name: 'zipPostalCode',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ZipPostalCode", UIControlsText.ZipPostalCode),
        editable: false,
        dataType: 'string',
        minWidth: 75,
        maxWidth: 75,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'attachmentName',
        name: 'attachmentName',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachments", UIControlsText.Attachments),
        editable: false,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'deleteAction',
        name: 'deleteAction',
        text: 'Delete',
        editable: false,
        dataType: 'string',
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        //Placeholder Control. This is overridden by onRender
        inputType: EditControlType.Link
    },
];

export const FinalTierResellerGridColumnConfig: IColumnConfig[] = [
    {
        key: 'ftrNo',
        name: 'ftrNo',
        text: 'FTR',
        editable: false,
        dataType: 'string',
        minWidth: 30,
        maxWidth: 30,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'resellerName',
        name: 'resellerName',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameText", UIControlsText.ResellerNameText),
        editable: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'contactName',
        name: 'contactName',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContactName", UIControlsText.ContactName),
        editable: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'contactEmail',
        name: 'contactEmail',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContactEmailText", UIControlsText.ContactEmailText),
        required: true,
        editable: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'address',
        name: 'address',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddressFacetText", UIControlsText.AddressFacetText),
        editable: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'city',
        name: 'city',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CityFacetText", UIControlsText.CityFacetText),
        editable: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'stateProvince',
        name: 'stateprovince',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "StateProvinceFacetText", UIControlsText.StateProvinceFacetText),
        editable: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'zipPostalCode',
        name: 'zipPostalCode',
        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ZipPostalCode", UIControlsText.ZipPostalCode),
        editable: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        inputType: EditControlType.TextField
    },
    {
        key: 'editAction',
        name: 'editAction',
        text: 'Edit',
        editable: false,
        dataType: 'string',
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        //Placeholder Control. This is overridden by onRender
        inputType: EditControlType.Link
    },
    {
        key: 'deleteAction',
        name: 'deleteAction',
        text: 'Delete',
        editable: false,
        dataType: 'string',
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        //Placeholder Control. This is overridden by onRender
        inputType: EditControlType.Link
    },
]

export interface IColumnConfig extends IColumn {
    key: string;
    text: string;
    editable?: boolean;
    dataType?: string;
    required?: boolean;
    isResizable?: boolean;
    includeColumnInExport?: boolean;
    includeColumnInSearch?: boolean;
    inputType?: EditControlType;
    onChange?: any;
    maxLength?: number;
    applyColumnFilter?: boolean;
    disableSort?: boolean;
    hidden?: boolean;
};

const theme = getTheme();
export const DetailListStyles = mergeStyleSets({
    container: {
        root: {
            background: theme.palette.neutralLighter,
            borderTop: '2px solid #0078d4',
            overflow: 'auto',
            maxHeight: '80%',
            marginLeft: 10,
            marginBottom: 10,
        },
        content: {
            flex: 1,
            justifyContent: 'left',
        },
        header: {
            root: {
                fontSize: 20,
                fontWeight: 'bold',
            }
        },
        row: {
            flexDirection: 'row',
            alignItems: 'left',
        },
    }
});