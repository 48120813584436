import { CoherenceDialog, CoherenceDialogFooter } from "@cseo/controls";
import { Grid, GridCellProps, GridColumn, GridHeaderCellProps } from "@progress/kendo-react-grid";
import { Upload, UploadOnAddEvent } from "@progress/kendo-react-upload";
import { encode } from "base64-arraybuffer";
import { DefaultButton, DialogType, Dropdown, IDropdownOption, IStackProps, IStackStyles, IStackTokens, Label, MessageBar, MessageBarType, Panel, PrimaryButton, Separator, Stack, TextField, Spinner } from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { getAllCountries, getLoggedinUserRoleDetails, getUserSession, validateEmails, getRuleValidationMessage, getEntityDataFromSession } from "../../components/shared/common/util";
import { CssClassConstants } from "../../content/CssClassConstants";
import { ErrorMessages } from "../../content/ErrorMessages";
import { AddCaseOverlayStyles, AddCasToDashboardStyles, MarginStyles, stackButtonStyles, _Styles } from "../../content/styles/Page.styles";
import { UIControlsText } from "../../content/UIControlsText";
import { Guid } from "../../infrastructure/Guid";
import { JavaScriptExtensions } from "../../infrastructure/JavaScriptExtensions";
import { ApplicationConstants } from "../../models/ApplicationConstants";
import { IAccount } from "../../models/IAccount";
import { IAddOpportunityAccountToCaseRequest } from "../../models/IAddOpportunityAccountToCaseRequest";
import { IAttachment } from "../../models/IAttachment";
import { IUserRoleDetail } from "../../models/IUserRoleDetail";
import { IValidateCaseAndResellerRequest } from "../../models/IValidateCaseAndResellerRequest";
import { UserSubRole } from "../../models/UserSubRole";
import { nextGenService } from "../../services/NextGenService";
import { PageConstants } from "../pageconstants/Constants";
import { IAddToCaseProps } from "./props/IAddToCaseProps";
import fileUploadControlLocaleMessages from "../../content/fileUpload-locale-config.json";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { useBoolean } from '@fluentui/react-hooks';
import { MessageTypeText } from '../../models/MessageTypeText';
import { SessionStorageConsts } from "../../models/SessionStorageConsts";
import { useToAddRoleAlert as mdppAccessibilityAddRoleAttribute } from "../../hooks/accessiblity-hooks";
import { UserRole } from "../../models/UserRole";

const stackTokensForOverlayButtons: IStackTokens = { childrenGap: 20 };
const stackTokensForDialog: IStackTokens = { childrenGap: 12 };
const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

export const AddToCase: React.FunctionComponent<IAddToCaseProps> = (props: IAddToCaseProps) => {
    const [hideDialog, setHideDialog] = React.useState<boolean>(true);
    const [showGrid, setShowGrid] = React.useState<boolean>(true);
    const [showLoadingResellersSpinner, setShowLoadingResellersSpinner] = React.useState<boolean>(false);
    const [showSubmittingAddCaseToDashboardSpinner, setShowSubmittingAddCaseToDashboardSpinner] = React.useState<boolean>(false);
    const [showAccountCountry, setShowAccountCountry] = React.useState<boolean>(false);
    const [disableSubmit, setDisableSubmit] = React.useState(true);
    const [casIdValue, setCasIdValue] = React.useState<string>("");
    const [resellerNameValue, setResellerNameValue] = React.useState<string>("");
    const [disableSearch, setDisableSearch] = React.useState<boolean>(true);
    const [resellerGridData, setResellerGridData] = React.useState<IAccount[]>();
    const [opportunityData, setOpportunityData] = React.useState<any>();
    const [selectedReseller, setSelectedReseller] = React.useState<IAccount | null>(null);
    const [selectedFile, setSelectedFile] = React.useState<any>(); //contains information on the currently picked file.
    const [isFilePicked, setIsFilePicked] = React.useState(false); //determines if a file has been picked or not.
    const [isRadioSelected, setIsRadioSelected] = React.useState(false);
    const [selectedADDAccount, setSelectedADDAccount] = React.useState<IAccount | null>();
    const [showSelectADDAccount, setShowSelectADDAccount] = React.useState<boolean>(false);
    const [disableSelectADDAccount, setDisableSelectADDAccount] = React.useState<boolean>(false);
    const [aDDAccounts, setADDAccounts] = React.useState<IAccount[] | undefined>([]);
    const [channelContactEmails, setChannelContactEmails] = React.useState<string>();
    const [emailError, setEmailError] = useState('');
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [showAddMessageBar, setAddShowMessageBar] = React.useState<boolean>(false);
    const resetChoice = React.useCallback(() => setAddShowMessageBar(false), []);
    const [errorMessageType, setErrorMessageType] = React.useState(MessageBarType.success);
    const [msgBarText, setMsgBarText] = React.useState<string>();
    const [multiline, { toggle: toggleMultiline }] = useBoolean(false);

    useEffect(() => {
        setHideDialog(props.hideDialog);
        setShowGrid(false);
        resetInputField();
    }, [props])

    useEffect(() => {
        loadMessages(fileUploadControlLocaleMessages, "bg-BG");
    }, [])

    /**
       * Handle File Upload Event.
       * @method
       * @param {Event>} event form event.
       */
    const onFileUpload = async (event: UploadOnAddEvent) => {
        setIsFilePicked(true);
        const attachments = await Promise.all(
            event.affectedFiles.map(async (file) => {
                if (file?.getRawFile) {
                    var bufffer = await file.getRawFile().arrayBuffer();
                    var content = encode(bufffer);
                    var att: IAttachment = {
                        id: Guid.newGuid(),
                        name: file.name,
                        extension: file.extension,
                        size: file.size,
                        source: "",
                        content: content,
                    };
                    return att;
                }
            })
        );
        setSelectedFile(attachments[0]);
    };

    /**
     * Handle Upload File Remove.
     * @method
     */
    function onFileRemove() {
        setSelectedFile(undefined);
        setIsFilePicked(false);
    }

    useEffect(() => {
        if (selectedFile && selectedFile !== undefined) {
            const allowedExtensions = ['.PDF', '.DOC', '.DOCM', '.DOCX', '.DOT', '.DOTM', '.DOTX', '.MSG'];
            const allowedFileSize = 5e+6;
            if (allowedExtensions.find(col => col.toLowerCase() === selectedFile.extension) && selectedFile.size < allowedFileSize) {
                setIsFilePicked(true);
            }
            else {
                setIsFilePicked(false);
            }
        } else {
            setIsFilePicked(false);
        }
    }, [selectedFile, isRadioSelected]);

    useEffect(() => {
        if (isFilePicked === true && isRadioSelected === true && (!JavaScriptExtensions.isEmptyOrNullOrUndefined(channelContactEmails) && JavaScriptExtensions.isEmptyOrNullOrUndefined(emailError)) && !JavaScriptExtensions.isNullOrUndfinedOrEmpty(selectedADDAccount)) {
            setDisableSubmit(false);
        }
        else {
            setDisableSubmit(true);
        }
    }, [isFilePicked, isRadioSelected, channelContactEmails, emailError, selectedADDAccount]);

    /**
     * Handle Reseller select event.
     * @method
     * @param {Event} event form event.
     */
    async function onResellerSelect(resellerData: IAccount) {
        setSelectedReseller(resellerData);
        setShowSelectADDAccount(false);
        if(opportunityData != null){
            if(opportunityData.dealType != null && opportunityData.dealType.name === "CP Strategic"){
                setShowAccountCountry(true);
            }
            const accounts = await Promise.resolve(GetADDAccounts(getLoggedinUserRoleDetails(), resellerData.countryId?.toString(), opportunityData.opportunityProductTypeId));
            if (accounts != undefined && accounts?.length > 1) {
                setSelectedADDAccount(null);
                setShowSelectADDAccount(true);
                setDisableSelectADDAccount(false);
            } else if(accounts != undefined && accounts?.length == 1) {
                console.log(accounts[0])
                setShowSelectADDAccount(true);
                setSelectedADDAccount(accounts[0]);
                setDisableSelectADDAccount(true);
            }
            else {
                setShowSelectADDAccount(false);
                setSelectedADDAccount(null);
                setDisableSelectADDAccount(true);
            }
            setIsRadioSelected(true);
        }
    }

    /**
     * Reset input values of textfield.
     * @method
     */
    const resetInputField = () => {
        setAddShowMessageBar(false);
        setCasIdValue("");
        setResellerNameValue("");
        setOpportunityData({});
        setSelectedReseller(null);
        setErrorMsg(false);
        setSelectedFile(undefined);
        setIsRadioSelected(false);
        setEmailError('');
        toggleMultiline();
    };

    /**
    * Invoke API to get reseller data.
    * @method
    */
    const searchReseller = () => {
        setShowLoadingResellersSpinner(true);
        setAddShowMessageBar(false);
        setSelectedReseller(null);
        setIsRadioSelected(false);
        setShowSelectADDAccount(false);

        var userSession = getUserSession();

        if (userSession != null) {

            let loggedInUserInfo = JSON.parse(userSession);

            let validateCaseAndResellerRequest: IValidateCaseAndResellerRequest =
            {
                caseId: casIdValue,
                resellerName: resellerNameValue?.toString(),
                userRoleDetails: loggedInUserInfo.userRoleDetails,
            }

            nextGenService.validateCaseAndReseller(validateCaseAndResellerRequest).then((response) => {
                if (response.status === 200) {
                    if (response.data.success) {
                        setResellerGridData(response.data.resellers);
                        const OpportunityData = {
                            opportunityId: response.data.opportunityId,
                            opportunityProductTypeId: response.data.opportunityProductTypeId,
                            dealType: response.data.dealType
                        };
                        setOpportunityData(OpportunityData);
                        if (!IsCasIdSame(validateCaseAndResellerRequest.caseId)) {
                            setChannelContactEmails(response.data.emails.join(';'));
                            if (response.data.emails.length === 0) {
                                setEmailError(ErrorMessages.ValidEmailError);
                            }
                        }
                        setShowGrid(true);
                        setShowLoadingResellersSpinner(false);
                        document.querySelector("[id='channelContactEmail']")?.setAttribute('data-opportunityNumber', validateCaseAndResellerRequest.caseId);
                    } else {
                        setShowGrid(false);
                        setOpportunityData({});
                        setErrorMessageType(MessageBarType.error);
                        setAddShowMessageBar(true);
                        setMsgBarText(response.data.errorMessage);
                        setShowLoadingResellersSpinner(false);
                    }
                }
                else {
                    props.onSuccess(true, MessageBarType.error, ErrorMessages.APIErrorMessage, false, true);
                }
            }).catch((e) => {
                setShowGrid(false);
                setOpportunityData({});
                setHideDialog(true);
                props.onSuccess(true, MessageBarType.error, ErrorMessages.APIErrorMessage, false, true);
            });
        }
    };
    /**
   * Get TextField ErrorMessage.
   * @method
   * @param {string>} value Text Box value.
   * @@returns {string} Error Message.
   */
    const getTextFieldErrorMessage = (value: string): string => {
        return !value ? ErrorMessages.RequiredFieldError : "";
    };

    /**
     * Get TextField for reseller name ErrorMessage.
     * @method
     * @param {string>} value Text Box value.
     * @@returns {string} Error Message.
     */
    const getTextFieldErrorMessageForResellerName = (value: string): string => {
        if (!value) {
            return ErrorMessages.RequiredFieldError;
        } else if (value.length < ApplicationConstants.MinLengthForResellerName) {
            return ErrorMessages.RequiredLengthForResellerName;
        } else {
            return "";
        }
    };

    /**
     * Get CaseId Value
     * @method
     * @param {Event} event Form Event.
     * @param {string} newValue Text Box value.
     */
    const onCaseIdChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void => {
        setCasIdValue(newValue as string);
    };

    /**
     * Get Reseller Value
     * @method
     * @param {Event} event Form Event.
     * @param {string} newValue Text Box value.
     */
    const onResellerNameChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void => {
        setResellerNameValue(newValue as string);
    };

    useEffect(() => {
        if (
            casIdValue &&
            casIdValue?.length > 0 &&
            resellerNameValue &&
            resellerNameValue?.length >= ApplicationConstants.MinLengthForResellerName
        ) {
            setDisableSearch(false);
        } else {
            setDisableSearch(true);
        }
    }, [resellerNameValue, casIdValue]);

    /**
     *Reset the input fields for Add Case
     *@method
     */
    function clearOverlayData() {
        setHideDialog(true);
        setShowGrid(false);
        resetInputField();
        setShowSelectADDAccount(false);
        props.onSuccess(false, "", "", false, true);
    }
    /**
     *Add Case Overlay Grid Header Styling
     *@method
     */
    const cellWithBackGround = (props: GridHeaderCellProps) => {
        return (
            <span style={{
                lineHeight: "30px",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: 700,
            }}
            >
                {props.title}
            </span>
        );
    };

    /**
     *Invoke API to Add Case
     *@method
     */
    const AddOpportunityAccount = () => {

        setShowSubmittingAddCaseToDashboardSpinner(true);
        setDisableSubmit(true);
        const userSession = getUserSession();
        const isCPSDeal = showAccountCountry;
        if (userSession != null) {
            const addOpportunityAccountToCaseRequest: IAddOpportunityAccountToCaseRequest = {
                accountId: selectedADDAccount?.accountId,
                countryId: selectedADDAccount?.countryId,
                attachment: selectedFile,
                partnerAccountId: selectedReseller?.accountId,
                emails: channelContactEmails?.split(';').map(x => x.trim()),
                isCPSDeal: isCPSDeal,
                requestInitiatedBy: JSON.parse(userSession)?.email,
            };

            nextGenService.AddOpportunityAccountToCase(opportunityData.opportunityId, addOpportunityAccountToCaseRequest).then((response) => {
                if (response.status === 200) {
                    if (response.data.success) {
                        props.onSuccess(true, MessageBarType.success, response.data.successMsg, true, false);
                        clearOverlayData();
                    } else {
                        setErrorMessageType(MessageBarType.error);
                        setAddShowMessageBar(true);
                        setMsgBarText(response.data.error.message);
                        setDisableSubmit(false);
                    }
                } else {
                    props.onSuccess(true, MessageBarType.error, ErrorMessages.APIErrorMessage, false, false);
                    clearOverlayData();
                    setDisableSubmit(false);
                }
                setShowSubmittingAddCaseToDashboardSpinner(false);
                setShowGrid(true)
            }).catch((e) => {
                props.onSuccess(true, MessageBarType.error, ErrorMessages.APIErrorMessage, false, false);
                clearOverlayData();
            });
        }
    };

    const GetADDAccounts = async (loggedInUserInfo: Array<IUserRoleDetail>, resellerCountryId?: string, productTypeId?: string): Promise<IAccount[]> => {
        let allCountries = await Promise.resolve(getAllCountries());
        
        let userRoleDetails = loggedInUserInfo?.filter((user: IUserRoleDetail) => user.userSubRole === UserSubRole.ADD && user.productType?.productId === productTypeId);
        const resellerCountry = allCountries.find(c => c.countryId === resellerCountryId);
        const userCountryIds = Array.prototype.map.call(userRoleDetails, a => a.countryId);
        const allADDCountries = allCountries.filter(c => userCountryIds.includes(c.countryId));

        if (allADDCountries.length > 1) {
            let resellerGeoCountries = allADDCountries.filter(c => c.applicablePriceListGeo == resellerCountry?.applicablePriceListGeo);
            userRoleDetails = userRoleDetails?.filter((a: any) => resellerGeoCountries.some(c => a.countryId === c.countryId));
        }
        var userAccounts = userRoleDetails?.map(ua => {
            return {
                accountId: ua.accountId,
                countryId: ua.countryId,
                country: allADDCountries.find(x => x.countryId === ua.countryId)?.countryName,
                accountName: ua.organizationName
            } as IAccount
        });
        setADDAccounts(userAccounts);
        return userAccounts;
    }

    const onADDAccountSelectChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            const account = aDDAccounts?.find(account => account.accountId === item?.id);
            setSelectedADDAccount(account);
        }
    };

    /**
    * Set Email Value
    * @method
    * @param {Event} event Form Event.
    * @param {string} newValue Text Box value.
    */
    const onEmailValueChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void => {
        const emails = newValue as string;
        const newMultiline = emails.length > 50;
        if (newMultiline !== multiline) {
            toggleMultiline();
        }
        setChannelContactEmails(emails);
        if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(newValue)) {
            var result = validateEmails(emails, ';');
            if (result === false) {
                setEmailError(ErrorMessages.ValidEmailError);
                setErrorMsg(true);
            }
            else {
                setEmailError('');
                setErrorMsg(false);
            }
        } else {
            setErrorMsg(false);
        }
    };

    useEffect(() => {
        // Accessibility fix for "nested-interactive controls"
        var selectFilesButtonContainers = document.querySelectorAll('div[aria-label="browse your files"]');
        selectFilesButtonContainers.forEach((node) => {
            if (node.getAttribute("role") === "button") {
                node.setAttribute("role", "none");
            }
        });
    });

    useEffect(() => {
        var selectFilesButtonContainers = document.getElementsByClassName('k-upload-files');
        selectFilesButtonContainers[0]?.setAttribute("dir", 'ltr')
    }, [isFilePicked]);
    
    mdppAccessibilityAddRoleAttribute();

    const IsCasIdSame = (newOpportunityNumber: string): boolean => {

        let dataSet = document.querySelector("[id='channelContactEmail']")?.getAttribute('data-opportunityNumber');
        let oldOpportunityNumber = dataSet ? dataSet : "";

        if (JavaScriptExtensions.isEqual(oldOpportunityNumber, newOpportunityNumber)) {
            return true;
        } else {
            return false;
        }
    };

    const onRenderFooterContent = React.useCallback(
        () =>
        (
            <>{showGrid && (
                <Stack horizontal tokens={stackTokensForOverlayButtons} styles={stackButtonStyles}>
                    <PrimaryButton
                        text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitText", UIControlsText.SubmitText)}
                        onClick={() => {
                            AddOpportunityAccount();
                        }}
                        disabled={isFilePicked !== true || disableSubmit === true}
                    />
                    <DefaultButton
                        text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)} onClick={clearOverlayData} />
                </Stack>)}</>
        ),
        [clearOverlayData],
    );
   
    return (
        <Panel
            isOpen={!hideDialog}
            onDismiss={clearOverlayData}
            headerText={PageConstants.AddCasToDashboard}
            closeButtonAriaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}
            //isHiddenOnDismiss={true}
            onRenderFooterContent={onRenderFooterContent}
            className={CssClassConstants.AddToCasePanelClass}
        >
            <div style={MarginStyles.marginTop_16}/>
            {showAddMessageBar && (
                <Stack>
                    <div>
                        <MessageBar messageBarType={errorMessageType} onDismiss={resetChoice} dismissButtonAriaLabel="Close" isMultiline={true}>
                            {msgBarText}
                        </MessageBar>
                    </div>
                </Stack>
            )}
            <Stack tokens={stackTokensForDialog} style={AddCaseOverlayStyles.textSpacingStyle}>
                {showLoadingResellersSpinner &&
                    <Stack>
                        <div>
                            <Spinner label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Loading Resellers", UIControlsText.LoadingReseller)} />
                        </div>
                    </Stack>
                }
                {showSubmittingAddCaseToDashboardSpinner && showGrid &&
                    <Stack>
                        <div>
                            <Spinner label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Submitting Request", UIControlsText.SubmittingAddCaseToDashboardRequest)} />
                        </div>
                    </Stack>
                }   
                <Stack>
                    <TextField
                        label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseIdText", UIControlsText.CaseIdText)}
                        placeholder={PageConstants.CaseIdInputPlaceholderText}
                        validateOnFocusOut
                        validateOnLoad={false}
                        onGetErrorMessage={getTextFieldErrorMessage}
                        onChange={onCaseIdChange}
                        value={casIdValue} required
                        title={PageConstants.CaseIdInputPlaceholderText}
                        styles={AddCasToDashboardStyles}
                    />
                </Stack>
                <Stack>
                    <TextField
                        label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameText", UIControlsText.ResellerNameText)}
                        placeholder={PageConstants.ResellerNameInputPlaceholderText}
                        validateOnFocusOut
                        validateOnLoad={false}
                        onGetErrorMessage={getTextFieldErrorMessageForResellerName}
                        onChange={onResellerNameChange}
                        value={resellerNameValue} required
                        title={PageConstants.ResellerNameInputPlaceholderText}
                        styles={AddCasToDashboardStyles}
                    />
                </Stack>
            </Stack>
            <Stack horizontal tokens={stackTokensForOverlayButtons} style={MarginStyles.marginTop_15}>
                <PrimaryButton
                    text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Search", UIControlsText.Search)}
                    onClick={() => {
                        searchReseller();
                    }}
                    disabled={disableSearch}
                />
                <DefaultButton
                    text="Clear"
                    hidden={showGrid}
                    onClick={() => {
                        setShowGrid(false);
                        resetInputField();
                    }}
                />
            </Stack>
            {showGrid && (
                <>
                    <Separator />
                </>
            )}
            <Stack tokens={stackTokensForDialog}>
                {showGrid && (
                    <>
                        <Stack>
                            <Label style={AddCaseOverlayStyles.labelStyle} required>Proof of Reseller Awareness</Label>
                            
                            <div className={CssClassConstants.KendoRightToLeftClass} dir={CssClassConstants.DirectionRightToLeftClass}>
                                <LocalizationProvider language="bg-BG">
                                    <IntlProvider locale="bg">
                                        <Upload
                                            className={CssClassConstants.KendoButtonLinkClass}
                                            batch={false}
                                            restrictions={{
                                                maxFileSize: 5000000,
                                                allowedExtensions: ['.PDF', '.DOC', '.DOCM', '.DOCX', '.DOT', '.DOTM', '.DOTX', '.MSG']
                                            }}
                                            multiple={false}
                                            withCredentials={false}
                                            defaultFiles={[]}
                                            autoUpload={false}
                                            showActionButtons={false}
                                            onAdd={onFileUpload}
                                            onRemove={onFileRemove}
                                            showFileList={true}
                                        />
                                    </IntlProvider>
                                </LocalizationProvider>
                            </div>
                            <Label styles={AddCaseOverlayStyles.proofOfResellerFont}>The submitted attachment is subject to audit by Microsoft.</Label>
                        </Stack>
                        <Stack>
                            <TextField
                                label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelContactEmailText", UIControlsText.ChannelContactEmailText)}
                                placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "ChannelContactEmailPlaceholderText", UIControlsText.ChannelContactEmailPlaceholderText)}
                                validateOnFocusOut
                                validateOnLoad={false}
                                multiline={multiline}
                                onGetErrorMessage={getTextFieldErrorMessage}
                                onChange={onEmailValueChange}
                                value={channelContactEmails}
                                required={true}
                                title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "ChannelContactEmailPlaceholderText", UIControlsText.ChannelContactEmailPlaceholderText)}
                                id="channelContactEmail"
                                styles={AddCasToDashboardStyles}
                            />
                            {errorMsg &&
                                <Stack>
                                    <Label className={_Styles.emailErrorStyle}>{emailError}</Label>
                                </Stack>
                            }
                        </Stack>
                    </>
                )}
                {showSelectADDAccount && showAccountCountry && (
                    <Stack>
                        <Dropdown
                            label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SelectADDAccountText", UIControlsText.SelectADDAccountText)}
                            ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "SelectADDAccountPlaceholderText", UIControlsText.SelectADDAccountPlaceholderText)}
                            placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "SelectADDAccountPlaceholderText", UIControlsText.SelectADDAccountPlaceholderText)}
                            title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "SelectADDAccountPlaceholderText", UIControlsText.SelectADDAccountPlaceholderText)}
                            options={(aDDAccounts && aDDAccounts.map((pt) => ({ key: pt.countryId, text: pt.country, id: pt.accountId } as IDropdownOption))) || []}
                            onChange={onADDAccountSelectChange}
                            required={showAccountCountry}
                        />
                    </Stack>
                )}
                {showSelectADDAccount && !showAccountCountry && (
                    <Stack>
                        <Dropdown
                            label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SelectADDAccountAndCountryText", UIControlsText.SelectADDAccountAndCountryText)}
                            ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "SelectADDAccountAndCountryPlaceholderText", UIControlsText.SelectADDAccountAndCountryPlaceholderText)}
                            placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "SelectADDAccountAndCountryPlaceholderText", UIControlsText.SelectADDAccountAndCountryPlaceholderText)}
                            title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "SelectADDAccountAndCountryPlaceholderText", UIControlsText.SelectADDAccountAndCountryPlaceholderText)}
                            options={(aDDAccounts && aDDAccounts.map((pt) => ({ key: pt.accountId, text: pt.accountName + " / " + pt.country, id: pt.accountId } as IDropdownOption))) || []}
                            onChange={onADDAccountSelectChange}
                            defaultSelectedKey = {aDDAccounts?.length == 1 ? aDDAccounts[0].accountId : selectedADDAccount?.accountId}
                            required={!showAccountCountry}
                            styles={AddCasToDashboardStyles}
                        />
                    </Stack>
                )}

                {showGrid && (<Stack>
                    <Label style={AddCaseOverlayStyles.labelStyle} required>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameHeading", UIControlsText.ResellerNameHeading)}</Label>
                    <Grid style={{ height: "auto" }} data={resellerGridData} className={CssClassConstants.AddToCaseResellerGridClass}>
                        <GridColumn width="30px" field="accountId" title=" "
                            cell={(props: GridCellProps) => (
                                <td>
                                    <input
                                        name="selectedADD"
                                        type="radio"
                                        aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RadioButton", UIControlsText.RadioButton)}
                                        style={AddCaseOverlayStyles.radioButtonStyle}
                                        onChange={async () => { await onResellerSelect(props.dataItem); }}
                                        value={props.dataItem[props.field || ""]}
                                        checked={
                                            selectedReseller?.accountId ===
                                            props.dataItem[props.field || ""]
                                        }
                                        className={CssClassConstants.AddToCaseResellerRadioClass}
                                    />
                                </td>
                            )}
                        />
                        <GridColumn field="companyName" title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameHeading", UIControlsText.ResellerNameHeading)} headerCell={cellWithBackGround} />
                    </Grid>
                </Stack>)}
            </Stack>
        </Panel>
    );
}