import * as React from "react";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import {  useEffect } from "react"
import { useHistory } from "react-router";
import { _Styles } from '../../content/styles/Page.styles';
import { AAdAuth } from "./authentication";
import { UIControlsText } from "../../content/UIControlsText";
import { Stack } from "office-ui-fabric-react";
import { getRuleValidationMessage, getEntityDataFromSession} from '../../components/shared/common/util';
import { SessionStorageConsts } from '../../models/SessionStorageConsts';
import { MessageTypeText } from '../../models/MessageTypeText';

const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

export const LoginPage: React.FunctionComponent = () => {
    var redirectURL:any="";
    let history = useHistory();
    let rslrAuthUrl = process.env.REACT_APP_RSLR_LOGIN_API_URI || "";
    useEffect(() => {
        redirectURL = window.location.href;  
        console.log("current url:", redirectURL)
        if (redirectURL.includes("LoginWithAAD")) {           
                history.push("/AAdAuth");
        }
    });

    function loginRSLR() { 
        window.location.href = rslrAuthUrl;
    }

    return (
        <div>              
            <div className={_Styles.contentStyle}>               
                <div className={_Styles.LoginOuterPanel}>
                    <div  className={_Styles.LoginInnerPanel}>
                        <h1 className={_Styles.LoginInnerPanelh1}>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Welcome", UIControlsText.Welcome)}</h1>
                        <h2 className={_Styles.LoginInnerPanelh2}>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MDPP", UIControlsText.MDPP)}</h2>
                            <div className={_Styles.LoginInnerSign}>
                                <h2 className ={_Styles.LoginInnerSignh2}>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SigninMessage", UIControlsText.SigninMessage)}</h2>
                                <h4 className ={_Styles.LoginInnerSignh4}>
                                    {getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "WorkAccountDescription", UIControlsText.WorkAccountDescription)}
                                    <Stack className={_Styles.LoginTextStyle}>
                                        <i>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "SigninExample", UIControlsText.SigninExample)}</i> 
                                    </Stack>
                                    <Stack className={_Styles.LoginTextStyle}>
                                       <span>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "SigninGuidance", UIControlsText.SigninGuidance)}<a href="https://docs.microsoft.com/en-us/partner-center/azure-active-directory-tenants-and-partner-center" target="_blank">{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "ClickHere", UIControlsText.ClickHere)}</a></span>
                                    </Stack>                                                                                          
                                    <PrimaryButton 
                                      title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Signin", UIControlsText.Signin)}
                                      text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Signin", UIControlsText.Signin)}
                                      ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Signin", UIControlsText.Signin)}
                                      onClick={loginRSLR} />
                                    </h4>     
                                </div>
                            </div>
                    </div>
                </div>         
       </div>          
    )
}