/**
 * Enum for Channel Sub-Type.
 * @readonly
 * @enum {Enum}
 */
export enum ChannelSubType {
    /**
    * Requester.
    */
    Requester = 1,
    /**
    * Co-Owner.
    */
    CoOwner = 2,
    /**
    * Direct Sales and Support.
    */
    DSS = 3,
    /**
     * Digital Store.
     */
    DigitalStore = 4,
}