import LinkCustomKendoGridCell from "./linkcustomkendogridcell";
import { IGridCustomCellDictionary } from "./IGridCustomCellDictionary";
import { GridCellProps } from "@progress/kendo-react-grid";
import { ComponentType } from "react";

/**
 * Common Props for Shared components.
 * @Constant
 */
export const CustomKendoGridCellConstants: IGridCustomCellDictionary<ComponentType<GridCellProps>> = {
    'link': LinkCustomKendoGridCell
}