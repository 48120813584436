/**
 * Enum for MenuOption.
 * @readonly
 * @enum {number}
 */
export enum MenuOption {
    /**
     * Revert value.
     */
    Revert = 1,

    /**
     * Expire value.
     */
    Expire = 2,

    /**
     * Delete value.
     */
    Delete = 3,

      /**
     * CpStrategicLetters value.
     */
       Letters = 4,

}