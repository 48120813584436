import { IComponent } from '../../../shared/Components/DynamicFormRendering';
import { CustomerDetails } from './CustomerDetails';
import { useCommonPPContext } from '../../common/context/common-pp-context';
import { ProductDetails } from './ProductDetails'
import { useEffect, useState } from 'react';
import { _Styles } from '../../../../content/styles/Page.styles';
import { getFieldConfig, getStatusNameByCode, getRuleValidationMessage, getEntityDataFromSession, isRequestorViewing } from '../../../../components/shared/common/util';
import { FieldMapMode } from '../../../../models/FieldMapMode';
import { UIControlsText } from '../../../../content/UIControlsText';
import { DealDetails } from './DealDetails';
import { IPricingProgramProps } from '../props/IPricingProgramProps';
import { PageStyles } from '../../common/content/Page.Styles';
import { PricingDiscount } from './PricingDiscount';
import { backToDashboard } from '../../../../components/shared/common/util';
import { PartnerTypeLabel, PartnerTypeName } from '../../../../models/PartnerTypeLabel';
import { ChannelSubType } from '../../../../models/ChannelSubType';
import { IPivotTab } from '../../../../models/IPivotTab';
import { CaseActionTypes } from '../../common/reducer/CaseActionTypes';
import { IActionMenuProps } from '../../../../components/shared/action-menu/IActionMenuProps';
import { enableActionMenu } from '../../../../components/shared/action-menu/actionMenuUtil';
import { UIControlType } from '../../../../models/UIControlType';
import { Channel } from './Channel';
import { getSelectedDealDetails, setPartnerDetails, showActionMenuHelper } from '../PricingProgramFormUtils';
import { OpportunityDealStatusCodes } from '../../../../models/OpportunityDealStatusCodes';
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { PartnerType } from "../../../../models/PartnerType";
import { DealType } from "../../../../models/DealType";


export function ViewForm(props: IPricingProgramProps) {
    const { commonPPContextState, commonPPContextDispatch } = useCommonPPContext();
    const [dealNameAction, setdealNameAction] = useState<string>("");
    const [caseStatus, setcaseStatus] = useState<string>("");
    const [MPNOrgID, setMPNOrgID] = useState<string>("");
    const [MPNLocationID, setMPNLocationID] = useState<string>("");
    const [country, setCountry] = useState<string>("");
    const [partnerRole, setPartnerRole] = useState<string>("");
    const [showPartnerDetail, setShowPartnerDetail] = useState(false);
    const [isPartnerDetailHidden, setisPartnerDetailHidden] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [actionMenuConfig, setActionMenuConfig] = useState<IActionMenuProps>();
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const tabNames: string[] = [
        getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerText", UIControlsText.CustomerText),
        getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealInformationText", UIControlsText.DealInformationText),
        getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText),
        getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductsText", UIControlsText.ProductsText),
    ]
    const [isRequestor, setIsRequestor] = useState<boolean>(true);
    useEffect(() => {
        const oppDealNumber = commonPPContextState.selectedOptyDealCaseNumber;
        if (commonPPContextState.opportunityDeals.filter(t => t.name === oppDealNumber
            && (t.statusCode === OpportunityDealStatusCodes.Approved || t.statusCode === OpportunityDealStatusCodes.Expired))?.length > 0) {
            if (tabNames.filter(t => t === UIControlsText.PricingAndDiscountsText)?.length === 0) {
                tabNames.push(UIControlsText.PricingAndDiscountsText);
            }
        }

        let pivotTabs: IPivotTab[] = [];
        tabNames.forEach(t => pivotTabs.push({ tabName: t, errors: new Map<string, string>() }))
        commonPPContextDispatch({ type: CaseActionTypes.pivotTabData, pivotTabDetails: pivotTabs });
        if (commonPPContextState.name && oppDealNumber && oppDealNumber.length > 0) {
            var oppDeal = commonPPContextState.opportunityDeals.filter(d => d.name === oppDealNumber)[0];

            if (oppDeal) {
                var requester = commonPPContextState.opportunityAccounts?.filter(acc => acc.channelSubType === ChannelSubType.Requester)[0];
                const oppDealdetails = showActionMenuHelper(commonPPContextState, oppDeal, requester);
                const actionMenuConfig = enableActionMenu(oppDealdetails);
                actionMenuConfig.showCPStrategicLetters = false // hiding manufacturers confirmation letter action when viewing DR/SP case
                var dealType = oppDealdetails.dealType.name?oppDealdetails.dealType.name.toLowerCase():"";
                if (dealType ===  DealType.DealRegistration.toLowerCase() || dealType === DealType.SpecialPricing.toLowerCase())
                {
                    let selectedDealValue = getSelectedDealDetails(oppDealdetails, oppDeal);
                    if(selectedDealValue)
                    { 
                        selectedDealValue.customerName = oppDeal.dealCustomers && oppDeal.dealCustomers[0]?oppDeal.dealCustomers[0].legalEntityName:"";
                        let resellerAccount = oppDealdetails.opportunityAccounts?.filter(pt=>pt.partnerType == PartnerType.DMP || pt.partnerType == PartnerType.ADR);
                        selectedDealValue.resellerName = resellerAccount && resellerAccount[0]?resellerAccount[0].accountCompanyName:"";
                    }
                }
                setActionMenuConfig({ actionConfig: actionMenuConfig, selectedData: getSelectedDealDetails(oppDealdetails, oppDeal) });

                // to read case name with action from context and show in ui
                var dealNameAndAction = commonPPContextState.name ? commonPPContextState.name + " | " + getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ViewCaseText", UIControlsText.ViewCaseText) + " | " + oppDeal.dealType?.name : "";
                setdealNameAction(dealNameAndAction);

                //to read case status from context
                var caseStatus = "Case Status - " + getStatusNameByCode(oppDeal.statusCode);
                setcaseStatus(caseStatus);

                let partnerdetails = setPartnerDetails(commonPPContextState, props.productTypes, props.countries);
                if (requester && requester.accounts) {
                    setMPNOrgID(requester.accounts[0].organizationId || "");
                    var MPNLocAndCountry = (requester.accounts[0].locationId || "").concat(" / ").concat(requester.accounts[0].countryName || "")
                    setMPNLocationID(MPNLocAndCountry);
                    setCountry(requester.accounts[0].countryName || "");
                    var RoleName = PartnerTypeName.get(requester?.partnerType || 0) || "";
                    var RoleValue = PartnerTypeLabel.get(requester?.partnerType || 0) || "";
                    setPartnerRole(RoleName.concat(" (", RoleValue, ")"));
                }

                setIsRequestor(isRequestorViewing(commonPPContextState.submitterDetails?.email));
                commonPPContextDispatch({ type: CaseActionTypes.partnerDetailsChanged, partnerDetails: partnerdetails })
                setShowPartnerDetail(true)
                setLoadingData(false)

                //to show partner details only for Partners
                var ispartnerHidden = getFieldConfig("commonPP", commonPPContextState.pageMode || "", oppDeal.statusCode || 0, oppDeal.dealType || {}, getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerInformation", UIControlsText.PartnerInformation), FieldMapMode.Hidden) || false;

                setisPartnerDetailHidden(ispartnerHidden);
            }
        }

    }, [commonPPContextState.name, commonPPContextState.selectedOptyDealCaseNumber])

    const viewFormJson: IComponent = {
        type: 'FormGroup',
        data: {
            id: 'RootContainer',
            'data-nextgen-parent-group':"View Form Tabs",
            'data-nextgen-parentid':"View Form Tabs",
            components: [
                {
                    type: "Stack",
                    data: {
                        id: "action-menu-details",
                        components: [{
                            type: "InnerHTMLContainer",
                            data: {
                                id: "partnerDetailsTooltip",
                                contolType: UIControlType.ActionMenu,
                                actionMenuConfig
                            }
                        }]
                    }
                },
                {
                    type: 'Spinner',
                    visible: loadingData,
                    data: {
                        id: 'SpinnerCaseDetailsSection'
                    }
                },
                {
                    type: 'CaseDetails',
                    visible: showPartnerDetail,
                    data: {
                        id: 'CaseDetailsSection',
                        caseName: dealNameAction,
                        caseStatus: caseStatus,
                        orgID: MPNOrgID,
                        locationID: MPNLocationID,
                        productGroup: commonPPContextState?.partnerDetails?.productGroupName,
                        partnerRole: partnerRole,
                        isPartner: isPartnerDetailHidden,
                        isRequestor: isRequestor,
                        country: country,
                        labelText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerDetailsForCaseText", UIControlsText.PartnerDetailsForCaseText)
                    }
                },
                {
                    type: "PivotContainer",
                    visible: showPartnerDetail,
                    data: {
                        id: 'RootPivotContainerForAllTabs',
                        baseState: props.pageContext,
                        tabNames: commonPPContextState.pivotTabs,
                        items: [
                            CustomerDetails(),
                            DealDetails({ verticals: props.verticals, dealTypes: props.dealTypes, countries: props.countries }),
                            Channel(),
                            ProductDetails(props.productTypes),
                            PricingDiscount()
                        ]
                    }
                },
                {
                    type: 'Stack',
                    data: {
                        id: 'StackBackButton',
                        horizontal: "true",
                        disableshrink: 'true',
                        components: [
                            {
                                type: "StackItem",
                                data: {
                                    id: "StackItemViewBackButton",
                                    components: [
                                        {
                                            type: 'PrimaryButton',
                                            data: {
                                                id: 'BackButton',
                                                text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BackButtonText", UIControlsText.BackButtonText),
                                                styles: PageStyles.commandButtonPadding,
                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BackButtonText", UIControlsText.BackButtonText),
                                                title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BackButtonText", UIControlsText.BackButtonText),
                                                onClick: backToDashboard
                                            }
                                        },
                                    ]
                                }
                            },
                        ]
                    },
                },
                {
                    type: 'Stack',
                    data: {
                        id: 'footerPadding',
                        components: [
                        ]
                    },
                },
            ]
        }
    }
    return viewFormJson;
}