/**
 * Enum for AsyncProcessStatus.
 * @readonly
 * @enum {number}
 */
export enum AsyncProcessStatus {

    /**
     * NotStarted.
     */
    NotStarted = 0,

    /**
     * InProgress.
     */
     InProgress = 1,

    /**
     * Completed.
     */
     Completed = 2,
}