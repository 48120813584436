/**
 * Constants for API.
 * @const
 */
export const ApiConstants = {
    productTypeBase: "/api/ProductType/",
    getAllProductType: "GetAllProductType",
    countryBase: "/api/Country/",
    getAllCountries: "GetAllCountries",
    opportunityDeal: "/api/OpportunityDeal/",
    setOpportunityDealStatus: "SetOpportunityDealStatus",
    accountsBase: "/api/Accounts/",
    getAllActiveDistributors: "GetAllActiveDistributors",
    getAllActiveResellers: "GetAllActiveResellers",
    GetAllActiveResellersByProductAndCountries: "GetAllActiveResellersByProductAndCountries",
    getAllActiveResellersByProductAndCountry: "GetAllActiveResellersByProductAndCountry",
    GetResellersWithProductTypeRole: "GetResellersWithProductTypeRole",
    GetAddsAccountsByDmpAccountIds: "getAddsAccountsByDmpAccountIds",
    verticalBase: "/api/BusinessVertical/",
    getAllVerticals: "GetAllVerticals",
    contactBase: "/api/Contact/",
    getContactFromGraphByEmail: "GetContactFromGraphByEmail",
    getContactRoleOptions: "GetContactRoleOptions",
    opportunityBase: "/api/Opportunity/",
    getOpportunity: "GetOpportunity",
    saveDraftOpportunity: "SaveOpportunityDraft",
    revertOpportunityDealStatus: "RevertOpportunity",
    dealTypeBase: "/api/DealType/",
    getAllDealTypes: "GetAllDealTypes",
    saveOpportunity: "SaveOpportunity",
    saveOpportunityAsync: "SaveOpportunityAsync",
    userBase: "/api/User/",
    getUserDetails: "GetUserDetails",
    getAccountDetails: "GetUserAccountDetailsAsync",
    getChatbotUserRole: "GetChatBotUserRole",
    attachmentsBase: "/api/Attachment/",
    getCPStrategicLettersByOpportunityDealId: "GetCPStrategicLettersByOpportunityDealId",
    GetDashboardData: "GetDashboardData",
    validateOpportunityNameAndReseller: "ValidateCaseAndReseller",
    addOpportunityAccountToCase: "AddOpportunityAccountToCase",
    opportunityAccountBase: "/api/OpportunityAccount/",
    validateCustomers: "ValidateDealCustomers",
    opportunityDealProductSkuBase: "/api/OpportunityDealProductSku/",
    getOpportunityDealDiscountPeriodAndSkuMSRP: "GetOpportunityDealDiscountPeriodAndSkuMSRP",
    businessVertical: "BusinessVerticalByCustomerVerticalAndCategory",
    caseLetters: "/api/CaseLetters/",
    getCaseDecisionLetter: "GetCaseDecisionLetter",
    customerBase: "/api/customer",
    getCustomerIndustries: "/GetCustomerIndustryVerticalDetails",
    userVoiceBase: "/api/UserVoice/",
    saveUserVoice: "SaveUserVoice",
    opportunityDealCustomerBase: "/api/OpportunityDealCustomer",
    createUnmanagedCustomer: "/CreateCustomer",
    searchDuplicateDeal: "SearchDuplicateDeal",
    getDealQuantityThresholds: "GetDealQuantityThresholds",
    getDealDurationConfig: "GetDealDurationConfiguration",
    extendOpportunityDeal: "ExtendOpportunityDeal",
    getPricingProgramDashboardData: "GetPricingProgramDashboardData",
    getAllSpecialPricingProducts: "GetAllSpecialPricingProducts",
    updateUnmanagedCustomer: "/UpdateCustomer",
    blobBase: "/api/BlobStorage/",
    getBlobBufferByName: "GetBlobBufferByName",
    copyOpportunity: "CopyOpportunity",
    renewOpportunity: "RenewOpportunity",
    extensionConfig: "GetDealExtensionConfig",
    bidGridTypeBase: "/api/BidGrid/",
    getBidGridDashboardData: 'GetBidGridDashboardData',
    ruleValidationBase: "/api/RuleValidation/",
    getCustomMessageAndText: 'GetCustomMessageAndText',
};