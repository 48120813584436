import { AzSearchStore } from "azsearchstore";
import _, { StringIterator } from "lodash";
import moment from "moment";
import { UIControlsText } from "../../content/UIControlsText";
import { Guid } from "../../infrastructure/Guid";
import { ApplicationConstants } from "../../models/ApplicationConstants";
import { DealType } from "../../models/DealType";
import { GridType } from "../../models/GridType";
import { ICountry } from "../../models/ICountry";
import { IOpportunityDealProductSku } from "../../models/IOpportunityDealProductSku";
import { OpportunityDealStatusCodes } from "../../models/OpportunityDealStatusCodes";
import { SessionStorageConsts } from "../../models/SessionStorageConsts";
import { IGridProps } from "./grid/IGridProps";
import { IKendoGridColumnProps } from "./grid/IKendoGridColumnProps";
import { getRuleValidationMessage, getEntityDataFromSession } from "./common/util";
import { MessageTypeText } from "../../models/MessageTypeText";
import { appendFunction } from "@fluentui/react";
import { useState } from "react";

const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);


/**
 * GetCompactProductData method : this method is used in both cp strategic and DR/SP pricing to get compact view data
 * @returns {IOpportunityDealProductSku[]}.
 */
export function GetCompactProductData(productData: IOpportunityDealProductSku[], gridConfig: IGridProps, dealTypeName?: string, dealStatusCode?: number): IOpportunityDealProductSku[] {
    var discountTitle = ApplicationConstants.DiscountTitle;
    if (gridConfig.kendoGridProps.gridName === GridType.DRSPExpandView || gridConfig.kendoGridProps.gridName === GridType.DRSPCompactView) {
        discountTitle = dealTypeName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealRegistrationText", UIControlsText.DealRegistrationText) ? ApplicationConstants.CoreDiscountTitle : ApplicationConstants.DiscountTitle;
    }
    var apiData: any;
    const selectedData: IOpportunityDealProductSku[] = [];
    let roles: any

    if (productData.length !== 0) {
        apiData = productData;
        let i: number = 0;
        var startDate: string;
        var endDate: string;
        const defaultDateRanges = apiData.find((element: { dealPeriodStartDate: Date; }) => element.dealPeriodStartDate !== null);
        apiData.forEach((element: IOpportunityDealProductSku) => {
            roles = gridConfig.kendoGridProps.columns.filter(col => col.columnTitle === ApplicationConstants.Country)[0].allowColumnView;
            var column = {} as IKendoGridColumnProps;
            column.childColumns = [];
            if (element.hasOwnProperty(ApplicationConstants.DealPeriodDate) === false) {
                startDate = moment(defaultDateRanges.dealPeriodStartDate).format('MM/DD/YYYY');
                endDate = moment(defaultDateRanges.dealPeriodEndDate).format('MM/DD/YYYY');
            } else {
                startDate = moment(element.dealPeriodStartDate).format('MM/DD/YYYY');
                endDate = moment(element.dealPeriodEndDate).format('MM/DD/YYYY');
            }
            var dateColumnApi = "column" + i;
            var validityGridColumn = "From : " + startDate + " To " + endDate;
            if (selectedData.find((data) => data.productFamily === element.productFamily &&
                data.country === element.country)) {
                var discountValue = dateColumnApi;
                // var dynamicDiscountApi=discountApi
                if (gridConfig.kendoGridProps.columns.find((c) => c.columnTitle === validityGridColumn)) {
                    discountValue = gridConfig.kendoGridProps.columns.filter(col => col.columnTitle === validityGridColumn)[0].apiField;
                } else {
                    column.apiField = discountValue
                    column.columnTitle = validityGridColumn
                    column.isVisible = dealTypeName != undefined && dealStatusCode != undefined && (dealTypeName === DealType.SpecialPricing || dealTypeName === DealType.DealRegistration)  && dealStatusCode === OpportunityDealStatusCodes.Denied ? false : true;
                    column.allowColumnView = roles;
                    column.childColumns.push(getGridColumn(discountValue, discountTitle, roles));
                    gridConfig.kendoGridProps.columns.push(column)
                }
                var idx = selectedData.findIndex(item => item.productFamily === element.productFamily && item.country === element.country)
                var newArray: IOpportunityDealProductSku;
                var data = selectedData[idx];
                if (element.decisionType === ApplicationConstants.DeniedStatus) {
                    newArray = {
                        ...data,
                        [discountValue]: ""
                    };
                } else {
                    newArray = {
                        ...data,
                        [discountValue]: element.discountValue
                    };
                }
                selectedData[idx] = newArray
            } else {
                var discountField = dateColumnApi;
                if (gridConfig.kendoGridProps.columns.find((c) => c.columnTitle === validityGridColumn)) {
                    discountField = gridConfig.kendoGridProps.columns.filter(col => col.columnTitle === validityGridColumn)[0].apiField;
                }
                else {
                    column.apiField = discountField;
                    column.columnTitle = validityGridColumn;
                    column.isVisible = dealTypeName != undefined && dealStatusCode != undefined && (dealTypeName === DealType.SpecialPricing || dealTypeName === DealType.DealRegistration) && dealStatusCode === OpportunityDealStatusCodes.Denied ? false : true;
                    column.allowColumnView = roles;
                    column.childColumns.push(getGridColumn(discountField, discountTitle, roles));
                    gridConfig.kendoGridProps.columns.push(column)
                }
                if (element.decisionType === ApplicationConstants.DeniedStatus) {
                    selectedData.push({
                        ...element,
                        [discountField]: "",
                        [getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "IDColumnName", UIControlsText.IDColumnName)]: Guid.newGuid()
                    });
                } else {
                    selectedData.push({
                        ...element,
                        [discountField]: element.discountValue,
                        [getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "IDColumnName", UIControlsText.IDColumnName)]: Guid.newGuid()
                    });
                }
            }
            i += 1;
        });
    }
    const columnNamesDynamic: Array<string> = [];
    // Quantity column is added in the grid for OTLE
    gridConfig.kendoGridProps.columns.slice(6)?.forEach(element => {
        element.childColumns.forEach(element => {
                columnNamesDynamic.push(element.apiField)
            });
        });
          
    return AddMissingColumnWithValueForProducts(selectedData,columnNamesDynamic);
}

/**
 * Function to populate discount value as 0 if the column/value doesnt exist in the products API response.
 * @param selectedData Data Input Array
 * @param columnNamesDynamic dynamic columns names prepared earlier.
 * @returns Json Array which renders on the compact and expanded view for products & Pricing tab.
 */
function AddMissingColumnWithValueForProducts(selectedData :IOpportunityDealProductSku[], columnNamesDynamic : Array<string>)
{
    columnNamesDynamic.forEach(column =>{
        var i : number = 0
       selectedData.forEach(product =>
        {
            if(column in product == false)
            {
                var newArray: IOpportunityDealProductSku;
                newArray = {
                    ...product,
                    [column]: 0
                };  
                selectedData[i]= newArray 
            }
            i++;             
        });
    });
   return selectedData;
}
/**
 * GetExpandedProductData method : this method is used in both cp strategic and DR/SP pricing to get expanded view data
 * @returns {IOpportunityDealProductSku[]}.
 */
export function GetExpandedProductData(productData: IOpportunityDealProductSku[], gridConfig: IGridProps): IOpportunityDealProductSku[] {
    var discountTitle = ApplicationConstants.DiscountTitle;
    var showAdditionalDiscounts = false;
    if (gridConfig.kendoGridProps.gridName === GridType.DRSPExpandView || gridConfig.kendoGridProps.gridName === GridType.DRSPCompactView) {
        let isDrDealType = gridConfig.kendoGridProps.dealType === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealRegistrationText", UIControlsText.DealRegistrationText);
        discountTitle =  isDrDealType ? ApplicationConstants.CoreDiscountTitle : ApplicationConstants.DiscountTitle;
        showAdditionalDiscounts = isDrDealType;
    }
    var apiData: any
    let i: number = 0;
    var startDate: string;
    var endDate: string;
    let roles: any
    const selectedData: IOpportunityDealProductSku[] = [];
    if (productData.length !== 0) {
        apiData = productData
        apiData.forEach((element: IOpportunityDealProductSku) => {
            roles = gridConfig.kendoGridProps.columns.filter(col => col.columnTitle === ApplicationConstants.Country)[0].allowColumnView;
            var column = {} as IKendoGridColumnProps;
            column.childColumns = [];
            const defaultDateRanges = apiData.find((element: { dealPeriodStartDate: Date; }) => element.dealPeriodStartDate !== null);
            if (element.hasOwnProperty(ApplicationConstants.DealPeriodDate) === false) {
                startDate = moment(defaultDateRanges.dealPeriodStartDate).format('MM/DD/YYYY');
                endDate = moment(defaultDateRanges.dealPeriodEndDate).format('MM/DD/YYYY');
            } else {
                startDate = moment(element.dealPeriodStartDate).format('MM/DD/YYYY');
                endDate = moment(element.dealPeriodEndDate).format('MM/DD/YYYY');
            }
            var discountAmount = "column" + i;
            var discountField = "discountValue" + i;
            var boosterDiscountField = "boosterDiscount" + i;
            var totalDiscountField = "totalDiscount" + i;
            var validityGridColumn = "From : " + startDate + " To "
                + endDate;
            if (selectedData.find((data) => data.skuPartNumber === element.skuPartNumber &&
                data.country === element.country && data.netMSRP === element.netMSRP && data.currencyName == element.currencyName)) {
                var discountAmountFieldext = discountAmount;
                let discountFieldext = discountField;
                let boosterDiscountFieldext = boosterDiscountField;
                let totalDiscountFieldext = totalDiscountField;
                if (gridConfig.kendoGridProps.columns.find((c) => c.columnTitle === validityGridColumn)) {
                    discountAmountFieldext = gridConfig.kendoGridProps.columns.filter(col => col.columnTitle === validityGridColumn)[0].apiField;
                    var parentColumn = gridConfig.kendoGridProps.columns.find((c) => c.columnTitle === validityGridColumn)
                    discountFieldext = parentColumn?.childColumns.filter((col) => col.columnTitle === discountTitle)[0].apiField || ""
                    if (showAdditionalDiscounts) {
                        boosterDiscountFieldext = parentColumn?.childColumns.filter((col) => col.columnTitle ===  ApplicationConstants.BoosterDiscountTitle)[0].apiField || ""
                        totalDiscountFieldext = parentColumn?.childColumns.filter((col) => col.columnTitle ===  ApplicationConstants.TotalDiscountTitle)[0].apiField || ""
                    }
                } else {
                    column.apiField = discountAmountFieldext
                    column.columnTitle = validityGridColumn
                    column.isVisible = true;
                    column.allowColumnView = roles;
                    column.childColumns.push(getGridColumn(discountFieldext, discountTitle, roles));
                    if (showAdditionalDiscounts) {
                        column.childColumns.push(getGridColumn(boosterDiscountFieldext,  ApplicationConstants.BoosterDiscountTitle, roles));
                        column.childColumns.push(getGridColumn(totalDiscountFieldext,  ApplicationConstants.TotalDiscountTitle, roles));
                    }
                    column.childColumns.push(getGridColumn(discountAmountFieldext, ApplicationConstants.DiscountAmountTitle, roles));
                    gridConfig.kendoGridProps.columns.push(column)
                }
                var idx = selectedData.findIndex(item => item.skuPartNumber === element.skuPartNumber
                    && item.country === element.country && item.netMSRP === element.netMSRP)
                var newArray = {} as IOpportunityDealProductSku;
                var data = selectedData[idx];
                var discountNo = showAdditionalDiscounts === true ? (element.discountValue + element.boosterDiscountValue) : element.discountValue;
                if (element.decisionType === ApplicationConstants.ApprovedStatus) {
                    newArray = {
                        ...data, [discountAmountFieldext]:
                            Math.round((((discountNo * element.netMSRP) / 100) + Number.EPSILON) * 100) / 100,
                        [discountFieldext]: element.discountValue,
                        [boosterDiscountFieldext]: element.boosterDiscountValue,
                        [totalDiscountFieldext]: element.totalDiscountValue,
                        [getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "IDColumnName", UIControlsText.IDColumnName)]: Guid.newGuid()
                    };
                }
                selectedData[idx] = newArray
            } else {
                var discountAmountField = discountAmount;
                var discountFieldVal = discountField;
                var boosterDiscountFieldVal = boosterDiscountField;
                var totalDiscountFieldVal = totalDiscountField;
                if (gridConfig.kendoGridProps.columns.find((c) => c.columnTitle === validityGridColumn)) {
                    discountAmountField = gridConfig.kendoGridProps.columns.filter(col => col.columnTitle === validityGridColumn)[0].apiField;
                    var parentColumn1 = gridConfig.kendoGridProps.columns.find((c) => c.columnTitle === validityGridColumn)
                    discountFieldVal = parentColumn1?.childColumns.filter((col) => col.columnTitle === discountTitle)[0].apiField || ""
                    if (showAdditionalDiscounts) {
                        boosterDiscountFieldVal = parentColumn1?.childColumns.filter((col) => col.columnTitle ===  ApplicationConstants.BoosterDiscountTitle)[0].apiField || ""; 
                        totalDiscountFieldVal = parentColumn1?.childColumns.filter((col) => col.columnTitle ===  ApplicationConstants.TotalDiscountTitle)[0].apiField || ""                       
                    }
                }
                else {
                    column.apiField = discountAmountField;
                    column.columnTitle = validityGridColumn;
                    column.isVisible = true;
                    column.allowColumnView = roles;
                    column.childColumns.push(getGridColumn(discountFieldVal, discountTitle, roles));
                    if (showAdditionalDiscounts) {
                        column.childColumns.push(getGridColumn(boosterDiscountFieldVal, ApplicationConstants.BoosterDiscountTitle , roles));
                        column.childColumns.push(getGridColumn(totalDiscountFieldVal,  ApplicationConstants.TotalDiscountTitle, roles));
                    }
                    column.childColumns.push(getGridColumn(discountAmountField, ApplicationConstants.DiscountAmountTitle, roles));
                    gridConfig.kendoGridProps.columns.push(column)
                }
                if (element.decisionType === ApplicationConstants.ApprovedStatus) {
                    var discountNo = showAdditionalDiscounts === true ? (element.discountValue + element.boosterDiscountValue) : element.discountValue;
                    selectedData.push({
                        ...element,
                        [discountAmountField]:
                            Math.round((((discountNo * element.netMSRP) / 100) + Number.EPSILON) * 100) / 100,
                        [discountFieldVal]: element.discountValue,
                        [boosterDiscountFieldVal]: element.boosterDiscountValue,
                        [getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "IDColumnName", UIControlsText.IDColumnName)]: Guid.newGuid(),
                        [totalDiscountFieldVal]: showAdditionalDiscounts ? element.totalDiscountValue : 0
                    });
                }
            }
            i += 1;
        });
    }
    
    const columnNamesDynamic: Array<string> = [];
    gridConfig.kendoGridProps.columns.slice(12)?.forEach(element => {
        element.childColumns?.forEach(element => {
                columnNamesDynamic.push(element.apiField)
            });
        });
        
       
    return AddMissingColumnWithValueForProducts(selectedData,columnNamesDynamic);
}

/**
 * formatProductSearchAPIData method : this method is used in both cp strategic and DR/SP pricing to format the data returend by product search index
 * @returns {any[]}.
 */
export function formatProductSearchAPIData(resultArray: any[], selectedGeoCountries: ICountry[], PFAMProductSearch: boolean): any[] {
    let array: any[] = [];
    const allCountries: ICountry[] = JSON.parse(sessionStorage.getItem(SessionStorageConsts.countries) || '{}');

    if (selectedGeoCountries != null && selectedGeoCountries.length > 0) {
        let productsForEachEUCountry: any[] = [];
        let productsForEachNonEUCountry: any[] = [];
        if (selectedGeoCountries.filter(country => country.isEUEFTA === true).length > 0) {
            let euCountries: string[] = allCountries.filter(country => country.isEUEFTA === true).map(filteredCountry => { return filteredCountry.countryId });
            let euProducts: any[] = resultArray.filter(product => euCountries.includes(product.CountryId));
            let distinctEUProducts: any[];
            if (PFAMProductSearch) {
                distinctEUProducts = _.uniqBy(euProducts, product => [product.PfamName].join());
            }
            else {
                distinctEUProducts = _.uniqBy(euProducts, product => [product.BusinessUnit].join());
            }
            selectedGeoCountries.filter(geoCountry => euCountries.includes(geoCountry.countryId)).forEach(function (country) {
                productsForEachEUCountry = productsForEachEUCountry.concat(distinctEUProducts.map(product => {
                    var tempProduct = Object.assign({}, product);
                    tempProduct.CountryId = country.countryId;
                    tempProduct.Country = country.countryName;
                    tempProduct.id = Guid.newGuid();
                    return tempProduct;
                }));
            });
        }

        if (selectedGeoCountries.filter(country => country.isEUEFTA === false).length > 0) {
            let nonEUCountries: string[] = allCountries.filter(country => country.isEUEFTA === false).map(filteredCountry => { return filteredCountry.countryId });
            let nonEUProducts: any[] = resultArray.filter(product => nonEUCountries.includes(product.CountryId));
            selectedGeoCountries.filter(geoCountry => geoCountry.isEUEFTA === false).forEach(function (country) {
                productsForEachNonEUCountry = productsForEachNonEUCountry.concat(nonEUProducts.filter(product => product.Country === country.applicablePriceListGeo).map(product => {
                    var tempProduct = Object.assign({}, product);
                    tempProduct.CountryId = country.countryId;
                    tempProduct.Country = country.countryName;
                    tempProduct.id = Guid.newGuid();
                    return tempProduct;
                }));
            });
        }

        array = array.concat(productsForEachNonEUCountry);
        array = array.concat(productsForEachEUCountry);
    }
    else {
        array = resultArray.map((item: any) => ({ ...item, ['id']: Guid.newGuid() }));
    }

    return array;
}

/**
 * formatProductSearchAPIData method : this method is used in both cp strategic and DR/SP pricing to format the data returend by product search index
 * @returns {any[]}.
 */
export function setProductSearchStore(indexName: string, searchSuggesterName: string, searchSelectColumn: string, globalSearchFilterExpression: string): AzSearchStore {
    const searchStore = new AzSearchStore();
    
    /** * AZ Search Configuration */
    // define search configuration
    searchStore.setConfig({
        index: indexName,
        queryKey: process.env.REACT_APP_SEARCH_API_KEY || "",
        service: process.env.REACT_APP_SEARCH_API_ENV || "",
    });

    // set suggester, project some additional fields into the returned suggestions
    searchStore.updateSuggestionsParameters({
        suggesterName: searchSuggesterName,
        select: searchSelectColumn,
        top: ApplicationConstants.TopSearchSuggesstions,
        fuzzy: ApplicationConstants.IsFuzzy,
        filter: globalSearchFilterExpression,
    });
    const defaultProductSuggestionsProcessor = (results: any): any => {
        return results.map(function (result: any) {
            result.searchText = result["@search.text"];
            return result;
        });
    };
    searchStore.setSuggestionsProcessor(defaultProductSuggestionsProcessor);
    
    return searchStore;
}

const getGridColumn = (apiField: string, title: string, roles: string[]): IKendoGridColumnProps => {
    var column = {} as IKendoGridColumnProps;
    column.apiField = apiField;
    column.columnTitle = title;
    column.isVisible = true;
    column.allowColumnView = roles;
    return column;
}