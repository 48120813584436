import { useEffect, useState } from 'react';
import { UIControlsText, UIConfigIdentifierConstants } from '../../../../content/UIControlsText';
import { DealDetailsStyles, PageStyles } from '../../common/content/Page.Styles';
import { DistributorGridColumnConfig, PrimaryResellerGridColumnConfig, PrimaryResellerGridItemsType, DistributorGridItemsType, IColumnConfig } from '../../../shared/ComponentTypes/DetailListEditableGridTypes';
import { defaultColumnProps, _Styles } from '../../../../content/styles/Page.styles';
import { getEntityDataFromSession, getRuleValidationMessage } from '../../../../components/shared/common/util';
import { stackItemStyles, smallAlignmentTokens } from '../../PricingProgramForm/PricingProgramFormUtils';
import { PartnerTypeLabel } from '../../../../models/PartnerTypeLabel';
import _ from 'lodash';
import { PartnerType } from '../../../../models/PartnerType';
import { Stack } from '@fluentui/react';
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { useInternalPricingContext } from '../context/internalpricing-context';
import { EditControlType } from 'fluentui-editable-grid';
import DocumentCardContainerComponent from '../../../shared/ComponentTypes/CustomTileCardContainer';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { UserSubRole } from '../../../../models/UserSubRole';

export function OtleChannelView() {
    const { internalPricingContextState } = useInternalPricingContext();
    const opportunityAccounts = internalPricingContextState && internalPricingContextState?.opportunityAccounts;
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const [primaryResellerGridItems, setPrimaryResellerGridItems] = useState<PrimaryResellerGridItemsType[]>([]);
    const [distributorListGridItems, setDistributorGridItemsState] = useState<DistributorGridItemsType[]>([]);
    const userRole = sessionStorage.getItem(SessionStorageConsts.userRole);

    //Show the content appropriate to the Channel Requestor Role and other combinations
    const [msStoreSelection, setMsStoreSelection] = useState(UIControlsText.NoText);


    const getMsStoreItem = (): string => {
        return internalPricingContextState?.opportunityAccounts?.find(opp => opp.partnerType === PartnerType.MsStore) ? UIControlsText.YesText : UIControlsText.NoText
    };

    const getPrimaryResellerGridItems = () => {
        return opportunityAccounts?.filter(reseller => reseller.partnerType === PartnerType.ADR || reseller.partnerType === PartnerType.DMP)
            .map((optyAcc) => {
                const contact = internalPricingContextState.opportunityChannelContacts?.find((c) => c.opportunityAccountId === optyAcc.id);
                return {
                    id: optyAcc.id,
                    resellerCountry: optyAcc.accounts && optyAcc.accounts[0]?.countryName,
                    resellerType: optyAcc.partnerType ? PartnerTypeLabel.get(optyAcc.partnerType) : "",
                    resellerNames: optyAcc.accounts && optyAcc.accounts[0]?.companyName,
                    resellerEmailContacts: contact?.channelContactEmails,
                    deleteAction: optyAcc.id
                } as PrimaryResellerGridItemsType
            });
    }

    const getDistributorGridItems = () => {
        return opportunityAccounts?.filter(optyAccount => optyAccount.partnerType === PartnerType.ADD)
            .map((optyAcc) => {
                const contact = internalPricingContextState.opportunityChannelContacts?.find((c) => c.opportunityAccountId === optyAcc.id);
                const distiAccounts = optyAcc?.accounts && optyAcc?.accounts[0];
                return {
                    id: optyAcc.id,
                    distributorCountry: distiAccounts?.countryName,
                    distributorNames: distiAccounts?.companyName,
                    distributorEmailContacts: contact?.channelContactEmails,
                    associatedPartner: distiAccounts && optyAcc.partnerAccounts && optyAcc.partnerAccounts[distiAccounts.accountId]
                        && optyAcc.partnerAccounts[distiAccounts.accountId][0]
                        && optyAcc.partnerAccounts[distiAccounts.accountId][0].companyName
                } as DistributorGridItemsType
            });
    }

    // Attach Primary Reseller grid callbacks
    const attachPrimaryResellerCallbacks = (columnConfig: IColumnConfig[]): IColumnConfig[] => {
        columnConfig = columnConfig.filter(x => x.key !== "deleteAction");
        if (userRole === UserSubRole.ADD) {
            columnConfig = columnConfig.filter(x => x.key !== "resellerEmailContacts");
        }

        return columnConfig;
    };

    // Attach Distributor grid callbacks
    const attachDistributorCallbacks = (columnConfig: IColumnConfig[]): IColumnConfig[] => {
        var emailColumn = columnConfig.find(x => x.key === "distributorEmailContacts");
        columnConfig = columnConfig.filter(x => x.key !== "distributorOwner" && x.key !== "deleteAction" && x.key !== "distributorEmailContacts");

        var associatedPartner = {
            key: 'associatedPartner',
            name: 'associatedPartner',
            text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AssociatedReseller", UIControlsText.AssociatedReseller),
            editable: true,
            dataType: 'string',
            minWidth: 180,
            maxWidth: 200,
            isResizable: true,
            inputType: EditControlType.TextField
        };

        columnConfig = columnConfig.concat([associatedPartner]);
        if (emailColumn) {
            columnConfig = columnConfig.concat([emailColumn]);
        }

        return columnConfig;
    };

    useEffect(() => {
        if (opportunityAccounts && opportunityAccounts.length) {
            setPrimaryResellerGridItems(getPrimaryResellerGridItems() || []);
            setDistributorGridItemsState(getDistributorGridItems() || []);
            setMsStoreSelection(getMsStoreItem());
        }
    }, [opportunityAccounts]);

    /**
   * Content for the Channel tab in view mode with JSON format
   * @json
   */
    const ChannelJson = {
        className: "otle-reseller",
        components: [
            {
                type: 'Stack',
                data: {
                    id: 'MSStoreChannel',
                    visible: userRole !== UserSubRole.ADD && userRole !== UserSubRole.ADR && userRole !== UserSubRole.DMP && userRole !== UserSubRole.Reseller,
                    styles: stackItemStyles,
                    align: "auto",
                    tokens: smallAlignmentTokens,
                    wrap: true,
                    components: [
                        {
                            type: 'ChoiceGroup',
                            data: {
                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MsStoreLabeltext", UIControlsText.MsStoreLabeltext),
                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MsStoreLabeltext", UIControlsText.MsStoreLabeltext),
                                styles: DealDetailsStyles.horizontalChoiceGroup,
                                visible: true,
                                options: [{ key: UIControlsText.YesText, text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText) }, { key: UIControlsText.NoText, text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText), styles: { field: DealDetailsStyles.marginLeft10 } }],
                                required: true,
                                defaultSelectedKey: msStoreSelection,
                                selectedKey: msStoreSelection,
                                disabled: false,
                            }
                        }
                    ]
                }
            },
            {
                type: "CustomTileCardComponent",
                data: {
                    id: "ViewCardForReseller",
                    visible: primaryResellerGridItems.length > 0,
                    baseState: internalPricingContextState,
                    name: "Reseller Details",
                    iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellersIcon", UIControlsText.ResellersIcon),
                    components: [
                        {
                            type: 'Stack',
                            data: {
                                id: 'StackReseller',
                                visible: true,
                                styles: stackItemStyles,
                                components: [
                                    //Reseller Grid
                                    {
                                        type: 'Stack',
                                        data: {
                                            id: 'StackItemForAddChannelRoleBasedContentForReseller',
                                            visible: true,
                                            components: [
                                                {
                                                    type: "StackItem",
                                                    data: {
                                                        id: UIConfigIdentifierConstants.PrimaryResellerContainerId,
                                                        visible: true,
                                                        components: [
                                                            {
                                                                type: "DetailListEditableGrid",
                                                                data: {
                                                                    id: "DetailsListGridForPrimaryReseller",
                                                                    key: "DetailsListGridForPrimaryReseller",
                                                                    styles: { root: { height: "100%" } },
                                                                    items: primaryResellerGridItems,
                                                                    columns: attachPrimaryResellerCallbacks(PrimaryResellerGridColumnConfig),
                                                                    gridConfigOptions: {
                                                                        enableCellEdit: false,
                                                                    },
                                                                    enableDefaultEditMode: false,
                                                                    height: "140px",
                                                                    width: "100%",
                                                                    visible: primaryResellerGridItems?.length !== 0,
                                                                    className: "channel-grid",
                                                                }
                                                            }
                                                        ]
                                                    }
                                                },
                                            ]
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                type: "CustomTileCardComponent",
                data: {
                    id: "ViewCardForDistributor",
                    visible: distributorListGridItems?.length > 0,
                    baseState: internalPricingContextState,
                    name: "Distributor Details",
                    iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.UserEvent, "DistributorIcon", UIControlsText.DistributorIcon),
                    components: [
                        {
                            type: 'Stack',
                            data: {
                                id: 'ChannelStackForDisti',
                                horizontal: true,
                                components: [
                                    //Distributor Grid
                                    {
                                        type: 'StackItem',
                                        data: {
                                            id: 'StackItemForAddChannelRoleBasedContentForDistributor',
                                            align: "auto",
                                            components: [
                                                {
                                                    type: "DetailListEditableGrid",
                                                    data: {
                                                        id: "DetailsListCustomColumnsForDistributor",
                                                        key: "DetailsListCustomColumnsForDistributor",
                                                        items: distributorListGridItems,
                                                        columns: attachDistributorCallbacks(DistributorGridColumnConfig),
                                                        gridConfigOptions: { enableCellEdit: false },
                                                        styles: PageStyles.textFieldPanelWidth,
                                                        visible: distributorListGridItems?.length > 0,
                                                        height: (90 + (30 * distributorListGridItems?.length)) + "px",
                                                        className: "channel-grid",
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            }
        ]
    };

    return (
        <div data-nextgen-parent-group={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)}
            data-nextgen-parentid={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)}
            className="otle-channel">
            <Stack horizontal {...defaultColumnProps} styles={PageStyles.labelStyles}>
                <DocumentCardContainerComponent {...ChannelJson} />
            </Stack>
        </div>
    );
}