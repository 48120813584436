import { Paper } from "@mui/material";
import { ActionButton, DocumentCardPreview, getTheme, IDocumentCardPreviewProps, Stack, StackItem } from "office-ui-fabric-react";
import { ActionButtonStyle, DefaultPageStyles } from "../../../content/styles/Page.styles";
import { UIControlType } from "../../../models/UIControlType";
import { IBasePageState } from "../../pricingprogram/common/state/IBasePageState";
import { itemAlignmentsStackTokens, smallAlignmentTokens } from "../../pricingprogram/PricingProgramForm/PricingProgramFormUtils";
import { FormRenderingService } from "../Components/DynamicFormRendering";
import InnerHTMLContainer from "./InnerHTMLContainer";
import { cardHeaderIcon } from "../../../hooks/accessiblity-hooks";

const theme = getTheme();
const { palette } = theme;

export const IconClasses = ({
  circleIcon: {
    backgroundColor: '#333333',
    border: `1px solid`,
    color: '#333333'
  }
});

const DocumentCardComponent = (jsonDataComponents: any | undefined) => {
    let registerProp = jsonDataComponents?.register;
    let baseStateProp = jsonDataComponents?.baseState;
    // Don't render if template is undefined
    if (!jsonDataComponents || jsonDataComponents === undefined) return null;

    return (
        
            <Stack styles={{ root: { width: jsonDataComponents?.styles, paddingRight: 30, paddingBottom: 20}}} className={jsonDataComponents?.className}>
                <Paper id= "CardForCustomerDetails" elevation={1} style={{border: `0px solid ${palette.black}`, borderLeftWidth: "4px" }}>
                    <Stack horizontal wrap verticalAlign='start'>
                        <StackItem grow styles={{ root: {paddingLeft: 10} }}>
                            <Stack id="ContainerForDetails" horizontal wrap tokens={smallAlignmentTokens} >
                                <StackItem>
                                    {cardHeaderIcon(jsonDataComponents?.iconName, jsonDataComponents?.name)}
                                </StackItem>
                                <StackItem>
                                    <InnerHTMLContainer id="DetailsField" value={jsonDataComponents?.name} contolType={UIControlType.SubHeading} styles={{verticalAlign:'center', display:"inline-block", fontWeight:'bold'}}>
                                        
                                    </InnerHTMLContainer>
                                </StackItem>
                            </Stack>
                                {jsonDataComponents?.components?.map((eachItem: any, index: number) => {  
                                    return (
                                        <>
                                            {FormRenderingService(eachItem, registerProp, baseStateProp as IBasePageState)}
                                        </>
                                    );
                                })}
                        </StackItem>
                    </Stack>
                </Paper>
            </Stack>
    )
}

export default DocumentCardComponent;