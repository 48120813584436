import * as React from "react";
import { _Styles } from '../../content/styles/Page.styles';
import Logo from '../../content/images/Microsoft_Full_Color_reverse_logo.png';
import { UIControlsText } from "../../content/UIControlsText";
import { getRuleValidationMessage, getEntityDataFromSession} from '../../components/shared/common/util';
import { SessionStorageConsts } from '../../models/SessionStorageConsts';
import { MessageTypeText } from '../../models/MessageTypeText';

export const Footer: React.FunctionComponent = () => {
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const [buildNumberText, setBuildNumberText] = React.useState<string>(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BuildNumberText", UIControlsText.BuildNumberText));
    const [buildNumber] = React.useState<string>(process.env.REACT_APP_BUILD_NUMBER || "");

    return (
        <div className={`${_Styles.FooterStyle} page-footer`}>
            <img alt="Microsoft Logo" src={Logo} style={{ width: "103px", height: "2.5em" }} />
            <a target="_blank" href="mailto:sppsup@microsoft.com" className={_Styles.AnchorSection}>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContactUs", UIControlsText.ContactUs)}</a>
            <a target="_blank" href="http://go.microsoft.com/fwlink/?LinkId=521839" className={_Styles.AnchorSection}>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PrivacyAndCookies", UIControlsText.PrivacyAndCookies)}</a>
            <a target="_blank" href="https://privacy.microsoft.com/en-US/data-privacy-notice" className={_Styles.AnchorSection}>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DataPrivacyLink", UIControlsText.DataPrivacyLink)}</a>
            <a target="_blank" href="http://www.microsoft.com/en-us/legal/intellectualproperty/copyright/default.aspx" className={_Styles.AnchorSection}>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "TermsOfUse", UIControlsText.TermsOfUse)}</a>
            <a target="_blank" href="https://www.microsoft.com/trademarks" className={_Styles.AnchorSection}>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "TradeMark", UIControlsText.TradeMark)}</a>
            <a href="#"
                className={_Styles.AnchorSection}
                onClick={(e) => {
                    setBuildNumberText(buildNumberText === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BuildNumberText", UIControlsText.BuildNumberText) ? buildNumber : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BuildNumberText", UIControlsText.BuildNumberText));
                }}>{buildNumberText}</a>
            <a className={_Styles.AnchorSection}>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CopyRightMicrosoft", UIControlsText.CopyRightMicrosoft)}</a>
        </div>
    )
}