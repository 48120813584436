import React, { useCallback, useEffect, useState } from "react";
import { RefreshButtonStyles, _Styles } from '../../content/styles/Page.styles';
import { IKendoGridProps } from '../../components/shared/grid/IKendoGridProps';
import { populateGridColumns, getLoggedinUserRoleDetails, getUserRole, getUserSession, readGridConfig, getPageNumberFromSession, getRuleValidationMessage, getEntityDataFromSession } from "../../components/shared/common/util";
import { GridType } from "../../models/GridType";
import "../../content/styles/AddCaseGrid.css";
import { IOpportunityDeal } from "../../models/IOpportunityDeal";
import DashboardGrid from "../../components/shared/grid/dashboardgrid";
import { CommandBarButton, FontIcon, IconButton, IStackStyles, IStackTokens, MessageBar, MessageBarType, Spinner, Stack } from "office-ui-fabric-react";
import { UIControlsText } from "../../content/UIControlsText";
import Row from "react-bootstrap/Row";
import { JavaScriptExtensions } from "../../infrastructure/JavaScriptExtensions";
import { UserSubRole } from "../../models/UserSubRole";
import { IUserRoleDetail } from "../../models/IUserRoleDetail";
import { IDashboardDataRequest } from "../../models/IDashboardDataRequest";
import { DealType } from "../../models/DealType";
import { nextGenService } from "../../services/NextGenService";
import { UserRole } from "../../models/UserRole";
import { CoherencePivot, CoherencePivotTabProps, PivotContent, PivotContentItem } from "@cseo/controls";
import { CPStrategicDealReportGrid } from "./cp-strategic-deal-report";
import { ApplicationConstants } from "../../models/ApplicationConstants";
import { IDashboardGridProps } from "./props/IDashboardGridProps";
import { unionBy } from "lodash";
import { CssClassConstants } from "../../content/CssClassConstants";
import { PageConstants } from "../pageconstants/Constants";
import { AddToCase } from "./add-to-case";
import { MessageTypeText } from '../../models/MessageTypeText';
import { SessionStorageConsts } from "../../models/SessionStorageConsts";
import { IAccount } from "../../models/IAccount";
import { PartnerType } from "../../models/PartnerType";
import { DashboardSearch } from "./dashboard-search";
import moment from "moment";

const stackTokens: IStackTokens = { childrenGap: 8 };
const stackStyles: Partial<IStackStyles> = { root: { height: 32, marginBottom: 8 } };
const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

export const CPStrategicCaseViewGrid: React.FunctionComponent<IDashboardGridProps> = (props: IDashboardGridProps) => {
    const [selectedPivot, setSelectedPivot] = useState<string>(ApplicationConstants.DefaultDashBoardCaseView);
    const [useGrayPivot] = useState<boolean>(false);
    const [isRefreshed, setIsRefreshed] = useState<boolean>(false);
    var KendoGridProps: IKendoGridProps[] = [];
    var dashboardData: IOpportunityDeal[] = [];
    KendoGridProps.push(readGridConfig(GridType.CPStrategicDealReport).kendoGridProps)
    KendoGridProps.push(readGridConfig(GridType.CPStrategicCaseView).kendoGridProps)
    var activeDashboardConfig = KendoGridProps.filter(x => x.gridName === selectedPivot)
    const [cpStrategicCaseViewGridData, setCPStrategicCaseViewGridData] = useState<Array<IOpportunityDeal>>([]);
    const [cpStrategicDealReportGridData, setCPStrategicDealReportGridData] = useState<Array<IOpportunityDeal>>([]);
    const [cpStrategicCompleteGridData, setCPStrategicCompleteGridData] = useState<Array<IOpportunityDeal>>([]);
    const [activeTabConfig, setactiveTabConfig] = useState(activeDashboardConfig);
    const [userRoles] = useState<string>(getUserRole());
    const [isCaseLoading, setIsCaseLoading] = useState<boolean>(false);
    const [pageNumber, setpageNumber] = useState<number>(1)
    let userRole = userRoles;
    let renderDashboradTabs: any = [];
    const [sqlRecordCount, setSqlRecordCount] = useState<number>(0);
    const [msgBarText, setMsgBarText] = useState<string>();
    const [hideDialog, setHideDialog] = useState<boolean>(true);
    const [errorMessageType, setErrorMessageType] = useState(MessageBarType.success);
    const [showMessageBarOnDashboard, setshowMessageBarOnDashboard] = useState<boolean>(false);
    const resetDashboardMessageBarChoice = useCallback(() => setshowMessageBarOnDashboard(false), []);
    const [filteredPartnerAccount, setfilteredPartnerAccount] = useState<IAccount[] | undefined>(undefined);
    const [gridDataState, setGridDataState] = useState<any>();



    const getCPStrategicCaseView = async (pageNumber?: number, dataState?: any, pageChanged?: boolean) => {
        try {
            setIsRefreshed(false);
            const userSession = getUserSession();
            if (userSession != null) {
                setIsCaseLoading(true);
                let tpids: Array<string> = [];
                let loggedInUserInfo = JSON.parse(userSession);
                let sort: any
                let filter: any
                var searchedCaseNumber = "";
                if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(dataState)) {
                    sort = dataState.sort[0].field + " " + dataState.sort[0].dir
                    Date.prototype.toJSON = function(){ return moment(this).format(); }
                    filter = JSON.stringify(dataState.filter);
                    searchedCaseNumber = dataState?.filter?.filters[0]?.filters[0]?.field === "opportunityName" 
                                            ? dataState.filter.filters[0].filters[0].value
                                            : "";
                }
                if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(loggedInUserInfo) && !JavaScriptExtensions.isNullOrUndfinedOrEmpty(loggedInUserInfo.userRoleDetails)) {
                    let roles = userRoles.split(",");

                    if (loggedInUserInfo.userRoleDetails?.some((userRoleDetail: IUserRoleDetail) => userRoleDetail.userRole === UserRole.MSSeller)) {
                        roles.push(UserSubRole.MSSeller);
                        const surfaceSpecialistUserDetails: Array<IUserRoleDetail> = loggedInUserInfo.userRoleDetails?.filter((userRoleDetail: IUserRoleDetail) => userRoleDetail.userSubRole === UserSubRole.SurfaceSpecialist);
                        tpids = surfaceSpecialistUserDetails.flatMap(x => (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(x.tpid) ? x.tpid : [])) as Array<string>;
                    }

                    var resellerOrDistributorFilters : IAccount[] = [];
                    if(filteredPartnerAccount != undefined && filteredPartnerAccount.length != 0){
                        resellerOrDistributorFilters = filteredPartnerAccount
                    }
                    const dashboardRequest: IDashboardDataRequest = {
                        userRoleDetails: loggedInUserInfo.userRoleDetails,
                        dealType: DealType.CPStrategic,
                        email: loggedInUserInfo.email,
                        userRoles: roles.join(","),
                        tPIDs: tpids,
                        filter: filter,
                        pageNumber: pageNumber,
                        recordsToFetch: KendoGridProps.filter(x => x.gridName === selectedPivot)[0].recordsToFetch,
                        sort: sort,
                        isAllCasesDashboardRequest: false,
                        isLocalEmpowerment: false,
                        organizationId: loggedInUserInfo.organizationId,
                        resellerOrDistributorFilters: resellerOrDistributorFilters
                    };

                    if(cpStrategicCaseViewGridData.filter(dt => dt.opportunityName === searchedCaseNumber).length == 0){
                    let apiReturnedData = await Promise.resolve(nextGenService.getPricingProgramDashboardData(dashboardRequest));
                    if (apiReturnedData.status === 200) {
                        dashboardData = apiReturnedData.data.item1.map((item: IOpportunityDeal) => ({
                            ...item,
                            expirationDate: (JavaScriptExtensions.isNullOrUndfinedOrEmpty(item.expirationDate) ? item.expirationDate : new Date(item.expirationDate as Date)),
                            caseStartDate: (JavaScriptExtensions.isNullOrUndfinedOrEmpty(item.caseStartDate) ? item.caseStartDate : new Date(item.caseStartDate as Date))
                        }));
                        const cpStrategicCaseViewData = dashboardData.filter((x) => !x.isCaseAssociatedWithTPID)
                        if(pageChanged === undefined || pageChanged === false)
                        {
                            setCPStrategicCaseViewGridData(cpStrategicCaseViewData);
                        }
                        else
                        {
                            setCPStrategicCaseViewGridData(cpStrategicCaseViewGridData => unionBy([...cpStrategicCaseViewGridData, ...cpStrategicCaseViewData], x => x.opportunityName));
                        }
                        const casesAssociatedWithTPID = dashboardData.filter((x) => x.isCaseAssociatedWithTPID);
                        if(pageChanged === undefined || pageChanged === false)
                        {
                            setCPStrategicDealReportGridData(casesAssociatedWithTPID);
                        }
                        else
                        {
                            setCPStrategicDealReportGridData(casesAssociatedWithTPID ? (cpStrategicDealReportGridData => unionBy([...cpStrategicDealReportGridData, ...casesAssociatedWithTPID], x => x.opportunityName)) : []);
                        }
                        if(pageChanged === undefined || pageChanged === false)
                        {
                            setCPStrategicCompleteGridData(unionBy([...cpStrategicCaseViewData, ...casesAssociatedWithTPID], x => x.id));
                        }
                        else
                        {
                            setCPStrategicCompleteGridData(cpStrategicCompleteGridData => unionBy([...cpStrategicCompleteGridData, ...cpStrategicCaseViewData, ...casesAssociatedWithTPID], x => x.id));
                        }
                        setSqlRecordCount(apiReturnedData.data.item2.CPStrategicDeals + sqlRecordCount)
                        setIsCaseLoading(false);
                    } else {
                        setIsCaseLoading(false);
                    }
                }else {
                    setIsCaseLoading(false);
                }
                }
            }
        } catch (error) {
            setIsCaseLoading(false);
        }
    };

    useEffect(() => {
        if(isRefreshed){
            onRefresh()
        }
        
    }, [isRefreshed]);

    useEffect(() => {
        if(filteredPartnerAccount != undefined){
            setIsRefreshed(true);
        }
    }, [filteredPartnerAccount]);

    useEffect(() => {
        if(cpStrategicCaseViewGridData.length == 0 && cpStrategicDealReportGridData.length == 0 && isRefreshed){
            setIsRefreshed(false)
            setpageNumber(1)
            setSqlRecordCount(0)
            getCPStrategicCaseView(1, gridDataState)
        }
    }, [cpStrategicCaseViewGridData, cpStrategicDealReportGridData]);

    useEffect(() => {
        var activeDashboardConfig = KendoGridProps.filter(x => x.gridName === selectedPivot)
        let cpStrategicGridData = getCPStrategicGridData(selectedPivot);
        activeDashboardConfig[0].data = cpStrategicGridData
        activeDashboardConfig[0].totalRecords = cpStrategicGridData.length;
        activeDashboardConfig[0] = populateGridColumns(activeDashboardConfig[0])
        activeDashboardConfig[0].noOfRecords = getPageNumberFromSession(activeDashboardConfig[0])
        setactiveTabConfig(activeDashboardConfig);
    }, [cpStrategicCaseViewGridData, cpStrategicDealReportGridData])

    useEffect(() => {
        activeDashboardConfig[0] = populateGridColumns(activeDashboardConfig[0])
        activeDashboardConfig[0].noOfRecords = getPageNumberFromSession(activeDashboardConfig[0])
        setactiveTabConfig(activeDashboardConfig);
        if (props.onAddToCaseCallBack === GridType.CPStrategicCaseView) {
            getCPStrategicCaseView(1);
        }
    }, [props.onAddToCaseCallBack]);

    const onGetDataRequest = (dataState: any, isSortingOrFilterAppliedOrRemoved?: boolean) => {
        setGridDataState(dataState);
        if ((((cpStrategicCompleteGridData.length - dataState.skip) <= dataState.take) || isSortingOrFilterAppliedOrRemoved === true)) {
            var pageNo = (!isSortingOrFilterAppliedOrRemoved && isSortingOrFilterAppliedOrRemoved !== undefined && isSortingOrFilterAppliedOrRemoved !== null) ? pageNumber + 1 : 1;
            if(!isSortingOrFilterAppliedOrRemoved && isSortingOrFilterAppliedOrRemoved !== undefined && isSortingOrFilterAppliedOrRemoved !== null)
            {
                setpageNumber(count => count + 1)
            }
            else
            {
                setpageNumber(1);
            }
            getCPStrategicCaseView(pageNo, dataState, !isSortingOrFilterAppliedOrRemoved)
        }
    }

    const getCPStrategicGridData = (selectedPivot: string) => {
        if (selectedPivot == GridType.CPStrategicCaseView) {
            return cpStrategicCaseViewGridData;
        }
        else {
            return cpStrategicDealReportGridData;
        }
    }

    useEffect(() => {
        getCPStrategicCaseView(pageNumber)
    }, [])

    useEffect(() => {
        var kPagerNumbers = document.querySelectorAll('.k-pager-numbers .k-link');
        kPagerNumbers.forEach((kPagerNumber) => {
            if (kPagerNumber.innerHTML === "...") {
                kPagerNumber.setAttribute('aria-label', getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PaginationEllipsisLabel", UIControlsText.PaginationEllipsisLabel));
            } else {
                kPagerNumber.setAttribute('aria-label', 'Page ' + kPagerNumber.innerHTML);
            }
        });
    });
    const onPivotChange = (item: CoherencePivotTabProps) => {
        if (item.key) {
            setSelectedPivot(item.key);

            const userRoleDetails = getLoggedinUserRoleDetails();
            if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(userRoleDetails)) {
                let isSurfaceSpecialist = userRoleDetails.some((userRoleDetail: IUserRoleDetail) => userRoleDetail.userSubRole === UserSubRole.SurfaceSpecialist);
                if (!isSurfaceSpecialist && item.key !== GridType.CPStrategicDealReport) {
                    let config = KendoGridProps.filter((x) => x.gridName === item.key);
                    config[0].data = cpStrategicCaseViewGridData;
                    config[0].totalRecords = cpStrategicCaseViewGridData.length;
                    setactiveTabConfig(config);
                }
            }
        }
    };

    //render tabs for dashboard
    const renderTabs = () => {
        KendoGridProps.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
        KendoGridProps.forEach((eachConfig: any) => {
            if (eachConfig.allowCaseView[0].userRoles?.some((r: any) => userRole.includes(r))) {
                let thisPivot = { name: eachConfig.gridName, key: eachConfig.gridName }
                renderDashboradTabs.push(thisPivot);
            }
        });
    };
    renderTabs();

    function addCase() {
        return (!userRole.includes(UserSubRole.ADD)) ? "" : (<CommandBarButton onClick={() => { setHideDialog(false); }}>
            <div className={_Styles.CommandBarButton}>
                <FontIcon className={_Styles.DashboardIcons} aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddCaseAriaLabel", UIControlsText.AddCaseAriaLabel)} iconName={CssClassConstants.CirclePlusIconName}></FontIcon>
                <span className={_Styles.DashboardBtnText}>{PageConstants.AddCasToDashboard}</span>
            </div>
        </CommandBarButton>)
    }

    const initiateRefresh = () => {
        setIsRefreshed(true)
    }

    const onRefresh = () => {
        if(isRefreshed){
            let gridConfig = { ...activeTabConfig }
            gridConfig[0] = populateGridColumns(gridConfig[0])
            gridConfig[0].noOfRecords = getPageNumberFromSession(gridConfig[0])
            gridConfig[0].isRefresh = true
            gridConfig[0].data = []
            gridConfig[0].customGridMessage = ""
            setactiveTabConfig(gridConfig);
            setCPStrategicCaseViewGridData([])
            setCPStrategicDealReportGridData([])
        }
    }
    const onfilterByPartner = (account: IAccount[] | undefined) => {
        setfilteredPartnerAccount(account);
    }
    const onSuccess = (showMessageBar: boolean, messageType: any, messageText: string, isReloadData: boolean, resetHideDialog: boolean): void => {

        if (resetHideDialog) {
            setHideDialog(resetHideDialog);
        }

        if (showMessageBar) {
            setErrorMessageType(messageType);
            setMsgBarText(messageText);
            setshowMessageBarOnDashboard(true);
        }
        if (isReloadData) {
            setIsRefreshed(true);
        }
    };

    function addRefreshIcon() {
        return (
            <CommandBarButton onClick={initiateRefresh} iconProps={{ iconName: CssClassConstants.RefreshIconName }} ariaLabel={CssClassConstants.RefreshIconName} title="Refresh" styles={RefreshButtonStyles}>
                <span className={_Styles.DashboardBtnText}>{PageConstants.Refresh}</span>
            </CommandBarButton>
        )

    }

    return (
        <React.Fragment>
            {(!userRole.includes(UserSubRole.ADD)) ? (
                <div id="RefreshIconButton" className={_Styles.CommandBarButton}>
                    {addRefreshIcon()}
                </div>
            ) : <div id="RefreshButton" className={_Styles.CommandBarButton}>
                {addRefreshIcon()}
            </div>}

            <div className={CssClassConstants.AddToCaseButtonClass}>
                {addCase()}
            </div>
            <div data-nextgen-parent-group="Dashboard - CP Strategic cases" data-nextgen-parentid= "Dashboard - CP Strategic cases">
                <Stack>
            { userRoles.includes(UserSubRole.ADD) &&
                    <Row className="text-center mt-2 ms-Label">
                        <Stack>
                            <DashboardSearch  {...{onfilterByPartner : onfilterByPartner } }/>
                        </Stack>
                    </Row>
                }
            <Row>
                {showMessageBarOnDashboard && (
                    <Stack>
                        <div>
                            <MessageBar messageBarType={errorMessageType} onDismiss={resetDashboardMessageBarChoice} dismissButtonAriaLabel="Close" isMultiline={true}>
                                {msgBarText}
                            </MessageBar>
                        </div>
                    </Stack>
                )}
            </Row>
            <Row>
                {isCaseLoading &&
                    <Stack>
                        <div>
                            <Spinner label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LoadingCases", UIControlsText.LoadingCases)} />
                        </div>
                    </Stack>
                }
            </Row>
            <Row>
                <CoherencePivot className="tabListStyle"
                    useGrayPivot={useGrayPivot}
                    onTabSelected={onPivotChange}
                    selectedKey={selectedPivot}
                    tabs={[...renderDashboradTabs]}
                />
            </Row>            
            <Stack horizontal tokens={stackTokens}>
                <PivotContent selectedKey={selectedPivot}>
                    <PivotContentItem itemKey={GridType.CPStrategicCaseView} key={GridType.CPStrategicCaseView}>
                        <DashboardGrid {...{ kendoGridProps: { ...activeTabConfig[0] }, onGetDataRequest: onGetDataRequest } } />
                        <div id="footerPadding"></div>
                    </PivotContentItem>
                </PivotContent>
                <PivotContent selectedKey={selectedPivot}>
                    <PivotContentItem itemKey={GridType.CPStrategicDealReport} key={GridType.CPStrategicDealReport}>
                        <CPStrategicDealReportGrid {...{ opportunitDeals: cpStrategicDealReportGridData , onGetDataRequest: onGetDataRequest }} />
                    </PivotContentItem>
                </PivotContent>
            </Stack>
            </Stack>
            </div>            
            {/* Add Case Model */}
            <AddToCase {...{ hideDialog: hideDialog, onSuccess }} />
        </React.Fragment>
    );
};
