import { isCaseContainsDealType, isMultiDeal, stackItemCustomerStyles, stackItemStyles } from '../PricingProgramFormUtils';
import { UIControlsText, UIConfigIdentifierConstants } from '../../../../content/UIControlsText';
import { PageStyles, DealDetailsStyles } from '../../common/content/Page.Styles';
import { IChoiceGroupOption, IDropdownOption, MessageBarType } from '@fluentui/react';
import DefaultLabelRendererText from '../../../shared/ComponentTypes/DefaultLabelRendererText';
import { getsavedContext, isValidEmailWithSeperator, isLoggedInUserHasSubRole, isValidPostalCode, isValidInterger, getFieldConfigByState, getEntityDataFromSession, getRuleValidationMessage, isRequestorViewing } from '../../../../components/shared/common/util';
import { GuidanceText } from '../../../../models/GuidanceText';
import { useCommonPPContext } from '../../common/context/common-pp-context';
import { CaseActionTypes } from '../../common/reducer/CaseActionTypes';
import { StatePropertiesText } from '../../common/state/StatePropertiesText';
import { ErrorMessages } from '../../../../content/ErrorMessages';
import { useEffect, useState } from 'react';
import { IVertical } from '../../../../models/IVertical';
import { Guid } from '../../../../infrastructure/Guid';
import { ActionType } from '../../../../models/ActionType';
import { AttachmentDisplayMode } from '../../../../models/AttachmentDisplayMode';
import { IAttachment } from '../../../../models/IAttachment';
import { ArrayExtensions } from '../../../../infrastructure/ArrayExtensions';
import { JavaScriptExtensions } from '../../../../infrastructure/JavaScriptExtensions';
import { IDealInfoProps } from '../props/IDealInfoProps';
import React from 'react';
import { UserSubRole } from '../../../../models/UserSubRole';
import { UIControlType } from '../../../../models/UIControlType';
import Moment from 'moment';
import { RfpType } from '../../../../models/RfpType';
import { IDealState } from '../../common/state/IDealState';
import { OpportunityDealStatusCodes } from '../../../../models/OpportunityDealStatusCodes';
import _ from 'lodash';
import { FieldMapMode } from '../../../../models/FieldMapMode';
import { DealType } from '../../../../models/DealType';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { PageMode } from '../../../../models/PageMode';
import { IOpportunityChannelContact } from '../../../../models/IOpportunityChannelContact';

export function DealDetails(props: IDealInfoProps) {
    const { commonPPContextState, commonPPContextDispatch } = useCommonPPContext();
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const [isRfpRfqPublicTender, setIsRfpRfqPublicTender] = useState<boolean>(commonPPContextState?.opportunityRfp === RfpType.Yes);
    const [isRfpJustificationRequired, setIsRfpJustificationRequired] = useState<boolean>(true);
    const [rfpJustificationRequiredError, setrfpJustificationRequiredError] = useState<string>("");
    const [attachmentList, setAttachmentList] = useState<Array<IAttachment | undefined>>(commonPPContextState.attachments?.filter(x => x?.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText)) || []);
    const [showMessageBar, setShowMessageBar] = React.useState<boolean>(true);
    const handleDismiss = React.useCallback(() => setShowMessageBar(false), []);
    const customerData = commonPPContextState.opportunityDeals && commonPPContextState.opportunityDeals[0]?.dealCustomers
        && commonPPContextState.opportunityDeals[0]?.dealCustomers[0];
    const [selectedOptyDeal, setSelectedOptyDeal] = React.useState<IDealState>();
    const savedAttachments = getsavedContext()?.attachments;
    const deviceShipToCountryName = customerData?.country;
    const pivotTab = commonPPContextState.pivotTabs?.find(t => t.tabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealInformationText", UIControlsText.DealInformationText));
    const isMultiDealType: boolean = isMultiDeal(commonPPContextState);
    const [dealVerticalValue, setDealVerticalValue] = useState<string>('');
    const [emailValue, setEmailValue] = useState<string>(commonPPContextState && commonPPContextState?.opportunityChannelContacts?.find(t => t.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText))
        ?.channelContactEmails || "");

    useEffect(() => {
        const dealData = commonPPContextState?.opportunityDeals.find((t: IDealState) => t.statusCode !== OpportunityDealStatusCodes.Draft);
        if(dealData && !_.isEmpty(dealData)) { 
            setSelectedOptyDeal(commonPPContextState?.opportunityDeals && (commonPPContextState?.opportunityDeals?.find(t => t.name === commonPPContextState?.selectedOptyDealCaseNumber) || {}));
        }
        else {
            setSelectedOptyDeal({});
        }
        let dealVerticalVal = props?.verticals && commonPPContextState.opportunityDeals && props?.verticals.find(pt => pt.id === commonPPContextState?.opportunityDeals[0]?.dealVertical ? pt.name : '');
        setDealVerticalValue(dealVerticalVal?.name || '');
    }, [commonPPContextState?.selectedOptyDealCaseNumber]);

    useEffect(() => {
        commonPPContextState?.opportunityChannelContacts 
        && commonPPContextState.opportunityChannelContacts?.length 
        && setEmailValue(commonPPContextState?.opportunityChannelContacts
                    .find(t => t.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText))
                    ?.channelContactEmails || "");
        
    }, [commonPPContextState?.opportunityChannelContacts]);

    useEffect(() => {
        if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(deviceShipToCountryName)) {
            var countryId = props?.countries?.find(t => t?.countryName?.toLowerCase() === deviceShipToCountryName?.toLowerCase())?.countryId;
            commonPPContextDispatch({ type: CaseActionTypes.dealAddressValueChanged, dealInputControlValue: { key: StatePropertiesText.DeviceShipToCountryText, value: countryId } })
        }
    }, [deviceShipToCountryName]);

    useEffect(() => {
        setIsRfpRfqPublicTender(commonPPContextState?.opportunityRfp === RfpType.Yes);
   }, [commonPPContextState?.opportunityRfp]);

    useEffect(() => {
        setrfpJustificationRequiredError(pivotTab?.errors?.get(StatePropertiesText.RfpJustification) || "");
    }, [pivotTab?.errors?.get(StatePropertiesText.RfpJustification)]);

    useEffect(() => {
        let dealAttachments = commonPPContextState?.attachments?.filter(t => t?.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText));
        if (dealAttachments && dealAttachments?.length > 0) {
            setAttachmentList(dealAttachments);
            setIsRfpJustificationRequired(false);
            setErrorsDataIntoContext('', StatePropertiesText.RfpJustification || "");
        }
        else {
            setIsRfpJustificationRequired(true);
        }

    }, [commonPPContextState.attachments, isRfpRfqPublicTender]);

    const isFieldDisabled = (properId: string): boolean | undefined => {
        const isFieldDisabled: boolean | undefined = getFieldConfigByState(commonPPContextState, properId, FieldMapMode.Disable);
        return (isFieldDisabled === true) || (selectedOptyDeal?.isRenewed === true && isMultiDealType)
    }

    /**
     * Attachment change Callback function.
     * @param {Array<IAttachment | undefined>} attachmentList Updated list of attachments 
     */
    const handleAttachmentChanges = (attachmentList: Array<IAttachment | undefined>): void => {
        setAttachmentList(attachmentList.filter(item => item?.action !== ActionType.Delete));
        let otherAttachments = commonPPContextState.attachments?.filter(x => x?.source !== getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText)) || [];
        commonPPContextDispatch({ type: CaseActionTypes.attachmentChanged, attachments: ArrayExtensions.mergeArrays(otherAttachments, attachmentList) });
    }

    const onDealVerticalBlur = () => {
        let dealVertical = commonPPContextState.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.dealVertical;
        let message : string = '';
        if (JavaScriptExtensions.isEmptyOrNullOrUndefined(dealVertical)) {
            message = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoDealVerticalRequired", ErrorMessages.DealInfoDealVerticalRequired);
        }

        setErrorsDataIntoContext(message, StatePropertiesText.DealVerticalText || "");
        return;
    }

    /**
    * Deal Deatils Textbox Change Callback function.
    * @method
    * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
    * @param {string} newValue updated value from textField.
    * @param {string} targetPropertyName property name
    * @param {string} dealId property name
    */
    const onDealTextBoxChange = (targetPropertyName?: string, dealId?: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(!JavaScriptExtensions.isEmptyOrNullOrUndefined(newValue) &&  !JavaScriptExtensions.isEmptyOrNullOrUndefined(pivotTab?.errors?.get(targetPropertyName || ""))) {
            setErrorsDataIntoContext('', targetPropertyName || "");
        }

        commonPPContextDispatch({ type: CaseActionTypes.dealFieldValueChanged, dealInputControlValue: { key: targetPropertyName, value: newValue, id: dealId } })
    }

    /**
    * Deal Deatils Volume Change Callback function.
    * @method
    * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
    * @param {string} newValue updated value from textField.
    * @param {string} targetPropertyName property name
    * @param {string} dealId property name
    */
     const onDealVolumeChange = (targetPropertyName?: string, dealId?: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const re = /[^0-9]/g;
        newValue = newValue?.replace(re,'') || "";
        if((isValidInterger(newValue) && newValue.indexOf('+') < 0  && newValue.indexOf('-') < 0 && newValue?.length <= 10) || newValue ==="") {
            commonPPContextDispatch({ type: CaseActionTypes.dealFieldValueChanged, dealInputControlValue: { key: targetPropertyName, value: newValue, id: dealId } })
        }
        if(!JavaScriptExtensions.isEmptyOrNullOrUndefined(newValue) &&  !JavaScriptExtensions.isEmptyOrNullOrUndefined(pivotTab?.errors?.get(targetPropertyName || ""))) {
            setErrorsDataIntoContext('', targetPropertyName || "");
        }
    }    
    
    /**
    * Deal Address Textbox Change Callback function.
    * @method
    * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
    * @param {string} newValue updated value from textField.
    * @param {string} targetPropertyName property name
    */
    const onDealAddressTextBoxChange = (targetPropertyName?: string, dealId?: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(!JavaScriptExtensions.isEmptyOrNullOrUndefined(newValue) &&  !JavaScriptExtensions.isEmptyOrNullOrUndefined(pivotTab?.errors?.get(targetPropertyName || ""))) {
            setErrorsDataIntoContext('', targetPropertyName || "");
        }

        commonPPContextDispatch({ type: CaseActionTypes.dealAddressValueChanged, dealInputControlValue: { key: targetPropertyName, value: newValue, id: dealId } })
    }

    /**
     * Get PostalCode TextField ErrorMessage.
     * @method
     * @param {string>} value Text Box value.
     * @@returns {string} Error Message.
     */
     const getPostalCodeTextFieldErrorMessage = () => (value: string): string => {
         let message = '';
        if (!value) {
            message = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "PostalCodeRequired", ErrorMessages.PostalCodeRequired);
        } else {
        let isInvalid = !isValidPostalCode(value);
            if (isInvalid) {
                message =  getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidPostalCodeError", ErrorMessages.ValidPostalCodeError);
            }
        }

        setErrorsDataIntoContext(message, StatePropertiesText.DeviceShipToPostalCodeText || "");
        return message;
    } 

    /**
    * Customer Textbox Change Callback function.
    * @method
    * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
    * @param {string} newValue updated value from textField.
    * @param {string} targetPropertyName property name
    */
    const onCusotmerTextBoxChange = (targetPropertyName?: string, dealId?: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(!JavaScriptExtensions.isEmptyOrNullOrUndefined(newValue) && !JavaScriptExtensions.isEmptyOrNullOrUndefined(pivotTab?.errors?.get(targetPropertyName || ""))) {
            setErrorsDataIntoContext('', targetPropertyName || "");
        }

        commonPPContextDispatch({ type: CaseActionTypes.dealCustomerValueChanged, dealInputControlValue: { key: targetPropertyName, value: newValue, id: dealId } })
    }

    /**
    * Textbox EmailCCNotification Change Callback function.
    * @method
    * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
    * @param {string} newValue updated value from textField.
    */
    const onEmailCCNotificationTextBoxChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setEmailValue(event.currentTarget.value)
    }

     /**
    * Textbox EmailCCNotification Blur Callback function.
    * @method
    * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
    * @param {string} newValue updated value from textField.
    */
     const onEmailCCNotificationTextBoxBlur = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        newValue = newValue || event?.currentTarget?.value;
        const allChannelContacts: IOpportunityChannelContact[] = commonPPContextState.opportunityChannelContacts || [];
        const isComplete = isValidEmailWithSeperator(newValue || "", ';');
        let dealSource = getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText);
        
        if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(newValue)) {
            setEmailValue(newValue?.replace(/,/g, ';'));
            // Find all matching entries for the given dealSource
            let matchingCCDealContacts: IOpportunityChannelContact[] = allChannelContacts.filter(c => c.source === dealSource) || [];
            if (matchingCCDealContacts?.length > 0) {
                // If there are matching entries, update them
                matchingCCDealContacts?.forEach((emailCCDealContact: IOpportunityChannelContact) => {
                    emailCCDealContact.channelContactEmails = newValue?.replace(/,/g, ';');
                    emailCCDealContact.isComplete = true;
                });
            } else {
                // Otherwise, create a new entry with the email value
                allChannelContacts.push({
                    action: ActionType.Add,
                    id: Guid.newGuid(),
                    source: dealSource,
                    channelContactEmails: newValue?.replace(/,/g, ';'),
                    isComplete: isComplete
                });
    
                // Update the state with the new allChannelContacts
                commonPPContextDispatch({ type: CaseActionTypes.channelContactChanged, channelContacts: allChannelContacts });
            }
        }
    }
    
    /**
     * Dropdown change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {IDropdownOption} item Clicked Option from Dropdown.
     * @param {string} targetPropertyName property name
     * @param {string} dealId property name
     */
    const onDealDropDownChange = (targetPropertyName?: string, dealId?: string) => (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            setErrorsDataIntoContext('', targetPropertyName || "");
            commonPPContextDispatch({ type: CaseActionTypes.dealFieldValueChanged, dealInputControlValue: { key: targetPropertyName, value: item.key, id: dealId } })
        }
    }

    /**
     * Rfp Rfq PublicTender change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {IChoiceGroupOption} item Clicked Option from ChoiceGroup.
     */
    const onRfpRfqPublicTenderChange = (event: React.FormEvent<HTMLElement | HTMLInputElement>, item?: IChoiceGroupOption): void => {
        if (item) {
            setIsRfpRfqPublicTender(item.key.toString() === RfpType.Yes.toString())
            commonPPContextDispatch({ type: CaseActionTypes.rfpRfqPublicTenderValueChanged, inputNumber: item.key })
            if (item.key.toString() === RfpType.No.toString()) {
                commonPPContextDispatch({ type: CaseActionTypes.rfpRfqPublicTenderJustificationValueChanged, inputValue: "" });
                setErrorsDataIntoContext('', StatePropertiesText.RfpJustification || "");
            }
        }
    }

    /**
    * Rfp Rfq PublicTender Justification Textbox Change Callback function.
    * @method
    * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
    * @param {string} newValue updated value from textField.
    */
    const onRfpRfqPublicTenderJustificationValueChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(!JavaScriptExtensions.isEmptyOrNullOrUndefined(newValue) &&  !JavaScriptExtensions.isEmptyOrNullOrUndefined(pivotTab?.errors?.get(StatePropertiesText.RfpJustification))) {
            setErrorsDataIntoContext('', StatePropertiesText.RfpJustification);
        }
        commonPPContextDispatch({ type: CaseActionTypes.rfpRfqPublicTenderJustificationValueChanged, inputValue: newValue })
    }

    /**
     * Get TextField ErrorMessage.
     * @method
     * @param {string>} value Text Box value.
     * @@returns {string} Error Message.
     */
    const getTextFieldErrorMessage = (errorMessage: string, targetPropertyId: string) => (value: string): string => {
        const message = !value ? errorMessage : '';
        setErrorsDataIntoContext(message, targetPropertyId)
        return message;
    };

    const setErrorsDataIntoContext = (message: string, targetPropertyId: string) => {
        const pivotTabsData = commonPPContextState.pivotTabs;
        var pivotTab = pivotTabsData?.find(t=> t.tabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealInformationText", UIControlsText.DealInformationText));
        if(JavaScriptExtensions.isEmptyOrNullOrUndefined(message)) {
            pivotTab?.errors?.delete(targetPropertyId);
        }
        else {
            pivotTab?.errors?.set(targetPropertyId, message);
        }
        
        commonPPContextDispatch({ type: CaseActionTypes.pivotTabData, pivotTabDetails: pivotTabsData })
    }

    /**
     * Get Email Field Validate ErrorMessage.
     * @method
     * @param {string} value Text Box value.
     * @returns {string} Error Message.
     */
    const getEmailFieldErrorMessage = (errorMessage: string) => (value: string): string => {
        let message = '';
        if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(value)) {
            message = !isValidEmailWithSeperator(value, ';') ? errorMessage : '';
        }

        setErrorsDataIntoContext(message, StatePropertiesText.EmailCCNotificationRecipients);
        return message;
    };

    /**
     * Get Justification Field Validate ErrorMessage.
     * @method
     * @param {string>} value Text Box value.
     * @returns {string} Error Message.
     */
    const getTextFieldJustificationErrorMessage = (errorMessage: string) => (value: string): string => {
        let message = ''
        if (!value && isRfpJustificationRequired) {
            message = errorMessage;
        }
        else {
            message = '';
        }

        setErrorsDataIntoContext(message, StatePropertiesText.RfpJustification);
        return message;
    };
    const checkRequestorViewing = () => {
        return isRequestorViewing(commonPPContextState.submitterDetails?.email);
    }
    const DealDetailsJson = [
        {
            type: "Stack",
            data: {
                id: "StackTileForDeal",
                'data-nextgen-parent-group': "Create - Deal Details",
                'data-nextgen-parentid': "Create - Deal Details",
                horizontal: true,
                verticalAlign: "start",
                components: [
                    {
                        type: "CustomTileCardComponent",
                        data: {
                            id: "CreateCardForDeal",
                            baseState: commonPPContextState,
                            name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText),
                            iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsIcon", UIControlsText.DealDetailsIcon),
                            components: [
                                {
                                    type: 'Stack',
                                    data: {
                                        id: 'DealsHeaderGuidance',
                                        vertical: true,
                                        components: [
                                            {
                                                type: "StackItem",
                                                data: {
                                                    id: "StackitemForGuidance",
                                                    components: [
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'StackItemDealInfoMessageBar',
                                                                styles: stackItemStyles,
                                                                visible: !JavaScriptExtensions.isNullOrUndfinedOrEmpty(customerData) && !customerData?.isManaged && showMessageBar,
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "DealInfoMessageBar",
                                                                            contolType: UIControlType.MessageBar,
                                                                            message: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "DealInfoNewCustomerWarning", GuidanceText.DealInfoNewCustomerWarning),
                                                                            messageBarType: MessageBarType.info,
                                                                            handleDismiss: handleDismiss,
                                                                            styles: DealDetailsStyles.customerWarningMessageWidth
                                                                        }
                                                                    },
                                                                    
                                                                ]
                                                            },
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                type: "Stack",
                                                data: {
                                                    id: "StackForDealitems",
                                                    horizontal: true,
                                                    verticalAlign: "start",
                                                    components: [
                                                        {
                                                            type: "StackItem",
                                                            data: {
                                                                id: UIConfigIdentifierConstants.StackDealsNameRowId,
                                                                styles: stackItemStyles,
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "TextField",
                                                                        visible: !isFieldDisabled(UIConfigIdentifierConstants.StackDealsNameRowId),
                                                                        data: {
                                                                            id: "DealNameTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealNameText", UIControlsText.DealNameText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealNameText", UIControlsText.DealNameText),
                                                                            styles: PageStyles.textFieldStylesOneColumn,
                                                                            placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealNamePlaceHolderTextExample", UIControlsText.DealNamePlaceHolderTextExample),
                                                                            required: true,
                                                                            validateOnLoad: false,
                                                                            validateOnFocusOut: true,
                                                                            maxLength: 100,
                                                                            onGetErrorMessage: getTextFieldErrorMessage(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoDealNameRequired", ErrorMessages.DealInfoDealNameRequired), StatePropertiesText.DealNameText),
                                                                            value: (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? commonPPContextState && commonPPContextState.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.dealName : selectedOptyDeal?.dealName,
                                                                            onChange: onDealTextBoxChange(StatePropertiesText.DealNameText, selectedOptyDeal?.id),
                                                                            errorMessage: pivotTab?.errors?.get(StatePropertiesText.DealNameText)
                                                                        }
                                                                    },
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        visible: isFieldDisabled(UIConfigIdentifierConstants.StackDealsNameRowId),
                                                                        data: {
                                                                            id: "DealNameTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealNameText", UIControlsText.DealNameText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealNameText", UIControlsText.DealNameText),
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? commonPPContextState && commonPPContextState.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.dealName : selectedOptyDeal?.dealName,
                                                                        }
                                                                    },
                                                                    
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: "StackItemForDealVerticalDropdown",
                                                                styles: stackItemStyles,
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "Dropdown",
                                                                        visible: !isFieldDisabled(UIConfigIdentifierConstants.StackItemForDealVerticalDropdownId),
                                                                        data: {
                                                                            id: "dealVerticalDropdown",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVerticalText", UIControlsText.DealVerticalText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVerticalText", UIControlsText.DealVerticalText),
                                                                            styles: PageStyles.dropdownStyles,
                                                                            placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealVerticalPlaceHolderText", UIControlsText.DealVerticalPlaceHolderText),
                                                                            required: true,
                                                                            options: (props?.verticals && props?.verticals.map((pt: IVertical) => ({ key: pt.id, text: pt.name } as IDropdownOption))) || [],
                                                                            selectedKey: (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? commonPPContextState.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.dealVertical : selectedOptyDeal?.dealVertical,
                                                                            onBlur: onDealVerticalBlur,
                                                                            onChange: onDealDropDownChange(StatePropertiesText.DealVerticalText),
                                                                            errorMessage: pivotTab?.errors?.get(StatePropertiesText.DealVerticalText)
                                                                        }
                                                                    },
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        visible: isFieldDisabled(UIConfigIdentifierConstants.StackItemForDealVerticalDropdownId),
                                                                        data: {
                                                                            id: "dealVerticalDropdown",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVerticalText", UIControlsText.DealVerticalText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealVerticalText", UIControlsText.DealVerticalText),
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: (props.verticals && props.verticals?.map((item : IVertical) => ((item?.id === (commonPPContextState?.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.dealVertical)) ? item.name : '') as string)),                 
                                                                        }
                                                                    },
                                                                ]
                                                            }
                                                        },
                                                    ]
                                                }
                                            },
                                            {
                                                type: 'Stack',
                                                data: {
                                                    id: "StackContainerForOverviewAndDomain",
                                                    horizontal: true,
                                                    verticalAlign: "start",
                                                    components: [
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: UIConfigIdentifierConstants.StackItemDealOverviewId,
                                                                styles: stackItemStyles,
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "TextField",
                                                                        visible: !getFieldConfigByState(commonPPContextState, UIConfigIdentifierConstants.StackItemDealOverviewId, FieldMapMode.Disable),
                                                                        data: {
                                                                            id: UIConfigIdentifierConstants.DealOverviewTextFieldId,
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText),
                                                                            styles: PageStyles.textFieldStylesOneColumn,
                                                                            placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DealOverviewPlaceholderTextExample", UIControlsText.DealOverviewPlaceholderTextExample),
                                                                            required: true,
                                                                            validateOnLoad: false,
                                                                            validateOnFocusOut: true,
                                                                            multiline: true,
                                                                            rows: 2,
                                                                            resizable: false,
                                                                            maxLength: 5000,
                                                                            value:  (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? (commonPPContextState?.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.dealOverview) : selectedOptyDeal?.dealOverview,
                                                                            onGetErrorMessage: getTextFieldErrorMessage(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoDealOverviewRequired", ErrorMessages.DealInfoDealOverviewRequired), StatePropertiesText.DealOverviewText),
                                                                            onRenderLabel: DefaultLabelRendererText(isLoggedInUserHasSubRole(UserSubRole.MSStoreUser) ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "DealInfoDealOverviewForMSStore", GuidanceText.DealInfoDealOverviewForMSStore) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "DealInfoDealOverview", GuidanceText.DealInfoDealOverview)),
                                                                            onChange: onDealTextBoxChange(StatePropertiesText.DealOverviewText, selectedOptyDeal?.id),
                                                                            errorMessage: pivotTab?.errors?.get(StatePropertiesText.DealOverviewText)
                                                                        }
                                                                    },
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        visible: getFieldConfigByState(commonPPContextState, UIConfigIdentifierConstants.StackItemDealOverviewId, FieldMapMode.Disable),
                                                                        data: {
                                                                            id: UIConfigIdentifierConstants.DealOverviewTextFieldId,
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText),
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value:  (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? (commonPPContextState?.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.dealOverview) : selectedOptyDeal?.dealOverview,
                                                                        }
                                                                    },
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: "StackItem",
                                                            data: {
                                                                id: "StackContainerDomain",
                                                                styles: stackItemCustomerStyles,
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "TextField",
                                                                        visible: !isFieldDisabled(UIConfigIdentifierConstants.StackContainerDomainId),
                                                                        data: {
                                                                            id: "CustomerDomainTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDomainText", UIControlsText.CustomerDomainText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDomainText", UIControlsText.CustomerDomainText),
                                                                            styles: PageStyles.textFieldStylesOneColumn,
                                                                            placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "CustomerDomainPlaceholderTextExample", UIControlsText.CustomerDomainPlaceholderTextExample),
                                                                            required: true,
                                                                            validateOnLoad: false,
                                                                            validateOnFocusOut: true,
                                                                            maxLength: 100,
                                                                            onGetErrorMessage: getTextFieldErrorMessage(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoCustomerDomainRequired", ErrorMessages.DealInfoCustomerDomainRequired), StatePropertiesText.CustomerDomain),
                                                                            value: (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? commonPPContextState?.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.dealCustomers && commonPPContextState?.opportunityDeals[0]?.dealCustomers[0]?.dealCustomerDomain: selectedOptyDeal?.dealCustomers && selectedOptyDeal?.dealCustomers[0]?.dealCustomerDomain,
                                                                            onRenderLabel: DefaultLabelRendererText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "DealInfoCustomerDomain", getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "DealInfoCustomerDomain", GuidanceText.DealInfoCustomerDomain))),
                                                                            onChange: onCusotmerTextBoxChange(StatePropertiesText.CustomerDomain, selectedOptyDeal?.id),
                                                                            errorMessage: pivotTab?.errors?.get(StatePropertiesText.CustomerDomain)
                                                                        }
                                                                    },
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        visible: isFieldDisabled(UIConfigIdentifierConstants.StackContainerDomainId),
                                                                        data: {
                                                                            id: "CustomerDomainTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDomainText", UIControlsText.CustomerDomainText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDomainText", UIControlsText.CustomerDomainText),
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? commonPPContextState?.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.dealCustomers && commonPPContextState?.opportunityDeals[0]?.dealCustomers[0]?.dealCustomerDomain: selectedOptyDeal?.dealCustomers && selectedOptyDeal?.dealCustomers[0]?.dealCustomerDomain,
                                                                        }
                                                                    },
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                type: "Stack",
                                                data: {
                                                    id: "StackItemColForDeal",
                                                    horizontal: true,
                                                    verticalAlign: "start",
                                                    //wrap: true,
                                                    components: [
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'StackItemForStartDate',
                                                                styles: stackItemStyles,
                                                                //align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CaseStartDateTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealInfoCaseStartDateText", UIControlsText.DealInfoCaseStartDateText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealInfoCaseStartDateText", UIControlsText.DealInfoCaseStartDateText),
                                                                            //styles: PageStyles.textFieldStylesOneColumn,
                                                                            value: selectedOptyDeal && selectedOptyDeal?.caseStartDate && Moment(selectedOptyDeal?.caseStartDate).format('MM/DD/YYYY'),
                                                                            contolType: UIControlType.LabelValueView,
                                                                        }
                                                                    },
                                                                ]
                                                            }
                                                        },
                                                        {
                                                                type: 'StackItem',
                                                                data: {
                                                                    id: 'StackItemForCaseDealEndDate',
                                                                    styles: stackItemStyles,
                                                                    //align: "auto",
                                                                    components: [
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        data: {
                                                                            id: "CaseExpireTextField",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealInfoEndDateText", UIControlsText.DealInfoEndDateText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealInfoEndDateText", UIControlsText.DealInfoEndDateText),
                                                                            //styles: PageStyles.textFieldStylesOneColumn,
                                                                            value: selectedOptyDeal && selectedOptyDeal?.caseEndDate && Moment(selectedOptyDeal?.caseEndDate).format('MM/DD/YYYY'),
                                                                            contolType: UIControlType.LabelValueView,
                                                                        }
                                                                    },
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                                    
                                        ]
                                    }
                                }   
                             ]
                         }
                    },
                    {
                        type: "StackItem",
                        data: {
                            id: "StackTileForAddressForCreate",
                            //disableShrink: true,
                            //wrap: true,
                            horizontal: true,
                            verticalAlign:"start",
                            components: [
                                {
                                    type: "CustomTileCardComponent",
                                    data: {
                                        id: "ViewCardForAddressForCreate",
                                        baseState: commonPPContextState,
                                        name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeviceShipToAddressCardText", UIControlsText.DeviceShipToAddressCardText),
                                        iconName: UIControlsText.AddressIcon,
                                        components: [
                                                {
                                                    type: 'Stack',
                                                    data: {
                                                        id: UIConfigIdentifierConstants.StackDeviceShipToAddressRowId,
                                                        //wrap: true,
                                                        verticalAlign: "start",
                                                        components: [
                                                            {
                                                                type: 'Stack',
                                                                data: {
                                                                    id: 'StackItemForShipAddress',
                                                                    horizontal: true,
                                                                    //wrap: true,
                                                                    components: [
                                                                        {
                                                                            type: "StackItem",
                                                                            data: {
                                                                                id: "StackItemForDeviceShipTo",
                                                                                styles: stackItemStyles,
                                                                                align: "start",
                                                                                components: [
                                                                                    {
                                                                                        type: "TextField",
                                                                                        visible: !isFieldDisabled("StackItemForShipAddress"),
                                                                                        data: {
                                                                                            id: "DeviceShipToAddressTextField",
                                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeviceShipToAddressText", UIControlsText.DeviceShipToAddressText),
                                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeviceShipToAddressText", UIControlsText.DeviceShipToAddressText),
                                                                                            styles: PageStyles.textFieldStylesTwoColumn,
                                                                                            placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DeviceShipToAddressPlaceholderTextExample", UIControlsText.DeviceShipToAddressPlaceholderTextExample),
                                                                                            required: true,
                                                                                            validateOnLoad: false,
                                                                                            validateOnFocusOut: true,
                                                                                            maxLength: 1000,
                                                                                            onGetErrorMessage: getTextFieldErrorMessage(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoDeviceShipToAddressRequired", ErrorMessages.DealInfoDeviceShipToAddressRequired), StatePropertiesText.DeviceShipToAddressText),
                                                                                            value: (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? commonPPContextState?.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.dealAddress && commonPPContextState?.opportunityDeals[0]?.dealAddress?.address1 : selectedOptyDeal?.dealAddress?.address1,
                                                                                            onChange: onDealAddressTextBoxChange(StatePropertiesText.DeviceShipToAddressText, selectedOptyDeal?.id),
                                                                                            errorMessage: pivotTab?.errors?.get(StatePropertiesText.DeviceShipToAddressText)
                                                                                        }
                                                                                    },
                                                                                    {
                                                                                        type: "InnerHTMLContainer",
                                                                                        visible: isFieldDisabled("StackItemForShipAddress"),
                                                                                        data: {
                                                                                            id: "DeviceShipToAddressTextField",
                                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeviceShipToAddressText", UIControlsText.DeviceShipToAddressText),
                                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeviceShipToAddressText", UIControlsText.DeviceShipToAddressText),
                                                                                            contolType: UIControlType.LabelValueView,
                                                                                            value: (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? commonPPContextState?.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.dealAddress && commonPPContextState?.opportunityDeals[0]?.dealAddress?.address1 : selectedOptyDeal?.dealAddress?.address1,
                                                                                        }
                                                                                    },
                                                                                ]
                                                                            }
                                                                        },
                                                                        {
                                                                            type: 'StackItem',
                                                                            styles: stackItemStyles,
                                                                            data: {
                                                                                id: 'StackItemForCountry',
                                                                                styles: stackItemStyles,
                                                                                align: "end",
                                                                                components: [
                                                                                    {
                                                                                        type: "InnerHTMLContainer",
                                                                                        data: {
                                                                                            id: "CountryTextField",
                                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Country", UIControlsText.Country),
                                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Country", UIControlsText.Country),
                                                                                            value: deviceShipToCountryName,
                                                                                            contolType: UIControlType.LabelValueView,
                                                                                        }
                                                                                    },
                                                                                ]
                                                                            }
                                                                        },
                                                                    ]
                                                                }
                                                            },
                                                            {
                                                                type: "Stack",
                                                                data: {
                                                                    id: "StackForFirstAddressRow",
                                                                    horizontal: true,
                                                                    components: [
                                                                        {
                                                                            type: 'StackItem',
                                                                            data: {
                                                                                id: 'StackItemForCityTextField',
                                                                                styles: stackItemStyles,
                                                                                align: "auto",
                                                                                components: [
                                                                                    {
                                                                                        type: "TextField",
                                                                                        visible: !isFieldDisabled("StackItemForCity"),
                                                                                        data: {
                                                                                            id: "CityTextField",
                                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CityText", UIControlsText.CityText),
                                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CityText", UIControlsText.CityText),
                                                                                            styles: PageStyles.textFieldStylesWidth146,
                                                                                            placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DeviceShipToCityPlaceholderTextExample", UIControlsText.DeviceShipToCityPlaceholderTextExample),
                                                                                            required: true,
                                                                                            validateOnLoad: false,
                                                                                            validateOnFocusOut: true,
                                                                                            maxLength: 100,
                                                                                            onGetErrorMessage: getTextFieldErrorMessage(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoCityRequired", ErrorMessages.DealInfoCityRequired), StatePropertiesText.DeviceShipToCityText),
                                                                                            value: (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? commonPPContextState?.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.dealAddress && commonPPContextState?.opportunityDeals[0]?.dealAddress?.city : selectedOptyDeal?.dealAddress?.city,
                                                                                            onChange: onDealAddressTextBoxChange(StatePropertiesText.DeviceShipToCityText, selectedOptyDeal?.id),
                                                                                            errorMessage: pivotTab?.errors?.get(StatePropertiesText.DeviceShipToCityText)
                                                                                        }
                                                                                    },
                                                                                    {
                                                                                        type: "InnerHTMLContainer",
                                                                                        visible: isFieldDisabled("StackItemForCity"),
                                                                                        data: {
                                                                                            id: "CityTextField",
                                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CityText", UIControlsText.CityText),
                                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CityText", UIControlsText.CityText),
                                                                                            contolType: UIControlType.LabelValueView,
                                                                                            value: (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? commonPPContextState?.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.dealAddress && commonPPContextState?.opportunityDeals[0]?.dealAddress?.city : selectedOptyDeal?.dealAddress?.city,
                                                                                        }
                                                                                    },
                                                                                ]
                                                                            }
                                                                        },                                                            
                                                                        {   
                                                                            type: 'StackItem',
                                                                            data: {
                                                                                id: 'StackItemForStateProvince',
                                                                                styles: stackItemStyles,
                                                                                align: "auto",
                                                                                components: [
                                                                                    {
                                                                                        type: "TextField",
                                                                                        visible: !isFieldDisabled("StackItemForStateProvince"),
                                                                                        data: {
                                                                                            id: "StateProvinceTextField",
                                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeviceShipToStateProvinceText", UIControlsText.DeviceShipToStateProvinceText),
                                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeviceShipToStateProvinceText", UIControlsText.DeviceShipToStateProvinceText),
                                                                                            styles: PageStyles.textFieldStylesWidth146,
                                                                                            placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DeviceShipToStatePlaceholderTextExample", UIControlsText.DeviceShipToStatePlaceholderTextExample),
                                                                                            required: true,
                                                                                            validateOnLoad: false,
                                                                                            validateOnFocusOut: true,
                                                                                            maxLength: 100,
                                                                                            onGetErrorMessage: getTextFieldErrorMessage(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoStateRequired", ErrorMessages.DealInfoStateRequired), StatePropertiesText.DeviceShipToStateProvinceText),
                                                                                            value: (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? commonPPContextState?.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.dealAddress && commonPPContextState?.opportunityDeals[0]?.dealAddress?.stateOrProvince : selectedOptyDeal?.dealAddress?.stateOrProvince,
                                                                                            onChange: onDealAddressTextBoxChange(StatePropertiesText.DeviceShipToStateProvinceText, selectedOptyDeal?.id),
                                                                                            errorMessage: pivotTab?.errors?.get(StatePropertiesText.DeviceShipToStateProvinceText)
                                                                                        }
                                                                                    },
                                                                                    {
                                                                                        type: "InnerHTMLContainer",
                                                                                        visible: isFieldDisabled("StackItemForStateProvince"), 
                                                                                        data: {
                                                                                            id: "StateProvinceTextField",
                                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeviceShipToStateProvinceText", UIControlsText.DeviceShipToStateProvinceText),
                                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeviceShipToStateProvinceText", UIControlsText.DeviceShipToStateProvinceText),
                                                                                            contolType: UIControlType.LabelValueView,
                                                                                            value: (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? commonPPContextState?.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.dealAddress && commonPPContextState?.opportunityDeals[0]?.dealAddress?.stateOrProvince : selectedOptyDeal?.dealAddress?.stateOrProvince,
                                                                                        }
                                                                                    },
                                                                                ]
                                                                            }
                                                                        },
                                                                    ]
                                                                }
                                                            },
                                                            {
                                                                type: "Stack",
                                                                data: {
                                                                    id: "StackForSecondAddressRow",
                                                                    //wrap: true,
                                                                    horizontal: true,
                                                                    components: [
                                                                        {
                                                                            type: 'StackItem',
                                                                            data: {
                                                                                id: 'StackItemForOther',
                                                                                styles: stackItemStyles,
                                                                                align: "auto",
                                                                                components: [
                                                                                    {
                                                                                        type: "TextField",
                                                                                        visible: !isFieldDisabled("StackItemForPostalCode"),
                                                                                        data: {
                                                                                            id: "PostalCodeTextField",
                                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeviceShipToPostalCodeText", UIControlsText.DeviceShipToPostalCodeText),
                                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeviceShipToPostalCodeText", UIControlsText.DeviceShipToPostalCodeText),
                                                                                            styles: PageStyles.textFieldStylesWidth146,
                                                                                            placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "DeviceShipTopostalPlaceholderTextExample", UIControlsText.DeviceShipTopostalPlaceholderTextExample),
                                                                                            required: true,
                                                                                            validateOnLoad: false,
                                                                                            validateOnFocusOut: true,
                                                                                            maxLength: 20,
                                                                                            onGetErrorMessage: getPostalCodeTextFieldErrorMessage(),
                                                                                            value: (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? commonPPContextState?.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.dealAddress && commonPPContextState?.opportunityDeals[0]?.dealAddress?.postalCode : selectedOptyDeal?.dealAddress?.postalCode,
                                                                                            onChange: onDealAddressTextBoxChange(StatePropertiesText.DeviceShipToPostalCodeText, selectedOptyDeal?.id),
                                                                                            errorMessage: pivotTab?.errors?.get(StatePropertiesText.DeviceShipToPostalCodeText)
                                                                                        }
                                                                                    },
                                                                                    {
                                                                                        type: "InnerHTMLContainer",
                                                                                        visible: isFieldDisabled("StackItemForPostalCode"),
                                                                                        data: {
                                                                                            id: "PostalCodeTextField",
                                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PostalCode", UIControlsText.PostalCode),
                                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PostalCode", UIControlsText.PostalCode),
                                                                                            contolType: UIControlType.LabelValueView,
                                                                                            value: (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? commonPPContextState?.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.dealAddress && commonPPContextState?.opportunityDeals[0]?.dealAddress?.postalCode : selectedOptyDeal?.dealAddress?.postalCode,
                                                                                        }
                                                                                    },
                                                                                ]
                                                                            }
                                                                        },
                                                                    ]
                                                                }
                                                            },
                                                        ]
                                                    },
                                                },
                                                {
                                                    type: 'Stack',
                                                    data: {
                                                        id: 'emptyHeight20',
                                                        components: [
                                                        ]
                                                    },
                                                },
                                            ]
                                    }
                                },
                            ]
                        }
                     },
                 ]
            }
         },
         
        {
            type: "Stack",
            data: {
                id: "StackItemForCreateDealVol",
                horizontal: true,
                verticalAlign: "start",
                components: [
                    {
                        type: "CustomTileCardComponent",
                        data: {
                            id: "ViewCardForCommittedDealVolume",
                            baseState: commonPPContextState,
                            name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CommittedDealVolumeText", UIControlsText.CommittedDealVolumeText),
                            iconName: UIControlsText.CommittedDealVolumeIcon,
                            components: [
                                {
                                    type: 'StackItem',
                                    data: {
                                        id: 'StackItemCommittedDealVolume',
                                        styles: stackItemStyles,
                                        align: "auto",
                                        components: [
                                            {
                                                type: "CommittedDealVolume",
                                                data: {
                                                    required: true,
                                                    opportunityDeals: commonPPContextState?.opportunityDeals,
                                                    pageMode: commonPPContextState?.pageMode,
                                                    dealTypes: props.dealTypes,
                                                    handleDealQuantityChanges: onDealVolumeChange,
                                                    setErrorsDataIntoContext: setErrorsDataIntoContext,
                                                    selectedOptyDealCaseNumber: commonPPContextState?.selectedOptyDealCaseNumber,
                                                    pivotTabContext: pivotTab,
                                                    partnerDetails: commonPPContextState?.partnerDetails
                                                }
                                            },
                                        ]
                                    }
                                },
                            ]
                        }
                    },
                    {
                        type: "Stack",
                        data: {
                            id: "StackTileItemForCreateCase",
                            horizontal: true,
                            disableShrink: true,
                            verticalAlign: "start",
                            components: [
                                {
                                    type: "CustomTileCardComponent",
                                    data: {
                                        id: "CreateCardForCase",
                                        baseState: commonPPContextState,
                                        name: "Case Details",
                                        iconName: UIControlsText.CaseDetailsIcon,
                                        components: [
                                            {
                                                type: 'StackItem',
                                                data: {
                                                    id: UIConfigIdentifierConstants.StackItemEmailCCNotificationRecipientsId,
                                                    styles: stackItemStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "TextField",
                                                            visible: !getFieldConfigByState(commonPPContextState, UIConfigIdentifierConstants.StackItemEmailCCNotificationRecipientsId, FieldMapMode.Disable) && checkRequestorViewing(),
                                                            data: {
                                                                id: "EmailCCNotificationRecipient",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailCCNotificationRecipients", UIControlsText.EmailCCNotificationRecipients),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailCCNotificationRecipients", UIControlsText.EmailCCNotificationRecipients),
                                                                styles: PageStyles.textFieldPanelWidth,
                                                                placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "EmailCCRecipientsPlaceholderTextExample", UIControlsText.EmailCCRecipientsPlaceholderTextExample),
                                                                validateOnLoad: false,
                                                                validateOnFocusOut: true,
                                                                multiline: true,
                                                                rows: 1,
                                                                resizable: false,
                                                                maxLength: 5000,
                                                                onGetErrorMessage: getEmailFieldErrorMessage(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidEmailError", ErrorMessages.ValidEmailError)),
                                                                value: emailValue,
                                                                onChange: onEmailCCNotificationTextBoxChange,
                                                                onBlur: onEmailCCNotificationTextBoxBlur,
                                                                errorMessage: pivotTab?.errors?.get(StatePropertiesText.EmailCCNotificationRecipients)
                                                            }
                                                        },
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            visible: getFieldConfigByState(commonPPContextState, UIConfigIdentifierConstants.StackItemEmailCCNotificationRecipientsId, FieldMapMode.Disable),
                                                            data: {
                                                                id: "EmailCCNotificationRecipient",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailCCNotificationRecipients", UIControlsText.EmailCCNotificationRecipients),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EmailCCNotificationRecipients", UIControlsText.EmailCCNotificationRecipients),
                                                                contolType: UIControlType.LabelValueView,
                                                                value: commonPPContextState?.opportunityChannelContacts && commonPPContextState?.opportunityChannelContacts.find(t => t.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText))?.channelContactEmails,
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                type: 'StackItem',
                                                data: {
                                                    id: UIConfigIdentifierConstants.StackForCaseDecisionRowId,
                                                    styles: stackItemStyles,
                                                    //align: "auto",
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                id: "CaseDecisionJustificationTextField",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseDecisionJustification", UIControlsText.CaseDecisionJustification),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseDecisionJustification", UIControlsText.CaseDecisionJustification),
                                                                styles: PageStyles.textFieldStylesOneColumn,
                                                                value: selectedOptyDeal && selectedOptyDeal?.closureDecisionComments,
                                                                contolType: UIControlType.LabelValueView,
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                type: "Stack",
                                                data: {
                                                    id: "emptyHeight10",
                                                    compoennts: [
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                ]
            }
        },
        {
            type: "Stack",
            data: {
                id: "StackItemTileForRFPRFQTenderForCreate",
                horizontal: true,
                verticalAlign: "start",
                components: [
                    {
                        type: "CustomTileCardComponent",
                        visible: (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? isCaseContainsDealType(commonPPContextState, DealType.DealRegistration) : selectedOptyDeal?.dealType?.name === DealType.DealRegistration,
                        data: {
                            id: "CustomTileCardComponentForPreSales",
                            baseState: commonPPContextState,
                            name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PreSalesActivitiesText", UIControlsText.PreSalesActivitiesText),
                            iconName: UIControlsText.PreSalesIcon,
                            components: [
                                {
                                    type: 'Stack',
                                    data: {
                                        id: 'DealsPreSalesStack',
                                        horizontal: true,
                                        wrap: true,
                                        components: [
                                            {
                                                type: 'StackItem',
                                                data: {
                                                    id: UIConfigIdentifierConstants.StackItemPreSalesActivitiesId,
                                                    styles: stackItemStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "TextField",
                                                            visible: !getFieldConfigByState(commonPPContextState, "StackItemPreSalesActivities", FieldMapMode.Disable),
                                                            data: {
                                                                id: "dealPreSalesActivities",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PreSalesActivitiesText", UIControlsText.PreSalesActivitiesText),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PreSalesActivitiesText", UIControlsText.PreSalesActivitiesText),
                                                                styles: PageStyles.textFieldStylesOneColumn,
                                                                placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "PreSalesActivitiesPlaceholderText", UIControlsText.PreSalesActivitiesPlaceholderText),
                                                                validateOnLoad: false,
                                                                validateOnFocusOut: true,
                                                                required: true,
                                                                maxLength: 5000,
                                                                multiline: true,
                                                                rows: 3,
                                                                resizable: false,
                                                                onGetErrorMessage: getTextFieldErrorMessage(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "DealInfoPreSalesRequired", ErrorMessages.DealInfoPreSalesRequired), StatePropertiesText.PreSalesActivitiesText),
                                                                value:  (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? (commonPPContextState?.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.preSalesActivity) : selectedOptyDeal?.preSalesActivity,
                                                                onChange: onDealTextBoxChange(StatePropertiesText.PreSalesActivitiesText, selectedOptyDeal?.id),
                                                                errorMessage: pivotTab?.errors?.get(StatePropertiesText.PreSalesActivitiesText)
                                                            }
                                                        },
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            visible: getFieldConfigByState(commonPPContextState, "StackItemPreSalesActivities", FieldMapMode.Disable),
                                                            data: {
                                                                id: "dealPreSalesActivities",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PreSalesActivitiesText", UIControlsText.PreSalesActivitiesText),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PreSalesActivitiesText", UIControlsText.PreSalesActivitiesText),
                                                                contolType: UIControlType.LabelValueView,
                                                                value:  (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? (commonPPContextState?.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.preSalesActivity) : selectedOptyDeal?.preSalesActivity,
                                                            }
                                                        },
                                                    ]
                                                }
                                            },
                                        ]
                                    }
                                },
                            ]
                        }
                    },
                    {
                        type: "StackItem",
                        data: {
                            id: "StackTileForRfQ",
                            horizontal: true,
                            verticalAlign: "start",
                            components: [
                                {
                                    type: "CustomTileCardComponent",
                                    data: {
                                        id: "CreateCardForRFP",
                                        baseState: commonPPContextState,
                                        name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RfpRfqPublicTenderText", UIControlsText.RfpRfqPublicTenderText),
                                        iconName: UIControlsText.PublicTenderPolicyIcon,
                                        components: [
                                            {
                                                type: 'Stack',
                                                data: {
                                                    id: 'DealsRFPRFQTab',
                                                    //wrap: true,
                                                    horizontal: true,
                                                    components: [
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                styles: stackItemStyles,
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "ChoiceGroup",
                                                                        visible: !getFieldConfigByState(commonPPContextState, "RfpRfqPublicTender", FieldMapMode.Disable) ,
                                                                        data: {
                                                                            id: UIConfigIdentifierConstants.RfpRfqPublicTenderId,
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RfpRfqPublicTenderText", UIControlsText.RfpRfqPublicTenderText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RfpRfqPublicTenderText", UIControlsText.RfpRfqPublicTenderText),
                                                                            styles: DealDetailsStyles.horizontalChoiceGroupSmallColumn,
                                                                            options: [{ key: RfpType.Yes, text: UIControlsText.YesText, styles: { field: DealDetailsStyles.fontSize12 }  }, { key: RfpType.No, text: UIControlsText.NoText, styles: { field: DealDetailsStyles.marginLeftFontSize } }],
                                                                            required: true,
                                                                            defaultSelectedKey: RfpType.No,
                                                                            selectedKey: commonPPContextState?.opportunityRfp,
                                                                            onChange: onRfpRfqPublicTenderChange
                                                                        }
                                                                    },
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        visible: getFieldConfigByState(commonPPContextState, "RfpRfqPublicTender", FieldMapMode.Disable),
                                                                        data: {
                                                                            id: UIConfigIdentifierConstants.RfpRfqPublicTenderId,
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RfpRfqPublicTenderText", UIControlsText.RfpRfqPublicTenderText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RfpRfqPublicTenderText", UIControlsText.RfpRfqPublicTenderText),
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: commonPPContextState.opportunityRfp === RfpType.Yes ? UIControlsText.YesText : UIControlsText.NoText,                     
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: UIConfigIdentifierConstants.StackItemPublicTenderJustificationId,
                                                                styles: stackItemStyles,
                                                                visible: isRfpRfqPublicTender,
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "TextField",
                                                                        visible: !getFieldConfigByState(commonPPContextState, "RfpRfqPublicTender", FieldMapMode.Disable),
                                                                        data: {
                                                                            id: "RfpRfqPublicTenderJustificationText",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RfpRfqPublicTenderJustificationText", UIControlsText.RfpRfqPublicTenderJustificationText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RfpRfqPublicTenderJustificationText", UIControlsText.RfpRfqPublicTenderJustificationText),
                                                                            styles: PageStyles.textFieldStylesOneColumn,
                                                                            placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "RfpRfqPublicTenderJustificationPlaceholderText", UIControlsText.RfpRfqPublicTenderJustificationPlaceholderText),
                                                                            required: isRfpJustificationRequired,
                                                                            validateOnLoad: false,
                                                                            validateOnFocusOut: true,
                                                                            multiline: true,
                                                                            rows: 3,
                                                                            resizable: false,
                                                                            maxLength: 5000,
                                                                            errorMessage: rfpJustificationRequiredError,
                                                                            value: commonPPContextState?.rfpJustification,
                                                                            onRenderLabel: DefaultLabelRendererText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "DealInfoPublicTenderJustification", GuidanceText.DealInfoPublicTenderJustification)),
                                                                            onChange: onRfpRfqPublicTenderJustificationValueChanged,
                                                                        }
                                                                    },
                                                                    {
                                                                        type: "InnerHTMLContainer",
                                                                        visible: getFieldConfigByState(commonPPContextState, "RfpRfqPublicTender", FieldMapMode.Disable),
                                                                        data: {
                                                                            id: "RfpRfqPublicTenderJustificationText",
                                                                            label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RfpRfqPublicTenderJustificationText", UIControlsText.RfpRfqPublicTenderJustificationText),
                                                                            ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "RfpRfqPublicTenderJustificationText", UIControlsText.RfpRfqPublicTenderJustificationText),
                                                                            contolType: UIControlType.LabelValueView,
                                                                            value: commonPPContextState?.rfpJustification,
                                                                        }
                                                                    },
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            type: 'StackItem',
                                                            data: {
                                                                id: 'StackItemPublicTenderAttachements',
                                                                styles: stackItemStyles,
                                                                align: "auto",
                                                                components: [
                                                                    {
                                                                        type: "Attachment",
                                                                        data: {
                                                                            id: "RfpRfqPublicTenderAttachement",
                                                                            required: false,
                                                                            attachmentList: attachmentList,
                                                                            labelText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Attachments", UIControlsText.Attachments),
                                                                            source: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealDetailsText", UIControlsText.DealDetailsText),
                                                                            guidanceText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "DealInfoPublicTenderAttachments", getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "DealInfoPublicTenderAttachments", GuidanceText.DealInfoPublicTenderAttachments)),
                                                                            handleAttachmentChanges: handleAttachmentChanges,
                                                                            opportunityDealStatusCode: commonPPContextState.opportunityDeals[0]?.statusCode,
                                                                            pageMode: commonPPContextState.pageMode,
                                                                            attachmentDisplayMode: AttachmentDisplayMode.Grid,
                                                                            allowedFileExtensions: UIControlsText.AllowedFileExtensionsForDRAndSP.split(','),
                                                                            savedAttachments: savedAttachments,
                                                                            disabled: false
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                    ]
                                                },
                                            },
                                            
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    
                ]
            }
        },
        {
            type: "Stack",
            data: {
                id: "StackItemTileForAdditionalInformationForCreate",
                horizontal: true,
                verticalAlign: "start",
                components: [
                    {
                        type: "CustomTileCardComponent",
                        visible: true,
                        data: {
                            id: "CustomTileCardComponentForAdditionalInformation",
                            baseState: commonPPContextState,
                            name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalInformation", UIControlsText.AdditionalInformation),
                            iconName: UIControlsText.DealDetailsIcon,
                            components: [
                                {
                                    type: 'Stack',
                                    data: {
                                        id: 'AdditionalInformationStack',
                                        horizontal: true,
                                        wrap: true,
                                        components: [
                                            {
                                                type: 'StackItem',
                                                data: {
                                                    id: UIConfigIdentifierConstants.StackItemAdditionalInformation,
                                                    styles: stackItemStyles,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "TextField",
                                                            visible: !getFieldConfigByState(commonPPContextState, "StackItemAdditionalInformation", FieldMapMode.Disable),
                                                            data: {
                                                                id: "AdditionalInformationId",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalInformation", UIControlsText.AdditionalInformation),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalInformation", UIControlsText.AdditionalInformation),
                                                                styles: PageStyles.textFieldStylesOneColumn,
                                                                placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "AdditionalInformationsPlaceholderText", UIControlsText.AdditionalInformationsPlaceholderText),
                                                                validateOnLoad: false,
                                                                validateOnFocusOut: true,
                                                                maxLength: 5000,
                                                                multiline: true,
                                                                rows: 3,
                                                                resizable: false,
                                                                value:  (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? (commonPPContextState?.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.additionalComments) : selectedOptyDeal?.additionalComments,
                                                                onChange: onDealTextBoxChange(StatePropertiesText.AdditionalComments, selectedOptyDeal?.id),
                                                                errorMessage: pivotTab?.errors?.get(StatePropertiesText.AdditionalComments)
                                                            }
                                                        },
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            visible: getFieldConfigByState(commonPPContextState, "StackItemAdditionalInformation", FieldMapMode.Disable),
                                                            data: {
                                                                id: "AdditionalInformationId",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalInformation", UIControlsText.AdditionalInformation),
                                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AdditionalInformation", UIControlsText.AdditionalInformation),
                                                                contolType: UIControlType.LabelValueView,
                                                                value:  (selectedOptyDeal && _.isEmpty(selectedOptyDeal)) ? (commonPPContextState?.opportunityDeals && commonPPContextState?.opportunityDeals[0]?.additionalComments) : selectedOptyDeal?.additionalComments,
                                                            }
                                                        },
                                                    ]
                                                }
                                            },
                                        ]
                                    }
                                },
                            ]
                        }
                    },
                ]
            }
        }

    ];

    return DealDetailsJson;
}