import { useEffect, useState } from "react";
import { getUserRole, getRuleValidationMessage, getEntityDataFromSession } from "../../components/shared/common/util";
import { HelpAndSupportStyles } from "../../content/styles/Page.styles";
import { UIControlsText } from "../../content/UIControlsText";
import { MessageTypeText } from '../../models/MessageTypeText';
import { SessionStorageConsts } from '../../models/SessionStorageConsts';
import { useToSetSupportPageTitle as mdppAccessibilitySetSupportPageTitle } from "../../hooks/accessiblity-hooks";

export const HelpAndSupport: React.FunctionComponent = () => {
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const [isPartner, setIsPartner] = useState(false);
    const toolSupportEmail = process.env.REACT_APP_TOOL_SUPPORT_EMAIL || "";
    const dealRegSupportEmail = process.env.REACT_APP_DEALREG_SUPPORT_EMAIL || "";
    const specialPricingNorthAmericaSupportEmail = process.env.REACT_APP_SP_NORTH_AMERICA_SUPPORT_EMAIL || "";
    const specialPricingEUMiddleEastAfricaSupportEmail = process.env.REACT_APP_SP_EUROPE_MIDDLE_EAST_AFRICA_SUPPORT_EMAIL || "";
    const specialPricingAsiaSupportEmail = process.env.REACT_APP_SP_ASIA_SUPPORT_EMAIL || "";
    const cPSSupportEmail = process.env.REACT_APP_CPS_SUPPORT_EMAIL || "";
    const supportMailTo = `mailto:${toolSupportEmail}`;

    mdppAccessibilitySetSupportPageTitle();

    //const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    useEffect(() => {
        var userRole = getUserRole();
        //logged in user should have any one of the role
        var partnerRoles = ["ADR", "ADD", "DMP", "MST"]
        partnerRoles.forEach((role) => {
            if (userRole.indexOf(role) !== -1) {
                setIsPartner(true);
                return;
            }
        })
    }, [])

    if (isPartner) {
        return (
            <div style={HelpAndSupportStyles.paddingStyle}>
                <div style={HelpAndSupportStyles.HelpAndSupportHeaderStyles}>
                    {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MDPPHelpAndSupport", UIControlsText.MDPPHelpAndSupport)}
                </div>
                <div style={HelpAndSupportStyles.contentStyle} >
                    {
                        <div style={HelpAndSupportStyles.contentStyle}>
                            <div style={HelpAndSupportStyles.contentStyleBottomLine}>
                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "HelpAndSupportResourceGuidance", UIControlsText.HelpAndSupportResourceGuidance)}
                            </div>
                            <b>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "CaseSpecificContacts", UIControlsText.CaseSpecificContacts)}</b>
                            <div style={HelpAndSupportStyles.contentStyleBottomLineBlueText}>
                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "DealRegistrationText", UIControlsText.DealRegistrationText) + ":"} <a href={supportMailTo}>{dealRegSupportEmail} </a>
                            </div>
                            <div style={HelpAndSupportStyles.contentStyleBlueText}>
                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "SpecialPricingText", UIControlsText.SpecialPricingText)}<div style={{ color: "black" }}>: {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "ContactByRegion", UIControlsText.ContactByRegion)}</div>
                            </div>
                            <ul>
                                <li>
                                    {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "SpecialPricingContactNorthAmerica", UIControlsText.SpecialPricingContactNorthAmerica) + ":"} <a href={supportMailTo}>{specialPricingNorthAmericaSupportEmail} </a>
                                </li>
                                <li>
                                    {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "SpecialPricingContactEUMiddleEastAfrica", UIControlsText.SpecialPricingContactEUMiddleEastAfrica) + ":"} <a href={supportMailTo}>{specialPricingEUMiddleEastAfricaSupportEmail} </a>
                                </li>
                                <li>
                                    <div style={HelpAndSupportStyles.contentStyleBottomLine}>
                                        {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "SpecialPricingContactAsia", UIControlsText.SpecialPricingContactAsia) + ":"} <a href={supportMailTo}>{specialPricingAsiaSupportEmail} </a>
                                    </div>
                                </li>
                            </ul>
                            <div style={HelpAndSupportStyles.contentStyleBlueText}>
                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "CustomPricingStrategic", UIControlsText.CustomPricingStrategic) + ":"}
                            </div>
                            <ul>
                                <li>
                                    {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "CPContactGuidance", UIControlsText.CPContactGuidance)}
                                </li>
                                <li>
                                    <div style={HelpAndSupportStyles.contentStyleBottomLine}>
                                        {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "CPContact", UIControlsText.CPContact)} <a href={supportMailTo}>{cPSSupportEmail} </a>
                                    </div>
                                </li>
                            </ul>
                            <div style={HelpAndSupportStyles.contentStyleBlueText}>
                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "OTLE", UIControlsText.OTLE) + ":"}
                            </div>
                            <ul>
                                <li>
                                    {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "OTLEContactGuidance", UIControlsText.OTLEContactGuidance)}
                                </li>

                            </ul>
                            <b>
                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "ClaimsSupportGuidance", UIControlsText.ClaimsSupportGuidance)}
                            </b>
                            <div style={HelpAndSupportStyles.contentStyleBottomLineBlueText}>
                                {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "ClaimsContact", UIControlsText.ClaimsContact)} <a href={supportMailTo}>{toolSupportEmail} </a>
                            </div>

                        </div>
                    }
                </div>
            </div>
        )
    }
    else
        return (
            <div style={HelpAndSupportStyles.paddingStyle}>
                <h5>{getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "HelpAndSupport", UIControlsText.HelpAndSupport)}</h5>
                <ul style={HelpAndSupportStyles.contentStyle} >
                    {
                        <li>
                            {getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ToolSupportContent", UIControlsText.ToolSupportContent)} <a href={supportMailTo}>{toolSupportEmail}</a>
                        </li>
                    }
                </ul>
            </div>
        )
}